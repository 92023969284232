import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setUserData } from 'app/store/userSlice';
import axios from 'axios';
import userService from '../../../services/userService';
import { getDomainRequest } from '../../admin/appWebsite/store/appWebsiteSlice';

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
});

export const submitLogin = createAsyncThunk(
  'login/submitLogin',
  async (data, { getState, dispatch }) => {
    const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    instance.defaults.headers.common.isrequestfrom = true;
    instance.defaults.headers.common.timeZone = getTimeZone;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      const result = await response.data;
      if (result && result.data && result.data.login.user) {
        const loginValue = result.data.login;
        dispatch(setUserData(loginValue?.user));
        userService.setSession(loginValue?.accessToken);
      }
      return result.data.login;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const sendOTPOnPhoneNumberForLogin = createAsyncThunk(
  'login/sendOTPOnPhoneNumberForLogin',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.isrequestfrom = true;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const checkEmail = createAsyncThunk(
  'login/checkEmail',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.isrequestfrom = true;
    try {
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const checkUserName = createAsyncThunk(
  'login/checkUserName',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.isrequestfrom = true;
    try {
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const googleAuth = createAsyncThunk(
  'login/googleAuth',
  async (data, { getState, dispatch }) => {
    const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    instance.defaults.headers.common.isrequestfrom = true;
    instance.defaults.headers.common.timeZone = getTimeZone;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      const result = await response.data;
      if (result && result.data && result.data.googleAuth.user) {
        const loginValue = result.data.googleAuth;
        dispatch(setUserData(loginValue?.user));
        userService.setSession(loginValue?.accessToken);
      }
      return result.data.googleAuth;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const asPreviouslyRegesterInGoogle = createAsyncThunk(
  'login/asPreviouslyRegesterInGoogle',
  async (data) => {
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response?.data?.data?.asPreviouslyRegesterInGoogle;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const asPreviouslyRegesterInAppleThunk = createAsyncThunk(
  'login/asPreviouslyRegesterInApple',
  async (data) => {
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response?.data?.data?.asPreviouslyRegesterInApple;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const appleAuthThunk = createAsyncThunk(
  'login/appleAuth',
  async (data, { getState, dispatch }) => {
    const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    instance.defaults.headers.common.isrequestfrom = true;
    instance.defaults.headers.common.timeZone = getTimeZone;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      const result = await response.data;
      if (result && result?.data && result?.data?.appleAuthV2?.user) {
        const loginValue = result.data.appleAuthV2;
        dispatch(setUserData(loginValue?.user));
        userService.setSession(loginValue?.accessToken);
      }
      return result.data.appleAuthV2;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const appleTokenThunk = createAsyncThunk('login/appleTokenThunk', async (data) => {
  try {
    return data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

const loginAuthSlice = createSlice({
  name: 'login',
  initialState: {},
  reducers: {
    resetLoginAction: (state) => {
      if (state) {
        state.submitLoginData = {};
        state.googleAuthData = {};
        state.asPreviouslyRegesterInGoogleData = {};
        state.sendOTPOnPhoneNumberForLoginData = {};
        state.checkEmailData = {};
        state.checkUserNameData = {};
        state.asPreviouslyRegesterInAppleData = {};
        state.appleAuthData = {};
        state.appleTokenThunk = {};
      }
    },
  },
  extraReducers: {
    [submitLogin.fulfilled]: (state, action) => ({
      ...state,
      submitLoginData: action.payload,
    }),
    [googleAuth.fulfilled]: (state, action) => ({
      ...state,
      googleAuthData: action.payload,
    }),
    [sendOTPOnPhoneNumberForLogin.fulfilled]: (state, action) => ({
      ...state,
      sendOTPOnPhoneNumberForLoginData: action.payload,
    }),
    [checkEmail.fulfilled]: (state, action) => ({
      ...state,
      checkEmailData: action.payload,
    }),
    [checkUserName.fulfilled]: (state, action) => ({
      ...state,
      checkUserNameData: action.payload,
    }),
    [asPreviouslyRegesterInGoogle.fulfilled]: (state, action) => ({
      ...state,
      asPreviouslyRegesterInGoogleData: action.payload,
    }),
    [asPreviouslyRegesterInAppleThunk.fulfilled]: (state, action) => ({
      ...state,
      asPreviouslyRegesterInAppleData: action.payload,
    }),
    [appleAuthThunk.fulfilled]: (state, action) => ({
      ...state,
      appleAuthData: action.payload,
    }),
    [appleTokenThunk.fulfilled]: (state, action) => ({
      ...state,
      appleTokenData: action.payload,
    }),
  },
});

export const { resetLoginAction } = loginAuthSlice.actions;

export default loginAuthSlice.reducer;

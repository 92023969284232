const getPromotionSlider = `
query ($isActive: Boolean, $pageNumber:Int, $pageSize: Int, $searchText: String) {
  getPromotionSlider(isActive: $isActive, pageNumber:$pageNumber, pageSize:$pageSize, searchText: $searchText){
    message
    status
    data {
      id
      description
      icon
      index
      isActive
      buttonText
      title
      url
      cts
      packageType{
        id
        name
      }
    }
    totalCount
  }
}
`;

const updatePromotionSlider = `
mutation updatePromotionSlider($promotionSliderId: Int!, $description: String, $buttonText: String, $title: String, $url: String, $index: Int, $packageTypeIds: [Int!], $isActive: Boolean, $icon: Upload) {
  updatePromotionSlider(promotionSliderId: $promotionSliderId,
description: $description, buttonText: $buttonText, title: $title, url: $url, index: $index, packageTypeIds: $packageTypeIds, isActive: $isActive, icon: $icon) {
    message
    status
  }
}
`;

const addAppPromotionSlider = `
mutation addAppPromotionSlider($description: String!, $buttonText: String!, $title: String!, $url: String!, $index: Int!, $packageTypeIds: [Int!]!, $icon: Upload!) {
  addAppPromotionSlider(
    description: $description
    buttonText: $buttonText
    title: $title
    url: $url
    index: $index
    packageTypeIds: $packageTypeIds
    icon: $icon
  ) {
    message
    status
  }
}`;

const getPackageTypes = `
query {
  getPackageTypes {
    data {
      id
      name
    }
    message
    status
  }
}
`;

const getPromotions = `
query getPromotions($promotionInfoId: Int, $isActive: Boolean, $pageNumber: Int, $pageSize: Int, $promotionTypeId: Int) {
  getPromotions(promotionInfoId: $promotionInfoId, isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, promotionTypeId: $promotionTypeId) {
    data {
      id
      cts
      businessName
      htmlTemplate
      button
      promotionType
      isActive
      image
      discountValue
      minimumCartValue
      button
      maximumDiscount
      buyQuantity
      getQuantity
      buyProductName
      getProductName
      points
      minimumPurchaseAmount
      productName
      duration
      loyaltyPointsTypeId
      loyalityType
      applicableFor
      applyTo
    }
    count
    message
    status
  }
}
`;

const getPromotionApplicableForType = `
query{
  getPromotionApplicableForType{
    message
    status
    data{
      key
      type
    }
  }
}
`;

const getPromotionButtonType = `
query{
  getPromotionButtonType{
    message
    status
    data{
      key
      type
    }
  }
}
`;

const getPromotionType = `
query{
  getPromotionType{
    data{
      id
      type
      icon
    }
    message
    status
  }
}
`;

const getPromotionAndRedeemPreviewImages = `
query($type: String, $pageNumber: Int, $pageSize: Int){
  getPromotionAndRedeemPreviewImages(type: $type, pageNumber:$pageNumber, pageSize: $pageSize){
    message
    status
    data{
      id
      type
      image
    }
  }
}
`;

const createPromotion = `
mutation createPromotion($userBusinessId: Int!, $promotionCategoryId: Int!, $image: Upload, $htmlTemplate: String!, $minimumCartValue: Int, $maximumDiscount: Int, $buttonName: String!, $applicableFor: String!, $discountValue: Int, $buyQuantity: Int, $getQuantity: Int, $buyProductName: String, $getProductName: String, $loyaltyPointsTypeId: Int, $applyTo: String, $productName: String, $points: Int, $minimumPurchaseAmount: Int, $duration: String, $imageUrl: String) {
  createPromotion(
    userBusinessId: $userBusinessId
    promotionCategoryId: $promotionCategoryId
    image: $image
    imageUrl: $imageUrl,
    htmlTemplate: $htmlTemplate
    minimumCartValue: $minimumCartValue
    maximumDiscount: $maximumDiscount
    buttonName: $buttonName
    applicableFor: $applicableFor
    discountValue: $discountValue
    buyQuantity: $buyQuantity
    getQuantity: $getQuantity
    buyProductName: $buyProductName
    getProductName: $getProductName
    loyaltyPointsTypeId: $loyaltyPointsTypeId
    applyTo: $applyTo
    productName: $productName
    points: $points
    minimumPurchaseAmount: $minimumPurchaseAmount
    duration: $duration
  ) {
     data {
  userBusinessId
  discountValue
  maximumDiscount
  minimumCartValue
  buyQuantity  
  getQuantity 
  buyProductName
  getProductName
  applicableFor
  buttonName 
  promotionCategoryId
  applyTo
  points
  loyaltyPointsTypeId
  productName 
  minimumPurchaseAmount 
  duration
  image
  htmlTemplate
  userId 
  isActive
    }

    message
    status
  }
}
`;

const getLoyaltyPointsType = `
query{
  getLoyaltyPointsType{
    message
    status
    data{
      id
      isActive
      default
      type
    }
  }
}
`;

const getPromotionLoyaltyDurationType = `
query{
  getPromotionLoyaltyDurationType{
    message
    status
    data{
      key
      type
    }
  }
}
`;

const getRedeemPromotionsList = `
query ($pageNumber: Int, $pageSize: Int) {
  getRedeemPromotionsList(pageNumber: $pageNumber, pageSize: $pageSize) {
    data {
      cts
      htmlTemplate
      id
      image
      isActive
      points
      productName
      user_businesses{
        id
        name
      }
      userId
    }
    totalCount
    message
    status
  }
}
`;

const createRedeemPromotion = `
mutation createRedeemPromotion($htmlTemplate: String!, $image: Upload, $imageUrl:String, $points: Int!, $productName: String!, $userBusinessId: Int!) {
  createRedeemPromotion(
    htmlTemplate: $htmlTemplate
    image: $image
    imageUrl: $imageUrl
    productName: $productName
    userBusinessId: $userBusinessId
    points:$points
  ) {
    message
    status
  }
}
`;

const updateRedeemPromotion = `
mutation ($redeemPromotionId: Int!, $status: Boolean!){
  updateRedeemPromotion(redeemPromotionId: $redeemPromotionId, status: $status) {
    message
    status
  }
}
`;

const getPromotionApplyTo = `
query{
  getPromotionApplyTo{
    message
    status
    data{
      key
      type
    }
  }
}
`;

const deletePromotion = `
mutation ($promotionInfoId: Int!){
  deletePromotion(promotionInfoId: $promotionInfoId){
    status
    message
  }
}
`;

const promotionQuery = {
  getPromotionSlider,
  updatePromotionSlider,
  addAppPromotionSlider,
  getPackageTypes,
  getPromotions,
  getPromotionApplicableForType,
  getPromotionButtonType,
  getPromotionType,
  getPromotionAndRedeemPreviewImages,
  createPromotion,
  getLoyaltyPointsType,
  getPromotionLoyaltyDurationType,
  getRedeemPromotionsList,
  createRedeemPromotion,
  updateRedeemPromotion,
  getPromotionApplyTo,
  deletePromotion,
};

export default promotionQuery;

import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';
import userData from '../../query/user';
import { handleApiRequest } from '../../common/common';
import TableHeader from '../../../component/table/TableHeader';
import Pagination from '../../../component/table/Pagination';
import UserFilterDialog from './userFilter';

const UserList = () => {
  const { t } = useTranslation();
  const headerData = [
    t('users.UserList.column.srNo'),
    t('users.UserList.column.name'),
    t('users.UserList.column.emailId'),
    t('users.UserList.column.businessCount'),
    t('users.UserList.column.country'),
    t('users.UserList.column.registrationDate'),
    t('users.UserList.column.plan'),
    t('users.UserList.column.subscription'),
    t('users.UserList.column.action'),
  ];

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('all');
  const [actionValue, setActionValue] = useState('action');
  const [orderNow, setOrderNow] = useState('desc');
  const [totalCount, setTotalCount] = useState();
  const [orderBy, setOrderBy] = useState('cts');
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState([]);
  const [filterLength, setFilterLength] = useState([]);
  const [selectedSubcription, setSelectedSubcription] = useState([]);
  const [selectedSubcriptionPeriod, setSelectedSubcriptionPeriod] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAllUsers();
    userStatusType();
  }, []);

  const fetchAllUsers = async () => {
    setLoading(true);
    const payload = {
      query: userData.getAllUsers,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getAllUsers?.status === 200) {
      setData(result?.getAllUsers?.data);
      setTotalCount(result?.getAllUsers?.totalCount);
    }
  };

  const userStatusType = async () => {
    const payload = {
      query: userData.getUserStatusType,
    };
    const result = await handleApiRequest(payload);
    setStatusData(result?.getUserStatusType?.data);
  };

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: userData.getAllUsers,
      variables: {
        searchText: searchValues,
        pageNumber: 1,
        pageSize: rowsPerPage,
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    if (status !== 'all') {
      payload.variables.isActive = Boolean(JSON.parse(status));
    }
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getAllUsers?.status === 200) {
      setData(result?.getAllUsers?.data);
      setTotalCount(result?.getAllUsers?.totalCount);
    }
  };

  const handleSearchEvent = async (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    await setPage(1);
    await getStatusClick(0, searchText);
  };

  const handleActionDropdownChange = (event) => {
    setActionValue(event.target.value);
  };

  const handleStatusDropdownChange = async (event) => {
    const statusValue = event.target.value;
    setStatus(statusValue);
    setLoading(true);
    setPage(1);
    let statusId = {};
    if (statusValue === 'deleted') {
      statusId = {
        userStatusId: [4],
      };
    }
    const payload = {
      query: userData.getAllUsers,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
        ...(statusValue === 'deleted' && statusId),
      },
    };
    if (event?.target?.value !== 'all' && event?.target?.value !== 'deleted') {
      payload.variables.isActive = Boolean(JSON.parse(event.target.value));
    }
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getAllUsers?.status === 200) {
      setData(result?.getAllUsers?.data);
      setTotalCount(result?.getAllUsers?.totalCount);
    }
  };
  const getStatusClick = async (value, clearText, filterCloseClick) => {
    const countryIds = selectedCountries.map((country) => country.id);
    const statusIds = selectedStatus.map((statusVal) => statusVal.id);
    const subscriptionNames = selectedSubcription.map((sub) => sub.subName);
    const subscriptionPeriodNames = selectedSubcriptionPeriod.map((sub) => sub.subPerName);
    setLoadingPage(true);
    const statusId = {
      userStatusId: statusIds,
    };
    const subscriptionConditionName = {
      subscriptionCondition: subscriptionNames,
    };
    const subscriptionPeriodConditionName = {
      subscriptionPeriod: [],
    };
    if (subscriptionPeriodNames.includes('Yearly')) {
      subscriptionPeriodConditionName.subscriptionPeriod.push('year');
    }
    if (subscriptionPeriodNames.includes('Monthly')) {
      subscriptionPeriodConditionName.subscriptionPeriod.push('month');
    }
    const countryData = {
      countryId: countryIds,
    };
    let payload = {};
    if (!filterCloseClick) {
      const filterLen =
        selectedCountries.length +
        selectedStatus.length +
        selectedSubcription.length +
        selectedSubcriptionPeriod.length;
      setFilterLength(filterLen);
      payload = {
        query: userData.getAllUsers,
        variables: {
          searchText: clearText,
          pageNumber: value + 1,
          pageSize: rowsPerPage,
          sortBy: orderBy,
          sortOrder: orderNow,
          ...(selectedCountries.length > 0 && countryData),
          ...(selectedStatus.length > 0 && statusId),
          ...(selectedSubcription.length > 0 && subscriptionConditionName),
          ...(selectedSubcriptionPeriod.length > 0 && subscriptionPeriodConditionName),
        },
      };
    } else {
      payload = {
        query: userData.getAllUsers,
        variables: {
          searchText: clearText,
          pageNumber: value + 1,
          pageSize: rowsPerPage,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
    }
    const result = await handleApiRequest(payload);
    setLoadingPage(false);
    if (result?.getAllUsers?.status === 200) {
      setData(result?.getAllUsers?.data);
      setTotalCount(result?.getAllUsers?.totalCount);
    }
  };
  const filterListCloseClick = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    if (filterLength?.length === 0) {
      setLoadingPage(true);
      let statusId = {};
      if (status === 'deleted') {
        statusId = {
          userStatusId: [4],
        };
      }
      const payload = {
        query: userData.getAllUsers,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: value + 1,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
          ...(status === 'deleted' && statusId),
        },
      };
      if (status !== 'all' && status !== 'deleted') {
        payload.variables.isActive = Boolean(JSON.parse(status));
      }
      const result = await handleApiRequest(payload);
      setLoadingPage(false);
      if (result?.getAllUsers?.status === 200) {
        setData(result?.getAllUsers?.data);
        setTotalCount(result?.getAllUsers?.totalCount);
      }
    } else {
      getStatusClick(value, searchValue);
    }
  };
  const filterListClick = () => {
    setOpen(!open);
    setFilterOpen(!filterOpen);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
    setOpen(false);
    setFilterLength([]);
    setSelectedCountries([]);
    setSelectedSubcription([]);
    setSelectedSubcriptionPeriod([]);
    setSelectedStatus([]);
    fetchAllUsers();
    getStatusClick(0, searchValue, true);
    setPage(1);
  };

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await getStatusClick(0, '');
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: userData.getAllUsers,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (status !== 'all') {
      payload.variables.isActive = Boolean(JSON.parse(status));
    }
    const result = await handleApiRequest(payload);
    setLoadingPage(false);
    if (result?.getAllUsers?.status === 200) {
      setData(result?.getAllUsers?.data);
      setTotalCount(result?.getAllUsers?.totalCount);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  const redirectUserRoutes = (id) => {
    return history.push({
      pathname: `/user-details/${id}`,
    });
  };
  return (
    <div className="p-20 lg:p-40">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('users.UserList.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('users.UserList.searchUserName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end">
          {filterLength >= 1 ? (
            <Button
              endIcon={<FilterListIcon onClick={() => filterListCloseClick()} />}
              variant="contained"
              color="secondary"
              className="text-16 w-full min-w-192 hover:bg-darkgreen justify-between px-20"
            >
              {t('users.UserList.Filter.mainBtn')}
              <Button className="bg-white text-darkgreen hover:bg-white justify-between px-10 min-h-auto h-auto t-0 py-3.5">
                {filterLength}
                <CloseIcon onClick={handleFilterClose} />
              </Button>
            </Button>
          ) : (
            <Button
              className="text-16 w-full min-w-192 justify-between px-20"
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => filterListClick()}
            >
              {t('users.UserList.Filter.mainBtn')}{' '}
              <Icon onClick={() => filterListClick()} className="text-20 text-black ">
                filter_list
              </Icon>
            </Button>
          )}

          {open && (
            <UserFilterDialog
              setSelectedStatus={setSelectedStatus}
              setStatusData={setStatusData}
              statusData={statusData}
              selectedStatus={selectedStatus}
              setFilterOpen={setFilterOpen}
              filterOpen={filterOpen}
              setOpen={setOpen}
              open={open}
              getStatusClick={getStatusClick}
              setSelectedCountries={setSelectedCountries}
              selectedCountries={selectedCountries}
              filterLength={filterLength}
              setSelectedPlanType={setSelectedPlanType}
              selectedPlanType={selectedPlanType}
              setSelectedSubcription={setSelectedSubcription}
              selectedSubcription={selectedSubcription}
              selectedSubcriptionPeriod={selectedSubcriptionPeriod}
              setSelectedSubcriptionPeriod={setSelectedSubcriptionPeriod}
              fetchAllUsers={fetchAllUsers}
              setFilterLength={setFilterLength}
              searchValue={searchValue}
            />
          )}
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {data?.length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHeader headerData={headerData} />
                <TableBody>
                  {data?.length > 0 &&
                    data?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.firstName} {row?.lastName}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold max-w-256 break-all"
                            align="left"
                          >
                            {row.email}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.businessCount}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.countries?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.user_package_subscription?.map((packages, i) => (
                              <span className="block" key={i}>
                                {packages?.packages?.name}
                              </span>
                            ))}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.user_package_subscription?.map((packages, i) => (
                              <span className="block" key={i}>
                                {packages?.isFreeTrial ? (
                                  t('users.UserList.package.free')
                                ) : (
                                  <span>
                                    {moment(packages.expireDate).isBefore(moment())
                                      ? t('users.UserList.package.expired')
                                      : t('users.UserList.package.paid')}
                                  </span>
                                )}
                              </span>
                            ))}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value={actionValue}
                              fullWidth
                              onChange={handleActionDropdownChange}
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('menuItems.action')}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => redirectUserRoutes(row.id)}
                              >
                                {t('menuItems.viewDetails')}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('users.UserList.messages.noUsers')}
            </Typography>
          </motion.div>
        )}
      </div>
      {data?.length > 0 && (
        <Pagination
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default UserList;

import ManageReviewSite from './ManageReviewSite';

const ManageReviewSiteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'manageReviewSite/:id?',
      element: <ManageReviewSite />,
    },
  ],
};

export default ManageReviewSiteConfig;

import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Input,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import history from '@history';
import TableHeader from 'src/app/component/table/TableHeader';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import adminData from '../../query/admin';
import { handleApiRequest } from '../../common/common';
import Pagination from '../../../component/table/Pagination';
import DeletePopUp from '../../business/manage-business/PopUp';
import Loading from '../../business/manage-business/Loading';

const Partners = () => {
  const headerData = [
    t('partners.column.srNo'),
    t('partners.column.fullName'),
    t('partners.column.agencyName'),
    t('partners.column.agencyUrl'),
    t('partners.column.phoneNo'),
    t('partners.column.action'),
  ];
  const [searchValue, setSearchValue] = useState();
  const [tabValue, setTabValue] = useState(2);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [partnersData, setPartnersData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState();
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [userStatusId, setUserStatusId] = useState();
  const [userId, setUserId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const fetchPartners = useCallback(async () => {
    if (!searchValue) {
      setPageLoading(true);
    }
    const payload = {
      query: adminData.getPartnerRequest,
      variables: {
        userStatusId: parseInt(params?.id, 10),
        searchText: searchValue,
        pageSize: rowsPerPage,
        pageNumber: 1,
      },
    };
    const result = await handleApiRequest(payload);
    setPageLoading(false);
    setLoading(false);
    if (result?.getPartnerRequest?.status === 200) {
      setPartnersData(result?.getPartnerRequest?.data);
      setTotalCount(result?.getPartnerRequest?.totalCount);
    } else {
      enqueueSnackbar(result?.getPartnerRequest?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [searchValue, rowsPerPage, params?.id, enqueueSnackbar]);

  useEffect(() => {
    if (params?.id) {
      setTabValue(parseInt(params?.id, 10));
      fetchPartners();
    }
  }, [fetchPartners, params?.id]);

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    const payload = {
      query: adminData.getPartnerRequest,
      variables: {
        userStatusId: tabValue,
        pageSize: rowsPerPage,
        pageNumber: value + 1,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getPartnerRequest?.status === 200) {
      setPartnersData(result?.getPartnerRequest?.data);
      setTotalCount(result?.getPartnerRequest?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleSearch = async (event) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const agentAction = async (value, id) => {
    if (parseInt(value, 10) === 2) {
      history.push(`partner/${id}?status=${tabValue}`);
    } else if (parseInt(value, 10) === 4) {
      setOpenDeleteDialogue(true);
      setUserStatusId(value, id);
      setUserId(id, id);
    } else if (parseInt(value, 10) === 3) {
      const payload = {
        query: adminData.updateAgentByAdmin,
        variables: {
          userId: parseInt(id, 10),
          userStatusId: 1,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateAgentByAdmin?.status === 200) {
        enqueueSnackbar(result?.updateAgentByAdmin?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        fetchPartners();
      } else {
        enqueueSnackbar(result?.updateAgentByAdmin?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const deleteAgent = async () => {
    const payload = {
      query: adminData.updateAgentByAdmin,
      variables: {
        userId: parseInt(userId, 10),
        userStatusId: parseInt(userStatusId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.updateAgentByAdmin?.status === 200) {
      setOpenDeleteDialogue(false);
      fetchPartners();
    } else {
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const redirect = async (id) => {
    history.push(`partner/${id}?status=${tabValue}`);
  };

  const cancelDeleteAgent = () => {
    setOpenDeleteDialogue(false);
    setUserStatusId();
    setUserId();
  };

  const handleTabValue = (value) => {
    setTabValue(value);
    history.push(`partners/${value}`);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40">
      <div className="sm:flex flex-1 w-full items-center justify-between mb-36">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
        >
          {t('partners.title')}
        </Typography>
        <Paper
          component={motion.div}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          className="flex items-center w-full max-w-384 px-8 rounded-8 shadow-none"
        >
          <Input
            placeholder={t('partners.searchPeople')}
            className="flex flex-1 mx-8 text-black text-16"
            disableUnderline
            name="searchText"
            inputProps={{
              'aria-label': 'Search',
            }}
            onChange={handleSearch}
            value={searchValue}
          />
          {searchValue ? (
            <Icon color="action" className="cursor-pointer" onClick={() => setSearchValue('')}>
              close
            </Icon>
          ) : (
            <Icon color="action">search</Icon>
          )}
        </Paper>
        <div className="items-center justify-end" />
      </div>
      <div className="border-b-1 bg-white px-20 pt-6 border-none rounded-md border-solid border-grey mb-20 flex justify-between items-center">
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => handleTabValue(newValue)}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="sm:text-18 font-semibold text-black p-0 sm:mr-28 mr-14"
            value={2}
            label={`${t('partners.tabs.newRequest')} ${
              partnersData?.length > 0 && tabValue === 2 ? `(${partnersData.length})` : ''
            }`}
          />
          <Tab
            className="sm:text-18 font-semibold text-black p-0 sm:mr-28 mr-14"
            value={1}
            label={t('partners.tabs.partners')}
          />
          <Tab
            className="sm:text-18 font-semibold text-black p-0 sm:mr-28 mr-14"
            value={5}
            label={t('partners.tabs.rejected')}
          />
          <Tab
            className="sm:text-18 font-semibold text-black p-0 sm:mr-28 mr-14"
            value={4}
            label={t('partners.tabs.deleted')}
          />
        </Tabs>
      </div>
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <>
            {partnersData?.length > 0 ? (
              <div className="bg-white rounded-md mb-16">
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                    <TableHeader headerData={headerData} />
                    <TableBody>
                      {partnersData?.map((item, i) => {
                        return (
                          <TableRow
                            hover
                            className="border border-t-2 border-t-grey-100"
                            role="checkbox"
                            tabIndex={-1}
                            key={i}
                          >
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {(page - 1) * rowsPerPage + i + 1}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {item?.fullName &&
                                item.fullName.charAt(0).toUpperCase() + item.fullName.slice(1)}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {item?.agencyName &&
                                item.agencyName.charAt(0).toUpperCase() + item.agencyName.slice(1)}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {item?.agencyUrl || '-'}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {item?.phoneNumber?.phoneNumber
                                ? `${item?.phoneNumber?.countryCode} ${item?.phoneNumber?.phoneNumber}`
                                : '-'}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {tabValue !== 4 ? (
                                <Select
                                  value="1"
                                  className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                  sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
                                  variant="filled"
                                  size="small"
                                  onChange={(e) => agentAction(e.target.value, item?.id)}
                                >
                                  <MenuItem value="1" disabled>
                                    {t('partners.action.action')}
                                  </MenuItem>
                                  <MenuItem
                                    className="hover:bg-darkgreen-100 hover:text-darkgreen"
                                    value="2"
                                  >
                                    {t('partners.action.viewDetails')}
                                  </MenuItem>
                                  {tabValue === 2 && (
                                    <MenuItem
                                      className="hover:bg-darkgreen-100 hover:text-darkgreen"
                                      value="3"
                                    >
                                      {t('partners.action.accept')}
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    className="hover:bg-darkgreen-100 hover:text-darkgreen"
                                    value="4"
                                  >
                                    {t('partners.action.delete')}
                                  </MenuItem>
                                </Select>
                              ) : (
                                <Button
                                  className="bg-darkgreen-100 hover:bg-darkgreen-100 rounded-md text-darkgreen px-16 font-semibold"
                                  onClick={() => redirect(item?.id)}
                                >
                                  {t('partners.action.viewDetails')}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div className="text-center">
                {!searchValue ? (
                  <>
                    <img
                      className="m-auto mt-160 w-192"
                      src="/assets/images/business/partners.svg"
                      alt=""
                    />
                    {parseInt(params?.id, 10) === 2 && (
                      <>
                        <Typography className="font-semibold text-24 my-24">
                          {t('partners.noNewPartners')}
                        </Typography>
                        <Typography className="sm:max-w-640 m-auto text-16">
                          {t('partners.newRequestMessage')}
                        </Typography>
                      </>
                    )}
                    {parseInt(params?.id, 10) === 1 && (
                      <>
                        <Typography className="font-semibold text-24 my-24">
                          {t('partners.noPartner')}
                        </Typography>
                        <Typography className="sm:max-w-640 m-auto text-16">
                          {t('partners.noPartnerMessage')}
                        </Typography>
                      </>
                    )}
                    {parseInt(params?.id, 10) === 5 && (
                      <>
                        <Typography className="font-semibold text-24 my-24">
                          {t('partners.noReject')}
                        </Typography>
                        <Typography className="sm:max-w-640 m-auto text-16">
                          {t('partners.noRejectMessage')}
                        </Typography>
                      </>
                    )}
                    {parseInt(params?.id, 10) === 4 && (
                      <>
                        <Typography className="font-semibold text-24 my-24">
                          {t('partners.noDelete')}
                        </Typography>
                        <Typography className="sm:max-w-640 m-auto text-16">
                          {t('partners.noDeleteMessage')}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <Typography className="text-16 font-semibold">{t('partners.noData')}</Typography>
                )}
              </div>
            )}
          </>
          {partnersData?.length > 0 && (
            <Pagination
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      <DeletePopUp
        openValue={openDeleteDialogue}
        eventCall={deleteAgent}
        cancelCall={cancelDeleteAgent}
        popUpMessage={t('partners.areYouSure')}
        popUpDescription={t('partners.deleteWarnMessage')}
        popUpButtonName={t('partners.confirm')}
      />
    </div>
  );
};

export default Partners;

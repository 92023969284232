const getDemoBusinessWithDemoUserDetail = `
query GetDemoBusinessWithDemoUserDetail(
  $businessId: Int
  $pageNumber: Int
  $pageSize: Int
  $searchText: String
  $status: String
  $businessType: String
  $sortBy: String
  $sortOrder: OrderDirection
  $isVisited: Boolean
  $isBookmark: Boolean
  $isDeactivated: Boolean
) {
  getDemoBusinessWithDemoUserDetail(
    businessId: $businessId
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchText: $searchText
    status: $status
    businessType: $businessType
    sortBy: $sortBy
    sortOrder: $sortOrder
    isVisited: $isVisited
    isBookmark: $isBookmark
    isDeactivated: $isDeactivated
  ) {
    total_count
    data {
      QRUrl
      id
      address
      isFreeTrial
      salesPersonVisited
      businessQRId
      validity
      cts
      isBookmark
      countries {
        id
        name
      }
      email
      name
      googlePhoneNumber{
        isoCode
        countryCode
        phoneNumber
       }
      user_business_review_platforms{
        user_businesses_status{
          id
          status
        }
      }
      business_types{
        bType
      }
      users {
        id
        fullName
        email
        userName
        userStatusId
        phoneNumber{
          isoCode
          phoneNumber
          countryCode
        }
        user_package_subscription {
          startDate
          expireDate
          packages{
            package_types{
              name
              id
            }
          }
        }
      }
      phoneNumber {
        countryCode
        isoCode
        phoneNumber
      }
      states {
        id
        name
      }
      review_platforms{
          id
          name
          logo
      }
      city
      zipCode 
    }
    message
    status
  }
}

`;

const convertDemoToRealUser = `
mutation($phoneNumber: PhoneNumberObjectV1!, $firstName: String!, $lastName: String!, $userId: Int!){
  convertDemoToRealUser(phoneNumber: $phoneNumber, firstName: $firstName, lastName: $lastName, userId: $userId){ 
      message
      status
     }
   }
`;

const otpVerifyByDemoUserForAccountConvertToRealUser = `
mutation($data: String!){
  otpVerifyByDemoUserForAccountConvertToRealUser(data: $data){ 
      message
      status
     }
   }
`;

const updateDemoAccountBySalesUser = `
mutation( $data: String!){
  updateDemoAccountBySalesUser(data: $data) { 
    message
    status 
  }
}
`;

const getSalesPersonVisitedStatus = `
query{
  getSalesPersonVisitedStatus{ 
      data{
        id
        type
      }
      id
      message
      status
     }
   }
`;

const getDemoUserAccountSetting = `
query($userId: Int!){
  getDemoUserAccountSetting(userId: $userId){ 
      data{
        activatePremiumAccount
        convertedRealAccount
        salesPersonVisited
      }
      id
      message
      status
     }
   }
`;

const visitedBusinessAndFeedback = `
mutation($businessId: Int!, $businessesOwnerFeedbackId: Int, $feedback: String!, $followUpDate: FollowUpSchedule $salesPersonResponeId: Int!, $visited: Boolean!, $phoneNumber: Float, $countryCode: String){
  visitedBusinessAndFeedback(businessId: $businessId, businessesOwnerFeedbackId: $businessesOwnerFeedbackId, followUpDate:$followUpDate feedback: $feedback, salesPersonResponeId: $salesPersonResponeId, visited: $visited, phoneNumber:$phoneNumber, countryCode:$countryCode){ 
      message
      status
     }
   }
`;

const getBusinessesOwnerFeedbackOption = `
query{
  getBusinessesOwnerFeedbackOption{ 
      data{
        id
        type
      }
      message
      status
     }
   }
`;
const getUserBusinessStatus = `
query {
  getUserBusinessStatus {
    data {
      id
      status
    }
    message
    status
  }
}
`;

const fetchDemoBusinessData = `
mutation($accessToken: String!,$businessId: Int!) {
  fetchDemoBusinessData(accessToken: $accessToken, businessId: $businessId ) {
    message
    status
  }
}
`;

const addRemoveBookmark = `
mutation addRemoveBookmark(
  $businessId: Int!
  $isBookmark: Boolean!
) {
  addRemoveBookmark(
    businessId: $businessId
    isBookmark: $isBookmark
  ) {
    message
    status
  }
}
`;

const convertedAccountExtendExpireDate = `
mutation($extendDate: Int!,$userId: Int!) {
  convertedAccountExtendExpireDate(extendDate: $extendDate, userId: $userId ) {
    message
    status
  }
}
`;

const createQRCodePdfWithInfo = `
query($businessQRId: String!) {
  createQRCodePdfWithInfo(businessQRId: $businessQRId ) {
    message
    status
    data{
      pdf
    }
  }
}
`;

const getSalesPersonBusinessType = `
query {
  getSalesPersonBusinessType{
    message
    status
    data{
      id
      bType
    }
  }
}
`;

const extendDemoAccountValidity = `
mutation($extendDay: Int!, $userId: Int!) {
  extendDemoAccountValidity(extendDay: $extendDay, userId: $userId) {
    message
    status
  }
}
`;

const getDemoBusinessFollowUpData = `
query ($businessId: Int!) {
  getDemoBusinessFollowUpData(businessId: $businessId) {
    status
    message
    data {
      cts
      followUpDate
      note
      businessId
    }
  }
}
`;

const setNewFollowUpBySalesPerson = `
mutation ($businessId: Int!, $followUpDate: FollowUpSchedule!, $note: String){
  setNewFollowUpBySalesPerson(
    businessId: $businessId,
    followUpDate: $followUpDate,
    note: $note
  ) {
    status
    message
  }
}
`;

const getSalesPersonsInsights = `
query ($businessId: Int!){
  getSalesPersonsInsights(businessId:$businessId){
    status
    message
    data {
      id
      feedback
      phoneNumber
      countryCode
    }
  }
}
`;

const updateSalesPersonsInsights = `
mutation ($salesPersonsInsightsId: Int!, $feedback: String, $phoneNumber: Float, $countryCode: String){
  updateSalesPersonsInsights(salesPersonsInsightsId:$salesPersonsInsightsId, feedback:$feedback,phoneNumber :$phoneNumber , countryCode:$countryCode
   ){
    status
    message
  }
}
`;

const businessPerformanceReports = `
query ($userBusinessId: Int!, $month: Int, $year: Int) {
  businessPerformanceReports(userBusinessId: $userBusinessId, month: $month, year: $year) {
    message
    status
    data {
      monthlyBusinessReport {
        timestamp
        url
      }
      overallBusinessReport {
        timestamp
        url
      }
    }
    id
  }
}
`;

const selectedBusinessData = {
  getDemoBusinessWithDemoUserDetail,
  convertDemoToRealUser,
  otpVerifyByDemoUserForAccountConvertToRealUser,
  updateDemoAccountBySalesUser,
  getSalesPersonVisitedStatus,
  getDemoUserAccountSetting,
  visitedBusinessAndFeedback,
  getBusinessesOwnerFeedbackOption,
  getUserBusinessStatus,
  fetchDemoBusinessData,
  addRemoveBookmark,
  convertedAccountExtendExpireDate,
  createQRCodePdfWithInfo,
  getSalesPersonBusinessType,
  extendDemoAccountValidity,
  getDemoBusinessFollowUpData,
  setNewFollowUpBySalesPerson,
  getSalesPersonsInsights,
  updateSalesPersonsInsights,
  businessPerformanceReports,
};

export default selectedBusinessData;

import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useDispatch } from 'react-redux';
import promotionQuery from '../../../query/promotion';
import { getPromotionType } from '../store/promotionSlice';

const SelectPromotionType = (props) => {
  const { selectPromotionTypeId, setSelectPromotionTypeId } = props;
  const dispatch = useDispatch();
  const [promotionTypeData, setPromotionTypeData] = useState([]);

  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionType,
      };
      const result = await dispatch(getPromotionType(payload));
      setPromotionTypeData(result?.payload?.getPromotionType?.data);
    };
    fetchPromotionType();
  }, [dispatch]);

  return (
    <div className="bg-white rounded-md relative rounded-lg shadow p-20 mt-24">
      <div className="border-b-2 border-solid border-black border-dotted">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-20 pb-16 font-medium"
        >
          {t('promotion.selectPromotionType')}
        </Typography>
      </div>
      <Typography className="mt-12">{t('promotion.promotionMessage')}</Typography>
      <div className="sm:flex mt-16 gap-16 grid grid-cols-2">
        {promotionTypeData?.length > 0 &&
          promotionTypeData
            ?.sort((a, b) => a.id - b.id)
            .map((item, index) => {
              return (
                <div>
                  <div
                    role="button"
                    tabIndex={0}
                    className="border relative rounded md:px-56 py-16 max-w-96 mx-auto md:max-w-none"
                    onClick={() => setSelectPromotionTypeId(item.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setSelectPromotionTypeId(item.id);
                      }
                    }}
                  >
                    {selectPromotionTypeId === item.id && (
                      <div className="border absolute right-10 top-4 w-[20px] flex items-center text-center bg-darkgreen rounded-3xl h-[20px]">
                        <CheckOutlinedIcon className="w-[16px] h-[16px] text-white" />
                      </div>
                    )}
                    <img className="mx-auto" src={item.icon} alt="" />
                  </div>
                  <Typography
                    className={`text-center ${
                      selectPromotionTypeId === item.id ? 'text-darkgreen' : 'text-black'
                    }`}
                  >
                    {item.type}
                  </Typography>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default SelectPromotionType;

import { Button, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import selectedBusinessData from '../../../query/selectedBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const QrCode = () => {
  const [businessQrImg, setBusinessQrImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  useEffect(() => {
    const fetchQrCode = async () => {
      setLoading(true);
      const payload = {
        query: selectedBusinessData.createQRCodePdfWithInfo,
        variables: {
          businessQRId: userCurrentBusinessDetails?.businessQRId?.split('/').pop(),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.createQRCodePdfWithInfo?.status === 200) {
        setBusinessQrImg(result?.createQRCodePdfWithInfo?.data.pdf);
      } else {
        enqueueSnackbar(result?.createQRCodePdfWithInfo?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    if (userCurrentBusinessDetails?.businessQRId?.split('/').pop()) {
      fetchQrCode();
    }
  }, [userCurrentBusinessDetails?.businessQRId, enqueueSnackbar]);

  const handleDownload = () => {
    window.open(businessQrImg, '_blank');
  };

  const orderRedirect = () => {
    const url = `/qrcode-orders/${userCurrentBusinessDetails?.id}`;
    window.open(url, '_blank');
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 h-max lg:p-40">
      <div className="flex w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('qrCode.title')}
          </Typography>
        </div>
        <div className="text-center">
          <Button
            className="bg-transparent rounded md:px-8 text-darkgreen disabled:bg-gray disabled:border-gray disabled:text-black border border-solid border-darkgreen hover:bg-transparent mx-10 md:mx-0"
            type="button"
            disabled={!userCurrentBusinessDetails?.id || !businessQrImg}
            onClick={() => handleDownload()}
          >
            <FileDownloadOutlinedIcon className="mr-4 text-24" />
            {t('qrCode.button.downloadQrCode')}
          </Button>
        </div>
      </div>
      <div className="bg-white py-40">
        <div className="bg-cover w-288 sm:w-384 h-586 m-auto bg-no-repeat bg-[url('/public/assets/images/business/Mask.png')]">
          <div className="text-center">
            <img className="pt-40 m-auto" src="/assets/images/business/logo.svg" alt="PDF Page" />
            <Typography className="text-gray-A600 text-20 pt-14 tracking-widest font-medium">
              {t('qrCode.reviewMessage')} <br /> {t('qrCode.us')}{' '}
              <span className="font-bold text-white text-24">{t('qrCode.review')}</span>
            </Typography>
            <div className="text-center m-auto bg-white max-w-160 p-14 rounded-md my-12">
              <QRCode value={userCurrentBusinessDetails?.businessQRId} />
            </div>
            <div className="flex gap-8 pt-16 pb-14 justify-center">
              {[...Array(5)].map((_, i) => (
                <img className="w-20" src="/assets/images/icon/simple-star.svg" alt="" />
              ))}
            </div>
            <Typography className="text-white font-bold text-20">
              {userCurrentBusinessDetails?.name}
            </Typography>
            <Typography className="text-black font-semibold text-16 py-48 pb-48">
              Effortlessly manage your review <br /> with quick reviews.
            </Typography>
          </div>
        </div>

        <div className="md:flex justify-center mt-36 gap-24 text-center ">
          <Button
            className="bg-darkgreen rounded md:px-28 disabled:bg-gray disabled:text-black text-white hover:bg-darkgreen mx-10 md:mx-0 hidden"
            disabled={!userCurrentBusinessDetails?.id}
            onClick={() => orderRedirect()}
          >
            <ShoppingBagOutlinedIcon className="mr-4 text-20" />
            {t('qrCode.button.orderQrCode')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QrCode;

const getQuickChatDefaultAvatar = ` 
query{
	getQuickChatDefaultAvatar {
          message
          status
          data{
            id
            cts
            avatar
            isActive
          }      
	}
  }  
`;

const checkQuickChatDomain = ` 
query($domain: String!){
	checkQuickChatDomain(domain: $domain) {
          message
          status
          data{
            domainAvailable
          }      
	}
  }  
`;

const createQuickChatAndUpdate = ` 
mutation($avatar: String, $avatarImageFile: Upload, $domain: String!, $headerColor: String!, $name: String!, $userBusinessId: Int!){
	createQuickChatAndUpdate(avatar: $avatar, avatarImageFile: $avatarImageFile, domain: $domain, headerColor: $headerColor, name: $name, userBusinessId: $userBusinessId) {
          message
          status  
	}
  }  
`;

const getQuickChat = ` 
query($businessId: Int, $domain: String){
	getQuickChat(businessId: $businessId, domain: $domain) {
          message
          status 
          data{
            avatar
            domain
            headerColor
            id
            isActive
            name
            userBusinessesId
            quickChatSuggestion
          }
	}
  }  
`;

const quickChatQuery = {
  getQuickChatDefaultAvatar,
  checkQuickChatDomain,
  createQuickChatAndUpdate,
  getQuickChat,
};

export default quickChatQuery;

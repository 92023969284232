import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`negativeReviews/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getAllNegativeReview = createAsyncThunkWithAuthorization(
  'getAllNegativeReview',
  baseURL
);
export const negativeReviewStatus = createAsyncThunkWithAuthorization(
  'negativeReviewStatus',
  baseURL
);

export const deleteNegativeReview = createAsyncThunkWithAuthorization(
  'deleteNegativeReview',
  baseURL
);

const negativeReviewsSlice = createSlice({
  name: 'negativeReviews',
  initialState: {},
  reducers: {
    reSetNegativeReviews: (state, action) => {
      if (state) {
        state.getAllNegativeReviewData = {};
        state.negativeReviewStatusData = {};
        state.deleteNegativeReviewData = {};
      }
    },
  },
  extraReducers: {
    [getAllNegativeReview.fulfilled]: (state, action) => ({
      ...state,
      getAllNegativeReviewData: action.payload,
    }),
    [negativeReviewStatus.fulfilled]: (state, action) => ({
      ...state,
      negativeReviewStatusData: action.payload,
    }),
    [deleteNegativeReview.fulfilled]: (state, action) => ({
      ...state,
      deleteNegativeReviewData: action.payload,
    }),
  },
});

export const { reSetNegativeReviews } = negativeReviewsSlice.actions;

export default negativeReviewsSlice.reducer;

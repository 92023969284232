import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import { Typography } from '@mui/material';
import metricsData from '../../query/metrics';
import { getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function CustomerSatisfactionScore(props) {
  const { reviewPlatformId, placeId } = props;
  const { t } = useTranslation();

  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getCustomerSatisfactionScoreV1,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await getDataToServer(payload);
          if (result?.getCustomerSatisfactionScoreV1?.data) {
            setNewGraphData([result?.getCustomerSatisfactionScoreV1?.data?.score]);
          } else {
            setNewGraphData('');
          }
        } else if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setNewGraphData('');
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function getChartSimple(data, index, color, types) {
    const chart = {
      series: data,
      options: {
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: '40px',
                fontWeight: 'bold',
                color: '#000',
                offsetY: -10,
                formatter(val) {
                  return `${val}%`;
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          colors: color,
        },
      },
    };
    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        {chart?.plotOptions?.total?.formatter}
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="450px"
          width="100%"
        />
      </div>
    );
  }
  return (
    <>
      <div>
        {newGraphData && newGraphData?.length > 0 ? (
          <>
            {getChartSimple(newGraphData, 4, ['#67df9d'], 'radialBar')}
            <Typography className="font-bold text-16 max-w-256 text-center mx-auto mb-0 mt-40">
              {newGraphData}% {t('metrics.customerSatisfactionScoreDesc')}
            </Typography>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}

export default CustomerSatisfactionScore;

import history from '@history';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { getEncryptedData } from '../common/common';

const SignInDeepLink = () => {
  const params = useParams();
  const paramsData = params?.token?.replace('token=', '');

  useEffect(() => {
    const isQuickreviewsInstalled = () => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

      if (isMobile) {
        const appUrl = `whatsapplink://quickreviewsWhatsappSuccess/${paramsData}`;
        const webUrl = `${process.env.REACT_APP_PORTAL_URL}/whatsappSignIn?token=${paramsData}`;
        const startTime = Date.now();
        const timeout = 2000;
        const link = document.createElement('a');
        link.href = appUrl;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          if (Date.now() - startTime < timeout + 500) {
            window.location.href = webUrl;
          }
          document.body.removeChild(link);
        }, timeout);
      } else {
        history.push(`${process.env.REACT_APP_PORTAL_URL}/whatsappSignIn?token=${paramsData}`);
      }
    };

    isQuickreviewsInstalled();
  }, [paramsData]);

  return <FuseLoading />;
};

export default SignInDeepLink;

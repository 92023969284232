import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import withReducer from 'app/store/withReducer';
import { useSnackbar } from 'notistack';
import jwtDecode from 'jwt-decode';
import { setUserData } from 'app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '../../../@history/@history';
import reducer from '../auth/store';
import Query from '../query/auth';
import userData from '../query/user';
import { handleApiRequest, handleAuthApi } from '../common/common';
import userService from '../../services/userService';

function SignInTokenPage() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [fetchStatus, setFetchStatus] = useState(true);

  const clearAllData = useCallback(async () => {
    if (fetchStatus) {
      localStorage.clear();
      history.push('/sign-in');
      history.go(0);
      enqueueSnackbar('Invalid token', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [enqueueSnackbar, fetchStatus]);

  const fetchUserDetails = useCallback(async () => {
    try {
      if (routeParams?.token) {
        const accessToken = routeParams.token;
        const decoded = jwtDecode(accessToken);
        if (decoded?.userType !== 'business_user') {
          setFetchStatus(true);
          await clearAllData();
          return;
        }
        const payload = {
          query: Query.verifyToken,
          variables: { token: accessToken },
        };
        const verifyData = await handleAuthApi(payload);
        if (verifyData?.verifyToken?.status === 200) {
          const userPayload = {
            query: userData.getUserDetails,
            variables: {
              userId: parseInt(decoded?.id, 10),
            },
          };
          const verifyUserData = await handleApiRequest(userPayload, accessToken);
          const userDetails = verifyUserData?.getUserDetails?.data;
          const userPayloadData = {
            id: parseInt(decoded?.id, 10),
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            fullName: userDetails?.fullName,
            deviceId: decoded?.deviceId,
            email: userDetails?.email,
            middleName: userDetails?.middleName,
            address: userDetails?.address,
            address2: userDetails?.address2,
            zipcode: userDetails?.zipcode,
            stateId: userDetails?.states?.id,
            countryId: userDetails?.countries?.id,
            countryCode: userDetails?.phoneNumber?.countryCode,
            currency: userDetails?.currency,
            isoCode: userDetails?.isoCode,
            profileUrl: userDetails?.profileUrl,
            roleId: 2,
            roles: { id: 2, type: decoded?.userType },
            userStatusId: userDetails?.userStatusId,
            privacyPolicyAccept: null,
            packagePlanId: null,
            subUserType: userDetails?.subUserType,
            phoneNumber: { isoCode: null, countryCode: null, phoneNumber: null },
            timeZone: null,
          };
          dispatch(setUserData(userPayloadData));
          userService.setSession(accessToken);
          if (routeParams?.page === 'promotion') {
            history.push('/promotion');
          } else if (routeParams?.businessId) {
            history.push(`/qrcode-orders/${routeParams?.businessId}`);
          } else {
            history.push('/business/dashboard');
          }
        } else {
          setFetchStatus(true);
          await clearAllData();
        }
      }
    } catch (e) {
      setFetchStatus(true);
      await clearAllData();
    }
  }, [clearAllData, dispatch, routeParams]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  if (loading) {
    return <FuseLoading />;
  }
  return '';
}

export default withReducer('signInTokenReducer', reducer)(SignInTokenPage);

import { useTranslation } from 'react-i18next';

function NoData() {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center pt-96 min-h-360">
        <img
          src="/assets/images/business/chart.png"
          className="img-fluid mx-auto max-w-96 mb-28"
          alt="..."
        />
        <h2 className="text-16 font-semibold text-grey-500">{t('metrics.noData')}</h2>
      </div>
    </>
  );
}

export default NoData;

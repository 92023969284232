import SalesPerson from './SalesPerson';
import SalesDetails from './SalesDetails';
import CreateSales from './CreateSales';

const salesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'sales-person',
      element: <SalesPerson />,
    },
    {
      path: 'sales-details/:salesId',
      element: <SalesDetails />,
    },
    {
      path: 'create-sales-account',
      element: <CreateSales />,
    },
  ],
};

export default salesConfig;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export const deleteUserAccount = createAsyncThunk('delete-user/deleteUserAccount', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    const result = await response.data;
    return result;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

const deleteUserSlice = createSlice({
  name: 'delete-user',
  initialState: null,
  reducers: {
    resetdeleteUser: (state, action) => {
      state.deleteUserAccountData = {};
    },
  },
  extraReducers: {
    [deleteUserAccount.fulfilled]: (state, action) => ({
      ...state,
      deleteUserAccountData: action.payload,
    }),
  },
});

export const { resetdeleteUser } = deleteUserSlice.actions;

export default deleteUserSlice.reducer;

import TabletVideos from './TabletVideos';
import TabletVideosDetails from './TabletVideosDetails';

const tabletVideosConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'tablet-videos',
      element: <TabletVideos />,
    },
    {
      path: 'tablet-video-details/:businessTypeId/:businessTypeName',
      element: <TabletVideosDetails />,
    },
  ],
};

export default tabletVideosConfig;

import BusinessProductAndService from './BusinessProductAndService';

const BusinessProductAndServiceConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'businessProductAndService/:id?',
      element: <BusinessProductAndService />,
    },
  ],
};

export default BusinessProductAndServiceConfig;

import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { selectDashboardData } from '../../../store/userSlice';
import metricsData from '../../query/metrics';
import { handleApiRequest } from '../../common/common';
import ReviewSentiment from './reviewSentiment';
import NoData from './noDataScreen';

const NetPromoterScore = (props) => {
  const { reviewSentiment, reviewPlatformId, placeId } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [promoterData, setPromoterData] = useState([]);
  const [npsScore, setNpsScore] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getNetPromoterScoreV1,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewSentimentPeriodId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await handleApiRequest(payload);
          if (result?.getNetPromoterScoreV1?.status === 200) {
            const data = result?.getNetPromoterScoreV1?.data?.scoreV1;
            const removeNps = data?.filter((nps) => nps.key !== 'nps');
            const findNpsScore = data?.find((score) => score.key === 'nps');
            setNpsScore(findNpsScore?.value);
            setPromoterData(removeNps);
            const filterNpsScore = data?.filter((score) => score.key !== 'nps');
            const netPromoterValue = filterNpsScore?.map((item) => item?.value);
            setValues(netPromoterValue);
          }
        }
        if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setNpsScore('');
          setPromoterData([]);
          setValues();
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    selectedReviewSentimentId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  return (
    <div className="">
      <div className="mb-8">
        {!userCurrentBusinessDetails?.isOnlineBusiness && (
          <ReviewSentiment
            reviewSentiment={reviewSentiment}
            sentimentId={[2, 4, 7]}
            onSelectSentiment={setSelectedReviewSentimentId}
          />
        )}
      </div>
      {promoterData?.length > 0 ? (
        <>
          <div className="flex justify-center max-h-208 min-h-208">
            <ReactSpeedometer
              minValue={-100}
              maxValue={100}
              segments={3}
              value={npsScore}
              segmentColors={['#FFA500', '#1E90FF', '#67DF9D']}
              needleColor="black"
              needleTransition="easeElastic"
              textColor="#AAA"
              customSegmentLabels={[
                {
                  text: '-100',
                  position: 'OUTSIDE',
                  color: '#AAA',
                },
                {
                  text: '0',
                  position: 'OUTSIDE',
                  color: '#AAA',
                },
                {
                  text: '100',
                  position: 'OUTSIDE',
                  color: '#AAA',
                },
              ]}
            />
          </div>
          <hr />
          <div className="flex justify-between px-8 mt-8 items-center text-center">
            {promoterData?.length > 0 &&
              promoterData?.map((promoter, index) => {
                return (
                  <div key={index}>
                    <div className="border-l border-solid !border-gray" />
                    <div className="flex items-center justify-center gap-2">
                      <div
                        className={`border-2 w-14 border-[#67DF9D] h-14 ${
                          promoter.key === 'promoters' && 'bg-[#67DF9D] !border-[#67DF9D]'
                        }  ${promoter.key === 'detractors' && 'bg-[#F2B443] !border-[#F2B443]'}  ${
                          promoter.key === 'passives' && 'bg-[#3E8CF3] !border-[#3E8CF3]'
                        } rounded-full`}
                      />
                      <Typography className="font-bold">{promoter?.value}</Typography>
                    </div>
                    <Typography>
                      {promoter.key && promoter.key.charAt(0).toUpperCase() + promoter.key.slice(1)}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default NetPromoterScore;

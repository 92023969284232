import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import adminData from '../../query/admin';
import { handleApiRequest } from '../../common/common';

const AppMaintenance = () => {
  const [isAppMaintenance, setIsAppMaintenance] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fetchIsAppMaintenance = async () => {
    setLoading(true);
    const payload = {
      query: adminData.getAppMaintenanceStatus,
    };
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getAppMaintenanceStatus?.status === 200) {
      setIsAppMaintenance(result.getAppMaintenanceStatus?.data?.isAppUnderMaintenance);
    }
  };

  useEffect(() => {
    fetchIsAppMaintenance();
  }, []);

  const ChangeSwitchValue = async () => {
    setRefreshIcon(true);
    const payload = {
      query: adminData.updateAppMaintenanceStatus,
      variables: {
        isAppUnderMaintenance: !isAppMaintenance,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.updateAppMaintenanceStatus?.status === 200) {
      fetchIsAppMaintenance();
      setIsDialogOpen(false);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40">
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-20 md:text-28 font-bold"
      >
        {t('appMaintenance.title')}
      </Typography>
      <div className="border border-solid bg-white flex justify-between gap-40 items-center lg:gap-120 p-20 !border-gray rounded-md mt-24">
        <Typography className="text-14 md:text-18">
          {t('appMaintenance.maintenanceMessage')}
        </Typography>
        <Switch checked={isAppMaintenance} onChange={() => setIsDialogOpen(true)} />
      </div>
      <Dialog
        classes={{
          paper: 'max-w-240 overflow:scroll p-28 business-type-dropdown',
        }}
        open={isDialogOpen}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-6">
          <Icon
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            onClick={() => setIsDialogOpen(false)}
          >
            {t('appPromotionSlider.cancel')}
          </Icon>
        </DialogTitle>
        <DialogContent className="p-0">
          <Paper
            className="relative w-full h-full rounded-8 shadow-none p-8 text-center"
            role="button"
          >
            <span className="block text-center">
              <img
                src="/assets/images/admin-icon/mobile-settings.png"
                className="mx-auto mb-12"
                width="75"
                height="64"
                alt="..."
              />
            </span>
            <Typography className="text-16 font-bold py-16">
              {t('appMaintenance.enableAppMaintenance')}
            </Typography>
            <Typography>{t('appMaintenance.confirmationMessage')}</Typography>
          </Paper>
        </DialogContent>
        <DialogActions className="p-0 m-auto">
          <Button
            className="bg-darkgreen rounded-lg hover:bg-darkgreen disabled:bg-gray mt-8 px-28 text-white"
            disabled={refreshIcon}
            onClick={() => ChangeSwitchValue()}
          >
            {t('appMaintenance.confirm')}
            {refreshIcon && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppMaintenance;

import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import history from '@history';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './MangeBusiness.css';
import TopHeader from './TopHeader';
import reducer from './store';
import manageBusinessData from '../../query/manageBusiness';
import { getParamsId, handleApiRequest } from '../../common/common';

function SelectBusiness() {
  const { t } = useTranslation();

  const [selectedBusinessType, setSelectedBusinessType] = useState();

  const redirectBusiness = () => {
    localStorage.setItem('activeStepNumber', 1);
    const id = getParamsId();
    if (parseInt(selectedBusinessType, 10) === 1) {
      if (id) {
        history.push(`/manage-business/${id}`);
      } else {
        history.push('/manage-business');
      }
    }
    if (parseInt(selectedBusinessType, 10) === 2) {
      if (id) {
        history.push(`/manage-business-online/${id}`);
      } else {
        history.push('/manage-business-online');
      }
    }
  };

  return (
    <div>
      <TopHeader />
      <div className="text-center max-w-fit mx-auto mt-48 mangeBusinessPage-height flex justify-center items-center container px-16 overflow-hidden">
        <div>
          <h2 className="sm:text-28 text-20 font-semibold mt-24 md:mt-0">
            {t('manageBusiness.selectCategory.title')}
          </h2>
          <Typography className="sm:text-18 font-medium mt-28 mb-24 sm:mb-48 max-w-640 mx-auto text-black">
            {t('manageBusiness.selectCategory.desc')}
          </Typography>
          <FormControl className="block mb-48">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              className="block"
              value={selectedBusinessType}
              onChange={(event) => setSelectedBusinessType(event?.target?.value)}
            >
              <FormControlLabel
                value={1}
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <code className="flex justify-between items-center">
                    <span className="block mx-auto">
                      <img
                        src="https://quick-review.s3.amazonaws.com/business_flow/physical_business.png"
                        alt="..."
                        className="w-full mx-auto"
                      />
                      <span className="text-18 font-semibold inline-block capitalize select-plan-signup mt-20">
                        {t('manageBusiness.selectCategory.physicalBusiness')}
                      </span>
                    </span>
                  </code>
                }
                className={`rounded-md p-10 border-darkgreen m-0 mb-20 relative inline-block border-1 border-solid md:min-w-360 sm:w-auto w-full mr-48 text-center py-20 ${
                  parseInt(selectedBusinessType, 10) === 1
                    ? 'bg-darkgreen-200'
                    : 'bg-white border-grey '
                }`}
              />
              <FormControlLabel
                value={2}
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <code className="flex justify-between items-center">
                    <span className="block mx-auto">
                      <img
                        src="https://quick-review.s3.amazonaws.com/business_flow/online_business.png"
                        alt="..."
                        className="sm:w-full mx-auto img-fluid"
                      />
                      <span className="text-18 font-semibold inline-block capitalize select-plan-signup mt-20">
                        {t('manageBusiness.selectCategory.onlineBusiness')}
                      </span>
                    </span>
                  </code>
                }
                className={`rounded-md p-10 border-darkgreen m-0 mb-20 relative inline-block border-1 border-solid md:min-w-360 sm:w-auto w-full text-center py-20 ${
                  parseInt(selectedBusinessType, 10) === 2
                    ? 'bg-darkgreen-200'
                    : 'bg-white border-grey '
                }`}
              />
            </RadioGroup>
          </FormControl>
          <div className="text-right sm:mb-0 mb-28">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => {
                const id = getParamsId();
                if (id) {
                  history.push(`/business/dashboard/${id}`);
                } else {
                  history.push('/business/dashboard');
                }
              }}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md sm:min-w-160"
              aria-label="confirm"
              disabled={!selectedBusinessType}
              onClick={() => redirectBusiness()}
              type="button"
            >
              {t('manageBusiness.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(SelectBusiness);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const handleApiRequest = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, thunkFn) => {
  return createAsyncThunk(`appWebsite/${name}`, async (data) => {
    return thunkFn(data);
  });
};

export const getDomainRequest = createAsyncThunkWithAuthorization(
  'getDomainRequest',
  handleApiRequest
);
export const getDomainStatus = createAsyncThunkWithAuthorization(
  'getDomainStatus',
  handleApiRequest
);
export const createCloudfrontDistribution = createAsyncThunkWithAuthorization(
  'createCloudfrontDistribution',
  handleApiRequest
);

const appWebsiteSlice = createSlice({
  name: 'appWebsite',
  initialState: {},
  reducers: {
    resetAppWebsite: (state, action) => {
      if (state) {
        state.getDomainRequestData = {};
        state.getDomainStatusData = {};
        state.createCloudfrontDistributionData = {};
      }
    },
  },
  extraReducers: {
    [getDomainRequest.fulfilled]: (state, action) => ({
      ...state,
      getDomainRequestData: action.payload,
    }),
    [getDomainStatus.fulfilled]: (state, action) => ({
      ...state,
      getDomainStatusData: action.payload,
    }),
    [createCloudfrontDistribution.fulfilled]: (state, action) => ({
      ...state,
      createCloudfrontDistributionData: action.payload,
    }),
  },
});

export const { resetAppWebsite } = appWebsiteSlice.actions;

export default appWebsiteSlice.reducer;

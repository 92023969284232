import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export const getBusinessQRDetail = createAsyncThunk(
  'qr-scanner/getBusinessQRDetail',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getAmazonProductDetailsBaseOnId = createAsyncThunk(
  'qr-scanner/getAmazonProductDetailsBaseOnId',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const autoGenerateReview = createAsyncThunk(
  'qr-scanner/autoGenerateReview',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getFlipkartProductDetailsBaseOnId = createAsyncThunk(
  'qr-scanner/getFlipkartProductDetailsBaseOnId',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const isReviewNegative = createAsyncThunk('qr-scanner/isReviewNegative', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    const result = await response.data;
    return result;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const requestCallForNegativeReviews = createAsyncThunk(
  'qr-scanner/requestCallForNegativeReviews',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getBusinessAgentDetailsOnPortalSideUsingBusinessQRId = createAsyncThunk(
  'qr-scanner/getBusinessAgentDetailsOnPortalSideUsingBusinessQRId',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const submitBusinessReview = createAsyncThunk(
  'qr-scanner/submitBusinessReview',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const updateReviewPhoneNumber = createAsyncThunk(
  'qr-scanner/updateReviewPhoneNumber',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);
export const addVisitorCountBasedOnIPAddress = createAsyncThunk(
  'qr-scanner/addVisitorCountBasedOnIPAddress',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getBusinessProductByBusinessId = createAsyncThunk(
  'qr-scanner/getBusinessProductByBusinessId',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getBusinessServicesByBusinessId = createAsyncThunk(
  'qr-scanner/getBusinessServicesByBusinessId',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getAllRemainingCount = createAsyncThunk(
  'qr-scanner/getAllRemainingCount',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getLocation = createAsyncThunk('qr-scanner/getLocation', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

const qrScannerSlice = createSlice({
  name: 'qr-scanner',
  initialState: null,
  reducers: {
    resetqrScanner: (state, action) => {
      state.getBusinessQRDetailData = {};
      state.submitBusinessReviewData = {};
      state.getBusinessProductByBusinessIdValue = {};
      state.getBusinessServicesByBusinessIdValue = {};
      state.getBusinessAgentDetailsOnPortalSideUsingBusinessQRIdData = {};
      state.isReviewNegativeData = {};
      state.requestCallForNegativeReviewsData = {};
      state.getFlipkartProductDetailsBaseOnIdData = {};
      state.getAmazonProductDetailsBaseOnIdData = {};
    },
  },
  extraReducers: {
    [getBusinessQRDetail.fulfilled]: (state, action) => ({
      ...state,
      getBusinessQRDetailData: action.payload,
    }),
    [submitBusinessReview.fulfilled]: (state, action) => ({
      ...state,
      submitBusinessReviewData: action.payload,
    }),
    [updateReviewPhoneNumber.fulfilled]: (state, action) => ({
      ...state,
      updateReviewPhoneNumberData: action.payload,
    }),
    [addVisitorCountBasedOnIPAddress.fulfilled]: (state, action) => ({
      ...state,
      addVisitorCountBasedOnIPAddressData: action.payload,
    }),
    [getBusinessProductByBusinessId.fulfilled]: (state, action) => ({
      ...state,
      getBusinessProductByBusinessIdValue: action.payload,
    }),
    [getBusinessServicesByBusinessId.fulfilled]: (state, action) => ({
      ...state,
      getBusinessServicesByBusinessIdValue: action.payload,
    }),
    [getBusinessAgentDetailsOnPortalSideUsingBusinessQRId.fulfilled]: (state, action) => ({
      ...state,
      getBusinessAgentDetailsOnPortalSideUsingBusinessQRIdData: action.payload,
    }),
    [isReviewNegative.fulfilled]: (state, action) => ({
      ...state,
      isReviewNegativeData: action.payload,
    }),
    [requestCallForNegativeReviews.fulfilled]: (state, action) => ({
      ...state,
      requestCallForNegativeReviewsData: action.payload,
    }),
    [getAmazonProductDetailsBaseOnId.fulfilled]: (state, action) => ({
      ...state,
      getAmazonProductDetailsBaseOnIdData: action.payload,
    }),
    [autoGenerateReview.fulfilled]: (state, action) => ({
      ...state,
      autoGenerateReviewData: action.payload,
    }),
    [getFlipkartProductDetailsBaseOnId.fulfilled]: (state, action) => ({
      ...state,
      getFlipkartProductDetailsBaseOnIdData: action.payload,
    }),
  },
});

export const { resetqrScanner } = qrScannerSlice.actions;

export default qrScannerSlice.reducer;

import Dashboards from './Dashboards';
import ViewAllUpComingFollowUp from './ViewAllUpcomingFollowUp';

const DashboardsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboard',
      element: <Dashboards />,
    },
    {
      path: 'view-all-upcoming-followup',
      element: <ViewAllUpComingFollowUp />,
    },
  ],
};

export default DashboardsConfigs;

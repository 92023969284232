import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const createAuthorizedRequestThunk = (name, endpoint) => {
  return createAsyncThunk(`visitedBusiness/${name}`, async (data) => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    });

    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;

    try {
      return (await instance.post(endpoint, { ...data }))?.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  });
};

export const getVisitedBusinessBySalesPerson = createAuthorizedRequestThunk(
  'getVisitedBusinessBySalesPerson',
  process.env.REACT_APP_API_ENDPOINT
);

export const getUserBusinessStatus = createAuthorizedRequestThunk(
  'getUserBusinessStatus',
  process.env.REACT_APP_API_ENDPOINT
);

const visitedBusinessSlice = createSlice({
  name: 'visitedBusiness',
  initialState: null,
  reducers: {
    resetVisitedBusiness: (state, action) => {
      if (state) {
        state.getVisitedBusinessBySalesPersonData = {};
        state.getUserBusinessStatusData = {};
      }
    },
  },
  extraReducers: {
    [getVisitedBusinessBySalesPerson.fulfilled]: (state, action) => ({
      ...state,
      getVisitedBusinessBySalesPersonData: action.payload,
    }),
    [getUserBusinessStatus.fulfilled]: (state, action) => ({
      ...state,
      getUserBusinessStatusData: action.payload,
    }),
  },
});

export const { resetVisitedBusiness } = visitedBusinessSlice.actions;

export default visitedBusinessSlice.reducer;

import BusinessPerformanceReports from './BusinessPerformanceReport';

const BusinessPerformanceReportsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'businessPerformanceReports/:id?',
      element: <BusinessPerformanceReports />,
    },
  ],
};

export default BusinessPerformanceReportsConfig;

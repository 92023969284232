import SelectedBusinessDetails from '../selected-business/SelectedBusinessDetails';
import VisitedBusiness from './VisitedBusiness';

const VisitedBusinessConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'visited-business',
      element: <VisitedBusiness />,
    },
    {
      path: 'visited-business-details/:businessId',
      element: <SelectedBusinessDetails />,
    },
  ],
};

export default VisitedBusinessConfig;

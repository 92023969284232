import { useState } from 'react';
import { Icon, Typography, Button, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FollowUpDetails from './business-information/FollowUpDetails';
import SalesPersonInsight from './business-information/SalesPersonInsight';

const BusinessInformation = (props) => {
  const { selectedBusinessListDetails } = props;
  const currentPath = useLocation();
  const [copy, setCopy] = useState(false);

  const handleCopyClick = async (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      setCopy(true);
      return navigator.clipboard.writeText(textToCopy);
    });
    await setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div className="md:flex justify-between rounded-md gap-24">
      {currentPath.pathname.includes('visited-business-details') && (
        <div className="w-full md:w-[30%]">
          <FollowUpDetails />
          <SalesPersonInsight />
        </div>
      )}
      <div className="w-full md:w-[70%] bg-grey-100 p-20 mt-20 md:mt-0">
        <Typography className="text-black font-bold text-15 md:text-18">
          {t('appWebsite.businessInformation.title')}
        </Typography>
        <hr className="border-dashed border-black mt-16 mb-16" />
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.businessName')}
          </span>
          <h2 className="text-14 text-grey-600 font-medium">{selectedBusinessListDetails?.name}</h2>
        </div>
        <div className="mb-20">
          <div className="inline-block">
            <span className="text-16 font-medium text-black block">
              {t('business.selectedBusinessDetail.labels.address')}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <Typography className="text-14 text-grey-600 font-medium">
              {selectedBusinessListDetails?.address ? selectedBusinessListDetails?.address : '-'}
            </Typography>

            {selectedBusinessListDetails?.address && (
              <a
                href={`https://www.google.com/maps?q=${selectedBusinessListDetails?.name},${selectedBusinessListDetails?.address}`}
                className="bg-white active:bg-white map-custom inline-block md:ml-40 ml-10"
                rel="noreferrer"
                target="_blank"
              >
                <span className="inline-block text-center">
                  <Icon className="align-middle text-white bg-darkgreen w-24 h-24 text-16 pt-4 text-center rounded-full block mx-auto">
                    location_on
                  </Icon>
                  <span className="font-medium text-12">Directions</span>
                </span>
              </a>
            )}
          </div>
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.state')}
          </span>
          <Typography className="text-14 text-grey-600 font-medium">
            {selectedBusinessListDetails?.states?.name
              ? selectedBusinessListDetails?.states?.name
              : '-'}
          </Typography>
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.phoneNumber')}
            <span className="text-[#000000] font-thin text-14 pl-4">
              ({t('business.selectedBusinessDetail.labels.fromGoogle')})
            </span>
          </span>
          {selectedBusinessListDetails?.googlePhoneNumber?.phoneNumber ? (
            <Typography className="text-14 text-grey-600 font-medium">
              {selectedBusinessListDetails?.googlePhoneNumber?.phoneNumber &&
              selectedBusinessListDetails?.googlePhoneNumber?.countryCode?.toString().includes('+')
                ? selectedBusinessListDetails?.googlePhoneNumber?.countryCode
                : `+${selectedBusinessListDetails?.googlePhoneNumber?.countryCode}`}{' '}
              {selectedBusinessListDetails?.googlePhoneNumber?.phoneNumber
                ? selectedBusinessListDetails?.googlePhoneNumber?.phoneNumber
                : '-'}
            </Typography>
          ) : (
            '-'
          )}
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.phoneNumber')}
            <span className="text-[#000000] font-thin text-14 pl-4">
              ({t('business.selectedBusinessDetail.labels.fromSalesPerson')})
            </span>
          </span>
          {selectedBusinessListDetails?.phoneNumber?.phoneNumber ? (
            <Typography className="text-14 text-grey-600 font-medium">
              {selectedBusinessListDetails?.phoneNumber?.countryCode?.toString().includes('+')
                ? selectedBusinessListDetails?.phoneNumber?.countryCode
                : `+${selectedBusinessListDetails?.phoneNumber?.countryCode}`}{' '}
              {selectedBusinessListDetails?.phoneNumber?.phoneNumber
                ? selectedBusinessListDetails?.phoneNumber?.phoneNumber
                : '-'}
            </Typography>
          ) : (
            '-'
          )}
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.userName')}{' '}
          </span>
          <div className="flex relative items-center gap-16">
            <Typography id="username" className="text-14 text-grey-600 font-medium">
              {selectedBusinessListDetails?.users?.userName}
            </Typography>
            <div>
              <Button
                className="p-0 min-w-0 min-h-0 hover:bg-white h-auto w-0"
                onClick={() => handleCopyClick('username')}
              >
                <ContentCopyRoundedIcon className="text-darkgreen text-18" />
              </Button>
              {copy && (
                <span className="absolute bg-black text-white text-12 px-8 rounded -top-16 duration-300 animate-bounce">
                  {t('business.selectedBusinessDetail.labels.copied')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.businessType')}
          </span>
          <Typography className="text-14 text-grey-600 font-medium capitalize">
            {selectedBusinessListDetails?.business_types?.bType}
          </Typography>
        </div>
        <div className="mb-20">
          <span className="text-16 font-medium text-black block">
            {t('business.selectedBusinessDetail.labels.status')}
          </span>
          <Typography className="text-14 text-grey-600 font-medium capitalize">
            {selectedBusinessListDetails?.user_business_review_platforms[0]?.user_businesses_status
              ?.status === 'pending'
              ? t('business.selectedBusiness.menu.setupInProgress')
              : selectedBusinessListDetails?.user_business_review_platforms[0]
                  ?.user_businesses_status?.status === 'in_progress'
              ? t('business.selectedBusiness.menu.setupInProgress')
              : selectedBusinessListDetails?.user_business_review_platforms[0]
                  ?.user_businesses_status?.status === 'reachable'
              ? t('business.selectedBusiness.menu.readyToDemo')
              : ''}{' '}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;

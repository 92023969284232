import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`appWebsite/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const checkDomainAvailableOnRoute53 = createAsyncThunkWithAuthorization(
  'checkDomainAvailableOnRoute53',
  baseURL
);
export const domainRegistration = createAsyncThunkWithAuthorization('domainRegistration', baseURL);
export const getDomainAndDeploymentStatus = createAsyncThunkWithAuthorization(
  'getDomainAndDeploymentStatus',
  baseURL
);
export const getUserCMSMenu = createAsyncThunkWithAuthorization('getUserCMSMenu', baseURL);
export const getDnsStatus = createAsyncThunkWithAuthorization('getDnsStatus', baseURL);
export const getDemoThemes = createAsyncThunkWithAuthorization('getDemoThemes', baseURL);
export const getAppTemplate = createAsyncThunkWithAuthorization('getAppTemplate', baseURL);
export const getBusinessUserSelectedAppTemplet = createAsyncThunkWithAuthorization(
  'getBusinessUserSelectedAppTemplet',
  baseURL
);
export const getDomainDns = createAsyncThunkWithAuthorization('getDomainDns', baseURL);
export const getCountries = createAsyncThunkWithAuthorization('getCountries', baseURL);
export const getStates = createAsyncThunkWithAuthorization('getStates', baseURL);
export const updateUserTemplateBasicDetails = createAsyncThunkWithAuthorization(
  'updateUserTemplateBasicDetails',
  baseURL
);

const appWebsiteSlice = createSlice({
  name: 'appWebsite',
  initialState: null,
  reducers: {
    resetAppWebsite: (state, action) => {
      if (state) {
        state.checkDomainAvailableOnRoute53Data = {};
        state.domainRegistrationData = {};
        state.getDomainAndDeploymentStatusData = {};
        state.getUserCMSMenuData = {};
        state.getDnsStatusData = {};
        state.getDemoThemesData = {};
        state.updateUserTemplateBasicDetailsData = {};
      }
    },
  },
  extraReducers: {
    [checkDomainAvailableOnRoute53.fulfilled]: (state, action) => ({
      ...state,
      checkDomainAvailableOnRoute53Data: action.payload,
    }),
    [domainRegistration.fulfilled]: (state, action) => ({
      ...state,
      domainRegistrationData: action.payload,
    }),
    [getDomainAndDeploymentStatus.fulfilled]: (state, action) => ({
      ...state,
      getDomainAndDeploymentStatusData: action.payload,
    }),
    [getUserCMSMenu.fulfilled]: (state, action) => ({
      ...state,
      getUserCMSMenuData: action.payload,
    }),
    [getDnsStatus.fulfilled]: (state, action) => ({
      ...state,
      getDnsStatusData: action.payload,
    }),
    [getDemoThemes.fulfilled]: (state, action) => ({
      ...state,
      getDemoThemesData: action.payload,
    }),
    [getAppTemplate.fulfilled]: (state, action) => ({
      ...state,
      getAppTemplateData: action.payload,
    }),
    [getBusinessUserSelectedAppTemplet.fulfilled]: (state, action) => ({
      ...state,
      getBusinessUserSelectedAppTempletData: action.payload,
    }),
    [getDomainDns.fulfilled]: (state, action) => ({
      ...state,
      getDomainDnsData: action.payload,
    }),
    [getCountries.fulfilled]: (state, action) => ({
      ...state,
      getCountriesData: action.payload,
    }),
    [getStates.fulfilled]: (state, action) => ({
      ...state,
      getStatesData: action.payload,
    }),
    [updateUserTemplateBasicDetails.fulfilled]: (state, action) => ({
      ...state,
      updateUserTemplateBasicDetailsData: action.payload,
    }),
  },
});

export const { resetAppWebsite } = appWebsiteSlice.actions;

export default appWebsiteSlice.reducer;

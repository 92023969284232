import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import history from '@history';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import cmsData from '../../query/cms';
import {
  resetCmsWebsite,
  addBusinesAchievementData,
  getBusinesAchievementData,
  updateBusinesAchievementData,
  deleteBusinesAchievementData,
} from './store/cmsWebsiteSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Achievements() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form Validation Schema
   */

  const defaultValues = {
    // bannerTitle: '',
    // description: '',
    // uploadImage: '',
  };

  const schema = yup.object().shape({
    formData: yup.array().of(
      yup.object().shape({
        counterTitle: yup
          .string()
          .required(
            t('appWebsite.achievements.formField.counterTitle.validationMessages.required')
          ),
        counterValue: yup
          .mixed()
          .test('is-number', 'Counter number value must be a number', (value) => {
            if (typeof value === 'number' && !Number.isNaN(value)) {
              return true;
            }
            return false;
          })
          .required(
            t('appWebsite.achievements.formField.counterValue.validationMessages.required')
          ),
        icon: yup
          .string()
          .required(t('appWebsite.achievements.formField.counterIcon.validationMessages.required')),
      })
    ),
  });
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [actionValue, setActionValue] = useState('action');
  const loginUserData = UserService.getUserData();
  const [achievementId, setAchievementId] = useState(null);
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const businessAchievements = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getBusinesAchievementDataValue?.data?.getBusinessAchivement
        ?.data || ''
  );

  const deleteStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.deleteBusinesAchievementDataValue?.data
        ?.deleteBusinessAchievement || ''
  );

  const updateBusinessAchievementstatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.updateBusinesAchievementDataValue?.data
        ?.updateBusinessAchievement || ''
  );

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessAchivement,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getBusinesAchievementData(payload));
    }
    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (deleteStatus?.status) {
      enqueueSnackbar(deleteStatus?.message, {
        variant: deleteStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [deleteStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateBusinessAchievementstatus?.status) {
      enqueueSnackbar(updateBusinessAchievementstatus?.message, {
        variant: updateBusinessAchievementstatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateBusinessAchievementstatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (businessAchievements?.length === 0) {
      append({ counterTitle: '', counterValue: null, icon: '' });
    }
  }, [businessAchievements?.length, append]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (businessAchievements || businessAchievements === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [businessAchievements, isValidDomainRegisterId]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleDeleteBlog = async (id) => {
    try {
      const deletePayload = {
        query: cmsData.deleteBusinessAchievement,
        variables: {
          businessAchievementId: parseInt(id, 10),
        },
      };
      await dispatch(deleteBusinesAchievementData(deletePayload));
      const obj = {
        query: cmsData.getBusinessAchivement,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      await dispatch(getBusinesAchievementData(obj));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditBlog = async (item) => {
    setAchievementId(parseInt(item.id, 10));
    setIsEdit(true);
    const fieldsCopy = [...fields];
    fieldsCopy.forEach((field, index) => {
      remove(index);
    });
    append(item);
  };

  const handleAddNewBlog = () => {
    setIsEdit(true);
    setAchievementId(null);
    reset({ formData: [{ counterTitle: '', counterValue: null, icon: '' }] });
  };

  const formsData = [{ counterTitle: '', counterValue: null, icon: '' }];
  const onSubmit = async (values) => {
    setRefreshIcon(true);
    try {
      if (achievementId) {
        const payloads = {
          query: cmsData.updateBusinessAchievement,
          variables: {
            businessAchievementId: achievementId,
            counterValue: values?.formData[0]?.counterValue,
            counterTitle: values?.formData[0]?.counterTitle,
            icon: values?.formData[0]?.icon,
          },
        };
        const dataObjs = {
          payload: payloads,
          fileData: images[0],
        };
        await dispatch(updateBusinesAchievementData(dataObjs));
      } else {
        const payloadValue = {
          query: cmsData.addBusinessAchivement,
          variables: {
            input: values.formData.map((formDataItem) => ({
              domainRegisterId: userDomainRegisterId,
              ...formDataItem,
            })),
          },
        };
        const dataObj = {
          payload: payloadValue,
        };

        await dispatch(addBusinesAchievementData(dataObj));
        setImages([]);
      }
      setIsEdit(false);
      setRefreshIcon(false);
      reset({ formData: [{ counterTitle: '', counterValue: null, icon: '' }] });
      const obj = {
        query: cmsData.getBusinessAchivement,
        variables: { domainRegisterId: userDomainRegisterId },
      };

      await dispatch(getBusinesAchievementData(obj));
      reset();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center mb-36">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.achievements.title')}
            </Typography>

            {isEdit && (
              <div className="inline-block items-center w-auto float-right">
                <Button
                  className="text-18 px-15 font-semibold"
                  variant="outlined"
                  color="secondary"
                  aria-label="Edit"
                  onClick={(e) => {
                    setIsEdit(false);
                    setAchievementId(null);
                    reset({ formData: [{ heading: '', description: '' }] });
                  }}
                >
                  {t('business.selectedBusinessDetail.buttons.cancel')}
                </Button>
              </div>
            )}
          </div>
          <div>
            {!businessAchievements.length > 0 || isEdit ? (
              <form
                name="registerForm"
                noValidate
                className="w-full"
                defaultValue={{ forms: formsData }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <div>
                    {fields.map((fieldInput, index) => {
                      return (
                        <div className="bg-white rounded-md relative shadow p-24 mb-40" key={index}>
                          <div className="flex">
                            <div className="w-full">
                              <div className="block">
                                <InputLabel
                                  htmlFor="counterTitle"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.achievements.formField.counterTitle.name')}*
                                </InputLabel>
                                <Controller
                                  // name="bannerTitle"
                                  name={`formData[${index}].counterTitle`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="text"
                                      size="small"
                                      value={undefined}
                                      defaultValue={fieldInput.counterTitle}
                                      error={!!errors?.formData?.[index]?.counterTitle}
                                      helperText={errors?.formData?.[index]?.counterTitle?.message}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="block">
                                <InputLabel
                                  htmlFor="counterValue"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.achievements.formField.counterValue.name')}*
                                </InputLabel>
                                <Controller
                                  // name="counterValue"
                                  name={`formData[${index}].counterValue`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="number"
                                      size="small"
                                      value={undefined}
                                      onChange={(e) => {
                                        field.onChange(parseInt(e.target.value, 10));
                                      }}
                                      defaultValue={fieldInput.counterValue}
                                      error={!!errors?.formData?.[index]?.counterValue}
                                      helperText={errors?.formData?.[index]?.counterValue?.message}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <motion.div className="block mb-24">
                                <InputLabel
                                  htmlFor="icon"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.achievements.formField.counterIcon.name')}
                                </InputLabel>
                                <Controller
                                  // name="counterValue"
                                  name={`formData[${index}].icon`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-4"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="text"
                                      size="small"
                                      value={undefined}
                                      defaultValue={fieldInput.icon}
                                      error={!!errors?.formData?.[index]?.icon}
                                      helperText={errors?.formData?.[index]?.icon?.message}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                                <span className="block mt-2">
                                  <strong>{t('business.businessAttribute.label.note')}</strong>{' '}
                                  {t('appWebsite.achievements.label.addCounterIconName')}{' '}
                                  <a
                                    href="https://fonts.google.com/icons"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    role="button"
                                    className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                                  >
                                    {t('business.businessAttribute.formField.getIconsName')}
                                  </a>{' '}
                                </span>
                              </motion.div>
                            </div>
                            {!achievementId && index !== 0 && (
                              <Button
                                onClick={() => remove(index)}
                                className="ml-24 w-44 px-0 min-w-44 h-44 min-h-44 rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                                type="button"
                                color="secondary"
                                variant="contained"
                              >
                                <Icon>delete</Icon>
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {!achievementId && (
                      <div className="text-right">
                        <Button
                          className="rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={() => append({ counterTitle: '', counterValue: null, icon: '' })}
                        >
                          <Icon>add</Icon> {t('appWebsite.achievements.formField.addNew')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {fields.length > 0 && (
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Register"
                      disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                      type="submit"
                      size="small"
                    >
                      {t('appWebsite.home.formField.button')}{' '}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                )}
              </form>
            ) : (
              <>
                <div className="bg-white rounded-md relative shadow p-24">
                  <div className="items-center mb-36">
                    <Typography
                      component={motion.span}
                      initial={{ x: -20 }}
                      animate={{ x: 0, transition: { delay: 0.2 } }}
                      delay={300}
                      className="text-16 md:text-24 font-semibold mb-20"
                    >
                      {t('appWebsite.achievements.achievements')}
                    </Typography>
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-16 px-16 rounded-md"
                        variant="contained"
                        color="secondary"
                        aria-label="Edit"
                        onClick={() => handleAddNewBlog()}
                        // onClick={(e) => setIsEdit(true)}
                      >
                        {t('appWebsite.achievements.formField.addNew')}
                      </Button>
                    </div>
                  </div>
                  <TableContainer className="border-b-1 border-solid border-grey-200">
                    <Table aria-labelledby="tableTitle" size="medium">
                      <TableHead>
                        <TableRow className="bg-grey-200 rounded-tr-md space-x-4">
                          <TableCell className="text-16 w-[25%] font-medium py-10">
                            {t('appWebsite.achievements.column.icon')}
                          </TableCell>
                          <TableCell className="text-16 w-[25%] font-medium py-10">
                            {t('appWebsite.achievements.column.name')}
                          </TableCell>
                          <TableCell className="text-16 w-[25%] font-medium py-10">
                            {t('appWebsite.achievements.column.counterValue')}
                          </TableCell>
                          <TableCell className="text-16 w-[25%] text-right pr-48 font-medium py-10 rounded-tr-md">
                            {t('appWebsite.blog.column.action')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {businessAchievements &&
                          businessAchievements?.length > 0 &&
                          businessAchievements?.map((sliderData, i) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100 space-x-4 space-y-4"
                                key={i}
                              >
                                <TableCell className="w-[25%]" align="left">
                                  <Icon>{sliderData?.icon}</Icon>
                                </TableCell>
                                <TableCell
                                  className="w-[25%] text-16 color-black font-semibold"
                                  align="left"
                                >
                                  {sliderData?.counterTitle}
                                </TableCell>
                                <TableCell
                                  className="w-[25%] text-16 color-black text-left font-semibold"
                                  align="left"
                                >
                                  {sliderData?.counterValue}
                                </TableCell>
                                <TableCell
                                  className="w-[25%] text-16 text-right color-black font-semibold"
                                  align="left"
                                >
                                  <Select
                                    className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                    sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                    variant="filled"
                                    value={actionValue}
                                    fullWidth
                                  >
                                    <MenuItem
                                      value="action"
                                      className="capitalize mx-5 rounded-md"
                                      disabled
                                    >
                                      {t('menuItems.action')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => handleEditBlog(sliderData)}
                                    >
                                      {t('menuItems.edit')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => handleDeleteBlog(sliderData.id)}
                                    >
                                      {t('menuItems.delete')}
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(Achievements);

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const CustomAttributes = () => {
  const [businessAttribute, setBusinessAttribute] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [businessCustomAttribute, setBusinessCustomAttribute] = useState([]);
  const [ratingFormateValue, selectRatingFormateValue] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [ratingFormateData, setRatingFormateData] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [bAttribute, setBAttribute] = useState();
  const [googleAttribute, setGoogleAttribute] = useState([]);
  const [allAttribute, setAllAttribute] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [userCustomBusinessAttributeId, setUserBusinessAttributeId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchBusinessAttributeData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessAttributesOnId,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          businessTypeId: parseInt(userCurrentBusinessDetails?.businessTypeId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAttributesOnId?.status === 200) {
        setBusinessAttribute(result?.getBusinessAttributesOnId?.data);
        if (Array.isArray(result?.getBusinessAttributesOnId?.data)) {
          setAllAttribute((prevAttributes) => [
            ...prevAttributes,
            ...result.getBusinessAttributesOnId.data,
          ]);
        }
      } else {
        enqueueSnackbar(result?.getBusinessAttributesOnId?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, userCurrentBusinessDetails?.businessTypeId]);

  useEffect(() => {
    fetchBusinessAttributeData();
  }, [fetchBusinessAttributeData]);

  useEffect(() => {
    setPageLoading(true);
    const fetchData = async () => {
      const payload = {
        query: manageBusinessData.getBusinessAttributeValue,
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAttributeValue?.status === 200) {
        setRatingFormateData(result?.getBusinessAttributeValue?.data);
      } else {
        enqueueSnackbar(result?.getBusinessAttributeValue?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  const fetchBusinessCustomAttribute = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payloadCustom = {
        query: manageBusinessData.getBusinessOwnerCustomAttributes,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const resultCustom = await handleApiRequest(payloadCustom);
      setPageLoading(false);
      if (resultCustom?.getBusinessOwnerCustomAttributes?.status === 200) {
        setBusinessCustomAttribute(resultCustom?.getBusinessOwnerCustomAttributes?.data);
        setGoogleAttribute(resultCustom?.getBusinessOwnerCustomAttributes?.googleAttributesData);
        const googleAttributesData = Array.isArray(
          resultCustom?.getBusinessOwnerCustomAttributes?.googleAttributesData
        )
          ? resultCustom.getBusinessOwnerCustomAttributes.googleAttributesData
          : [];
        const ownerAttributesData = Array.isArray(
          resultCustom?.getBusinessOwnerCustomAttributes?.data
        )
          ? resultCustom.getBusinessOwnerCustomAttributes.data
          : [];
        setAllAttribute((prevAttributes) => [
          ...prevAttributes,
          ...googleAttributesData,
          ...ownerAttributesData,
        ]);
      } else {
        enqueueSnackbar(resultCustom?.getBusinessOwnerCustomAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);
  useEffect(() => {
    const compareArraysResult = compareArrays(
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute],
      allAttribute
    );
    const isDisabledValue =
      !userCurrentBusinessDetails?.id ||
      compareArraysResult ||
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute].filter(
        (obj) => obj.isActive === true
      ).length > 5;

    setIsDisabled(isDisabledValue);
  }, [
    userCurrentBusinessDetails,
    businessAttribute,
    googleAttribute,
    businessCustomAttribute,
    allAttribute,
  ]);
  useEffect(() => {
    fetchBusinessCustomAttribute();
  }, [fetchBusinessCustomAttribute]);

  const handleRemove = () => {
    selectRatingFormateValue(0);
    setOpenDialogue(false);
    setEdit(false);
    setBAttribute('');
    setIsEmpty(false);
    setEditData();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value?.length !== 21) {
      setBAttribute(value);
      setIsEmpty(value.trim() === '');
    }
  };

  const editBusinessAttribute = (value) => {
    setOpenDialogue(true);
    setEdit(true);
    setBAttribute(value?.bAttribute);
    selectRatingFormateValue(value?.businessAttributeValueId);
    setUserBusinessAttributeId(value?.id);
    setEditData(value);
  };

  const enableDisableCustomAttribute = (value) => {
    const data = businessCustomAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessCustomAttribute(data);
  };

  const enableDisableGoogleAttribute = (value) => {
    const data = googleAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setGoogleAttribute(data);
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    const payload = {
      query: edit
        ? manageBusinessData.editCustomBusinessAttribute
        : manageBusinessData.addCustomBusinessAttribute,
      variables: {
        bAttribute,
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        businessAttributeValueId: parseInt(ratingFormateValue, 10),
        isActive: false,
        ...(edit && {
          userCustomBusinessAttributeId: parseInt(userCustomBusinessAttributeId, 10),
        }),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const res = edit ? result?.editCustomBusinessAttribute : result?.addCustomBusinessAttribute;
    if (res?.status === 200) {
      handleRemove();
      setAllAttribute([]);
      setGoogleAttribute([]);
      setUserBusinessAttributeId();
      fetchBusinessCustomAttribute();
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const enableDisableDefaultAttribute = (value) => {
    const data = businessAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessAttribute(data);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }
  const save = async () => {
    setPageLoading(true);
    const customAttributeData = businessCustomAttribute?.map((ele) => {
      return {
        userCustomBusinessAttributeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const googleAttributeData = googleAttribute?.map((ele) => {
      return {
        userCustomBusinessAttributeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const defaultAttributeData = businessAttribute?.map((ele) => {
      return {
        businessTypeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const payload = {
      query: manageBusinessData.updateCustomBusinessAttributeWithDefaultBusinessAttribute,
      variables: {
        customBusinessAttribute: customAttributeData.concat(googleAttributeData),
        defaultAttribute: defaultAttributeData,
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setPageLoading(false);
    if (result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.status === 200) {
      setGoogleAttribute([]);
      setAllAttribute([]);
      fetchBusinessCustomAttribute();
    } else {
      enqueueSnackbar(result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  function compareArrays(changesAttributeArray, defaultAttributeArray) {
    if (changesAttributeArray.length !== defaultAttributeArray.length) {
      setIsDisabled(false);
      return false;
    }
    for (let i = 0; i < changesAttributeArray.length; i += 1) {
      if (changesAttributeArray[i] !== defaultAttributeArray[i]) {
        setIsDisabled(false);
        return false;
      }
    }

    setIsDisabled(true);
    return true;
  }

  const isAllAttributesDisabled = () => {
    return (
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute].filter(
        (obj) => obj.isActive === true
      ).length === 0
    );
  };

  return (
    <div className="p-20 lg:p-40">
      <div className="sm:flex flex-1 w-full items-center justify-between">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('customAttribute.title')}
          </Typography>
        </div>
        <div className="md:flex gap-24 text-center">
          <Button
            className="bg-darkgreen rounded md:px-36 text-white border disabled:bg-gray disabled:text-black disabled:border-gray border-solid border-darkgreen hover:bg-darkgreen mx-10 md:mx-0"
            type="button"
            disabled={!userCurrentBusinessDetails?.id}
            onClick={() => setOpenDialogue(true)}
          >
            {t('customAttribute.button.addAttribute')}
          </Button>
          <Button
            className="bg-darkgreen rounded px-40 sm:px-60 disabled:bg-gray disabled:text-black text-white hover:bg-darkgreen mx-10 md:mx-0"
            disabled={isDisabled || isAllAttributesDisabled()}
            onClick={() => save()}
          >
            {t('customAttribute.button.save')}
          </Button>
        </div>
      </div>
      {[...businessAttribute, ...googleAttribute, ...businessCustomAttribute].filter(
        (obj) => obj.isActive === true
      ).length > 5 && (
        <Typography className="text-red md:text-right font-medium mt-4">
          {t('manageBusiness.addBusinessSteps.customAttribute.customAttributeLimitExist')}
        </Typography>
      )}
      <div className="">
        {businessAttribute?.length > 0 && (
          <div className="bg-white px-20 py-16 mt-28 rounded-md">
            <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
              {t('manageBusiness.addBusinessSteps.customAttribute.defaultAttribute')}
            </h4>
            {businessAttribute?.map((item, index) => {
              return (
                <div
                  className="my-14 items-center bg-grey-100 rounded-sm mx-auto md:py-2 px-10 py-6 h-44"
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <span className="block md:text-16 text-14 font-medium">{item?.bAttribute}</span>
                    <Switch
                      checked={item?.isActive}
                      onChange={() => enableDisableDefaultAttribute(item)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {googleAttribute?.length > 0 && (
          <div className="bg-white px-20 py-16 mt-28 rounded-md">
            <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
              {t('manageBusiness.addBusinessSteps.customAttribute.googleAttribute')}
            </h4>
            <div className="max-h-288 overflow-scroll">
              {googleAttribute?.map((item, index) => {
                return (
                  <div
                    className="my-14 items-center bg-grey-100 rounded-sm mx-auto md:py-2 px-10 py-6 h-44"
                    key={index}
                  >
                    <div className="flex items-center justify-between">
                      <span className="block md:text-16 text-14 font-medium">
                        {item?.bAttribute}
                      </span>
                      <Switch
                        checked={item?.isActive}
                        onChange={() => enableDisableGoogleAttribute(item)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="bg-white  px-20 py-16 mt-36 rounded-md">
          <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
            {t('customAttribute.title')}
          </h4>
          {businessCustomAttribute?.length > 0 ? (
            <div className="max-h-288 overflow-scroll">
              {businessCustomAttribute?.map((item, index) => {
                return (
                  <div
                    className="my-14 items-center bg-gray-100 rounded-md mx-auto flex items-center"
                    key={index}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        className="md:text-16 font-medium disabled:text-black bg-gray-100 rounded-sm px-0 mr-16 min-h-44 w-44 min-w-44"
                        aria-label="edit"
                        onClick={() => editBusinessAttribute(item)}
                        type="button"
                      >
                        <Icon>edit</Icon>
                      </Button>
                    </div>
                    <div className="flex items-center justify-between md:py-2 px-10 py-6 h-44 w-full">
                      <span className="block md:text-16 text-14 font-semibold capitalize">
                        {item?.bAttribute}
                      </span>
                      <Switch
                        checked={item?.isActive}
                        onChange={() => enableDisableCustomAttribute(item)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-60">
              <h5 className="text-20 font-semibold mb-20">
                {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeTitle')}
              </h5>
              <Typography className="text-14 font-medium mb-20 max-w-384 mx-auto">
                {t('manageBusiness.addBusinessSteps.customAttribute.AddAttributeDesc')}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-full px-28"
                aria-label="add"
                disabled={!userCurrentBusinessDetails?.id}
                onClick={() => setOpenDialogue(true)}
                type="button"
              >
                <Icon>add</Icon>
                {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeButton')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {!edit
              ? t('manageBusiness.addBusinessSteps.customAttribute.dialog.add')
              : t('manageBusiness.addBusinessSteps.customAttribute.dialog.edit')}{' '}
            {t('manageBusiness.addBusinessSteps.customAttribute.dialog.title')}
          </Typography>
        </DialogTitle>

        <form noValidate className="flex flex-col md:overflow-hidden">
          <DialogContent className="p-0 mb-24">
            <div className="block mb-24">
              <InputLabel
                htmlFor="bAttribute"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
              >
                {t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.name'
                )}
                <span>{bAttribute?.length || 0}/20</span>
              </InputLabel>
              <TextField
                placeholder={t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                )}
                onChange={handleChange}
                value={bAttribute}
                size="small"
                id="bAttribute"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty && (
                <Typography className="text-red pt-8 text-14">
                  {t(
                    'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                  )}
                </Typography>
              )}
            </div>
            <div className="block">
              <InputLabel htmlFor="bAttribute" className="text-16 font-medium mb-12 text-grey-900">
                {t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.selectRatingFormat.name'
                )}
              </InputLabel>
              <Select
                className="w-full"
                id="ratingFormate"
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                size="small"
                value={ratingFormateValue}
                displayEmpty
                onChange={(e) => {
                  selectRatingFormateValue(e?.target?.value);
                }}
              >
                <MenuItem value={0} disabled>
                  {t('business.businessAttribute.formField.select')}
                </MenuItem>
                {ratingFormateData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
              variant="contained"
              color="secondary"
              type="submit"
              size="small"
              disabled={
                ratingFormateValue === 0 ||
                refreshIcon ||
                !bAttribute ||
                (editData?.bAttribute === bAttribute &&
                  parseInt(editData?.businessAttributeValueId, 10) ===
                    parseInt(ratingFormateValue, 10))
              }
              onClick={() => onSubmit()}
            >
              {t('manageBusiness.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CustomAttributes;

/* global FB */
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './Waba.css';
import FuseLoading from '@fuse/core/FuseLoading';
import WabaTabs from './WabaTabs';
import { selectDashboardData } from '../../../store/userSlice';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest, getDecryptData } from '../../common/common';

const Waba = () => {
  const [loading, setLoading] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const configId = process.env.REACT_APP_WABA_CONFIG_ID;
  const [whatsAppBusinessAccountId, setWhatsAppBusinessAccountId] = useState('');
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [tokenCode, setTokenCode] = useState(
    'AQC_8PE_kh6P9DelsCiYjzzh_de6MMfI8wxfp7P4uOr2Vp-WaF8O55Fd6vp5TZTuvePTKFFqVMr_U9jtkTSn_btWxHNettKfL8eIsHyiZGFbbnJiaHrmlFOhgVUdBhiR3f2jAroMC7h9sRA4FBidiDyHg3F8PFiy9juEmYeENc4faZ7qv6k7o5X0sqeB0dk7JykyauqXdqGX89DdSk8fCi3yaOQf36f1Un2nIlU1zgnfy9fR1au4YQ3mIJVktH1tmEANf8L3HDp8r6sRBZc81MJvd1ZXrPPj4KvmqlvDqJg_wS5KKy8sofMPT04mCzi9Kbzzt6l1GQ5LOMOVK8bgTs_RIKhT2oxoUP6IkE_aGki9jF-F1zkgk2s5DX286of-kac'
  );
  const [whatAppUserDetails, setWhatAppUserDetails] = useState({});
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    window.fbAsyncInit = function fbAsyncInit() {
      FB.init({
        appId: appKey,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Load the JavaScript SDK asynchronously
    (function loadSDK(d, s, id) {
      let js = d.getElementById(id);
      if (js) return;
      const fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [appKey]);

  useEffect(() => {
    const sessionInfoListener = (event) => {
      if (!event.origin.includes('facebook.com')) return;

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } = data.data;
            setWhatsAppBusinessAccountId(wabaId);
            setWhatsAppPhoneNumberId(phoneNumberId);
            console.log('Finished Signup', phoneNumberId, wabaId);
          } else {
            const { current_step: currentStep } = data.data;
            console.log('Current Step', currentStep);
          }
        }
      } catch {
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    try {
      const whatAppUserDetail = async () => {
        const payload = {
          query: wabaQuery.getUserDetail,
        };
        const res = await handleWabaApiRequest(payload);
        setLoading(false);
        if (res?.getUserDetail?.status === 200) {
          await setUserDetails(JSON.parse(getDecryptData(res?.getUserDetail?.data)));
          setWhatsAppPhoneNumberId(userDetails?.whatsAppPhoneNumberId);
        }
      };

      whatAppUserDetail();
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }
  }, [
    whatsAppBusinessAccountId,
    whatsAppPhoneNumberId,
    tokenCode,
    userDetails?.whatsAppPhoneNumberId,
  ]);

  useEffect(() => {
    try {
      if (userDetails && !userDetails?.waAccessToken) {
        const whatAppUserDetail = async () => {
          const payload = {
            query: wabaQuery.saveWhatAppUserDetails,
            variables: {
              whatsAppBusinessAccountId,
              whatsAppPhoneNumberId,
              tokenCode,
            },
          };
          const res = await handleWabaApiRequest(payload);

          if (res?.saveWhatAppUserDetails?.status === 200) {
            setWhatAppUserDetails(res?.saveWhatAppUserDetails);
          }
        };

        whatAppUserDetail();
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }
  }, [whatsAppBusinessAccountId, whatsAppPhoneNumberId, tokenCode, userDetails]);

  const launchWhatsAppSignup = () => {
    FB.login(
      function handleFBLogin(response) {
        if (response.authResponse) {
          const { code } = response.authResponse;
          console.log('CODE', code);
          setTokenCode(code);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: configId,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      }
    );
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      {userDetails?.waAccessToken ? (
        <div>
          <WabaTabs whatsAppPhoneNumberId={whatsAppPhoneNumberId} />
        </div>
      ) : (
        <div className="p-20 lg:p-28">
          <>
            <div className="bg-white shadow rounded-md py-28 lg:px-80 p-20 mb-28">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center">
                <div>
                  <h2 className="text-32 font-bold mb-32">{t('waba.title')}</h2>
                  <Typography className="font-medium text-16 border-l-2 border-solid border-darkgreen pl-16 max-w-586">
                    {t('waba.desc')}
                  </Typography>

                  <Button
                    onClick={launchWhatsAppSignup}
                    className="rounded-md mt-56 md:px-40"
                    variant="contained"
                    color="secondary"
                  >
                    {t('waba.btnLabel')}
                  </Button>
                </div>
                <div>
                  <img
                    src="/assets/images/business/waba.png"
                    className="img-fluid w-full mx-auto max-w-586"
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-md py-28 lg:px-80 p-20">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center mb-80">
                <div>
                  <img
                    src="/assets/images/business/waba_1.png"
                    className="img-fluid w-full max-w-512"
                    alt="..."
                  />
                </div>
                <div>
                  <h2 className="text-28 font-semibold mb-32">{t('waba.feature.title')}</h2>
                  <ul className="list-disc list-outside pl-16 text-16 font-medium">
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea1'),
                      }}
                    />
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea2'),
                      }}
                    />
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea3'),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea4'),
                      }}
                    />
                  </ul>
                </div>
              </div>
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center">
                <div>
                  <h2 className="text-28 font-semibold mb-32">{t('waba.feature_1.title')}</h2>
                  <ul className="list-disc list-outside pl-16 text-16 font-medium">
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea1'),
                      }}
                    />
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea2'),
                      }}
                    />
                    <li
                      className="mb-16"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea3'),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea4'),
                      }}
                    />
                  </ul>
                </div>
                <div>
                  <img
                    src="/assets/images/business/waba_2.png"
                    className="img-fluid w-full max-w-512 ml-auto"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Waba;

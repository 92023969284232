import Waba from './Waba';

const WabaConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'waba/:id?',
      element: <Waba />,
    },
  ],
};

export default WabaConfigs;

import Queries from './Queries';

const QueriesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'queries',
      element: <Queries />,
    },
  ],
};

export default QueriesConfig;

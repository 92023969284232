import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import _ from '@lodash';
import axios from 'axios';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import history from '@history';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, Icon, Input, Paper, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import { FixedSizeList as List } from 'react-window';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import Loading from '../../manage-business/Loading';
import queryData from '../../../query/common';
import UserService from '../../../../services/userService';
import {
  countryData,
  getDecryptData,
  getParamsId,
  handleApiRequest,
  sortOptions,
} from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';
import {
  getBusinessTypeData,
  getCountryData,
  getReviewPlaterFormData,
  selectDashboardData,
  setUserCurrentBusinessDetailsValue,
} from '../../../../store/userSlice';
import userData from '../../../query/user';

const defaultValues = {
  name: '',
  email: '',
  website: '',
  phone_number: '',
};

const EditBusinessDetails = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        t('manageBusiness.addBusinessSteps.addBusiness.form.businessName.validationMessage')
      ),
    phone_number: yup
      .string()
      .required(t('signUpPage.formField.phoneNumber.validationMessages.required'))
      .min(10, t('signUpPage.formField.phoneNumber.validationMessages.min')),
    email: yup
      .string()
      .email(t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.validationMessage')),
    website: yup
      .string()
      .test(
        'is-url',
        t('manageBusiness.addBusinessSteps.addBusiness.form.website.validationMessage'),
        (value) => {
          if (!value || value === '') {
            return true;
          }
          return /^(https?:\/\/)?(www\.)?[\w-]+(\.[a-z]{2,}){1,}$/i.test(value);
        }
      ),
  });
  const dispatch = useDispatch();
  const loginUserData = UserService.getUserData();
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [countryList, setCountryList] = useState([]);
  const [phone, setPhone] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [zomatoVerified, setZomatoVerified] = useState(false);
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [zomatoUrl, setZomatoUrl] = useState();
  const [googleData, setGoogleData] = useState({});
  const [stateName, setStateName] = useState();
  const [isValidZomatoUrl, setIsValidZomatoUrl] = useState(false);
  const [zomatoBusiness, setZomatoBusiness] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [dataAvailable, setDataAvailable] = useState(false);
  const [yelpData, setYelpData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [isZomato, setIsZomato] = useState(false);
  const [isYelp, setIsYelp] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchBusinessName, setSearchBusinessName] = useState();
  const [loading, setLoading] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [businessType, setBusinessType] = useState([]);
  const [businessTypeId, setBusinessTypeId] = useState();
  const [stateId, setStateId] = useState(0);
  const [zipCode, setZipCode] = useState();
  const [countryId, setCountryId] = useState(0);
  const [reviewPlateFormData, setReviewPlateFormData] = useState([]);
  const [count, setCount] = useState();
  const [inputValue, setInputValue] = useState('');
  const [countryLocation, setCountryLocation] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const businessTypeList = useSelector(getBusinessTypeData);
  const reviewPlateFormsData = useSelector(getReviewPlaterFormData);
  const [amazonProductData, setAmazonProductData] = useState([]);
  const [flipkartProductData, setFlipkartProductData] = useState([]);
  const countriesData = useSelector(getCountryData);
  const ipData =
    localStorage.getItem('ipData') && JSON.parse(getDecryptData(localStorage.getItem('ipData')));

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectBusinessType = (selectedOption) => {
    setBusinessTypeId(selectedOption ? selectedOption.value : null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  const { control, watch, reset, handleSubmit, formState, getValues, setError, setValue } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    async function fetchData() {
      if (countryId) {
        const payload = {
          query: queryData.getStates,
          variables: {
            countryId: parseInt(countryId, 10),
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          setStateData(res.getStates?.data);
        }
      }
    }
    fetchData();
  }, [countryId]);

  const fetchAmazonData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      try {
        setPageLoading(true);
        const payload = {
          query: manageBusinessData.getAmazonProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.getAmazonProduct?.status === 200) {
          setAmazonProductData(result?.getAmazonProduct?.data);
        } else {
          enqueueSnackbar(result?.getAmazonProduct?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error fetching Amazon products:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const fetchFlipCartData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getFlipkartProduct,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getFlipkartProduct?.status === 200) {
        setFlipkartProductData(result?.getFlipkartProduct?.data);
      } else {
        enqueueSnackbar(result?.getFlipkartProduct?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      fetchAmazonData();
      fetchFlipCartData();
    }
  }, [userCurrentBusinessDetails?.isOnlineBusiness, fetchAmazonData, fetchFlipCartData]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!loading) {
      setLoading(true);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchPlateFormPlanDetails = async () => {
      const payload = {
        query: manageBusinessData.businessPlatformPlanValidation,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.businessPlatformPlanValidation?.status === 200) {
        setCount(result?.businessPlatformPlanValidation?.data);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchPlateFormPlanDetails();
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchYelpData = async (page) => {
      try {
        const payload = {
          query: manageBusinessData.searchYelpBusiness,
          variables: {
            location: countryLocation,
            search: searchBusinessName,
            pageNumber,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.searchYelpBusiness?.status === 200) {
          setDataAvailable(result?.searchYelpBusiness?.data?.length === 0 && true);
          setYelpData((prevData) => [...prevData, ...result.searchYelpBusiness.data]);
        } else if (pageNumber === 1) {
          enqueueSnackbar(result?.searchYelpBusiness?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error fetching Yelp data:', error);
      }
    };
    if (!dataAvailable && isYelp) {
      fetchYelpData();
    }
  }, [enqueueSnackbar, pageNumber, isYelp, dataAvailable, countryLocation, searchBusinessName]);

  useEffect(() => {
    if (userCurrentBusinessDetails) {
      const phoneObj = userCurrentBusinessDetails?.phoneNumber;
      setValue('name', userCurrentBusinessDetails?.name);
      setValue('email', userCurrentBusinessDetails?.email);
      setValue(
        'phone_number',
        phoneObj?.phoneNumber?.includes(phoneObj?.countryCode)
          ? phoneObj?.phoneNumber.slice(phoneObj?.countryCode.length)
          : phoneObj?.phoneNumber
      );
      setPhoneValue(phoneObj?.countryCode?.toString().concat(phoneObj?.phoneNumber));
      const findBusinessType = businessType?.find(
        (item) =>
          parseInt(item.value, 10) === parseInt(userCurrentBusinessDetails?.businessTypeId, 10)
      );
      setBusinessTypeId(findBusinessType?.value);
      const findCountryId = countryList?.find(
        (item) =>
          parseInt(item.id, 10) ===
          (parseInt(userCurrentBusinessDetails?.countries?.id, 10) || ipData?.isoCode)
      );
      setCountryLocation(findCountryId?.isoCode);
      setCountryId(findCountryId?.id);
      setPhone({
        phoneNumber: phoneObj?.phoneNumber,
        dialCode: phoneObj?.countryCode,
        countryCode: phoneObj?.isoCode,
      });
    }
  }, [userCurrentBusinessDetails, setValue, businessType, countryList, ipData?.isoCode]);

  useEffect(() => {
    if (businessTypeList?.length > 0) {
      const businessTypeData = businessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessType(businessTypeData);
    }
  }, [businessTypeList]);

  useEffect(() => {
    const physicalBusiness = ['amazon', 'flipkart', 'quickreviews', 'trustpilot'];
    const onlineBusiness = ['amazon', 'flipkart'];
    const physicalData = reviewPlateFormsData?.filter(
      (platform) => !physicalBusiness?.includes(platform?.name)
    );
    const onlineData = reviewPlateFormsData?.filter((platform) =>
      onlineBusiness?.includes(platform?.name)
    );
    const sortPhysicalData = physicalData?.sort((a, b) => a.id - b.id);
    const sortOnlineData = onlineData?.sort((a, b) => a.id - b.id);
    setReviewPlateFormData(
      userCurrentBusinessDetails?.isOnlineBusiness ? sortOnlineData : sortPhysicalData
    );
  }, [userCurrentBusinessDetails?.isOnlineBusiness, reviewPlateFormsData]);

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const reviewPlateForm = reviewPlateFormData?.filter((item) => item.placeId);
    const defaultReviewPlateForm = userCurrentBusinessDetails?.reviewplatform.find(
      (item) => item.defaultReview === true
    );
    const filteredReviewPlatForm = reviewPlateForm.filter(
      (item) =>
        !userCurrentBusinessDetails.reviewplatform.some(
          (platform) => platform.reviewPlatformId === item.id
        )
    );
    const businessDetail = {
      businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      countryId: parseInt(countryId, 10),
      email: data?.email,
      name: data?.name,
      businessTypeId: parseInt(businessTypeId, 10),
      phoneNumberV1: {
        phoneNumber: parseInt(data?.phone_number, 10),
        countryCode: `+${phone?.dialCode}`,
        isoCode: phone?.countryCode,
      },
      reviewPlatformId: parseInt(defaultReviewPlateForm?.reviewPlatformId, 10),
      website: data?.website,
      reviewPlatform: filteredReviewPlatForm?.map((item) => ({
        placeId: item.placeId,
        reviewPlatformId: parseInt(item.id, 10),
        url: item.url || '',
      })),
    };
    const payload = {
      query: manageBusinessData.updateBusinessDetail,
      variables: { businessDetail },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.updateBusinessDetail?.status === 200) {
      if (userCurrentBusinessDetails?.id) {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        dispatch(setUserCurrentBusinessDetailsValue(res?.getDashboard?.data?.[0]));
      }
      enqueueSnackbar(result?.updateBusinessDetail?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.updateBusinessDetail?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  if (pageLoading) {
    return <Loading />;
  }

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({ ...data, phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10) });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectCountry = (event) => {
    setCountryId(event.target.value);
    setPageNumber(1);
    setYelpData([]);
    const findCountry = countryList?.find((country) => country?.id === event.target.value);
    setCountryLocation(findCountry?.isoCode);
  };

  const verifyZomatoLink = async () => {
    setRefreshIcon(true);
    setIsValidZomatoUrl(false);
    const payload = {
      query: manageBusinessData.validateZomatoUrl,
      variables: {
        url: zomatoUrl,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.validateZomatoUrl?.status === 200 && result?.validateZomatoUrl?.data) {
      const businessDetails = result?.validateZomatoUrl?.data;
      setZomatoVerified(true);
      setZomatoBusiness({
        businessName: businessDetails?.name,
        url: businessDetails?.business_url,
        address: businessDetails?.address,
        businessImage: businessDetails?.business_image_url,
        city: businessDetails?.city_name,
      });
      const data = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'zomato') {
          return {
            ...ele,
            address: businessDetails?.address,
            businessName: businessDetails?.name,
            placeId: businessDetails?.res_id?.toString(),
            url: businessDetails?.business_url,
            isBusiness: true,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
    }
    if (result?.validateZomatoUrl?.data === null) {
      setIsValidZomatoUrl(true);
      enqueueSnackbar(result?.validateZomatoUrl?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const onPlacesChanged = async () => {
    if (autocompleteMap !== null) {
      try {
        const place = autocompleteMap?.getPlace();
        setCurrentLocation({
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
        });
        if (!place?.place_id) {
          enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }

        let formattedAddress = '';
        if (place?.place_id) {
          const googleBusinessData = await axios({
            url: process.env.REACT_APP_AI_GOOGLE_PLACE,
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              place_id: place?.place_id,
              place_name: place.name,
            }),
          });

          formattedAddress = place.formatted_address;
          setSelectedMapFullAddress(formattedAddress);

          const tempPhone = place?.international_phone_number
            ?.split(' ')
            .join('')
            .replace(/[^0-9]/g, '');

          if (googleBusinessData?.status === 200) {
            await setGoogleData({
              address: formattedAddress,
              businessName: place?.name,
              url: place?.url,
              businessImage: googleBusinessData?.data?.data?.data?.profile_image,
              rating: place?.rating,
              userTotalRating: place?.user_ratings_total,
              phone: {
                ...(tempPhone && {
                  phoneNumber: tempPhone,
                  countryCode: place?.international_phone_number?.split(' ')[0],
                  isoCode: place?.international_phone_number?.split(' ')[0] === '+91' ? 'in' : 'us',
                }),
              },
              country: googleBusinessData?.data?.data?.data?.country,
            });
          } else {
            enqueueSnackbar(googleBusinessData.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }

        const data = reviewPlateFormData?.map((ele) => {
          if (ele.name === 'google') {
            return {
              ...ele,
              address: formattedAddress,
              businessName: place?.name,
              placeId: place.place_id,
              url: place.url,
              isBusiness: true,
            };
          }
          return ele;
        });

        setReviewPlateFormData(data);
      } catch (error) {
        console.error('Error occurred in onPlacesChanged: ', error);
        enqueueSnackbar(t('manageBusiness.noFoundBusiness'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onDragChanged = async (event) => {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
    }
  };

  const addBusinessDetails = async (value, data) => {
    if (value === 'zomato') {
      setIsZomato(false);
    }
    if (value === 'google') {
      setIsGoogle(false);
    }
    if (value === 'yelp') {
      setIsYelp(false);
      setPageNumber(1);
      const updatedData = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'yelp') {
          return {
            ...ele,
            address: data?.location?.display_address?.join(' '),
            businessName: data?.name,
            placeId: data?.id,
            url: data?.url,
            isBusiness: true,
          };
        }
        return ele;
      });
      setReviewPlateFormData(updatedData);
    }
  };

  const selectState = async (state) => {
    setStateId(parseInt(state, 10) !== 0 ? state : 0);
    const findState = stateData.find((ele) => ele.id === state);
    setStateName(findState?.name || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${findState?.name || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleZipCode = async (value) => {
    if (value?.length < 7) {
      setZipCode(value);
      const payload = {
        query: manageBusinessData.searchYelpBusiness,
        variables: {
          location: `${countryLocation}, ${stateName || ''}, ${value}`,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.status === 200) {
        setYelpData(result?.searchYelpBusiness?.data);
      }
    }
  };

  const handleSearchBusinessName = async (value) => {
    setSearchBusinessName(value || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: value,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const cancelYelp = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setIsYelp(false);
    setDataAvailable(false);
    setPageNumber(1);
  };

  const clearSearch = async () => {
    setSearchBusinessName('');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: '',
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleBusinessLocation = async (data) => {
    if (parseInt(count?.availableCount, 10) !== parseInt(count?.usedCount, 10)) {
      if (data.name === 'zomato') {
        setIsZomato(true);
      } else if (data.name === 'google') {
        setIsGoogle(true);
      } else if (data.name === 'yelp') {
        setIsYelp(true);
        setPageNumber(1);
        const payload = {
          query: manageBusinessData.searchYelpBusiness,
          variables: {
            location: countryLocation,
            search: searchBusinessName,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.searchYelpBusiness?.data) {
          setYelpData(result?.searchYelpBusiness?.data);
        }
      }
    } else {
      enqueueSnackbar(t('editBusinessDetails.planMessage').replace('two', count?.availableCount), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleRemoveBusiness = async (data) => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.id === data.id) {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      if (ele.businessName) {
        return {
          ...ele,
        };
      }
      return ele;
    });
    if (data.name === 'google') {
      setGoogleData();
    }
    if (data.name === 'zomato') {
      setZomatoUrl();
      setZomatoVerified(false);
      setZomatoBusiness();
    }
    setReviewPlateFormData(removeBusinessInformation);
  };

  const skipGoogleBusiness = () => {
    setIsGoogle(false);
    setGoogleData();
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'google') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
  };

  const skipZomatoBusiness = () => {
    setIsZomato(false);
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'zomato') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setZomatoUrl();
    setZomatoVerified(false);
    setZomatoBusiness();
    setReviewPlateFormData(removeBusinessInformation);
  };

  const redirectAddProduct = () => {
    const id = getParamsId();
    if (id) {
      history.push(`/product/${id}`);
    } else {
      history.push('/product');
    }
  };

  return (
    <div className="p-20 lg:p-40">
      {!isGoogle && !isZomato && !isYelp && (
        <div>
          <form noValidate onSubmit={handleSubmit(onSubmit)} className="">
            <div className="flex flex-1 w-full items-center justify-between mb-36">
              <div className="flex items-center">
                <Typography
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
                >
                  {t('editBusinessDetails.title')}
                </Typography>
              </div>
              <div className="md:flex gap-24 text-center">
                <Button
                  className="bg-darkgreen rounded disabled:bg-gray disabled:text-black md:px-60 text-white hover:bg-darkgreen mx-10 md:mx-0"
                  type="submit"
                  disabled={refreshIcon}
                >
                  {t('reviewScreenMode.button.save')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
            <div className="mx-auto">
              <div className="bg-grey-50 px-20 py-40 rounded-md">
                <div className="flex justify-between border-b-1 border-dashed border-solid border-grey pb-14 mb-20">
                  <h4 className="text-20 font-semibold ">
                    {t('manageBusiness.addBusinessSteps.addBusiness.smallTitle')}
                  </h4>
                </div>
                <div className="grid gap-16 md:gap-20 grid-cols-1 md:grid-cols-2">
                  <div className="block">
                    <InputLabel
                      htmlFor="name"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                    >
                      {t('manageBusiness.addBusinessSteps.addBusiness.form.businessName.name')}{' '}
                      <span className=" text-2xl text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.businessName.placeHolder'
                          )}
                          size="small"
                          id="name"
                          error={!!errors.name}
                          helperText={errors?.name?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      htmlFor="website"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                    >
                      {t('manageBusiness.addBusinessSteps.addBusiness.form.website.name')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="website"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.website.placeHolder'
                          )}
                          size="small"
                          id="website"
                          error={!!errors.website}
                          helperText={errors?.website?.message}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      htmlFor="website"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                    >
                      {t('manageBusiness.addBusinessSteps.businessLocation.businessType')}
                    </InputLabel>
                    <ReactSelect
                      labelId="businessType"
                      placeholder={t('business.selectBusinessType')}
                      id="businessType"
                      name="businessType"
                      onChange={handleSelectBusinessType}
                      onInputChange={handleInputChange}
                      value={businessType?.find((op) => {
                        return parseInt(op.value, 10) === parseInt(businessTypeId, 10);
                      })}
                      options={filteredOptions}
                      components={{
                        MenuList: CustomMenuList,
                      }}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      htmlFor="phone_number"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                    >
                      {t('manageBusiness.addBusinessSteps.addBusiness.form.phone.name')}
                      <span className=" text-2xl text-red-500">*</span>
                    </InputLabel>
                    <PhoneInput
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                      )}
                      inputStyle={{
                        width: '100%',
                        height: '37px',
                      }}
                      className="w-full"
                      name="phone_number"
                      required
                      error={!!errors.phone_number}
                      helperText={errors?.phone_number?.message}
                      value={phoneValue}
                      size="small"
                      country={loginUserData?.isoCode?.toLowerCase()}
                      enableSearch="true"
                      onChange={handlePhoneChange}
                    />
                    {phoneValue && (
                      <p
                        className={`text-red ml-12 top-5 relative text-md ${
                          (phoneValue?.length || 0) - (phone?.dialCode?.length || 0) <= 10
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        {errors.phone_number?.message
                          ? t('signUpPage.formField.phoneNumber.validationMessages.min')
                          : ''}
                      </p>
                    )}
                  </div>
                  <div className="block">
                    <InputLabel
                      htmlFor="email"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                    >
                      {t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.name')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.placeHolder'
                          )}
                          size="small"
                          id="email"
                          error={!!errors.email}
                          helperText={errors?.email?.message}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div className="block">
                    <InputLabel
                      id="country"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                    >
                      {t('manageBusiness.addBusinessSteps.addBusiness.form.country.name')}
                      <span className=" text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="w-full"
                          id="country"
                          value={parseInt(countryId, 10)}
                          size="small"
                          displayEmpty
                          error={!!errors.country}
                          onChange={(e) => {
                            handleSelectCountry(e);
                          }}
                        >
                          <MenuItem value={0} disabled>
                            {t('qrcodeOrder.form.country.placeHolder')}
                          </MenuItem>
                          {countryList?.map((option, i) => (
                            <MenuItem key={i} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="mx-auto mt-32">
            <div className="bg-grey-50 px-20 py-40 rounded-md">
              <div className="flex justify-between border-b-1 border-dashed border-solid border-grey pb-14 mb-20">
                <h4 className="text-20 font-semibold ">
                  {t('manageBusiness.addBusinessSteps.addBusiness.businessPlatForm')}
                </h4>
              </div>
              {reviewPlateFormData?.length > 0 &&
                reviewPlateFormData
                  ?.sort((a, b) => a.reviewPlatformId - b.reviewPlatformId)
                  .map((item, i) => {
                    const findReview = userCurrentBusinessDetails?.reviewplatform.find(
                      (review) => review.reviewPlatformId === item.id
                    );
                    return (
                      <div
                        className={`flex justify-between mb-16 rounded-md p-20 ${
                          parseInt(item.id, 10) === parseInt(findReview?.reviewPlatformId, 10) ||
                          userCurrentBusinessDetails?.isOnlineBusiness
                            ? 'bg-darkgreen-100'
                            : 'border border-solid border-darkgreen'
                        }`}
                        key={i}
                      >
                        <div className="flex items-center gap-24">
                          <img className="w-48" src={item?.logo} alt="" />
                          <Typography className="text-16 font-semibold">
                            {item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                          </Typography>
                        </div>
                        {!userCurrentBusinessDetails?.isOnlineBusiness ? (
                          <>
                            {parseInt(findReview?.reviewPlatformId, 10) ===
                            parseInt(item?.id, 10) ? (
                              <img
                                src="/assets/images/business/check_circle.svg"
                                className="img-fluid"
                                alt="..."
                              />
                            ) : (
                              <div className="flex items-center">
                                {item?.businessName && (
                                  <>
                                    <img
                                      src="/assets/images/business/check_circle.svg"
                                      className="img-fluid"
                                      alt="..."
                                    />
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      size="small"
                                      className="flex items-center rounded-full h-40 md:text-16 font-medium disabled:text-black sm:min-w-128 bg-transparent border-darkgreen text-darkgreen md:mx-16 mx-6"
                                      onClick={() => handleBusinessLocation(item)}
                                    >
                                      <span>{t('manageBusiness.button.edit')}</span>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      size="small"
                                      className="flex items-center rounded-full h-40 w-40 disabled:text-black min-w-0 bg-transparent border-red text-darkgreen"
                                      onClick={() => handleRemoveBusiness(item)}
                                    >
                                      <DeleteIcon className="text-24 text-red" />
                                    </Button>
                                  </>
                                )}
                                {!item?.businessName && (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black min-w-160 min-h-40"
                                    onClick={() => handleBusinessLocation(item)}
                                  >
                                    <span>{t('manageBusiness.button.addLocation')}</span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {parseInt(findReview?.reviewPlatformId, 10) === 5 && (
                              <>
                                {amazonProductData?.length > 0 ? (
                                  <img
                                    src="/assets/images/business/check_circle.svg"
                                    className="img-fluid"
                                    alt="..."
                                  />
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black min-w-160 min-h-40"
                                    onClick={() => redirectAddProduct()}
                                  >
                                    <span>{t('products.addAmazonProduct')}</span>
                                  </Button>
                                )}
                              </>
                            )}
                            {parseInt(findReview?.reviewPlatformId, 10) === 6 && (
                              <>
                                {flipkartProductData?.length > 0 ? (
                                  <img
                                    src="/assets/images/business/check_circle.svg"
                                    className="img-fluid"
                                    alt="..."
                                  />
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black min-w-160 min-h-40"
                                    onClick={() => redirectAddProduct()}
                                  >
                                    <span>{t('products.addFlipkartProduct')}</span>
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
      {isGoogle && (
        <div className="mt-28 bg-white p-40">
          <div className={`${googleData?.businessName ? 'md:flex' : ''} gap-20`}>
            <div className={`${googleData?.businessName ? 'md:w-3/4' : 'w-full'}`}>
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-lg"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder={t(
                          'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                        )}
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `300px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `5px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
            </div>
            {googleData?.businessName && (
              <div className="border-1 sm:w-320 h-full mt-20 md:mt-0 m-auto md:w-288 rounded-md p-8 border-solid !border-darkgreen">
                <img className="rounded-md h-160 w-full" src={googleData?.businessImage} alt="" />
                <Typography className="font-medium text-16 pt-8">
                  {googleData?.businessName}
                </Typography>
                <Typography className="text-12 py-8">{googleData?.address}</Typography>
                <div className="flex items-center">
                  <Typography className="font-medium text-14">
                    {googleData?.rating > 0 && (
                      <Icon className="text-16 text-yellow-800 align-middle mr-2 -mt-2">star</Icon>
                    )}{' '}
                    {googleData?.rating}{' '}
                    <span className="font-normal pl-4 text-14">
                      ({googleData?.userTotalRating} reviews)
                    </span>
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => skipGoogleBusiness()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
              aria-label="confirm"
              disabled={!googleData?.businessName}
              onClick={() => addBusinessDetails('google')}
              type="button"
            >
              {t('manageBusiness.button.next')}
            </Button>
          </div>
        </div>
      )}
      {isZomato && (
        <div className="p-24 mt-28 rounded-lg bg-gray-50">
          <div className="text-start">
            <Typography className="text-black text-16 font-medium mb-8">
              {t('manageBusiness.addBusinessSteps.businessLocation.enterZomatoUrl')}
              <a
                href="https://www.zomato.com/"
                target="_blank"
                className="!bg-transparent !text-darkgreen underline text-12 ml-6"
                rel="noreferrer"
              >
                (Go to Zomato)
              </a>
            </Typography>
            <TextField
              className="w-full text-black text-16 font-medium bg-white"
              value={zomatoUrl}
              onChange={(e) => {
                setZomatoUrl(e.target.value);
                setZomatoVerified(false);
              }}
              placeholder="https://"
              size="small"
            />
            {zomatoVerified && zomatoUrl ? (
              <>
                <Typography className="text-black text-16 font-medium my-8">
                  {t('manageBusiness.addBusinessSteps.businessLocation.businessName')}
                </Typography>
                <Typography className="text-black border text-black text-14 bg-white border-gray rounded border solid py-10 pl-12 my-8">
                  {zomatoBusiness?.businessName}
                </Typography>
              </>
            ) : (
              <>
                <Button
                  className="border border-solid rounded-4xl mt-20 bg-darkgreen hover:bg-darkgreen disabled:border-none disabled:text-black disabled:bg-gray text-white font-medium px-40"
                  onClick={() => verifyZomatoLink()}
                  disabled={refreshIcon || !zomatoUrl}
                >
                  {t('manageBusiness.button.verifiedZomatoLink')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <img
                  src="/assets/images/business/zomatoGuide.png"
                  className="img-fluid max-w-384 w-full mt-32"
                  alt="..."
                />
              </>
            )}
            {isValidZomatoUrl && (
              <Typography className="text-red mt-16 font-medium text-14">
                <WarningAmberIcon className="text-red" />
                {t('manageBusiness.addBusinessSteps.businessLocation.zomatoAlertMessage')}
              </Typography>
            )}
            <div className="text-right mt-60">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                onClick={() => skipZomatoBusiness()}
                type="button"
              >
                {t('manageBusiness.button.skip')}
              </Button>
              {zomatoVerified && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 px-28 font-medium rounded inline-block align-middle"
                  onClick={() => addBusinessDetails('zomato')}
                >
                  <span>{t('manageBusiness.button.addReviewSite')}</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {isYelp && (
        <div className="h-full">
          <div className="p-24 h-ful mt-28 rounded-lg bg-gray-200">
            <div className="h-full overflow-scroll" onScroll={handleScroll}>
              <div className="flex gap-16 w-full">
                <Paper
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-full sm:w-1/2 px-8 border border-solid border-gray-500 rounded-8 shadow-none"
                >
                  <Input
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                    )}
                    className="flex flex-1 mx-8 text-black text-16"
                    disableUnderline
                    name="searchText"
                    fullWidth
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={(e) => handleSearchBusinessName(e.target.value)}
                    value={searchBusinessName}
                  />
                  {searchBusinessName ? (
                    <Icon
                      color="action"
                      className="cursor-pointer"
                      onClick={() => {
                        clearSearch();
                      }}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="action">search</Icon>
                  )}
                </Paper>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(countryId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={countryId}
                  onChange={(e) => handleSelectCountry(e)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0" disabled>
                    Select country
                  </MenuItem>
                  {countryList?.length > 0 &&
                    countryList?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(stateId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={stateId}
                  onChange={(e) => selectState(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0" disabled>
                    Select State
                  </MenuItem>
                  {stateData?.length > 0 &&
                    stateData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              <div className="mt-12">
                <TextField
                  placeholder="Zip code"
                  value={zipCode}
                  type="number"
                  onChange={(e) => handleZipCode(e.target.value)}
                  className="w-full bg-white"
                  size="small"
                />
              </div>
              {yelpData?.length > 0 ? (
                yelpData?.map((item, i) => {
                  return (
                    <div
                      className="sm:flex justify-between items-center p-16 bg-white rounded mt-16"
                      key={i}
                    >
                      <div className="flex items-center gap-24">
                        <div>
                          <img
                            className="w-120 rounded rounded-md h-80"
                            src={item.image_url}
                            alt=""
                          />
                        </div>
                        <div className="text-start">
                          <Typography className="font-bold">{item.name}</Typography>
                          <Typography className="text-black pt-4 text-14">
                            <LocationOnOutlinedIcon className="text-gray-500 text-20" />{' '}
                            {item.location?.display_address.join(' ')}
                          </Typography>
                        </div>
                      </div>
                      <div className="text-right mt-24 sm:mt-0">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black min-w-160 min-h-40"
                          onClick={() => addBusinessDetails('yelp', item)}
                        >
                          <span>{t('manageBusiness.button.addBusiness')}</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Typography className="font-semibold pt-80 text-16 flex items-center justify-center">
                  {t('manageBusiness.addBusinessSteps.businessLocation.noDataAvailable')}
                </Typography>
              )}
            </div>
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => cancelYelp()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBusinessDetails;

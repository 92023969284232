const getAgencyDetails = `
	query {
		getAgencyDetails{
      message
      status
      data {
        clientCount
        businessCount
      }  
		}
	}
`;

const getBusinessListForAgencyAndClient = `
  query ( $pageNumber: Int
    $pageSize: Int
    $searchText: String
    $clientId: Int) {
    getBusinessListForAgencyAndClient(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchText: $searchText
    clientId: $clientId) {
      status
      message
      totalCount
      data {
        name
        address
        address2
        city
        state
        profileURL
        userId
        businessId
        users {
          firstName
          lastName
          email
          phoneNumber{
            isoCode
            countryCode
            phoneNumber
          }
          userName
        }
         user_package_subscription {
          packageId
          packageName
          startDate
          expireDate
        }
      }
  }
}
`;

const removeUserBusinessDetail = `
  mutation ( $businessId: Int!) {
      removeUserBusinessDetail(businessId: $businessId) {
      status
      message
  }
}
`;

const getAgencyClientList = `
query ($clientId: Int, $pageNumber: Int, $pageSize: Int, $searchText: String) {
    getAgencyClientList(clientId: $clientId, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText) {
      status
      message
      totalCount
    data {
      id
      firstName
      lastName
      email
      userName
      packagePlanId
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      profileUrl
      user_package_subscription {
        id
        packageId
        packageName
        startDate
        expireDate
      }
      businessCount
    }
  }
}
`;

const getTokenUsingUserIdAndBusinessId = `
query ($data : String!){
  getTokenUsingUserIdAndBusinessId(data : $data){
    accessToken
    refreshToken
    message
    status
  }
}

`;

const getUserDetailsBaseOnUserId = `
query ($userId: Int!){
  getUserDetailsBaseOnUserId(userId: $userId){
    data{
      firstName
      lastName
      fullName
      userName
      email
      profileUrl
      phoneNumber{
        isoCode
        countryCode
        phoneNumber
      }
      user_package_subscription{
        packageId
        packageName
        expireDate
        startDate
      }
    }
    message
    status
  }
}

`;

const agencyData = {
  getAgencyDetails,
  getBusinessListForAgencyAndClient,
  removeUserBusinessDetail,
  getAgencyClientList,
  getTokenUsingUserIdAndBusinessId,
  getUserDetailsBaseOnUserId,
};

export default agencyData;

const searchBusinessNearBy = `
query($city: String, $state: String, $radius: Int!, $type: String, $search: String, $lat: Float, $lng: Float){
     searchBusinessNearBy(city: $city, state: $state, radius: $radius, type: $type, search: $search, lat: $lat, lng: $lng ){ 
          data {
               business_status
               geometry {
                 location {
                   lat
                   lng
                 }
                 viewport {
                   northeast {
                     lat
                     lng
                   }
                   southwest {
                     lat
                     lng
                   }
                 }
               }
               name
               icon
               icon_background_color
               location_url
               icon_mask_base_uri
               opening_hours {
                 open_now
               }
               photos {
                 height
                 html_attributions
                 photo_reference
                 width
               }
               place_id
               plus_code {
                 compound_code
                 global_code
               }
               price_level
               rating
               reference
               scope
               types
               user_ratings_total
               formatted_address
          }
          message
          status
     }
   }
`;

const addBusinessBySalesPerson = `
mutation($data: String!){
     addBusinessBySalesPerson(data: $data){ 
          message
          status
          data {
              existedBusiness
          }
     }
   }
`;

const getBusinessPlaceType = `
query{
  getBusinessPlaceType{ 
      data {
        id
        type
        slug
      }
      status
      message
    }
  }
`;

const searchBusinessData = {
  searchBusinessNearBy,
  addBusinessBySalesPerson,
  getBusinessPlaceType,
};

export default searchBusinessData;

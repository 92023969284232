import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import ProductsServices from '../../manage-business/ProductsServices';
import SupportForPhysicalOrOnline from '../supportForPhysicalOrOnline/SupportForPhysicalOrOnline';
import { selectDashboardData } from '../../../../store/userSlice';

const BusinessProductAndService = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  return (
    <div className="p-20 h-5/6  lg:p-40">
      {!userCurrentBusinessDetails?.isOnlineBusiness ? (
        <>
          <div className="md:flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t('businessProductAndService.title')}
              </Typography>
            </div>
          </div>
          <div className="bg-white h-full">
            <ProductsServices />
          </div>
        </>
      ) : (
        <SupportForPhysicalOrOnline />
      )}
    </div>
  );
};

export default BusinessProductAndService;

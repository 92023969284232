import SelectedBusinessDetails from '../selected-business/SelectedBusinessDetails';
import DeactivatedAccount from './deactivatedAccount';

const DeactivatedAccountConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'deactivated-account',
      element: <DeactivatedAccount />,
    },
    {
      path: 'deactive-business-details/:businessId',
      element: <SelectedBusinessDetails />,
    },
  ],
};

export default DeactivatedAccountConfig;

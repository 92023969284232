import { t } from 'i18next';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import WabaDashBoard from './WabaDashBoard';
import WabaInbox from './WabaInbox';
import WabaBroadcast from './WabaBroadcast';
import WabaContact from './WabaContact';
import 'swiper/swiper-bundle.css';
import 'swiper/css';

const WabaTabs = (props) => {
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="bg-white rounded-md border-t border-solid border-grey-300">
        <Tabs
          value={tabValue}
          className="sm:overflow-hidden overflow-scroll p-0"
          sx={{
            '& .MuiTabs-scroller': {
              borderBottom: '1px solid #eee',
              padding: '0px',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
            value={0}
            label={t('waba.tabs.tab1')}
          />
          <Tab
            className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
            value={1}
            label={t('waba.tabs.tab2')}
          />
          <Tab
            className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
            value={2}
            label={t('waba.tabs.tab3')}
          />
          <Tab
            className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
            value={3}
            label={t('waba.tabs.tab4')}
          />
        </Tabs>
      </div>
      <div>
        <div>
          <div className={tabValue !== 0 ? 'hidden' : ''}>
            <WabaDashBoard />
          </div>
          <div className={tabValue !== 1 ? 'hidden' : ''}>
            <WabaInbox whatsAppPhoneNumberId={props?.whatsAppPhoneNumberId} />
          </div>
          <div className={tabValue !== 2 ? 'hidden' : ''}>
            <WabaBroadcast whatsAppPhoneNumberId={props?.whatsAppPhoneNumberId} />
          </div>
          <div className={tabValue !== 3 ? 'hidden' : ''}>
            {' '}
            <WabaContact whatsAppPhoneNumberId={props?.whatsAppPhoneNumberId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WabaTabs;

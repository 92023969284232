import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import history from '@history';
import Collapse from '@mui/material/Collapse';
import FuseLoading from '@fuse/core/FuseLoading';
import UserService from '../../../services/userService';
import cmsData from '../../query/cms';
import {
  getUserBusinessSocialMediaLink,
  addUserBusinessSocialMediaLink,
  updateUserBusinessSocialMediaLink,
  resetCmsWebsite,
} from './store/cmsWebsiteSlice';
import reducer from './store';

function SocialLinks() {
  const { t } = useTranslation();

  /**
   * Form Validation Schema
   */

  const defaultValues = {
    link: '',
  };

  const schema = yup.object().shape({
    link: yup
      .string()
      .matches(
        /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
        t('appWebsite.social.formField.link.validationMessages.required')
      )
      .required(t('appWebsite.social.formField.link.validationMessages.required')),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const dispatch = useDispatch();

  const loginUserData = UserService.getUserData();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [editLink, setEditLink] = useState(false);
  const [socialId, setSocialId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const socialData = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getUserBusinessSocialMediaLinkData?.data
        ?.getUserBusinessSocialMediaLink?.data || ''
  );
  const socialDataStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.addUserBusinessSocialMediaLinkData?.data
        ?.addUserBusinessSocialMediaLink
  );

  const socialDataUpdateStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.updateUserBusinessSocialMediaLinkData?.data
        ?.updateUserBusinessSocialMediaLink
  );
  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getUserBusinessSocialMediaLink,
        variables: {
          domainRegisterId: userDomainRegisterId,
        },
      };
      dispatch(getUserBusinessSocialMediaLink(payload));
    }
    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (socialDataStatus && socialDataStatus.status === 400) {
      enqueueSnackbar(socialDataStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (socialDataStatus && socialDataStatus.status === 200) {
      enqueueSnackbar(socialDataStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setEdit(false);
      closeSnackbar(3000);
    }
  }, [reset, dispatch, socialDataStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (socialDataUpdateStatus && socialDataUpdateStatus.status === 400) {
      enqueueSnackbar(socialDataUpdateStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (socialDataUpdateStatus && socialDataUpdateStatus.status === 200) {
      enqueueSnackbar(socialDataUpdateStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setEdit(false);
      closeSnackbar(3000);
    }
  }, [reset, dispatch, socialDataUpdateStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (socialData || socialData === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [socialData, isValidDomainRegisterId]);

  if (loading) {
    return <FuseLoading />;
  }

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    try {
      if (!editLink) {
        if (!socialData.find((socialValue) => socialValue.id === socialId)?.addbyUser) {
          const payload = {
            query: cmsData.addUserBusinessSocialMediaLink,
            variables: {
              domainSocialMediaId: parseInt(socialId, 10),
              domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10),
              link: data.link,
            },
          };
          await dispatch(addUserBusinessSocialMediaLink(payload));
        } else {
          const payload = {
            query: cmsData.updateUserBusinessSocialMediaLink,
            variables: {
              userBusinessDomainSocialMediaId: parseInt(socialId, 10),
              link: data.link,
            },
          };
          await dispatch(updateUserBusinessSocialMediaLink(payload));
        }
        reset();
        setEditLink(false);

        const payloadAbout = {
          query: cmsData.getUserBusinessSocialMediaLink,
          variables: { domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10) },
        };
        dispatch(getUserBusinessSocialMediaLink(payloadAbout));
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <div>
          <div className="items-center mb-36">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.social.title')}
            </Typography>
          </div>
          <div className="bg-white rounded-md relative shadow p-24 max-w-640">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-16 font-medium block"
            >
              {t('appWebsite.social.shortDesc')}
            </Typography>
            <div>
              {socialData &&
                socialData?.length > 0 &&
                socialData.map((socialValue, index) => (
                  <div className="bg-grey-100 rounded-md p-14 mt-20" key={index}>
                    <form name="registerForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex items-center justify-between">
                        <span className="font-medium text-16 flex items-center">
                          {socialValue?.icon === 'Facebook' ? (
                            <img
                              src="/assets/images/business/social_icons/facebook.png"
                              alt="..."
                            />
                          ) : socialValue?.icon === 'instagram' ? (
                            <img src="/assets/images/business/social_icons/twitter.png" alt="..." />
                          ) : socialValue?.icon === 'linkedin' ? (
                            <img
                              src="/assets/images/business/social_icons/linkedin.png"
                              alt="..."
                            />
                          ) : socialValue?.icon === 'twitter' ? (
                            <img
                              src="/assets/images/business/social_icons/linkedin.png"
                              alt="..."
                            />
                          ) : socialValue?.icon === 'youtube' ? (
                            <img
                              src="/assets/images/business/social_icons/you tube.png"
                              alt="..."
                            />
                          ) : (
                            socialValue?.icon
                          )}
                          <span className="inline-block ml-14">{socialValue?.name}</span>
                        </span>
                        <span
                          className="font-semibold text-16 text-darkgreen items-center align-middle"
                          onClick={() => {
                            setEdit(true);
                            setSocialId(socialValue?.id);
                            clearErrors('link');
                          }}
                          aria-hidden="true"
                        >
                          {!edit && (
                            <span className="cursor-pointer">
                              {socialValue?.addbyUser ? (
                                <>
                                  <Icon className="align-middle">edit</Icon> Edit Link
                                </>
                              ) : (
                                <>
                                  <Icon className="align-middle">link</Icon>Add Links
                                </>
                              )}
                            </span>
                          )}
                          {edit && socialId === socialValue.id && (
                            <Button
                              variant="link"
                              color="secondary"
                              className="font-semibold text-16 text-darkgreen items-center align-middle px-0 w-auto py-0 min-w-auto"
                              aria-label="Save"
                              onClick={() => {
                                setEditLink(true);
                                handleSubmit(onSubmit)();
                              }}
                              disabled={refreshIcon || !isValid}
                              type="button"
                              size="small"
                            >
                              {t('appWebsite.social.formField.button')}{' '}
                            </Button>
                          )}
                        </span>
                      </div>
                      {edit && socialId === socialValue.id && (
                        <Collapse in={edit} timeout="auto" unmountOnExit>
                          <div>
                            <div className="mt-24">
                              <div className="block">
                                <InputLabel
                                  htmlFor="companyInformation"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.social.formField.link.name')}*
                                </InputLabel>
                                <Controller
                                  name="link"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="text"
                                      size="small"
                                      value={undefined}
                                      defaultValue={socialValue?.link || ''}
                                      error={!!errors.link}
                                      helperText={errors?.link?.message}
                                      required
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <Button
                            variant="link"
                            color="secondary"
                            className="font-semibold text-16 text-darkgreen items-center align-middle px-0 w-auto py-0 min-w-auto"
                            aria-label="Register"
                            onClick={() => {
                              setEdit(false);
                            }}
                            type="button"
                            size="small"
                          >
                            {t('appWebsite.social.formField.cancel')}{' '}
                          </Button>
                        </Collapse>
                      )}
                    </form>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(SocialLinks);

import { useEffect, useState } from 'react';
import withReducer from 'app/store/withReducer';
import { Checkbox, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import menuData from '../../query/menu';
import { addUpdateDomainMenu, getUserDomainMenu, reSetMenu } from './store/menuSlice';
import reducer from './store';
import userService from '../../../services/userService';

function AddMenuDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuListData = useSelector(
    ({ menuReducer }) =>
      menuReducer?.menu?.getUserDomainMenuValue?.data?.getUserDomainMenu?.data || ''
  );

  const addUpdateMenuStatus = useSelector(
    ({ menuReducer }) =>
      menuReducer?.menu?.addUpdateDomainMenuValue?.data?.addUpdateDomainMenu || ''
  );

  const [loading, setLoading] = useState(true);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [checkedItems, setCheckedItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const loginUserData = userService.getUserData();
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toggleCheckbox = (item, index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);

    const newItem = {
      domainMenuId: item.domainMenuId,
      isActive: updatedCheckedItems[index],
      domainRegisterId: isValidDomainRegisterId || null,
    };

    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = [...prevSelectedItems];
      const existingItemIndex = newSelectedItems.findIndex(
        (existingItem) => existingItem.domainMenuId === newItem.domainMenuId
      );

      if (existingItemIndex !== -1) {
        newSelectedItems[existingItemIndex].isActive = newItem.isActive;
      } else {
        newSelectedItems.push(newItem);
      }
      return newSelectedItems;
    });
  };

  const handleSubmit = async () => {
    if (selectedItems?.length > 0) {
      setRefreshIcon(true);
      const payload = {
        query: menuData.addUpdateDomainMenu,
        variables: {
          input: selectedItems,
        },
      };
      await dispatch(addUpdateDomainMenu(payload));
      const dataObj = {
        query: menuData.getMenu,
      };
      await dispatch(getUserDomainMenu(dataObj));
      setSelectedItems([]);
      window.location.reload();
    }
  };

  useEffect(() => {
    let unmounted = false;

    const payload = {
      query: menuData.getMenu,
    };
    dispatch(getUserDomainMenu(payload));

    return () => {
      unmounted = true;
      dispatch(reSetMenu());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (menuListData || menuListData === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [menuListData, isValidDomainRegisterId]);

  useEffect(() => {
    if (menuListData) {
      const initialCheckedItems = menuListData.map((item) => (item.active ? item.active : false));
      setCheckedItems(initialCheckedItems);
      setLoading(false);
    }
  }, [menuListData]);

  useEffect(() => {
    if (addUpdateMenuStatus?.status) {
      enqueueSnackbar(addUpdateMenuStatus?.message, {
        variant: addUpdateMenuStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    }
  }, [addUpdateMenuStatus, enqueueSnackbar, closeSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-40">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-16 md:text-28 font-bold"
      >
        {t('menu.title')}
      </Typography>
      <div className="bg-white lg:w-1/2 rounded-md relative shadow my-16">
        <div className="p-20 lg:p-40">
          <Typography className="text-black font-medium text-16">
            {t('menu.labels.manageMenu')}
          </Typography>
          {menuListData?.length > 0 ? (
            <>
              {menuListData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="border-1 border-grey border-solid flex px-12 justify-between my-16 items-center h-[4.5rem] rounded"
                  >
                    <div>
                      <Typography className="text-black text-16 font-semibold">
                        {item.menu}
                      </Typography>
                    </div>
                    <div className="flex items-center">
                      <Checkbox
                        className="!text-darkgreen"
                        checked={item.isDefault ? item.isDefault : checkedItems[index]}
                        onChange={() => {
                          if (!item.isDefault) {
                            toggleCheckbox(item, index);
                          }
                        }}
                        color="primary"
                        style={{
                          opacity: item.isDefault ? 0.3 : 1,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="text-center">
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="secondary"
                  className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                  aria-label="Register"
                  disabled={selectedItems?.length === 0 || refreshIcon}
                  type="button"
                  size="small"
                >
                  {t('menu.button.save')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography color="textSecondary" variant="h5" className="text-16 py-12 text-center">
                {t('menu.messages.noMenu')}
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withReducer('menuReducer', reducer)(AddMenuDetails);

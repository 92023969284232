import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FuseLoading from '@fuse/core/FuseLoading';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import _ from '@lodash';
import history from '@history';
import * as yup from 'yup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneInput from 'react-phone-input-2';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import userData from '../../query/user';
import BusinessDetail from './BusinessDetails';
import { handleApiRequest } from '../../common/common';
import InviteUserDetails from './InviteUserDetails';
import planQuery from '../../query/plan';
import userService from '../../../services';
import OnlineBusiness from './OnlineBusiness';

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  zipCode: '',
  phone_number: '',
};

const UserDetails = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    middleName: yup
      .string()
      .notRequired()
      .when('middle_name', {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .max(15, t('signUpPage.formField.middleName.validationMessages.max'))
            .matches(
              /^[a-zA-Z ]+$/,
              t('signUpPage.formField.middleName.validationMessages.matches')
            ),
      }),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  const dispatch = useDispatch();
  const routeParams = useParams();

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditBusiness, setIsEditBusiness] = useState(false);
  const [isEditInviteBusiness, setIsEditInviteBusiness] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [phoneValue, setPhoneValue] = useState();
  const [phone, setPhone] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState();
  const [phoneIsoCode, setPhoneIsoCode] = useState();
  const [profileUrl, profileImage] = useState();
  const [userStatusId, userStatus] = useState();
  const [userDetails, setUserDetails] = useState();
  const [inviteUserData, setInviteUserData] = useState([]);
  const [isSelectPlan, setIsSelectPlan] = useState(false);
  const [businessDetailBasedOnUserIdData, setBusinessDetailBasedOnUserIdData] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [selectDayId, setSelectDayId] = useState();
  const [days, setDays] = useState();
  const [isDaySelected, setIsDaySelected] = useState(false);
  const [isOnlineBusiness, setIsOnlineBusiness] = useState(true);
  const loginUserData = userService.getUserData();

  const fetchUserDetails = useCallback(async () => {
    const payload = {
      query: userData.getUserDetails,
      variables: {
        userId: parseInt(routeParams.userId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getUserDetails?.status === 200) {
      const user = result?.getUserDetails?.data;
      if (user) {
        setLoading(false);
        const setValueWithValidation = (field, value) => {
          setValue(field, value || '', {
            shouldDirty: true,
            shouldValidate: true,
          });
        };
        const expireDate = moment(user?.expireDate);
        const currentDate = moment(new Date()).startOf('day');
        setDays(parseInt(expireDate?.diff(currentDate, 'days'), 10));
        setSelectedPlanId(user?.packageId);
        setValueWithValidation('firstName', user.firstName);
        setValueWithValidation('middleName', user.middleName);
        setValueWithValidation('lastName', user.lastName);
        setValueWithValidation('phone_number', user.phoneNumber?.phoneNumber);
        setValueWithValidation('country_code', user.phoneNumber?.countryCode);
        setUserDetails(user);
        setPhoneNumber(user.phoneNumber?.phoneNumber);
        setSelectedCountryId(user.countries || '');
        setStateId(user.states || '');
        profileImage(user.profileUrl || 0);
        userStatus(user.userStatusId || 0);
        setPhoneCountryCode(user.phoneNumber?.countryCode);
        setPhoneIsoCode(user.phoneNumber?.isoCode);
        if (user.phoneNumber?.countryCode || user.phoneNumber?.phoneNumber) {
          setPhoneValue(user.phoneNumber.countryCode + user.phoneNumber.phoneNumber);
        }
      }
    }
  }, [routeParams?.userId, setValue]);

  useEffect(() => {
    const fetchPackages = async () => {
      const payload = {
        query: planQuery.getPackages,
        variables: {
          countryId: parseInt(loginUserData?.countryId, 10),
          packageInterval: 'month',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getPackages?.status === 200) {
        setPackages(result?.getPackages?.data);
      } else {
        enqueueSnackbar(result?.getPackages?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    fetchPackages();
  }, [loginUserData?.countryId, enqueueSnackbar]);

  useEffect(() => {
    const fetchInviteUserData = async () => {
      const payload = {
        query: userData.getInvitedBusinessDetailBasedOnUserId,
        variables: {
          userId: parseInt(routeParams.userId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getInvitedBusinessDetailBasedOnUserId?.status === 200) {
        setInviteUserData(result?.getInvitedBusinessDetailBasedOnUserId?.data);
      }
    };
    fetchInviteUserData();
  }, [routeParams.userId]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails, routeParams?.userId]);

  useEffect(() => {
    async function fetchBusinessUserDetails() {
      setLoading(true);
      const payload = {
        query: userData.getBusinessDetailBasedOnUserId,
        variables: {
          userId: parseInt(routeParams.userId, 10),
          isOnlineBusiness: tabValue !== 1,
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getBusinessDetailBasedOnUserId?.status === 200) {
        if (result?.getBusinessDetailBasedOnUserId?.data) {
          setBusinessDetailBasedOnUserIdData(
            result?.getBusinessDetailBasedOnUserId?.data[0]?.user_businesses
          );
        }
      }
    }
    fetchBusinessUserDetails();
  }, [dispatch, routeParams.userId, tabValue]);

  if (_.isEmpty(form) || loading) {
    return <FuseLoading />;
  }

  const handleChange = (event, newValue) => {
    setIsEditBusiness(false);
    setTabValue(newValue);
  };
  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...data,
      phoneNumber: parseInt(event.slice(data.dialCode.length), 10),
    });
    setValue('phone_number', event.slice(data.dialCode.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectCountry = (event) => {
    setSelectedCountryId({ id: event.target.value, name: event.target.name });
    setStateId();
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const payload = {
      query: userData.editProfile,
      variables: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        userId: parseInt(routeParams?.userId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.editProfile?.status === 200) {
      enqueueSnackbar(result?.editProfile?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setIsEdit(false);
      setPhone();
      fetchUserDetails();
    } else {
      enqueueSnackbar(result?.editProfile?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCancelEditBusiness = async () => {
    setIsEditBusiness(false);
    const payload = {
      query: userData.getBusinessDetailBasedOnUserId,
      variables: {
        userId: parseInt(routeParams.userId, 10),
        isOnlineBusiness: tabValue !== 1,
      },
    };
    const result = await handleApiRequest(payload);

    if (result?.getBusinessDetailBasedOnUserId?.status === 200) {
      if (result?.getBusinessDetailBasedOnUserId?.data) {
        setBusinessDetailBasedOnUserIdData(
          result?.getBusinessDetailBasedOnUserId?.data[0]?.user_businesses
        );
      }
    }
  };

  const handleCancelInviteBusiness = async () => {
    setIsEditInviteBusiness(false);
    const payload = {
      query: userData.getInvitedBusinessDetailBasedOnUserId,
      variables: {
        userId: parseInt(routeParams.userId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getInvitedBusinessDetailBasedOnUserId?.status === 200) {
      setInviteUserData(result?.getInvitedBusinessDetailBasedOnUserId?.data);
    }
  };

  const submitSelectPlan = async () => {
    setRefreshIcon(true);
    const payload = {
      query: userData.updateDemoPackageAccountBySales,
      variables: {
        userId: parseInt(routeParams?.userId, 10),
        packageId: parseInt(selectedPlanId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.updateDemoPackageAccountBySales?.status === 200) {
      enqueueSnackbar(result?.updateDemoPackageAccountBySales?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      fetchUserDetails();
      setSelectedPlanId();
      setIsSelectPlan(false);
    }
  };

  const submitSelectDays = async () => {
    setRefreshIcon(true);
    const payload = {
      query: userData.extendDemoAccountValidity,
      variables: {
        extendDay: selectDayId || 0,
        userId: parseInt(routeParams?.userId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.extendDemoAccountValidity?.status === 200) {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      fetchUserDetails();
      setSelectDayId();
      setIsDaySelected(false);
    } else {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const filteredBusinessData = businessDetailBasedOnUserIdData.filter((business) =>
    tabValue === 1 ? business.isOnlineBusiness === false : business.isOnlineBusiness === true
  );

  return (
    <div className="p-20 lg:p-40">
      <div className="flex justify-between items-center">
        <div>
          <Toolbar className="px-0 font-semibold min-h-fit mb-20">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('users.UserList.title')}
            </Typography>
          </Toolbar>
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-16 md:text-28 font-bold"
              >
                <span className="inline-block bg-white rounded-md w-48 h-48 text-center flex items-center mr-10">
                  <Icon className="text-32 mx-auto">group</Icon>
                </span>{' '}
                {t('users.userDetails.labels.viewUserDetails')}
              </Typography>
            </div>
          </div>
        </div>

        <div>
          {tabValue === 0 && (
            <>
              {!isEdit ? (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-32"
                    variant="contained"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => setIsEdit(true)}
                  >
                    {t('users.userDetails.buttons.edit')}
                  </Button>
                </div>
              ) : (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-32 hover:bg-black hover:text-white ml-10"
                    variant="contained"
                    aria-label="Edit"
                    onClick={(e) => setIsEdit(false)}
                  >
                    {t('users.userDetails.buttons.cancel')}
                  </Button>
                </div>
              )}
            </>
          )}

          {tabValue === 1 && (
            <>
              {businessDetailBasedOnUserIdData?.length > 0 && (
                <>
                  {!isEditBusiness ? (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32"
                        variant="contained"
                        color="secondary"
                        aria-label="Edit"
                        onClick={(e) => setIsEditBusiness(true)}
                      >
                        {t('users.userDetails.buttons.editBusiness')}
                      </Button>
                    </div>
                  ) : (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32 hover:bg-black hover:text-white ml-10"
                        variant="contained"
                        aria-label="Edit"
                        onClick={(e) => handleCancelEditBusiness()}
                      >
                        {t('users.userDetails.buttons.cancel')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {tabValue === 2 && (
            <>
              {isOnlineBusiness && businessDetailBasedOnUserIdData?.length > 0 && (
                <>
                  {!isEditBusiness ? (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32"
                        variant="contained"
                        color="secondary"
                        aria-label="Edit"
                        onClick={(e) => setIsEditBusiness(true)}
                      >
                        {t('users.userDetails.buttons.editBusiness')}
                      </Button>
                    </div>
                  ) : (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32 hover:bg-black hover:text-white ml-10"
                        variant="contained"
                        aria-label="Edit"
                        onClick={(e) => handleCancelEditBusiness()}
                      >
                        {t('users.userDetails.buttons.cancel')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {tabValue === 3 && (
            <>
              {inviteUserData?.length > 0 && (
                <>
                  {!isEditInviteBusiness ? (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32"
                        variant="contained"
                        color="secondary"
                        aria-label="Edit"
                        onClick={(e) => setIsEditInviteBusiness(true)}
                      >
                        {t('users.userDetails.buttons.editInviteBusiness')}
                      </Button>
                    </div>
                  ) : (
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-18 px-32 hover:bg-black hover:text-white ml-10"
                        variant="contained"
                        aria-label="Edit"
                        onClick={(e) => handleCancelInviteBusiness()}
                      >
                        {t('users.userDetails.buttons.cancel')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="bg-white rounded-md relative shadow">
        <Tabs
          value={tabValue}
          className="bg-grey-50 rounded-tl-md rounded-tr-md"
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab className="text-18 font-semibold" value={0} label="User Information" />
          <Tab className="text-18 font-semibold" value={1} label="Physical Business" />
          <Tab className="text-18 font-semibold" value={2} label="Online Business" />
          <Tab className="text-18 font-semibold" value={3} label="Invited Business" />
        </Tabs>
        <div className="p-20 lg:p-40">
          <div className={tabValue !== 0 ? 'hidden' : ''}>
            <div className="w-full">
              {isEdit ? (
                <form name="userDetails" onSubmit={handleSubmit(onSubmit)}>
                  {isEdit && (
                    <Button
                      className="text-18 absolute right-[131px] top-[-88px] px-36 disabled:text-black"
                      variant="contained"
                      color="secondary"
                      disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                      type="submit"
                    >
                      {t('users.userDetails.buttons.save')}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  )}
                  <div className="flex justify-start">
                    <div className="basis-1/2  max-w-sm">
                      <div className="rounded-md border border-grey-400 p-14 mb-24">
                        <div className="flex items-center">
                          <Avatar
                            className="w-96 h-96 mr-24 bg-orange-500 text-40 capitalize"
                            alt={userDetails?.firstName}
                            src={profileUrl.toString()}
                          />
                          <div>
                            <h3 className="text-18 font-semibold capitalize">
                              {userDetails?.firstName} {userDetails?.lastName}
                            </h3>
                            <h4 className="text-16 font-semibold text-grey-500">
                              {userDetails?.email}
                            </h4>
                            <span
                              className={`inline-block w-92 h-28 flex items-center text-white text-center rounded-md text-16 font-semibold mt-8  ${
                                userStatusId === 1 ? 'bg-green-700' : 'bg-red-700'
                              }`}
                            >
                              <span className="block mx-auto">
                                {userStatusId === 1 ? 'Active' : 'Inactive'}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.firstName.name')}
                            type="text"
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.middleName.name')}
                            type="text"
                            error={!!errors.middleName}
                            helperText={errors?.middleName?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.lastName.name')}
                            type="text"
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <div>
                        <PhoneInput
                          placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                          inputStyle={{
                            width: '100%',
                            height: '52px',
                          }}
                          className="w-full max-w-sm w-full"
                          name="phone_number"
                          required
                          error={!!errors.phone_number}
                          helperText={errors?.phone_number?.message}
                          disabled
                          value={phoneValue}
                          country="us"
                          enableSearch="true"
                          onChange={handlePhoneChange}
                        />
                        <p className="text-black top-5 relative text-md">
                          {t('signUpPage.phoneNumberCannotChange')}
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="md:flex md:gap-0 gap-24 justify-between">
                  <div className="basis-1/2  max-w-sm">
                    <div className="py-14 mb-24">
                      <div className="flex items-center">
                        <Avatar
                          className="w-96 h-96 mr-24 bg-orange-500 text-40 capitalize"
                          alt={userDetails?.firstName}
                          src={profileUrl?.toString()}
                        />
                        <div>
                          <h3 className="text-18 font-semibold capitalize">
                            {userDetails?.firstName} {userDetails?.lastName}
                          </h3>
                          <h4 className="text-16 font-semibold text-grey-500">
                            {userDetails?.email}
                          </h4>
                          <span
                            className={`break-words inline-block w-92 h-28 flex items-center text-white text-center rounded-md text-16 font-semibold mt-8  ${
                              userStatusId === 1 ? 'bg-green-700' : 'bg-red-700'
                            }`}
                          >
                            <span className="block mx-auto capitalize">
                              {userDetails?.userStatus}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('signUpPage.formField.firstName.name')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {userDetails?.firstName}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('signUpPage.formField.middleName.name')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {userDetails?.middleName}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('signUpPage.formField.lastName.name')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {userDetails?.lastName}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">Business Phone</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {phoneCountryCode} {phoneNumber}
                      </span>
                    </div>
                    <div className="">
                      <span className="text-15 font-semibold block">Email ID*</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {userDetails?.email}
                      </span>
                    </div>
                  </div>
                  <div className="md:w-640 md:mt-0 mt-40">
                    <div className="bg-lightgray pb-28 rounded-md">
                      <h1 className="text-18 py-16 pl-20 text-black font-bold">
                        {userDetails?.isFreeTrial
                          ? t('userDetails.freeTrialInformation')
                          : t('userDetails.planInformation')}
                      </h1>
                      <hr className="border border-solid !border-gray-400" />
                      {isSelectPlan ? (
                        <div className="px-16 pt-24">
                          <div className="md:flex gap-40 bg-white items-center px-20 py-20 justify-between">
                            <div className="w-full">
                              <Select
                                className="w-full"
                                value={selectedPlanId || 0}
                                size="small"
                                onChange={(e) => setSelectedPlanId(e.target.value)}
                              >
                                <MenuItem className="text-gray" value="0">
                                  {t('userDetails.selectPlan')}
                                </MenuItem>
                                {packages?.length > 0 &&
                                  packages?.map((item, i) => {
                                    return (
                                      <MenuItem key={i} value={item?.id}>
                                        {item?.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className="flex gap-12 mt-24 md:mt-0">
                              <Button
                                className="text-gray-600 text-14 font-medium border border-gray-600 border-solid bg-white rounded px-36 h-24 min-h-36"
                                onClick={() => setIsSelectPlan(false)}
                              >
                                {t('userDetails.cancel')}
                              </Button>
                              <Button
                                className="text-white bg-darkgreen text-14 hover:bg-darkgreen disabled:bg-gray font-medium rounded px-48 h-24 min-h-36"
                                disabled={refreshIcon}
                                onClick={() => submitSelectPlan()}
                              >
                                {t('userDetails.save')}
                                {refreshIcon && (
                                  <CircularProgress
                                    size={24}
                                    className="text-darkgreen absolute mx-auto"
                                  />
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="px-16 pt-24">
                          <div className="flex bg-white items-center px-20 py-20 gap-24 justify-between">
                            <div className="">
                              <Typography className="font-bold">
                                {t('userDetails.currentPlan')}
                              </Typography>
                              <Typography className="font-medium pt-6">
                                {userDetails?.planName}
                              </Typography>
                            </div>
                            {userDetails?.isFreeTrial && (
                              <div className="md:mt-0 mt-8">
                                <Button
                                  className="text-darkgreen text-12 sm:text-14 font-medium !bg-darkgreen-100 rounded px-28 h-24 min-h-36"
                                  onClick={() => setIsSelectPlan(true)}
                                >
                                  {t('userDetails.changePlan')}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {isDaySelected && userDetails?.isFreeTrial ? (
                        <div className="px-16 pt-24">
                          <div className="md:flex bg-white items-center gap-40 px-20 py-20 justify-between">
                            <div className="w-full">
                              <Select
                                className="w-full"
                                value={selectDayId || 0}
                                size="small"
                                onChange={(e) => setSelectDayId(e.target.value)}
                              >
                                <MenuItem className="text-gray" value="0">
                                  {t('userDetails.selectExtendDays')}
                                </MenuItem>
                                {Array.from({ length: 7 }, (item, i) => {
                                  return (
                                    <MenuItem key={i} value={i + 1}>
                                      {i + 1} {t('userDetails.day')}
                                      {i === 0 ? '' : 's'}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                            <div className="flex gap-12 mt-24 md:mt-0">
                              <Button
                                className="text-gray-600 text-14 font-medium border !border-gray-600 border-solid bg-white rounded px-36 h-24 min-h-36"
                                onClick={() => setIsDaySelected(false)}
                              >
                                {t('userDetails.cancel')}
                              </Button>
                              <Button
                                className="text-white bg-darkgreen text-14 hover:bg-darkgreen disabled:bg-gray font-medium rounded px-48 h-24 min-h-36"
                                disabled={refreshIcon}
                                onClick={() => submitSelectDays()}
                              >
                                {t('userDetails.save')}
                                {refreshIcon && (
                                  <CircularProgress
                                    size={24}
                                    className="text-darkgreen absolute mx-auto"
                                  />
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="px-16 pt-24">
                          {userDetails?.isFreeTrial ? (
                            <div className="flex bg-white items-center px-20 py-20 justify-between">
                              <div className="">
                                <Typography className="font-bold">
                                  {t('userDetails.remainingPlan')}
                                </Typography>
                                <Typography className="font-medium pt-6">
                                  {days > 0
                                    ? `${days} ${t('userDetails.days')}`
                                    : t('userDetails.freeTrialExpired')}
                                </Typography>
                              </div>
                              <div className="md:mt-0 mt-8">
                                <Button
                                  className="text-darkgreen text-12 sm:text-14 font-medium !bg-darkgreen-100 rounded px-16 h-24 min-h-36"
                                  onClick={() => setIsDaySelected(true)}
                                >
                                  {t('userDetails.extendedPlan')}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="bg-white items-center px-20 py-20 gap-24">
                              <div>
                                <Typography className="font-bold">
                                  {t('userDetails.nextRecurringDate')}
                                </Typography>
                                <Typography className="font-medium pt-6">
                                  {moment(userDetails?.nextRecurringDate).format('MMM DD, YYYY')}
                                </Typography>
                              </div>
                              <div>
                                <Typography className="font-bold pt-12">
                                  {t('userDetails.nextRecurringAmount')}
                                </Typography>
                                <Typography className="font-medium pt-6">
                                  {userDetails?.offerAmount}
                                </Typography>
                              </div>
                              <div>
                                <Typography className="font-bold pt-12">
                                  {t('userDetails.paymentMethod')}
                                </Typography>
                                <Typography className="font-medium pt-6">
                                  {userDetails?.paymentMethod &&
                                    userDetails.paymentMethod.charAt(0).toUpperCase() +
                                      userDetails.paymentMethod.slice(1)}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={tabValue !== 1 ? 'hidden' : ''}>
            {(() => {
              const nonOnlineBusinesses = businessDetailBasedOnUserIdData?.filter(
                (business) => !business.isOnlineBusiness
              );

              if (nonOnlineBusinesses.length > 0) {
                return (
                  <BusinessDetail
                    businessDetailBasedOnUserIdData={nonOnlineBusinesses}
                    setBusinessDetailBasedOnUserIdData={setBusinessDetailBasedOnUserIdData}
                    isEditBusiness={isEditBusiness}
                    setIsEditBusiness={setIsEditBusiness}
                    tabValue={tabValue}
                  />
                );
              }
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.1 } }}
                  className="flex flex-1 items-center justify-center h-full"
                >
                  <div className="flex justify-center w-full items-center my-80">
                    <div>
                      <img
                        src="assets/images/business/business1.png"
                        className="m-auto max-w-200"
                        alt="..."
                      />
                      <Typography
                        color="textsecondary"
                        variant="h5"
                        className="text-24 py-12 font-bold text-center"
                      >
                        {t('users.userDetails.messages.noPhysicalBusiness')}
                      </Typography>
                      <div>
                        <Typography
                          color="text-black"
                          variant="h6"
                          className="text-center tracking-normal text-black opacity-100"
                        >
                          {t('users.userDetails.messages.businessInformationMessage')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            })()}
          </div>

          <div className={tabValue !== 2 ? 'hidden' : ''}>
            {(() => {
              const onlineBusinesses = businessDetailBasedOnUserIdData?.filter(
                (business) => business.isOnlineBusiness
              );
              if (isOnlineBusiness && onlineBusinesses.length > 0) {
                return (
                  <OnlineBusiness
                    businessDetailBasedOnUserIdData={onlineBusinesses}
                    setBusinessDetailBasedOnUserIdData={setBusinessDetailBasedOnUserIdData}
                    isEditBusiness={isEditBusiness}
                    setIsEditBusiness={setIsEditBusiness}
                    tabValue={tabValue}
                  />
                );
              }
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.1 } }}
                  className="flex flex-1 items-center justify-center h-full"
                >
                  <div className="flex justify-center w-full items-center my-80">
                    <div>
                      <img
                        src="assets/images/business/business1.png"
                        className="m-auto max-w-200"
                        alt="..."
                      />
                      <Typography
                        color="textsecondary"
                        variant="h5"
                        className="text-24 py-12 font-bold text-center"
                      >
                        {t('users.userDetails.messages.noOnlineBusiness')}
                      </Typography>
                      <div>
                        <Typography
                          color="text-black"
                          variant="h6"
                          className="text-center tracking-normal text-black opacity-100"
                        >
                          {t('users.userDetails.messages.businessInformationMessage')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            })()}
          </div>

          <div className={tabValue !== 3 ? 'hidden' : ''}>
            <InviteUserDetails
              inviteUserData={inviteUserData}
              isEditInviteBusiness={isEditInviteBusiness}
              setInviteUserData={setInviteUserData}
              setIsEditInviteBusiness={setIsEditInviteBusiness}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

import withReducer from 'app/store/withReducer';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import './Dashboards.css';
import { selectDashboardData, setUserCurrentBusinessDetailsValue } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import Loading from '../manage-business/Loading';
import history from '../../../../@history/@history';
import reviewPlatformData from '../../query/reviewPlatform';
import { handleApiRequest, getDataToServer, getParamsId } from '../../common/common';
import reducer from './store';
import userData from '../../query/user';
import DashboardAlerts from './DashboardAlerts';
import BusinessPlateForm from './BusinessPlatForm';
import BusinessSetUp from './BusinessSetUp';
import WeeklyAverageReviews from './WeeklyAverageReviews';
import DashboardReviewDistribution from './DashboardReviewDistribution';
import ReviewSentimentChart from '../matrices/reviewSentimentChart';
import metricsData from '../../query/metrics';
import ReviewDistribution from '../matrices/reviewDistribution';
import LatestReviews from './LatestReview';
import ReceivedReviews from '../matrices/receivedReviews';

function Dashboards() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [selectPlateForm, setSelectPlateForm] = useState();
  const [reviewPlatformInfos, setReviewPlatformInfos] = useState({});
  const [dateDetails, setDateDetails] = useState([]);
  const [reviewPlaceId, setReviewPlaceId] = useState(null);
  const [zomatoRatingType, setZomatoRatingType] = useState('dining');
  const [loading, setLoading] = useState(false);
  const [reviewSentiment, setReviewSentiment] = useState([]);
  const [selectPlateFormIDForRedirect, setSelectPlateFormIDForRedirect] = useState();
  const [reviewData, setReviewData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!selectPlateForm) {
      const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find((item) =>
        userCurrentBusinessDetails?.isOnlineBusiness
          ? item?.name === 'quickreviews'
          : item.defaultReview === true
      );
      setSelectPlateForm(reviewPlatformInfo?.reviewPlatformId);
      setSelectPlateFormIDForRedirect(reviewPlatformInfo?.reviewPlatformId);
      setReviewPlaceId(reviewPlatformInfo?.placeId);
    }
  }, [selectPlateForm, userCurrentBusinessDetails]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setLoading(true);
      try {
        const payload = {
          query: metricsData.getReviewSentimentPeriodsData,
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getReviewSentimentPeriodsData?.status === 200) {
          setReviewSentiment(result?.getReviewSentimentPeriodsData?.data || []);
        } else {
          enqueueSnackbar(result?.getReviewSentimentPeriodsData?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log('An error occurred while fetching the dashboard data.', error);
      }
    };
    fetchDashboard();
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      if (!selectPlateForm) {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.defaultReview === true
        );
        setReviewPlatformInfos(reviewPlatformValue);
        setSelectPlateForm(reviewPlatformValue?.reviewPlatformId);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      } else {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.reviewPlatformId === selectPlateForm
        );
        setReviewPlatformInfos(reviewPlatformValue);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      }
    }
  }, [
    selectPlateForm,
    userCurrentBusinessDetails,
    reviewPlatformInfos,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  useEffect(() => {
    const fetchDashboard = async () => {
      if (selectPlateForm) {
        const payload = {
          query: reviewPlatformData.getAllBusinessReviews,
          variables: {
            placeId: reviewPlaceId,
            platformId: parseInt(selectPlateForm, 10),
            pageNumber: 1,
            pageSize: 3,
            sortBy: 'newest',
            sortOrder: 'desc',
            ...(parseInt(selectPlateForm, 10) === 3 && {
              ratingType: zomatoRatingType,
            }),
          },
        };
        const result = await getDataToServer(payload);
        setReviewData(result?.getAllBusinessReviews?.data || []);
      }
    };
    fetchDashboard();
  }, [dispatch, reviewPlaceId, selectPlateForm, zomatoRatingType]);

  function handlePlatformChange(event) {
    const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === event?.target?.value
    );
    setSelectPlateForm(event?.target?.value);
    setReviewPlaceId(reviewPlatformInfo?.placeId);
  }

  const handlePlatformRedirect = async (event) => {
    setSelectPlateFormIDForRedirect(event);
    const payload = {
      query: reviewPlatformData.defaultReviewPlatform,
      variables: {
        businessId: userCurrentBusinessDetails?.id,
        defaultPlatformId: event,
      },
    };
    const result = await getDataToServer(payload);

    if (
      result?.defaultReviewPlatform?.status === 400 ||
      result?.defaultReviewPlatform?.status === 403
    ) {
      enqueueSnackbar(result?.defaultReviewPlatform.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (result?.defaultReviewPlatform?.status === 200) {
      enqueueSnackbar(result?.defaultReviewPlatform.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      if (userCurrentBusinessDetails?.id) {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        dispatch(setUserCurrentBusinessDetailsValue(res?.getDashboard?.data?.[0]));
      }
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40 h-full l-padding">
      <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-28">
        <div className="col-span-2">
          <div className="mb-20">
            <DashboardAlerts />
          </div>
          <div className="flex gap-28">
            <div className="bg-white rounded-md relative shadow md:p-20 p-20 w-full">
              <h2 className="text-18 break-all font-semibold">
                {userCurrentBusinessDetails?.name}
              </h2>
              <div className="relative w-full max-w-586 rounded-md my-16 h-256 flex items-center justify-center">
                {loading && <Loading />}
                <img
                  src={
                    userCurrentBusinessDetails?.profileURL ||
                    '/assets/images/business/businessprofile.png'
                  }
                  className="w-full max-w-586 rounded-md my-16 h-256"
                  alt="Business profile"
                  style={{ display: loading ? 'none' : 'block' }}
                  onLoad={() => setLoading(false)}
                  onError={() => setLoading(false)}
                />
              </div>
              <Typography className="text-16 font-medium">
                {userCurrentBusinessDetails?.address || userCurrentBusinessDetails?.address2}
              </Typography>
            </div>
            <div className="bg-white rounded-md relative shadow md:p-20 p-20 w-full max-w-400">
              <h3 className="text-18 font-semibold border-b-2 border-dashed border-grey-400 mb-16 pb-16">
                {t('businessDashboard.qrCode')}
              </h3>
              <span className="text-15 font-medium text-grey-700 mb-12 block">
                {t('businessDashboard.redirect')}
              </span>
              <Select
                className="block bg-grey-100 capitalize"
                id="country"
                value={parseInt(selectPlateFormIDForRedirect, 10)}
                size="small"
                displayEmpty
                disabled={userCurrentBusinessDetails?.isOnlineBusiness}
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                onChange={(e) => {
                  handlePlatformRedirect(e?.target?.value);
                }}
              >
                {userCurrentBusinessDetails?.reviewplatform?.map((option) => (
                  <MenuItem
                    key={option.reviewPlatformId}
                    value={option?.reviewPlatformId}
                    className="capitalize"
                  >
                    {option.name === 'quickreviews' ? 'Quick reviews' : option.name}
                  </MenuItem>
                ))}
              </Select>
              <div className="text-center border-1 border-dashed border-darkgreen rounded-md qrcode-height flex items-center mt-16">
                <div className="block mx-auto">
                  <div className="block mx-auto">
                    <img
                      src="/assets/images/business/dashboard_qr.svg"
                      className="img-fluid mx-auto max-w-92 mb-20"
                      alt="..."
                    />
                  </div>
                  <Button
                    variant="contained"
                    className="rounded-md px-20 mx-auto"
                    color="secondary"
                    size="small"
                    type="button"
                    onClick={() => {
                      const id = getParamsId();
                      if (id) {
                        history.push(`/qrCode/${id}`);
                      } else {
                        history.push('/qrCode');
                      }
                    }}
                  >
                    {t('businessDashboard.open')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {(reviewData?.length > 0 || parseInt(selectPlateForm, 10) === 3) && (
            <div>
              <LatestReviews
                reviewData={reviewData}
                selectPlateForm={selectPlateForm}
                handlePlatformChange={handlePlatformChange}
                zomatoRatingType={zomatoRatingType}
                setZomatoRatingType={setZomatoRatingType}
              />
            </div>
          )}
        </div>
        <div>
          <BusinessPlateForm />
          <BusinessSetUp />
        </div>
        <div />
      </div>
      {!userCurrentBusinessDetails?.isOnlineBusiness && (
        <div className="grid grid-cols-3 col-span-1 gap-16">
          <div className="bg-white rounded-md relative shadow p-20 w-full mt-16">
            <h3 className="text-18 font-bold mb-16 items-center">
              {t('metrics.receivedReview')}{' '}
              {dateDetails?.[0]?.reviewPeriod && dateDetails?.[dateDetails.length - 1]?.reviewPeriod
                ? `(${moment.unix(dateDetails[0].reviewPeriod).format('ll').split(',')[0]} - ${
                    moment
                      .unix(dateDetails[dateDetails.length - 1].reviewPeriod)
                      .format('ll')
                      .split(',')[0]
                  })`
                : ''}
            </h3>
            <ReceivedReviews
              reviewPlatformId={selectPlateForm}
              useArea="dashboard"
              setDateDetails={setDateDetails}
            />
          </div>
          <div className="bg-white rounded-md relative shadow p-20 w-full mt-16">
            <h3 className="text-18 font-bold mb-16">{t('metrics.reviewDistribution')}</h3>
            <ReviewDistribution
              reviewPlatformId={selectPlateForm}
              totalReview={
                !userCurrentBusinessDetails?.isOnlineBusiness ||
                parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                  ? reviewPlatformInfos?.reviewPlatformId === 3
                    ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                    : reviewPlatformInfos?.totalReview
                  : selectedProduct?.totalReviews
              }
              averageRating={
                !userCurrentBusinessDetails?.isOnlineBusiness ||
                parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                  ? reviewPlatformInfos?.reviewPlatformId === 3
                    ? reviewPlatformInfos?.aggregateData?.aggregateAverageRating
                    : reviewPlatformInfos?.totalRatingStars
                  : selectedProduct?.rating
              }
              color={['#029C00', '#95B001', '#F59001', '#F45604', '#D12022']}
              categories={['5 ★', '4 ★', '3 ★', '2 ★', '1 ★']}
              useArea="dashboard"
            />
          </div>
          <div className="bg-white rounded-md relative shadow p-20 w-full mt-16 mb-16">
            <ReviewSentimentChart
              reviewSentiment={reviewSentiment}
              reviewPlatformId={selectPlateForm}
              totalReview={
                reviewPlatformInfos?.reviewPlatformId === 3
                  ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                  : reviewPlatformInfos?.totalReview
              }
              sizes="65%"
              dataLabel="true"
              useArea="dashboard"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default withReducer('businessDashboardsReducer', reducer)(Dashboards);

import withReducer from 'app/store/withReducer';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect, useState, useRef, useMemo } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import adminData from '../../query/admin';
import {
  getDashboardAdmin,
  getDashboardAdminSectionSalesPerson,
  getBusinessAndUserAddedLastMonth,
  resetDashboards,
} from './store/dashboardsSlice';
import reducer from './store';

function Dashboards() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  let salesPersonArray = [];
  let adminDetailsValue = [];
  const lastBusiness = [];
  const lastMonthBusiness = [];
  const lastMonthUsers = [];

  const getAdminDashboardDetails = useSelector(
    ({ dashboardsReducer }) =>
      dashboardsReducer?.dashboards?.getDashboardAdminData?.data?.getDashboardAdmin?.data
        ?.dashboardResponse || ''
  );

  if (getAdminDashboardDetails) {
    const adminDetails = JSON.parse(getAdminDashboardDetails);
    adminDetailsValue = adminDetails;

    adminDetailsValue?.business?.adminDashboardData?.numBusinessTypeWise
      ?.slice(0, 7)
      ?.map((type) => lastBusiness?.push(type));
  }

  const getDashboardSalesPersonDetails = useSelector(
    ({ dashboardsReducer }) =>
      dashboardsReducer?.dashboards?.getDashboardAdminSectionSalesPersonData?.data
        ?.getDashboardAdminSectionSalesPerson?.data?.dashboardResponse || ''
  );
  const getAddedBusinessAndUserDetails = useSelector(
    ({ dashboardsReducer }) =>
      dashboardsReducer?.dashboards?.getBusinessAndUserAddedLastMonthData?.data
        ?.getBusinessAndUserAddedLastMonth?.data || ''
  );
  if (getAddedBusinessAndUserDetails?.businessesData) {
    getAddedBusinessAndUserDetails?.businessesData?.map((data) =>
      lastMonthBusiness?.push({ x: moment(data.cts).format('MMM DD, YYYY'), y: data.count })
    );
  }

  if (getAddedBusinessAndUserDetails?.usersData) {
    getAddedBusinessAndUserDetails?.usersData?.map((data) =>
      lastMonthUsers?.push({ x: moment(data.cts).format('MMM DD, YYYY'), y: data.count })
    );
  }

  if (getDashboardSalesPersonDetails) {
    salesPersonArray = JSON.parse(getDashboardSalesPersonDetails);
  }

  useEffect(() => {
    const payload = {
      query: adminData.getDashboardAdmin,
    };
    dispatch(getDashboardAdmin(payload));

    return () => {
      dispatch(resetDashboards());
    };
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      query: adminData.getBusinessAndUserAddedLastMonth,
    };
    dispatch(getBusinessAndUserAddedLastMonth(payload));
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      query: adminData.getDashboardAdminSectionSalesPerson,
    };
    dispatch(getDashboardAdminSectionSalesPerson(payload));
  }, [dispatch]);

  useEffect(() => {
    if (getAdminDashboardDetails || getAdminDashboardDetails === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [getAdminDashboardDetails]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getChart(data, index, color, types, name) {
    const chart = {
      series: [
        {
          name,
          data,
          show: false,
        },
      ],

      options: {
        grid: {
          show: false,
          forceNiceScale: false,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          height: '100%',
          type: types,
          background: 'transparent',
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          colors: [color],
          width: [2, 2],
        },
        tooltip: {
          followCursor: true,
          theme: 'dark',
        },
        fill: {
          type: 'gradient',
          colors: color,
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          show: false,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        style={{ height: '195px' }}
        className="flex flex-col flex-auto w-full"
      >
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height={195}
        />
      </div>
    );
  }

  function getChartDonut(data, index, types) {
    const chart = {
      series: data,
      options: {
        labels: [
          'Premium accounts',
          'Trial accounts',
          'Enterprise accounts',
          'Standard accounts',
          'Professional accounts',
        ],
        colors: ['#E52592', '#F2B443', '#E8710A', '#3E8CF3', '#008000'],
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
            },
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          width: '100%',
          type: types,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: false,
          },
        },
        aspectRatio: 2,
        total: {
          show: true,
        },
        legend: {
          position: 'bottom',
          markers: {
            width: 24,
            height: 18,
            radius: 5,
          },
          itemMargin: {
            horizontal: 15,
            vertical: 15,
          },
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        style={{ height: '350px', width: '100%' }}
        className="flex flex-col flex-auto w-full"
      >
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height={400}
          width="100%"
        />
      </div>
    );
  }

  function getChartSimple(data, business, index, color, types) {
    const chart = {
      series: [
        {
          name: t('dashboard.dashboardAdmin.totalBusiness'),
          data,
        },
      ],
      options: {
        grid: {
          show: true,
          forceNiceScale: false,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'right',
            },
            horizontal: true,
            borderRadius: 3,
            barHeight: '35px',
          },
        },
        fill: {
          colors: color,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: business,
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              fontSize: '14px',
              fontWeight: '600',
              color: '#006B5A',
            },
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: 'left',
            maxWidth: 350,
            offsetX: -26,
            style: {
              fontSize: '14px',
            },
          },
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        // style={{ height: '100%', width: '100%' }}
        className="flex flex-col flex-auto w-full"
      >
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  }

  return (
    <div className="p-20 lg:p-40 l-padding">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-20 lg:gap-40">
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.account?.adminDashboardData?.numStandardAccounts}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.standardAccounts')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/basic_accounts.png"
              width="50"
              height="50"
              alt="standard_accounts"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg  Trial Accountsmd:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.account?.adminDashboardData?.numTrialAccounts}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.trialAccounts')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/trial_accounts.png"
              width="50"
              height="50"
              alt="trial_accounts"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.account?.adminDashboardData?.numPremiumAccounts}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.premiumAccounts')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/premium_accounts.png"
              width="50"
              height="50"
              alt="premium_accounts"
            />
          </div>
        </div>

        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.account?.adminDashboardData?.numEnterpriseAccounts}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.numEnterpriseAccounts')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/enterprise_account.png"
              width="50"
              height="50"
              alt="enterprise_accounts"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.account?.adminDashboardData?.numProfessionalAccounts}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.numProfessionalAccounts')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/enterprise_account.png"
              width="50"
              height="50"
              alt="enterprise_accounts"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numNewUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.newUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/new_users.png"
              width="50"
              height="50"
              alt="new_users"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numTotalUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.totalUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/total_users.png"
              width="50"
              height="50"
              alt="total_users"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numActiveUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.activeUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/active_users.png"
              width="50"
              height="50"
              alt="active_users"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numDeletedUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.deletedUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/deleted_users.png"
              width="50"
              height="50"
              alt="deleted_users"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numInactiveUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.inactiveUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/inactive_users.png"
              width="50"
              height="50"
              alt="inactive_users"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.salesVisit?.adminDashboardData?.numTotalSalesVisits}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.salesVisits')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/sales_visits.png"
              width="50"
              height="50"
              alt="sales_visits"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.salesVisit?.adminDashboardData?.numPendingSalesVisits}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.pendingSalesVisits')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/pending_sales_visits.png"
              width="50"
              height="50"
              alt="pending_sales_visits"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.salesVisit?.adminDashboardData?.numCompletedSalesVisits}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.completedSalesVisits')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/completed_sales_visits.png"
              width="50"
              height="50"
              alt="completed_sales_visits"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-16 p-12">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-20 lg:text-32 text-black font-semibold block">
                {adminDetailsValue?.user?.adminDashboardData?.numPendingUsers}
              </span>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mt-10 block text-black">
                {t('dashboard.dashboardAdmin.pendingUsers')}
              </span>
            </div>
            <img
              src="/assets/images/dashboard-icons/pending_users.png"
              width="50"
              height="50"
              alt="pending_users"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 lg:gap-40 mt-20 lg:mt-40">
        {/* <div className="shadow-md bg-white rounded-lg md:p-18 p-12">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start">
              <img
                src="/assets/images/dashboard-icons/total_downloads.png"
                width="35"
                height="35"
                alt="total_downloads"
              />
              <span className="text-16 md:text-18 lg:text-20 font-semibold ml-16 block text-black">
                {t('dashboard.dashboardAdmin.totalDownloads')}
              </span>
            </div>
            <span className="text-20 lg:text-32 text-black font-semibold block">
              {adminDetailsValue?.downloads?.numTotalDownloads}
            </span>
          </div>
        </div> */}
        {/* <div className="shadow-md bg-white rounded-lg md:p-18 p-12">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start">
              <img
                src="/assets/images/dashboard-icons/app_store_downloads.png"
                width="35"
                height="35"
                alt="app_store_downloads"
              />
              <span className="text-16 md:text-18 lg:text-20 font-semibold ml-16 block text-black">
                {t('dashboard.dashboardAdmin.appStoreDownloads')}
              </span>
            </div>
            <span className="text-20 lg:text-32 text-black font-semibold block">
              {adminDetailsValue?.downloads?.numAppStoreDownloads}
            </span>
          </div>
        </div> */}
        {/* <div className="shadow-md bg-white rounded-lg md:p-18 p-12">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start">
              <img
                src="/assets/images/dashboard-icons/play_store_downloads.png"
                width="35"
                height="35"
                alt="play_store_downloads"
              />
              <span className="text-16 md:text-18 lg:text-20 font-semibold ml-16 block text-black">
                {t('dashboard.dashboardAdmin.playStoreDownloads')}
              </span>
            </div>
            <span className="text-20 lg:text-32 text-black font-semibold block">
              {adminDetailsValue?.downloads?.numPlayStoreDownloads}
            </span>
          </div>
        </div> */}
        <div className="shadow-md bg-white rounded-lg md:p-18 p-12">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start">
              <img
                src="/assets/images/dashboard-icons/total_businesses.png"
                width="35"
                height="35"
                alt="total_businesses"
              />
              <span className="text-16 md:text-18 lg:text-20 font-semibold ml-16 block text-black">
                {t('dashboard.dashboardAdmin.totalBusinesses')}
              </span>
            </div>
            <span className="text-20 lg:text-32 text-black font-semibold block">
              {adminDetailsValue?.business?.adminDashboardData?.numTotalBusinesses}
            </span>
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-18 p-12">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start">
              <img
                src="/assets/images/dashboard-icons/active_businesses.png"
                width="35"
                height="35"
                alt="active_businesses"
              />
              <span className="text-16 md:text-18 lg:text-20 font-semibold ml-16 block text-black">
                {t('dashboard.dashboardAdmin.activeBusinesses')}
              </span>
            </div>
            <span className="text-20 lg:text-32 text-black font-semibold block">
              {adminDetailsValue?.business?.adminDashboardData?.numActiveBusinesses}
            </span>
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-18 p-20 sm:row-span-2">
          <h3 className="text-16 lg:text-20 font-semibold text-black">
            {t('dashboard.dashboardAdmin.accountReport')}
          </h3>
          <span className="text-14 font-semibold text-black mb-16 block">
            {t('dashboard.dashboardAdmin.total')}{' '}
            {adminDetailsValue?.account?.adminDashboardData?.numTotalAccounts}{' '}
            {t('dashboard.dashboardAdmin.totalAccounts')}
          </span>
          <div>
            {getChartDonut(
              [
                adminDetailsValue?.account?.adminDashboardData?.numPremiumAccounts,
                adminDetailsValue?.account?.adminDashboardData?.numTrialAccounts,
                adminDetailsValue?.account?.adminDashboardData?.numEnterpriseAccounts,
                adminDetailsValue?.account?.adminDashboardData?.numStandardAccounts,
                adminDetailsValue?.account?.adminDashboardData?.numProfessionalAccounts,
              ],
              4,
              'donut'
            )}
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-18 p-20 sm:col-span-2">
          <h3 className="text-16 lg:text-20 font-semibold text-black">
            {t('dashboard.dashboardAdmin.topBusinessTypes')}
          </h3>
          <span className="text-12 lg:text-14 font-semibold text-black block lg:text-right">
            {t('dashboard.dashboardAdmin.total')}{' '}
            {adminDetailsValue?.business?.adminDashboardData?.numTotalBusinesses}{' '}
            {t('dashboard.dashboardAdmin.totalBusinessTypes')}
          </span>
          <div>
            {getChartSimple(
              lastBusiness?.map((bType) => bType?.value?.numTotal),
              lastBusiness?.map((bType) => bType?.name),
              1,
              '#03CEF3',
              'bar'
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-40 mt-20 lg:mt-40">
        <div className="shadow-md bg-white rounded-lg">
          <div className="p-20 pb-0">
            <h3 className="text-16 lg:text-20 font-semibold text-black">
              {t('dashboard.dashboardAdmin.businessAddedLastMonth')}
            </h3>
            <span className="text-28 font-semibold text-black">
              {getAddedBusinessAndUserDetails?.businessesInMonth}
            </span>
          </div>
          <div className="relative overflow-hidden">
            {getChart(lastMonthBusiness, 2, '#3E8CF3', 'area', 'Business')}
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg h-full">
          <div className="p-20 pb-0">
            <h3 className="text-16 lg:text-20 font-semibold text-black">
              {t('dashboard.dashboardAdmin.userAddedLastMonth')}
            </h3>
            <span className="text-28 font-semibold text-black">
              {getAddedBusinessAndUserDetails?.usersInMonth}
            </span>
          </div>
          <div>{getChart(lastMonthUsers, 3, '#F2B443', 'area', 'Users')}</div>
        </div>
      </div>
      <div className="md:flex mt-20 lg:mt-40 gap-20 lg:gap-40">
        <div className="lg:w-3/5">
          <div className="shadow-md bg-white rounded-lg p-16">
            <h3 className="text-16 lg:text-20 font-semibold text-black">
              {t('dashboard.dashboardAdmin.topSalesPersons')}
            </h3>
            <div className="flex justify-between items-center">
              <span className="text-14 font-semibold text-black">
                {t('dashboard.dashboardAdmin.total')}{' '}
                {salesPersonArray?.adminDashboardData?.numTotalSalesPersons}{' '}
                {t('dashboard.dashboardAdmin.totalSalesPersons')}
              </span>
              <Link
                to="/sales-person"
                role="button"
                className="text-white bg-darkgreen hover:bg-darkgreen-800 py-4 px-7 rounded-md cursor-pointer"
              >
                View All
              </Link>
            </div>

            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="pl-0 border-b-1 pb-2 py-10 border-solid border-grey-500 text-grey-600">
                      {t('dashboard.dashboardAdmin.salesPersonsName')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.demoAccounts')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.realAccounts')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.standardAccounts')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.freeAccounts')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 pr-0"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.premiumAccounts')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesPersonArray &&
                    salesPersonArray?.adminDashboardData?.salesPersonsActivity
                      ?.slice(0, 10)
                      ?.map((row) => (
                        <TableRow
                          key={row.name}
                          className=""
                          sx={{
                            '& td, th': { borderBottom: '1px dashed #949494', color: '#000000' },
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            className="pl-0 font-medium py-10 capitalize"
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.value?.numTotalDemoAccount}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.value?.numTotalConvertedAccount}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.value?.numStandardAccount}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.value?.numFreeAccount}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.value?.numPremiumAccount}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="lg:w-2/5">
          <div className="shadow-md bg-white rounded-lg p-16">
            <h3 className="text-16 lg:text-20 font-semibold text-black">
              {t('dashboard.dashboardAdmin.topSubscriber')}
            </h3>
            <div className="flex justify-between items-center">
              <span className="text-14 font-semibold text-black">
                {t('dashboard.dashboardAdmin.total')}{' '}
                {adminDetailsValue?.topSubscriber?.adminDashboardData?.length}{' '}
                {t('dashboard.dashboardAdmin.Subscriber')}
              </span>
              {/* <Link
                to="/sales-person"
                role="button"
                className="text-white bg-darkgreen-900 hover:bg-darkgreen-800 py-4 px-7 rounded-md cursor-pointer"
              >
                View All
              </Link> */}
            </div>

            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="pl-0 border-b-1 pb-2 py-10 border-solid border-grey-500 text-grey-600">
                      {t('dashboard.dashboardAdmin.SubscriberName')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.dateOfJoining')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.totalInMonth')}
                    </TableCell>
                    <TableCell
                      className="pl-0 border-b-1 py-10 border-solid border-grey-500 text-grey-600"
                      align="right"
                    >
                      {t('dashboard.dashboardAdmin.planName')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminDetailsValue?.topSubscriber?.adminDashboardData?.length > 0 &&
                    adminDetailsValue?.topSubscriber?.adminDashboardData
                      ?.slice(0, 10)
                      ?.map((row) => (
                        <TableRow
                          key={row.userId}
                          className=""
                          sx={{
                            '& td, th': { borderBottom: '1px dashed #949494', color: '#000000' },
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            className="pl-0 font-medium py-10 capitalize"
                            component="th"
                            scope="row"
                          >
                            {row.userFullName}
                          </TableCell>
                          <TableCell className="font-medium p-0" align="center">
                            {moment(row?.dateOfJoining).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.totalNoOfMonths} Month
                          </TableCell>
                          <TableCell className="font-medium py-10" align="center">
                            {row?.currentPlanOfThatUser}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withReducer('dashboardsReducer', reducer)(Dashboards);

import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import CryptoJS from 'crypto-js';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import axios from 'axios';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Collapse from '@mui/material/Collapse';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import qrData from '../query/qrScanner';
import {
  getBusinessQRDetail,
  submitBusinessReview,
  addVisitorCountBasedOnIPAddress,
  resetqrScanner,
} from './store/qrScannerSlice';
import reducer from './store';

const useStyles = makeStyles((theme) => ({
  customCheckbox: {
    position: 'absolute',
    opacity: '0',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
  },
}));

/**
 * Form Validation Schema
 */
// const schema = yup.object().shape({
//   review: yup.string().required('Please Enter Review Text..'),
// });

const defaultValues = {
  review: '',
};

function AppReview() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const qrDetails = {
    data: {
      getBusinessQRDetail: {
        data: {
          id: '1951',
          name: 'Quick Reviews',
          platFormURL: 'https://play.google.com/store/apps/details?id=reviewApp.dev&hl=en-IN',
          reviewModeTypeId: 1,
          reviewModeTypeName: 'Default Mode',
          isAutoTextGenerate: true,
          autoGenerateReviewComplexityTypeId: 1,
          review_platforms: {
            id: 2,
            name: 'google',
          },
          business_types: {
            bType: 'Quick Reviews',
            image:
              'https://quick-review.s3.amazonaws.com/business-type-image/desktop_background_image.jpg',
            business_attributes: [
              {
                bAttribute: 'User Experience',
                bAttributeId: '18715',
                icon: null,
                business_attribute_values: {
                  id: 4,
                  type: 'NPS',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
              {
                bAttribute: 'Accuracy and Trustworthiness',
                bAttributeId: '18714',
                icon: null,
                business_attribute_values: {
                  id: 4,
                  type: 'NPS',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
              {
                bAttribute: 'Satisfaction With Service and Product',
                bAttributeId: '18116',
                icon: null,
                business_attribute_values: {
                  id: 2,
                  type: 'Faces',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
              {
                bAttribute: 'Integration with other Platforms',
                bAttributeId: '19503',
                icon: null,
                business_attribute_values: {
                  id: 3,
                  type: 'Stars',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
              {
                bAttribute: 'Auto Generated Review Process',
                bAttributeId: '13109',
                icon: 'star',
                business_attribute_values: {
                  id: 1,
                  type: 'Thumbs',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
              {
                bAttribute: 'Social Sharing',
                bAttributeId: '9774',
                icon: 'star',
                business_attribute_values: {
                  id: 2,
                  type: 'Faces',
                  reaction: '["1","2","3","1","2","3"]',
                },
              },
            ],
          },
        },
        message: 'Success',
        status: 200,
      },
    },
  };
  const businessDetails = useSelector(
    ({ qrScannerReducer }) => qrDetails?.data?.getBusinessQRDetail?.data || ''
  );

  const businessDetailsStatus = useSelector(
    ({ qrScannerReducer }) => qrDetails?.data?.getBusinessQRDetail
  );

  const submitStatus = useSelector(({ qrScannerReducer }) =>
    qrScannerReducer?.qrScanner?.submitBusinessReviewData?.data?.submitBusinessReview
      ? qrScannerReducer.qrScanner.submitBusinessReviewData.data.submitBusinessReview
      : {}
  );

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const faces = [
    {
      id: 1,
      value: 'bad',
    },
    { id: 2, value: 'okay' },
    { id: 3, value: 'good' },
  ];

  const thumbs = [
    {
      id: 1,
      value: 'no',
    },
    { id: 2, value: 'yes' },
  ];

  const [loading, setLoading] = useState(true);
  const [isAttributeLoading, setAttributeLoading] = useState(false);
  const [isActive, setIsActive] = useState('tab-0');
  const [isActiveButton, setIsActiveButton] = useState('tabButton-0');
  const [ratingValue, setRatingValue] = useState([]);
  const [facesValue, setFacesValue] = useState([]);
  const [starValue, setStarValue] = useState([]);
  const [likeValue, setLikeValue] = useState([]);
  const [attributeNumber, setAttributeNumber] = useState(1);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [reviewData, setReviewValue] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [textValue, setTextValue] = useState();
  const [rotateIcon, setRotateIcon] = useState(false);
  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(true);
  const [currentAttribute, setCurrentAttribute] = useState({});
  const [optionsWithReviewText, setOptionsWithReviewText] = useState([]);
  const [currentSelectedAttribute, setCurrentSelectedAttribute] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const businessQRId = routeParams.id;
  const indexValue = parseInt(isActive.split('-')[1], 10) + 1;
  const allAttributeData = [];
  const complexityMapping = {
    1: 'simple',
    2: 'moderate',
    3: 'advanced',
  };

  const langComplexity =
    complexityMapping[businessDetails?.autoGenerateReviewComplexityTypeId] || 'moderate';
  const SYMMETRIC_KEY_BASE64 = process.env.REACT_APP_SYMMETRIC_KEY_BASE;
  const IV_BASE64 = process.env.REACT_APP_IV_BASE;

  const symmetricKey = CryptoJS.enc.Hex.parse(
    CryptoJS.enc.Base64.parse(SYMMETRIC_KEY_BASE64).toString()
  );
  const iv = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(IV_BASE64).toString());
  allAttributeData.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);

  // useEffect(() => {
  //   let unmounted = false;
  //   const payload = {
  //     query: qrData.getBusinessQRDetail,
  //     variables: { businessQRId },
  //   };
  //   dispatch(getBusinessQRDetail(payload));

  //   return () => {
  //     unmounted = true;
  //   };
  // }, [businessQRId, dispatch]);

  useEffect(() => {
    if (submitStatus?.status === 200) {
      setRefreshIcon(false);
      reset(defaultValues);
      setRatingValue([]);
      setFacesValue([]);
      setStarValue([]);
      setLikeValue([]);
      // window.open(`${businessDetails?.platFormURL}`, '_blank');
      // history.push(businessDetails?.platFormURL);
      dispatch(resetqrScanner());
    }
  }, [businessDetails?.platFormURL, reset, dispatch, submitStatus]);

  function onSubmitRedirect() {
    setAttributeLoading(true);
    onSubmit();
    setTimeout(() => {
      const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
      if (navigator.userAgent.match(/iPhone/i) || isSafari) {
        window.location.replace('https://itunes.apple.com/app/quick-reviews/id6449814972');
      } else if (navigator.userAgent.match(/Android/i) || !isSafari) {
        window.location.replace('https://play.google.com/store/apps/details?id=reviewApp.dev');
      }
      setAttributeLoading(false);
      return null;
    }, 2000);
  }

  useEffect(() => {
    if (businessDetails) {
      if (businessDetails?.reviewModeTypeId === 5) {
        setLoading(true);
        history.push(businessDetails?.platFormURL);
      } else if (businessDetails?.reviewModeTypeId === 4) {
        setCurrentSelectedAttribute({
          ...businessDetails?.business_types?.business_attributes.at(0),
          reviewType:
            businessDetails?.business_types?.business_attributes.at(0).business_attribute_values
              .type,
        });
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [businessDetails]);

  useEffect(() => {
    if (businessDetailsStatus?.status === 404) {
      setLoading(false);
    }
  }, [businessDetailsStatus?.status]);

  function onHandleReviews(e, item) {
    const {
      bAttribute: name,
      bAttributeId,
      business_attribute_values: businessAttributeValues,
    } = item;
    const { type, id: typeId } = businessAttributeValues;
    setTextValue('');
    setCurrentSelectedAttribute({
      ...item,
      reviewType: type,
    });
    const reviewValue = parseInt(e.target.value, 10);
    let reviewsData = [];
    let setReviewData = [];
    switch (type) {
      case 'Stars':
        reviewsData = starValue;
        break;
      case 'Faces':
        reviewsData = facesValue;
        break;
      case 'Thumbs':
        reviewsData = likeValue;
        break;
      case 'NPS':
        reviewsData = ratingValue;
        break;
      default:
        break;
    }
    const appendData = {
      name,
      review: reviewValue,
      attributeId: parseInt(typeId, 10),
      bAttributeId: parseInt(bAttributeId, 10),
      reviewType: type,
    };
    setCurrentAttribute(appendData);
    if (reviewsData.find((obj) => obj.name === name)) {
      const foundIndex = reviewsData.findIndex((x) => x.name === name);
      reviewsData[foundIndex] = appendData;
      setReviewData = [...reviewsData];
    } else {
      setReviewData = [...reviewsData, appendData];
    }
    switch (type) {
      case 'Stars':
        setStarValue(setReviewData);
        break;
      case 'Faces':
        setFacesValue(setReviewData);
        break;
      case 'Thumbs':
        setLikeValue(setReviewData);
        break;
      case 'NPS':
        setRatingValue(setReviewData);
        break;
      default:
        break;
    }
    if (businessDetails?.reviewModeTypeId === 3) {
      handleNextButtonIndex();
    }
  }

  if (loading) {
    return <FuseLoading />;
  }

  const marks = [
    {
      value: 1,
      label: (
        <img src="/assets/images/icon/rating-1.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 2,
      label: (
        <img src="/assets/images/icon/rating-2.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 3,
      label: (
        <img src="/assets/images/icon/rating-3.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 4,
      label: (
        <img src="/assets/images/icon/rating-4.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 5,
      label: (
        <img src="/assets/images/icon/rating-5.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
  ];

  const IOSSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    padding: '15px 0',
    margin: ' 30px 0 0 0',
    '& .MuiSlider-thumb': {
      backgroundColor: '#fff',
      background: "url('/assets/images/icon/point.svg') no-repeat center",
      boxShadow: 'none',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'none',
      },
      '&:before': {
        boxShadow: 'none',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    "& .MuiSlider-markLabel[data-index='4'] ": {
      right: -30,
    },
    '& .MuiSlider-markLabel ': {
      top: -30,
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
  }));
  function onSubmit() {
    // setRefreshIcon(true);
    let copyText;
    const options = [];
    const optionsData = [];

    options.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);
    if (businessDetails?.reviewModeTypeId === 4) {
      copyText = document.getElementById('split-review-text').textContent;
    }
    options.forEach((record) => {
      optionsData.push({
        review: record.review,
        attributeId: record.attributeId,
        bAttributeId: parseInt(record.bAttributeId, 10),
      });
    });
    const finalTextValue = businessDetails?.reviewModeTypeId === 4 ? copyText : textValue;
    // const payload = {
    //   query: qrData.submitBusinessReview,
    //   variables: {
    //     attributeDetail: optionsData,
    //     reviewDescription: finalTextValue,
    //     reviewPlatformId: businessDetails?.review_platforms?.id,
    //     businessId: parseInt(businessDetails?.id, 10),
    //     phoneNumber: {
    //       phoneNumber,
    //       countryCode,
    //     },
    //   },
    // };

    navigator.clipboard.writeText(finalTextValue);
    setTextValue(textValue);
    // dispatch(submitBusinessReview(payload));

    setDialogOpen(false);
  }
  function handleNextButtonIndex() {
    const indexNumber = parseInt(isActiveButton.split('-')[1], 10) + 1;
    if (indexNumber < businessDetails?.business_types?.business_attributes?.length) {
      setIsActiveButton(`tabButton-${indexNumber}`);
      setIsActive(`tab-${indexNumber}`);
    }
    setCurrentSelectedAttribute({
      ...businessDetails?.business_types?.business_attributes.at(indexNumber),
      reviewType:
        businessDetails?.business_types?.business_attributes.at(indexNumber)
          ?.business_attribute_values?.type,
    });
    setCurrentAttribute({
      ...currentAttribute,
      reviewType:
        businessDetails?.business_types?.business_attributes.at(indexNumber)
          ?.business_attribute_values?.type,
    });
    setAttributeNumber(indexNumber + 1);
  }
  function handleIncrementReviewValue(action) {
    setTextValue('');
    const currentAttributeId = parseInt(currentSelectedAttribute?.bAttributeId, 10);
    let compareAttributeId = [];
    switch (currentAttribute?.reviewType) {
      case 'Faces':
        compareAttributeId = facesValue;
        break;
      case 'Stars':
        compareAttributeId = starValue;
        break;
      case 'Thumbs':
        compareAttributeId = likeValue;
        break;
      case 'NPS':
        compareAttributeId = ratingValue;
        break;
      default:
        break;
    }
    let attributeData = compareAttributeId.find(
      (obj) => parseInt(obj.bAttributeId, 10) === parseInt(currentAttributeId, 10)
    );
    if (!attributeData) {
      attributeData = {
        ...currentSelectedAttribute,
      };
    }
    if (!attributeData?.review) {
      enqueueSnackbar('Select review first', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    const foundIndex = compareAttributeId.findIndex(
      (obj) => parseInt(obj.bAttributeId, 10) === parseInt(currentAttributeId, 10)
    );
    const setValue = {
      ...attributeData,
      review: action === '+' ? attributeData.review + 1 || 1 : attributeData.review - 1,
      reviewType: currentAttribute?.reviewType,
    };
    switch (currentAttribute?.reviewType) {
      case 'Faces':
        if (setValue.review <= 3 && setValue.review >= 1) {
          compareAttributeId[foundIndex] = setValue;
          setFacesValue(compareAttributeId);
          setCurrentAttribute(setValue);
        }
        break;
      case 'Stars':
        if (setValue.review <= 5 && setValue.review >= 1) {
          compareAttributeId[foundIndex] = setValue;
          setStarValue(compareAttributeId);
          setCurrentAttribute(setValue);
        }
        break;
      case 'Thumbs':
        if (setValue.review <= 2 && setValue.review >= 1) {
          compareAttributeId[foundIndex] = setValue;
          setLikeValue(compareAttributeId);
          setCurrentAttribute(setValue);
        }
        break;
      case 'NPS':
        if (setValue.review <= 5 && setValue.review >= 1) {
          compareAttributeId[foundIndex] = setValue;
          setRatingValue(compareAttributeId);
          setCurrentAttribute(setValue);
        }
        break;
      default:
        break;
    }
  }

  const handleFormSubmit = (data) => {
    setDialogOpen(false);
    setReviewValue(data);
  };
  function handleAddAndContinue() {
    if (!countryCode || !phoneNumber) {
      setErrorMessage('Please enter a valid country code and phone number');
      return;
    }
    setErrorMessage('');
    onSubmit(reviewData);
    false;
    setPhoneNumber('');
    setCountryCode('');
  }

  function handleSkipAndContinue() {
    setDialogOpen(false);
    onSubmit(reviewData);
  }
  function handlePhoneChange(event, data, value, formattedValue) {
    setCountryCode(data?.dialCode);
    const enteredPhoneNumber = event.slice(data.dialCode.length);
    setPhoneNumber(enteredPhoneNumber);
    setPhoneValue(event);
    if (enteredPhoneNumber.length !== 10) {
      setErrorMessage('Please enter a valid 10-digit phone number');
    } else {
      setErrorMessage('');
    }
  }

  async function handleSetReviewText(value) {
    setTextValue(value);
    const foundIndex = optionsWithReviewText.findIndex(
      (x) => parseInt(x?.bAttributeId, 10) === parseInt(currentSelectedAttribute?.bAttributeId, 10)
    );
    if (optionsWithReviewText.length > 0 && foundIndex >= 0) {
      if (optionsWithReviewText[foundIndex].reviewText) {
        optionsWithReviewText[foundIndex].reviewText = value;
      } else {
        optionsWithReviewText[foundIndex] = {
          ...optionsWithReviewText[foundIndex],
          reviewText: value,
        };
      }
      setOptionsWithReviewText(optionsWithReviewText);
    } else {
      optionsWithReviewText.push({
        ...currentSelectedAttribute,
        reviewText: value,
      });
      setOptionsWithReviewText(optionsWithReviewText);
    }
  }

  const handleCheckReview = () => {
    setOpen(!open);
  };
  const encryptData = (data, symmetricKey1, iv1) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, symmetricKey1, {
      iv: iv1,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encryptedData;
  };

  const decryptData = (responseData, symmetricKey2, iv2) => {
    const encryptedPayload = responseData?.data?.data;
    const decryptedData = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(encryptedPayload) },
      symmetricKey2,
      {
        iv: iv2,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString(CryptoJS.enc.Utf8);

    return decryptedData;
  };
  async function sendReviewRequest(combinedData) {
    return axios({
      // Endpoint to send files
      url: process.env.REACT_APP_AI_APP_STORE_QUICK_REVIEW,
      method: 'post',
      mode: 'cors',
      withCredentials: false,
      headers: {
        // Add any auth token here
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTU5LCJmaXJzdE5hbWUiOiJBZG1pbiIsImxhc3ROYW1lIjoiVXNlciIsIm1pZGRsZU5hbWUiOiJKIiwiZW1haWwiOiJhZG1pbkBxdWlja3Jldmlld3MuY29tIiwidXNlclR5cGUiOiJhZG1pbiIsImNvdW50cmllcyI6MiwidGltZVpvbmUiOm51bGwsImRldmljZUlkIjoic2twaHNqc2YyaDZzb3FjY24iLCJpYXQiOjE2ODkwNjQ1NTMsImV4CI6MTY4OTQ5NjU1M30.n9AkUwpnzDx6mWYEsaMfovMcC4wl7ayS465ucm2e6X8',
        'Content-Type': 'text/plain',
      },
      // Attaching the form data
      data: combinedData,
    });
  }
  async function handleReview() {
    try {
      const options = [];
      const optionsData = [];
      const obj = {};
      options.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);

      options.forEach((record) => {
        let val;
        if (record?.reviewType === 'Faces') {
          faces.find((value) => {
            if (value?.id === record?.review) {
              val = value.value;
            }
            return '';
          });
        } else if (record?.reviewType === 'Thumbs') {
          thumbs.find((value) => {
            if (value?.id === record?.review) {
              val = value.value;
            }
            return '';
          });
        } else if (record.reviewType === 'NPS' || record.reviewType === 'Stars') {
          val = `${record.review}/5`;
        }

        optionsData.push({
          review: val,
          attributeId: record.attributeId,
          attributes: record.name,
          reviewType: record.reviewType,
        });
      });
      optionsData.forEach((element, index) => {
        obj[`${element.attributes}`] = element.review;
      });
      const reviewObj = {
        business_type: businessDetails?.business_types?.bType,
        attributes: obj,
        lang_complexity: langComplexity,
        review_mode: businessDetails?.reviewModeTypeName?.replace(' Mode', '').toLowerCase(),
        attribute_number: attributeNumber,
      };
      const combinedData = encryptData(reviewObj, symmetricKey, iv);
      setRotateIcon(true);
      sendReviewRequest(combinedData)
        .then((responseData) => {
          setTextValue(responseData?.data?.data?.generated_reviews);
          setRotateIcon(false);
        })
        .catch((error) => {
          console.log(error);
          setRotateIcon(false);
        });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function handleReviewSingle() {
    try {
      const option = [];
      const optionsData = [];
      const obj = {};
      let reviewObj = {};

      option.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);

      let val;
      if (currentAttribute?.reviewType === 'Faces') {
        faces.find((value) => {
          if (value?.id === currentAttribute?.review) {
            val = value.value;
          }
          return '';
        });
      } else if (currentAttribute?.reviewType === 'Thumbs') {
        thumbs.find((value) => {
          if (value?.id === currentAttribute?.review) {
            val = value.value;
          }
          return '';
        });
      } else if (currentAttribute.reviewType === 'NPS' || currentAttribute.reviewType === 'Stars') {
        val = `${currentAttribute.review}/5`;
      }
      optionsData.push({
        review: val,
        attributeId: currentAttribute.attributeId,
        attributes: currentAttribute.name,
        reviewType: currentAttribute.reviewType,
      });

      optionsData.forEach((element, index) => {
        obj[`${element.attributes}`] = element.review;
      });

      reviewObj = {
        business_type: businessDetails?.business_types?.bType,
        attributes: obj,
        lang_complexity: langComplexity,
        review_mode: businessDetails?.reviewModeTypeName?.replace(' Mode', '').toLowerCase(),
        attribute_number: attributeNumber,
      };
      const combinedData = encryptData(reviewObj, symmetricKey, iv);
      setRotateIcon(true);

      sendReviewRequest(combinedData)
        .then((responseData) => {
          setTextValue(responseData?.data?.data?.generated_reviews);
          const decryptedPayload = decryptData(responseData, symmetricKey, iv);
          const jsonObject = JSON.parse(decryptedPayload);
          const arrLen = parseInt(jsonObject?.generated_reviews?.length, 10);
          const randomIndex = Math.floor(Math.random() * arrLen);
          setRotateIcon(false);
          const foundIndex = option.findIndex((x) => x.name === currentAttribute.name);
          option[foundIndex].reviewText = jsonObject?.generated_reviews[randomIndex];
        })
        .catch((error) => {
          console.log(error);
          setRotateIcon(false);
        });
      setOptionsWithReviewText(option);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function handleReviewText(attrData) {
    if (businessDetails?.reviewModeTypeId === 4) {
      const attId = parseInt(attrData.bAttributeId, 10);
      setCurrentSelectedAttribute({
        ...attrData,
        bAttributeId: attId,
        reviewType: attrData?.business_attribute_values?.type,
      });
      // setCompareAttributeId
      const currentAttributeStatus = optionsWithReviewText.find((currAttr) => {
        return parseInt(currAttr.bAttributeId, 10) === attId;
      });
      setTextValue(currentAttributeStatus?.reviewText || '');
      setCurrentAttribute({
        ...currentAttribute,
        ...attrData,
        bAttributeId: attId,
        reviewType: attrData?.business_attribute_values?.type,
      });
    }
  }
  return (
    <div>
      {businessDetails?.reviewModeTypeId !== 5 && (
        <>
          <div className="relative mb-80">
            <div className="overflow-hidden relative">
              <img
                className="w-full cover-img"
                width="1920"
                height="423"
                src={businessDetails?.business_types?.image}
                alt={businessDetails?.name}
              />
            </div>
            <div
              className={`logo absolute left-0 right-0 review-logo ${
                businessDetails?.reviewModeTypeId === 2
                  ? 'formmode top-20 md:top-52'
                  : 'top-40 md:top-96 '
              }`}
            >
              <img className="mx-auto" src="assets/images/logo/logo.png" alt="logo" />
              {businessDetails?.reviewModeTypeId === 2 && (
                <h1 className="md:text-2xl text-14 font-bold mt-0 mb-12 text-center text-white mt-16">
                  {t('qrScanner.labels.enjoying')} {businessDetails?.name}?
                </h1>
              )}
            </div>

            <div className="md:p-0 p-16">
              <div
                className={`${
                  businessDetails?.reviewModeTypeId !== 2 ? 'bg-white md:p-32 p-16 rounded-3xl' : ''
                } w-full md:max-w-3xl md:mx-auto text-center relative md:-mt-96 -mt-80`}
              >
                {(businessDetails?.reviewModeTypeId === 1 ||
                  businessDetails?.reviewModeTypeId === 3 ||
                  businessDetails?.reviewModeTypeId === 4) && (
                  <h1 className="md:text-2xl text-14 font-bold mt-0 mb-12">
                    {t('qrScanner.labels.enjoying')} {businessDetails?.name}?
                  </h1>
                )}

                {/* Default Mode */}

                <div>
                  <Box role="group" aria-labelledby="rank" className="text-left">
                    {(businessDetails?.reviewModeTypeId === 1 ||
                      businessDetails?.reviewModeTypeId === 3 ||
                      businessDetails?.reviewModeTypeId === 4) && (
                      <List
                        orientation="horizontal"
                        className="mb-32"
                        sx={{
                          '--List-gap': '8px',
                          '--ListItem-radius': '20px',
                          '--ListItem-minHeight': '32px',
                        }}
                      >
                        {businessDetails?.business_types?.business_attributes &&
                          businessDetails?.business_types?.business_attributes?.length > 0 &&
                          businessDetails?.business_types?.business_attributes?.map(
                            (item, index) => (
                              <>
                                <ListItem
                                  key={index}
                                  className={`inline-block w-fit border border-grey-100 bg-grey-100 text-black md:py-5 py-3 md:my-8 my-4 mr-4 md:mr-8 rounded-full font-semibold text-14 md:text-lg tabButton-${index} ${
                                    isActiveButton === `tabButton-${index}`
                                      ? 'rating-title-active bg-white border-darkgreen text-darkgreen'
                                      : 'rating-title-inActive'
                                  } ${allAttributeData
                                    .filter((attribute) => {
                                      return attribute?.name === item?.bAttribute;
                                    })
                                    .map((attributeValue) => {
                                      return 'active-done';
                                    })}`}
                                >
                                  <Checkbox
                                    size="sm"
                                    className={`${classes.customCheckbox}`}
                                    label={item?.bAttribute}
                                    onClick={() => {
                                      setIsActive(`tab-${index}`);
                                      setIsActiveButton(`tabButton-${index}`);
                                      handleReviewText(item);
                                      setAttributeNumber(index + 1);
                                    }}
                                  />
                                  <span>
                                    <Icon className="text-18 align-middle mr-5 text-darkgreen md:inline-block m-icon relative -top-2">
                                      {item?.icon || 'star'}
                                    </Icon>
                                    <span className="capitalize inline-block">
                                      {item?.bAttribute}
                                    </span>
                                  </span>
                                </ListItem>
                              </>
                            )
                          )}
                      </List>
                    )}
                    {businessDetails?.business_types?.business_attributes &&
                      businessDetails?.business_types?.business_attributes?.length > 0 &&
                      businessDetails?.business_types?.business_attributes?.map((item, index) => (
                        <div
                          key={index}
                          className={` ${
                            businessDetails?.reviewModeTypeId === 1 ||
                            businessDetails?.reviewModeTypeId === 3 ||
                            businessDetails?.reviewModeTypeId === 4
                              ? `tab-${index} ${
                                  isActive === `tab-${index}`
                                    ? 'rating-content-active'
                                    : 'rating-content-inActive'
                                }`
                              : 'bg-white md:p-24 p-16 rounded-3xl mb-16'
                          }`}
                        >
                          {businessDetails?.reviewModeTypeId === 2 && (
                            <h2 className="md:text-2xl text-18 font-bold mt-0 mb-12">
                              {item?.bAttribute}
                            </h2>
                          )}
                          {item?.business_attribute_values?.type === 'Faces' && (
                            <Box className="mx-auto text-center mb-32">
                              <div className="mx-auto mb-32 rounded-full inline-block">
                                {parseInt(
                                  facesValue
                                    .filter((attribute) => {
                                      return attribute?.name === item?.bAttribute;
                                    })
                                    .map((attributeValue) => {
                                      return attributeValue.review;
                                    }),
                                  10
                                ) === 1 ? (
                                  <img
                                    src="/assets/images/icon/bad-face.svg"
                                    alt="bad"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    facesValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 2 ? (
                                  <img
                                    src="/assets/images/icon/okay-face.svg"
                                    alt="okay"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    facesValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 3 ? (
                                  <img
                                    src="/assets/images/icon/good-face.svg"
                                    alt="good"
                                    className="mx-auto"
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/icon/review-default.svg"
                                    width={132}
                                    height={132}
                                    alt="silent"
                                    className="mx-auto"
                                  />
                                )}
                              </div>
                              <RadioGroup
                                aria-labelledby={item?.bAttribute}
                                className="mx-auto w-full block text-center"
                                row
                                defaultValue="0"
                                name={item?.bAttribute}
                                value={(e) => facesValue(e)}
                                onChange={(event) => onHandleReviews(event, item)}
                              >
                                <FormControlLabel
                                  className="relative"
                                  value={1}
                                  control={
                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                  }
                                  label={
                                    <code>
                                      <img src="/assets/images/icon/bad.svg" alt="bad" />
                                      <span className="block mt-5 font-semibold">
                                        {t('qrScanner.labels.bad')}
                                      </span>
                                    </code>
                                  }
                                  labelPlacement="bottom"
                                />
                                <FormControlLabel
                                  className="relative"
                                  value={2}
                                  control={
                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                  }
                                  label={
                                    <code>
                                      <img src="/assets/images/icon/okay.svg" alt="okay" />
                                      <span className="block mt-5 font-semibold">
                                        {t('qrScanner.labels.okay')}
                                      </span>
                                    </code>
                                  }
                                  labelPlacement="bottom"
                                />
                                <FormControlLabel
                                  className="relative"
                                  value={3}
                                  control={
                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                  }
                                  label={
                                    <code>
                                      <img src="/assets/images/icon/good.svg" alt="good" />
                                      <span className="block mt-5 font-semibold">
                                        {t('qrScanner.labels.good')}
                                      </span>
                                    </code>
                                  }
                                  labelPlacement="bottom"
                                />
                              </RadioGroup>
                            </Box>
                          )}
                          {item?.business_attribute_values?.type === 'Thumbs' && (
                            <Box className="mx-auto text-center mb-32">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                className="mx-auto w-full block text-center"
                                row
                                defaultValue="1"
                                name={item?.bAttribute}
                                onChange={(event) => onHandleReviews(event, item)}
                                value={(e) => likeValue(e)}
                              >
                                <FormControlLabel
                                  className="relative"
                                  value={1}
                                  control={
                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                  }
                                  label={
                                    <code>
                                      {parseInt(
                                        likeValue
                                          .filter((attribute) => {
                                            return attribute?.name === item?.bAttribute;
                                          })
                                          .map((attributeValue) => {
                                            return attributeValue.review;
                                          }),
                                        10
                                      ) === 1 ? (
                                        <img
                                          src="/assets/images/icon/dislike-active.svg"
                                          alt="no"
                                        />
                                      ) : (
                                        <img
                                          src="/assets/images/icon/dislike-deactive.svg"
                                          alt="no"
                                        />
                                      )}
                                      <span className="block mt-5 font-semibold">No</span>
                                    </code>
                                  }
                                  labelPlacement="bottom"
                                />
                                <FormControlLabel
                                  className="relative"
                                  value={2}
                                  control={
                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                  }
                                  label={
                                    <code>
                                      {parseInt(
                                        likeValue
                                          .filter((attribute) => {
                                            return attribute?.name === item?.bAttribute;
                                          })
                                          .map((attributeValue) => {
                                            return attributeValue.review;
                                          }),
                                        10
                                      ) === 2 ? (
                                        <img src="/assets/images/icon/like-active.svg" alt="yes" />
                                      ) : (
                                        <img
                                          src="/assets/images/icon/like-deactive.svg"
                                          alt="yes"
                                        />
                                      )}
                                      <span className="block mt-5 font-semibold">
                                        {t('qrScanner.labels.yes')}
                                      </span>
                                    </code>
                                  }
                                  labelPlacement="bottom"
                                />
                              </RadioGroup>
                            </Box>
                          )}
                          {item?.business_attribute_values?.type === 'NPS' && (
                            <Box
                              sx={{ width: '100%', maxWidth: '476px' }}
                              className="mx-auto rating-ruler text-center"
                            >
                              <div className="mx-auto mb-32 rounded-full inline-block">
                                {parseInt(
                                  ratingValue
                                    .filter((attribute) => {
                                      return attribute?.name === item?.bAttribute;
                                    })
                                    .map((attributeValue) => {
                                      return attributeValue.review;
                                    }),
                                  10
                                ) === 1 ? (
                                  <img
                                    src="/assets/images/icon/rating-1.svg"
                                    alt="rating-1"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    ratingValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 2 ? (
                                  <img
                                    src="/assets/images/icon/rating-2.svg"
                                    alt="rating-2"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    ratingValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 3 ? (
                                  <img
                                    src="/assets/images/icon/rating-3.svg"
                                    alt="rating-3"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    ratingValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 4 ? (
                                  <img
                                    src="/assets/images/icon/rating-4.svg"
                                    alt="rating-4"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    ratingValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue?.review;
                                      }),
                                    10
                                  ) === 5 ? (
                                  <img
                                    src="/assets/images/icon/rating-5.svg"
                                    alt="rating-5"
                                    className="mx-auto"
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/icon/review-default.svg"
                                    alt="silent"
                                    className="mx-auto"
                                  />
                                )}
                              </div>
                              <IOSSlider
                                aria-label="Rating"
                                className="mb-32"
                                defaultValue={1}
                                value={
                                  parseInt(
                                    ratingValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue?.review;
                                      }),
                                    10
                                  ) || 0
                                }
                                step={1}
                                min={1}
                                max={5}
                                marks={marks}
                                onChange={(event) => onHandleReviews(event, item)}
                                valueLabelDisplay="off"
                              />
                            </Box>
                          )}
                          {item?.business_attribute_values?.type === 'Stars' && (
                            <Box className="mx-auto text-center mb-32">
                              <div className="mx-auto mb-32 rounded-full inline-block">
                                {parseInt(
                                  starValue
                                    .filter((attribute) => {
                                      return attribute?.name === item?.bAttribute;
                                    })
                                    .map((attributeValue) => {
                                      return attributeValue.review;
                                    }),
                                  10
                                ) === 1 ? (
                                  <img
                                    src="/assets/images/icon/star-1.svg"
                                    alt="rating-1"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    starValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 2 ? (
                                  <img
                                    src="/assets/images/icon/star-2.svg"
                                    alt="rating-2"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    starValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 3 ? (
                                  <img
                                    src="/assets/images/icon/star-3.svg"
                                    alt="rating-3"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    starValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 4 ? (
                                  <img
                                    src="/assets/images/icon/star-4.svg"
                                    alt="rating-4"
                                    className="mx-auto"
                                  />
                                ) : parseInt(
                                    starValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  ) === 5 ? (
                                  <img
                                    src="/assets/images/icon/star-5.svg"
                                    alt="rating-5"
                                    className="mx-auto"
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/icon/star-default.svg"
                                    alt="silent"
                                    className="mx-auto"
                                  />
                                )}
                              </div>
                              <div className="w-full mx-auto text-center">
                                <Rating
                                  name="star rating"
                                  value={parseInt(
                                    starValue
                                      .filter((attribute) => {
                                        return attribute?.name === item?.bAttribute;
                                      })
                                      .map((attributeValue) => {
                                        return attributeValue.review;
                                      }),
                                    10
                                  )}
                                  onChange={(event) => onHandleReviews(event, item)}
                                  icon={
                                    <StarIcon
                                      style={{ width: '28px', height: '28px' }}
                                      fontSize="inherit"
                                    />
                                  }
                                  emptyIcon={
                                    <StarOutlineIcon
                                      style={{
                                        opacity: 1,
                                        color: '#faaf00',
                                        width: '28px',
                                        height: '28px',
                                      }}
                                      fontSize="28px"
                                    />
                                  }
                                />
                              </div>
                            </Box>
                          )}
                        </div>
                      ))}
                  </Box>
                </div>

                <form
                  name="reviewForm"
                  noValidate
                  className={`flex flex-col justify-center w-full review-form rounded-md ${
                    businessDetails?.reviewModeTypeId === 2
                      ? 'bg-white md:p-24 p-16 rounded-3xl mb-16'
                      : ''
                  }`}
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  {businessDetails?.reviewModeTypeId === 2 && (
                    <h2 className="md:text-2xl text-18 font-bold mt-0 mb-20 text-left">
                      {t('qrScanner.labels.writeReview')}
                    </h2>
                  )}
                  <Controller
                    name="review"
                    className="border-none text-center"
                    control={control}
                    render={({ field }) => (
                      <div
                        className="relative mx-auto"
                        style={{ width: '100%', maxWidth: '550px' }}
                      >
                        <div className={`${businessDetails?.reviewModeTypeId === 4 ? 'flex' : ''}`}>
                          {businessDetails?.reviewModeTypeId === 4 &&
                            businessDetails?.isAutoTextGenerate && (
                              <>
                                {(ratingValue.length > 0 ||
                                  facesValue.length > 0 ||
                                  starValue.length > 0 ||
                                  likeValue.length > 0) && (
                                  <div className="bg-darkgreen-100 rounded-md w-28">
                                    <Icon
                                      className={`cursor-pointer text-darkgreen p-6 inline-block h-full w-full flex items-center justify-center ${
                                        rotateIcon ? 'animate-spin' : ''
                                      }`}
                                      onClick={handleReview}
                                    >
                                      refresh
                                    </Icon>
                                  </div>
                                )}
                              </>
                            )}
                          <div
                            className={`${
                              businessDetails?.reviewModeTypeId === 4 ? 'w-full mx-8' : ''
                            }`}
                          >
                            <TextField
                              {...field}
                              className="bg-grey-50 border-none rounded-md"
                              style={{ width: '100%', maxWidth: '550px' }}
                              type="text"
                              InputProps={{
                                endAdornment: !textValue ? (
                                  ratingValue.length > 0 ||
                                  facesValue.length > 0 ||
                                  starValue.length > 0 ||
                                  likeValue.length > 0 ? (
                                    <div className="absolute top-[5px] flex">
                                      <span className="text-black">
                                        {t('qrScanner.placeholder')}{' '}
                                        {businessDetails?.isAutoTextGenerate && 'or'}
                                      </span>
                                      {businessDetails?.isAutoTextGenerate && (
                                        <div className="flex">
                                          <button
                                            type="button"
                                            className={`px-6 ${
                                              rotateIcon ? 'text-gray' : 'text-darkgreen'
                                            } underline`}
                                            onClick={handleReview}
                                            disabled={rotateIcon}
                                          >
                                            {t('qrScanner.generateAiText')}
                                          </button>
                                          {rotateIcon ? (
                                            <img
                                              className="w-16 h-16"
                                              src="/assets/images/icon/Generate_Review-grey.png"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="w-16 h-16"
                                              src="/assets/images/icon/GenerateReview.png"
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <span className="absolute top-[5px]">
                                      {t('qrScanner.provideReview')}
                                    </span>
                                  )
                                ) : (
                                  ''
                                ),
                              }}
                              value={textValue}
                              onChange={(e) => {
                                handleSetReviewText(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                setTextValue(e.target.value);
                              }}
                              multiline
                              minRows={3}
                              error={!!errors.review}
                              helperText={errors?.review?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                            {businessDetails?.isAutoTextGenerate &&
                              (ratingValue.length > 0 ||
                                facesValue.length > 0 ||
                                starValue.length > 0 ||
                                likeValue.length > 0) && (
                                <Icon
                                  className={`absolute bottom-80 mb-8 text-20 right-5 cursor-pointer bg-darkgreen text-white rounded-full p-6 inline-block w-24 h-24 flex items-center justify-center ${
                                    businessDetails?.reviewModeTypeId === 4
                                      ? 'right-44 bottom-72 hidden'
                                      : ''
                                  } ${rotateIcon ? 'animate-spin' : ''}`}
                                  onClick={handleReview}
                                >
                                  refresh
                                </Icon>
                              )}
                          </div>

                          {businessDetails?.reviewModeTypeId === 4 && (
                            <div>
                              <div className="w-28 h-1/2">
                                <div className="pb-4 h-full">
                                  <Icon
                                    className="bg-darkgreen-100 rounded-md cursor-pointer text-darkgreen block h-full w-full flex items-center justify-center"
                                    onClick={(e) => {
                                      handleIncrementReviewValue('+');
                                    }}
                                  >
                                    add
                                  </Icon>
                                </div>
                              </div>
                              <div className="w-28 h-1/2">
                                <div className="pt-4 h-full">
                                  <Icon
                                    className="bg-darkgreen-100 rounded-md cursor-pointer text-darkgreen block  flex h-full w-full items-center justify-center"
                                    onClick={(e) => {
                                      handleIncrementReviewValue('-');
                                    }}
                                  >
                                    remove
                                  </Icon>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ width: '100%', maxWidth: '550px' }}
                          className=" text-left mx-auto pt-24"
                        >
                          <strong>{t('qrScanner.labels.note')}</strong>{' '}
                          <p className="text-red">{t('qrScanner.labels.textCopied')}</p>
                        </div>
                      </div>
                    )}
                  />
                  <div className="flex items-center justify-center">
                    <Button
                      variant="contained"
                      color="primary"
                      className="whitespace-nowrap rounded-md px-32 py-8 text-center bg-darkgreen text-white font-semibold text-18 mt-24 mx-12 disabled:text-black"
                      aria-label="Reset"
                      disabled={refreshIcon || !textValue}
                      type="submit"
                      // onClick={() => setDialogOpen(true)}
                      onClick={() => onSubmitRedirect()}
                    >
                      {t('qrScanner.button.share')}
                      {isAttributeLoading && (
                        <CircularProgress size={24} className="text-white absolute mx-auto" />
                      )}
                    </Button>
                    {(businessDetails?.reviewModeTypeId === 3 ||
                      businessDetails?.reviewModeTypeId === 4) && (
                      <>
                        {indexValue !==
                          businessDetails?.business_types?.business_attributes?.length && (
                          <Button
                            variant="contained"
                            className="whitespace-nowrap rounded-md px-32 py-8 mx-12 text-center text-darkgreen font-semibold text-18 mt-24 border border-darkgreen border-solid bg-white hover:bg-darkgreen hover:text-white"
                            aria-label="Reset"
                            onClick={handleNextButtonIndex}
                          >
                            {t('qrScanner.button.next')}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {businessDetails?.reviewModeTypeId === 4 && (
                    <div
                      className="relative mx-auto mt-16"
                      style={{ width: '100%', maxWidth: '550px' }}
                    >
                      <div
                        className="flex items-center justify-between cursor-pointer font-medium mb-8"
                        aria-hidden="true"
                        onClick={handleCheckReview}
                        onKeyDown={handleCheckReview}
                      >
                        <span>{t('qrScanner.labels.checkMyReview')}</span>{' '}
                        {/* {open ? (
                            <Remove className="text-darkgreen" />
                          ) : (
                            <Add className="text-darkgreen" />
                          )} */}
                      </div>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <span
                          className="bg-grey-50 border-none rounded-md px-12 py-20 block mb-24 text-left"
                          id="split-review-text"
                        >
                          {optionsWithReviewText.length > 0
                            ? optionsWithReviewText?.map((allReviewText, i) => (
                                <>
                                  {allReviewText.reviewText}
                                  <br />
                                </>
                              ))
                            : 'Not any review'}
                        </span>
                      </Collapse>
                    </div>
                  )}
                </form>
                <Dialog
                  classes={{
                    paper: 'get-contact',
                  }}
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  className="mx-auto rounded-t-lg"
                >
                  <DialogTitle className="text-center relative md:min-w-xs">
                    <div className="bg-darkgreen h-112 w-full z-10 absolute -top-2 left-0 rounded-t-2xl" />
                    <img
                      className=" inline relative z-20 -mt-36 relative"
                      src="assets/images/apps/contacts/share contact_icon.png"
                      alt="contact"
                    />
                    <div className="text-16 font-semibold -mt-36">
                      {t('qrScanner.labels.shareContactNumber')}
                      <br /> {t('qrScanner.labels.yourContactNumber')}
                    </div>
                  </DialogTitle>
                  <div className="flex justify-center">
                    <PhoneInput
                      country="us"
                      value={phoneValue}
                      enableSearch="true"
                      variant="filled"
                      onChange={handlePhoneChange}
                      className="w-full px-16 scanner-phone"
                      inputStyle={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: '#F4F3F4',
                      }}
                      maxLength={10}
                    />
                  </div>
                  {errorMessage && (
                    <div className="text-red-500 text-center mt-2">{errorMessage}</div>
                  )}
                  <DialogActions className="text-center p-6 block w-full">
                    <div>
                      <div>
                        <Button
                          onClick={handleAddAndContinue}
                          color="primary"
                          className={`rounded-md text-black font-semibold text-18 mt-12 px-16 ${
                            phoneNumber?.length === 10
                              ? 'bg-darkgreen  hover:bg-darkgreen text-white'
                              : 'bg-grey-300'
                          }`}
                          disabled={phoneNumber?.length !== 10}
                        >
                          {t('qrScanner.button.addAndContinue')}
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={handleSkipAndContinue}
                          color="primary"
                          className=" rounded-md hover:text-darkgreen active:bg-darkgreen text-black font-semibold text-14 mt-12"
                        >
                          {t('qrScanner.button.skipAndContinue')}
                        </Button>
                      </div>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withReducer('qrScannerReducer', reducer)(AppReview);

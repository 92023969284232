import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const AutoGenerateText = () => {
  const [isAutoGenerateText, setIsAutoGenerateText] = useState(false);
  const [reviewComplexityTypeData, setReviewComplexityTypeData] = useState();
  const [complexityTypeId, setComplexityTypeId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [preComplexityId, setPreComplexityId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const fetchAutoReviewComplexityType = async () => {
      const payload = {
        query: manageBusinessData.getAutoReviewComplexityType,
      };
      const result = await handleApiRequest(payload);
      if (result?.getAutoReviewComplexityType?.status === 200) {
        const sortData = result.getAutoReviewComplexityType?.data?.sort((a, b) => b.id - a.id);
        setReviewComplexityTypeData(sortData);
      } else {
        enqueueSnackbar(result?.getAutoReviewComplexityType?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    fetchAutoReviewComplexityType();
  }, [enqueueSnackbar]);

  const fetchReviewComplexityType = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      const payload = {
        query: manageBusinessData.getSelectedReviewComplexityType,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getSelectedReviewComplexityType?.status === 200) {
        setComplexityTypeId(
          result?.getSelectedReviewComplexityType?.data?.autoGenerateReviewComplexityTypeId
        );
        setPreComplexityId(
          result?.getSelectedReviewComplexityType?.data?.autoGenerateReviewComplexityTypeId
        );
      } else {
        enqueueSnackbar(result?.getSelectedReviewComplexityType?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchReviewComplexityType();
  }, [fetchReviewComplexityType]);

  const fetchAutoGenerateText = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessAutoTextGenerateStatus,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAutoTextGenerateStatus?.status === 200) {
        setIsAutoGenerateText(result?.getBusinessAutoTextGenerateStatus?.data?.isAutoTextGenerate);
      } else {
        enqueueSnackbar(result?.getBusinessAutoTextGenerateStatus?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchAutoGenerateText();
  }, [fetchAutoGenerateText]);

  const enableDisable = async () => {
    const payload = {
      query: manageBusinessData.setBusinessAutoTextGenerate,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        isActive: !isAutoGenerateText,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.setBusinessAutoTextGenerate?.status === 200) {
      fetchAutoGenerateText();
    } else {
      enqueueSnackbar(result?.setBusinessAutoTextGenerate?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const saveComplexityType = async () => {
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.setReviewComplexityType,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        typeId: parseInt(complexityTypeId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.setReviewComplexityType?.status === 200) {
      fetchReviewComplexityType();
      enqueueSnackbar(result?.setReviewComplexityType?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(result?.setReviewComplexityType?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 h-max lg:p-40">
      <div className="flex items-center justify-between">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
        >
          {t('autoGenerateText.title')}
        </Typography>
        {isAutoGenerateText && (
          <Button
            className="bg-darkgreen text-white px-40 hover:bg-darkgreen disabled:bg-gray disabled:text-black rounded-md"
            disabled={refreshIcon || preComplexityId === complexityTypeId}
            onClick={() => saveComplexityType()}
          >
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
            {t('autoGenerateTextComplexity.button.save')}
          </Button>
        )}
      </div>
      <div className="bg-white rounded-md p-36 mt-28">
        <div className="flex items-center justify-between">
          <Typography className="text-20 font-semibold">{t('autoGenerateText.title')}</Typography>
          <Switch checked={isAutoGenerateText} onChange={() => enableDisable()} />
        </div>
        <Typography className="text-16 sm:w-5/6 mt-20">
          {t('autoGenerateText.enableDisableMessage')}
        </Typography>
      </div>
      {isAutoGenerateText && (
        <div className="bg-white rounded-md p-36 mt-28">
          <Typography className="text-20 font-semibold">
            {t('autoGenerateTextComplexity.setTextComplexity')}
          </Typography>
          <hr className="border-dashed mt-16 !border-gray" />
          <div className="mt-20">
            {reviewComplexityTypeData?.length > 0 &&
              reviewComplexityTypeData?.map((reviewType, index) => {
                return (
                  <div className="mt-8" key={index}>
                    <Typography className="text-16 font-semibold">
                      {reviewType?.typeName}
                    </Typography>
                    <FormControl className="block">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        className="block"
                        value={parseInt(complexityTypeId, 10)}
                        onChange={(event) => setComplexityTypeId(event?.target?.value)}
                      >
                        <FormControlLabel
                          value={reviewType?.id}
                          control={<Radio className="p-0 absolute bottom-2 right-0" />}
                          className="w-full block m-0"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoGenerateText;

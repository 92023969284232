const checkDomainAvailableOnRoute53 = `
	query ($domainName: String!) {
		checkDomainAvailableOnRoute53(domainName: $domainName) {
      data {
        availability
        domainName
      } 
      message
      status 
		}
	}
`;

const domainRegistration = `
  mutation ($address: String, $city: String, $contactType: String, $domainName: String!, $email: String, $firstName: String, $isHaveDomain: Boolean, $lastName: String, $phoneNumber: String, $state: String, $zipCode: String) {
		domainRegistration(address: $address, city: $city, contactType: $contactType, domainName: $domainName, email: $email, firstName: $firstName, isHaveDomain: $isHaveDomain, lastName: $lastName, phoneNumber: $phoneNumber, state: $state, zipCode: $zipCode) {
      message
      status 
		}
	}
`;

const getDomainAndDeploymentStatus = `
	query {
		getDomainAndDeploymentStatus{
      data{
        isHaveDomain {
          step
          isCompleted
          haveDomain
        }
        domainRegister{
          step
          isCompleted
        }
        hostedZoneCreated{
          step
          isCompleted
        }
        certificateCreated{
          step
          isCompleted
        }
        certificateActivated{
          step
          isCompleted
        }
        distributionCreated{
          step
          isCompleted
        }
        isThemeSelect{
          step
          isCompleted
        }
      }  
      message
      status 
		}
	}
`;

const getDnsStatus = `
  query {
		getDnsStatus {
      message
      status
      availability
      dnsValidation
      domainRegistrationId
		}
	}
`;

const getDemoThemes = `
  query {
		getDemoThemes {
      message
      status
      data{
        name
        id
        image
        status
        url
      }
		}
	}
`;

const getDomainDns = `
  query($domainName: String!) {
		getDomainDns(domainName: $domainName) {
      message
      status
      data{
        dns
        id
        domainName
      }
		}
	}
`;

const getDomainRequest = `
  query($pageNumber: Int, $pageSize: Int, $searchText: String, $status: DomainEnumInput) {
		getDomainRequest(pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, status: $status) {
      totalCount
      data {
        domainName
        domainPublishStatus
        users{
          email
          id
          name
        }
      }
      message
      status  
		}
	}
`;

const getDomainStatus = `
  query{
		getDomainStatus{
      data {
        published
        request
      }
      message
      status  
		}
	}
`;

const createCloudfrontDistribution = `
mutation($userId: Int!){
  createCloudfrontDistribution(userId: $userId){
      message
      status  
		}
	}
`;

const getUserCMSMenu = `
query{
  getUserCMSMenu{
    message
    status
    data{
      id
      menu
      isActive
      isDefault
      url
    }
  }
}

`;

const getUserDomainMenu = `
query{
  getUserDomainMenu{
    message
    status
    data{
      active
      domainMenuId
      domainRegisterId
      userDomainMenuId
      isDefault
      menu 
      url
    }
  }
}
`;

const getAppTemplate = `
query{
  getAppTemplate{
    message
    status
    data{
      id
      image
      name
      status
      url
    }
  }
}
`;

const getBusinessUserSelectedAppTemplet = `
query($domainRegisterId: Int!){
  getBusinessUserSelectedAppTemplet(domainRegisterId: $domainRegisterId){
    message
    status
    data{
      id
      app_template{
        image
        name
        status
        url
      }
    }
  }
}
`;

const appWebsiteData = {
  checkDomainAvailableOnRoute53,
  domainRegistration,
  getDomainAndDeploymentStatus,
  getDnsStatus,
  getDemoThemes,
  getDomainDns,
  getDomainRequest,
  getDomainStatus,
  createCloudfrontDistribution,
  getUserCMSMenu,
  getUserDomainMenu,
  getAppTemplate,
  getBusinessUserSelectedAppTemplet,
};

export default appWebsiteData;

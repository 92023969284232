import { Button, Paper, TextField, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { t } from 'i18next';

const PreviewImages = (props) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const {
    previewImages,
    setImages,
    buyXGetYFree,
    selectPromotionTypeId,
    percentageDiscount,
    buttonType,
    fixDiscount,
    loyalty,
    selectLoyaltyPoints,
    promotionApply,
    selectDuration,
    countryId,
  } = props;
  const [imagePreview, setImagePreview] = useState(previewImages?.[0]?.image);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    setImages(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectImage = (value) => {
    setImages(value);
    setImagePreview(value);
  };
  return (
    <div>
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-16 md:text-24 mb-24 font-medium"
      >
        {t('promotion.preview')}
      </Typography>
      <div
        style={{
          backgroundImage: `url(${imagePreview})`,
          margin: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div style={{ padding: '25px' }}>
          <div style={{ border: '1px solid white', paddingLeft: '20px' }}>
            {selectPromotionTypeId === 1 && (
              <div className="pt-8">
                <h1 className="text-white">{fixDiscount?.discountValue || 0} % OFF</h1>
                <p className="text-white text-16">
                  On all orders with a minimum <br />
                  order value of {parseInt(countryId, 10) === 1 ? '$' : '₹'}{' '}
                  {fixDiscount?.minimumCartValue || 0}
                </p>
                <button className="bg-white px-28 py-6 rounded my-12" type="button">
                  {buttonType}
                </button>
              </div>
            )}
            {selectPromotionTypeId === 2 && (
              <div>
                <h1 className="text-white pt-16">{percentageDiscount?.discountValue || 0} % OFF</h1>
                <p className="text-white text-16">
                  On all orders with a minimum <br /> order value of{' '}
                  {parseInt(countryId, 10) === 1 ? '$' : '₹'}{' '}
                  {percentageDiscount?.minimumCartValue || 0}
                </p>
                <div className="flex items-center my-12 gap-8">
                  <button className="bg-white px-28 py-6 rounded my-12" type="button">
                    {buttonType}
                  </button>
                  <p className="text-white">
                    Up to {parseInt(countryId, 10) === 1 ? '$' : '₹'}{' '}
                    {percentageDiscount?.maximumDiscount || 0}
                  </p>
                </div>
              </div>
            )}
            {selectPromotionTypeId === 3 && (
              <div>
                <p className="text-white text-20 mt-16 font-bold">
                  BUY {buyXGetYFree?.buyQuantity || 'X'}{' '}
                  {buyXGetYFree?.buyProductName.toUpperCase() || 'PRODUCT'} <br /> AND GET{' '}
                  {buyXGetYFree?.getQuantity || 'Y'} FREE <br />
                  {buyXGetYFree?.getProductName.toUpperCase() || 'PRODUCT'}
                </p>
                <button className="bg-white px-28 py-6 rounded my-12" type="button">
                  {buttonType}
                </button>
              </div>
            )}
            {selectPromotionTypeId === 4 && (
              <div>
                {selectLoyaltyPoints === 1 && promotionApply === 'all_products' && (
                  <div>
                    <p className="text-white pt-16 font-bold text-20">
                      BUY ANY PRODUCT AND <br /> EARN {loyalty?.getPoints || 'Y'} POINTS INSTANTLY!
                    </p>
                    <div className="flex items-center my-12 gap-8">
                      <button className="bg-white px-28 py-6 rounded my-12" type="button">
                        {buttonType}
                      </button>
                    </div>
                  </div>
                )}
                {selectLoyaltyPoints === 1 && promotionApply !== 'all_products' && (
                  <div>
                    <p className="text-white pt-16 font-bold text-20">
                      BUY {loyalty?.productName.toUpperCase() || 'PRODUCT'} AND <br /> EARN{' '}
                      {loyalty?.getPoints || 'Y'} POINTS INSTANTLY!
                    </p>
                    <div className="flex items-center my-12 gap-8">
                      <button className="bg-white px-28 py-6 rounded my-12" type="button">
                        {buttonType}
                      </button>
                    </div>
                  </div>
                )}
                {selectLoyaltyPoints === 2 && (
                  <div>
                    <p className="text-white pt-16 font-bold text-20">
                      BUY {parseInt(countryId, 10) === 1 ? '$' : '₹'}
                      {loyalty?.minimumPurchaseAmount || 'X'} GET <br /> {loyalty?.points || 'Y'}{' '}
                      POINTS!
                    </p>
                    <div className="flex items-center my-12 gap-8">
                      <button className="bg-white px-28 py-6 rounded my-12" type="button">
                        {buttonType}
                      </button>
                    </div>
                  </div>
                )}
                {selectLoyaltyPoints === 3 && (
                  <div>
                    <p className="text-white pt-16 font-bold text-20">
                      BUY {loyalty?.buyQuantity || 'X'}{' '}
                      {loyalty?.buyProductName.toUpperCase() || 'PRODUCTS'} <br /> THIS{' '}
                      {selectDuration.toUpperCase() || 'MONTH'} AND GET <br />{' '}
                      {loyalty?.getQuantity || 'Y'} {loyalty?.getProductName.toUpperCase()} FREE!
                    </p>
                    <div className="flex items-center my-12 gap-8">
                      <button className="bg-white px-28 py-6 rounded my-12" type="button">
                        {buttonType}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-24 slider">
        <Slider {...settings} className="">
          <div style={{ marginLeft: '10px' }}>
            <motion.div className="block rounded-6">
              <Paper
                className="relative rounded-8 shadow-none border-1 bg-[#B4D4CF] border-[#B4D4CF] text-center"
                role="button"
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/upload_image.png"
                    className="mx-auto w-[40px]"
                    alt="..."
                  />
                </span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <TextField
                      placeholder={t('appPromotionSlider.field.sliderName.message')}
                      size="small"
                      id="button-file"
                      className="hidden"
                      onChange={(event) => {
                        handleFileChange(event);
                      }}
                      type="file"
                      accept="image/*"
                      variant="outlined"
                      required
                      fullWidth
                    />
                    <Button
                      size="small"
                      className="py-0 bg-[#B4D4CF] hover:bg-[#B4D4CF] pb-6 text-darkgreen font-medium w-full"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('promotion.uploadImage')}
                    </Button>
                  </label>
                </div>
              </Paper>
            </motion.div>
          </div>
          {previewImages?.length > 0 &&
            previewImages?.map((item, index) => {
              return (
                <div key={index}>
                  <Button className="hover:bg-white" onClick={() => handleSelectImage(item.image)}>
                    <img className="px-8" src={item.image} alt="" />
                  </Button>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default PreviewImages;

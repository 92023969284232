import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const setAuthorizationHeader = () => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
};

const handleApiRequest = async (endpoint, data) => {
  setAuthorizationHeader();
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`convertedBusiness/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getConvertedAccountSalesPerson = createAsyncThunkWithAuthorization(
  'getConvertedAccountSalesPerson',
  baseURL
);

export const getDemoBusinessWithDemoUserDetail = createAsyncThunkWithAuthorization(
  'getDemoBusinessWithDemoUserDetail',
  baseURL
);

export const convertedAccountExtendExpireDate = createAsyncThunkWithAuthorization(
  'convertedAccountExtendExpireDate',
  baseURL
);

export const resendConvertedUserUsernameAndPasswordBySalesPerson =
  createAsyncThunkWithAuthorization(
    'resendConvertedUserUsernameAndPasswordBySalesPerson',
    authEndpoint
  );

const convertedBusinessSlice = createSlice({
  name: 'convertedBusiness',
  initialState: null,
  reducers: {
    resetConvertedBusiness: (state, action) => {
      if (state) {
        state.getConvertedAccountSalesPersonData = {};
        state.resendConvertedUserUsernameAndPasswordBySalesPersonData = {};
        state.getDemoBusinessWithDemoUserDetailData = {};
        state.convertedAccountExtendExpireDateData = {};
      }
    },
  },
  extraReducers: {
    [getConvertedAccountSalesPerson.fulfilled]: (state, action) => ({
      ...state,
      getConvertedAccountSalesPersonData: action.payload,
    }),
    [getDemoBusinessWithDemoUserDetail.fulfilled]: (state, action) => ({
      ...state,
      getDemoBusinessWithDemoUserDetailData: action.payload,
    }),
    [resendConvertedUserUsernameAndPasswordBySalesPerson.fulfilled]: (state, action) => ({
      ...state,
      resendConvertedUserUsernameAndPasswordBySalesPersonData: action.payload,
    }),
    [convertedAccountExtendExpireDate.fulfilled]: (state, action) => ({
      ...state,
      convertedAccountExtendExpireDateData: action.payload,
    }),
  },
});

export const { resetConvertedBusiness } = convertedBusinessSlice.actions;

export default convertedBusinessSlice.reducer;

import AppWebsite from './appWebsite';
import SelectTheme from './selectTheme';
import SelectApp from './selectApp';

const appWebsiteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'app-website',
      element: <AppWebsite />,
    },
    {
      path: 'select-theme',
      element: <SelectTheme />,
    },
    {
      path: 'select-app',
      element: <SelectApp />,
    },
  ],
};

export default appWebsiteConfig;

import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState, useCallback, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { Editor, EditorState, RichUtils, Modifier } from 'draft-js';
import EmojiPicker from 'emoji-picker-react';
import 'draft-js/dist/Draft.css';
import './Waba.css';
import wabaQuery from '../../query/waba';
import businessData from '../../query/business';
import {
  handleWabaApiRequest,
  handleApiRequest,
  storeCsvFileDataToServer,
} from '../../common/common';

const CreateTemplate = (props) => {
  const { openValue, cancelCall, popUpMessage, popUpButtonName } = props;

  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [categoryList, setCategoryList] = useState();
  const [selectCategory, setSelectCategory] = useState();
  const [selectPreviewTemplate, setSelectPreviewTemplate] = useState();
  const [templateName, setTemplateName] = useState();
  const [msgTitle, setMsgTitle] = useState();
  const [csvFileData, setCsvFileData] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileRef = useRef();
  const templatePreview = selectPreviewTemplate?.find(
    (template) => template.id === selectCategory?.value
  );

  console.log('variableList', variableList);

  const CustomMenuList = (propsList) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = propsList;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleTemplate = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getTemplateCategories,
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getTemplateCategories?.status === 200) {
        const templateCategoryList = res?.getTemplateCategories?.data?.map((idx) => ({
          value: idx.id,
          label: idx.name,
        }));
        setCategoryList(templateCategoryList);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    handleTemplate();
  }, [handleTemplate]);

  useEffect(() => {
    try {
      const whatAppVariable = async () => {
        const payload = {
          query: wabaQuery.getTemplateVariable,
        };
        const res = await handleWabaApiRequest(payload);
        console.log('res', res);
        if (res?.getTemplateVariable?.status === 200) {
          setCategoryList(res?.getTemplateVariable?.data);
        }
      };
      whatAppVariable();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  const handleFileInputChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.type === 'text/csv' || selectedFile?.name?.endsWith('.csv')) {
      const payload = {
        query: businessData.saveUserCSV,
        variables: {
          file: selectedFile,
          variables: {
            callFrom: 'whatsapp',
          },
        },
      };
      const result = await storeCsvFileDataToServer(payload);
      if (result?.saveUserCSV?.status === 200) {
        const obj = {
          query: businessData.getUserCSV,
          variables: {
            callFrom: 'whatsapp',
          },
        };
        const res = await handleApiRequest(obj);
        if (res?.getUserCSV?.status === 200) {
          const data = res?.getUserCSV?.data?.userCSVData;
          setCsvFileData(data);
        }
      } else {
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    fileRef.current.value = '';
  };

  const handleCreateBroadCast = async () => {
    setRefreshIcon(true);

    // const dateTimeString = `${selectedDate} ${selectedTime}`;
    // const parsedDate = moment(dateTimeString, 'YYYY-MM-DD h:mm:ss A');
    // let isoDateTimeString;
    // if (parsedDate.isValid()) {
    //   isoDateTimeString = parsedDate.toISOString();
    // } else {
    //   console.error('Invalid date or time value');
    // }
    // try {
    //   const payload = {
    //     query: wabaQuery.createBroadcast,
    //     variables: {
    //       contact: result,
    //       isScheduled: selectedOption === 'schedule',
    //       name: templateName,
    //       templateId: selectCategory?.value,
    //     },
    //   };
    //   if (selectedOption === 'schedule') {
    //     payload.variables.scheduledDate = isoDateTimeString;
    //   }
    //   const res = await handleWabaApiRequest(payload);
    //   setRefreshIcon(false);
    //   if (res.createBroadcast?.status === 200) {
    //     handleClose();
    //     setTemplateName('');
    //   } else {
    //     enqueueSnackbar(res?.createBroadcast?.message, {
    //       variant: 'error',
    //       autoHideDuration: 3000,
    //     });
    //   }
    // } catch (error) {
    //   console.error('An error occurred:', error);
    // }
  };

  const handleKeyCommand = (command, editorStates) => {
    const newState = RichUtils.handleKeyCommand(editorStates, command);
    console.log('editorStates', editorStates);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const onStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'));
  };

  const addEmoji = (event, emojiObject) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEmoji = Modifier.insertText(
      contentState,
      editorState.getSelection(),
      emojiObject.emoji
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEmoji,
      'insert-characters'
    );
    console.log('newEditorState', newEditorState);
    setEditorState(newEditorState);
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <Dialog
      classes={{
        paper:
          'm-24 max-w-screen-lg width-full border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md overflow-x-hidden',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="flex">
        <div className="w-3/4 p-20 create-broadcast-height overflow-y-auto">
          <div className="flex gap-16">
            <div className="block mb-20 md:basis-1/2">
              <InputLabel
                id="templateName"
                className="text-16 font-medium mb-4 text-grey-900 mb-10"
              >
                {t('waba.broadcast.newBroadcast.name')}
                <span className="text-red">*</span>
              </InputLabel>
              <TextField
                name="templateName"
                type="text"
                onChange={(e) => setTemplateName(e?.target?.value)}
                value={templateName}
                className="bg-white"
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>
            <div className="md:basis-1/2">
              <InputLabel
                id="templateName"
                className="text-16 font-medium mb-4 text-grey-900 mb-10"
              >
                {t('waba.broadcast.AddTemplate.category')}
              </InputLabel>
              <ReactSelect
                labelId="templateCategory"
                placeholder={t('waba.broadcast.AddTemplate.selectCategory')}
                id="templateCategory"
                name="templateCategory"
                minMenuHeight="45px"
                onChange={(e) => {
                  setSelectCategory(e);
                }}
                value={selectCategory}
                options={categoryList}
                components={{
                  MenuList: CustomMenuList,
                }}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: '42px',
                  }),
                }}
              />
            </div>
          </div>
          <div className="block mb-20 md:basis-1/2">
            <InputLabel id="messageTitle" className="text-16 font-medium mb-4 text-grey-900 mb-10">
              {t('waba.broadcast.AddTemplate.msgTitle')}
            </InputLabel>
            <TextField
              name="messageTitle"
              placeholder={t('waba.broadcast.AddTemplate.msgTitlePlaceholder')}
              type="text"
              onChange={(e) => setMsgTitle(e?.target?.value)}
              value={msgTitle}
              className="bg-white"
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="block mb-20">
            <InputLabel id="media" className="text-16 font-medium mb-4 text-grey-900 mb-10">
              {t('waba.broadcast.AddTemplate.media')}
            </InputLabel>
            <div className="border-2 border-dotted border-darkgreen text-center py-20 rounded-md">
              <Icon>upload</Icon>
              <h4 className="text-16 font-semibold">
                {t('waba.broadcast.AddTemplate.uploadFile')}
              </h4>
              <span className="text-14 font-medium block text-grey-600 my-12">
                {t('waba.broadcast.AddTemplate.uploadInfo')}
              </span>
              <div className="relative">
                <label htmlFor="button-file">
                  <input
                    className="hidden w-full"
                    id="button-file"
                    type="file"
                    ref={fileRef}
                    onChange={handleFileInputChange}
                    accept="image/*"
                    aria-labelledby="upload-label"
                  />
                  <Button
                    className="border rounded-md bg-darkgreen-100 font-semibold text-darkgreen"
                    variant="contained"
                    color="secondary"
                    component="span"
                    id="upload-label"
                  >
                    {t('waba.broadcast.AddTemplate.uploadImage')}
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="block mb-20">
            <InputLabel id="body" className="text-16 font-medium mb-4 text-grey-900 mb-10">
              {t('waba.broadcast.AddTemplate.body')}
            </InputLabel>
            <Typography className="mb-10">{t('waba.broadcast.AddTemplate.bodyInfo')}</Typography>
            <div className="border-1 border-solid border-grey-400 p-10 rounded-md relative">
              <div className="mb-10 flex gap-10">
                <div className="bg-grey-50 rounded-md">
                  <Button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="min-w-40">
                    <Icon className="text-16">insert_emoticon</Icon>
                  </Button>
                </div>
                <div className="bg-grey-50 rounded-md w-full">
                  <Button onClick={onBoldClick} className="text-20 font-bold text-grey-600 min-w-0">
                    B
                  </Button>
                  <Button
                    onClick={onItalicClick}
                    className="text-20 font-bold italic text-grey-600 min-w-0"
                  >
                    I
                  </Button>
                  <Button
                    onClick={onStrikethroughClick}
                    className="text-20 font-bold line-through text-grey-600 min-w-0"
                    title="Strike-through"
                  >
                    T
                  </Button>
                </div>
              </div>
              {showEmojiPicker && <EmojiPicker onEmojiClick={addEmoji} className="absolute" />}
              <div className="min-h-200">
                <Editor
                  className="min-h-200"
                  editorState={editorState}
                  handleKeyCommand={handleKeyCommand}
                  onChange={setEditorState}
                  placeholder={t('waba.broadcast.AddTemplate.bodyPlaceHolder')}
                />
              </div>
            </div>
          </div>
          <div className="block mb-20 md:basis-1/2">
            <InputLabel id="footer" className="text-16 font-medium mb-4 text-grey-900 mb-10">
              {t('waba.broadcast.AddTemplate.footer')}
            </InputLabel>
            <TextField
              name="footer"
              placeholder={t('waba.broadcast.AddTemplate.placeHolder')}
              type="text"
              onChange={(e) => setMsgTitle(e?.target?.value)}
              value={msgTitle}
              className="bg-white"
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>

          <div className="block mb-20 md:basis-1/2">
            <InputLabel id="footer" className="text-16 font-medium mb-4 text-grey-900 mb-5">
              {t('waba.broadcast.AddTemplate.callToAction')}
            </InputLabel>
            <Typography className="mb-10">
              {t('waba.broadcast.AddTemplate.callToActionInfo')}
            </Typography>
            <Select
              className="rounded-md font-semibold text-16 p-0 shadow-none pt-0"
              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
              size="small"
              variant="outlined"
              // value={selectedRow === row ? selectedRowAction : 'action'}
              fullWidth
              // onChange={handleActionDropdownChange}
            >
              <MenuItem
                value="view"
                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
              >
                {t('waba.broadcast.AddTemplate.none')}
              </MenuItem>
              <MenuItem
                value="delete"
                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
              >
                {t('waba.broadcast.AddTemplate.callToAction')}
              </MenuItem>
            </Select>
          </div>

          <DialogActions className="p-0 mt-20 justify-start">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192"
              variant="contained"
              color="secondary"
              onClick={() => handleCreateBroadCast()}
              type="button"
              disabled={!templateName}
            >
              {popUpButtonName}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </div>
        <div className="w-2/4 bg-gray-A300 text-center create-broadcast-height sticky top-0">
          <h3 className="text-20 font-semibold text-center py-48">
            {t('waba.broadcast.AddTemplate.preview')}
          </h3>
          <div className="relative inline-block mx-auto">
            <img
              src="/assets/images/business/mobile_frame_waba.png"
              className="img-fluid mx-auto"
              alt="..."
            />
            {templatePreview && (
              <div className="absolute top-144 left-0 right-0 bg-white rounded-md px-8 py-8 max-w-256 mx-auto">
                {templatePreview &&
                  JSON.parse(templatePreview?.templateData)?.components?.map((templateValue, i) => (
                    <div className="block max-w-320 text-left bg-white" key={i}>
                      <div>
                        {templateValue?.type === 'HEADER' && (
                          <>
                            {templateValue?.format === 'TEXT' && (
                              <Typography>{templateValue?.text}</Typography>
                            )}
                            {templateValue?.format === 'IMAGE' && (
                              <>
                                {JSON.parse(templatePreview?.dynamicData)?.header?.map(
                                  (dynamicValue) => (
                                    <>
                                      {dynamicValue?.type === 'image' && (
                                        <img
                                          src={dynamicValue?.value}
                                          className="mb-10 max-h-128"
                                          alt="..."
                                        />
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                          </>
                        )}
                        {templateValue?.type === 'BODY' && (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: templateValue?.text,
                              }}
                            />
                          </>
                        )}
                        {templateValue?.type === 'FOOTER' && (
                          <>
                            <Typography className="mt-6 text-grey-700">
                              {templateValue?.text}
                            </Typography>
                          </>
                        )}
                        {templateValue?.type === 'BUTTONS' &&
                          templateValue?.buttons?.map((buttonValue, b) => (
                            <div
                              className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                              key={b}
                            >
                              <a
                                className="font-bold text-darkgreen text-16"
                                href={`${
                                  buttonValue?.phone_number
                                    ? `tel:${buttonValue?.phone_number}`
                                    : ''
                                }`}
                                rel="noreferrer"
                                target="_blank"
                                role="button"
                              >
                                <img
                                  src="/assets/images/business/telephone.png"
                                  className="w-14 h-14 align-middle mr-4 inline-block"
                                  alt="..."
                                />
                                {buttonValue?.text}
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateTemplate;

import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import history from '@history';
import { Button, Typography } from '@mui/material';
import businessData from '../../query/business';
import { getParamsId, handleApiRequest } from '../../common/common';

const BusinessSetUp = () => {
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const fetchCardData = async () => {
      const payload = {
        query: businessData.getAppSetupCard,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAppSetupCard?.status === 200) {
        const data = result?.getAppSetupCard?.data;
        const removeData = ['Send Reviews Invitation', 'Invite Your Friend'];
        const filterInviteFriend = data?.filter((item) => !removeData.includes(item.title));
        setCardData(filterInviteFriend);
      }
    };
    fetchCardData();
  }, [userCurrentBusinessDetails?.id]);

  const handleButtons = (value) => {
    const id = getParamsId();
    if (value === 'addProductAndServices') {
      if (id) {
        history.push(`businessProductAndService/${id}`);
      } else {
        history.push('businessProductAndService/');
      }
    } else if (value === 'addStaffMember') {
      if (id) {
        history.push(`staff/${id}`);
      } else {
        history.push('staff/');
      }
    } else if (value === 'inviteYourTeamMember') {
      if (id) {
        history.push(`inviteUser/${id}`);
      } else {
        history.push('inviteUser/');
      }
    } else if (value === 'addFlipkartProducts') {
      if (id) {
        history.push(`product/${id}`);
      } else {
        history.push('product/');
      }
    } else if (value === 'addAmazonProducts') {
      if (id) {
        history.push(`product/${id}`);
      } else {
        history.push('product/');
      }
    } else if (value === 'inviteReviews') {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    } else if (value === 'addMorePlatforms') {
      if (id) {
        history.push(`editBusinessDetails/${id}`);
      } else {
        history.push('editBusinessDetails/');
      }
    } else if (value === 'exploreTheMetrics') {
      if (id) {
        history.push(`matrices/${id}`);
      } else {
        history.push('matrices/');
      }
    } else if (value === 'addYourBusinessAttributes') {
      if (id) {
        history.push(`customAttributes/${id}`);
      } else {
        history.push('customAttributes/');
      }
    } else if (value === 'changeReviewModes') {
      if (id) {
        history.push(`reviewScreenMode/${id}`);
      } else {
        history.push('reviewScreenMode/');
      }
    } else if (value === 'tweakTheAutoGeneratedText') {
      if (id) {
        history.push(`autoGenerateText/${id}`);
      } else {
        history.push('autoGenerateText/');
      }
    } else if (value === 'addYourBusiness') {
      if (id) {
        history.push(`select-business/${id}`);
      } else {
        history.push('select-business/');
      }
    } else if (value === 'shareReviewPost') {
      if (id) {
        history.push(`quick-post/${id}`);
      } else {
        history.push('quick-post/');
      }
    }
  };

  return (
    <>
      {cardData?.length > 0 && (
        <div className="bg-white rounded-md relative shadow p-20 w-full mt-16">
          <Typography className="font-bold text-20">
            {t('businessDashboard.setUpYourBusiness')}
          </Typography>
          {cardData?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex gap-16 items-center w-full p-12 py-16 mt-16 rounded-md"
                style={{ backgroundColor: item.backgroundColor }}
              >
                <div>
                  <Typography className="font-semibold text-16">{item?.title}</Typography>
                  <Typography className="py-8">{item?.description}</Typography>
                  <Button
                    className="rounded-md text-white max-w-216 min-w-216 mt-8"
                    style={{
                      backgroundColor: item.buttonColor,
                      '--hover-bg': item.buttonColor,
                    }}
                    onClick={() => handleButtons(item?.cardName)}
                  >
                    {item?.button}
                  </Button>
                </div>
                <div>
                  <img className="hidden sm:block" src={item?.image} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default BusinessSetUp;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`dashboards/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getDashboardAdminSectionSalesPerson = createAsyncThunkWithAuthorization(
  'getDashboardAdminSectionSalesPerson',
  baseURL
);

export const getBusinessAndUserAddedLastMonth = createAsyncThunkWithAuthorization(
  'getBusinessAndUserAddedLastMonth',
  baseURL
);

export const getDashboardAdmin = createAsyncThunkWithAuthorization('getDashboardAdmin', baseURL);
const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: null,
  reducers: {
    resetDashboards: (state, action) => {
      if (state) {
        state.getDashboardAdminSectionSalesPersonData = {};
        state.getBusinessAndUserAddedLastMonthData = {};
      }
    },
  },
  extraReducers: {
    [getDashboardAdminSectionSalesPerson.fulfilled]: (state, action) => ({
      ...state,
      getDashboardAdminSectionSalesPersonData: action.payload,
    }),
    [getBusinessAndUserAddedLastMonth.fulfilled]: (state, action) => ({
      ...state,
      getBusinessAndUserAddedLastMonthData: action.payload,
    }),
    [getDashboardAdmin.fulfilled]: (state, action) => ({
      ...state,
      getDashboardAdminData: action.payload,
    }),
  },
});

export const { resetDashboards } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import { useForm, Controller } from 'react-hook-form';
import { memo, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import {
  Button,
  Icon,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  CircularProgress,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { publicIpv4 } from 'public-ip';
import { getEncryptedData, getDecryptData } from '../../common/common';
import userService from '../../../services';
import planQuery from '../../query/plan';
import queryData from '../../query/common';
import {
  openContactDialog,
  closeContactDialog,
  saveQuickReviewContactUsData,
  getLocation,
  reSetPlan,
} from './store/planSlice';
import reducer from './store';

function ContactDialog(props) {
  const dispatch = useDispatch();
  const loginUserData = userService.getUserData();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
      .required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    message: yup.string().required('Message is required'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .min(10, 'Phone number is too short - should be at least 10 digits.'),
  });
  const defaultValues = useMemo(
    () => ({
      email: '',
      name: '',
      message: '',
      phoneNumber: '',
    }),
    []
  );

  const { control, reset, handleSubmit, formState, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const appDialog = useSelector(({ planReducer }) => planReducer.plan.contactDialog);

  const saveQuickReviewContactUsDataStatus = useSelector(
    ({ planReducer }) =>
      planReducer?.plan?.saveQuickReviewContactUsDataDetail?.data?.saveQuickReviewContactUsData ||
      {}
  );

  const [phoneNumberWithoutCode, setPhoneNumberWithoutCode] = useState('');
  const [countryCodeNumber, setCountryCodeNumber] = useState('');
  const [isoCode, setIsoCode] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(saveQuickReviewContactUsDataStatus).length > 0) {
      if (
        saveQuickReviewContactUsDataStatus.status === 400 ||
        saveQuickReviewContactUsDataStatus.status === 500
      ) {
        enqueueSnackbar(saveQuickReviewContactUsDataStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else if (saveQuickReviewContactUsDataStatus.status === 200) {
        enqueueSnackbar(saveQuickReviewContactUsDataStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        dispatch(closeContactDialog());
        reset(defaultValues);
      }
      setButtonDisabled(false);
      setRefreshIcon(false);
      closeSnackbar(3000);
    }
  }, [
    reset,
    dispatch,
    saveQuickReviewContactUsDataStatus,
    enqueueSnackbar,
    closeSnackbar,
    defaultValues,
  ]);

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...appDialog.data,
      });
    }
  }, [appDialog.data, appDialog.type, reset, defaultValues]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (appDialog.open) {
      initDialog();
    }
  }, [appDialog.open, dispatch, initDialog]);

  function isValidEmail(value) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  }

  const onSubmit = (data) => {
    if (!isValidEmail(data.email)) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email format',
      });
      return;
    }

    setRefreshIcon(true);
    setButtonDisabled(true);
    const payload = {
      query: planQuery.saveQuickReviewContactUsData,
      variables: {
        name: data.name,
        email: data.email,
        message: data.message,
        phoneNumber: parseInt(phoneNumberWithoutCode, 10),
        countryCode: `+${countryCodeNumber}`,
        isoCode: isoCode.toUpperCase(),
      },
    };

    dispatch(saveQuickReviewContactUsData(payload));
  };

  const validatePhoneNumber = (phoneValues) => {
    const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return phoneNumberPattern.test(phoneValues);
  };

  const handlePhoneChange = (value, country, event, formattedValue) => {
    const phoneNumberWithoutCodeValue = value.slice(country.dialCode.length);
    // setPhoneNumber(phoneNumberWithoutCodeValue);
    setPhoneNumberWithoutCode(phoneNumberWithoutCodeValue);
    // setPhoneValue(value);
    // setPhoneCountryCode(country.dialCode);
    // setPhoneIsoCode(country.countryCode);
    const isValidPhoneNumber = validatePhoneNumber(phoneNumberWithoutCodeValue);

    setValue('phoneNumber', phoneNumberWithoutCodeValue, {
      shouldDirty: true,
      shouldValidate: true,
      value: isValidPhoneNumber ? phoneNumberWithoutCodeValue : '',
    });

    setCountryCodeNumber(country.dialCode);
    setIsoCode(country.countryCode);
  };

  return (
    <Dialog
      classes={{
        paper: 'm-4 contact-dialog rounded-md max-w-[480px]',
      }}
      {...appDialog.props}
      onClose={() => {
        dispatch(closeContactDialog());
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <div>
          <div className="">
            <DialogTitle className="text-darkgreen font-semibold text-28 md:28 lg:28 mb-2 p-2">
              <Icon
                onClick={() => dispatch(closeContactDialog())}
                className="text-32 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              >
                cancel
              </Icon>
              Contact Us
            </DialogTitle>
            <DialogContentText className="text-black font-medium md:text-16 lg:text-16 mb-28">
              Get in touch and let us know how we can assist you. We would be delighted to hear from
              you. Here is how you can reach us.
            </DialogContentText>
          </div>
          <div className="col-span-12 md:col-span-1" />
          <div className="col-span-12 md:col-span-5 bg-darkgreen p-24 rounded-md text-white relative z-10 contact-form-ui">
            <div className="block mb-24">
              <InputLabel htmlFor="name" className="text-14 font-normal mb-6 text-white">
                Your Name*
              </InputLabel>

              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Your Name"
                    id="name"
                    error={!!formState.errors.name}
                    helperText={
                      formState.errors.name?.message && (
                        <span className="text-red-900">{formState.errors.name?.message}</span>
                      )
                    }
                    variant="outlined"
                    required
                    fullWidth
                    type=""
                    sx={{
                      '& .MuiInputBase-input': {
                        backgroundColor: 'transparent',
                        height: '42px',
                        padding: '0 15px',
                        color: '#fff',
                        '&:-webkit-autofill': {
                          WebkitTextFillColor: '#fff',
                          caretColor: '#fff',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        height: '42px',
                        border: '1px solid #fff',
                        backgroundColor: 'transparent',
                        margin: '1px 0',
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="block mb-24">
              <InputLabel
                htmlFor="phoneNumber"
                className="text-14 font-normal mb-6 text-white mt-4"
              >
                Your Phone Number*
              </InputLabel>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <PhoneInput
                    country={
                      loginUserData?.countryId === 2 ? 'in' : loginUserData?.isoCode?.toLowerCase()
                    }
                    value={field.value}
                    onChange={(value, country, event, formattedValue) => {
                      handlePhoneChange(value, country, event, formattedValue);
                      field.onChange(value);
                    }}
                    enableSearch
                    variant="filled"
                    inputStyle={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: 'transparent',
                    }}
                  />
                )}
              />
              {formState.errors.phoneNumber && (
                <div className="text-red-900 text-center mt-2">
                  {formState.errors.phoneNumber.message}
                </div>
              )}
            </div>
            <div className="block">
              <InputLabel htmlFor="email" className="text-14 font-normal mb-6 text-white mt-4">
                Your Email*
              </InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    placeholder="Your Email"
                    type="email"
                    error={!!formState.errors.email}
                    helperText={
                      <span className="text-red-900">
                        {formState.errors.email?.message ||
                          (field.value && !isValidEmail(field.value) ? 'Invalid email format' : '')}
                      </span>
                    }
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-input': {
                        backgroundColor: 'transparent',
                        height: '42px',
                        padding: '0 15px',
                        color: '#fff',
                        '&:-webkit-autofill': {
                          WebkitTextFillColor: '#fff',
                          caretColor: '#fff',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        height: '42px',
                        border: '1px solid #fff',
                        backgroundColor: 'transparent',
                        margin: '1px 0',
                      },
                    }}
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="mb-20">
              <InputLabel htmlFor="message" className="text-14 font-normal mb-6 text-white">
                Your Message*
              </InputLabel>
              <div className="mb-2">
                <Controller
                  control={control}
                  name="message"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Your Message"
                      id="message"
                      error={!!formState.errors.message}
                      helperText={formState.errors.message?.message}
                      variant="outlined"
                      InputProps={{
                        style: { border: '1px solid #fff', color: '#fff' },
                      }}
                      required
                      fullWidth
                      multiline
                      rows={8}
                    />
                  )}
                />
              </div>
            </div>
            <Button
              variant="outlined"
              color="primary"
              className="w-full rounded-md !text-darkgreen bg-white border text-18 hover:bg-darkgreen hover:border-darkgreen hover:!text-white"
              style={{ borderColor: '#006B5A' }}
              disabled={!formState.isValid || refreshIcon || buttonDisabled}
              onClick={handleSubmit(onSubmit)}
            >
              Send Message
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
          <img
            src="/assets/images/icon/background_image.png"
            alt="bad"
            className="hidden md:block mx-auto absolute bottom-0 left-2/4"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default withReducer('planReducer', reducer)(ContactDialog);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

export const getDashboardForSalesUser = createAsyncThunk(
  'dashboards/getDashboardForSalesUser',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    try {
      const { data: result } = await instance.post(baseURL, { ...data });
      return result;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: null,
  reducers: {
    resetDashboards: (state, action) => {
      if (state) {
        state.getDashboardForSalesUSerData = {};
      }
    },
  },
  extraReducers: {
    [getDashboardForSalesUser.fulfilled]: (state, action) => ({
      ...state,
      getDashboardForSalesUSerData: action.payload,
    }),
  },
});

export const { resetDashboards } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;

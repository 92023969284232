const getCountries = `
   query {
     getCountries{ 
          data {
               id
               countryCode
               isActive
               isoCode
               name
               cts
             }
             message
             status
     }
   }
`;

const getStates = `
   query($countryId: Int!){
     getStates(countryId: $countryId){ 
          data {
               countryId
               id
               name
               cts
               shortName
             }
             message
             status
     }
   }
`;

const getBusinessType = `
   query getBusinessType($isOnlineBusiness: Boolean){
    getBusinessType(isOnlineBusiness:$isOnlineBusiness){
        data {
          bType
          cts
          id
          isActive
        }
        message
        status
      }
   }
`;

const verifyOTP = `
mutation($data: String!){
    verifyOTP(data: $data){ 
        message
        status
    }
  }
`;

const checkEmail = `
query($email: String!){
  checkEmail(email: $email){ 
        message
        status
        available
    }
  }
`;

const checkUserName = `
query($userName: String!){
  checkUserName(userName: $userName){ 
        message
        status
        available
    }
  }
`;

const sendOTPOnEmailVerification = `
mutation($email: String!){
  sendOTPOnEmailVerification(email: $email){ 
        message
        status
    }
  }
`;
const checkAndVerifyEmailStatus = `
query($email: String!){
  checkAndVerifyEmailStatus(email: $email){ 
        message
        status
        available
    }
  }
`;
const checkAndVerifyPhoneNumber = `
query($phoneNumber: Float!){
  checkAndVerifyPhoneNumber(phoneNumber: $phoneNumber){ 
        message
        status
        available
    }
  }
`;

const sendOTPOnPhoneNumberVerification = `
mutation($countryCode: String!, $phoneNumber: Float!){
  sendOTPOnPhoneNumberVerification(countryCode:$countryCode,phoneNumber:$phoneNumber){
    status
    message
  }
}
`;

const verifyPhoneNumberOTP = `
mutation($data: String!){
  verifyPhoneNumberOTP(data: $data){ 
        message
        status
    }
  }
`;

const signupV3 = `
mutation signupV3($data: String!) {
  signupV3(data:$data){
    status
    message
    isEmailVerified
    isRegistrationCompleted
    refreshToken
    accessToken
    user {
      id
      phoneNumber{
        isoCode
        countryCode
        phoneNumber
      }
      firstName
      lastName
      fullName
      countryId
      packagePlanId
      email
      roles{
        id
        type
      }
      userType
      address
      address2
      city
      zipcode
      userStatusId
      profileUrl
      domainRegisterId
      deviceId
      roleId
    }
  }
}
`;

const googleAuthV3 = `
mutation googleAuthV3($data: String!) {
  googleAuthV3(data:$data){
    status
    message
    isEmailVerified
    refreshToken
    accessToken
    isRegistrationCompleted
    user {
      id
      phoneNumber{
        isoCode
        countryCode
        phoneNumber
      }
      firstName
      lastName
      fullName
      email
      countryId
      packagePlanId
      roles{
        id
        type
      }
      userType
      address
      address2
      city
      zipcode
      userStatusId
      profileUrl
      domainRegisterId
      deviceId
      roleId
    }
  }
}
`;

const getLocation = `
query getLocation($data: String!) {
  getLocation(data: $data) {
    message
    status
    data
    id
  }
}
`;

const setPassword = `
mutation($password: String!) {
  setPassword(password: $password){
    message
    status
  }
}
`;

const sendOtpForSetPassword = `
mutation($email: String!) {
  sendOtpForSetPassword(email: $email){
    message
    status
  }
}
`;

const verifyOtpForSetPassword = `
mutation($email: String!, $otp: Int!){
  verifyOtpForSetPassword(email: $email,otp: $otp){
    message
    status
  }
}
`;

const resetPassword = `
mutation ($data : String!) {
  resetPassword(data: $data){
    message
    status
  }
}
`;

const getProfile = `query {
  getProfile{
    status
    message
    data {
      id
      firstName
      lastName
      email
      agencyName
      agencyUrl
      agencyAddress
      profileUrl
      phoneNumber {
        phoneNumber
        isoCode
        countryCode
      }
    }
  }
}
`;

const editAgentProfile = `mutation editAgentProfile($firstName: String, $lastName: String, $agencyName: String,$agencyUrl: String,$agencyAddress: String, $profileImage: Upload) {
  editAgentProfile(
    firstName: $firstName
    lastName: $lastName
    agencyName: $agencyName
    agencyUrl: $agencyUrl
    agencyAddress: $agencyAddress
    profileImage: $profileImage
  ) {
    message
    status
  }
}
`;
const appleAuthV2 = `mutation appleAuthV2($data: String!){
  appleAuthV2(data: $data){
    status
    message
    accessToken
    refreshToken
    user {
      id
      cts
      uts
      firstName
      lastName
      fullName
      email
      middleName
      address
      address2
      zipcode
      stateId
      countryId
      isDefaultCountry
      roleId
      userStatusId
      privacyPolicyAccept
      packagePlanId
      roles{
        id
        type
      }
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      timeZone
    }
    business {
      id
      cts
      uts
      name
      email
      address
      address2
      city
      stateId
      countryId
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      businessQRId
      reviewPlatformId
      businessTypeId
      website
      userId
      profileURL
      user_business_review_platforms {
        id
        isActive
        userBusinessId
        reviewPlatformId
        placeId
        url
      }
    }
    }
  }`;

const getOnlineBusinessProductType = `query($searchText: String){
  getOnlineBusinessProductType(searchText:$searchText){
    message
    status
    data{
      id
      bType
      isActive
    }
  }
}
`;

const queryData = {
  getCountries,
  getStates,
  getBusinessType,
  verifyOTP,
  checkEmail,
  checkUserName,
  sendOTPOnEmailVerification,
  checkAndVerifyEmailStatus,
  checkAndVerifyPhoneNumber,
  sendOTPOnPhoneNumberVerification,
  verifyPhoneNumberOTP,
  signupV3,
  googleAuthV3,
  getLocation,
  setPassword,
  sendOtpForSetPassword,
  verifyOtpForSetPassword,
  resetPassword,
  getProfile,
  editAgentProfile,
  appleAuthV2,
  getOnlineBusinessProductType,
};

export default queryData;

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import withReducer from 'app/store/withReducer';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import JSEncrypt from 'jsencrypt';
import history from '@history';
import { useTranslation } from 'react-i18next';
import reducer from './store';
import Query from '../query/auth';
import { forgotPasswordAction, sendForgotPasswordLink } from './store/forgotPasswordSlice';
import SigninBoxContent from '../sign-in/SignInBoxContent';
import { getEncryptedData } from '../common/common';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 8,
    position: 'relative',
  },
  buttonProgress: {
    color: 'darkgreen',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -5,
    marginLeft: -5,
  },
}));

const defaultValues = {
  email: '',
};

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .required(t('signInPage.formField.email.validationMessages.required')),
  });

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const sendForgotPasswordLinkStatus = useSelector(
    ({ forgotPasswordReducer }) =>
      forgotPasswordReducer?.forgotPassword?.sendForgotPasswordLinkData?.data
        ?.sendForgotPasswordLink
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [confirmationEmail, setConfirmationEmail] = React.useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordAction());
    };
  }, [dispatch]);

  useEffect(() => {
    setLoading(false);
    if (sendForgotPasswordLinkStatus?.status === 400) {
      enqueueSnackbar(sendForgotPasswordLinkStatus?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else if (sendForgotPasswordLinkStatus?.status === 200) {
      enqueueSnackbar(sendForgotPasswordLinkStatus?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setShowConfirmationDialog(true);
    }
  }, [enqueueSnackbar, sendForgotPasswordLinkStatus]);

  function onSubmitForgotPassword({ email }) {
    setLoading(true);
    const data = getEncryptedData(`{"e": "${email}"}`);
    const payload = {
      query: Query.sendForgotPasswordLink,
      variables: { data },
    };
    setConfirmationEmail(email);
    dispatch(sendForgotPasswordLink(payload));
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <span className="absolute bg-darkgreen rounded-full text-white w-32 h-32 text-center pt-2 top-72 left-72">
        <Icon className="text-28 cursor-pointer" onClick={() => history.go(-1)}>
          chevron_left
        </Icon>
      </span>

      <Paper
        className={`h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1 
        }`}
      >
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img src="assets/images/logo/icon.png" alt="logo" />

          <Typography className="mt-28 text-32 font-semibold tracking-tight leading-tight">
            {t('forgotPassword.title')}
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography className="text-15 font-medium mt-15">
              {t('forgotPassword.labels.description')}
            </Typography>
          </div>
          <form
            name="forgotPasswordForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmitForgotPassword)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputLabelProps={{
                    classes: {
                      asterisk: 'text-red-500',
                    },
                  }}
                />
              )}
            />

            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="secondary"
                className="w-full mt-16 md:text-18 font-semibold disabled:text-black"
                aria-label="Submit"
                disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                type="submit"
              >
                {t('forgotPassword.button.submit')}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </form>
        </div>
      </Paper>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        maxWidth="xs"
        fullWidth
        className="flex items-center justify-center"
      >
        <DialogTitle>
          <div className="flex items-center justify-center">
            <Icon fontSize="large" style={{ color: 'darkgreen' }}>
              check_circle
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent className="text-center py-10">
          <DialogContentText>
            {t('forgotPassword.labels.emailSentMessage')}
            <br />
            <strong>{confirmationEmail}</strong>
            <br />
            {t('forgotPassword.labels.resetPasswordLinkMessage')} <br />{' '}
            {t('forgotPassword.labels.resetPasswordMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setShowConfirmationDialog(false);
              history.push('/sign-in');
            }}
            variant="contained"
            color="secondary"
            className="mb-32 w-160"
          >
            {t('forgotPassword.button.okGotIt')}
          </Button>
        </DialogActions>
      </Dialog>
      <SigninBoxContent />
    </div>
  );
};
export default withReducer('forgotPasswordReducer', reducer)(ForgotPasswordPage);

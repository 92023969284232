import { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import Icon from '@mui/material/Icon';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import selectedBusinessData from '../../../query/selectedBusiness';
import { handleApiRequest } from '../../../common/common';

const QrCode = (props) => {
  const { selectedBusinessListDetails } = props;
  const [pdfLink, setPdfLink] = useState('');
  const currentPath = useLocation();

  useEffect(() => {
    async function fetchData() {
      if (selectedBusinessListDetails?.businessQRId) {
        const payload = {
          query: selectedBusinessData.createQRCodePdfWithInfo,
          variables: {
            businessQRId: selectedBusinessListDetails?.businessQRId,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.createQRCodePdfWithInfo?.status === 200) {
          setPdfLink(result?.createQRCodePdfWithInfo?.data?.pdf);
        }
      }
    }
    fetchData();
  }, [selectedBusinessListDetails?.businessQRId]);

  // const handleDownload = async () => {
  //   try {
  //     const response = await fetch(pdfLink);
  //     const blob = await response.blob();

  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'qr-code.pdf';

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading PDF:', error);
  //   }
  // };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfLink;
    link.target = '_blank';
    link.download = 'qr-code.pdf';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  return (
    <div className="inline-block text-center">
      <div className="mb-28 inline-block text-center">
        <h2 className="text-22 font-bold mb-12">
          {t('business.selectedBusinessDetail.labels.happyWithService')}
        </h2>
        <Typography className="text-14 font-semibold">
          {t('business.selectedBusinessDetail.labels.spreadTheWord')}
          <br /> {t('business.selectedBusinessDetail.labels.googleORYelp')}
        </Typography>
      </div>
      {selectedBusinessListDetails?.QRUrl && (
        <QRCode
          size={259}
          className="mx-auto"
          value={`${selectedBusinessListDetails?.QRUrl}${selectedBusinessListDetails?.businessQRId}`}
          id="QRCodes"
          includeMargin
          level="H"
        />
      )}

      <div className="text-yellow-800">
        <Icon>star</Icon>
        <Icon>star</Icon> <Icon>star</Icon> <Icon>star</Icon> <Icon>star</Icon>
      </div>
      <h3 className="text-20 font-semibold mb-16 mt-16">{selectedBusinessListDetails?.name}</h3>
      <Typography>{t('business.selectedBusinessDetail.labels.scanQRCodeToReviewUs')}</Typography>
      <div className="text-center w-full mt-20">
        <img
          src="/assets/images/icon/google icon.png"
          alt="google"
          className="h-auto inline-block mx-12"
        />
        <img src="/assets/images/icon/yelp icon.png" alt="google" className="inline-block mx-12" />
      </div>
      {!currentPath.pathname.includes('deactive-business-details') &&
        !currentPath.pathname.includes('converted-business-details') && (
          <>
            {pdfLink && (
              <Button
                variant="contained"
                color="secondary"
                className="px-32 py-7 font-semibold mt-28"
                onClick={handleDownload}
              >
                Download Code
              </Button>
            )}
          </>
        )}
    </div>
  );
};

export default QrCode;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`cmsApp/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
const makeApiRequest = (operationName) =>
  createAsyncThunk(`cmsApp/${operationName}`, async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data));
    if (data?.variables?.image) {
      formT.append('map', `{"nFile":["variables.image"]}`);
      formT.append('nFile', data.variables.image);
    } else {
      formT.append('map', '{}');
    }
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formT, {
        headers: {
          'apollo-require-preflight': 'true',
          'x-apollo-operation-name': operationName,
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  });

export const getAppBusinessCategories = createAsyncThunkWithAuthorization(
  'getAppBusinessCategories',
  baseURL
);

export const addAppBusinessCategories = createAsyncThunk(
  'cmsApp/addAppBusinessCategories',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;

    const formData = new FormData();
    formData.append('operations', JSON.stringify(data.payload));
    let variableData = `"0":["variables.input.0.image"]`;
    data?.fileData?.forEach((image, index) => {
      if (index !== 0)
        variableData = `${variableData},"${index}":["variables.input.${index}.image"]`;
    });
    formData.append('map', `{${variableData}}`);
    data?.fileData?.forEach((image, index) => {
      formData.append(index, image);
    });

    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formData, {
        headers: {
          'apollo-require-preflight': 'true',
          'Content-Type': 'multipart/form-data',
          'x-apollo-operation-name': 'addAppBusinessCategories',
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const updateAppBusinessCategories = createAsyncThunk(
  'cmsApp/updateDomainFeatures',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    let headerData = {};
    const formData = new FormData();
    let isFile = false;
    if (data?.fileData?.length > 0) {
      isFile = true;
      headerData = {
        headers: {
          'apollo-require-preflight': 'true',
          'Content-Type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateAppBusinessCategories',
        },
      };
      formData.append('operations', JSON.stringify(data));
      formData.append('map', `{"0":["variables.image"]}`);
      formData.append(0, data?.fileData[0]);
    }
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        !isFile ? { ...data } : formData,
        headerData
      );
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const getOnBoardingData = createAsyncThunkWithAuthorization('getOnBoardingData', baseURL);

export const addOnBoardingData = createAsyncThunk('cmsApp/addOnBoardingData', async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;

  const formData = new FormData();
  formData.append('operations', JSON.stringify(data.payload));
  let variableData = `"0":["variables.input.0.file"]`;
  data?.fileData?.forEach((image, index) => {
    if (index !== 0) variableData = `${variableData},"${index}":["variables.input.${index}.file"]`;
  });
  formData.append('map', `{${variableData}}`);
  data?.fileData?.forEach((image, index) => {
    formData.append(index, image);
  });

  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formData, {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
        'x-apollo-operation-name': 'addHomeOrSliderData',
      },
    });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const updateOnBoardingData = createAsyncThunk(
  'cmsApp/updateOnBoardingData',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data.payload));
    if (data?.fileData) {
      formT.append('map', '{"nFile":["variables.image"]}');
      formT.append('nFile', data.fileData);
    } else {
      formT.append('map', '{}');
    }
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formT, {
        headers: {
          'apollo-require-preflight': 'true',
          'x-apollo-operation-name': 'updateStoreBusinessBlog',
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const deleteOnBoardingData = createAsyncThunkWithAuthorization(
  'deleteOnBoardingData',
  baseURL
);

export const getBusinessFeedback = createAsyncThunkWithAuthorization(
  'getBusinessFeedback',
  baseURL
);

export const updatePopulerAndRecommendedAsIsMenuData = createAsyncThunkWithAuthorization(
  'updatePopulerAndRecommendedAsIsMenuData',
  baseURL
);

export const getAppBusinessCategoriesData = createAsyncThunkWithAuthorization(
  'getAppBusinessCategoriesData',
  baseURL
);

export const getBusinessMenuAndServicesData = createAsyncThunkWithAuthorization(
  'getBusinessMenuAndServicesData',
  baseURL
);

export const updateBusinessMenuAndServicesData = makeApiRequest('updateBusinessMenuAndServices');
export const addAppBusinessMenuAndServicesData = makeApiRequest('addAppBusinessMenuAndServices');

export const isPopularDatagetBusinessMenuAndServicesData = createAsyncThunkWithAuthorization(
  'isPopularDatagetBusinessMenuAndServicesData',
  baseURL
);
export const isRecommendedDatagetBusinessMenuAndServicesData = createAsyncThunkWithAuthorization(
  'isRecommendedDatagetBusinessMenuAndServicesData',
  baseURL
);

const cmsAppSlice = createSlice({
  name: 'cmsApp',
  initialState: {
    AddItemDialog: {
      type: 'popular',
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openAddPopularItemDialog: (state, action) => {
      state.AddItemDialog = {
        type: 'popular',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeAddPopularItemDialog: (state, action) => {
      state.AddItemDialog = {
        type: 'popular',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openAddRecommendedItemDialog: (state, action) => {
      state.AddItemDialog = {
        type: 'recommended',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeAddRecommendedItemDialog: (state, action) => {
      state.AddItemDialog = {
        type: 'recommended',
        props: {
          open: false,
        },
        data: null,
      };
    },
    resetCmsApp: (state, action) => {
      if (state) {
        state.getOnBoardingDataValue = {};
        state.addOnBoardingDataValue = {};
        state.updateOnBoardingDataValue = {};
        state.deleteOnBoardingDataValue = {};
        state.getAppBusinessCategoriesData = {};
        state.addAppBusinessCategoriesData = {};
        state.getBusinessFeedbackData = {};
        state.updatePopulerAndRecommendedAsIsMenuDataValue = {};
        state.getAppBusinessCategoriesDataValue = {};
        state.getBusinessMenuAndServicesDataValue = {};
        state.addAppBusinessMenuAndServicesDataValue = {};
        state.updateBusinessMenuAndServicesDataValue = {};
        state.isPopularDatagetBusinessMenuAndServicesDataValue = {};
        state.isRecommendedDatagetBusinessMenuAndServicesDataValue = {};
      }
    },
  },
  extraReducers: {
    [addOnBoardingData.fulfilled]: (state, action) => ({
      ...state,
      addOnBoardingDataValue: action.payload,
    }),
    [updateOnBoardingData.fulfilled]: (state, action) => ({
      ...state,
      updateOnBoardingDataValue: action.payload,
    }),
    [deleteOnBoardingData.fulfilled]: (state, action) => ({
      ...state,
      deleteOnBoardingDataValue: action.payload,
    }),
    [getAppBusinessCategories.fulfilled]: (state, action) => ({
      ...state,
      getAppBusinessCategoriesData: action.payload,
    }),
    [addAppBusinessCategories.fulfilled]: (state, action) => ({
      ...state,
      addAppBusinessCategoriesData: action.payload,
    }),
    [updateAppBusinessCategories.fulfilled]: (state, action) => ({
      ...state,
      updateAppBusinessCategoriesData: action.payload,
    }),
    [getBusinessFeedback.fulfilled]: (state, action) => ({
      ...state,
      getBusinessFeedbackData: action.payload,
    }),
    [getOnBoardingData.fulfilled]: (state, action) => ({
      ...state,
      getOnBoardingDataValue: action.payload,
    }),
    [updatePopulerAndRecommendedAsIsMenuData.fulfilled]: (state, action) => ({
      ...state,
      updatePopulerAndRecommendedAsIsMenuDataValue: action.payload,
    }),
    [getAppBusinessCategoriesData.fulfilled]: (state, action) => ({
      ...state,
      getAppBusinessCategoriesDataValue: action.payload,
    }),
    [getBusinessMenuAndServicesData.fulfilled]: (state, action) => ({
      ...state,
      getBusinessMenuAndServicesDataValue: action.payload,
    }),
    [addAppBusinessMenuAndServicesData.fulfilled]: (state, action) => ({
      ...state,
      addAppBusinessMenuAndServicesDataValue: action.payload,
    }),
    [updateBusinessMenuAndServicesData.fulfilled]: (state, action) => ({
      ...state,
      updateBusinessMenuAndServicesDataValue: action.payload,
    }),
    [isPopularDatagetBusinessMenuAndServicesData.fulfilled]: (state, action) => ({
      ...state,
      isPopularDatagetBusinessMenuAndServicesDataValue: action.payload,
    }),
    [isRecommendedDatagetBusinessMenuAndServicesData.fulfilled]: (state, action) => ({
      ...state,
      isRecommendedDatagetBusinessMenuAndServicesDataValue: action.payload,
    }),
  },
});

export const {
  resetCmsApp,
  openAddPopularItemDialog,
  closeAddPopularItemDialog,
  openAddRecommendedItemDialog,
  closeAddRecommendedItemDialog,
} = cmsAppSlice.actions;

export default cmsAppSlice.reducer;

import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Select from '@mui/material/Select';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useSnackbar } from 'notistack';
import cmsData from '../../query/cms';
import {
  resetCmsApp,
  getOnBoardingData,
  addOnBoardingData,
  deleteOnBoardingData,
  updateOnBoardingData,
} from './store/cmsAppSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Onboarding() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultValues = {
    // bannerTitle: '',
    // description: '',
    // file: '',
  };

  const schema = yup.object().shape({
    formData: yup.array().of(
      yup.object().shape({
        heading: yup
          .string()
          .required(t('appWebsite.onboarding.formField.heading.validationMessages.required')),
        description: yup
          .string()
          .required(t('appWebsite.onboarding.formField.description.validationMessages.required')),
      })
    ),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [imagePreviewName, setImagePreviewName] = useState([]);
  const [noImgMsg, setNoImgMsg] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [actionValue, setActionValue] = useState('action');
  const loginUserData = UserService.getUserData();
  const [editOnboardingId, setEditOnboardingId] = useState(null);
  const [editImagePreview, setEditImagePreview] = useState();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const onboardingDetails = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getOnBoardingDataValue?.data?.getOnboardingData?.data || ''
  );
  const deleteStatus = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.deleteOnBoardingDataValue?.data?.deleteOnboardingData || ''
  );
  const updateOnboardingStatus = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.updateOnBoardingDataValue?.data?.updateOnboardingData || ''
  );
  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getOnboardingData,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getOnBoardingData(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (deleteStatus?.status) {
      enqueueSnackbar(deleteStatus?.message, {
        variant: deleteStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [deleteStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateOnboardingStatus?.status) {
      enqueueSnackbar(updateOnboardingStatus?.message, {
        variant: updateOnboardingStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateOnboardingStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (onboardingDetails?.length === 0) {
      append({ heading: '', description: '' });
    }
  }, [onboardingDetails?.length, append]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (onboardingDetails || onboardingDetails === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [onboardingDetails, isValidDomainRegisterId]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleDeleteOnboarding = async (id) => {
    try {
      const deletePayload = {
        query: cmsData.deleteOnboardingData,
        variables: {
          onBoardingId: parseInt(id, 10),
        },
      };
      await dispatch(deleteOnBoardingData(deletePayload));
      const obj = {
        query: cmsData.getOnboardingData,
        variables: { domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10) },
      };
      await dispatch(getOnBoardingData(obj));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditOnboarding = async (item) => {
    setEditOnboardingId(parseInt(item.id, 10));
    setEditImagePreview(item?.image);
    setIsEdit(true);
    const fieldsCopy = [...fields];
    fieldsCopy.forEach((field, index) => {
      remove(index);
    });
    append(item);
  };

  function handleFileChange(event, index) {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }

    imagePreviewName[index] = event?.target?.files[0].name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);

    setNoImgMsg('');
  }

  function handleDrop(event, index) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    imagePreviewName[index] = event?.dataTransfer?.files[0].name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);
    setNoImgMsg('');
  }

  const handleAddNewOnboarding = () => {
    setIsEdit(true);
    setEditOnboardingId(null);
    reset({ formData: [{ heading: '', description: '' }] });
  };

  const formsData = [{ heading: '', description: '' }];
  const onSubmit = async (values) => {
    setRefreshIcon(true);
    try {
      if (editOnboardingId) {
        const payloads = {
          query: cmsData.UpdateOnboardingData,
          variables: {
            onBoardingId: editOnboardingId,
            description: values?.formData[0]?.description,
            heading: values?.formData[0]?.heading,
          },
        };
        const dataObjs = {
          payload: payloads,
          fileData: images[0],
        };
        await dispatch(updateOnBoardingData(dataObjs));
      } else {
        if (images?.length < values.formData?.length) {
          enqueueSnackbar(t('appWebsite.bannerRequiredMessage'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setRefreshIcon(false);
          return;
        }

        if (!isValidDomainRegisterId) {
          enqueueSnackbar(t('appWebsite.validationMessage.domainRegisterIdRequired'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setRefreshIcon(false);
          return;
        }
        const payloadValue = {
          query: cmsData.addOnboardingData,
          variables: {
            domainRegisterId: userDomainRegisterId,
            input: values.formData,
          },
        };

        const dataObj = {
          payload: payloadValue,
          fileData: images,
        };

        await dispatch(addOnBoardingData(dataObj));
        setImages([]);
        setImagePreviewName([]);
      }
      setIsEdit(false);
      setEditOnboardingId(null);
      setRefreshIcon(false);
      reset({ formData: [{ heading: '', description: '' }] });
      const obj = {
        query: cmsData.getOnboardingData,
        variables: { domainRegisterId: userDomainRegisterId },
      };

      await dispatch(getOnBoardingData(obj));
      reset();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.onboarding.title')}
            </Typography>
          </div>
          {isEdit && (
            <div className="inline-block justify-end flex w-auto">
              <Button
                className="text-18 px-15 font-semibold"
                variant="outlined"
                color="secondary"
                aria-label="Edit"
                onClick={(e) => {
                  setIsEdit(false);
                  setEditOnboardingId(null);
                  reset({ formData: [{ heading: '', description: '' }] });
                }}
              >
                {t('business.selectedBusinessDetail.buttons.cancel')}
              </Button>
            </div>
          )}
          <div>
            {!onboardingDetails.length > 0 || isEdit ? (
              <form
                name="registerForm"
                noValidate
                className="w-full"
                defaultValue={{ forms: formsData }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <div>
                    {fields.map((fieldInput, index) => {
                      return (
                        <div
                          className="bg-white rounded-md relative shadow mt-20 p-24 mb-40"
                          key={index}
                        >
                          <div className="flex">
                            <div className="w-full">
                              <div className="block">
                                <InputLabel
                                  htmlFor="heading"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.onboarding.formField.heading.name')}*
                                </InputLabel>
                                <Controller
                                  // name="bannerTitle"
                                  name={`formData[${index}].heading`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="text"
                                      size="small"
                                      value={undefined}
                                      defaultValue={fieldInput.heading}
                                      error={!!errors?.formData?.[index]?.heading}
                                      helperText={errors?.formData?.[index]?.heading?.message}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="block">
                                <InputLabel
                                  htmlFor="description"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.onboarding.formField.description.name')}*
                                </InputLabel>
                                <Controller
                                  // name="description"
                                  name={`formData[${index}].description`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          background: 'white',
                                        },
                                      }}
                                      type="text"
                                      size="small"
                                      value={undefined}
                                      multiline
                                      rows={4}
                                      defaultValue={fieldInput.description}
                                      error={!!errors?.formData?.[index]?.description}
                                      helperText={errors?.formData?.[index]?.description?.message}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <motion.div className="block mb-24">
                                <InputLabel
                                  htmlFor={`formData[${index}].file`}
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('appWebsite.onboarding.formField.uploadImage.name')}
                                </InputLabel>
                                <Paper
                                  className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                                  role="button"
                                  onDrop={(event) => {
                                    handleDrop(event, index);
                                  }}
                                  onDragOver={(e) => e.preventDefault()}
                                >
                                  <div className="inline-block">
                                    {/* <img
                            className="w-2/4 block rounded max-h-96"
                            name="logo"
                            src={imagePreview}
                            alt="logo"
                          /> */}
                                    <Controller
                                      name={`formData[${index}].file`}
                                      control={control}
                                      error={!!errors?.formData?.[index]?.file}
                                      helperText={errors?.formData?.[index]?.file?.message}
                                      render={({ field }) => (
                                        <label htmlFor={`formData[${index}].file`}>
                                          <input
                                            {...field}
                                            accept="image/*"
                                            className="hidden"
                                            name={`formData[${index}].file`}
                                            id={`formData[${index}].file`}
                                            type="file"
                                            onChange={(event) => {
                                              handleFileChange(event, index);
                                            }}
                                          />
                                          <Button
                                            size="small"
                                            className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                                            variant="contained"
                                            color="secondary"
                                            component="span"
                                          >
                                            <Icon color="action material-symbols-outlined align-middle">
                                              upload
                                            </Icon>{' '}
                                            {t('reviewPlatform.buttons.uploadReviewIcon')}
                                          </Button>
                                        </label>
                                      )}
                                    />
                                  </div>
                                  <div className="inline-block ml-16 font-medium">
                                    {imagePreviewName[index] ||
                                      `${t('appWebsite.onboarding.formField.OrDropImage')}`}
                                  </div>
                                  {noImgMsg && (
                                    <span className="text-red block mt-16">{noImgMsg}</span>
                                  )}
                                </Paper>
                              </motion.div>
                              {editOnboardingId && editImagePreview && (
                                <img
                                  src={editImagePreview}
                                  className="rounded-md max-w-400"
                                  alt="..."
                                />
                              )}
                            </div>
                            {!editOnboardingId && index !== 0 && (
                              <Button
                                onClick={() => remove(index)}
                                className="ml-24 w-44 px-0 min-w-44 h-44 min-h-44 rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                                type="button"
                                color="secondary"
                                variant="contained"
                              >
                                <Icon>delete</Icon>
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {!editOnboardingId && (
                      <div className="text-right">
                        <Button
                          className="rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={() => append({ heading: '', description: '' })}
                        >
                          <Icon>add</Icon> {t('appWebsite.onboarding.formField.addNew')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {fields.length > 0 && (
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Register"
                      disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                      type="submit"
                      size="small"
                    >
                      {t('appWebsite.onboarding.formField.button')}{' '}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                )}
              </form>
            ) : (
              <>
                <div className="items-center mb-16 text-right">
                  <div className="inline-block items-center w-auto">
                    <Button
                      className="text-16 px-16 rounded-md"
                      variant="contained"
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => handleAddNewOnboarding()}
                      // onClick={(e) => setIsEdit(true)}
                    >
                      {t('appWebsite.onboarding.formField.addNew')}
                    </Button>
                  </div>
                </div>
                <div className="bg-white rounded-md relative shadow p-24">
                  <TableContainer className="border-b-1 border-solid border-grey-200">
                    <Table aria-labelledby="tableTitle" size="medium">
                      <TableHead>
                        <TableRow className="bg-grey-200 rounded-tr-md">
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.onboarding.column.image')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.onboarding.column.name')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.onboarding.column.date')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                            {t('appWebsite.onboarding.column.action')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {onboardingDetails &&
                          onboardingDetails?.length > 0 &&
                          onboardingDetails?.map((item, i) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100"
                                key={i}
                              >
                                <TableCell
                                  className="text-16 color-black font-semibold"
                                  align="left"
                                >
                                  <img
                                    src={item?.image}
                                    className="img-fluid max-w-80 rounded-md"
                                    alt="trial_accounts"
                                  />
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold"
                                  align="left"
                                >
                                  {item?.heading}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold"
                                  align="left"
                                >
                                  {moment(item?.cts).format('MMM DD, YYYY')}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold"
                                  align="left"
                                >
                                  <Select
                                    className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                    sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                    variant="filled"
                                    value={actionValue}
                                    fullWidth
                                    // onChange={handleActionDropdownChange}
                                  >
                                    <MenuItem
                                      value="action"
                                      className="capitalize mx-5 rounded-md"
                                      disabled
                                    >
                                      {t('menuItems.action')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => {
                                        handleEditOnboarding(item);
                                      }}
                                    >
                                      {t('menuItems.edit')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => handleDeleteOnboarding(item.id)}
                                    >
                                      {t('menuItems.delete')}
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsAppReducer', reducer)(Onboarding);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`business/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getAllBusinessType = createAsyncThunkWithAuthorization(
  'getAllBusinessType',
  'business/getAllBusinessType'
);
export const addBusinessType = createAsyncThunkWithAuthorization(
  'addBusinessType',
  'business/addBusinessType'
);
export const enableDisableBusinessType = createAsyncThunkWithAuthorization(
  'enableDisableBusinessType',
  'business/enableDisableBusinessType'
);
export const updateBusinessType = createAsyncThunkWithAuthorization(
  'updateBusinessType',
  'business/updateBusinessType'
);
export const getAllBusinessAttributes = createAsyncThunkWithAuthorization(
  'getAllBusinessAttributes',
  'business/getAllBusinessAttributes'
);
export const addBusinessAttributes = createAsyncThunkWithAuthorization(
  'addBusinessAttributes',
  'business/addBusinessAttributes'
);
export const getBusinessAttributeValue = createAsyncThunkWithAuthorization(
  'getBusinessAttributeValue',
  'business/getBusinessAttributeValue'
);
export const updateBusinessAttribute = createAsyncThunkWithAuthorization(
  'updateBusinessAttribute',
  'business/updateBusinessAttribute'
);
export const getAllBusinessTypeAttributes = createAsyncThunkWithAuthorization(
  'getAllBusinessTypeAttributes',
  'business/getAllBusinessTypeAttributes'
);
export const addBusinessTypeAttribute = createAsyncThunkWithAuthorization(
  'addBusinessTypeAttribute',
  'business/addBusinessTypeAttribute'
);
export const updateBusinessAttributeType = createAsyncThunkWithAuthorization(
  'updateBusinessAttributeType',
  'selectedBusiness/updateBusinessAttributeType'
);
export const getBusinessType = createAsyncThunkWithAuthorization(
  'getBusinessType',
  'selectedBusiness/getBusinessType'
);
export const getBusinessList = createAsyncThunkWithAuthorization(
  'getBusinessList',
  'selectedBusiness/getBusinessList'
);

export const deleteBusiness = createAsyncThunkWithAuthorization(
  'deleteBusiness',
  'selectedBusiness/deleteBusiness'
);

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    businessDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openNewBusinessDialog: (state, action) => {
      state.businessDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewBusinessDialog: (state, action) => {
      state.businessDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditBusinessDialog: (state, action) => {
      state.businessDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditBusinessDialog: (state, action) => {
      state.businessDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    resetBusiness: (state, action) => {
      if (state) {
        state.getAllBusinessTypeData = {};
        state.addBusinessTypeData = {};
        state.updateBusinessTypeData = {};
        state.getAllBusinessAttributesData = {};
        state.addBusinessAttributesData = {};
        state.updateBusinessAttributeData = {};
        state.getAllBusinessTypeAttributesIdData = {};
        state.addBusinessTypeAttributeData = {};
        state.updateBusinessAttributeTypeData = {};
        state.enableDisableBusinessTypeData = {};
        state.getBusinessTypeData = {};
        state.getBusinessListData = {};
        state.deleteBusinessData = {};
        state.getBusinessAttributeValueData = {};
      }
    },
  },
  extraReducers: {
    [getAllBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getAllBusinessTypeData: action.payload,
    }),
    [addBusinessType.fulfilled]: (state, action) => ({
      ...state,
      addBusinessTypeData: action.payload,
    }),
    [updateBusinessType.fulfilled]: (state, action) => ({
      ...state,
      updateBusinessTypeData: action.payload,
    }),
    [getAllBusinessAttributes.fulfilled]: (state, action) => ({
      ...state,
      getAllBusinessAttributesData: action.payload,
    }),
    [addBusinessAttributes.fulfilled]: (state, action) => ({
      ...state,
      addBusinessAttributesData: action.payload,
    }),
    [updateBusinessAttribute.fulfilled]: (state, action) => ({
      ...state,
      updateBusinessAttributeData: action.payload,
    }),
    [getAllBusinessTypeAttributes.fulfilled]: (state, action) => ({
      ...state,
      getAllBusinessTypeAttributesIdData: action.payload,
    }),
    [getBusinessAttributeValue.fulfilled]: (state, action) => ({
      ...state,
      getBusinessAttributeValueData: action.payload,
    }),
    [addBusinessTypeAttribute.fulfilled]: (state, action) => ({
      ...state,
      addBusinessTypeAttributeData: action.payload,
    }),
    [updateBusinessAttributeType.fulfilled]: (state, action) => ({
      ...state,
      updateBusinessAttributeTypeData: action.payload,
    }),
    [enableDisableBusinessType.fulfilled]: (state, action) => ({
      ...state,
      enableDisableBusinessTypeData: action.payload,
    }),
    [getBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getBusinessTypeData: action.payload,
    }),
    [getBusinessList.fulfilled]: (state, action) => ({
      ...state,
      getBusinessListData: action.payload,
    }),
    [deleteBusiness.fulfilled]: (state, action) => ({
      ...state,
      deleteBusinessData: action.payload,
    }),
  },
});

export const {
  resetBusiness,
  openNewBusinessDialog,
  closeNewBusinessDialog,
  openEditBusinessDialog,
  closeEditBusinessDialog,
} = businessSlice.actions;

export default businessSlice.reducer;

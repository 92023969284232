import { t } from 'i18next';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  TablePagination,
  Input,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useSnackbar } from 'notistack';
import './Waba.css';
import AddContact from './AddContact';
import wabaQuery from '../../query/waba';
import businessData from '../../query/business';
import { handleApiRequest } from '../../common/common';

const WabaContact = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [ContactList, setContactList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogType, setOpenDialogType] = useState('add');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRowAction, setSelectedRowAction] = useState('action');
  const [selectedRow, setSelectedRow] = useState(null);

  const handleContactList = useCallback(async () => {
    if (!searchValue) {
      try {
        const payload = {
          query: businessData.getUserCSV,
          variables: {
            callFrom: 'whatsapp',
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserCSV?.status === 200) {
          setContactList(result?.getUserCSV?.data?.allContact);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }, [searchValue]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
    handleContactList();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  }

  const handleSearchEvent = async (event) => {
    setSearchValue(event.target.value);
    setPage(1);

    const payload = {
      query: wabaQuery.searchCampaignContact,
      variables: {
        callFrom: 'whatsapp',
        searchText: event.target.value,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchCampaignContact?.status === 200) {
      setContactList(result?.searchCampaignContact?.data);
    }
  };

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
  };

  function EnhancedTableHead() {
    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.phone')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.createdDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.action')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleCancelSubscription = async () => {
    const payloadData = {
      query: wabaQuery.deleteUserCsvContactOrCampaignContact,
      variables: {
        phoneNumber: `${selectedRow?.phoneNumber}`,
        email: selectedRow?.email,
        dataFrom: selectedRow?.dataFrom,
        id: selectedRow?.id,
      },
    };
    const result = await handleApiRequest(payloadData);
    if (result?.deleteUserCsvContactOrCampaignContact?.status === 200) {
      enqueueSnackbar(result?.deleteUserCsvContactOrCampaignContact?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setOpenDialog(false);
      handleContactList();
    }
  };

  function handleActionDropdownChange(event) {
    setSelectedRowAction(event.target.value);
  }

  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="bg-white rounded-md">
          <div className="flex items-center justify-between border-b-1 border-solid border-grey-300 py-12 px-24">
            <h3 className="text-20 font-semibold">{t('waba.broadcast.contactList.title')}</h3>
            <div className="inline-block min-w-360 rounded-8 shadow-none border-1 border-solid border-grey-400 rounded-md relative align-middle">
              <Input
                placeholder={t('waba.broadcast.contactList.search')}
                className="text-black text-16 h-40  px-12"
                disableUnderline
                name="searchText"
                fullWidth
                onChange={handleSearchEvent}
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
              />
              <span className="absolute right-8 top-10">
                {searchValue ? (
                  <Icon
                    color="secondary"
                    className="cursor-pointer text-20"
                    onClick={() => handleClearSearch()}
                  >
                    close
                  </Icon>
                ) : (
                  <Icon color="secondary" className="text-20">
                    search
                  </Icon>
                )}
              </span>
            </div>
            <Button
              onClick={() => setOpenCreateDialogue(true)}
              className="rounded-md md:px-40 text-14 font-medium inline-block align-middle ml-36"
              variant="contained"
              color="secondary"
            >
              {t('waba.broadcast.contactList.addContact')}
            </Button>
          </div>
          <div>
            <div className="p-16 contact-height w-full">
              <div className="flex justify-center items-center contact-height">
                {ContactList && ContactList?.length > 0 ? (
                  <div className="w-full">
                    <TableContainer className="border border-solid border-grey-300 rounded-md">
                      <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead />
                        <TableBody className="block md:table-row-group">
                          {ContactList?.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100 block md:table-row"
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:w-60 py-5 pt-16 md:py-16"
                                  align="left"
                                >
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                                  align="left"
                                >
                                  {row?.name}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-136 py-5 md:py-16"
                                  align="left"
                                >
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                    {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                  </span>
                                  {row?.phoneNumber
                                    ? `${row?.countryCode} ${row?.phoneNumber}`
                                    : '-'}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16 capitalize"
                                  align="left"
                                >
                                  <span>{moment.unix(row.cts / 1000).format('MMM DD, YYYY')}</span>
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                  align="left"
                                >
                                  <Select
                                    className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                    sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                    variant="filled"
                                    value={selectedRow === row ? selectedRowAction : 'action'}
                                    fullWidth
                                    onChange={handleActionDropdownChange}
                                  >
                                    <MenuItem
                                      value="action"
                                      className="capitalize mx-5 rounded-md"
                                      disabled
                                    >
                                      {t('business.businessList.column.action')}{' '}
                                      {/* {refreshIcon && row.id === selectedBusinessId && (
                                        <CircularProgress
                                          size={20}
                                          className="text-darkgreen absolute mx-auto right-5"
                                        />
                                      )} */}
                                    </MenuItem>
                                    <MenuItem
                                      value="view"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => {
                                        setOpenCreateDialogue(true);
                                        setSelectedRow(row);
                                        setOpenDialogType('edit');
                                      }}
                                    >
                                      {t('waba.broadcast.contactList.edit')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => {
                                        // handleDelete(row.id);
                                        setSelectedRow(row);
                                        setOpenDialog(true);
                                      }}
                                    >
                                      {t('waba.broadcast.contactList.delete')}
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* <TablePagination
                      sx={{
                        '.MuiInputBase-root': {
                          lineHeight: 'inherit',
                          padding: 0,
                        },
                      }}
                      component="div"
                      count={ContactList?.length}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      backIconButtonProps={{
                        'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                        'aria-label': 'Next Page',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                  </div>
                ) : (
                  <div className="text-center h-full flex items-center block">
                    <div className="mx-auto">
                      <img
                        src="/assets/images/business/contact.png"
                        className="img-fluid w-full mx-auto max-w-144"
                        alt="..."
                      />
                      <Typography className="text-24 font-semibold mt-36">
                        {t('waba.broadcast.contactList.noContact')}
                      </Typography>
                      <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                        {t('waba.broadcast.contactList.noContactDesc')}
                      </Typography>
                      <Button
                        onClick={() => setOpenCreateDialogue(true)}
                        className="rounded-md md:px-40 text-16 font-semibold"
                        variant="contained"
                        color="secondary"
                      >
                        {t('waba.broadcast.contactList.addContact')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {openCreateDialogue && (
          <AddContact
            openValue={openCreateDialogue}
            cancelCall={cancelDialogue}
            type={openDialogType}
            popUpMessage={t('waba.broadcast.contactList.addContact')}
            popUpButtonName={t('waba.broadcast.contactList.addContact')}
            selectedRow={selectedRow}
            initialValues={{
              firstName: selectedRow?.name,
              // lastName: selectedRow?.lastName,
              phone_number: `${selectedRow?.countryCode} ${selectedRow?.phoneNumber}`,
              mail: selectedRow?.email ? selectedRow?.email : '',
            }}
          />
        )}
        {openDialog && (
          <>
            <Dialog
              classes={{
                paper: 'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-red-600',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle className="p-0 text-center">
                <img
                  src="/assets/images/business/warning_alert.png"
                  className="img-fluid mx-auto w-136 h-136"
                  alt="..."
                />
                <Typography className="text-20 font-semibold text-black text-center w-full pb-10 mt-28 mb-0">
                  {t('waba.broadcast.contactList.confirmDeletion')}
                </Typography>
                <Icon
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  close
                </Icon>
              </DialogTitle>
              <DialogContent className="p-0 mb-28">
                <DialogContentText
                  id="alert-dialog-description"
                  className="text-14 font-medium text-center text-black max-w-sm mx-auto"
                >
                  {t('waba.broadcast.contactList.confirmDeletionDesc')}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="p-0 justify-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-24 pb-6"
                  aria-label={t('waba.broadcast.contactList.cancel')}
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  type="button"
                  size="small"
                >
                  {t('waba.broadcast.contactList.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-24 pb-6"
                  aria-label={t('waba.broadcast.contactList.deleteContact')}
                  onClick={() => handleCancelSubscription()}
                  // disabled={refreshCancelSubscriptionIcon}
                  type="button"
                  size="small"
                >
                  {t('waba.broadcast.contactList.deleteContact')}
                  {/* {refreshCancelSubscriptionIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )} */}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default WabaContact;

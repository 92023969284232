import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Icon,
  Select,
  MenuItem,
  TextField,
  Input,
  Paper,
  CircularProgress,
} from '@mui/material';
import './MangeBusiness.css';
import { useSnackbar } from 'notistack';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import axios from 'axios';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhoneInput from 'react-phone-input-2';
import history from '@history';
import manageBusinessData from '../../query/manageBusiness';
import reducer from './store';
import {
  countryData,
  getDecryptData,
  getParamsId,
  handleApiRequest,
  sortOptions,
} from '../../common/common';
import queryData from '../../query/common';
import userService from '../../../services';
import { getBusinessTypeData } from '../../../store/userSlice';
import Loading from './Loading';

function BusinessLocation(prop) {
  const {
    reviewPlateFormData,
    setActiveStepNumber,
    setReviewPlateFormData,
    packageTypeId,
    clientId,
  } = prop;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [isZomato, setIsZomato] = useState(false);
  const [isYelp, setIsYelp] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [isBusinessFetch, setIsBusinessFetch] = useState(false);
  const [yelpData, setYelpData] = useState([]);
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [searchBusinessName, setSearchBusinessName] = useState();
  const [googleData, setGoogleData] = useState({});
  const [addBusinessData, setAddBusinessData] = useState({
    address: '',
    businessName: '',
    websiteUrl: '',
    businessType: '',
    phone: '',
    googlePhoneNumber: '',
    businessEmail: '',
    email: '',
    country: '',
    city: '',
    businessImage: '',
    reviewPlatformId: '',
  });
  const [zomatoBusiness, setZomatoBusiness] = useState();
  const [zomatoVerified, setZomatoVerified] = useState(false);
  const [zomatoUrl, setZomatoUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [stateName, setStateName] = useState();
  const [stateData, setStateData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [businessType, setBusinessType] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [zipCode, setZipCode] = useState();
  const [dataAvailable, setDataAvailable] = useState(false);
  const [isValidZomatoUrl, setIsValidZomatoUrl] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isGoogleBusinessAdd, setIsGoogleBusinessAdd] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [number, setNumber] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [previousCountryId, setPreviousCountryId] = useState();
  const [countryLocation, setCountryLocation] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const loginUserData = userService.getUserData();

  const convertUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  useEffect(() => {
    const fetchBusinessType = async () => {
      const payload = {
        query: queryData.getBusinessType,
      };
      const res = await handleApiRequest(payload);
      if (res?.getBusinessType?.status === 200) {
        const businessTypeData = res?.getBusinessType?.data?.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }));
        setBusinessType(businessTypeData);
      }
    };
    fetchBusinessType();
  }, []);

  useEffect(() => {
    const ipData =
      localStorage.getItem('ipData') && JSON.parse(getDecryptData(localStorage.getItem('ipData')));
    setCountryLocation(ipData?.isoCode);
    const findCountry = countryList?.find((country) => country?.isoCode === ipData?.isoCode);
    setSelectedCountry(findCountry);
    setCountryId(findCountry?.id);
  }, [countryList]);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const payload = {
        query: queryData.getCountries,
      };
      const res = await handleApiRequest(payload);
      setPageLoading(false);
      if (res?.getCountries?.status === 200) {
        setCountryList(res?.getCountries?.data);
      } else {
        enqueueSnackbar(res?.getCountries?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectBusinessType = (selectedOption) => {
    setAddBusinessData({
      ...addBusinessData,
      businessType: selectedOption ? selectedOption.value : null,
    });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!loading) {
      setLoading(true);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchCountryData = async () => {
      const payload = {
        query: queryData.getStates,
        variables: {
          countryId: parseInt(countryId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getStates?.status === 200) {
        setStateData(result.getStates?.data);
      }
    };
    if (countryId) {
      fetchCountryData();
    }
  }, [countryId]);

  const fetchYelpData = useCallback(
    async (page) => {
      try {
        if (previousCountryId !== countryId) {
          setPageLoading(true);
        }
        const payload = {
          query: manageBusinessData.searchYelpBusiness,
          variables: {
            location: countryLocation,
            search: searchBusinessName,
            pageNumber: page,
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        setPreviousCountryId(countryId);
        setLoading(false);
        if (result?.searchYelpBusiness?.status === 200) {
          setDataAvailable(result?.searchYelpBusiness?.data?.length === 0 && true);
          setYelpData((prevData) => [...prevData, ...result.searchYelpBusiness.data]);
        } else {
          enqueueSnackbar(result?.searchYelpBusiness?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching Yelp data:', error);
      }
    },
    [enqueueSnackbar, countryLocation, searchBusinessName, previousCountryId, countryId]
  );

  useEffect(() => {
    if (!dataAvailable && isYelp) {
      fetchYelpData();
    }
  }, [fetchYelpData, dataAvailable, isYelp, countryLocation]);

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });

      if (!place?.place_id) {
        enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }

      setSelectedMapFullAddress(place.formatted_address);
      let formattedAddress = '';

      try {
        const googleBusinessData = await axios({
          url: process.env.REACT_APP_AI_GOOGLE_PLACE,
          method: 'post',
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            place_id: place?.place_id,
            place_name: place.name,
          }),
        });

        const googleDetails = googleBusinessData?.data?.data?.data;
        formattedAddress = place.formatted_address;
        const tempPhone = place?.international_phone_number
          ?.split(' ')
          .join('')
          .replace(/[^0-9]/g, '');

        if (googleBusinessData?.status === 200) {
          const findCountry = countryList?.find(
            (country) => country?.name === googleDetails?.country
          );
          setCountryId(findCountry?.id);
          await setGoogleData({
            address: formattedAddress,
            businessName: place?.name,
            url: place?.url,
            businessImage: googleDetails?.profile_image,
            rating: place?.rating,
            userTotalRating: place?.user_ratings_total,
            phone: {
              ...(tempPhone && {
                phoneNumber: tempPhone,
                countryCode: place?.international_phone_number?.split(' ')[0],
                isoCode: findCountry?.isoCode,
              }),
            },
            country: googleDetails?.country,
          });
        }
      } catch (error) {
        console.error('An error occurred', error);
      }

      const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'google') {
          return {
            ...ele,
            address: formattedAddress,
            businessName: place?.name,
            placeId: place.place_id,
            url: place.url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
      setDataLoading(false);
    }
  };
  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onDragChanged = async (event) => {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
    }
  };
  const handleRemoveBusiness = async (data) => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.id === data.id) {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      if (ele.businessName) {
        return {
          ...ele,
          isBusinessDetailsAdded: true,
        };
      }
      return ele;
    });

    const findBusinessDetails = await removeBusinessInformation?.find(
      (ele) => ele.isBusinessDetailsAdded === true
    );
    if (findBusinessDetails) {
      addBusinessInformation(findBusinessDetails);
    }
    const findData = reviewPlateFormData?.find((item) => item?.id === data?.id);
    if (findData?.isBusinessDetailsAdded === true) {
      setAddBusinessData();
    }
    if (data.name === 'google') {
      setGoogleData();
    }
    if (data.name === 'zomato') {
      setZomatoUrl();
      setZomatoVerified(false);
      setZomatoBusiness();
    }
    setReviewPlateFormData(removeBusinessInformation);
  };
  const handleBusinessLocation = async (data) => {
    setNumber(1);
    if (data.name === 'zomato') {
      setIsZomato(true);
    }
    if (data.name === 'google') {
      setIsGoogle(true);
    }
    if (data.name === 'yelp') {
      setIsYelp(true);
      setPageNumber(1);
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.searchYelpBusiness,
        variables: {
          location: countryLocation,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.searchYelpBusiness?.data) {
        setYelpData(result?.searchYelpBusiness?.data);
      } else {
        enqueueSnackbar(result?.searchYelpBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const addBusinessInformation = async (businessData, yelpValues) => {
    try {
      setIsGoogleBusinessAdd(true);
      let url = {};
      if (businessData?.name === 'google') {
        setIsGoogle(false);
        url = {
          url: googleData?.url,
        };
      }
      if (businessData?.name === 'zomato') {
        setIsZomato(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      if (businessData?.name === 'yelp') {
        setIsYelp(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      const businessDetails = await axios({
        url:
          businessData?.name === 'google'
            ? process.env.REACT_APP_AI_GOOGLE_BUSINESS_TYPE
            : process.env.REACT_APP_AI_YELP_ZOMATO_BUSINESS_TYPE,
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(url),
      });
      setNumber(2);
      if (businessDetails?.data?.status === 200) {
        const data = businessDetails.data?.data;
        const findBusinessType = businessType?.filter(
          (item) =>
            item?.label === (businessData?.name === 'google' ? data?.business_type : data?.type)
        );
        const phone = {
          phoneNumber: `${data?.country_code?.slice(1)}${data?.phone_number}`,
          countryCode: data?.country_code,
          isoCode: data?.iso_code,
        };
        setAddBusinessData({
          ...addBusinessData,
          reviewPlatformId: businessData?.id,
          businessType: findBusinessType?.[0]?.value,
          businessName: businessData?.businessName,
          phone: businessData?.name === 'google' ? googleData?.phone : phone,
          googlePhoneNumber: businessData?.name === 'google' ? googleData?.phone : phone,
          websiteUrl: businessData?.url,
          address: businessData?.address,
          country:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato'
              ? data?.country
              : yelpValues?.location?.country,
          city:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.city
              : yelpValues?.location?.city,
          businessImage:
            businessData?.name === 'google'
              ? googleData?.businessImage
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.businessImage
              : yelpValues?.image_url,
        });
        setTimeout(() => {
          setNumber(3);
          setTimeout(() => {
            setIsBusinessFetch(true);
            setIsGoogleBusinessAdd(false);
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setNumber(3);
        setTimeout(() => {
          setIsBusinessFetch(true);
          setIsGoogleBusinessAdd(false);
        }, 1000);
      }, 2000);
      setAddBusinessData({
        ...addBusinessData,
        reviewPlatformId: businessData?.id,
        businessName: businessData?.businessName,
        websiteUrl: businessData?.url,
        address: businessData?.address,
        city:
          businessData?.name === 'google'
            ? googleData?.country
            : businessData?.name === 'zomato'
            ? zomatoBusiness?.city
            : yelpValues?.location?.city,
        businessImage:
          businessData?.name === 'google'
            ? googleData?.businessImage
            : businessData?.name === 'zomato'
            ? zomatoBusiness?.businessImage
            : yelpValues?.image_url,
      });
    }
  };

  const addBusinessDetails = async (value, yelpValues) => {
    let result = [];
    if (value === 'yelp') {
      result = await handleYelpAddBusiness(yelpValues);
    }
    const arrayData = value === 'yelp' ? result : reviewPlateFormData;
    const checkBusinessData = await arrayData.find((ele) => ele.name === value);
    if (checkBusinessData?.isBusinessDetailsAdded === true) {
      addBusinessInformation(checkBusinessData, yelpValues);
    } else {
      if (value === 'google') {
        setIsGoogle(false);
      }
      if (value === 'zomato') {
        setIsZomato(false);
      }
      if (value === 'yelp') {
        setIsYelp(false);
      }
    }
  };

  const handleYelpAddBusiness = async (value) => {
    const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
    const data = await reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          ...ele,
          address: value?.location?.display_address.join(' '),
          businessName: value?.name,
          placeId: value?.id,
          url: value?.url,
          isBusiness: true,
          isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
        };
      }
      return ele;
    });
    setReviewPlateFormData(data);
    return data;
  };

  const handleSelectCountry = (id) => {
    setCountryId(id);
    setPageNumber(1);
    setYelpData([]);
    const findCountry = countryList?.find((country) => country?.id === id);
    setCountryLocation(findCountry?.isoCode);
  };

  const selectState = async (state) => {
    setStateId(parseInt(state, 10) !== 0 ? state : 0);
    const findState = stateData.find((ele) => ele.id === state);
    setStateName(findState?.name || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${findState?.name || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      setYelpData([]);
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleZipCode = async (value) => {
    if (value?.length < 7) {
      setZipCode(value);
      const payload = {
        query: manageBusinessData.searchYelpBusiness,
        variables: {
          location: `${countryLocation}, ${stateName || ''}, ${value}`,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.status === 200) {
        setYelpData(result?.searchYelpBusiness?.data);
      }
    }
  };

  const handleSearchBusinessName = async (value) => {
    setSearchBusinessName(value || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: value,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const cancelYelp = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setIsYelp(false);
    setDataAvailable(false);
    setPageNumber(1);
  };

  const clearSearch = async () => {
    setSearchBusinessName('');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: '',
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const verifyZomatoLink = async () => {
    setRefreshIcon(true);
    setIsValidZomatoUrl(false);
    const payload = {
      query: manageBusinessData.validateZomatoUrl,
      variables: {
        url: zomatoUrl,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.validateZomatoUrl?.status === 200 && result?.validateZomatoUrl?.data) {
      const businessDetails = result?.validateZomatoUrl?.data;
      setZomatoVerified(true);
      setZomatoBusiness({
        businessName: businessDetails?.name,
        url: businessDetails?.business_url,
        address: businessDetails?.address,
        businessImage: businessDetails?.business_image_url,
        city: businessDetails?.city_name,
      });
      const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'zomato') {
          return {
            ...ele,
            address: businessDetails?.address,
            businessName: businessDetails?.name,
            placeId: businessDetails?.res_id.toString(),
            url: businessDetails?.business_url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
    }
    if (result?.validateZomatoUrl?.data === null) {
      setIsValidZomatoUrl(true);
      enqueueSnackbar(result?.validateZomatoUrl?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    const phoneNumber = event;
    const countryCode = `+${data.dialCode}`;
    const isoCode = data.countryCode;
    const checkPhoneNumber = phoneNumber.includes(countryCode?.slice(1))
      ? phoneNumber.slice(countryCode?.slice(1).length)
      : phoneNumber;
    setAddBusinessData({
      ...addBusinessData,
      phone: {
        phoneNumber,
        countryCode,
        isoCode,
      },
    });
  };

  const skipGoogleBusiness = () => {
    setIsGoogle(false);
    setGoogleData();
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'google') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
  };

  const skipZomatoBusiness = () => {
    setIsZomato(false);
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'zomato') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setZomatoUrl();
    setZomatoVerified(false);
    setZomatoBusiness();
    setReviewPlateFormData(removeBusinessInformation);
  };

  const submitBusinessDetails = async () => {
    setRefreshIcon(true);
    const reviewPlateForm = reviewPlateFormData?.filter((item) => item.businessName);
    const phoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };
    const googlePhoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };

    const businessDetail = {
      address: addBusinessData?.address,
      address2: addBusinessData?.address,
      businessTypeId: parseInt(addBusinessData?.businessType, 10),
      businessQRId: '',
      city: addBusinessData?.city || '',
      countryId: parseInt(countryId, 10),
      email: addBusinessData?.email,
      name: addBusinessData?.businessName?.toString(),
      phoneNumber: phoneObj,
      googlePhoneNumber: googlePhoneObj,
      profileURL: addBusinessData?.businessImage || '',
      reviewPlatformId: parseInt(addBusinessData?.reviewPlatformId, 10),
      website: addBusinessData?.websiteUrl,
      reviewPlatform: reviewPlateForm?.map((item) => ({
        placeId: item.placeId,
        reviewPlatformId: parseInt(item.id, 10),
        url: item.url,
      })),
    };

    const payload = {
      query: manageBusinessData.addBusinessDetailV2,
      variables: { businessDetail },
    };
    if (clientId) {
      payload.variables.userId = clientId;
      payload.variables.isClient = true;
    }
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.addBusinessDetailV2?.status === 200) {
      setAddBusinessData('');
      setGoogleData('');
      setZomatoBusiness('');
      if (clientId) {
        history.push('/agency/business');
      }
      if (parseInt(packageTypeId, 10) === 5) {
        const id = getParamsId();
        if (id) {
          history.push(`/business/dashboard/${id}`);
        } else {
          history.push('/business/dashboard');
        }
      } else {
        localStorage.setItem('activeStepNumber', 2);
        if (!clientId) {
          setActiveStepNumber(2);
        }
      }
    } else {
      enqueueSnackbar(result?.addBusinessDetailV2?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleSkipStep = () => {
    setAddBusinessData('');
    setGoogleData('');
    setZomatoBusiness('');
    const id = getParamsId();
    if (id) {
      history.push(`/business/dashboard/${id}`);
    } else {
      history.push('/business/dashboard');
    }
    localStorage.removeItem('activeStepNumber');
  };

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className={`text-left ${parseInt(packageTypeId, 10) === 5 ? 'w-full' : ''}`}>
      <h3
        className={`text-24 font-bold mb-12 ${
          parseInt(packageTypeId, 10) === 5 ? 'text-center' : ''
        }`}
      >
        {t('manageBusiness.addBusinessSteps.businessLocation.pageTitle')}
      </h3>
      <Typography
        className={`block text-16 font-semibold leading-relaxed ${
          parseInt(packageTypeId, 10) === 5 ? 'text-center max-w-640 m-auto' : 'max-w-640'
        }`}
      >
        {t('manageBusiness.addBusinessSteps.businessLocation.pageDesc')}
      </Typography>
      {!isGoogle && !isYelp && !isZomato && !isGoogleBusinessAdd && !isBusinessFetch && (
        <>
          {reviewPlateFormData?.length > 0 &&
            reviewPlateFormData?.map((item, index) => {
              return (
                <div
                  className="border-1 flex justify-between mt-28 items-center border-solid !border-darkgreen rounded-xl mx-auto md:p-20 p-10"
                  key={index}
                >
                  <div className="flex items-center">
                    <img
                      src={item?.logo}
                      alt={item?.name}
                      className="md:max-w-52 max-w-28 md:mr-28 mr-10 rounded-full"
                    />
                    <span className="block md:text-20 text-14 font-bold">
                      {convertUppercase(item?.name)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    {item?.businessName && addBusinessData?.businessType && (
                      <>
                        <img
                          src="/assets/images/business/check_circle.svg"
                          className="img-fluid"
                          alt="..."
                        />
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="flex items-center rounded-full h-40 md:text-16 font-medium disabled:text-black sm:min-w-128 bg-transparent border-darkgreen text-darkgreen md:mx-16 mx-6"
                          onClick={() => handleBusinessLocation(item)}
                        >
                          <span>{t('manageBusiness.button.edit')}</span>
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="flex items-center rounded-full h-40 w-40 disabled:text-black min-w-0 bg-transparent border-red text-darkgreen"
                          onClick={() => handleRemoveBusiness(item)}
                        >
                          <DeleteIcon className="text-24 text-red" />
                        </Button>
                      </>
                    )}
                    {(!item?.businessName || !addBusinessData?.businessType) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                        onClick={() => handleBusinessLocation(item)}
                      >
                        <span>{t('manageBusiness.button.addLocation')}</span>
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
        </>
      )}
      {isGoogle && (
        <div className="mt-28">
          <div className={`${googleData?.businessName ? 'md:flex' : ''} gap-20`}>
            <div className={`${googleData?.businessName ? 'md:w-3/4' : 'w-full'}`}>
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-lg"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder={t(
                          'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                        )}
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `300px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `5px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
            </div>
            {googleData?.businessName && (
              <div className="border-1 sm:w-320 h-full mt-20 md:mt-0 m-auto md:w-288 rounded-md p-8 border-solid !border-darkgreen">
                <img className="rounded-md h-160 w-full" src={googleData?.businessImage} alt="" />
                <Typography className="font-medium text-16 pt-8">
                  {googleData?.businessName}
                </Typography>
                <Typography className="text-12 py-8">{googleData?.address}</Typography>
                <div className="flex items-center">
                  <Typography className="font-medium text-14">
                    {googleData?.rating > 0 && (
                      <Icon className="text-16 text-yellow-800 align-middle mr-2 -mt-2">star</Icon>
                    )}{' '}
                    {googleData?.rating}{' '}
                    <span className="font-normal pl-4 text-14">
                      ({googleData?.userTotalRating} reviews)
                    </span>
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => skipGoogleBusiness()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
              aria-label="confirm"
              onClick={() => addBusinessDetails('google')}
              type="button"
            >
              {t('manageBusiness.button.next')}
              {dataLoading && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        </div>
      )}
      {isGoogleBusinessAdd && (
        <div className="bg-gray-50 mt-28 flex items-center h-400 justify-center">
          <div className="">
            <div className="flex w-256 m-auto relative">
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number !== 1 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div className="absolute left-0 text-center w-20 h-20 border border-darkgreen rounded-xl bg-darkgreen text-white">
                  1
                </div>
              </div>
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number === 3 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div
                  className={`absolute left-0 text-center w-20 h-20 ${
                    number !== 1 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  2
                </div>
              </div>
              <div className={`pl-20 pt-12 relative ${number === 3 ? 'border-darkgreen' : ''}`}>
                <div
                  className={`absolute left-0 top-0 text-center w-20 h-20 ${
                    number === 3 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  3
                </div>
              </div>
            </div>
            <Typography className="text-darkgreen text-center py-28 font-bold text-16">
              {t('manageBusiness.addBusinessSteps.businessLocation.fetchingData')}
            </Typography>
            <Typography className="text-black font-medium text-center pb-8 text-16">
              {t('manageBusiness.addBusinessSteps.businessLocation.processYourRequest')}
            </Typography>
            <img className="w-480 m-auto" src="/assets/video/Transferfilegif.gif" alt="" />
          </div>
        </div>
      )}
      {isBusinessFetch &&
        reviewPlateFormData?.length > 0 &&
        reviewPlateFormData?.map((data, index) => {
          return (
            <div key={index}>
              {data?.isBusinessDetailsAdded && (
                <div className="mt-48">
                  <div className="bg-gray-50 p-24">
                    <Typography className="text-20 font-bold">
                      {t('manageBusiness.addBusinessSteps.businessLocation.businessDetails')}
                    </Typography>
                    <hr className="border-1 my-20 !border-gray border-solid" />
                    <div className="flex gap-28">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.businessName')}
                          <span className="text-red">*</span>
                        </Typography>
                        <TextField
                          className="w-full"
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.businessName.placeHolder'
                          )}
                          value={addBusinessData?.businessName}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              businessName: e.target.value,
                            });
                          }}
                          size="small"
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.websiteUrl')}
                        </Typography>
                        <TextField
                          className="w-full"
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.website.placeHolder'
                          )}
                          value={addBusinessData?.websiteUrl}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              websiteUrl: e.target.value,
                            });
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="flex gap-28 my-8">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.businessType')}
                          <span className="text-red">*</span>
                        </Typography>
                        <ReactSelect
                          labelId="businessType"
                          placeholder={t('business.selectBusinessType')}
                          id="businessType"
                          name="businessType"
                          onChange={handleSelectBusinessType}
                          onInputChange={handleInputChange}
                          value={businessType?.find((op) => {
                            return (
                              parseInt(op.value, 10) === parseInt(addBusinessData.businessType, 10)
                            );
                          })}
                          options={filteredOptions}
                          components={{
                            MenuList: CustomMenuList,
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.phone')}
                          <span className="text-red">*</span>
                        </Typography>
                        <PhoneInput
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                          )}
                          inputStyle={{
                            width: '100%',
                            height: '40px',
                          }}
                          className="w-full"
                          name="phone_number"
                          required
                          size="small"
                          value={addBusinessData.phone?.phoneNumber?.toString()}
                          country={
                            addBusinessData?.phone?.isoCode ||
                            selectedCountry?.isoCode.toLowerCase()
                          }
                          enableSearch="true"
                          enableLongNumbers={17}
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </div>
                    <div className="flex gap-28">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.businessEmail')}
                        </Typography>
                        <TextField
                          className="w-full"
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.placeHolder'
                          )}
                          size="small"
                          value={addBusinessData.email}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 disabled:text-black disabled:font-semibold text-black mb-8">
                          {t('manageBusiness.addBusinessSteps.businessLocation.country')}
                        </Typography>
                        <Select
                          className="w-full"
                          id="country"
                          value={parseInt(countryId, 10)}
                          size="small"
                          displayEmpty
                          onChange={(e) => {
                            setCountryId(e.target.value);
                          }}
                        >
                          <MenuItem value={0} disabled>
                            {t('qrcodeOrder.form.country.placeHolder')}
                          </MenuItem>
                          {countryList?.map((option, i) => (
                            <MenuItem key={i} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-28">
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="md:text-16 font-medium hover:bg-darkgreen disabled:bg-gray disabled:text-black bg-darkgreen text-white rounded-md mr-24 sm:min-w-160"
                      aria-label="skip"
                      disabled={
                        !addBusinessData?.businessName ||
                        !addBusinessData?.businessType ||
                        !addBusinessData?.phone?.phoneNumber ||
                        !addBusinessData?.websiteUrl
                      }
                      onClick={() => {
                        setIsBusinessFetch(false);
                      }}
                      type="button"
                    >
                      {t('manageBusiness.button.addLocation')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {isYelp && (
        <div>
          <div className="p-24 mt-28 rounded-lg bg-gray-200">
            <div className="h-480 overflow-scroll" onScroll={handleScroll}>
              <div className="flex gap-16 w-full">
                <Paper
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-full sm:w-1/2 px-8 border border-solid border-gray-500 rounded-8 shadow-none"
                >
                  <Input
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                    )}
                    className="flex flex-1 mx-8 text-black text-16"
                    disableUnderline
                    name="searchText"
                    fullWidth
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={(e) => handleSearchBusinessName(e.target.value)}
                    value={searchBusinessName}
                  />
                  {searchBusinessName ? (
                    <Icon
                      color="action"
                      className="cursor-pointer"
                      onClick={() => {
                        clearSearch();
                      }}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="action">search</Icon>
                  )}
                </Paper>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(countryId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={countryId}
                  onChange={(e) => handleSelectCountry(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0">
                    Select country
                  </MenuItem>
                  {countryList?.length > 0 &&
                    countryList?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(stateId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={stateId}
                  onChange={(e) => selectState(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0">
                    Select State
                  </MenuItem>
                  {stateData?.length > 0 &&
                    stateData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              <div className="mt-12">
                <TextField
                  placeholder="Zip code"
                  value={zipCode}
                  type="number"
                  onChange={(e) => handleZipCode(e.target.value)}
                  className="w-full bg-white"
                  size="small"
                />
              </div>
              {yelpData?.length > 0 ? (
                yelpData?.map((item) => {
                  return (
                    <div className="sm:flex justify-between items-center p-16 bg-white rounded mt-16">
                      <div className="flex items-center gap-24">
                        <div>
                          <img
                            className="w-120 rounded rounded-md h-80"
                            src={item.image_url}
                            alt=""
                          />
                        </div>
                        <div className="text-start">
                          <Typography className="font-bold">{item.name}</Typography>
                          <Typography className="text-black pt-4 text-14">
                            <LocationOnOutlinedIcon className="text-gray-500 text-20" />{' '}
                            {item.location?.display_address.join(' ')}
                          </Typography>
                        </div>
                      </div>
                      <div className="text-right mt-24 sm:mt-0">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                          onClick={() => addBusinessDetails('yelp', item)}
                        >
                          <span>{t('manageBusiness.button.addBusiness')}</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Typography className="font-semibold pt-80 text-16 flex items-center justify-center">
                  {t('manageBusiness.addBusinessSteps.businessLocation.noDataAvailable')}
                </Typography>
              )}
            </div>
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => cancelYelp()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
          </div>
        </div>
      )}
      {!isGoogle && !isYelp && !isZomato && !isGoogleBusinessAdd && !isBusinessFetch && (
        <div className="text-right mt-48">
          {!clientId && (
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => handleSkipStep()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
            aria-label="confirm"
            disabled={!addBusinessData?.businessName}
            onClick={() => submitBusinessDetails()}
            type="button"
          >
            {t('manageBusiness.addBusinessSteps.customAttribute.saveAndNext')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      )}
      {isZomato && (
        <div className="p-24 mt-28 rounded-lg bg-gray-200">
          <div className="text-start">
            <Typography className="text-black text-16 font-medium mb-8">
              {t('manageBusiness.addBusinessSteps.businessLocation.enterZomatoUrl')}{' '}
              <a
                href="https://www.zomato.com/"
                target="_blank"
                className="!bg-transparent !text-darkgreen underline text-12"
                rel="noreferrer"
              >
                (Go to Zomato)
              </a>
            </Typography>
            <TextField
              className="w-full text-black text-16 font-medium bg-white"
              value={zomatoUrl}
              onChange={(e) => {
                setZomatoUrl(e.target.value);
                setZomatoVerified(false);
              }}
              placeholder="https://"
              size="small"
            />
            {zomatoVerified && zomatoUrl ? (
              <>
                <Typography className="text-black text-16 font-medium my-8">
                  {t('manageBusiness.addBusinessSteps.businessLocation.businessName')}
                </Typography>
                <Typography className="text-black border text-black text-14 bg-white border-gray rounded border solid py-10 pl-12 my-8">
                  {zomatoBusiness?.businessName}
                </Typography>
              </>
            ) : (
              <>
                <Button
                  className="border border-solid rounded-4xl mt-20 bg-darkgreen hover:bg-darkgreen disabled:border-none disabled:text-black disabled:bg-gray text-white font-medium px-40"
                  onClick={() => verifyZomatoLink()}
                  disabled={refreshIcon || !zomatoUrl}
                >
                  {t('manageBusiness.button.verifiedZomatoLink')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <img
                  src="/assets/images/business/zomatoGuide.png"
                  className={`img-fluid w-full mt-32 ${
                    parseInt(packageTypeId, 10) === 5 ? 'max-w-256' : 'max-w-360'
                  }`}
                  alt="..."
                />
              </>
            )}
            {isValidZomatoUrl && (
              <Typography className="text-red mt-16 font-medium text-14">
                <WarningAmberIcon className="text-red" />
                {t('manageBusiness.addBusinessSteps.businessLocation.zomatoAlertMessage')}
              </Typography>
            )}
            <div className="text-right mt-60">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                onClick={() => skipZomatoBusiness()}
                type="button"
              >
                {t('manageBusiness.button.skip')}
              </Button>
              {zomatoVerified && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 px-28 font-medium rounded-md inline-block align-middle"
                  onClick={() => addBusinessDetails('zomato')}
                >
                  <span>{t('manageBusiness.button.addReviewSite')}</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(BusinessLocation);

import ConvertedBusiness from './ConvertedBusiness';
import ConvertedBusinessDetails from './ConvertedBusinessDetails';

const ConvertedBusinessConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'converted-business',
      element: <ConvertedBusiness />,
    },
    {
      path: 'converted-business-details/:businessId',
      element: <ConvertedBusinessDetails />,
    },
  ],
};

export default ConvertedBusinessConfig;

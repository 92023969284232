import SelectedBusiness from './SelectedBusiness';
import SelectedBusinessDetails from './SelectedBusinessDetails';

const SelectedBusinessConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'selected-business',
      element: <SelectedBusiness />,
    },
    {
      path: 'business-details/:businessId',
      element: <SelectedBusinessDetails />,
    },
  ],
};

export default SelectedBusinessConfig;

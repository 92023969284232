import {
  Button,
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import history from '@history';
import cmsData from '../../query/cms';
import {
  addAppBusinessMenuAndServicesData,
  getAppBusinessCategories,
  getBusinessMenuAndServicesData,
  resetCmsApp,
  updateBusinessMenuAndServicesData,
} from './store/cmsAppSlice';
import UserService from '../../../services/userService';
import reducer from './store';

const currencyCodes = [
  'USD',
  'EUR',
  'GBP',
  'JPY',
  'CAD',
  'AUD',
  'CHF',
  'CNY',
  'INR',
  'KRW',
  'BRL',
  'ZAR',
  'MXN',
  'SGD',
  'HKD',
  'NZD',
  'SEK',
  'NOK',
  'DKK',
  'RUB',
];

function Menu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const defaultValues = {};

  const schema = yup.object().shape({
    formData: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(t('appWebsite.dishmenu.formField.name.validationMessages.required')),
        currencyType: yup
          .string()
          .required(t('appWebsite.dishmenu.formField.currencyType.validationMessages.required')),
        price: yup
          .string()
          .required(t('appWebsite.dishmenu.formField.price.validationMessages.required')),
        userBusinessCategoriesId: yup
          .string()
          .required(t('appWebsite.dishmenu.formField.selectcategory.validationMessages.required')),
      })
    ),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const { isValid, dirtyFields, errors } = formState;

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imagePreviewName, setImagePreviewName] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [noImgMsg, setNoImgMsg] = useState('');
  const [editMenuId, setEditMenuId] = useState(null);
  const [actionValue, setActionValue] = useState('action');
  const [images, setImages] = useState([]);
  const loginUserData = UserService.getUserData();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const getAppBusinessCategoriesData = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getAppBusinessCategoriesData?.data?.getAppBusinessCategories?.data ||
      []
  );

  const getBusinessMenuAndServices = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getBusinessMenuAndServicesDataValue?.data?.getBusinessMenuAndServices
        ?.data || []
  );

  const updateAppBusinessCategories = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.updateBusinessMenuAndServicesDataValue?.data
        ?.updateBusinessMenuAndServices || ''
  );

  useEffect(() => {
    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getAppBusinessCategories,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getAppBusinessCategories(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
        },
      };
      dispatch(getBusinessMenuAndServicesData(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (updateAppBusinessCategories?.status) {
      enqueueSnackbar(updateAppBusinessCategories?.message, {
        variant: updateAppBusinessCategories?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateAppBusinessCategories, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (getBusinessMenuAndServices || getBusinessMenuAndServices === null) {
        setLoading(false);
        if (getBusinessMenuAndServices?.length > 0) {
          setIsEdit(false);
        } else {
          setIsEdit(true);
        }
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [getBusinessMenuAndServices, isValidDomainRegisterId]);

  const handleEditMenu = async (item) => {
    setEditMenuId(parseInt(item.id, 10));
    // setEditImagePreview(item?.blogImage);
    setIsEdit(true);
    const fieldsCopy = [...fields];
    fieldsCopy.forEach((field, index) => {
      remove(index);
    });
    append(item);
  };

  function resetForm() {
    setEditMenuId(null);
    reset({
      formData: [
        {
          name: '',
          description: '',
          userBusinessCategoriesId: null,
          percentage: 0,
          price: null,
          specialPrice: 0,
          keyFeatures: '',
          currencyType: '',
        },
      ],
    });
    setImages([]);
    setImagePreviewName([]);
  }

  useEffect(() => {
    if (getAppBusinessCategoriesData?.length === 0) {
      append({
        name: '',
        description: '',
        userBusinessCategoriesId: null,
        percentage: 0,
        price: null,
        specialPrice: 0,
        keyFeatures: '',
        currencyType: '',
      });
    }
  }, [getAppBusinessCategoriesData?.length, append]);

  function handleDrop(event, index) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    imagePreviewName[index] = event?.dataTransfer?.files[0]?.name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);
    setNoImgMsg('');
  }

  function handleFileChange(event, index) {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }

    imagePreviewName[index] = event?.target?.files[0]?.name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };

    setImages(images);
    setNoImgMsg('');
  }
  if (loading) {
    return <FuseLoading />;
  }
  const onSubmit = async (values) => {
    setRefreshIcon(true);
    try {
      if (editMenuId) {
        const payload = {
          query: cmsData.updateBusinessMenuAndServices,
          variables: {
            businessMenuAndServicesId: editMenuId,
            name: values?.formData[0]?.name,
            description: values?.formData[0]?.description,
            currencyType: values.formData?.[0].currencyType,
            percentage: parseFloat(values.formData?.[0].percentage),
            userBusinessCategoriesId: parseInt(values.formData?.[0].userBusinessCategoriesId, 10),
            keyFeatures: values.formData?.[0].keyFeatures,
            specialPrice: parseInt(values.formData?.[0].specialPrice, 10),
            image: images[0],
            price: parseInt(values.formData?.[0].price, 10),
          },
        };
        await dispatch(updateBusinessMenuAndServicesData(payload));
        setImages([]);
        setImagePreviewName([]);
        setIsEdit(false);
      } else {
        if (images.length < values.formData.length) {
          enqueueSnackbar(t('appWebsite.dishmenu.menuimagerequired'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setRefreshIcon(false);
          return;
        }
        const payloadValue = {
          query: cmsData.addAppBusinessMenuAndServices,
          variables: {
            domainRegisterId: userDomainRegisterId,
            name: values.formData?.[0].name,
            description: values.formData?.[0].description,
            currencyType: values.formData?.[0].currencyType,
            percentage: parseFloat(values.formData?.[0].percentage),
            userBusinessCategoriesId: parseInt(values.formData?.[0].userBusinessCategoriesId, 10),
            keyFeatures: values.formData?.[0].keyFeatures,
            specialPrice: parseInt(values.formData?.[0].specialPrice, 10),
            image: images[0],
            price: parseInt(values.formData?.[0].price, 10),
          },
        };
        await dispatch(addAppBusinessMenuAndServicesData(payloadValue));
        setIsEdit(false);
      }
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
        },
      };
      await dispatch(getBusinessMenuAndServicesData(payload));
      setRefreshIcon(false);
      resetForm();
    } catch (error) {
      console.error('Error occurred:');
    }
  };

  const handleToggle = async (value, categoryId) => {
    try {
      const payloadValue = {
        query: cmsData.updateBusinessMenuAndServices,
        variables: {
          businessMenuAndServicesId: categoryId,
          isActive: !value,
        },
      };
      await dispatch(updateBusinessMenuAndServicesData(payloadValue));
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
        },
      };
      await dispatch(getBusinessMenuAndServicesData(payload));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center mb-36">
            <div className="flex justify-between">
              <div>
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="text-16 md:text-24 font-bold mb-20"
                >
                  {t('appWebsite.dishmenu.title')}
                </Typography>
              </div>
              {!getBusinessMenuAndServices?.length > 0 || isEdit ? (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-15 font-semibold"
                    variant="outlined"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => {
                      setIsEdit(false);
                      resetForm();
                    }}
                  >
                    {t('business.selectedBusinessDetail.buttons.cancel')}
                  </Button>
                </div>
              ) : (
                <div className="items-center">
                  <div className="inline-block flex w-auto float-right">
                    <Button
                      className="text-16 px-16 rounded-md"
                      variant="contained"
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => {
                        setIsEdit(true);
                        resetForm();
                      }}
                    >
                      {t('appWebsite.dishmenu.addnewdish')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            {isEdit ? (
              <form
                name="registerForm"
                noValidate
                className="w-full"
                // defaultValue={{ forms: formsData }}
                onSubmit={handleSubmit(onSubmit)}
                ref={formRef}
              >
                <div>
                  <div>
                    {fields.map((fieldInput, index) => {
                      return (
                        <div key={index}>
                          <div className="bg-white rounded-md relative shadow p-24 mb-40">
                            <Typography
                              component={motion.span}
                              initial={{ x: -20 }}
                              animate={{ x: 0, transition: { delay: 0.2 } }}
                              delay={300}
                              className="text-16 md:text-24 font-medium"
                            >
                              {t('appWebsite.dishmenu.menutitle')}
                            </Typography>
                            <hr className="border-dashed border-t-2 border-grey-300 mt-8" />
                            <div className="flex mt-16">
                              <div className="w-full">
                                <div className="block">
                                  <InputLabel
                                    htmlFor="name"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.dishmenu.formField.name.name')}*
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].name`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="text"
                                        size="small"
                                        value={undefined}
                                        defaultValue={fieldInput.name}
                                        error={!!errors?.formData?.[index]?.name}
                                        helperText={errors?.formData?.[index]?.name?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <div className="block">
                                  <InputLabel
                                    htmlFor="description"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.home.formField.description.name')}*
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].description`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="text"
                                        size="small"
                                        value={undefined}
                                        multiline
                                        rows={4}
                                        defaultValue={fieldInput.description}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <motion.div className="block mb-24">
                                  <InputLabel
                                    htmlFor={`formData[${index}].uploadImage`}
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.home.formField.uploadImage.name')}
                                  </InputLabel>
                                  <Paper
                                    className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-grey-300 p-12 bg-white"
                                    role="button"
                                    onDrop={(event) => {
                                      handleDrop(event, index);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                  >
                                    <div className="inline-block">
                                      <Controller
                                        name={`formData[${index}].uploadImage`}
                                        control={control}
                                        error={!!errors?.formData?.[index]?.uploadImage}
                                        helperText={errors?.formData?.[index]?.uploadImage?.message}
                                        render={({ field }) => (
                                          <label htmlFor={`formData[${index}].uploadImage`}>
                                            <input
                                              {...field}
                                              accept="image/*"
                                              className="hidden"
                                              name={`formData[${index}].uploadImage`}
                                              id={`formData[${index}].uploadImage`}
                                              type="file"
                                              onChange={(event) => {
                                                handleFileChange(event, index);
                                              }}
                                            />
                                            <Button
                                              size="small"
                                              className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                                              variant="contained"
                                              color="secondary"
                                              component="span"
                                            >
                                              <Icon color="action material-symbols-outlined align-middle">
                                                upload
                                              </Icon>{' '}
                                              {t('reviewPlatform.buttons.uploadReviewIcon')}
                                            </Button>
                                          </label>
                                        )}
                                      />
                                    </div>
                                    <div className="inline-block ml-16 font-medium">
                                      {imagePreviewName[index] ||
                                        `${t('appWebsite.about.formField.OrDropImage')}`}
                                    </div>
                                    {noImgMsg && (
                                      <span className="text-red block mt-16">{noImgMsg}</span>
                                    )}
                                  </Paper>
                                </motion.div>
                                <div className="mt-24">
                                  <InputLabel
                                    htmlFor="formData.uploadImage"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.dishmenu.selectcategory')}
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].userBusinessCategoriesId`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        displayEmpty
                                        className="w-full h-8"
                                        placeholder="Select Category"
                                        error={
                                          !!errors?.formData?.[index]?.userBusinessCategoriesId
                                        }
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                      >
                                        {getAppBusinessCategoriesData.length > 0 &&
                                          getAppBusinessCategoriesData?.map((items, i) => {
                                            return (
                                              <MenuItem key={i} value={items.id}>
                                                {items.name}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    )}
                                  />
                                  {errors?.formData?.[index]?.userBusinessCategoriesId && (
                                    <span className="text-red-500 ml-12">
                                      {errors?.formData?.[index]?.userBusinessCategoriesId?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-white rounded-md relative shadow p-24 mb-40">
                            <Typography
                              component={motion.span}
                              initial={{ x: -20 }}
                              animate={{ x: 0, transition: { delay: 0.2 } }}
                              delay={300}
                              className="text-16 md:text-24 font-medium"
                            >
                              {t('appWebsite.dishmenu.menutitle')}
                            </Typography>
                            <hr className="border-dashed border-t-2 border-darkgreen mt-8" />
                            <div className="flex w-[50%] gap-20 mt-16">
                              <div className="w-full">
                                <div className="mt-8">
                                  <InputLabel className="text-16 font-medium mb-12 text-grey-900">
                                    {t('appWebsite.dishmenu.selectcurrency')}*
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].currencyType`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        displayEmpty
                                        className="w-full h-8"
                                        placeholder="Select Category"
                                        error={!!errors?.formData?.[index]?.currencyType}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setSelectedCurrency(e.target.value);
                                        }}
                                      >
                                        {currencyCodes?.map((item, i) => {
                                          return (
                                            <MenuItem key={i} value={item}>
                                              {item}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    )}
                                  />
                                  {errors?.formData?.[index]?.currencyType && (
                                    <span className="text-red-500 ml-12">
                                      {errors?.formData?.[index]?.currencyType?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="mt-8">
                                  <InputLabel className="text-16 font-medium mb-12 text-grey-900">
                                    {t('appWebsite.dishmenu.price')}*
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].price`}
                                    control={control}
                                    render={({ field }) => (
                                      <div className="flex">
                                        {(selectedCurrency || fieldInput.currencyType) && (
                                          <Typography className="border-1 bg-[#F2F2F2] h-[4rem] px-12 pt-8 border-gray-500 rounded-4">
                                            {selectedCurrency || fieldInput.currencyType}
                                          </Typography>
                                        )}
                                        <TextField
                                          {...field}
                                          className="mb-24"
                                          sx={{
                                            '& .MuiInputBase-input': {
                                              background: 'white',
                                            },
                                          }}
                                          type="number"
                                          size="small"
                                          value={undefined}
                                          defaultValue={fieldInput.price ? fieldInput.price : ''}
                                          error={!!errors?.formData?.[index]?.price}
                                          helperText={errors?.formData?.[index]?.price?.message}
                                          required
                                          fullWidth
                                        />
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex w-[50%] gap-20 mt-16">
                              <div className="w-full">
                                <div className="mt-8">
                                  <InputLabel
                                    htmlFor="name"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.dishmenu.discount')}
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].percentage`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="number"
                                        size="small"
                                        value={undefined}
                                        defaultValue={
                                          fieldInput.percentage ? fieldInput.percentage : ''
                                        }
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="mt-8">
                                  <InputLabel
                                    htmlFor="name"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.dishmenu.saleprice')}
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].specialPrice`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="number"
                                        size="small"
                                        value={undefined}
                                        defaultValue={
                                          fieldInput.specialPrice ? fieldInput.specialPrice : ''
                                        }
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {fields.length > 0 && (
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Register"
                      disabled={
                        editMenuId !== ''
                          ? refreshIcon
                          : _.isEmpty(dirtyFields) || !isValid || refreshIcon
                      }
                      type="submit"
                      size="small"
                    >
                      {t('appWebsite.home.formField.button')}{' '}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                )}
              </form>
            ) : (
              <div className="bg-white rounded-md relative shadow p-24">
                <TableContainer className="border-b-1 border-solid border-grey-200">
                  <Table aria-labelledby="tableTitle" size="medium">
                    <TableHead>
                      <TableRow className="bg-grey-200 rounded-tr-md">
                        <TableCell className="text-16 font-medium py-10">
                          {t('appWebsite.dishmenu.column.image')}
                        </TableCell>
                        <TableCell className="text-16 font-medium py-10">
                          {t('appWebsite.dishmenu.column.name')}
                        </TableCell>
                        <TableCell className="text-16 font-medium py-10">
                          {t('appWebsite.dishmenu.column.category')}
                        </TableCell>
                        <TableCell className="text-16 font-medium py-10">
                          {t('appWebsite.dishmenu.column.date')}
                        </TableCell>
                        <TableCell className="text-16 font-medium py-10">
                          {t('appWebsite.dishmenu.column.active')}
                        </TableCell>
                        <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                          {t('appWebsite.dishmenu.column.action')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getBusinessMenuAndServices?.length > 0 &&
                        getBusinessMenuAndServices?.map((menuData, i) => (
                          <TableRow hover className="border border-t-2 border-t-grey-100" key={i}>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <img
                                src={menuData?.image}
                                className="img-fluid max-w-80 rounded-md"
                                alt="trial_accounts"
                              />
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {menuData?.name}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {menuData.user_business_categories?.name}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {moment(menuData?.cts).format('MMM DD, YYYY')}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <Switch
                                color="secondary"
                                onChange={() => handleToggle(menuData?.isActive, menuData?.id)}
                                checked={menuData?.isActive}
                              />
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <Select
                                className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                variant="filled"
                                value={actionValue}
                                fullWidth
                              >
                                <MenuItem
                                  value="action"
                                  className="capitalize mx-5 rounded-md"
                                  disabled
                                >
                                  {t('menuItems.action')}
                                </MenuItem>
                                <MenuItem
                                  value="view"
                                  className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                  onClick={() => {
                                    handleEditMenu(menuData);
                                    setIsEdit(true);
                                  }}
                                >
                                  {t('menuItems.edit')}
                                </MenuItem>
                              </Select>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsReducer', reducer)(Menu);

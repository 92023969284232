import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoData from './noDataScreen';
import { selectDashboardData } from '../../../store/userSlice';
import metricsData from '../../query/metrics';
import { handleApiRequest } from '../../common/common';

const AttributesSentiment = (props) => {
  const { reviewPlatformId } = props;

  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id && reviewPlatformId) {
          const payload = {
            query: metricsData.getAttributesSentimentChartDataV1,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewsSentimentPeriodsId: 6,
              reviewPlatformId: parseInt(reviewPlatformId, 10),
            },
          };

          const result = await handleApiRequest(payload);
          if (result?.getAttributesSentimentChartDataV1?.status === 200) {
            const arrayData =
              result.getAttributesSentimentChartDataV1?.data?.attributesSentimentChartDataV1;
            const data = arrayData && JSON.parse(arrayData);
            const keyValueData = data?.map((item) => item[0]);
            const valueData = keyValueData?.map((item) => item?.value);
            const colors = ['#ff00ff', '#ffb300', '#00d2ff', '#0057e7', '#a200ff'];
            const attributeNames = [
              ...new Set(valueData?.flatMap((item) => item.map((sentiment) => sentiment.key))),
            ];
            const attributeColors = {};
            attributeNames?.forEach((attribute, index) => {
              attributeColors[attribute] = colors[index % colors.length];
            });

            const attributes = Object.keys(attributeColors);
            const transformedData = attributes.map((attribute) => {
              return {
                key: attribute,
                data: valueData?.map((period) => {
                  const attributeSentiment = period?.find((item) => item.key === attribute);
                  return attributeSentiment ? attributeSentiment.value : 0;
                }),
                color: attributeColors[attribute],
              };
            });
            const timePeriodData = data?.map((item) => item[1]);
            const timeValue = timePeriodData?.map((item) =>
              moment.unix(item.value).format('MMM-YY')
            );
            setNewGraphData(transformedData);
            setXAxisData(timeValue);
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId]);

  function getChartSimple(data, categories, index, colors, types) {
    const chart = {
      series: data.map((item) => ({
        name: item.key,
        data: item.data,
      })),
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          height: 500,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 7,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        legend: {
          markers: {
            radius: 12,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories,
          show: true,
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            offsetY: 0,
          },
        },
        yaxis: {
          min: -1,
          max: 1,
          show: true,
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            align: 'right',
            maxWidth: 350,
            position: 'right',
            offsetX: -10,
            style: {
              fontSize: '14px',
            },
          },
        },
        colors,
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return `${val}`;
            },
            title: {
              formatter() {
                return '';
              },
            },
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="650px"
          width="100%"
        />
      </div>
    );
  }

  return (
    <div>
      {newGraphData && newGraphData.length > 0 ? (
        getChartSimple(
          newGraphData,
          xAxisData,
          3,
          newGraphData.map((item) => item.color),
          'line'
        )
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default AttributesSentiment;

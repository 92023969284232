import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: null,
  reducers: {
    resetDashboard: (state, action) => {},
  },
  extraReducers: {},
});

export const { resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;

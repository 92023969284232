import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import quickPostQuery from '../../query/quickPost';
import { selectDashboardData } from '../../../store/userSlice';
import { handleApiRequest } from '../../common/common';

const PostSettingsDialog = ({ open, onClose, setOpenContact }) => {
  const [selectedReviews, setSelectedReviews] = useState({
    all: false,
    fiveStar: false,
    fourStar: false,
  });

  const [postFrequencyCount, setPostFrequencyCount] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [checkBoxSelectedId, setCheckBoxSelectedId] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedReviews((prevState) => {
      let newState = { ...prevState, [name]: checked };
      if (name === 'all') {
        newState = {
          all: true,
          fiveStar: true,
          fourStar: true,
        };
      } else if (name === 'fourStar' && !checked) {
        newState = {
          all: false,
          fiveStar: false,
          fourStar: true,
        };
      } else if (name === 'fiveStar' && !checked) {
        newState = {
          all: false,
          fiveStar: true,
          fourStar: false,
        };
      } else {
        const otherCheckbox = name === 'fiveStar' ? 'fourStar' : 'fiveStar';
        const shouldPreventDeselect = !checked && !newState[otherCheckbox];
        if (shouldPreventDeselect) {
          newState = prevState;
        } else {
          const allChecked = newState.fiveStar && newState.fourStar;
          newState = {
            ...newState,
            all: allChecked,
          };
        }
      }
      if (newState.fourStar && !newState.all) {
        setCheckBoxSelectedId(3);
      } else if (newState.fiveStar && !newState.all) {
        setCheckBoxSelectedId(2);
      } else if (newState.all) {
        setCheckBoxSelectedId(1);
      }
      return newState;
    });
  };

  const handlePostCountChange = (event) => {
    setPostFrequencyCount(event.target.value);
  };

  const handleSaveSettings = async () => {
    try {
      const payloadData = {
        query: quickPostQuery.saveUserPostSuggestionSetting,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          duration: 'Daily',
          maximumPostCount: postFrequencyCount,
          postSuggestionsSettingId: checkBoxSelectedId,
        },
      };
      await handleApiRequest(payloadData);
      setOpenContact(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const postSuggestion = async () => {
      try {
        const payloadData = {
          query: quickPostQuery.getPostSuggestionsSetting,
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getPostSuggestionsSetting?.status === 200) {
          const sortedData = resultData?.getPostSuggestionsSetting?.data.sort((a, b) => {
            const order = ['All', '5-Star Reviews', '4-Star Reviews'];
            return order.indexOf(a.text) - order.indexOf(b.text);
          });
          setCheckBoxData(sortedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    postSuggestion();
  }, []);

  useEffect(() => {
    if (selectedReviews.fiveStar && selectedReviews.fourStar) {
      setSelectedReviews((prevState) => ({
        ...prevState,
        all: true,
      }));
    }
  }, [selectedReviews.fiveStar, selectedReviews.fourStar]);

  useEffect(() => {
    const userPostSuggestionSetting = async () => {
      try {
        const payloadData = {
          query: quickPostQuery.getUserPostSuggestionSetting,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setPostFrequencyCount(resultData?.getUserPostSuggestionSetting?.data?.maximumPostCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const userPostSuggestionSettingDefault = async () => {
      try {
        const payloadData = {
          query: quickPostQuery.getPostSuggestionSettingDefault,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getPostSuggestionSettingDefault?.data?.text === '5-Star Reviews') {
          setSelectedReviews((prevState) => ({
            ...prevState,
            fiveStar: true,
          }));
          setCheckBoxSelectedId(2);
        }
        if (resultData?.getPostSuggestionSettingDefault?.data?.text === '4-Star Reviews') {
          setSelectedReviews((prevState) => ({
            ...prevState,
            fourStar: true,
          }));
          setCheckBoxSelectedId(3);
        }
        if (resultData?.getPostSuggestionSettingDefault?.data?.text === 'All') {
          setSelectedReviews((prevState) => ({
            ...prevState,
            all: true,
            fourStar: true,
            fiveStar: true,
          }));
          setCheckBoxSelectedId(1);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    userPostSuggestionSetting();
    userPostSuggestionSettingDefault();
  }, [userCurrentBusinessDetails?.id]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="relative border-t-8 border-darkgreen ">
        {t('createPost.postSettingsTitle')}
        <IconButton
          aria-label="close"
          onClick={handleSaveSettings}
          className="absolute right-2 top-2 text-gray-500"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className=" border-t-2 border-dashed border-gray">
          <Typography className="text-black font-bold  mt-20 " style={{ whiteSpace: 'pre-line' }}>
            {t('createPost.selectReviews')}
          </Typography>
          <div className="flex flex-col  space-y-1">
            {checkBoxData?.map((item) => {
              let name;
              if (item.text.toLowerCase().includes('5-star')) {
                name = 'fiveStar';
              } else if (item.text.toLowerCase().includes('4-star')) {
                name = 'fourStar';
              } else {
                name = item.text.toLowerCase().replace(/ /g, '');
              }
              return (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={selectedReviews[name] || false}
                      onChange={(event) => handleCheckboxChange(event)}
                      name={name}
                      value={item.id}
                    />
                  }
                  label={item.text}
                />
              );
            })}
          </div>

          <Typography className="text-black font-bold mt-16">
            {' '}
            {t('createPost.frequency')}
          </Typography>

          <FormControl fullWidth className="border-t-2 border-dashed border-gray mt-14">
            <InputLabel className="text-2xl mt-20 -ml-14">{t('createPost.postCount')}</InputLabel>
            {postFrequencyCount && (
              <Select value={postFrequencyCount} onChange={handlePostCountChange} className="mt-44">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PostSettingsDialog;

import { createSlice } from '@reduxjs/toolkit';

const targetMessageSlice = createSlice({
  name: 'viewMessage',
  initialState: {
    viewMessageDetailsData: null,
    sendSmsOrEmailDetailsData: {
      selectPlateFormType: 2,
      tabValue: 1,
    },
    customerListData: {
      queue: [],
      message: '',
    },
    customerHistoryData: {},
  },
  reducers: {
    storeViewMessageDetails: (state, action) => {
      state.viewMessageDetailsData = action.payload;
    },
    storeSendSmsOrEmailDetails: (state, action) => {
      state.sendSmsOrEmailDetailsData = action.payload;
    },
    storeCustomerList: (state, action) => {
      state.customerListData = action.payload;
    },
    storeCustomerHistory: (state, action) => {
      state.customerHistoryData = action.payload;
    },
  },
});

export const {
  storeViewMessageDetails,
  storeSendSmsOrEmailDetails,
  storeCustomerList,
  storeCustomerHistory,
} = targetMessageSlice.actions;

export default targetMessageSlice.reducer;

import OrderedQrcode from './OrderedQrcode';
import QrcodeOrderDetail from './QrcodeOrderDetail';

const OrderedQrcodeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'ordered-qrcode',
      element: <OrderedQrcode />,
    },
    {
      path: 'qrcode-orders-details/:businessId',
      element: <QrcodeOrderDetail />,
    },
  ],
};

export default OrderedQrcodeConfig;

import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Switch from '@mui/material/Switch';
import {
  addReviewPlatform,
  resetReviewPlatform,
  openNewReviewPlatformDialog,
  closeNewReviewPlatformDialog,
  openEditReviewPlatformDialog,
  closeEditReviewPlatformDialog,
  getReviewPlatformForPortal,
  updateReviewPlatform,
  getCountries,
  enableDisableReviewType,
} from './store/reviewPlatformSlice';
import reducer from './store';
import reviewPlatformData from '../../query/reviewPlatform';
import queryData from '../../query/common';
import { getCountryData } from '../../../store/userSlice';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const defaultValues = {
  name: '',
  logo: null,
  url: '',
  countryId: [],
};

function ReviewPlatform() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('reviewPlatform.formFields.reviewPlatformName.validationMessage')),
    url: yup.string().required(t('reviewPlatform.formFields.reviewPlatformUrl.validationMessage')),
  });

  const defaultImage = 'https://quick-review.s3.us-east-1.amazonaws.com/qr-code/1688454142_93.jpeg';

  const reviewData = useSelector(
    ({ reviewPlatformReducer }) =>
      reviewPlatformReducer?.reviewPlatform?.getReviewPlatformForPortalData?.data
        ?.getReviewPlatformForPortal?.data || ''
  );
  const addReviewPlatformStatus = useSelector(
    ({ reviewPlatformReducer }) =>
      reviewPlatformReducer?.reviewPlatform?.addReviewPlatformData?.data?.addReviewPlatform
  );

  const updateReviewPlatformStatus = useSelector(
    ({ reviewPlatformReducer }) =>
      reviewPlatformReducer?.reviewPlatform?.updateReviewPlatformData?.data?.updateReviewPlatform ||
      ''
  );

  const enableDisableReviewTypeStatus = useSelector(
    ({ reviewPlatformReducer }) =>
      reviewPlatformReducer?.reviewPlatform?.enableDisableReviewTypeData?.data
        ?.enableDisableReviewType
  );
  const totalReviewPlatformType = useSelector(
    ({ reviewPlatformReducer }) =>
      reviewPlatformReducer?.reviewPlatform?.getReviewPlatformForPortalData?.data
        ?.getReviewPlatformForPortal?.totalCount || ''
  );

  const appDialog = useSelector(
    ({ reviewPlatformReducer }) => reviewPlatformReducer.reviewPlatform.reviewPlatformDialog
  );

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [actionValue, setActionValue] = useState('action');
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [reviewDataList, setReviewDataList] = useState(reviewData);
  const [selectedCountryIds, setSelectedCountryIds] = useState([0]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(true);
  const [countryId, setCountryId] = useState([1]);
  const [status, setStatus] = useState(true);
  const [countryList, setCountryList] = useState();
  const [imageUpload, setImageUpload] = useState();
  const [imagePreview, setImagePreview] = useState(defaultImage);
  const [noImgMsg, setNoImgMsg] = useState('');

  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = reviewData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (appDialog.type === 'edit' && appDialog?.data) {
      const selectedCountryIdsFromData = appDialog.data?.countryId || [];

      setSelectedCountryIds(selectedCountryIdsFromData);

      // Reset the form fields
      reset({
        ...appDialog?.data,
        name: appDialog.data?.name,
        logo: appDialog.data?.logo,
        url: appDialog.data?.url,
      });
    }

    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...appDialog?.data,
      });
    }
  }, [appDialog?.data, appDialog.type, reset]);

  /**
	/**
	 * On Dialog Open
	 */
  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
    }
  }, [appDialog.props.open, initDialog]);

  useEffect(() => {
    return () => {
      dispatch(resetReviewPlatform());
    };
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        sortBy: 'id',
        sortOrder: 'desc',
        isActive: true,
      },
    };
    dispatch(getReviewPlatformForPortal(payload));
  }, [dispatch]);

  useEffect(() => {
    if (reviewData || reviewData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [reviewData]);

  useEffect(() => {
    if (reviewData) {
      setReviewDataList(reviewData);
    } else {
      setReviewDataList([]);
    }
  }, [reviewData]);

  useEffect(() => {
    if (addReviewPlatformStatus) {
      if (addReviewPlatformStatus.status === 400) {
        enqueueSnackbar(addReviewPlatformStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setRefreshIcon(false);
        closeSnackbar(3000);
        dispatch(closeNewReviewPlatformDialog());
        reset(defaultValues);
      } else if (addReviewPlatformStatus.status === 200) {
        enqueueSnackbar(addReviewPlatformStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setRefreshIcon(false);
        closeSnackbar(3000);
        dispatch(closeNewReviewPlatformDialog());
        reset(defaultValues);
      }
    }
  }, [addReviewPlatformStatus, closeSnackbar, dispatch, enqueueSnackbar, reset]);

  useEffect(() => {
    if (enableDisableReviewTypeStatus && enableDisableReviewTypeStatus.status === 400) {
      enqueueSnackbar(enableDisableReviewTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (enableDisableReviewTypeStatus && enableDisableReviewTypeStatus.status === 200) {
      enqueueSnackbar(enableDisableReviewTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [enableDisableReviewTypeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateReviewPlatformStatus) {
      if (updateReviewPlatformStatus.status === 400) {
        enqueueSnackbar(updateReviewPlatformStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setRefreshIcon(false);
        closeSnackbar(3000);
        dispatch(closeEditReviewPlatformDialog());
        reset(defaultValues);
      } else if (updateReviewPlatformStatus.status === 200) {
        enqueueSnackbar(updateReviewPlatformStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setRefreshIcon(false);
        closeSnackbar(3000);
        dispatch(closeEditReviewPlatformDialog());
        reset(defaultValues);
      }
    }
  }, [closeSnackbar, dispatch, enqueueSnackbar, reset, updateReviewPlatformStatus]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        // searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActive: Boolean(JSON.parse(status)),
      },
    };
    dispatch(getReviewPlatformForPortal(payload));
  }

  function handleStatusDropdownChange(event) {
    const statusValue = event.target.value;
    setStatus(statusValue);
    setLoading(true);
    setPage(1);
    const payloadData = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        sortBy: orderBy,
        isActive: Boolean(JSON.parse(statusValue)),
        sortOrder: orderNow,
      },
    };
    dispatch(getReviewPlatformForPortal(payloadData));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  // function handleSearchEvent(event) {
  //   setPage(1);
  //   const searchText = event.target.value;
  //   commonGetData(searchText);
  // }

  function handleActionDropdownChange(event) {
    setActionValue(event.target.value);
  }

  const handleSorting = (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...reviewDataList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setReviewDataList(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...reviewDataList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setReviewDataList(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }

    const payload = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        // searchText: '',
        sortBy: sortColumnName,
        sortOrder: order,
        isActive: Boolean(JSON.parse(status)),
      },
    };
    dispatch(getReviewPlatformForPortal(payload));
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        // searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActive: Boolean(JSON.parse(status)),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getReviewPlatformForPortal(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: reviewPlatformData.getReviewPlatformForPortal,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        // searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActive: Boolean(JSON.parse(status)),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getReviewPlatformForPortal(payload));
      setLoadingPage(false);
    });
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all Review Platform',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('name');
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>Logo</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>Url</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              Created Date
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>Status</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>Action</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    return appDialog.type === 'edit'
      ? dispatch(closeEditReviewPlatformDialog())
      : dispatch(closeNewReviewPlatformDialog());
  }

  /**
   * Remove Event
   */
  function handleRemove() {
    closeComposeDialog();
    reset(defaultValues);
    setImagePreview(defaultImage);
  }
  function handleActiveInactive(id, currentStatus) {
    const payload = {
      query: reviewPlatformData.enableDisableReviewType,
      variables: { reviewPlatformId: id, isEnable: !currentStatus },
    };

    dispatch(enableDisableReviewType(payload)).then(() => {
      const payloadData = {
        query: reviewPlatformData.getReviewPlatformForPortal,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          sortBy: orderBy,
          sortOrder: orderNow,
          isActive: currentStatus,
        },
      };

      dispatch(getReviewPlatformForPortal(payloadData));
    });
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
      setImageUpload(file);
    };
    setNoImgMsg('');
  }

  function onSubmit(submitData) {
    setRefreshIcon(true);
    const integerCountryIds = selectedCountryIds.map((id) => parseInt(id, 10));
    if (appDialog.type === 'new') {
      const payloadValue = {
        query: reviewPlatformData.addReviewPlatform,
        variables: {
          name: submitData.name,
          logo: null,
          countryId: integerCountryIds,
          url: submitData.url,
        },
      };
      const dataObj = {
        payload: payloadValue,
        filedata: imageUpload,
      };
      dispatch(addReviewPlatform(dataObj)).then(() => {
        setSelectedCountryIds([]);
        const payloadData = {
          query: reviewPlatformData.getReviewPlatformForPortal,
          variables: {
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: orderBy,
            sortOrder: orderNow,
            isActive: Boolean(JSON.parse(status)),
          },
        };
        dispatch(getReviewPlatformForPortal(payloadData));
        reset(defaultValues);
        setImagePreview(defaultImage);
        setImageUpload('');
      }, 800);
    } else {
      const payloadValue = {
        query: reviewPlatformData.updateReviewPlatform,
        variables: {
          reviewPlatformId: submitData.id,
          name: submitData.name,
          logo: null,
          countryId: selectedCountryIds,
          url: submitData.url,
          isActive: Boolean(JSON.parse(status)),
        },
      };
      const dataObj = {
        payload: payloadValue,
        filedata: imageUpload,
      };
      dispatch(updateReviewPlatform(dataObj));
      const payloadData = {
        query: reviewPlatformData.getReviewPlatformForPortal,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          // searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
          isActive: Boolean(JSON.parse(status)),
        },
      };
      setTimeout(() => {
        dispatch(getReviewPlatformForPortal(payloadData));
        setImagePreview(defaultImage);
        setImageUpload('');
      }, 1000);
    }
  }

  return (
    <div className="p-20 lg:p-40">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('reviewPlatform.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          {/* <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder="Search"
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  commonGetData();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper> */}
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-136 leading-5 shadow-none mr-16"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={status}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="true" className="capitalize">
              {t('menuItems.active')}
            </MenuItem>
            <MenuItem value="false" className="capitalize">
              {t('menuItems.inActive')}
            </MenuItem>
          </Select>
          <Button
            variant="contained"
            color="secondary"
            className="w-full min-w-256 md:text-18 font-medium disabled:text-black"
            aria-label="Add Review Platform"
            onClick={() => {
              dispatch(openNewReviewPlatformDialog());
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">
              &nbsp; {t('reviewPlatform.buttons.addReviewPlatformBtn')}
            </span>
            <span className="flex sm:hidden">&nbsp; {t('reviewPlatform.buttons.new')}</span>
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {reviewData.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={reviewData.length}
                />
                <TableBody>
                  {reviewDataList &&
                    reviewDataList.length > 0 &&
                    reviewDataList.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <img
                              className="shrink-0 w-32 h-32 mr-12 rounded-full overflow-hidden object-cover object-center"
                              src={row.logo}
                              alt="logo"
                            />
                          </TableCell>

                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.url}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.isActive ? 'Active' : 'Inactive'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Icon
                              className="cursor-pointer hover:text-darkgreen align-middle"
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(openEditReviewPlatformDialog(row));
                                setImagePreview(row.logo);
                              }}
                            >
                              edit
                            </Icon>
                            <Switch
                              checked={row.isActive}
                              onChange={() => handleActiveInactive(row?.id, row?.isActive)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              There are no any Review Platform!
            </Typography>
          </motion.div>
        )}
      </div>
      {reviewData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalReviewPlatformType}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        classes={{
          paper: 'm-24 p-28',
        }}
        {...appDialog.props}
        disableBackdropClick
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            {appDialog.type === 'new' ? 'Add Review Platform' : 'Edit Review Platform'}
          </Typography>
        </DialogTitle>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent classes={{ root: 'p-12' }}>
            <motion.div className="w-192 h-192 mb-24">
              <Paper
                className="flex flex-col relative items-center justify-center w-full h-full rounded-8 shadow hover:shadow-lg border-2"
                role="button"
              >
                <div className="flex flex-col items-center">
                  <img
                    className="w-2/4 block rounded max-h-96"
                    name="logo"
                    src={imagePreview}
                    alt="logo"
                  />
                  <label htmlFor="button-file">
                    <input
                      accept="image/*"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      size="small"
                      className="rounded mt-10 block"
                      variant="outlined"
                      color="primary"
                      component="span"
                    >
                      {t('reviewPlatform.buttons.uploadReviewIcon')}
                    </Button>
                  </label>
                </div>
                {noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
              </Paper>
            </motion.div>

            <div className="block mb-24">
              <InputLabel htmlFor="name" className="text-16 font-medium mb-12 text-grey-900">
                {t('reviewPlatform.formFields.reviewPlatformName.name')}
              </InputLabel>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('reviewPlatform.formFields.reviewPlatformName.placeholder')}
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="block mb-24">
              <InputLabel htmlFor="url" className="text-16 font-medium mb-12 text-grey-900">
                {t('reviewPlatform.formFields.reviewPlatformUrl.name')}
              </InputLabel>
              <Controller
                control={control}
                name="url"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('reviewPlatform.formFields.reviewPlatformUrl.placeholder')}
                    size="small"
                    id="url"
                    error={!!errors.url}
                    helperText={errors?.url?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block">
              <InputLabel htmlFor="countryId" className="text-16 font-medium mb-12 text-grey-900">
                {t('reviewPlatform.formFields.countryId')}
              </InputLabel>
              <Controller
                name="countryId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="mb-24 w-full"
                    id="countryId"
                    multiple
                    value={selectedCountryIds}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue.includes('all')) {
                        setSelectedCountryIds(
                          newValue.length === countryList.length + 1
                            ? []
                            : countryList.map((option) => option.id)
                        );
                      } else {
                        setSelectedCountryIds(newValue.filter((value) => value !== 'all'));
                      }
                    }}
                    renderValue={(selectedOptions) => (
                      <div>
                        {selectedOptions.includes(0) ? (
                          <div className="placeholder">{t('menuItems.selectCountry')}</div>
                        ) : (
                          selectedOptions
                            .map((value) => countryList.find((option) => option.id === value).name)
                            .join(', ')
                        )}
                      </div>
                    )}
                  >
                    <MenuItem value="all">
                      <Checkbox checked={selectedCountryIds.length === countryList.length} />
                      <ListItemText primary={t('menuItems.selectAll')} />
                    </MenuItem>
                    {countryList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedCountryIds.includes(option.id)} />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-start ml-12">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={appDialog.type === 'new' ? !imageUpload : false || !isValid || refreshIcon}
            >
              {appDialog.type === 'new' ? 'Create' : 'Update'}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('reviewPlatform.buttons.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('reviewPlatformReducer', reducer)(ReviewPlatform);

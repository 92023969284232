import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '@mui/material';
import { selectDashboardData } from 'src/app/store/userSlice';
import NoData from './noDataScreen';
import metricsData from '../../query/metrics';
import { getDataToServer } from '../../common/common';

function CustomersSay(props) {
  const { t } = useTranslation();

  const [reviewCustomerSayData, setReviewCustomerSayData] = useState([]);
  const [reviewCustomerSayDesc, setReviewCustomerSayDesc] = useState('');
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id) {
          const payload = {
            query: metricsData.getCustomerSayData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewsSentimentPeriodsId: parseInt(7, 10),
            },
          };

          const result = await getDataToServer(payload);
          setReviewCustomerSayData(result?.getCustomerSayData?.data?.attributesWithSentiment || []);
          setReviewCustomerSayDesc(result?.getCustomerSayData?.data?.summary);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  return (
    <>
      <div>
        <div>
          {reviewCustomerSayData && reviewCustomerSayData?.length > 0 ? (
            <>
              <Typography className="text-16 font-medium mb-20 text-grey-800">
                {reviewCustomerSayDesc}
              </Typography>
              {reviewCustomerSayData?.map((item, index) => (
                <div
                  className="py-6 px-10 mb-12 rounded-md inline-block font-medium mr-12"
                  style={{
                    backgroundColor:
                      item?.sentimentColor === 'green'
                        ? '#D5F9D5'
                        : item?.sentimentColor === 'red'
                        ? '#FFF3E0'
                        : '#EFF2F2',
                    color:
                      item?.sentimentColor === 'green'
                        ? '#4CAF50'
                        : item?.sentimentColor === 'red'
                        ? '#FF9B00'
                        : '#000000',
                  }}
                  key={index}
                >
                  <span
                    className="bg-green w-16 h-16 rounded-full inline-block text-center"
                    style={{
                      color:
                        item?.sentimentColor === 'green'
                          ? '#D5F9D5'
                          : item?.sentimentColor === 'red'
                          ? '#FFF3E0'
                          : '#EFF2F2',
                      backgroundColor:
                        item?.sentimentColor === 'green'
                          ? '#4CAF50'
                          : item?.sentimentColor === 'red'
                          ? '#FF9B00'
                          : '#000000',
                    }}
                  >
                    <Icon className="text-12">check</Icon>
                  </span>{' '}
                  {item?.attributeName}
                </div>
              ))}
            </>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </>
  );
}

export default CustomersSay;

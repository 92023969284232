import {
  Button,
  Icon,
  MenuItem,
  Select,
  Paper,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import history from '@history';
import { getParamsId } from '../../../common/common';
import promotionQuery from '../../../query/promotion';
import { createPromotion } from '../store/promotionSlice';
import PreviewImages from './PreviewImages';

const BuyXGetYFree = (props) => {
  const {
    promotionApplicableData,
    selectBusiness,
    selectPromotionTypeId,
    previewImages,
    buttonTypeData,
    images,
    setImages,
    countryId,
  } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [buttonType, setButtonType] = useState(buttonTypeData?.[0]?.key);
  const [applicable, setApplicable] = useState(promotionApplicableData?.[0]?.key);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectedButton, setSelectedButton] = useState(buttonTypeData?.[0]?.type);
  const [loading, setLoading] = useState(false);
  const [buyXGetYFree, setBuyXGetYFree] = useState({
    buyQuantity: '',
    buyProductName: '',
    getQuantity: '',
    getProductName: '',
  });
  const onSubmit = async () => {
    if (!loading) {
      if (
        !buyXGetYFree?.buyQuantity ||
        !buyXGetYFree?.getQuantity ||
        !buyXGetYFree?.buyProductName ||
        !buyXGetYFree?.getProductName
      ) {
        enqueueSnackbar('All field are required', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else {
        let imageData = {};
        if (typeof images === 'object') {
          imageData = {
            image: images,
          };
        }
        if (typeof images === 'string') {
          imageData = {
            imageUrl: images,
          };
        }
        setLoading(true);
        setRefreshIcon(true);
        const payload = {
          query: promotionQuery.createPromotion,
          variables: {
            userBusinessId: parseInt(selectBusiness, 10),
            promotionCategoryId: parseInt(selectPromotionTypeId, 10),
            ...imageData,
            htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
            </head>
            <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
                <div style="margin: 0 auto; padding: 20px;">
                      <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                        <div style="padding: 25px;">
                            <div style="border: 1px solid white; padding-left: 20px;">
                               <p style="color: white; font-weight: 600; font-size: 20px;">BUY ${buyXGetYFree?.buyQuantity} ${buyXGetYFree?.buyProductName} <br /> AND GET ${buyXGetYFree?.getQuantity} FREE <br /> ${buyXGetYFree?.getProductName}</p>
                                <button style="padding: 8px 25px 8px 25px; border-radius: 4px; border: 1px solid white; background-color: white; margin-bottom: 15px;">${selectedButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`),
            buyQuantity: parseInt(buyXGetYFree?.buyQuantity, 10),
            getQuantity: parseInt(buyXGetYFree?.getQuantity, 10),
            buyProductName: buyXGetYFree?.buyProductName,
            getProductName: buyXGetYFree?.getProductName,
            buttonName: buttonType,
            applicableFor: applicable,
          },
        };
        const result = await dispatch(createPromotion(payload));
        setRefreshIcon(false);
        setLoading(false);
        if (result?.payload?.createPromotion?.status === 200) {
          const id = getParamsId();
          if (id) {
            history.push(`promotion/${id}`);
          } else {
            history.push('promotion');
          }
          enqueueSnackbar(result?.payload.createPromotion?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result?.payload.createPromotion?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    }
  };
  const handleCancel = () => {
    const id = getParamsId();
    if (id) {
      history.push(`promotion/${id}`);
    } else {
      history.push('promotion');
    }
  };

  const handleButtonType = (value) => {
    const data = buttonTypeData?.find((item) => item.key === value);
    setButtonType(data?.key);
    setSelectedButton(data?.type);
  };

  return (
    <div className="md:grid md:grid-cols-8 gap-16">
      <div className="bg-white col-span-5 rounded-md relative rounded-lg shadow p-20 mt-24">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-24 mb-24 font-medium"
        >
          {t('promotion.promotionInfo')}
        </Typography>
        <form>
          <div className="grid md:grid-cols-8 gap-16">
            <div className="col-span-4">
              <Typography className="pb-8">
                {t('promotion.buyQuantity')} <span className=" text-2xl text-red-500">*</span>
              </Typography>
              <TextField
                className="w-full"
                type="number"
                size="small"
                onChange={(e) =>
                  setBuyXGetYFree((prevState) => ({
                    ...prevState,
                    buyQuantity: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-span-4">
              <Typography className="pb-8">
                {t('promotion.buyProduct')} <span className=" text-2xl text-red-500">*</span>
              </Typography>
              <TextField
                className="w-full"
                type="text"
                size="small"
                onChange={(e) =>
                  setBuyXGetYFree((prevState) => ({
                    ...prevState,
                    buyProductName: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-8 my-12 gap-16">
            <div className="col-span-4">
              <Typography className="pb-8">
                {t('promotion.getQuantity')} <span className=" text-2xl text-red-500">*</span>
              </Typography>
              <TextField
                className="w-full"
                type="number"
                size="small"
                onChange={(e) =>
                  setBuyXGetYFree((prevState) => ({
                    ...prevState,
                    getQuantity: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-span-4">
              <Typography className="pb-8">
                {t('promotion.getProduct')} <span className=" text-2xl text-red-500">*</span>
              </Typography>
              <TextField
                className="w-full"
                type="text"
                size="small"
                onChange={(e) =>
                  setBuyXGetYFree((prevState) => ({
                    ...prevState,
                    getProductName: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="py-8">
            <Typography className="pb-8">{t('promotion.applicableFor')}</Typography>
            <Select
              className="w-full"
              id="country"
              value={applicable}
              displayEmpty
              onChange={(e) => {
                setApplicable(e.target.value);
              }}
              size="small"
            >
              {promotionApplicableData?.length > 0 &&
                promotionApplicableData?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.key}>
                      {item?.type}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          <div className="py-8">
            <Typography className="pb-8">{t('promotion.buttonName')}</Typography>
            <Select
              className="w-full"
              id="country"
              value={buttonType}
              displayEmpty
              onChange={(e) => {
                handleButtonType(e.target.value);
              }}
              size="small"
            >
              {buttonTypeData?.length > 0 &&
                buttonTypeData?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.key}>
                      {item?.type}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          <div className="text-center my-16">
            <Button
              className="bg-white hover:bg-white border border-solid rounded md:px-60 text-darkgreen border-darkgreen"
              onClick={() => handleCancel()}
            >
              {t('promotion.cancel')}
            </Button>
            <Button
              className="text-white bg-darkgreen md:px-40 rounded disabled:text-black disabled:bg-gray hover:bg-darkgreen ml-8"
              type="button"
              disabled={
                !buyXGetYFree?.buyQuantity ||
                !buyXGetYFree?.getQuantity ||
                !buyXGetYFree?.buyProductName ||
                !buyXGetYFree?.getProductName ||
                refreshIcon
              }
              onClick={() => onSubmit()}
            >
              {t('promotion.createPromotion')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="col-span-3 bg-white rounded-md relative rounded-lg shadow p-20 mt-24">
        <PreviewImages
          previewImages={previewImages}
          setImages={setImages}
          buyXGetYFree={buyXGetYFree}
          selectPromotionTypeId={selectPromotionTypeId}
          buttonType={selectedButton}
        />
      </div>
    </div>
  );
};

export default BuyXGetYFree;

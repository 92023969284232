const createBusinessQrCodeOrder = `
mutation createBusinessQrCodeOrder($address: String!, $businessName: String!, $city: String!, $countryCode: String!, $countryId: Int!, $email: String!, $firstName: String!, $landmark: String!, $lastName: String!, $phoneNumber: Float!, $quantity: Int!, $stateId: Int!, $userBusinessId: Int!, $zipCode: Int!) {
     createBusinessQrCodeOrder(address: $address, businessName: $businessName, city: $city, countryCode: $countryCode, countryId: $countryId, email: $email, firstName: $firstName, landmark: $landmark, lastName: $lastName, phoneNumber: $phoneNumber, quantity: $quantity, stateId: $stateId, userBusinessId: $userBusinessId, zipCode: $zipCode) {
    message
    status
  }
}
`;

const getBusinessQrOrder = `
query getBusinessQrOrder($businessQrOrderId: Int, $filterStatus: Int, $pageNumber: Int, $pageSize: Int, $searchText: String) {
  getBusinessQrOrder(businessQrOrderId: $businessQrOrderId, filterStatus: $filterStatus, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText) {
    message
    status
    totalCount
    data{
      address
      businessName
      city
      countries{
        countryCode
        currency
        id
        isoCode
        name
        symbol
      }
      countryCode
      countryId
      cts
      email
      fullName
      id
      isActive
      landmark
      orderCancelByUser
      orderStatusId
      phoneNumber
      qrCodeOrderStatusField{
        id
        status
      }
      quantity
      stateId
      states{
        countryCode
        currency
        id
        isoCode
        name
        symbol
      }
      userBusinessId
      userId
      zipCode
    }
  }
}
`;

const getBusinessQrOrderStatusField = `
query getBusinessQrOrderStatusField {
  getBusinessQrOrderStatusField{
    message
    status
    data{
      id
      status
    }
  }
}
`;

const updateBusinessQrOrderStatus = `
mutation updateBusinessQrOrderStatus($businessQrcodeOrdersId: Int!, $statusFieldId: Int!) {
  updateBusinessQrOrderStatus(businessQrcodeOrdersId: $businessQrcodeOrdersId, statusFieldId: $statusFieldId){
    message
    status
  }
}
`;

const qrcodeData = {
  createBusinessQrCodeOrder,
  getBusinessQrOrder,
  getBusinessQrOrderStatusField,
  updateBusinessQrOrderStatus,
};

export default qrcodeData;

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Loading from '../manage-business/Loading';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest, getParamsId } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const AllPost = ({ tabValue }) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const navigate = useNavigate();

  const [allPostDetails, setAllPostDetails] = useState([]);
  const [favoriteTemplateIds, setFavoriteTemplateIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const favAPIListCall = useCallback(
    async (postAll) => {
      try {
        setPageLoading(true);
        const payloadData = {
          query: quickPostQuery.getFavoriteTemplate,
          variables: {
            pageNumber: 1,
            pageSize: 10,
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setPageLoading(false);
        if (resultData?.getFavoriteTemplate?.status === 200) {
          const favIds = postAll
            ?.filter((item1) =>
              resultData?.getFavoriteTemplate?.data.some(
                (item2) => item1.id === item2.sharePostTemplateId
              )
            )
            .map((item) => item.id);
          setFavoriteTemplateIds(favIds);
        } else {
          enqueueSnackbar(resultData?.api?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setPageLoading(false);
        console.error('An error occurred while API called.', error);
      }
    },
    [userCurrentBusinessDetails?.id, enqueueSnackbar]
  );

  const fetchAgentSupportStatus = useCallback(
    async (value = '', pageNo = '', pageSizeVal = '') => {
      if (userCurrentBusinessDetails?.id) {
        const valueTab = value || tabValue;
        setPageLoading(true);
        const queries = {
          0: quickPostQuery.suggestionPostV2,
          1: quickPostQuery.getViewedPosts,
          2: quickPostQuery.getSharePostTemplate,
          3: quickPostQuery.getFavoriteTemplate,
        };

        const query = queries[valueTab];
        if (userCurrentBusinessDetails?.id && query) {
          try {
            const variables = {
              pageNumber: pageNo,
              pageSize: pageSizeVal,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            };

            if (valueTab !== 3) {
              variables.rating = 5;
            } else {
              variables.businessId = variables.userBusinessId;
              delete variables.userBusinessId;
            }

            const payloadData = { query, variables };
            const resultData = await handleApiRequest(payloadData);
            setPageLoading(false);

            const dataKeys = [
              'suggestionPostV2',
              'getViewedPosts',
              'getSharePostTemplate',
              'getFavoriteTemplate',
            ];
            const resultKey = dataKeys[valueTab];

            if (resultData?.[resultKey]?.status === 200) {
              setTotalCount(resultData[resultKey]?.totalCount);
              const postData = resultData[resultKey]?.data || [];
              setAllPostDetails(postData);

              if (valueTab === 2) {
                favAPIListCall(postData);
              }
            } else {
              enqueueSnackbar(resultData?.api?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          } catch (error) {
            setPageLoading(false);
            console.error('Error fetching data:', error);
          }
        }
      }
    },
    [userCurrentBusinessDetails?.id, tabValue, favAPIListCall, enqueueSnackbar]
  );

  const fetchAgentSupportStatusRef = useRef(fetchAgentSupportStatus);

  useEffect(() => {
    fetchAgentSupportStatusRef.current = fetchAgentSupportStatus;
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    if (tabValue !== undefined) {
      fetchAgentSupportStatusRef.current(tabValue, 1, 10);
    }
    setPage(0);
  }, [tabValue]);

  const handleChangePage = (event, value) => {
    setPage(value);
    fetchAgentSupportStatus(tabValue, value + 1, 10);
  };

  const handleCreatePost = (data) => {
    const id = getParamsId();
    if (id) {
      navigate(`/create-post/${id}`, { state: data });
    } else {
      navigate('/create-post/', { state: data });
    }
  };

  if (pageLoading) {
    return <Loading />;
  }

  const addToFav = async (data) => {
    if (favoriteTemplateIds.includes(data.id)) {
      enqueueSnackbar(t('quickPost.alreadyInFavorites'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      setPageLoading(true);
      const payload = {
        query: quickPostQuery.addFavoriteTemplate,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          sharePostTemplateId: data?.id,
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.addFavoriteTemplate?.status === 200) {
        enqueueSnackbar(t('quickPost.addToFavorites'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setFavoriteTemplateIds((prevIds) => [...prevIds, data.id]);
      } else {
        enqueueSnackbar(result?.addFavoriteTemplate?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setPageLoading(false);
      console.error('Error adding template to favorites:', error);
    }
  };

  const removeFavList = async (data) => {
    try {
      setPageLoading(true);
      const payload = {
        query: quickPostQuery.deleteFavoriteTemplate,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          sharePostTemplateId: data?.sharePostTemplateId,
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.deleteFavoriteTemplate?.status === 200) {
        setFavoriteTemplateIds((prevIds) =>
          prevIds.filter((item) => item !== data.sharePostTemplateId)
        );
        enqueueSnackbar(t('quickPost.removeToFavorite'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        fetchAgentSupportStatus(tabValue, page + 1, 10);
      } else {
        enqueueSnackbar(result?.deleteFavoriteTemplate?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setPageLoading(false);
      console.error('Error removing template from favorites:', error);
    }
  };

  return (
    <div>
      {allPostDetails?.length > 0 ? (
        <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-10">
          {allPostDetails?.map((post, index) => (
            <div
              key={index}
              className="relative group cursor-pointer flex justify-center items-center mb-36"
            >
              <div className="w-full h-full max-h-288 max-w-288">
                <img
                  src={
                    tabValue === 2
                      ? post.templateImage
                      : tabValue === 1
                      ? post?.share_post_template?.templateImage
                      : tabValue === 3
                      ? post?.share_post_template?.templateImage
                      : post?.sharePostImage
                  }
                  alt="..."
                  className="w-full h-full object-cover rounded"
                />
              </div>
              <div
                aria-hidden="true"
                onClick={() => {
                  if (tabValue === 0) {
                    handleCreatePost(post);
                  }
                }}
                className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              >
                {tabValue === 2 && (
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      addToFav(post);
                    }}
                    className={`h-36 min-h-0 mt-14 ml-14 font-medium ${
                      favoriteTemplateIds.includes(post.id)
                        ? 'bg-white text-gray-500'
                        : 'bg-red-A300 text-white'
                    } hover:bg-black`}
                  >
                    {favoriteTemplateIds.includes(post.id)
                      ? t('quickPost.addedToFavorites')
                      : t('quickPost.addToFavorites')}
                  </Button>
                )}
                {tabValue === 3 && (
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      removeFavList(post);
                    }}
                    className="h-36 min-h-0 mt-14 ml-14 font-medium bg-red-A300 text-white hover:bg-black"
                  >
                    {t('quickPost.removeToFavorite')}
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Typography className="font-semibold text-16 flex items-center justify-center">
          {t('quickPost.noPostAvailable')}
        </Typography>
      )}
      {totalCount > 0 && (
        <div className="p-4">
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
          />
        </div>
      )}
    </div>
  );
};

export default AllPost;

import AgencyProfile from './AgencyProfile';
import UserProfile from './UserProfile';

const UserConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'user-profile/:id?',
      element: <UserProfile />,
    },
    {
      path: 'agency-profile/:id?',
      element: <AgencyProfile />,
    },
  ],
};

export default UserConfig;

import { Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { publicIpv4 } from 'public-ip';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import selectedBusinessData from '../../../../query/selectedBusiness';
import {
  handleApiRequest,
  countryData,
  getEncryptedData,
  getDecryptData,
} from '../../../../common/common';
import UserService from '../../../../../services/userService';
import queryData from '../../../../query/common';
import { getLocation } from '../../../../sign-up/store/signUpSlice';

const SalesPersonInsight = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const [note, setNote] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [phone, setPhone] = useState();
  const [orignalPhoneNumber, setOrignalPhoneNumber] = useState({});
  const [phoneValue, setPhoneValue] = useState();
  const [salesId, setSalesId] = useState();
  const loginUserData = UserService.getUserData();
  const [countryCode, setCountryCode] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const ipAddress = publicIpv4();
    const ipAddressData = async () => {
      const payload = {
        query: queryData.getLocation,
        variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
      };
      const locationResponse = await dispatch(getLocation(payload));
      if (locationResponse?.payload?.data?.getLocation?.data) {
        const locationData = locationResponse?.payload?.data?.getLocation?.data;
        const data =
          JSON.parse(getDecryptData(locationData))?.countryId || loginUserData?.countryCode;
        const country = countryData(data);
        if (country) {
          setCountryCode(country?.phoneCountry);
          setPhoneValue(country?.phoneCountry);
        }
      }
    };
    ipAddressData();
  }, [dispatch, loginUserData?.countryCode]);
  useEffect(() => {
    const fetchSalesInsights = async () => {
      const payload = {
        query: selectedBusinessData.getSalesPersonsInsights,
        variables: {
          businessId: parseInt(routeParams.businessId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getSalesPersonsInsights?.status === 200) {
        const countryCodes = result.getSalesPersonsInsights.data?.countryCode || '';
        const phoneNumbers = result.getSalesPersonsInsights?.data?.phoneNumber || '';
        setNote(result?.getSalesPersonsInsights?.data?.feedback);
        setPhoneNumber(
          result?.getSalesPersonsInsights?.data?.countryCode
            ?.concat(result?.getSalesPersonsInsights?.data?.phoneNumber)
            ?.slice(1)
        );
        setOrignalPhoneNumber({
          phoneNumber: result?.getSalesPersonsInsights?.data?.phoneNumber,
          countryCode: result?.getSalesPersonsInsights?.data?.countryCode,
        });
        setPhone({ dialCode: result?.getSalesPersonsInsights?.data?.countryCode?.slice(1) });
        setPhoneValue(countryCodes + phoneNumbers);
        setSalesId(result?.getSalesPersonsInsights?.data?.id);
      }
    };
    fetchSalesInsights();
  }, [routeParams.businessId]);

  function handlePhoneChange(event, data, value, formattedValue) {
    setPhoneNumber(event?.slice(data?.dialCode?.length));
    setPhoneValue(event);
    setPhone(data);
  }

  const onSubmit = async () => {
    const phoneNo = phoneNumber?.includes(phone?.dialCode)
      ? parseInt(phoneNumber?.slice(phone?.dialCode.length), 10)
      : parseInt(phoneNumber, 10);
    if (!note || phoneNo?.toString().length === 3) {
      enqueueSnackbar(t('business.selectedBusinessDetail.labels.noteAndPhoneNumber'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      const payload = {
        query: selectedBusinessData.updateSalesPersonsInsights,
        variables: {
          salesPersonsInsightsId: parseInt(salesId, 10),
          feedback: note,
          phoneNumber: phoneNumber?.includes(phone?.dialCode)
            ? parseInt(phoneNumber?.slice(phone?.dialCode.length), 10)
            : parseInt(phoneNumber, 10),
          countryCode: `+${phone?.dialCode}`,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateSalesPersonsInsights?.status === 200) {
        setIsEdit(false);
        const obj = {
          query: selectedBusinessData.getSalesPersonsInsights,
          variables: {
            businessId: parseInt(routeParams.businessId, 10),
          },
        };
        const res = await handleApiRequest(obj);
        if (res?.getSalesPersonsInsights?.status === 200) {
          setNote(res.getSalesPersonsInsights.data?.feedback);
          setPhoneNumber(
            res?.getSalesPersonsInsights?.data?.countryCode
              ?.concat(res?.getSalesPersonsInsights?.data?.phoneNumber)
              ?.slice(1)
          );
          setOrignalPhoneNumber({
            phoneNumber: res?.getSalesPersonsInsights?.data?.phoneNumber,
            countryCode: res?.getSalesPersonsInsights?.data?.countryCode,
          });
          setPhone({ dialCode: res?.getSalesPersonsInsights?.data?.countryCode?.slice(1) });
          setSalesId(res.getSalesPersonsInsights.data.id);
        }
      } else {
        enqueueSnackbar(result?.updateSalesPersonsInsights?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleCancel = async () => {
    const payload = {
      query: selectedBusinessData.getSalesPersonsInsights,
      variables: {
        businessId: parseInt(routeParams.businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getSalesPersonsInsights?.status === 200) {
      setNote(result.getSalesPersonsInsights.data.feedback);
      setPhoneNumber(
        result?.getSalesPersonsInsights?.data?.countryCode
          ?.concat(result?.getSalesPersonsInsights?.data?.phoneNumber)
          ?.slice(1)
      );
      setPhoneValue(countryCode);
      setPhone({ dialCode: result?.getSalesPersonsInsights?.data?.countryCode?.slice(1) });
      setSalesId(result.getSalesPersonsInsights.data.id);
    }
    setIsEdit(false);
  };

  return (
    <div className="bg-grey-100 rounded-md mt-20 p-20">
      <div className="flex justify-between items-center">
        <Typography className="text-black font-bold text-15 md:text-18">
          {t('business.selectedBusinessDetail.labels.salespersonInsights')}
        </Typography>
        {!isEdit ? (
          <Button
            className="bg-darkgreen hover:bg-darkgreen text-white h-28 min-h-auto px-12"
            onClick={() => setIsEdit(true)}
            type="small"
          >
            <EditIcon className="text-24 pr-6" /> {t('business.selectedBusinessDetail.labels.edit')}
          </Button>
        ) : (
          <div className="flex gap-4">
            <Button
              className="bg-darkgreen hover:bg-darkgreen text-white px-20 py-0"
              onClick={() => handleCancel()}
            >
              {t('business.selectedBusinessDetail.labels.cancel')}
            </Button>
            <Button
              className="bg-darkgreen hover:bg-darkgreen text-white px-20 py-0"
              onClick={() => onSubmit()}
            >
              {t('business.selectedBusinessDetail.labels.save')}
            </Button>
          </div>
        )}
      </div>
      <hr className="border-dashed border-black mt-16" />
      {!isEdit ? (
        <>
          <div className="mt-20">
            <div>
              <Typography className="font-bold">
                {t('business.selectedBusinessDetail.labels.note')}
              </Typography>
              <Typography>{note}</Typography>
            </div>
            <div className="mt-12">
              <Typography className="font-bold">
                {t('business.selectedBusinessDetail.labels.phoneNumber')}
              </Typography>
              <Typography>
                {orignalPhoneNumber.countryCode || ''} {orignalPhoneNumber.phoneNumber || ''}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-20">
            <Typography className="font-bold">
              {t('business.selectedBusinessDetail.labels.note')}
            </Typography>
            <TextField className="w-full" value={note} onChange={(e) => setNote(e.target.value)} />
          </div>
          <div className="pt-8">
            <Typography className="font-bold">
              {t('business.selectedBusinessDetail.labels.phoneNumber')}
            </Typography>
            <PhoneInput
              placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
              inputStyle={{
                width: '100%',
                height: '52px',
              }}
              className="w-full max-w-sm w-full"
              name="phone_number"
              required
              disabled={!isEdit}
              value={phoneNumber?.toString()}
              country={phoneValue}
              enableSearch="true"
              onChange={handlePhoneChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SalesPersonInsight;

import QuickPost from './QuickPost';
import CreatePost from './CreatePost';

const QuickPostConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-post/:id?',
      element: <QuickPost />,
    },
    {
      path: 'create-post/:id?',
      element: <CreatePost />,
    },
  ],
};

export default QuickPostConfigs;

import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useDispatch } from 'react-redux';
import './MangeBusiness.css';
import { Typography } from '@mui/material';
import TopHeader from './TopHeader';
import AddBusiness from './AddBusiness';
import CustomAttribute from './CustomAttribute';
import ReviewMode from './ReviewMode';
import AmazonProduct from './AmazonProduct';
import FlipkartProduct from './FlipkartProduct';
import manageBusinessData from '../../query/manageBusiness';
import userService from '../../../services';
import reducer from './store';
import { getUserBusinessDetail } from './store/manageBusinessSlice';

function ManageBusinessOnline() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginUserData = userService.getUserData();

  const [activeStepNumber, setActiveStepNumber] = useState(
    localStorage.getItem('activeStepNumber')
  );
  const [userBusinessDetail, setUserBusinessDetail] = useState({});

  const steps = [
    {
      id: 1,
      label: `${t('manageBusiness.addBusinessSteps.addBusiness.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.addBusiness.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/add_business_icon.svg',
    },
    {
      id: 2,
      label: `${t('manageBusiness.addBusinessSteps.customAttribute.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.customAttribute.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/custom_attribute_icon.svg',
    },
    {
      id: 3,
      label: `${t('manageBusiness.addBusinessSteps.reviewMode.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.reviewMode.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/review_mode_icon.svg',
    },
    {
      id: 4,
      label: `${t('manageBusiness.addBusinessSteps.amazonProduct.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.amazonProduct.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/amazon_roduct_icon.svg',
    },

    {
      id: 5,
      label: `${t('manageBusiness.addBusinessSteps.flipkartProduct.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.flipkartProduct.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/flipkart_roduct_icon.svg',
    },
  ];

  useEffect(() => {
    try {
      const fetchData = async () => {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const result = await dispatch(getUserBusinessDetail(payload));
        setUserBusinessDetail(result?.payload?.data?.getUserBusinessDetail?.data?.[0]);
      };
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, loginUserData?.countryId]);

  return (
    <div>
      <TopHeader />
      <div className="border-b-1 md:py-28 lg:px-28 px-0 py-10 mb-40">
        <Stepper
          activeStep={activeStepNumber}
          className="md:flex block"
          sx={{
            '& .MuiStepConnector-horizontal': { display: 'none' },
            '& .MuiStepIcon-root': { display: 'none' },
          }}
        >
          {steps.map((singleStep, index) => (
            <Step
              key={index}
              className={`md:w-1/5 flex relative md:my-0 my-20 ${
                index < activeStepNumber
                  ? 'lg:before:border-b-1 before:border-solid before:border-darkgreen before:absolute before:left-0 before:right-0 before:-bottom-28 before:-mb-1'
                  : ''
              }`}
            >
              <StepLabel>
                <div className="flex items-center">
                  <span
                    className={`mr-14 flex items-center min-w-52 w-52 h-52 rounded-full border-1 border-solid border-darkgreen text-center ${
                      index < activeStepNumber ? 'bg-darkgreen active-steps' : 'bg-white'
                    }`}
                  >
                    <img src={singleStep.icon} alt="... " className="mx-auto" />
                  </span>
                  <div>
                    <span className="block text-18 font-medium text-black">{singleStep.label}</span>
                    <span className="block text-14 font-medium text-black">{singleStep.desc}</span>
                  </div>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="container px-16 text-left">
        <Typography className="block text-16 font-medium">
          {t('manageBusiness.welcomeMsg')}
        </Typography>
        <span className="block font-semibold text-20 text-darkgreen mt-10 mb-28">
          {t('manageBusiness.step')} {activeStepNumber}/{steps.length}
        </span>
      </div>
      <div className="container px-16">
        {parseInt(activeStepNumber, 10) === 1 && (
          <AddBusiness
            totalSteps={steps.length}
            activeStepNumber={activeStepNumber}
            setActiveStepNumber={setActiveStepNumber}
          />
        )}
        {parseInt(activeStepNumber, 10) === 2 && (
          <CustomAttribute
            businessId={userBusinessDetail?.id}
            businessTypeId={userBusinessDetail?.businessTypeId}
            totalSteps={steps.length}
            activeStepNumber={activeStepNumber}
            setActiveStepNumber={setActiveStepNumber}
          />
        )}
        {parseInt(activeStepNumber, 10) === 3 && (
          <div className="text-left">
            <h3 className="text-24 font-bold mb-12">
              {t('manageBusiness.addBusinessSteps.reviewMode.pageTitle')}
            </h3>
            <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
              {t('manageBusiness.addBusinessSteps.reviewMode.pageDesc')}
            </Typography>
            <ReviewMode
              businessId={userBusinessDetail?.id}
              businessTypeId={userBusinessDetail?.businessTypeId}
              totalSteps={steps.length}
              activeStepNumber={activeStepNumber}
              setActiveStepNumber={setActiveStepNumber}
            />
          </div>
        )}
        {parseInt(activeStepNumber, 10) === 4 && (
          <AmazonProduct
            businessId={userBusinessDetail?.id}
            businessTypeId={userBusinessDetail?.businessTypeId}
            totalSteps={steps.length}
            activeStepNumber={activeStepNumber}
            setActiveStepNumber={setActiveStepNumber}
          />
        )}
        {parseInt(activeStepNumber, 10) === 5 && (
          <FlipkartProduct
            businessId={userBusinessDetail?.id}
            businessTypeId={userBusinessDetail?.businessTypeId}
            totalSteps={steps.length}
            activeStepNumber={activeStepNumber}
          />
        )}
      </div>
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ManageBusinessOnline);

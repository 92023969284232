import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { MenuItem, Select } from '@mui/material';
import { t } from 'i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function ReviewSentimentChart(props) {
  const { reviewSentiment, reviewPlatformId, placeId, sizes, dataLabel, useArea } = props;
  const [reviewsSentimentBarData, setReviewsSentimentBarData] = useState();
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [planStatus, setPlanStatus] = useState();
  const [reviewSentimentId, setReviewSentimentId] = useState(7);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getSentimentDataV1,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await getDataToServer(payload);
          const data = result?.getSentimentDataV1?.data?.reviewsSentimentJsonV1;
          const convertToObject = (arr) => {
            const obj = {};
            arr?.forEach(({ key, value }) => {
              obj[key] = value;
            });
            return obj;
          };
          const keyValueObject = convertToObject(data);
          setReviewsSentimentBarData(keyValueObject || {});

          const totalReviewCount = data?.find((item) => item.key === 'totalCount')?.value;
          setPlanStatus(result?.getSentimentDataV1?.status);

          setTotalCount(totalReviewCount || 0);
          const filterTotalCount = data?.filter((item) => item.key !== 'totalCount');
          if (filterTotalCount) {
            setNewGraphData(
              [
                filterTotalCount?.find((item) => item.key === 'negative')?.value,
                filterTotalCount?.find((item) => item.key === 'neutral')?.value,
                filterTotalCount?.find((item) => item.key === 'positive')?.value,
              ] || []
            );
          }
        } else if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setPlanStatus('');
          setNewGraphData([]);
          setReviewsSentimentBarData({});
          setTotalCount(0);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    selectedReviewSentimentId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function getChartSimple(data, business, index, color, types, totalReview) {
    const chart = {
      series: data,
      options: {
        labels: business,
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: sizes,
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Reviews',
                  fontSize: '22px',
                  fontWeight: 600,
                  value: '500',
                  color: '#373d3f',
                  formatter() {
                    return selectedReviewSentimentId === 7
                      ? formatRating(props?.totalReview)
                      : formatRating(totalCount);
                  },
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          show: false,
          width: '100%',
          markers: {
            radius: 12,
          },
        },
        colors: color,
        dataLabels: {
          enabled: dataLabel === 'true',
        },
      },
    };
    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        {chart?.plotOptions?.total?.formatter}
        <ReactApexChart
          key={totalReview}
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="230px"
          width="100%"
        />
      </div>
    );
  }

  function formatRating(value) {
    return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
  }

  const filteredReviewSentiment = reviewSentiment?.filter((reviewSentimentPeriod) =>
    [7, 5, 4, 2]?.includes(reviewSentimentPeriod.id)
  );

  const handleSentimentClick = (id) => {
    setReviewSentimentId(id);
    setSelectedReviewSentimentId(id);
  };
  console.log(reviewsSentimentBarData);
  return (
    <>
      {((planStatus !== 403 &&
        !userCurrentBusinessDetails?.isOnlineBusiness &&
        useArea === 'metrics') ||
        parseInt(reviewPlatformId, 10) === 7) && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[7, 5, 4, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
      )}
      {useArea === 'dashboard' && (
        <div className="flex items-center justify-between mb-28">
          <h3 className="text-18 font-bold">{t('metrics.reviewSentiment')}</h3>
          <Select
            className="min-w-112 max-w-112"
            size="small"
            value={reviewSentimentId}
            onChange={(e) => handleSentimentClick(e.target.value)}
          >
            {filteredReviewSentiment?.length > 0 &&
              filteredReviewSentiment?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
      )}
      {reviewsSentimentBarData?.totalCount ? (
        <>
          <div
            className={`${
              reviewsSentimentBarData &&
              useArea === 'dashboard' &&
              'flex items-center justify-center'
            }`}
          >
            <div>
              {reviewsSentimentBarData &&
                getChartSimple(
                  newGraphData,
                  ['Negative', 'Neutral', 'Positive'],
                  2,
                  ['#F2B443', '#3E8CF3', '#67DF9D'],
                  'donut',
                  formatRating(totalCount)
                )}
            </div>
            {reviewsSentimentBarData ? (
              <>
                {useArea === 'metrics' && (
                  <>
                    <li className="flex items-center justify-between border-b-1 border-dashed border-grey-400 pb-12 mb-12">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#67DF9D] rounded-full mr-14 relative top-3" />
                        Positive
                      </span>
                      <span className="text-18 font-semibold">
                        {reviewsSentimentBarData?.positive}%
                      </span>
                    </li>
                    <li className="flex items-center justify-between border-b-1 border-dashed border-grey-400 pb-12 mb-12">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#3E8CF3] rounded-full mr-14 relative top-3" />
                        Neutral
                      </span>
                      <span className="text-18 font-semibold">
                        {reviewsSentimentBarData?.neutral}%
                      </span>
                    </li>
                    <li className="flex items-center justify-between ">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#F2B443] rounded-full mr-14 relative top-3" />
                        Negative
                      </span>
                      <span className="text-18 font-semibold">
                        {reviewsSentimentBarData?.negative}%
                      </span>
                    </li>
                  </>
                )}
                {useArea === 'dashboard' && (
                  <div>
                    <li className="flex items-center justify-between pb-12 mb-12">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#67DF9D] rounded mr-14 relative top-3" />
                        Positive
                      </span>
                    </li>
                    <li className="flex items-center justify-between pb-12 mb-12">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#3E8CF3] rounded mr-14 relative top-3" />
                        Neutral
                      </span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span className="text-16 font-medium">
                        <span className="w-16 h-16 inline-block bg-[#F2B443] rounded mr-14 relative top-3" />
                        Negative
                      </span>
                    </li>
                  </div>
                )}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ReviewSentimentChart;

import UsersConfig from './users/UsersConfig';
import dashboardsConfigs from './dashboards/dashboardsConfigs';
import BusinessConfig from './business/BusinessConfig';
import TabletVideosConfig from './tabletVideos/TabletVideosConfig';
import ReviewPlatformConfig from './reviewPlatform/ReviewPlatformConfig';
import salesConfig from './sales/SalesConfig';
import appWebsiteConfig from './appWebsite/AppWebsiteConfig';
import UsersFeedbackConfig from './userFeedback/UserFeedbackConfig';
import ComparePlanAndFeatureConfig from './comparePlanAndFeature/ComparePlanAndFeatureConfig';
import QueriesConfig from './queries/QueriesConfig';
import PricingCatalogueConfig from './pricingCatalogue/PricingCatalogueConfig';
import AppPromotionSliderConfig from './appPromotionSlider/AppPromotionSliderConfig';
import NegativeReviewsConfig from './negativeReviews/NegativeReviewsConfig';
import OrderedQrcodeConfig from './orderedQrcode/OrderedQrcodeConfig';
import appMaintenanceConfig from './appMaintenance/AppMaintenanceConfig';
import partnersConfig from './partners/PartnersConfig';

const adminConfigs = [
  UsersConfig,
  dashboardsConfigs,
  BusinessConfig,
  TabletVideosConfig,
  ReviewPlatformConfig,
  salesConfig,
  appWebsiteConfig,
  UsersFeedbackConfig,
  ComparePlanAndFeatureConfig,
  QueriesConfig,
  PricingCatalogueConfig,
  AppPromotionSliderConfig,
  NegativeReviewsConfig,
  OrderedQrcodeConfig,
  appMaintenanceConfig,
  partnersConfig,
];

export default adminConfigs;

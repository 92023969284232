import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PhoneInput from 'react-phone-input-2';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import FuseLoading from '@fuse/core/FuseLoading';
import CircularProgress from '@mui/material/CircularProgress';
import history from '@history';
import { useSnackbar } from 'notistack';
import appWebsiteData from '../../query/appWebsite';
import queryData from '../../query/common';
import {
  checkDomainAvailableOnRoute53,
  domainRegistration,
  getDomainAndDeploymentStatus,
  resetAppWebsite,
  getDnsStatus,
  getDomainDns,
  getCountries,
  getStates,
} from './store/appWebsiteSlice';
import reducer from './store';
import { getCountryData } from '../../../store/userSlice';

const defaultValues = {
  domain: '',
};

function AppWebsite() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    domain: yup
      .string()
      .required(t('appWebsite.domainForm.validationRequired'))
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-za-z0-9#]+)*\/?(\?[a-za-z0-9-_]+=[a-za-z0-9-%]+&?)?$/,
        t('appWebsite.domainForm.validationFormate')
      ),
  });

  const addBusinessTypeStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.domainRegistrationData?.data?.domainRegistration
  );

  const getDomainAndDeploymentData = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getDomainAndDeploymentStatusData?.data
        ?.getDomainAndDeploymentStatus?.data || []
  );

  const getDomainDnsContent = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getDomainDnsData?.data?.getDomainDns?.data
  );
  const checkDomainAvailableOnRoute53Content = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.checkDomainAvailableOnRoute53Data?.data
        ?.checkDomainAvailableOnRoute53
  );

  const getDnsConnectStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getDnsStatusData?.data?.getDnsStatus
  );

  if (getDnsConnectStatus?.domainRegistrationId) {
    localStorage.setItem('domainRegistrationId', getDnsConnectStatus.domainRegistrationId);
  }

  const [loading, setLoading] = useState(false);
  const [stepsCount, setStepsCount] = useState(0);
  const [verifyRefreshIcon, setVerifyRefreshIcon] = useState(false);
  const [dnsRefreshIcon, setDnsRefreshIcon] = useState(true);
  const [newDomainSteps, setNewDomainSteps] = useState(false);
  const [verifyDomain, setVerifyDomain] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDomain, setCurrentDomain] = useState('');
  const [cloudLoading, setCloudLoading] = useState(false);
  const [checkDomainLoading, setCheckDomainLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(true);

  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    const payload = {
      query: appWebsiteData.getDomainAndDeploymentStatus,
    };
    dispatch(getDomainAndDeploymentStatus(payload));
    return () => {
      dispatch(resetAppWebsite());
    };
  }, [dispatch]);

  const callApi = () => {
    const payload = {
      query: appWebsiteData.getDnsStatus,
    };
    dispatch(getDnsStatus(payload));
  };

  useEffect(() => {
    if (stepsCount === 2) {
      const payload = {
        query: appWebsiteData.getDnsStatus,
      };
      dispatch(getDnsStatus(payload));
    }
  }, [dispatch, stepsCount]);

  useEffect(() => {
    let intervalId;

    if (stepsCount === 2) {
      intervalId = setInterval(callApi, 10000);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [stepsCount]);

  useEffect(() => {
    if (showComponent) {
      if (addBusinessTypeStatus && addBusinessTypeStatus.status === 400) {
        enqueueSnackbar(addBusinessTypeStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setVerifyRefreshIcon(false);
        closeSnackbar(3000);
        reset(defaultValues);
        setOpenDialog(false);
      } else if (addBusinessTypeStatus && addBusinessTypeStatus.status === 403) {
        enqueueSnackbar(addBusinessTypeStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setVerifyRefreshIcon(false);
        closeSnackbar(3000);
        reset(defaultValues);
        setOpenDialog(false);
      } else if (
        (addBusinessTypeStatus && addBusinessTypeStatus?.status === 200) ||
        addBusinessTypeStatus?.status === 201
      ) {
        enqueueSnackbar(addBusinessTypeStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setVerifyRefreshIcon(false);
        setVerifyDomain(true);
        closeSnackbar(3000);
        reset(defaultValues);
        setOpenDialog(false);
        if (addBusinessTypeStatus?.status === 201) {
          setVerifyDomain(true);
        }
        const payload = {
          query: appWebsiteData.getDomainDns,
          variables: { domainName: currentDomain },
        };
        dispatch(getDomainDns(payload));
      }
    }
  }, [
    reset,
    dispatch,
    addBusinessTypeStatus,
    enqueueSnackbar,
    closeSnackbar,
    showComponent,
    currentDomain,
  ]);

  useEffect(() => {
    if (stepsCount !== 2) {
      if (getDnsConnectStatus && getDnsConnectStatus.status === 400) {
        enqueueSnackbar(getDnsConnectStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setCloudLoading(false);
      } else if (getDnsConnectStatus && getDnsConnectStatus.status === 500) {
        setStepsCount(4);
        enqueueSnackbar(getDnsConnectStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setCloudLoading(false);
      } else if (getDnsConnectStatus && getDnsConnectStatus.status === 404) {
        setStepsCount(4);
        enqueueSnackbar(getDnsConnectStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      } else if (
        getDnsConnectStatus &&
        getDnsConnectStatus.status === 200 &&
        getDnsConnectStatus.availability === true
      ) {
        setStepsCount(4);
        closeSnackbar(3000);
        setCloudLoading(false);
      } else if (
        getDnsConnectStatus &&
        getDnsConnectStatus.status === 200 &&
        getDnsConnectStatus.availability === false
      ) {
        setStepsCount(4);
        closeSnackbar(3000);
        setCloudLoading(false);
      }
    }
  }, [dispatch, getDnsConnectStatus, enqueueSnackbar, closeSnackbar, stepsCount]);

  useEffect(() => {
    if (
      checkDomainAvailableOnRoute53Content &&
      checkDomainAvailableOnRoute53Content.status === 400
    ) {
      enqueueSnackbar(checkDomainAvailableOnRoute53Content.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setCheckDomainLoading(false);
    } else if (
      checkDomainAvailableOnRoute53Content &&
      checkDomainAvailableOnRoute53Content.status === 200
    ) {
      enqueueSnackbar(checkDomainAvailableOnRoute53Content.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setCheckDomainLoading(false);
    }
  }, [checkDomainAvailableOnRoute53Content, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (
      getDomainAndDeploymentData?.certificateActivated?.isCompleted &&
      !getDomainAndDeploymentData?.distributionCreated?.isCompleted &&
      !getDomainAndDeploymentData?.isThemeSelect?.isCompleted
    ) {
      setStepsCount(4);
    } else if (getDomainAndDeploymentData?.isThemeSelect?.isCompleted) {
      history.push('/select-theme');
    }
  }, [
    getDomainAndDeploymentData?.certificateActivated?.isCompleted,
    getDomainAndDeploymentData?.distributionCreated?.isCompleted,
    getDomainAndDeploymentData?.isThemeSelect?.isCompleted,
  ]);

  if (loading) {
    return <FuseLoading />;
  }

  if (getDomainAndDeploymentData) {
    if (
      getDomainAndDeploymentData?.certificateActivated?.isCompleted &&
      getDomainAndDeploymentData?.certificateCreated?.isCompleted &&
      getDomainAndDeploymentData?.distributionCreated?.isCompleted &&
      stepsCount !== 4
    ) {
      setStepsCount(4);
    } else if (getDomainAndDeploymentData?.isHaveDomain?.haveDomain) {
      if (
        getDomainAndDeploymentData?.hostedZoneCreated?.isCompleted &&
        stepsCount !== 2 &&
        !verifyDomain
      ) {
        // setVerifyDomain(true);
        const payload = {
          query: appWebsiteData.getDomainDns,
          variables: { domainName: currentDomain },
        };
        dispatch(getDomainDns(payload));
        setStepsCount(2);
        setVerifyDomain(true);
      }
    }
  }

  function onSubmit(submitData) {
    if (stepsCount === 2) {
      setCurrentDomain(submitData.domain);
      setOpenDialog(true);
    }
    if (stepsCount === 5) {
      setCheckDomainLoading(true);
      const payload = {
        query: appWebsiteData.checkDomainAvailableOnRoute53,
        variables: { domainName: submitData.domain },
      };
      dispatch(checkDomainAvailableOnRoute53(payload));
    }
  }

  function confirmDomain() {
    setVerifyRefreshIcon(true);
    const payload = {
      query: appWebsiteData.domainRegistration,
      variables: { isHaveDomain: true, domainName: currentDomain },
    };
    dispatch(domainRegistration(payload));
  }

  function handleCopyClick(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar('Text copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCopyClick();
    }
  };

  function connectForDNS() {
    setStepsCount(3);
    setCloudLoading(true);
    const payload = {
      query: appWebsiteData.getDnsStatus,
    };
    dispatch(getDnsStatus(payload));
  }

  function makeYourDomain() {
    setShowComponent(!showComponent);
  }

  return (
    <div className="p-20 lg:p-40 h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className={`${
          newDomainSteps
            ? 'text-left '
            : !showComponent
            ? 'text-left'
            : 'flex flex-1 items-center justify-center h-full text-center mx-auto h-fit'
        }`}
      >
        <div>
          {stepsCount === 0 && (
            <div>
              <img
                src="/assets/images/business/web_design.png"
                className="img-fluid mx-auto max-w-160 pb-40"
                alt="..."
              />
              <h5 className="block text-24 font-bold text-black">
                {t('appWebsite.createWebsite')}
              </h5>
              <Typography
                color="textPrimary"
                variant="h5"
                className="text-16 py-12 font-semibold max-w-640 text-center mx-auto"
              >
                {t('appWebsite.createWebsiteShortDesc')}
              </Typography>
              <div className="text-center">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                  aria-label="Search"
                  type="button"
                  size="medium"
                  onClick={() => setStepsCount(1)}
                >
                  {t('appWebsite.createWebsite')}
                </Button>
              </div>
            </div>
          )}
          {stepsCount === 1 && (
            <div>
              <img
                src="/assets/images/business/question_mark.png"
                className="img-fluid mx-auto max-w-200 pb-40"
                alt="..."
              />
              <Typography
                color="textPrimary"
                variant="h5"
                className="text-20 py-12 font-semibold max-w-640 text-center mx-auto"
              >
                {t('appWebsite.askDomain')}
              </Typography>
              <div className="text-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg mr-36"
                  aria-label="Search"
                  type="button"
                  size="medium"
                  onClick={() => {
                    setStepsCount(5);
                    setNewDomainSteps(true);
                  }}
                >
                  {t('appWebsite.button.no')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                  aria-label="Search"
                  type="button"
                  size="medium"
                  onClick={() => setStepsCount(2)}
                >
                  {t('appWebsite.button.yes')}
                </Button>
              </div>
            </div>
          )}
          {stepsCount === 2 && (
            <>
              {!verifyDomain ? (
                <div>
                  <h5 className="block text-24 font-bold text-black">
                    {t('appWebsite.verifyDomain')}
                  </h5>
                  <img
                    src="/assets/images/business/verify_domain.png"
                    className="img-fluid mx-auto pb-40"
                    alt="..."
                  />
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-360 mx-auto mb-36"
                  >
                    {t('appWebsite.verifyDomainDesc')}
                  </Typography>
                  <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col md:overflow-hidden max-w-400 mx-auto text-left"
                  >
                    <div className="block">
                      <InputLabel
                        htmlFor="domain"
                        className="text-14 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.domainForm.label')}
                      </InputLabel>
                      <Controller
                        control={control}
                        name="domain"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder={t('appWebsite.domainForm.placeHolder')}
                            className="bg-white"
                            size="small"
                            id="domain"
                            error={!!errors.domain}
                            helperText={errors?.domain?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <Button
                      className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                      variant="contained"
                      color="secondary"
                      type="submit"
                      size="medium"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                    >
                      {t('appWebsite.domainForm.button')}
                    </Button>
                  </form>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-14 py-12 font-medium max-w-480 mx-auto mt-20"
                  >
                    {t('appWebsite.domainForm.desc')}
                  </Typography>
                </div>
              ) : (
                <div className="text-center mx-auto">
                  <h5 className="block text-24 font-bold text-black">
                    {t('appWebsite.DNSVerification.title')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640 text-center mx-auto mb-48 mt-30"
                  >
                    {t('appWebsite.DNSVerification.shortDesc')}
                  </Typography>

                  <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-48 mx-auto">
                    <div className="bg-white rounded-lg text-left">
                      <h5 className="block text-18 font-semibold text-black border-b-2 border-b-grey-300 border-solid p-24 m-0">
                        {t('appWebsite.DNSVerification.suggested_Nameservers_Title')}
                      </h5>
                      <div className="p-24">
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          className="text-16 py-12 font-semibold max-w-640 mx-auto"
                        >
                          {t('appWebsite.DNSVerification.step_1')}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          className="text-16 py-12 font-semibold max-w-640 mx-auto"
                        >
                          {t('appWebsite.DNSVerification.step_2')}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          className="text-16 py-12 font-semibold max-w-640 mx-auto"
                        >
                          {t('appWebsite.DNSVerification.step_3')}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          className="text-16 py-12 font-semibold max-w-640 mx-auto"
                        >
                          {t('appWebsite.DNSVerification.step_4')}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          className="text-16 py-12 font-semibold max-w-640 mx-auto"
                        >
                          {t('appWebsite.DNSVerification.step_5')}
                        </Typography>
                      </div>
                    </div>

                    <div className="bg-white rounded-lg">
                      <h5 className="block text-18 font-semibold text-black border-b-2 border-b-grey-300 border-solid p-24 m-0 text-left">
                        {t('appWebsite.DNSVerification.suggested_Nameservers_Title')}
                      </h5>
                      <div className="p-24">
                        <div className="text-left">
                          <span className="text-darkgreen font-semibold text-16 inline-block">
                            {getDomainDnsContent?.domainName}
                          </span>

                          {getDnsConnectStatus?.dnsValidation === 'PENDING_VALIDATION' ? (
                            <span
                              className="inline-block rounded-md px-10 py-5 bg-grey-100 font-semibold mx-14 cursor-pointer"
                              aria-hidden="true"
                            >
                              {dnsRefreshIcon && (
                                <CircularProgress
                                  size={16}
                                  className="text-gray mx-auto align-middle"
                                />
                              )}{' '}
                              {t('appWebsite.DNSVerification.connect')}
                            </span>
                          ) : getDnsConnectStatus?.dnsValidation === 'ISSUED' ? (
                            <span
                              className="inline-block rounded-md px-10 py-5 bg-light-green-100 text-green font-semibold mx-14 cursor-pointer"
                              aria-hidden="true"
                            >
                              <Icon className="text-16 align-middle relative -top-2 mr-3">
                                check_circle
                              </Icon>
                              {t('appWebsite.DNSVerification.done')}
                            </span>
                          ) : getDnsConnectStatus?.dnsValidation === 'VALIDATION_TIMED_OUT' ||
                            getDomainDnsContent?.dnsValidation === 'EXPIRED' ? (
                            <span
                              className="inline-block rounded-md px-10 py-5 bg-red-100 text-red font-semibold mx-14 cursor-pointer"
                              aria-hidden="true"
                            >
                              <Icon className="text-16 align-middle relative -top-2 mr-3">
                                error
                              </Icon>
                              {t('appWebsite.DNSVerification.error')}
                            </span>
                          ) : (
                            <span
                              className="inline-block rounded-md px-10 py-5 bg-grey-100 font-semibold mx-14 cursor-pointer"
                              aria-hidden="true"
                            >
                              {dnsRefreshIcon && (
                                <CircularProgress
                                  size={16}
                                  className="text-gray mx-auto align-middle"
                                />
                              )}{' '}
                              {t('appWebsite.DNSVerification.connect')}
                            </span>
                          )}
                        </div>
                        <div className="text-left font-semibold text-16 my-20">
                          <span className="inline-block">Type -</span>
                          <span className="inline-block mx-12">CNAME</span>
                        </div>
                        <div className="text-left">
                          <h6 className="block text-16 font-semibold text-black mb-20">
                            {t('appWebsite.DNSVerification.suggested_Nameservers')}
                          </h6>
                          <ul className="bg-grey-100 px-20 py-14 leading-loose font-medium text-darkgreen rounded-lg">
                            {getDomainDnsContent &&
                              getDomainDnsContent?.dns?.length > 0 &&
                              getDomainDnsContent?.dns?.map((dnsName, i) => (
                                <li key={i}>
                                  <span
                                    onClick={() => handleCopyClick(dnsName)}
                                    onKeyDown={handleKeyPress}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <span className="font-semibold text-16 text-darkgreen">
                                      {dnsName}
                                    </span>
                                    <Icon className="text-16 text-black float-right mt-6">
                                      copy
                                    </Icon>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {getDnsConnectStatus?.dnsValidation === 'PENDING_VALIDATION' && (
                    <div className="text-16 font-semibold mt-40">
                      {' '}
                      <Icon className="text-24 align-middle">hourglass_bottom</Icon>{' '}
                      {t('appWebsite.DNSVerification.shortContent')}
                    </div>
                  )}
                  {getDnsConnectStatus?.dnsValidation === 'ISSUED' && (
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                        aria-label="Search"
                        onClick={() => setStepsCount(4)}
                        type="button"
                        size="medium"
                      >
                        {t('appWebsite.DNSVerification.next')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {stepsCount === 3 && (
            <>
              {cloudLoading && (
                <div>
                  <div className="relative inline-block mb-40 lg:mt-96">
                    <img
                      src="/assets/images/business/cloud.png"
                      className="img-fluid mx-auto"
                      alt="..."
                    />
                    <img
                      src="/assets/images/business/loader_cloud.png"
                      className="img-fluid mx-auto absolute top-72 left-0 right-0 animate-spin"
                      alt="..."
                    />
                  </div>
                  <h5 className="block text-24 font-bold text-black">
                    {t('appWebsite.DNSPropagation.title')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-lg"
                  >
                    {t('appWebsite.DNSPropagation.shortDesc')}
                  </Typography>
                </div>
              )}
            </>
          )}
          {stepsCount === 4 && (
            <>
              {getDnsConnectStatus &&
              (getDnsConnectStatus.status === 200 || getDnsConnectStatus.status === 500) &&
              getDnsConnectStatus.availability === false ? (
                <div>
                  <img
                    src="/assets/images/business/warning.png"
                    className="img-fluid mx-auto max-w-160 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('appWebsite.transferFailed.title')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-480 mx-auto text-center"
                  >
                    {t('appWebsite.transferFailed.shortDesc')}
                  </Typography>
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                      aria-label="Search"
                      type="button"
                      size="medium"
                      onClick={() => connectForDNS()}
                    >
                      {t('appWebsite.transferFailed.button')}
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <img
                    src="/assets/images/business/created_successfully.png"
                    className="img-fluid mx-auto max-w-256 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('appWebsite.domainConnected.title')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640 mx-auto text-center"
                  >
                    {t('appWebsite.domainConnected.shortDesc')}
                  </Typography>
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                      aria-label="Search"
                      type="button"
                      size="medium"
                      onClick={() => history.push('/select-theme')}
                    >
                      {t('appWebsite.domainConnected.button')}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div>
          {stepsCount === 5 && (
            <>
              {showComponent ? (
                <>
                  <div className="mx-auto" style={{ maxWidth: '1046px' }}>
                    <h5 className="block text-24 font-bold text-black mb-32 text-center">
                      {t('appWebsite.newDomain.title')}
                    </h5>
                    <form
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex flex-col md:overflow-hidden mx-auto text-left mb-40"
                    >
                      <div className="flex">
                        <Controller
                          control={control}
                          name="domain"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              placeholder={t('appWebsite.newDomain.placeHolder')}
                              className="bg-white"
                              size="small"
                              id="domain"
                              error={!!errors.domain}
                              helperText={errors?.domain?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          )}
                        />
                        <Button
                          className="sm:min-w-160 mx-auto md:text-16 font-semibold disabled:text-black rounded-lg ml-20"
                          variant="contained"
                          color="secondary"
                          type="submit"
                          size="medium"
                          disabled={_.isEmpty(dirtyFields) || !isValid}
                        >
                          {t('appWebsite.newDomain.button')}
                        </Button>
                      </div>
                    </form>
                    {checkDomainLoading ? (
                      <span className="inline-block font-semibold text-16">
                        {t('appWebsite.newDomain.loading')}
                      </span>
                    ) : (
                      <>
                        {checkDomainAvailableOnRoute53Content &&
                        checkDomainAvailableOnRoute53Content?.data &&
                        checkDomainAvailableOnRoute53Content?.data?.availability === 'AVAILABLE' ? (
                          <div className="bg-white px-28 py-20 rounded-lg">
                            <h3 className="text-darkgreen text-16 font-semibold mb-20 text-left">
                              {t('appWebsite.newDomain.exactMatch')}
                            </h3>
                            <div className="flex justify-between items-center">
                              <span className="inline-block font-semibold text-16">
                                {checkDomainAvailableOnRoute53Content?.data?.domainName}
                              </span>
                              <Button
                                className="sm:min-w-144 md:text-14 font-medium disabled:text-black rounded-lg ml-20"
                                variant="contained"
                                color="secondary"
                                type="button"
                                size="small"
                                onClick={() => {
                                  localStorage.setItem(
                                    'searchDomain',
                                    checkDomainAvailableOnRoute53Content?.data?.domainName
                                  );
                                  setShowComponent(!showComponent);
                                }}
                              >
                                {t('appWebsite.newDomain.makeItYours')}
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {checkDomainAvailableOnRoute53Content &&
                              checkDomainAvailableOnRoute53Content?.data &&
                              checkDomainAvailableOnRoute53Content?.data?.availability ===
                                'UNAVAILABLE' && (
                                <div className="bg-white px-28 py-20 rounded-lg">
                                  <h3 className="text-darkgreen text-16 font-semibold mb-20 text-left">
                                    {t('appWebsite.newDomain.exactMatch')}
                                  </h3>
                                  <div className="flex justify-between items-center">
                                    <span className="inline-block font-semibold text-16">
                                      {t('appWebsite.newDomain.UnAvailable')}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <DomainRegister />
              )}
            </>
          )}
        </div>
      </motion.div>

      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-28 max-w-400 border-t-8 border-solid border-red',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 text-center">
            <img
              src="/assets/images/business/attention.png"
              className="img-fluid mx-auto w-136 h-136"
              alt="..."
            />
            <Typography className="text-20 font-semibold text-black text-center w-full pb-10">
              {t('appWebsite.attentionMessage')}
            </Typography>
          </DialogTitle>
          <DialogContent className="p-0 mb-24">
            <DialogContentText
              id="alert-dialog-description"
              className="text-16 font-medium text-center text-black max-w-sm"
            >
              {t('appWebsite.attentionDesc')}
              <span className="text-red text-20 font-semibold block mt-24">{currentDomain}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              variant="contained"
              color="inherit"
              className="md:text-16 font-medium disabled:text-black rounded-md px-48 pb-6"
              aria-label={t('appWebsite.button.cancel')}
              onClick={() => {
                setOpenDialog(false);
              }}
              type="button"
              size="small"
            >
              {t('appWebsite.button.cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md px-48 pb-6"
              aria-label={t('appWebsite.button.confirm')}
              onClick={() => {
                confirmDomain();
              }}
              disabled={verifyRefreshIcon}
              type="button"
              size="small"
            >
              {t('appWebsite.button.confirm')}
              {verifyRefreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

function DomainRegister() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stateListData = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.appWebsite?.getStatesData?.data?.getStates?.data || ''
  );

  const domainRegistrationStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.appWebsite?.domainRegistrationData?.data?.domainRegistration
  );

  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [stateShortName, setStateShortName] = useState('');
  const [phoneValue, setPhoneValue] = useState();
  const [phone, setPhone] = useState();
  const [phoneValueValid, setPhoneValueValid] = useState(true);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const countriesData = useSelector(getCountryData);

  /**
   * Form Validation Schema
   */

  const defaultValuesDomainRegister = {
    contactType: '',
    firstName: '',
    LastName: '',
    email: '',
    address: '',
    zipCode: '',
    city: '',
  };

  const schema = yup.object().shape({
    contactType: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.contactType.validationMessages.required')
      )
      .matches(
        /^[a-zA-Z ]+$/,
        t('appWebsite.newDomain.registerDomain.formField.contactType.validationMessages.matches')
      ),
    firstName: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.firstName.validationMessages.required')
      )
      .max(15, t('appWebsite.newDomain.registerDomain.formField.firstName.validationMessages.max'))
      .matches(
        /^[a-zA-Z]+$/,
        t('appWebsite.newDomain.registerDomain.formField.firstName.validationMessages.matches')
      ),
    lastName: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.lastName.validationMessages.required')
      )
      .max(15, t('appWebsite.newDomain.registerDomain.formField.lastName.validationMessages.max'))
      .matches(
        /^[a-zA-Z]+$/,
        t('appWebsite.newDomain.registerDomain.formField.lastName.validationMessages.matches')
      ),
    email: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.email.validationMessages.required')
      )
      .email(t('appWebsite.newDomain.registerDomain.formField.email.validationMessages.validEmail'))
      .trim(),
    address: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.address.validationMessages.required')
      ),
    zipCode: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.required')
      )
      .matches(
        /^[0-9]+$/,
        t('appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.matches')
      )
      .test(
        'len',
        `${
          countryId === 2
            ? t(
                'appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.indiaMatch'
              )
            : t(
                'appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.otherMatch'
              )
        }`,
        (val) => {
          if (countryId === 2) {
            return val && val?.toString().length === 6;
          }
          return (val && val?.toString().length === 5) || (val && val?.toString()?.length === 9);
        }
      ),
    city: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.city.validationMessages.required')
      ),
    country: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.country.validationMessages.required')
      )
      .default(),
    state: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.state.validationMessages.required')
      )
      .default(),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValuesDomainRegister,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (countryId) {
      const payloadState = {
        query: queryData.getStates,
        variables: { countryId },
      };
      dispatch(getStates(payloadState));
    }
  }, [dispatch, countryId]);

  useEffect(() => {
    if (stateListData && stateListData.length) {
      setStateList(stateListData);
    } else {
      setStateList();
    }
  }, [stateListData]);

  useEffect(() => {
    if (countryId !== 0) {
      setError('country', {
        undefined,
      });
      clearErrors('country');
      setValue('country', '', { shouldDirty: true, shouldValidate: true });
    }
  }, [countryId, setError, clearErrors, setValue]);

  useEffect(() => {
    if (stateId !== 0) {
      setError('state', {
        undefined,
      });
      clearErrors('state');
      setValue('state', '', { shouldDirty: true, shouldValidate: true });
    }
  }, [stateId, setError, clearErrors, setValue]);

  useEffect(() => {
    if (domainRegistrationStatus && domainRegistrationStatus.status === 400) {
      enqueueSnackbar(domainRegistrationStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
      // setStepsCount(3)
      reset(defaultValuesDomainRegister);
    } else if (domainRegistrationStatus && domainRegistrationStatus.status === 200) {
      enqueueSnackbar(domainRegistrationStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValuesDomainRegister);
      setRefreshIcon(false);
      closeSnackbar(3000);
      localStorage.removeItem('searchDomain');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, dispatch, domainRegistrationStatus, enqueueSnackbar, closeSnackbar]);

  function handleSelectCountry(event) {
    setCountryId(event.target.value);
  }

  function handleSelectState(event) {
    setStateId(event.target.value);
    setStateShortName(event?.target?.value?.shortName);
  }

  function onSubmit(data) {
    setRefreshIcon(true);
    if (countryId === 0) {
      setError('country', {
        type: 'manual',
        message: t(
          'appWebsite.newDomain.registerDomain.formField.country.validationMessages.required'
        ),
      });
      setRefreshIcon(false);
    }
    if (stateId === 0) {
      setError('state', {
        type: 'manual',
        message: t(
          'appWebsite.newDomain.registerDomain.formField.state.validationMessages.required'
        ),
      });
      setRefreshIcon(false);
    }
    if (countryId !== 0 && stateId !== 0 && phoneValue) {
      const payload = {
        query: appWebsiteData.domainRegistration,
        variables: {
          address: data.address,
          city: data.city,
          contactType: data.contactType,
          domainName: localStorage.getItem('searchDomain'),
          email: data.email,
          firstName: data.firstName,
          isHaveDomain: false,
          lastName: data.lastName,
          phoneNumber,
          state: stateShortName,
          zipCode: data.zipCode,
        },
      };
      dispatch(domainRegistration(payload));
    }
  }

  const handlePhoneChange = (event, data) => {
    setPhoneNumber(event?.slice(data?.dialCode?.length));
    setPhoneValue('+1');
    setPhone(data);
    if (event.slice(data?.dialCode?.length)) {
      setPhoneValueValid(validatePhoneNumber(event?.slice(data?.dialCode?.length)));
    }
  };

  const validatePhoneNumber = (phoneValues) => {
    const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    return phoneNumberPattern.test(phoneValues);
  };

  return (
    <div className="">
      {domainRegistrationStatus && domainRegistrationStatus.status === 200 ? (
        <div>
          <img
            src="/assets/images/business/created_successfully.png"
            className="img-fluid mx-auto max-w-256 pb-40 lg:pt-96"
            alt="..."
          />
          <h5 className="block text-24 font-bold text-black text-center">
            {t('appWebsite.domainConnected.title')}
          </h5>
          <Typography
            color="textPrimary"
            variant="h5"
            className="text-16 py-12 font-semibold max-w-640 mx-auto text-center"
          >
            {t('appWebsite.newDomain.registerDomain.shortDesc')}
          </Typography>
          <div className="text-center">
            <Button
              variant="contained"
              color="secondary"
              className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
              aria-label="Search"
              type="button"
              size="medium"
              onClick={() => history.push('/select-theme')}
            >
              {t('appWebsite.domainConnected.button')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {refreshIcon ? (
            <div>
              <img
                src="/assets/images/business/loader.png"
                className="img-fluid mx-auto max-w-160 mb-40 lg:mt-96 animate-spin"
                alt="..."
              />
              <h5 className="block text-24 font-bold text-black text-center">
                {t('appWebsite.newDomain.registerDomain.securingYourDomainTitle')}
              </h5>
              <Typography
                color="textPrimary"
                variant="h5"
                className="text-16 py-12 font-semibold max-w-640 mx-auto text-center"
              >
                {t('appWebsite.newDomain.registerDomain.securingYourDomainDesc')}
              </Typography>
            </div>
          ) : (
            <>
              <div className="items-center mb-36 text-center">
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="text-16 md:text-24 font-bold mb-20"
                >
                  {t('appWebsite.newDomain.registerDomain.title')}
                </Typography>
              </div>
              <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full mt-32"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:mr-14">
                    <div className="block">
                      <InputLabel
                        htmlFor="contactType"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.contactType.name')}
                      </InputLabel>
                      <Controller
                        name="contactType"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.contactType}
                            helperText={errors?.contactType?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="firstName"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.firstName.name')}
                      </InputLabel>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="lastName"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.lastName.name')}
                      </InputLabel>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="organization"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.organization.name')}
                      </InputLabel>
                      <Controller
                        name="organization"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="email"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.email.name')}
                      </InputLabel>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <>
                      <InputLabel
                        htmlFor="phoneNumber"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.phoneNumber.name')}
                      </InputLabel>
                      <PhoneInput
                        inputStyle={{
                          width: '100%',
                          height: '40px',
                        }}
                        className="w-full mb-24"
                        inputProps={{
                          required: true,
                        }}
                        value={phoneValue}
                        country="us"
                        onChange={handlePhoneChange}
                      />

                      {!phoneValueValid && (
                        <p className="text-red ml-12 -top-16 relative text-md">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </>
                  </div>
                  <div className="lg:w-1/2 lg:ml-14">
                    <div className="block">
                      <InputLabel
                        htmlFor="address"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.address.name')}
                      </InputLabel>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.address}
                            helperText={errors?.address?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>

                    <div className="w-full">
                      <InputLabel
                        htmlFor="country"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.country.name')}
                      </InputLabel>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="mb-24 w-full bg-white"
                            id="country"
                            value={countryId}
                            displayEmpty
                            size="small"
                            error={!!errors.country}
                            onChange={(e) => {
                              handleSelectCountry(e);
                            }}
                          >
                            <MenuItem value={0} disabled>
                              {t(
                                'appWebsite.newDomain.registerDomain.formField.country.selectCountry'
                              )}
                            </MenuItem>
                            {countryList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.country && (
                        <p className="text-red -mt-24 absolute">{errors.country.message}</p>
                      )}
                    </div>
                    {countryId !== 0 ? (
                      <div className="w-full">
                        <InputLabel
                          htmlFor="state"
                          className="text-16 font-medium mb-12 text-grey-900"
                        >
                          {t('appWebsite.newDomain.registerDomain.formField.state.name')}
                        </InputLabel>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="mb-24"
                              sx={{
                                '& .MuiInputBase-input': {
                                  background: 'white',
                                },
                              }}
                              value={stateId}
                              size="small"
                              id="state"
                              onChange={(e) => {
                                handleSelectState(e);
                              }}
                              required
                              fullWidth
                            >
                              <MenuItem value={0} disabled>
                                {t(
                                  'appWebsite.newDomain.registerDomain.formField.state.selectState'
                                )}
                              </MenuItem>
                              {stateList?.map((option) => (
                                <MenuItem key={option.id} value={option}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.state && (
                          <p className="text-red -mt-24 absolute">{errors.state.message}</p>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="block">
                      <InputLabel
                        htmlFor="city"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.city.name')}
                      </InputLabel>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.city}
                            helperText={errors?.city?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="zipCode"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.formField.zipCode.name')}
                      </InputLabel>
                      <Controller
                        name="zipCode"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            error={!!errors.zipCode}
                            helperText={errors?.zipCode?.message}
                            type="number"
                            size="small"
                            value={undefined}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="yourDomain"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.newDomain.registerDomain.yourDomain')}
                      </InputLabel>
                      <TextField
                        className="mb-24"
                        sx={{
                          '& .MuiInputBase-input': {
                            background: 'white',
                          },
                        }}
                        type="text"
                        disabled
                        size="small"
                        value={localStorage.getItem('searchDomain')}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="secondary"
                  className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                  aria-label="Register"
                  disabled={
                    _.isEmpty(dirtyFields) ||
                    !isValid ||
                    refreshIcon ||
                    !phoneValueValid ||
                    phoneNumber < 10
                  }
                  type="submit"
                  size="small"
                >
                  {t('appWebsite.newDomain.registerDomain.next')}{' '}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withReducer('appsWebsitesReducer', reducer)(AppWebsite);

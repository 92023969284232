import Home from './home';
import Onboarding from './onboarding';
import Offers from './offers';
import Categories from './categories';
import Feedback from './feedback';
import Menu from './menu';
import PopularAndRecommended from './popularAndRecommended';

const cmsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'cms-app/home',
      element: <Home />,
    },
    {
      path: 'cms-app/onboarding',
      element: <Onboarding />,
    },
    {
      path: 'cms-app/offers',
      element: <Offers />,
    },
    {
      path: 'cms-app/categories',
      element: <Categories />,
    },
    {
      path: 'cms-app/menu',
      element: <Menu />,
    },
    {
      path: 'cms-app/feedback',
      element: <Feedback />,
    },
    {
      path: 'cms-app/popular-recommended',
      element: <PopularAndRecommended />,
    },
  ],
};

export default cmsAppConfig;

import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import './MangeBusiness.css';
import { Typography } from '@mui/material';
import TopHeader from './TopHeader';
import BusinessLocation from './BusinessLocation';
import CustomAttribute from './CustomAttribute';
import ProductsServices from './ProductsServices';
import ReviewMode from './ReviewMode';
import manageBusinessData from '../../query/manageBusiness';
import userService from '../../../services';
import reducer from './store';
import { getDecryptData, handleApiRequest } from '../../common/common';
import StaffMember from './StaffMember';
import Loading from './Loading';
import { getReviewPlaterFormData, getUserPackageData } from '../../../store/userSlice';

function ManageBusiness() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeStepNumber, setActiveStepNumber] = useState(
    localStorage.getItem('activeStepNumber')
  );
  const [reviewPlateFormData, setReviewPlateFormData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const getUserSubscriptionPackageDetails = useSelector(getUserPackageData);
  const reviewPlateFormsData = useSelector(getReviewPlaterFormData);

  const steps = [
    {
      id: 1,
      label: `${t('manageBusiness.addBusinessSteps.businessLocation.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.businessLocation.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/business_location_icon.svg',
    },
    {
      id: 2,
      label: `${t('manageBusiness.addBusinessSteps.customAttribute.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.customAttribute.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/custom_attribute_icon.svg',
    },
    {
      id: 3,
      label: `${t('manageBusiness.addBusinessSteps.productServices.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.productServices.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/product_services_icon.svg',
    },
    {
      id: 4,
      label: `${t('manageBusiness.addBusinessSteps.reviewMode.title')}`,
      desc: `${t('manageBusiness.addBusinessSteps.reviewMode.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/review_mode_icon.svg',
    },
    {
      id: 5,
      label: `${t('manageBusiness.addBusinessSteps.staffMember.staff')}`,
      desc: `${t('manageBusiness.addBusinessSteps.staffMember.desc')}`,
      icon: 'https://quick-review.s3.amazonaws.com/business_flow/staff_member_icon.svg',
    },
  ];

  useEffect(() => {
    const excludedPlatforms = ['amazon', 'flipkart', 'quickreviews', 'trustpilot'];
    const filteredData = reviewPlateFormsData?.filter(
      (platform) => !excludedPlatforms?.includes(platform?.name)
    );
    const updatedData = filteredData?.map((platform) => ({
      ...platform,
      isBusinessDetailsAdded: false,
    }));
    const googlePlatform = updatedData?.find((platform) => platform.name === 'google');
    const otherPlatforms = updatedData?.filter((platform) => platform.name !== 'google');
    const finalData = googlePlatform ? [googlePlatform, ...otherPlatforms] : otherPlatforms;
    setReviewPlateFormData(finalData);
  }, [reviewPlateFormsData]);

  if (pageLoading) {
    return <Loading />;
  }
  return (
    <div>
      {parseInt(getUserSubscriptionPackageDetails?.packageTypeId, 10) !== 5 ? (
        <>
          <div className="border-b-1 md:py-24 lg:px-28 px-0 py-10 mb-40">
            <Stepper
              activeStep={parseInt(activeStepNumber, 10)}
              className="md:flex block"
              sx={{
                '& .MuiStepConnector-horizontal': { display: 'none' },
                '& .MuiStepIcon-root': { display: 'none' },
              }}
            >
              {steps.map((singleStep, index) => {
                return (
                  <Step
                    key={index}
                    className={`md:w-1/5 md:px-0 flex relative md:my-0 my-20 ${
                      index < activeStepNumber
                        ? 'lg:before:border-b-1 before:border-solid before:border-darkgreen before:absolute before:left-0 before:right-0 before:-bottom-24 before:-mb-1'
                        : ''
                    }`}
                  >
                    <StepLabel>
                      <div className="flex items-center">
                        <span
                          className={`mr-10 flex items-center min-w-52 w-52 h-52 rounded-full border-1 border-solid border-darkgreen text-center ${
                            index < activeStepNumber ? 'bg-darkgreen active-steps' : 'bg-white'
                          }`}
                        >
                          <img src={singleStep.icon} alt="... " className="mx-auto" />
                        </span>
                        <div>
                          <span className="block text-18 font-medium text-black">
                            {singleStep.label}
                          </span>
                          <span className="block text-14 font-medium text-black">
                            {singleStep.desc}
                          </span>
                        </div>
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div className="container px-16 text-left">
            <Typography className="block text-16 font-medium">
              {t('manageBusiness.welcomeMsg')}
            </Typography>
            <span className="block font-semibold text-20 text-darkgreen mt-10 mb-28">
              {t('manageBusiness.step')} {activeStepNumber}/{steps.length}
            </span>
          </div>
        </>
      ) : (
        <TopHeader />
      )}
      <div
        className={`container px-16 ${
          parseInt(getUserSubscriptionPackageDetails?.packageTypeId, 10) === 5
            ? 'flex items-center justify-center h-screen'
            : ''
        }`}
      >
        {parseInt(activeStepNumber, 10) === 1 && (
          <BusinessLocation
            activeStepNumber={activeStepNumber}
            reviewPlateFormData={reviewPlateFormData}
            setActiveStepNumber={setActiveStepNumber}
            setReviewPlateFormData={setReviewPlateFormData}
            packageTypeId={getUserSubscriptionPackageDetails?.packageTypeId}
          />
        )}
        {parseInt(activeStepNumber, 10) === 2 && (
          <CustomAttribute setActiveStepNumber={setActiveStepNumber} />
        )}
        {parseInt(activeStepNumber, 10) === 3 && (
          <div className="text-left">
            <h3 className="text-24 font-bold mb-12">
              {t('manageBusiness.addBusinessSteps.productServices.pageTitle')}
            </h3>
            <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
              {t('manageBusiness.addBusinessSteps.productServices.pageDesc')}
            </Typography>
            <ProductsServices setActiveStepNumber={setActiveStepNumber} />
          </div>
        )}
        {parseInt(activeStepNumber, 10) === 4 && (
          <div className="text-left">
            <h3 className="text-24 font-bold mb-12">
              {t('manageBusiness.addBusinessSteps.reviewMode.pageTitle')}
            </h3>
            <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
              {t('manageBusiness.addBusinessSteps.reviewMode.pageDesc')}
            </Typography>
            <ReviewMode setActiveStepNumber={setActiveStepNumber} />
          </div>
        )}
        {parseInt(activeStepNumber, 10) === 5 && <StaffMember />}
      </div>
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ManageBusiness);

import dashboardsConfigs from './dashboards/dashboardsConfigs';
import searchBusinessConfig from './search-business/searchBusinessConfig';
import SelectedBusinessConfig from './selected-business/selectedBusinessConfig';
import VisitedBusinessConfig from './visited-business/visitedBusinessConfig';
import ConvertedBusiness from './converted-business/convertedBusinessConfig';
import DeactivatedAccount from './deactivated-account/deactivatedAccountConfig';

const salesConfigs = [
  dashboardsConfigs,
  searchBusinessConfig,
  SelectedBusinessConfig,
  VisitedBusinessConfig,
  ConvertedBusiness,
  DeactivatedAccount,
];

export default salesConfigs;

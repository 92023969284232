import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  CircularProgress,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import history from '@history';
import { getParamsId } from '../../../common/common';
import promotionQuery from '../../../query/promotion';
import { createPromotion } from '../store/promotionSlice';
import PreviewImages from './PreviewImages';

const LoyaltyPoints = (props) => {
  const {
    promotionApplicableData,
    selectBusiness,
    selectPromotionTypeId,
    previewImages,
    buttonTypeData,
    images,
    setImages,
    loyaltyPointsData,
    loyaltyDurationType,
    promotionApplyTo,
    countryId,
  } = props;
  const { control } = useForm();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [buttonType, setButtonType] = useState(buttonTypeData?.[0]?.key);
  const [applicable, setApplicable] = useState(promotionApplicableData?.[0]?.key);
  const [selectLoyaltyPoints, setSelectLoyaltyPoints] = useState(loyaltyPointsData?.[0].id);
  const [selectedButton, setSelectedButton] = useState(buttonTypeData?.[0]?.type);
  const [selectDuration, setSelectDuration] = useState(loyaltyDurationType?.[0]?.key);
  const [loading, setLoading] = useState(false);
  const [promotionApply, setPromotionApply] = useState(promotionApplyTo?.[0]?.key);
  const [loyalty, setLoyalty] = useState({
    buyQuantity: '',
    buyProductName: '',
    getQuantity: '',
    getProductName: '',
    productName: '',
    getPoints: '',
    points: '',
    minimumPurchaseAmount: '',
  });

  const createPromotionWithPayload = async (payload) => {
    const result = await dispatch(createPromotion(payload));
    setRefreshIcon(false);
    await setLoading(false);
    if (result?.payload?.createPromotion?.status === 200) {
      const id = getParamsId();
      if (id) {
        history.push(`promotion/${id}`);
      } else {
        history.push('promotion');
      }
      enqueueSnackbar(result?.payload.createPromotion?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.payload.createPromotion?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleButtonType = (value) => {
    const data = buttonTypeData?.find((item) => item.key === value);
    setButtonType(data?.key);
    setSelectedButton(data?.type);
  };

  const onSubmit = async () => {
    if (!loading) {
      let imageData = {};
      if (typeof images === 'object') {
        imageData = {
          image: images,
        };
      }
      if (typeof images === 'string') {
        imageData = {
          imageUrl: images,
        };
      }
      setLoading(true);
      setRefreshIcon(true);

      const commonVariables = {
        userBusinessId: parseInt(selectBusiness, 10),
        promotionCategoryId: parseInt(selectPromotionTypeId, 10),
        ...imageData,
        buttonName: buttonType,
        applicableFor: applicable,
        loyaltyPointsTypeId: parseInt(selectLoyaltyPoints, 10),
      };

      if (
        selectLoyaltyPoints === 1 &&
        promotionApply &&
        loyalty?.getPoints &&
        promotionApply === 'all_products'
      ) {
        const obj = {
          htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
          </head>
          <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
              <div style="margin: 0 auto; padding: 20px;">
                   <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                      <div style="padding: 25px;">
                          <div style="border: 1px solid white; padding-left: 20px;">
                              <p style="color: white; font-weight: 600; font-size: 20px;">BUY ANY PRODUCT AND <br />
                              EARN ${loyalty?.getPoints} POINTS INSTANTLY!</p>
                              <button style="padding: 8px 25px 8px 25px; background-color: white; border-radius: 4px; border: 1px solid white; margin-bottom: 15px;">${selectedButton}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </body>
          </html>`),
          applyTo: promotionApply,
          points: parseInt(loyalty?.getPoints, 10),
        };
        const payload = {
          query: promotionQuery.createPromotion,
          variables: { ...commonVariables, ...obj },
        };
        await createPromotionWithPayload(payload);
      } else if (
        selectLoyaltyPoints === 1 &&
        promotionApply &&
        promotionApply !== 'all_products' &&
        loyalty?.productName &&
        loyalty?.getPoints
      ) {
        const obj = {
          htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
            </head>
            <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
                <div style="margin: 0 auto; padding: 20px;">
                     <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                        <div style="padding: 25px;">
                            <div style="border: 1px solid white; padding-left: 20px;">
                                <p style="color: white; font-weight: 600; font-size: 20px;">BUY ${loyalty?.productName.toUpperCase()} AND <br />
                                EARN ${loyalty?.getPoints} POINTS INSTANTLY!</p>
                                <button style="padding: 8px 25px 8px 25px; background-color: white; border-radius: 4px; border: 1px solid white; margin-bottom: 15px;">${selectedButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`),
          applyTo: promotionApply,
          productName: loyalty?.productName,
          points: parseInt(loyalty?.getPoints, 10),
        };
        const payload = {
          query: promotionQuery.createPromotion,
          variables: { ...commonVariables, ...obj },
        };
        await createPromotionWithPayload(payload);
      } else if (selectLoyaltyPoints === 2 && loyalty?.minimumPurchaseAmount && loyalty?.points) {
        const obj = {
          htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
            </head>
            <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
                <div style="margin: 0 auto; padding: 20px;">
                       <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                        <div style="padding: 25px;">
                            <div style="border: 1px solid white; padding-left: 20px;">
                                <p style="color: white; font-weight: 600; font-size: 20px;">BUY ${
                                  parseInt(countryId, 10) === 1 ? '$' : '₹'
                                } ${loyalty?.minimumPurchaseAmount} <br />
                                GET ${loyalty?.points} POINTS!</p>
                                <button style="padding: 8px 25px 8px 25px; background-color: white; border-radius: 4px; border: 1px solid white; margin-bottom: 15px;">${selectedButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`),
          minimumPurchaseAmount: parseInt(loyalty?.minimumPurchaseAmount, 10),
          points: parseInt(loyalty?.points, 10),
        };
        const payload = {
          query: promotionQuery.createPromotion,
          variables: { ...commonVariables, ...obj },
        };
        await createPromotionWithPayload(payload);
      } else if (
        selectLoyaltyPoints === 3 &&
        loyalty?.buyQuantity &&
        loyalty?.getQuantity &&
        loyalty?.buyProductName &&
        loyalty?.getProductName &&
        selectDuration
      ) {
        const obj = {
          htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
            </head>
            <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
                <div style="margin: 0 auto; padding: 20px;">
                      <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                        <div style="padding: 25px;">
                            <div style="border: 1px solid white; padding-left: 20px;">
                               <p style="color: white; font-weight: 600; font-size: 20px;">BUY ${
                                 loyalty?.buyQuantity
                               } ${loyalty?.buyProductName} <br /> THIS ${
            loyaltyDurationType.find((item) => item.key === selectDuration)?.type.toUpperCase() ||
            'MONTH'
          } AND GET ${loyalty?.getQuantity} FREE <br /> ${loyalty?.getProductName}</p>
                                <button style="padding: 8px 25px 8px 25px; background-color: white; border-radius: 4px; border: 1px solid white; margin-bottom: 15px;">${selectedButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`),
          buyQuantity: parseInt(loyalty?.buyQuantity, 10),
          getQuantity: parseInt(loyalty?.getQuantity, 10),
          buyProductName: loyalty?.buyProductName,
          getProductName: loyalty?.getProductName,
          duration: selectDuration,
        };
        const payload = {
          query: promotionQuery.createPromotion,
          variables: { ...commonVariables, ...obj },
        };
        await createPromotionWithPayload(payload);
      } else {
        enqueueSnackbar('All fields are required', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };
  const handleCancel = () => {
    const id = getParamsId();
    if (id) {
      history.push(`promotion/${id}`);
    } else {
      history.push('promotion');
    }
  };
  return (
    <div className="md:grid md:grid-cols-8 gap-16">
      <div className="bg-white col-span-5 rounded-md relative rounded-lg shadow p-20 mt-24">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-24 mb-24 font-medium"
        >
          {t('promotion.promotionInfo')}
        </Typography>
        <Typography className="mb-4">{t('promotion.selectLoyaltyType')}</Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectLoyaltyPoints}
          onChange={(event) => setSelectLoyaltyPoints(parseInt(event.target.value, 10))}
        >
          {loyaltyPointsData?.length > 0 &&
            loyaltyPointsData.map((item, index) => (
              <FormControlLabel key={index} value={item.id} control={<Radio />} label={item.type} />
            ))}
        </RadioGroup>
        <div>
          {selectLoyaltyPoints === 1 && (
            <div className="mt-24">
              <Typography className="mb-8">{t('promotion.applyTo')}</Typography>
              <Select
                className="w-full"
                value={promotionApply}
                displayEmpty
                onChange={(e) => {
                  setPromotionApply(e.target.value);
                }}
                size="small"
              >
                {promotionApplyTo?.length > 0 &&
                  promotionApplyTo?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.key}>
                        {item.type}
                      </MenuItem>
                    );
                  })}
              </Select>
              <div className="grid grid-cols-8 mt-12 gap-16">
                {promotionApply !== 'all_products' && (
                  <div className="col-span-4">
                    <Typography className="pb-8">{t('promotion.productName')}</Typography>
                    <TextField
                      className="w-full"
                      type="text"
                      size="small"
                      onChange={(e) =>
                        setLoyalty((prevState) => ({
                          ...prevState,
                          productName: e.target.value,
                        }))
                      }
                    />
                  </div>
                )}
                <div
                  className={`${promotionApply !== 'all_products' ? 'col-span-4' : 'col-span-8'}`}
                >
                  <Typography className="pb-8">{t('promotion.getPoints')}</Typography>
                  <TextField
                    className="w-full"
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setLoyalty((prevState) => ({
                        ...prevState,
                        getPoints: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectLoyaltyPoints === 2 && (
            <div className="mt-24">
              <div>
                <Typography className="pb-8">
                  {t('promotion.minimumPurchaseAmount')}
                  <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  className="w-full"
                  type="number"
                  size="small"
                  onChange={(e) =>
                    setLoyalty((prevState) => ({
                      ...prevState,
                      minimumPurchaseAmount: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mt-8">
                <Typography className="pb-8">{t('promotion.getPoints')}</Typography>
                <TextField
                  className="w-full"
                  type="number"
                  size="small"
                  onChange={(e) =>
                    setLoyalty((prevState) => ({
                      ...prevState,
                      points: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {selectLoyaltyPoints === 3 && (
            <div>
              <div className="grid grid-cols-8 gap-16">
                <div className="col-span-4">
                  <Typography className="pb-8">{t('promotion.buyQuantity')}</Typography>
                  <TextField
                    className="w-full"
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setLoyalty((prevState) => ({
                        ...prevState,
                        buyQuantity: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-span-4">
                  <Typography className="pb-8">{t('promotion.buyProduct')}</Typography>
                  <TextField
                    className="w-full"
                    type="text"
                    size="small"
                    onChange={(e) =>
                      setLoyalty((prevState) => ({
                        ...prevState,
                        buyProductName: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-8 my-12 gap-16">
                <div className="col-span-4">
                  <Typography className="pb-8">{t('promotion.getQuantity')}</Typography>
                  <TextField
                    className="w-full"
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setLoyalty((prevState) => ({
                        ...prevState,
                        getQuantity: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-span-4">
                  <Typography className="pb-8">{t('promotion.getProduct')}</Typography>
                  <TextField
                    className="w-full"
                    type="text"
                    size="small"
                    onChange={(e) =>
                      setLoyalty((prevState) => ({
                        ...prevState,
                        getProductName: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <Typography className="pb-8">{t('promotion.duration')}</Typography>
              <Select
                className="w-full"
                value={selectDuration}
                displayEmpty
                onChange={(e) => {
                  setSelectDuration(e.target.value);
                }}
                size="small"
              >
                {loyaltyDurationType?.length > 0 &&
                  loyaltyDurationType?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.key}>
                        {item.type}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          )}
        </div>
        <div className="py-8">
          <Typography className="pb-8">{t('promotion.applicableFor')}</Typography>
          <Select
            className="w-full"
            value={applicable}
            displayEmpty
            onChange={(e) => {
              setApplicable(e.target.value);
            }}
            size="small"
          >
            {promotionApplicableData?.length > 0 &&
              promotionApplicableData?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.key}>
                    {item?.type}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="py-8">
          <Typography className="pb-8">{t('promotion.buttonName')}</Typography>
          <Select
            className="w-full"
            value={buttonType}
            displayEmpty
            onChange={(e) => {
              handleButtonType(e.target.value);
            }}
            size="small"
          >
            {buttonTypeData?.length > 0 &&
              buttonTypeData?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.key}>
                    {item?.type}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="text-center my-16">
          <Button
            className="bg-white hover:bg-white border border-solid rounded md:px-60 text-darkgreen border-darkgreen"
            type="button"
            onClick={() => handleCancel()}
          >
            {t('promotion.cancel')}
          </Button>
          <Button
            className="text-white bg-darkgreen md:px-40 disabled:text-black disabled:bg-gray rounded hover:bg-darkgreen ml-8"
            type="button"
            disabled={
              (selectLoyaltyPoints === 1 &&
                promotionApply === 'all_products' &&
                !loyalty?.getPoints) ||
              (selectLoyaltyPoints === 1 &&
                promotionApply &&
                promotionApply !== 'all_products' &&
                (!loyalty?.productName || !loyalty?.getPoints)) ||
              (selectLoyaltyPoints === 2 && !loyalty?.minimumPurchaseAmount && !loyalty?.points) ||
              (selectLoyaltyPoints === 3 &&
                (!loyalty?.buyQuantity ||
                  !loyalty?.getQuantity ||
                  !loyalty?.buyProductName ||
                  !loyalty?.getProductName ||
                  !selectDuration)) ||
              refreshIcon
            }
            onClick={() => onSubmit()}
          >
            {t('promotion.createPromotion')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      </div>
      <div className="col-span-3 bg-white rounded-md relative rounded-lg shadow p-20 mt-24">
        <PreviewImages
          previewImages={previewImages}
          setImages={setImages}
          selectPromotionTypeId={selectPromotionTypeId}
          buttonType={selectedButton}
          loyalty={loyalty}
          promotionApply={promotionApply}
          selectLoyaltyPoints={selectLoyaltyPoints}
          selectDuration={loyaltyDurationType.find((item) => item.key === selectDuration)?.type}
          countryId={countryId}
        />
      </div>
    </div>
  );
};

export default LoyaltyPoints;

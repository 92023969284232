const getAllNegativeReview = `
   query($pageNumber: Int, $pageSize: Int, $redirectNegativeReviewId: Int, $searchText: String, $status: String, $sortBy: String, $sortOrder: String){
     getAllNegativeReview(pageNumber: $pageNumber, pageSize: $pageSize, redirectNegativeReviewId: $redirectNegativeReviewId, searchText: $searchText, status: $status, sortBy: $sortBy, sortOrder: $sortOrder){ 
          message
          status
          totalCount
          data{
            id
            cts
            businessId
            name
            phoneNumber
            countryCode
            reason
            status
            autoGeneratedReview
            businessName
          }      
     }
   }
`;

const negativeReviewStatus = `
   query{
     negativeReviewStatus{ 
          message
          status
          data{
               key
               type
          }
     }
   }
`;

const deleteNegativeReview = `
     mutation($redirectNegativeReviewId: Int!){
          deleteNegativeReview(redirectNegativeReviewId: $redirectNegativeReviewId){ 
          message
          status
     }
   }
`;

const negativeReviewsData = {
  getAllNegativeReview,
  negativeReviewStatus,
  deleteNegativeReview,
};

export default negativeReviewsData;

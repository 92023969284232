const getConvertedAccountSalesPerson = `
query getConvertedAccountSalesPerson(
  $pageNumber: Int
  $pageSize: Int
  $searchText: String
) {
  getConvertedAccountSalesPerson(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchText: $searchText
  ) {
    totalCount
    data {
      id
      name
      userId
      email
      city
      zipCode
      phoneNumber {
        countryCode
        isoCode
        phoneNumber
      }
      business_types {
        id
        bType
      }
      countries {
        id
        name
      }
      states {
        id
        name
      }
      review_platforms {
        id
        name
      }
      users {
        isLogin
        demoAccountConvertedCts
        user_package_subscription {
          packages {
            id
            package_types {
              name
            }
          }
        }
      }
    }
    message
    status
  }
}
`;
const resendConvertedUserUsernameAndPasswordBySalesPerson = `
mutation( $data: String!){
  resendConvertedUserUsernameAndPasswordBySalesPerson(data: $data) { 
    message
    status 
  }
}`;

const convertedBusinessData = {
  getConvertedAccountSalesPerson,
  resendConvertedUserUsernameAndPasswordBySalesPerson,
};

export default convertedBusinessData;

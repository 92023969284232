const getAllUsers = `
	query ($pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $userStatusId:[Int!], $sortOrder: OrderDirection, $countryId: [Int!], $packageTypeId:[Int!], $subscriptionCondition: [String!], $subscriptionPeriod: [String!]

 ) {
		getAllUsers(pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder, userStatusId: $userStatusId, countryId: $countryId, packageTypeId: $packageTypeId, subscriptionCondition: $subscriptionCondition, subscriptionPeriod: $subscriptionPeriod) {
      message
      status
      totalCount
      data {
        countries{
          id
          name
        }
        user_businesses{
          name
          id
        }
        user_package_subscription{ 
          isFreeTrial
          expireDate
          packages{
            id
            name
          }
        }
        id
        firstName
        lastName
        middleName
        businessCount
        phoneNumber {
          isoCode
          phoneNumber
          countryCode
        }
        email
        cts
        profileUrl
        stateId
      }  
		}
	}
`;

const getUserDetails = `
	query ($userId: Int!) {
		getUserDetails(userId: $userId) {
      status
      message
      data {
        firstName
        lastName
        city
        middleName
        email
        profileUrl
        userStatusId
        userStatus
        fullName
        countries{
          id
          name
        }
        states{
          id
          name
        }
        phoneNumber {
          countryCode
          isoCode
          phoneNumber
        }
        currency
        isoCode
        countryCode
        planName
        packageId
        startDate
        expireDate
        isFreeTrial
        zipcode
        address
        address2
        nextRecurringDate
        amount
        offerAmount
        paymentMethod
  
      }
  
		}
	}
`;

const deleteUserAccount = `
	mutation ($email: String!, $reason: String!) {
		deleteUserAccount(email: $email, reason: $reason) {
      message
      status   

    }
  }
`;

const editProfile = `
  mutation ($address: String, $email: String, $addressTwo: String,  $profileUrl: String, $city: String, $countryId: Int, $firstName: String, $lastName: String, $middleName: String, $salesId: String, $stateId: Int, $userId: Int, $phoneNumber:PhoneNumberObject,  $zipCode: Int, $removeEmail: Boolean) {
		editProfile(address: $address, email: $email, addressTwo: $addressTwo, profileUrl:$profileUrl, city: $city, countryId: $countryId, firstName: $firstName, lastName: $lastName, middleName: $middleName, phoneNumber: $phoneNumber, salesId: $salesId, stateId: $stateId, userId: $userId, zipCode: $zipCode, removeEmail: $removeEmail) {
      status
      message
      data {
        firstName
        lastName
        middleName
        address
        address2
        city
        zipcode
        stateId
        countryId
        phoneNumber {
          countryCode
          isoCode
          phoneNumber
        }
      }  
		}
	}
`;

const getBusinessDetailBasedOnUserId = `
  query ($userId: Int!, $isOnlineBusiness: Boolean) {
		getBusinessDetailBasedOnUserId(userId: $userId, isOnlineBusiness: $isOnlineBusiness) {
      status
      message
      data {
        id
        email
        user_businesses {
          id
          isActive
          isOnlineBusiness
          name
          city
          email
          zipCode
          website
          address
          review_platforms {
            id
            name
            isDefault
            logo
          }
          user_business_review_platforms{
            id
            isDefault
            logo
            name
          }
          business_types {
            id
            bType
          }
          states {
            id
            name
          }
          phoneNumber {
            countryCode
            isoCode
            phoneNumber
          }
          countries {
            id
            name
            states{
              id
              name
            }
          }
        }
        user_package_subscription {
          packages {
            id
          }
        }
      }   
		}
	}
`;

const updateMultipleBusinessDetail = `
mutation($businessDetail: [BusinessMultipleDetailDto!]!){
  updateMultipleBusinessDetail(businessDetail: $businessDetail){
    message
    status
  }
}
`;

const getProfile = `
query{
  getProfile{
    status
    message
    data{
      id
      firstName
      middleName
      lastName
      isPassword
      email
      phoneNumber{
        isoCode
        countryCode
        phoneNumber
      }
      address
      address2
      profileUrl
      countries{
        id
        name
      }
      states{
        id
        name
      }
      city
      zipcode
    }
  }
}
`;

const getInvitedBusinessDetailBasedOnUserId = `
query($userId: Int!){
  getInvitedBusinessDetailBasedOnUserId(userId:$userId){
    message
    status
    data{
        user_businesses{
          id
          name
          isActive
          city
          email
          zipCode
          phoneNumber{
            isoCode
            countryCode
            phoneNumber
          }
          address
          address2
          website
          review_platforms{
            id
            name
            logo
          }
          user_business_review_platforms{
            id
            logo
            name
          }
          business_types{
            id
            bType
          }
          states{
            id
            name
          }
          countries{
            id
            name
            states{
              id
              name
            }
        }
      }
    }
  }
}
`;

const updateDemoPackageAccountBySales = `
mutation ($packageId: Int!, $userId: Int!){
  updateDemoPackageAccountBySales(packageId:$packageId , userId:$userId){
    status
    message
  }
}
`;

const extendDemoAccountValidity = `
mutation ($extendDay: Int!, $userId: Int!) {
  extendDemoAccountValidity(extendDay:$extendDay, userId:$userId){
    status
    message
  }
}
`;

const getAllBusinessOnUserId = `
query ($countryId: Int, $stateId: Int, $state:String, $pageNumber: Int, $pageSize: Int, $searchText: String, $businessName: String) {
  getAllBusinessOnUserId(countryId:$countryId, stateId:$stateId, state:$state, pageNumber:$pageNumber, pageSize: $pageSize, searchText: $searchText, businessName: $businessName){
    status
    message
    data{
      address
      address2
      id
      name
      profileURL
    }
    totalCount
  }
}
`;

const getDashboard = `
query ($businessId: Int) {
  getDashboard(businessId:$businessId){
    status
    message
    data{
      address
      address2
      businessPlatformName
      businessQRId
      businessTypeId
      business_types{
        bType
        cts
        id
        isActive
      }
      city
      countries{
        countryCode
        currency
        id
        isoCode
        name
        symbol
      }
      email
      id
      isActive
      isAmazonProducts
      isFlipkartProducts
      isOnlineBusiness
      isPackageBasedDefaultBusiness
      isPromotion
      isQuickReviewProducts
      name
      pcBusinessVistedCount
      phoneNumber{
        countryCode
        isoCode
        phoneNumber
      }
      profileURL
      reviewPlatformId
      reviewplatform{
        aggregateData{
          aggregateAverageRating
          aggregateTotalReview
        }
        defaultReview
        logo
        name
        placeId
        reviewPlatformId
        totalRatingStars
        totalReview
        url
      }
      states{
        countryCode
        currency
        id
        isoCode
        name
        symbol
      }
      userId
      website
      zipCode
    }
  }
}
`;

const addProfileImage = `
mutation addProfileImage($image: Upload!) {
  addProfileImage(image: $image) {
    status
    message
    data {
      url
    }
  }
}
`;
const getUserStatusType = `
{
  getUserStatusType {
    status
    message
    data {
       id
      status
    }
  }
}
`;

const userData = {
  getAllUsers,
  getUserDetails,
  deleteUserAccount,
  editProfile,
  getBusinessDetailBasedOnUserId,
  updateMultipleBusinessDetail,
  getProfile,
  getInvitedBusinessDetailBasedOnUserId,
  updateDemoPackageAccountBySales,
  extendDemoAccountValidity,
  getAllBusinessOnUserId,
  getDashboard,
  addProfileImage,
  getUserStatusType,
};

export default userData;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const setHeaders = () => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
};

const handleApiRequest = async (endpoint, data) => {
  setHeaders();
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`selectedBusiness/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

const createAsyncThunkWithAuthorizationAndRequestFrom = (name, endpoint) => {
  return createAsyncThunk(`selectedBusiness/${name}`, async (data) => {
    instance.defaults.headers.common.isrequestfrom = true;
    return handleApiRequest(endpoint, data);
  });
};

export const getDemoBusinessWithDemoUserDetail = createAsyncThunkWithAuthorization(
  'getDemoBusinessWithDemoUserDetail',
  process.env.REACT_APP_API_ENDPOINT
);

export const checkEmail = createAsyncThunkWithAuthorizationAndRequestFrom(
  'checkEmail',
  process.env.REACT_APP_API_ENDPOINT
);

export const otpVerifyByDemoUserForAccountConvertToRealUser =
  createAsyncThunkWithAuthorizationAndRequestFrom(
    'otpVerifyByDemoUserForAccountConvertToRealUser',
    process.env.REACT_APP_AUTH_ENDPOINT
  );

export const getUserBusinessStatus = createAsyncThunkWithAuthorization(
  'getUserBusinessStatus',
  process.env.REACT_APP_API_ENDPOINT
);

export const fetchDemoBusinessData = createAsyncThunkWithAuthorization(
  'fetchDemoBusinessData',
  process.env.REACT_APP_API_ENDPOINT
);

export const getSalesPersonBusinessType = createAsyncThunkWithAuthorization(
  'getSalesPersonBusinessType',
  process.env.REACT_APP_API_ENDPOINT
);

const selectedbusinessSlice = createSlice({
  name: 'selectedBusiness',
  initialState: null,
  reducers: {
    resetSelectedBusiness: (state, action) => {
      if (state) {
        state.getDemoBusinessWithDemoUserDetailData = {};
        state.checkEmailData = {};
        state.otpVerifyByDemoUserForAccountConvertToRealUserData = {};
        state.getUserBusinessStatusData = {};
        state.fetchDemoBusinessDataData = {};
        state.getSalesPersonBusinessTypeData = {};
      }
    },
  },
  extraReducers: {
    [getDemoBusinessWithDemoUserDetail.fulfilled]: (state, action) => ({
      ...state,
      getDemoBusinessWithDemoUserDetailData: action.payload,
    }),
    [checkEmail.fulfilled]: (state, action) => ({
      ...state,
      checkEmailData: action.payload,
    }),
    [otpVerifyByDemoUserForAccountConvertToRealUser.fulfilled]: (state, action) => ({
      ...state,
      otpVerifyByDemoUserForAccountConvertToRealUserData: action.payload,
    }),
    [getUserBusinessStatus.fulfilled]: (state, action) => ({
      ...state,
      getUserBusinessStatusData: action.payload,
    }),
    [fetchDemoBusinessData.fulfilled]: (state, action) => ({
      ...state,
      fetchDemoBusinessDataData: action.payload,
    }),
    [getSalesPersonBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getSalesPersonBusinessTypeData: action.payload,
    }),
  },
});

export const { resetSelectedBusiness } = selectedbusinessSlice.actions;

export default selectedbusinessSlice.reducer;

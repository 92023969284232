import Dashboards from './Dashboards';

const DashboardsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'admin/dashboard',
      element: <Dashboards />,
    },
  ],
};

export default DashboardsConfigs;

import { yupResolver } from '@hookform/resolvers/yup';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FuseLoading from '@fuse/core/FuseLoading';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import history from '@history';
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import tabletVideosData from '../../query/tabletVideos';
import {
  getVideoByBusinessType,
  addVideoByBusinessType,
  deleteAndUpdateBusinessTypeVideo,
  updateVideoByBusinessType,
  openEditVideoDialog,
  closeEditVideoDialog,
  reSetTabletVideos,
} from './store/tabletVideosSlice';
import reducer from './store';

const defaultValues = {
  businessSubTitle: '',
};

const defaultValuesEdit = {
  businessSubTitle: '',
};

const schema = yup.object().shape({
  businessSubTitle: yup.string().required('Business Subtitle is required'),
});

const schemaEdit = yup.object().shape({
  businessSubTitle: yup.string().required('Business Subtitle is required'),
});

/**
 * Form Validation Schema
 */

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#006B5A',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

function TabletVideosDetails() {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const routeParams = useParams();

  const tabletVideosBusinessData = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.getVideoByBusinessTypeData?.data?.getVideoByBusinessType
        ?.data || ''
  );

  const addVideoByBusinessTypeStatus = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.addVideoByBusinessTypeData?.data?.addVideoByBusinessType
  );

  const updateVideoByBusinessTypeStatus = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.updateVideoByBusinessTypeData?.data
        ?.updateVideoByBusinessType
  );

  const deleteAndUpdateBusinessTypeVideoStatus = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.deleteAndUpdateBusinessTypeVideoData?.data
        ?.deleteAndUpdateBusinessTypeVideo
  );

  const appDialog = useSelector(
    ({ tabletVideosReducer }) => tabletVideosReducer.tabletVideos.videoDialog
  );

  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [refreshIconHideShow, setRefreshIconHideShow] = useState(false);
  const [hideShowVideoId, setHideShowVideoId] = useState(false);
  const [refreshIconEdit, setRefreshIconEdit] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const [imagePreviewName, setImagePreviewName] = useState('');
  const [noImgMsg, setNoImgMsg] = useState('');
  const [imageUploadEdit, setImageUploadEdit] = useState();
  const [imagePreviewNameEdit, setImagePreviewNameEdit] = useState('');
  const [noImgMsgEdit, setNoImgMsgEdit] = useState('');
  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const {
    control: controlEdit,
    watch: watchEdit,
    reset: resetEdit,
    handleSubmit: handleSubmitEdit,
    formState: formStateEdit,
    getValues: getValuesEdit,
    setError: setErrorEdit,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValuesEdit,
    resolver: yupResolver(schemaEdit),
  });
  const { isValid: isValidEdit, dirtyFields: dirtyFieldsEdit, errors: errorsEdit } = formStateEdit;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (appDialog.type === 'edit' && appDialog.data) {
      resetEdit({
        ...appDialog.data,
        businessSubTitle: appDialog.data.videoTitle,
      });
    }
  }, [appDialog.data, appDialog.type, resetEdit]);

  /**
      /**
       * On Dialog Open
       */
  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
    }
  }, [appDialog.props.open, dispatch, initDialog]);

  useEffect(() => {
    const payload = {
      query: tabletVideosData.getVideoByBusinessType,
      variables: { businessType: parseInt(routeParams.businessTypeId, 10) },
    };
    dispatch(getVideoByBusinessType(payload));
    return () => {
      dispatch(reSetTabletVideos());
    };
  }, [dispatch, routeParams.businessTypeId]);

  useEffect(() => {
    if (tabletVideosBusinessData || tabletVideosBusinessData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tabletVideosBusinessData]);

  useEffect(() => {
    if (addVideoByBusinessTypeStatus && addVideoByBusinessTypeStatus.status === 400) {
      enqueueSnackbar(addVideoByBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
      setImageUpload();
      setImagePreviewName('');
    } else if (addVideoByBusinessTypeStatus && addVideoByBusinessTypeStatus.status === 200) {
      enqueueSnackbar(addVideoByBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
      setImageUpload();
      setImagePreviewName('');
    }
  }, [addVideoByBusinessTypeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateVideoByBusinessTypeStatus && updateVideoByBusinessTypeStatus.status === 400) {
      enqueueSnackbar(updateVideoByBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setImageUploadEdit();
      setImagePreviewNameEdit('');
    } else if (updateVideoByBusinessTypeStatus && updateVideoByBusinessTypeStatus.status === 200) {
      enqueueSnackbar(updateVideoByBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setImageUploadEdit();
      setImagePreviewNameEdit('');
    }
  }, [updateVideoByBusinessTypeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (
      deleteAndUpdateBusinessTypeVideoStatus &&
      deleteAndUpdateBusinessTypeVideoStatus.status === 400
    ) {
      enqueueSnackbar(deleteAndUpdateBusinessTypeVideoStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIconHideShow(false);
    } else if (
      deleteAndUpdateBusinessTypeVideoStatus &&
      deleteAndUpdateBusinessTypeVideoStatus.status === 200
    ) {
      enqueueSnackbar(deleteAndUpdateBusinessTypeVideoStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIconHideShow(false);
    }
  }, [deleteAndUpdateBusinessTypeVideoStatus, enqueueSnackbar, closeSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }

  function handleFileChange(e, type) {
    const file = e.target.files[0];
    if (!file?.type.startsWith('video/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);
    if (type === 'add') {
      reader.onload = async () => {
        setImageUpload(file);
        setImagePreviewName(file?.name);
      };
      setNoImgMsg('');
    } else {
      reader.onload = async () => {
        setImageUploadEdit(file);
        setImagePreviewNameEdit(file?.name);
      };
      setNoImgMsgEdit('');
    }
  }

  function handleDrop(e, type) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file.type.startsWith('video/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);
    if (type === 'add') {
      reader.onload = async () => {
        setImageUpload(file);
        setImagePreviewName(file?.name);
      };
      setNoImgMsgEdit('');
    } else {
      reader.onload = async () => {
        setImageUploadEdit(file);
        setImagePreviewNameEdit(file?.name);
      };
      setNoImgMsgEdit('');
    }
  }

  const onSubmit = async (value) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: tabletVideosData.addVideoByBusinessType,
        variables: {
          businessTypeId: parseInt(routeParams.businessTypeId, 10),
          file: null,
          videoTitle: value.businessSubTitle,
        },
      };
      const dataObj = {
        payload,
        fileData: imageUpload,
      };
      await dispatch(addVideoByBusinessType(dataObj));
      const payloadAbout = {
        query: tabletVideosData.getVideoByBusinessType,
        variables: { businessType: parseInt(routeParams.businessTypeId, 10) },
      };
      dispatch(getVideoByBusinessType(payloadAbout));
      reset(defaultValues);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmitEdit = async (value) => {
    setRefreshIconEdit(true);
    try {
      const payload = {
        query: tabletVideosData.updateVideoByBusinessType,
        variables: {
          marketingVideoId: parseInt(value.id, 10),
          file: null,
          videoTitle: value.businessSubTitle,
        },
      };
      const dataObj = {
        payload,
        fileData: imageUploadEdit,
      };
      await dispatch(updateVideoByBusinessType(dataObj));
      const payloadAbout = {
        query: tabletVideosData.getVideoByBusinessType,
        variables: { businessType: parseInt(routeParams.businessTypeId, 10) },
      };
      setTimeout(() => {
        dispatch(getVideoByBusinessType(payloadAbout));
      }, 1000);
      reset(defaultValuesEdit);
      window.location.reload();
      closeComposeDialog();
      setRefreshIconEdit(false);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  /**
   * Remove Event
   */
  function handleRemove() {
    const fileInput = document.getElementById('button-fileEdit');
    if (fileInput) {
      fileInput.value = '';
    }
    closeComposeDialog();
    resetEdit(defaultValuesEdit);
    setImageUploadEdit();
    setImagePreviewNameEdit();
  }

  const handleDeleteOrHide = async (videoId, type) => {
    setRefreshIconHideShow(true);
    try {
      const payload = {
        query: tabletVideosData.deleteAndUpdateBusinessTypeVideo,
        variables: {
          videoId: parseInt(videoId, 10),
          isHide: !type,
        },
      };
      await dispatch(deleteAndUpdateBusinessTypeVideo(payload));
      const payloadAbout = {
        query: tabletVideosData.getVideoByBusinessType,
        variables: { businessType: parseInt(routeParams.businessTypeId, 10) },
      };
      dispatch(getVideoByBusinessType(payloadAbout));
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  function closeComposeDialog() {
    return dispatch(closeEditVideoDialog());
  }

  return (
    <div className="p-20 lg:p-40">
      <div>
        <Toolbar className="px-0 font-semibold min-h-fit mb-20">
          <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
            west
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 px-12 font-bold text-18"
          >
            {t('tabletVideos.title')}
          </Typography>
        </Toolbar>
        <div className="flex flex-1 w-full items-center justify-between mb-36">
          <div className="flex items-center">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold"
            >
              <span className="inline-block bg-white rounded-md w-48 h-48 text-center flex items-center mr-10 capitalize">
                <Icon className="text-32 mx-auto">video_library</Icon>
              </span>{' '}
              <span className="capitalize">{routeParams.businessTypeName}</span>
            </Typography>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow inline-block w-full">
        <Typography className="sm:flex text-18 md:text-18 font-semibold bg-grey-100 rounded-tl-md rounded-tr-md px-24 py-12">
          {t('tabletVideos.tabletVideoDetail.title')}
        </Typography>
        <div className="p-24">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
            {tabletVideosBusinessData &&
              tabletVideosBusinessData?.length > 0 &&
              tabletVideosBusinessData?.map((row, index) => (
                <div
                  key={index}
                  className="
        
           rounded-md relative
        "
                >
                  <div className="relative">
                    <video className="block w-full rounded-md h-200" controls>
                      <source src={row?.videoLink} type="video/mp4" />
                      <track kind="captions" label="English" default />
                    </video>
                    {row?.hideVideo && (
                      <img
                        src="/assets/images/admin-icon/hangout_video_off.png"
                        className="mx-auto absolute top-12 right-12"
                        alt="..."
                      />
                    )}
                    <div className="flex items-center justify-between mt-5">
                      <h3 className="text-16 font-semibold">{row?.videoTitle}</h3>
                      <div>
                        <Icon
                          className="cursor-pointer text-grey-600 hover:text-darkgreen align-middle"
                          title={t('tabletVideos.tabletVideoDetail.editVideo')}
                          aria-hidden="true"
                          onClick={() => {
                            dispatch(openEditVideoDialog(row));
                          }}
                        >
                          edit
                        </Icon>
                        <Switch
                          checked={!row.hideVideo}
                          title={
                            row?.hideVideo
                              ? `${t('tabletVideos.tabletVideoDetail.show')}`
                              : `${t('tabletVideos.tabletVideoDetail.hide')}`
                          }
                          onChange={() => {
                            handleDeleteOrHide(row?.id, row?.hideVideo);
                            setHideShowVideoId(row?.id);
                          }}
                        />
                        {refreshIconHideShow && hideShowVideoId === row?.id && (
                          <CircularProgress
                            size={18}
                            className="text-darkgreen absolute mx-auto right-0 mt-10"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow inline-bloc mt-52 w-full">
        <Typography className="sm:flex text-18 md:text-18 font-semibold bg-grey-100 rounded-tl-md rounded-tr-md px-24 py-12">
          {t('tabletVideos.tabletVideoDetail.addVideo')}
        </Typography>
        <div className="p-24">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="block mb-24">
              <InputLabel
                htmlFor="businessSubTitle"
                className="text-16 font-medium mb-12 text-grey-900"
              >
                {t('tabletVideos.formFields.businessSubTitle.name')}
              </InputLabel>
              <Controller
                control={control}
                name="businessSubTitle"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('tabletVideos.formFields.businessSubTitle.name')}
                    size="small"
                    id="businessSubTitle"
                    error={!!errors.businessSubTitle}
                    helperText={errors?.businessSubTitle?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <motion.div className="block mb-24 bg-darkgreen-100 p-14 rounded-8">
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-20 bg-darkgreen-100 text-center"
                role="button"
                onDrop={(e) => handleDrop(e, 'add')}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/video-icon.png"
                    className="mx-auto mb-12"
                    width="61"
                    height="64"
                    alt="..."
                  />
                </span>
                <div className="block font-medium">
                  {imagePreviewName || `${t('tabletVideos.tabletVideoDetail.fileTypeError')}`}
                </div>
                <span className="block my-6">or</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <input
                      accept="video/*"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={(e) => handleFileChange(e, 'add')}
                    />
                    <Button
                      size="small"
                      className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('tabletVideos.tabletVideoDetail.browseFile')}
                    </Button>
                  </label>
                </div>

                {noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
              </Paper>
            </motion.div>

            <div className="mx-auto text-center">
              <Button
                className="rounded-md text-18 font-semibold px-24 py-5 w-auto inline-block mx-10 relative disabled:text-black"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={refreshIcon || !imageUpload}
              >
                {t('tabletVideos.tabletVideoDetail.add')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
              <Button
                size="small"
                className="rounded-md text-18 font-semibold px-24 py-5 w-auto inline-block mx-10"
                variant="outlined"
                color="secondary"
                component="span"
                onClick={() => {
                  const fileInput = document.getElementById('button-file');
                  if (fileInput) {
                    fileInput.value = '';
                  }
                  setImageUpload();
                  setImagePreviewName('');
                }}
              >
                {t('tabletVideos.tabletVideoDetail.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        classes={{
          root: classes.root,
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            {t('tabletVideos.tabletVideoDetail.editVideo')}
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmitEdit(onSubmitEdit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent
            className={`${classes.attDialog} p-0 mb-24 business-type-dropdown-content`}
          >
            <div className="block mb-24">
              <InputLabel
                htmlFor="businessSubTitle"
                className="text-16 font-medium mb-12 text-grey-900"
              >
                {t('tabletVideos.formFields.businessSubTitle.name')}
              </InputLabel>
              <Controller
                control={controlEdit}
                name="businessSubTitle"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('tabletVideos.formFields.businessSubTitle.name')}
                    size="small"
                    id="businessSubTitle"
                    error={!!errorsEdit.businessSubTitle}
                    helperText={errorsEdit?.businessSubTitle?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <InputLabel
              htmlFor="businessSubTitle"
              className="text-16 font-medium mb-12 text-grey-900"
            >
              {t('tabletVideos.tabletVideoDetail.editVideo')}
            </InputLabel>
            <motion.div className="block mb-24 bg-darkgreen-100 p-14 rounded-8">
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-20 bg-darkgreen-100 text-center"
                role="button"
                onDrop={(e) => handleDrop(e, 'edit')}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/video-icon.png"
                    className="mx-auto mb-12"
                    width="61"
                    height="64"
                    alt="..."
                  />
                </span>
                <div className="block font-medium">
                  {imagePreviewNameEdit || `${t('tabletVideos.tabletVideoDetail.fileTypeError')}`}
                </div>
                <span className="block my-6">or</span>
                <div className="inline-block">
                  <label htmlFor="button-fileEdit">
                    <input
                      accept="video/*"
                      className="hidden"
                      id="button-fileEdit"
                      type="file"
                      onChange={(e) => handleFileChange(e, 'edit')}
                    />
                    <Button
                      size="small"
                      className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('tabletVideos.tabletVideoDetail.browseFile')}
                    </Button>
                  </label>
                </div>

                {noImgMsgEdit && <span className="text-red block mt-16">{noImgMsgEdit}</span>}
              </Paper>
            </motion.div>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={refreshIconEdit}
            >
              {t('tabletVideos.tabletVideoDetail.save')}
              {refreshIconEdit && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('business.businessAttribute.buttons.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('tabletVideosReducer', reducer)(TabletVideosDetails);

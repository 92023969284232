/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import settingsConfig from 'app/configs/settingsConfig';
import userService from '../services/userService';

export const setUserData = createAsyncThunk(
  'user/setUser',
  async (user, { dispatch, getState }) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    // if (user.loginRedirectUrl) {
    //   settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
    // }
    localStorage.setItem('userData', JSON.stringify(user));
    if (user && user.data) {
      dispatch(setDefaultSettings(user.data.settings));
    }

    dispatch(setUser(user));

    // return user;
  }
);

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const setUserCurrentBusinessDetailsValue = createAsyncThunk(
  'user/setUserCurrentBusinessDetails',
  async (businessDetails) => {
    return businessDetails;
  }
);

export const setUserSubscriptionPackage = createAsyncThunk(
  'user/getUserSubscriptionPackage',
  async (businessDetails) => {
    return businessDetails;
  }
);

export const setBusinessType = createAsyncThunk('user/getBusinessType', async (businessType) => {
  return businessType;
});

export const setReviewPlateForm = createAsyncThunk(
  'user/getReviewPlateForm',
  async (reviewPlateForm) => {
    return reviewPlateForm;
  }
);

export const setCountries = createAsyncThunk('user/getCountries', async (country) => {
  return country;
});

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  // if (!user.role || user.role.length === 0) {
  //   // is guest
  //   return null;
  // }

  history.push({
    pathname: '/sign-in',
  });

  switch (user.roleId) {
    case 'firebase': {
      break;
    }
    case 'auth0': {
      break;
    }
    default: {
      userService.logout();
    }
  }

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  userService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  role: [], // guest
  data: {
    displayName: '',
    photoURL: '',
    email: '',
    shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setUser: (state, action) => action.payload,
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUserCurrentBusinessDetailsValue.fulfilled]: (state, action) => ({
      ...state,
      setUserCurrentBusinessDetailsValueData: action.payload,
    }),
    [setUserSubscriptionPackage.fulfilled]: (state, action) => ({
      ...state,
      getUserSubscriptionPackageData: action.payload,
    }),
    [setBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getBusinessTypeData: action.payload,
    }),
    [setReviewPlateForm.fulfilled]: (state, action) => ({
      ...state,
      getReviewPlateFormData: action.payload,
    }),
    [setCountries.fulfilled]: (state, action) => ({
      ...state,
      getCountryData: action.payload,
    }),
  },
});

export const selectDashboardData = (state) => {
  return state.user.setUserCurrentBusinessDetailsValueData;
};

export const getUserPackageData = (state) => {
  return state.user.getUserSubscriptionPackageData;
};

export const getBusinessTypeData = (state) => {
  return state.user.getBusinessTypeData;
};

export const getReviewPlaterFormData = (state) => {
  return state.user.getReviewPlateFormData;
};

export const getCountryData = (state) => {
  return state.user.getCountryData;
};

export const { setUser, userLoggedOut } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;

const getQuickReviewContactUsData = `
   query($pageNumber: Int, $pageSize: Int, $searchText: String){
    getQuickReviewContactUsData(pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText){ 
          message
          status
          totalCount
          data{
               cts
               email
               id
               message
               name
               phoneNumber{
                countryCode
                isoCode
                phoneNumber
               }
               resolveStatus
          }
     }
   }
`;

const updateQuickReviewContactStatus = `
mutation($quickReviewContactId: Int!, $resolveStatus: QuickReviewContactStatus!){
    updateQuickReviewContactStatus(quickReviewContactId: $quickReviewContactId, resolveStatus: $resolveStatus){ 
          message
          status
     }
   }
`;

const queriesData = {
  getQuickReviewContactUsData,
  updateQuickReviewContactStatus,
};

export default queriesData;

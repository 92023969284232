import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`menu/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getUserDomainMenu = createAsyncThunkWithAuthorization('getUserDomainMenu', baseURL);

export const addUpdateDomainMenu = createAsyncThunkWithAuthorization(
  'addUpdateDomainMenu',
  baseURL
);

const menuSlice = createSlice({
  name: 'menu',
  initialState: null,
  reducers: {
    reSetMenu: (state, action) => {
      if (state) {
        state.getUserDomainMenuValue = {};
        state.addUpdateDomainMenuValue = {};
      }
    },
  },
  extraReducers: {
    [getUserDomainMenu.fulfilled]: (state, action) => ({
      ...state,
      getUserDomainMenuValue: action.payload,
    }),
    [addUpdateDomainMenu.fulfilled]: (state, action) => ({
      ...state,
      addUpdateDomainMenuValue: action.payload,
    }),
  },
});

export const { reSetMenu } = menuSlice.actions;

export default menuSlice.reducer;

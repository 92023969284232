import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import _ from '@lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'notistack';
import RadioGroup from '@mui/material/RadioGroup';
import {
  getDataToServer,
  getDataToServerForAuth,
  handleApiRequest,
  getEncryptedData,
  getDecryptData,
  imageSendToServer,
} from '../../common/common';
import queryData from '../../query/common';
import planQuery from '../../query/plan';
import userData from '../../query/user';
import UserService from '../../../services/userService';

const defaultValues = {
  firstName: '',
  lastName: '',
  phone_number: '',
  mail: '',
};

const AddEditClientPopUp = (props) => {
  const {
    openValue,
    cancelCall,
    popUpMessage,
    profileImageUrl,
    setProfileImageUrl,
    popUpButtonName,
    type,
    clientId,
    initialValues,
  } = props;

  let prevItem = null;
  const loginUserData = UserService.getUserData();
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [getPackageResponse, setGetPackageResponse] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [imageUrl, setImageUrl] = useState();
  const [image, setImage] = useState();
  const [imageLoader, setImageLoader] = useState(false);
  const [selectedPackageTypeId, setSelectedPackageTypeId] = useState(
    process.env.REACT_APP_DEFAULT_SIGNUP_PACKAGEID
  );
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    phone_number: yup
      .string()
      .required(t('signUpPage.formField.phoneNumber.validationMessages.required'))
      .min(10, t('signUpPage.formField.phoneNumber.validationMessages.min')),
    mail: yup
      .string()
      .email(t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.validationMessage'))
      .trim(),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    defaultValues: type === 'edit' ? { ...props.initialValues } : defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (type === 'add') {
      const fetchData = async () => {
        const payload = {
          query: planQuery.getPackages,
          variables: {
            countryId: loginUserData?.countryId,
            packageInterval: 'month',
          },
        };
        const result = await getDataToServer(payload);
        setGetPackageResponse(result?.getPackages?.data);
      };

      fetchData();
    }
    if (type === 'edit' && props?.initialValues?.phone_number) {
      const dialCode = props?.initialValues?.phone_number?.split(' ')[0] || '';
      const phoneNumber = props?.initialValues?.phone_number?.split(' ')[1] || '';
      setPhoneValue(props.initialValues.phone_number);
      setPhone({ dialCode, phoneNumber });
      setValue('phone_number', phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [loginUserData?.countryId, props?.initialValues?.phone_number, type, setValue]);

  useEffect(() => {
    const foundSelectedPackageObject = getPackageResponse.find(
      (item) => item?.packageTypeId === parseInt(selectedPackageTypeId, 10)
    );

    setSelectedPackageId(foundSelectedPackageObject?.id);
  }, [getPackageResponse, selectedPackageTypeId]);

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({ ...data, phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10) });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedPackageTypeId(process.env.REACT_APP_DEFAULT_SIGNUP_PACKAGEID);
    cancelCall();
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    let profileImgUrl = '';
    if (image) {
      const payload = {
        query: userData.addProfileImage,
        variables: {
          image,
        },
      };
      const result = await imageSendToServer(payload);
      setImageLoader(false);
      if (result?.addProfileImage?.status === 200) {
        profileImgUrl = result?.addProfileImage?.data?.url;
      }
    }
    if (type === 'add') {
      const dataValue = getEncryptedData(
        JSON.stringify({
          firstName: data?.firstName,
          lastName: data?.lastName,
          e: data?.mail,
          phoneNumber: {
            countryCode: `+${phone?.dialCode}`,
            phoneNumber: parseInt(phone?.phoneNumber, 10),
            isoCode: ipBasedData?.isoCode,
          },
          accessToken: '',
          countryId: ipBasedData?.countryId,
          userPackageId: parseInt(selectedPackageId, 10) || 1,
          isSocialUser: false,
          isClientRegistration: true,
          timeZone: '',
          agentId: parseInt(loginUserData?.id, 10),
          ...(profileImgUrl && {
            profileUrl: profileImgUrl,
          }),
        })
      );
      const obj = {
        query: queryData.signupV3,
        variables: { data: dataValue },
      };

      const res = await getDataToServerForAuth(obj);

      if (
        res?.signupV3?.status === 200 &&
        res?.signupV3?.message !== 'Phone Number already exists' &&
        res?.signupV3?.message !== 'Email address already exists'
      ) {
        enqueueSnackbar(res?.signupV3?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setRefreshIcon(false);
        handleClose();
        closeSnackbar(3000);
      } else {
        enqueueSnackbar(res?.signupV3?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setRefreshIcon(false);
      }
    } else {
      const obj = {
        query: userData.editProfile,
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          email: data?.mail || initialValues?.mail,
          userId: parseInt(clientId, 10),
          phoneNumber: {
            countryCode: phone?.dialCode.includes('+')
              ? `${phone?.dialCode}`
              : `+${phone?.dialCode}`,
            phoneNumber: parseInt(phone?.phoneNumber, 10),
            isoCode: ipBasedData?.isoCode,
          },
          ...(profileImgUrl && {
            profileUrl: profileImgUrl,
          }),
          ...(!data.mail &&
            initialValues?.mail && {
              removeEmail: true,
            }),
        },
      };
      const res = await handleApiRequest(obj);
      setRefreshIcon(false);
      if (
        res?.editProfile?.status === 200 &&
        res?.editProfile?.message !== 'Phone Number already exists' &&
        res?.editProfile?.message !== 'Email address already exists'
      ) {
        enqueueSnackbar(res?.editProfile?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setImageUrl();
        setImage();
        setRefreshIcon(false);
        handleClose();
      } else {
        enqueueSnackbar(res?.editProfile?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };
  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      setImageUrl(reader.result);
      setImage(file);
    };
    reader.readAsDataURL(file);
  };

  const saveProfile = async () => {
    setImageLoader(true);
    const payload = {
      query: userData.addProfileImage,
      variables: {
        image,
      },
    };
    const result = await imageSendToServer(payload);
    setImageLoader(false);
    if (result?.addProfileImage?.status === 200) {
      setProfileImageUrl(result?.addProfileImage?.data?.url);
      setImageUrl();
      setImage();
    }
  };

  return (
    <Dialog
      classes={{
        paper: 'm-24 p-28 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 mb-28">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
          {popUpMessage}
        </Typography>
      </DialogTitle>

      <form name="addClient" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center relative my-24">
          <div className="text-center">
            {imageUrl || profileImageUrl ? (
              <div>
                <img
                  className="w-92 h-92 rounded-full m-auto"
                  src={imageUrl || profileImageUrl}
                  alt=""
                />
              </div>
            ) : (
              <div className="bg-darkgreen-200 m-auto rounded-full w-92 h-92 flex items-center justify-center">
                <label htmlFor="button-file">
                  <input
                    id="button-file"
                    className="hidden"
                    onChange={(event) => {
                      handleFileChange(event);
                    }}
                    type="file"
                    accept="image/*"
                  />
                  <>
                    {!profileImageUrl && (
                      <Button className="text-darkgreen hover:bg-transparent" component="span">
                        <AddIcon className="text-darkgreen text-60" />
                      </Button>
                    )}
                  </>
                </label>
              </div>
            )}
            <label htmlFor="button-file">
              <input
                id="button-file"
                className="hidden"
                onChange={(event) => {
                  handleFileChange(event);
                }}
                type="file"
                accept="image/*"
              />
              {imageUrl || profileImageUrl ? (
                <>
                  <Button
                    className="bg-darkgreen absolute top-0 right-144 z-10 max-w-[22px] max-h-[22px] min-w-[22px] min-h-[22px] hover:bg-darkgreen"
                    component="span"
                  >
                    <EditIcon className="text-white text-lg" />{' '}
                  </Button>
                </>
              ) : (
                <>
                  {!profileImageUrl && (
                    <Button className="text-darkgreen hover:bg-transparent" component="span">
                      {t('agencyBusiness.clients.addProfilePicture')}
                    </Button>
                  )}
                </>
              )}
            </label>
          </div>
        </div>
        <div className="block mb-20">
          <InputLabel id="firstName" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.firstName.name')}
            <span className="text-red">*</span>
          </InputLabel>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-20">
          <InputLabel id="lastName" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.lastName.name')}
            <span className="text-red">*</span>
          </InputLabel>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-20">
          <InputLabel id="phone_number" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.phone.name')}
            <span className="text-red">*</span>
          </InputLabel>
          <PhoneInput
            placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
            inputStyle={{
              width: '100%',
              height: '37px',
            }}
            className="w-full"
            name="phone_number"
            required
            error={!!errors.phone_number}
            helperText={errors?.phone_number?.message}
            value={phoneValue}
            size="small"
            country={loginUserData?.isoCode?.toLowerCase() || ipBasedData?.isoCode?.toLowerCase()}
            enableSearch="true"
            onChange={handlePhoneChange}
          />
          {phoneValue && (
            <p
              className={`text-red ml-12 top-5 relative text-md ${
                (phoneValue?.length || 0) - (phone?.dialCode?.length || 0) <= 10
                  ? 'block'
                  : 'hidden'
              }`}
            >
              {errors.phone_number?.message
                ? t('signUpPage.formField.phoneNumber.validationMessages.min')
                : ''}
            </p>
          )}
        </div>
        <div className="block mb-20">
          <InputLabel id="mail" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.email.name')}
          </InputLabel>
          <Controller
            name="mail"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                error={!!errors.mail}
                helperText={errors?.mail?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        {type === 'add' && (
          <>
            <InputLabel
              id="controlled-radio-buttons-group"
              className="text-16 font-medium mb-4 text-grey-900"
            >
              {t('agencyBusiness.clients.form.selectPlan')}
            </InputLabel>

            <FormControl className="block mb-28">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                className="grid grid-cols-1 gap-20 lg:gap-20"
                value={selectedPackageTypeId}
                onChange={(event) => setSelectedPackageTypeId(event?.target?.value)}
              >
                {getPackageResponse
                  ?.filter((packageValue) => packageValue?.id !== 1)
                  ?.sort((a, b) => {
                    if (a?.packageIndex < b?.packageIndex) {
                      return -1;
                    }
                    if (a?.packageIndex > b?.packageIndex) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((packageValue, index) => {
                    const previousItem = prevItem;
                    prevItem = packageValue;
                    return (
                      <FormControlLabel
                        key={index}
                        value={packageValue?.packageTypeId}
                        control={<Radio className="p-0 absolute left-12 top-12 w-24 h-24" />}
                        label={
                          <div className="w-full ml-32">
                            <span className="text-16 font-semibold block capitalize text-grey-700">
                              {packageValue.name}
                            </span>
                            <span>
                              <span
                                className={`${
                                  packageValue?.offerAmount
                                    ? 'line-through font-medium text-16 md:text-16'
                                    : 'text-16 font-medium inline-block capitalize'
                                }`}
                              >
                                <span>
                                  {loginUserData?.countryId === 2 || ipBasedData?.countryId === 2
                                    ? '₹'
                                    : '$'}
                                </span>
                                {packageValue?.amount}
                              </span>
                              {packageValue?.offerAmount && (
                                <h3 className="my-0 text-16 md:text-16 inline-block ml-5 font-bold text-16 md:text-16">
                                  <span>
                                    {loginUserData?.countryId === 2 || ipBasedData?.countryId === 2
                                      ? '₹'
                                      : '$'}
                                  </span>
                                  {packageValue.offerAmount}
                                </h3>
                              )}{' '}
                              <span className="font-semibold">per Month</span>
                            </span>
                          </div>
                        }
                        className={`rounded-md p-10 border m-0 relative block w-full border-1 border-solid ${
                          parseInt(selectedPackageTypeId, 10) === packageValue?.packageTypeId
                            ? 'bg-darkgreen-100 border-darkgreen'
                            : 'bg-white border-grey '
                        }`}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </>
        )}

        <DialogActions className="p-0 mt-20 justify-center">
          <Button
            className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
          >
            {popUpButtonName}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditClientPopUp;

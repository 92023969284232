import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

const setAuthorizationHeader = () => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
};

const apiRequest = async (endpoint, data) => {
  setAuthorizationHeader();
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDeactivateBusiness = createAsyncThunk(
  'deactivatedAccount/getDeactivateBusiness',
  async (data) => apiRequest(baseURL, data)
);

export const activateBusinessAccountByAdmin = createAsyncThunk(
  'deactivatedAccount/activateBusinessAccountByAdmin',
  async (data) => apiRequest(baseURL, data)
);

const deactivatedAccountSlice = createSlice({
  name: 'deactivatedAccount',
  initialState: null,
  reducers: {
    resetDeactivatedAccount: (state, action) => {
      if (state) {
        state.getDeactivateBusinessData = {};
        state.activateBusinessAccountByAdminData = {};
      }
    },
  },
  extraReducers: {
    [getDeactivateBusiness.fulfilled]: (state, action) => ({
      ...state,
      getDeactivateBusinessData: action.payload,
    }),
    [activateBusinessAccountByAdmin.fulfilled]: (state, action) => ({
      ...state,
      activateBusinessAccountByAdminData: action.payload,
    }),
  },
});

export const { resetDeactivatedAccount } = deactivatedAccountSlice.actions;

export default deactivatedAccountSlice.reducer;

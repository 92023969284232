import Plan from './Plan';
import ManageAddon from './ManageAddon';

const planConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'your-plan/:id?',
      element: <Plan />,
    },
    {
      path: 'manage-plan/:id?',
      element: <ManageAddon />,
    },
  ],
};

export default planConfig;

const getReviewPlatforms = `
 query{
  getReviewPlatforms{
    id 
    data{
      id 
      logo
      name
    }
    status
    message
    cts
    uts
  }
 }
`;

const getBusinessAttributesOnId = `
 query($businessId: Int!, $businessTypeId: Int!){
     getBusinessAttributesOnId(businessId: $businessId, businessTypeId: $businessTypeId){
    data{
      id 
      bAttribute
      isActive
    }
    status
    message
  }
 }
`;

const getBusinessOwnerCustomAttributes = `
 query($businessId: Int!){
  getBusinessOwnerCustomAttributes(businessId: $businessId){
    message
    status
    data{
      id
      cts
      isActive
      bAttribute
      businessAttributeValueId
    }
    googleAttributesData{
      id
      cts
      isActive
      bAttribute
      businessAttributeValueId
      isGoogle
    }
  }
 }
`;

const getUserBusinessDetail = `
 query($businessId: Int){
     getUserBusinessDetail(businessId: $businessId){
     status
     message
     data {
      cts
       isOnlineBusiness
       id
       isNegativeReviewRedirect
       isNegativeReviewsPrevention
       isPackageBasedDefaultBusiness
       name
       city
       address
       address2
       states{
         id
         name
       }
       countries{
         id
         name
       }
       zipCode
       profileURL
       phoneNumber {
         countryCode
         phoneNumber
         isoCode
       }
       email
       website
       reviewPlatformId
       reviewModeTypeId
       businessTypeId
       isAutoThankYouReply
       isQRCodeOrdered
       business_types {
         id
         bType
         isActive
       }
       businessQRId
       userId
       user_business_review_platforms {
         id
         url
         placeId
         reviewPlatformId
       }
     } 
  }
 }
`;

const getBusinessAttributeValue = `
query {
      getBusinessAttributeValue{
        message
        status
        data{
          id
          type
          reaction  
        }
    
		}
	}
`;

const getBusinessServicesAndProductData = `
 query($businessId: Int!, $type: String, $isActive: Boolean){
  getBusinessServicesAndProductData(businessId: $businessId, type: $type, isActive: $isActive){
    data{
      id 
      businessId
      name
      isActive
      specialty
      type
    }
    status
    message
  }
 }
`;

const getReviewPlatfromModeType = `
 query($reviewPlatformId: Int, $isActive: Boolean, $sortOrder: OrderDirection, $sortBy: String){
  getReviewPlatfromModeType(reviewPlatformId: $reviewPlatformId, isActive: $isActive, sortOrder: $sortOrder, sortBy: $sortBy){
    data{
      id
      image
      name
    }
    status
    message
  }
 }
`;

const getBusinessAgentList = `
 query($businessId: Int!){
  getBusinessAgentList (businessId: $businessId){
    data{
      id
      role
      name
      isBusinessQr
      businessQRId
    }
    status
    message
  }
 }
`;

const getAgentSupportStatus = `
 query($businessId: Int!){
  getAgentSupportStatus (businessId: $businessId){
    data{
      agentSupport
    }
    status
    message
  }
 }
`;

const getAmazonProduct = `
 query($userBusinessId: Int!, $amazonProductId: Int){
  getAmazonProduct (userBusinessId: $userBusinessId, amazonProductId: $amazonProductId){
    data{
      id
      userId
      userBusinessId
      profileUrl
      title
      productUrl
      totalReviews
      rating
      isActive
      reviewPlatformId
      placeId
      businessQRRedirectUrl
      genericName
      scrapingStatus
      reviewModeTypeId
      businessTypeId
      isAutoTextGenerate
      isNegativeReviewRedirect
    }
    status
    message
  }
 }
`;

const getFlipkartProduct = `
 query($userBusinessId: Int!, $flipkartProductId: Int){
  getFlipkartProduct (userBusinessId: $userBusinessId, flipkartProductId: $flipkartProductId){
    data{
      businessQRRedirectUrl
      businessTypeId
      genericName
      id
      isActive
      isAutoTextGenerate
      isNegativeReviewRedirect
      placeId
      productQrId
      productUrl
      profileUrl
      rating
      reviewModeTypeId
      reviewPlatformId
      scrapingStatus
      title
      totalReviews
      userBusinessId
      userId
    }
    status
    message
  }
 }
`;

const searchYelpBusiness = `
query ($location: String!, $search: String, $pageNumber: Int, $pageSize: Int) {
  searchYelpBusiness(
    location: $location
    search: $search
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    data {
      alias
      categories {
        alias
        title
      }
      coordinates {
        latitude
        longitude
      }
      location {
        address1
        address2
        address3
        city
        country
        display_address
        state
        zip_code
      }
      display_phone
      distance
      id
      image_url
      is_closed
      name
      phone
      price
      rating
      review_count
      transactions
      url
    }
    message
    status
  }
}
`;

const validateZomatoUrl = `
mutation ($url: String!){
  validateZomatoUrl(url: $url){
    status
    message
    data {
      res_id
      name
      address
      city_name
      country_name
      business_image_url
      business_url
      overall_ratings {
        DINING {
          reviewCount
          rating
          rating_type
        }
        DELIVERY {
           reviewCount
          rating
          rating_type
        }
      }
      aggregate_rating {
        aggregate_rating
      }
    }
  }
}
`;

const addBusinessDetailV2 = `mutation AddBusinessDetailV2($businessDetail: BusinessDetailV2!, $isClient: Boolean, $userId: Int) {
  addBusinessDetailV2(businessDetail: $businessDetail, isClient: $isClient, userId: $userId) {
    status
    message
  }
}
`;

const updateBusinessDetail = `mutation updateBusinessDetail($businessDetail: BusinessDetail!) {
  updateBusinessDetail(businessDetail: $businessDetail) {
    status
    message
  }
}
`;

const addCustomBusinessAttribute = `
mutation addCustomBusinessAttribute($bAttribute: String! , $businessId:Int! , $businessAttributeValueId:Int! , $isActive:Boolean!){
  addCustomBusinessAttribute(bAttribute: $bAttribute, businessId: $businessId, businessAttributeValueId: $businessAttributeValueId, isActive: $isActive){
    status
    message
  }
}
`;

const editCustomBusinessAttribute = `
mutation editCustomBusinessAttribute($businessAttributeValueId: Int!, $businessId:Int!, $userCustomBusinessAttributeId:Int!, $bAttribute: String!){
  editCustomBusinessAttribute(businessAttributeValueId: $businessAttributeValueId, businessId: $businessId, userCustomBusinessAttributeId: $userCustomBusinessAttributeId, bAttribute: $bAttribute){
    status
    message
  }
}
`;

const updateCustomBusinessAttributeWithDefaultBusinessAttribute = `mutation updateCustomBusinessAttributeWithDefaultBusinessAttribute($defaultAttribute: [DefaultAttributeArgData!]!, $customBusinessAttribute: [CustomBusinessAttributeInput!]!, $businessId : Int!){
  updateCustomBusinessAttributeWithDefaultBusinessAttribute(defaultAttribute: $defaultAttribute, customBusinessAttribute: $customBusinessAttribute, businessId: $businessId){
    status
    message
  }
}`;

const addBusinessServicesAndProductDataV1 = `mutation addBusinessServicesAndProductDataV1($businessId:Int!,$type:String!
  $name:String!,$specialty:Boolean!
) {
addBusinessServicesAndProductDataV1(businessId: $businessId, type: $type, name: $name, specialty: $specialty){
  message
  status
  }
}`;

const editBusinessServicesAndProductData = `mutation editBusinessServicesAndProductData($userBusinessServiceAndProductId:Int!,
  $name:String,$businessId:Int,$specialty:Boolean
) {
editBusinessServicesAndProductData(userBusinessServiceAndProductId: $userBusinessServiceAndProductId,
name: $name,businessId: $businessId,specialty: $specialty){
  message
  status
  }
}`;

const removeBusinessProductsServices = `mutation removeBusinessServicesAndProductData($userBusinessServiceAndProductId: [Int!]!){
  removeBusinessServicesAndProductData(userBusinessServiceAndProductId: $userBusinessServiceAndProductId){
    message
    status
  }
}
`;

const updateUserBusinessesPlatformModeTypeV2 = `mutation  updateUserBusinessesPlatfromModeType($userBusinessId: Int!, $reviewModeTypeId: Int!){
  updateUserBusinessesPlatfromModeType(userBusinessId: $userBusinessId, reviewModeTypeId: $reviewModeTypeId) {
    status
    message
  }
}
`;

const addBusinessAgent = `mutation addBusinessAgent($businessId: Int!,$name: String!,$role: String) {
  addBusinessAgent(businessId:$businessId,name:$name,role:$role){
    message
    status
  }
}
`;

const updateBusinessAgent = `mutation updateBusinessAgent($businessAgentId: Int!,$name: String,$role: String) {
  updateBusinessAgent(businessAgentId:$businessAgentId,name:$name,role:$role){
    message
    status
  }
}
`;

const deleteBusinessAgent = `mutation deleteBusinessAgent($businessAgentId: Int!) {
  deleteBusinessAgent(businessAgentId:$businessAgentId){
    message
    status
  }
}
`;

const setAgentSupportStatus = `mutation setAgentSupportStatus($businessId: Int!,$isActive: Boolean!) {
  setAgentSupportStatus(businessId:$businessId,isActive:$isActive){
    message
    status
  }
}
`;

const addAmazonProduct = `mutation addAmazonProduct($productTypeId: Int, $url: String!, $userBusinessId: Int!){
  addAmazonProduct(productTypeId: $productTypeId, url: $url, userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const deleteAmazonProduct = `mutation deleteAmazonProduct($amazonProductId: Int!){
  deleteAmazonProduct(amazonProductId: $amazonProductId){
    message
    status
  }
}`;

const deleteFlipkartProduct = `mutation deleteFlipkartProduct($flipkartProductId: Int!){
  deleteFlipkartProduct(flipkartProductId: $flipkartProductId){
    message
    status
  }
}`;

const addFlipkartProduct = `mutation addFlipkartProduct($productTypeId: Int, $url: String!, $userBusinessId: Int!){
  addFlipkartProduct(productTypeId: $productTypeId, url: $url, userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const fetchAmazonApiData = `query fetchAmazonApiData($url: String!) {
  fetchAmazonApiData(url: $url){
    message
    status
    data{
      product_url
      rating
      product_asin
      total_reviews
      title
      profile_url
    }
  }
}`;

const fetchFlipkartProduct = `query fetchFlipkartProduct($url: String!) {
  fetchFlipkartProduct(url: $url){
    message
    status
    data{
      product_url
      review_url
      generic_name
      product_id
      rating
      title
      total_reviews
      product_image
    }
  }
}`;

const removeBusinessPlatform = `
mutation removeBusinessPlatform($useBusinessId: Int!, $userBusinessPlatformId: Int!){
  removeBusinessPlatform(useBusinessId: $useBusinessId, userBusinessPlatformId: $userBusinessPlatformId){
    message
    status
  }
}
`;

const getNegativeReview = `query ($userBusinessId: Int, $negativeReviewId: Int, $status: String, $amazonProductId: Int, $flipkartProductId: Int){
  getNegativeReview(userBusinessId: $userBusinessId,negativeReviewId: $negativeReviewId,status: $status, amazonProductId: $amazonProductId, flipkartProductId: $flipkartProductId){
    message
    status
    data{
      name
      countryCode
      phoneNumber
      reason
      cts
      status
      businessUserNote
      autoGeneratedReview
    }
  }
}
`;

const getNegativeReviewsBaseOnPrevention = `
 query getNegativeReviewsBaseOnPrevention($businessId: Int!){
  getNegativeReviewsBaseOnPrevention(businessId: $businessId){
    data {
      rating
      review
      cts
      phoneNumber{
        countryCode
        isoCode,
        phoneNumber
      }
    }
    message
    status
  }
 }
`;

const getNegativeReviewRedirectStatus = `query getNegativeReviewRedirectStatus($userBusinessId: Int, $flipkartProductId: Int, $amazonProductId: Int) {
  getNegativeReviewRedirectStatus(userBusinessId: $userBusinessId, flipkartProductId: $flipkartProductId, amazonProductId: $amazonProductId) {
    message
    status
    data{
      id
      cts
      uts
      businessId
      name
      phoneNumber
      countryCode
      reason
      status
      businessUserNote
      autoGeneratedReview
    }
  }
}
`;

const negativeReviewUpdate = `mutation negativeReviewUpdate($negativeReviewId: Int!, $status: String!, $businessUserNote: String) {
  negativeReviewUpdate(negativeReviewId: $negativeReviewId,status: $status,businessUserNote: $businessUserNote){
    message
    status
  }
}
`;
const setNegativeReviewRedirect = `
mutation setNegativeReviewRedirect($userBusinessId: Int!, $isActive: Boolean! $negativeReviewsPrevention: Boolean) {
  setNegativeReviewRedirect(userBusinessId: $userBusinessId, isActive: $isActive, negativeReviewsPrevention: $negativeReviewsPrevention) {
    status
    message
  }
}
`;

const getBusinessQRIdBaseOnBusinessId = `query getBusinessQRIdBaseOnBusinessId($businessId: Int!) {
  getBusinessQRIdBaseOnBusinessId(
  businessId: $businessId) {
  message
  status
  data{
  id
  businessQRId
  }
  }
  }`;

const addBusinessQRIdToBusinessAgent = `mutation addBusinessQRIdToBusinessAgent($agentId:Int!,$businessQRId: Int!){
    addBusinessQRIdToBusinessAgent(agentId: $agentId,businessQRId: $businessQRId ){
    status
    message
  }
}
`;

const deleteQRCode = `mutation deleteQRCode($agentId: Int!){
  deleteQRCode(agentId: $agentId){
  status
  message
  }
}`;

const businessPlatformPlanValidation = `query businessPlatformPlanValidation($businessId: Int!){
  businessPlatformPlanValidation(businessId: $businessId) {
    data {
      availableCount
      usedCount
    }
    message
    status
  }
}
`;

const getUserBusinessesPackageUsage = `query($businessId: Int!) {
  getUserBusinessesPackageUsage(businessId:$businessId){
    status
    message
    data {
      userId
      dataUsage 
    }
  }
}
`;

const getBusinessAutoTextGenerateStatus = `query getBusinessAutoTextGenerateStatus($businessId: Int!){
  getBusinessAutoTextGenerateStatus(businessId: $businessId){
    status
    message
    data{
      isAutoTextGenerate
    }
  }
}`;

const setBusinessAutoTextGenerate = `mutation setBusinessAutoTextGenerate($businessId: Int! $isActive: Boolean) {
  setBusinessAutoTextGenerate(businessId: $businessId, isActive:$isActive){
     message
     status
  }
}`;

const getAutoReviewComplexityType = `query{
  getAutoReviewComplexityType(isActive:true){
    message
    status
    data{
      id
      cts
      isActive
      typeName
    }
  }
}
`;

const setReviewComplexityType = `mutation setReviewComplexityType($businessId:Int!,$typeId:Int!){
  setReviewComplexityType(
    businessId:$businessId
    typeId:$typeId
  ){
    status
    message
  }
}
`;

const getSelectedReviewComplexityType = `query getSelectedReviewComplexityType($businessId: Int!){
  getSelectedReviewComplexityType(businessId: $businessId){
    status
    message
    data{
      id
      autoGenerateReviewComplexityTypeId
    }
  }
}`;

const getContactFromBusinessReview = `query getContactFromBusinessReview($userBusinessId: Int!, $pageNumber: Int, $pageSize: Int, $isNewest: Boolean){
  getContactFromBusinessReview(userBusinessId: $userBusinessId, pageNumber: $pageNumber, pageSize: $pageSize, isNewest: $isNewest){
    status
    message
    totalCount
    data {
      id
      countryCode
      cts
      phoneNumber
    }
  }
}`;

const getBusinessAutoThanksHistory = `query getBusinessAutoThanksHistory($businessId: Int!,$pageNumber: Int, $pageSize: Int) {
  getBusinessAutoThanksHistory(businessId: $businessId,pageNumber: $pageNumber, pageSize: $pageSize,) {
    data {
      phoneNumber {
        phoneNumber
        countryCode
      }
      messagesStatus
      cts
    }
      totalCount
    message
    status
  }
}
`;

const setBusinessAutoThankSupport = `mutation setBusinessAutoThankSupport($businessId: Int!, $isAutoThankYouReply: Boolean!, $defaultAutoReplyStandardMessageId: Int) {
  setBusinessAutoThankSupport(businessId: $businessId, isAutoThankYouReply: $isAutoThankYouReply, defaultAutoReplyStandardMessageId: $defaultAutoReplyStandardMessageId) {
    message
    status
  }
}
`;

const getAutoThankYouStandardMessages = `query getAutoThankYouStandardMessages($pageNumber: Int, $pageSize: Int) {
  getAutoThankYouStandardMessages(pageNumber: $pageNumber, pageSize: $pageSize) {
    status
    message
    data {
      id
      messages
    }
  }
}
`;

const getAutoThankYouDefaultMessages = `query getAutoThankYouDefaultMessages($businessId: Int!) {
  getAutoThankYouDefaultMessages(businessId: $businessId) {
    status
    message
    data {
      messages
    }
  }
}
`;

const getInvitationRequestedAndAcceptedRequestUserData = `query getInvitationRequestedAndAcceptedRequestUserData($status: BusinessUserRequestEnum!, $userBusinessId: Int!, $pageSize: Int, $pageNumber: Int ){
  getInvitationRequestedAndAcceptedRequestUserData(status: $status, userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
      message
      status
      data{
        id
        userId
        firstName
        lastName
        profile
        phoneNumber
        email
      }
    }
   }`;

const searchUserOnEmailAndPhoneNumber = `query searchUserOnEmailAndPhoneNumber($email: String, $businessId: Int!, $phoneNumberDetails: SearchPhoneNumber){
  searchUserOnEmailAndPhoneNumber(email: $email, businessId: $businessId, phoneNumberDetails: $phoneNumberDetails) {
    message
    status
    data {
      id
      firstName
      lastName
      profileUrl
      email
      phoneNumber{
        countryCode
        isoCode
        phoneNumber
      }
    }
  }
}`;

const getAttributeLevelThreshold = `query getAttributeLevelThreshold($amazonProductId: Int, $face: String, $flipkartProductId: Int, $star: Int, $thumbs: String, $userBusinessId: Int!){
  getAttributeLevelThreshold(amazonProductId: $amazonProductId, face: $face, flipkartProductId: $flipkartProductId, star: $star, thumbs: $thumbs, userBusinessId: $userBusinessId) {
    message
    status
    cts
    data {
      amazonProductId
      face
      flipkartProductId
      star
      thumbs
      userBusinessId
    }
    id
    message
    status
    uts
  }
}`;

const updateAttributeLevelThreshold = `mutation updateAttributeLevelThreshold($amazonProductId: Int, $face: String, $flipkartProductId: Int, $star: Int, $thumbs: String, $userBusinessId: Int!) {
  updateAttributeLevelThreshold(amazonProductId: $amazonProductId,face: $face,flipkartProductId: $flipkartProductId, star: $star, thumbs: $thumbs,userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const invitedUserToJoinBusinessServices = `mutation invitedUserToJoinBusinessServices($invitationData: [invitationData!]){
  invitedUserToJoinBusinessServices(invitationData: $invitationData) {
    status
    message
  }
}`;

const cancelRequestedInvitationAndAcceptedInvitation = `mutation cancelRequestedInvitationAndAcceptedInvitation($status: BusinessUserRequestEnum!, $userBusinessRoleMappingId: Int!){
  cancelRequestedInvitationAndAcceptedInvitation(status: $status, userBusinessRoleMappingId: $userBusinessRoleMappingId){
    status
    message
    
  }
}
`;

const removeUserBusinessDetail = `mutation removeUserBusinessDetail($businessId: Int!){
  removeUserBusinessDetail(businessId: $businessId){
    message
    status
  }
}`;

const manageBusinessData = {
  getReviewPlatforms,
  getBusinessAttributesOnId,
  getBusinessOwnerCustomAttributes,
  getUserBusinessDetail,
  getBusinessAttributeValue,
  getBusinessServicesAndProductData,
  getReviewPlatfromModeType,
  getBusinessAgentList,
  getAgentSupportStatus,
  getAmazonProduct,
  getFlipkartProduct,
  searchYelpBusiness,
  validateZomatoUrl,
  addBusinessDetailV2,
  updateBusinessDetail,
  addCustomBusinessAttribute,
  editCustomBusinessAttribute,
  updateCustomBusinessAttributeWithDefaultBusinessAttribute,
  addBusinessServicesAndProductDataV1,
  editBusinessServicesAndProductData,
  removeBusinessProductsServices,
  updateUserBusinessesPlatformModeTypeV2,
  addBusinessAgent,
  setAgentSupportStatus,
  updateBusinessAgent,
  deleteBusinessAgent,
  addAmazonProduct,
  deleteAmazonProduct,
  deleteFlipkartProduct,
  addFlipkartProduct,
  fetchAmazonApiData,
  fetchFlipkartProduct,
  removeBusinessPlatform,
  getNegativeReview,
  getNegativeReviewsBaseOnPrevention,
  getNegativeReviewRedirectStatus,
  negativeReviewUpdate,
  setNegativeReviewRedirect,
  getBusinessQRIdBaseOnBusinessId,
  addBusinessQRIdToBusinessAgent,
  deleteQRCode,
  businessPlatformPlanValidation,
  getUserBusinessesPackageUsage,
  getBusinessAutoTextGenerateStatus,
  setBusinessAutoTextGenerate,
  getAutoReviewComplexityType,
  setReviewComplexityType,
  getSelectedReviewComplexityType,
  getContactFromBusinessReview,
  getBusinessAutoThanksHistory,
  setBusinessAutoThankSupport,
  getAutoThankYouStandardMessages,
  getAutoThankYouDefaultMessages,
  getInvitationRequestedAndAcceptedRequestUserData,
  searchUserOnEmailAndPhoneNumber,
  getAttributeLevelThreshold,
  updateAttributeLevelThreshold,
  invitedUserToJoinBusinessServices,
  cancelRequestedInvitationAndAcceptedInvitation,
  removeUserBusinessDetail,
};

export default manageBusinessData;

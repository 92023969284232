import { useEffect } from 'react';
import history from '@history';
import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import reducer from '../store';

const ViewIndividualDetails = () => {
  const viewMessageDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.viewMessageDetailsData
  );

  useEffect(() => {
    if (!viewMessageDetailsData) {
      history.go(-1);
    }
  }, [viewMessageDetailsData]);

  return (
    <div className="p-20 lg:p-40">
      <Typography className=" text-3xl font-semibold tracking-normal text-black text-left capitalize text-grey-900">
        {t('sendMessage.sendSmsDetails')}
      </Typography>
      <div className="flex gap-20">
        <div className="bg-white w-[60%] lg:w-[70%] rounded-md relative shadow p-20 mt-20">
          <Typography className=" text-xl justify-normal tracking-normal text-grey-900 text-left capitalize font-semibold">
            {t('inviteReviews.customerPersonalInformation')}
          </Typography>
          <hr className="border-gray border-dashed my-10" />
          <Typography className=" font-semibold tracking-normal text-black text-left capitalize">
            {t('inviteReviews.fullName')}
          </Typography>
          <div className="bg-[#FBFBFB] p-16 mt-4">
            <Typography className=" text-base text-black font-bold">
              {viewMessageDetailsData?.campaign_services_queue?.[0]?.name}
            </Typography>
          </div>
          {viewMessageDetailsData?.campaign_platform?.name === 'Email' ? (
            <>
              <Typography className="text-14 text-black pt-8">
                {t('inviteReviews.email')}
              </Typography>
              <div className="bg-[#FBFBFB] p-16 mt-4">
                <Typography className="text-14 text-black">
                  {viewMessageDetailsData?.campaign_services_queue?.[0]?.email}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <Typography className=" font-semibold text-base tracking-normal text-black text-left capitalize">
                {t('inviteReviews.phone')}
              </Typography>
              <div className="bg-[#FBFBFB] p-16 mt-4 ">
                <Typography className="text-base text-black font-bold">
                  {viewMessageDetailsData?.campaign_services_queue?.[0]?.countryCode}{' '}
                  {viewMessageDetailsData?.campaign_services_queue?.[0]?.phoneNumber}
                </Typography>
              </div>
            </>
          )}
        </div>
        <div className="bg-white rounded-md w-[38%] lg:w-[30%] relative shadow p-20 mt-20">
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/squarecheck.png" alt="" />
              <Typography className=" font-semibold tracking-normal text-black text-left">
                {t('inviteReviews.status')}
              </Typography>
            </div>
            <Typography
              className={`tracking-normal font-bold text-lg text-right ${
                viewMessageDetailsData?.campaign_services_queue[0]?.status === 'delivered'
                  ? 'text-green-A300'
                  : 'text-red-A300'
              }`}
            >
              {viewMessageDetailsData?.campaign_services_queue[0]?.status &&
                viewMessageDetailsData.campaign_services_queue[0].status.charAt(0).toUpperCase() +
                  viewMessageDetailsData.campaign_services_queue[0].status.slice(1)}
            </Typography>
          </div>

          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/calendar.png" alt="" />
              <Typography className=" font-semibold tracking-normal text-black text-left">
                {t('sendMessage.date')}
              </Typography>
            </div>
            <Typography className="tracking-normal font-bold text-lg text-right">
              {moment(viewMessageDetailsData?.scheduleDate).format('MMM DD, YYYY')}
            </Typography>
          </div>
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/clock.png" alt="" />
              <Typography className=" font-semibold tracking-normal text-black text-left">
                {t('sendMessage.time')}
              </Typography>
            </div>
            <Typography className="tracking-normal font-bold text-lg text-right">
              {moment(parseInt(viewMessageDetailsData?.cts, 10)).format('hh.mm A')}
            </Typography>
          </div>
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center ">
              <img className="w-[30px]" src="/assets/images/icon/squarecheck.png" alt="" />
              <Typography className=" font-semibold tracking-normal text-black text-left">
                {t('sendMessage.selectedPlatform')}
              </Typography>
            </div>
            <Typography className="tracking-normal font-bold text-lg text-right">
              {viewMessageDetailsData?.campaign_platform?.name}
            </Typography>
          </div>
        </div>
      </div>
      <div className="bg-white MD:w-[57%] lg:w-[69%] rounded-md relative shadow p-20 mt-20">
        <Typography className="  font-semibold text-xl tracking-normal text-grey-900 text-left capitalize ">
          {t('sendMessage.message')}
        </Typography>
        <hr className="border-gray border-dashed my-10" />
        {viewMessageDetailsData?.campaign_platform?.name === 'Email' ? (
          <div>
            <Typography className="font-medium">
              {JSON.parse(viewMessageDetailsData?.campaign_services_queue?.[0]?.messages)?.subject}
            </Typography>
            <p
              className="pt-12"
              dangerouslySetInnerHTML={{
                __html: JSON.parse(viewMessageDetailsData?.campaign_services_queue?.[0]?.messages)
                  ?.body,
              }}
            />
          </div>
        ) : (
          <Typography className="text-grey-900 font-bold">
            {viewMessageDetailsData?.campaign_services_queue?.[0]?.messages}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withReducer('state', reducer)(ViewIndividualDetails);

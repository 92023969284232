const getAllUserFeedback = `
query($pageNumber: Int,$pageSize:Int,$searchText: String){
  getUserFeedback(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchText: $searchText
  ){
    totalCount
    status
    message
    data{
      id
      feedback
      cts
      userId
      users{
        firstName
        email
        profileUrl
        lastName
      }
    }
  }
}
`;

const usersFeedbackData = {
  getAllUserFeedback,
};

export default usersFeedbackData;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`queries/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getQuickReviewContactUsData = createAsyncThunkWithAuthorization(
  'getQuickReviewContactUsData',
  baseURL
);
export const getUserDetails = createAsyncThunkWithAuthorization('getUserDetails', baseURL);
export const updateQuickReviewContactStatus = createAsyncThunkWithAuthorization(
  'updateQuickReviewContactStatus',
  baseURL
);

const queriesSlice = createSlice({
  name: 'queries',
  initialState: {
    queriesDialog: {
      type: 'new',
      props: {
        open: false,
      },
    },
  },
  reducers: {
    openQueriesDialog: (state, action) => {
      state.queriesDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeQueriesDialog: (state, action) => {
      state.queriesDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    reSetQueries: (state, action) => {
      if (state) {
        state.getQuickReviewContactUsDataValue = {};
        state.updateQuickReviewContactStatusData = {};
      }
    },
  },
  extraReducers: {
    [getQuickReviewContactUsData.fulfilled]: (state, action) => ({
      ...state,
      getQuickReviewContactUsDataValue: action.payload,
    }),
    [updateQuickReviewContactStatus.fulfilled]: (state, action) => ({
      ...state,
      updateQuickReviewContactStatusData: action.payload,
    }),
  },
});

export const { reSetQueries, openQueriesDialog, closeQueriesDialog } = queriesSlice.actions;

export default queriesSlice.reducer;

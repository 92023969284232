import AppMaintenance from './AppMaintenance';

const appMaintenanceConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'app-maintenance',
      element: <AppMaintenance />,
    },
  ],
};

export default appMaintenanceConfig;

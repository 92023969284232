import NegativeReviews from './NegativeReviews';
import NegativeReviewDetails from './NegativeReviewDetails';

const NegativeReviewsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'negative-reviews',
      element: <NegativeReviews />,
    },
    {
      path: 'negative-review-detail/:id',
      element: <NegativeReviewDetails />,
    },
  ],
};

export default NegativeReviewsConfig;

import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { logoutUser, selectUser } from 'app/store/userSlice';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <>
        <div className="mb-36 mt-36" color="inherit">
          <div className="text-center">
            <Avatar
              className="mx-auto w-96 h-96 mb-16 text-40 capitalize"
              alt={user?.firstName}
              src={user?.profileUrl || user?.firstName}
            />
            <Typography component="span" className="font-semibold text-16 text-grey-100 capitalize">
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography className="text-12 font-medium text-grey-500">{user?.email}</Typography>
          </div>
        </div>
        <FuseNavigation
          className={clsx('navigation', props.className)}
          navigation={navigation}
          layout={props.layout}
          dense={props.dense}
          active={props.active}
          onItemClick={handleItemClick}
        />
      </>
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
    user?.firstName,
    user?.lastName,
    user?.email,
    user?.profileUrl,
  ]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);

import {
  Button,
  FormControl,
  FormControlLabel,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import history from '@history';
import userService from 'src/app/services';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { publicIpv4 } from 'public-ip';
import { setUserData } from 'app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import SigninBoxContent from './SignInBoxContent';
import Query from '../query/auth';
import {
  getDataToServerForAuth,
  getDecryptData,
  getEncryptedData,
  handleApiRequest,
} from '../common/common';
import planQuery from '../query/plan';
import queryData from '../query/common';
import { getLocation } from '../sign-up/store/signUpSlice';

const WhatsappSign = () => {
  const location = useLocation();
  const [userValue, setUserValue] = useState();
  const [packagesData, setPackagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [selectedPackageTypeId, setSelectedPackageTypeId] = useState(6);
  const { enqueueSnackbar } = useSnackbar();
  const [isPersonalDetailsFilled, setIsPersonalDetailsFilled] = useState(false);
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  let prevItem = null;

  useEffect(() => {
    const decryptData = token && JSON.parse(getDecryptData(token));

    const redirectToDashboard = async () => {
      try {
        setLoading(true);
        const payload = {
          query: Query.verifyWhatsAppAuthToken,
          variables: token,
        };
        const result = await getDataToServerForAuth(payload);
        setLoading(false);
        if (result?.verifyWhatsAppAuthToken?.status === 200) {
          userService.setSession(result?.verifyWhatsAppAuthToken?.accessToken);
          dispatch(setUserData(result?.verifyWhatsAppAuthToken?.user));
          history.push('business/dashboard/');
        } else {
          enqueueSnackbar(result?.verifyWhatsAppAuthToken?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error during authentication:', error);
      }
    };

    if (decryptData?.isPersonalDetails) {
      redirectToDashboard();
    }
  }, [dispatch, token, enqueueSnackbar]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const tokenValue = location.search?.replace('?token=', '');
        if (!tokenValue) {
          throw new Error('Token is missing');
        }
        setToken(tokenValue);
        const decryptedData = JSON.parse(await getDecryptData(tokenValue));
        setUserValue(decryptedData);
        setIsPersonalDetailsFilled(decryptedData?.isPersonalDetails);
      } catch (error) {
        console.error('Error fetching token:', error.message);
      }
    };

    fetchToken();
  }, [location.search]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            localStorage.setItem('ipData', locationData);
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: planQuery.getPackages,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: 'month',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getPackages?.status === 200) {
        setPackagesData(result?.getPackages?.data);
      }
    };

    fetchData();
  }, [dispatch, ipBasedData?.countryId]);

  const handleNextRedirect = async () => {
    try {
      const payload = {
        query: Query.verifyWhatsAppAuthToken,
        variables: {
          data: isPersonalDetailsFilled
            ? token
            : getEncryptedData(
                JSON.stringify({
                  ...JSON.parse(getDecryptData(token)),
                  countryId: ipBasedData?.countryId,
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  userPackageId: parseInt(selectedPackageTypeId, 10),
                })
              ),
        },
      };
      const result = await getDataToServerForAuth(payload);
      if (result?.verifyWhatsAppAuthToken?.status === 200) {
        userService.setSession(result?.verifyWhatsAppAuthToken?.accessToken);
        dispatch(setUserData(result?.verifyWhatsAppAuthToken?.user));
        history.push('business/dashboard/');
      } else {
        enqueueSnackbar(result?.verifyWhatsAppAuthToken?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error during WhatsApp auth token verification:', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="block">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <img src="assets/images/logo/logo_green.png" className="w-[170px]" alt="logo" />
            <Typography className="mt-28 text-32 font-semibold tracking-tight leading-tight">
              {t('personalInformation.personalInformation')}
            </Typography>
          </div>
          <div className="mt-24 max-w-320 m-auto">
            <TextField
              className="w-full"
              size="small"
              value={userValue?.firstName}
              onChange={(e) => setUserValue({ ...userValue, firstName: e.target.value })}
            />
          </div>
          <div className="mt-24 max-w-320 m-auto">
            <TextField
              className="w-full"
              size="small"
              value={userValue?.lastName}
              onChange={(e) => setUserValue({ ...userValue, lastName: e.target.value })}
            />
          </div>
          <div className="mt-24 max-w-320 m-auto">
            <Typography>{t('signUpPage.messages.termsAndConditionsMessage')}</Typography>
            <Typography className="text-darkgreen">
              <a href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}>
                {' '}
                {t('signUpPage.messages.termsAndCondition')}
              </a>{' '}
              <span className="text-black">and </span>{' '}
              <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK}>
                {' '}
                {t('signUpPage.messages.pricyPolicy')}{' '}
              </a>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className="w-full md:text-18 mt-24 font-semibold rounded-md"
              aria-label="Sign in"
              type="button"
              onClick={() => handleNextRedirect()}
            >
              {t('signUpPage.buttons.next')}
            </Button>
          </div>
          <div>
            <span className="block text-center mb-20 text-16 font-semibold text-darkgreen">
              {t('signUpPage.messages.selectPlan')}
            </span>
            <FormControl className="block mb-28">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                className="grid grid-cols-1 gap-20 lg:gap-20"
                value={selectedPackageTypeId}
                onChange={(event) => setSelectedPackageTypeId(event?.target?.value)}
              >
                {packagesData
                  ?.filter((packageValue) => packageValue?.id !== 1)
                  .sort((a, b) => {
                    if (a?.packageIndex < b?.packageIndex) {
                      return -1;
                    }
                    if (a?.packageIndex > b?.packageIndex) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((packageValue, index) => {
                    const previousItem = prevItem;
                    prevItem = packageValue;
                    return (
                      <FormControlLabel
                        key={index}
                        value={packageValue?.packageTypeId}
                        control={
                          <Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />
                        }
                        label={
                          <code className="flex justify-between items-center w-full">
                            <span className="text-16 font-semibold inline-block capitalize select-plan-signup">
                              {packageValue.name}
                              <span className="option-info h-full inline-block">
                                <Icon className="text-16 relative top-3 ml-3">info</Icon>
                                <span className="option-description absolute hidden bg-darkgreen text-white rounded-md p-2 mt-1 w-full left-0 right-0 z-30 max-h-320 overflow-y-auto">
                                  <ul className="list-disc list-inside px-14 pt-8 pb-14">
                                    {Object.values(JSON.parse(packageValue.packageDetails))
                                      .filter((item, idx) => idx < 18)
                                      .map((feature, i) => (
                                        <li className="mb-0" key={i}>
                                          <span className="text-12 font-semibold relative -left-10">
                                            {feature.name}
                                          </span>
                                        </li>
                                      ))}
                                  </ul>
                                </span>
                              </span>
                            </span>
                            <span>
                              <span
                                className={`${
                                  packageValue?.offerAmount
                                    ? 'line-through font-medium text-16 md:text-16'
                                    : 'text-16 font-medium inline-block capitalize'
                                }`}
                              >
                                <span>{packageValue?.currency === 'INR' ? '₹' : '$'}</span>
                                {packageValue?.amount}
                              </span>
                              {packageValue?.offerAmount && (
                                <h3 className="my-0 text-16 md:text-16 font-semibold inline-block ml-5 font-semibold text-16 md:text-16">
                                  <span>{packageValue?.currency === 'INR' ? '₹' : '$'}</span>
                                  {packageValue.offerAmount}
                                </h3>
                              )}
                              /Month
                            </span>
                          </code>
                        }
                        className={`rounded-md p-10 border m-0 relative block w-full border-1 border-solid ${
                          parseInt(selectedPackageTypeId, 10) === packageValue?.packageTypeId
                            ? 'bg-[#006B5A] border-[#006B5A] text-white'
                            : 'bg-white border-grey '
                        }`}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Paper>
      <SigninBoxContent />
    </div>
  );
};

export default WhatsappSign;

import CreateReviewsOrMessage from './common/CreateReviewsOrMessage';
import SelectCustomerList from './common/SelectCustomerList';
import SelectPlateFormType from './common/SelectPlateFormType';
import SendSmsOrEmail from './common/SendSmsOrEmail';
import ViewBulkMessageDetails from './common/ViewBulkMessageDetails';
import History from './common/History';
import ViewIndividualDetails from './common/ViewIndividualDetails';

const TargetMessageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'invite-reviews/:id?',
      element: <SelectPlateFormType />,
    },
    {
      path: 'create-new-review/:id?',
      element: <CreateReviewsOrMessage />,
    },
    {
      path: 'select-invite-review-customer/:id?',
      element: <SelectCustomerList />,
    },
    {
      path: 'send-reviews/:id?',
      element: <SendSmsOrEmail />,
    },
    {
      path: 'send-message/:id?',
      element: <SelectPlateFormType />,
    },
    {
      path: 'create-new-message/:id?',
      element: <CreateReviewsOrMessage />,
    },
    {
      path: 'select-send-message-customer/:id?',
      element: <SelectCustomerList />,
    },
    {
      path: 'send-messages/:id?',
      element: <SendSmsOrEmail />,
    },
    {
      path: 'invite-review-history/:id?',
      element: <History />,
    },
    {
      path: 'send-message-history/:id?',
      element: <History />,
    },
    {
      path: 'view-bulk-details/:campaignServicesId/:id?',
      element: <ViewBulkMessageDetails />,
    },
    {
      path: 'view-individual-details/:campaignServicesId/:id?',
      element: <ViewIndividualDetails />,
    },
  ],
};

export default TargetMessageConfig;

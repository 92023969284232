import { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import history from '@history';
import moment from 'moment';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import FuseLoading from '@fuse/core/FuseLoading';
import Pagination from '../../../../component/table/Pagination';
import TableHeader from '../../../../component/table/TableHeader';
import businessData from '../../../query/business';
import { getParamsId, handleApiRequest } from '../../../common/common';
import reducer from '../store';

const ViewBulkMessageDetails = () => {
  const headerData = [
    t('sendMessage.scheduleColumn.srNo'),
    t('sendMessage.scheduleColumn.name'),
    t('sendMessage.scheduleColumn.email'),
    t('sendMessage.scheduleColumn.phoneNo'),
    t('sendMessage.scheduleColumn.status'),
  ];
  const routeParams = useParams();
  const [loading, setLoading] = useState(false);
  const [queueMessageData, setQueueMessageData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const viewMessageDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.viewMessageDetailsData
  );

  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const payload = {
        query: businessData.getAllQueueMessages,
        variables: {
          campaignServicesId: parseInt(routeParams?.campaignServicesId, 10),
          pageSize: 10,
          pageNumber: 1,
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getAllQueueMessages?.status === 200) {
        setQueueMessageData(result?.getAllQueueMessages?.data);
        setTotalCount(result?.getAllQueueMessages?.totalCount);
      }
    }
    fetchData();
  }, [routeParams.campaignServicesId]);

  useEffect(() => {
    if (!viewMessageDetailsData) {
      history.go(-1);
    }
  }, [viewMessageDetailsData]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleSendAnotherMessage = () => {
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'send_messages') {
      if (id) {
        history.push(`send-message/${id}`);
      } else {
        history.push('send-message/');
      }
    }
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    } else if (id) {
      history.push(`invite-reviews/${id}`);
    } else {
      history.push('invite-reviews/');
    }
  };

  const handleChangePage = async (event, value) => {
    await setPage(value + 1);
    const payload = {
      query: businessData.getAllQueueMessages,
      variables: {
        campaignServicesId: parseInt(routeParams?.campaignServicesId, 10),
        pageSize: rowsPerPage,
        pageNumber: value + 1,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAllQueueMessages?.status === 200) {
      setQueueMessageData(result?.getAllQueueMessages?.data);
      setTotalCount(result?.getAllQueueMessages?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    await setRowsPerPage(event.target.value);
    await setPage(1);
    const payload = {
      query: businessData.getAllQueueMessages,
      variables: {
        pageSize: event.target.value,
        pageNumber: page,
        campaignServicesId: parseInt(routeParams?.campaignServicesId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAllQueueMessages?.status === 200) {
      setQueueMessageData(result?.getAllQueueMessages?.data);
      setTotalCount(result?.getAllQueueMessages?.totalCount);
    }
  };

  return (
    <div className="p-20 lg:p-40">
      <Typography className="top-24 text-3xl font-semibold text-black text-lg font-bold">
        {t('sendMessage.sendSmsDetails')}
      </Typography>
      <div className="flex gap-20">
        <div className="bg-white w-[60%] lg:w-[70%] rounded-md relative shadow p-20 mt-20">
          <Typography className="text-16 text-black font-bold font-medium capitalize">
            {t('sendMessage.message')}
          </Typography>
          <hr className="border border-gray border-dashed my-12" />
          {viewMessageDetailsData?.campaign_platform?.name === 'Email' ? (
            <div>
              <Typography className="font-medium">
                {viewMessageDetailsData?.campaign_services_standard_messages?.htmlSubject}
              </Typography>
              <p
                className="pt-12"
                dangerouslySetInnerHTML={{
                  __html: viewMessageDetailsData?.campaign_services_standard_messages?.htmlTemplate,
                }}
              />
            </div>
          ) : (
            <Typography>
              {viewMessageDetailsData?.campaign_services_standard_messages?.messages}
            </Typography>
          )}
        </div>
        <div className="bg-white rounded-md w-[37%] lg:w-[30%] relative shadow p-20 mt-20">
          <div className="sm:flex justify-between items-center">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/calendar.png" alt="" />
              <Typography className="text-black text-left tracking-normal">
                {t('sendMessage.date')}
              </Typography>
            </div>
            <Typography className="text-14 text-black font-bold">
              {moment(queueMessageData?.scheduleDate).format('MMM DD, YYYY')}
            </Typography>
          </div>
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/clock.png" alt="" />
              <Typography className="tracking-normal text-black text-left">
                {t('sendMessage.time')}
              </Typography>
            </div>
            <Typography className="text-14 text-black font-bold">
              {moment(queueMessageData?.scheduleDate).format('hh.mm A')}
            </Typography>
          </div>
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/squarecheck.png" alt="" />
              <Typography className="tracking-normal text-black text-left">
                {t('sendMessage.selectPlatform')}
              </Typography>
            </div>
            <Typography className="text-14 text-black font-bold">
              {viewMessageDetailsData?.campaign_platform?.name}
            </Typography>
          </div>
          <div className="sm:flex justify-between items-center pt-12">
            <div className="flex gap-8 items-center">
              <img className="w-[30px]" src="/assets/images/icon/user.png" alt="" />
              <Typography className="tracking-normal text-black text-left">
                {t('sendMessage.totalCustomers')}
              </Typography>
            </div>
            <Typography className="text-14 text-black font-bold">
              {viewMessageDetailsData?.totalCustomers}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <Typography className="text-20 text-black font-bold py-20">
          {t('sendMessage.customerList')}
        </Typography>
        <Button
          className="text-16 text-white rounded px-16 font-medium bg-darkgreen hover:bg-darkgreen py-20"
          onClick={() => handleSendAnotherMessage()}
        >
          {t('sendMessage.sendAnotherMessage')}
        </Button>
      </div>
      <div className="bg-white rounded-md relative shadow pl-16">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <TableHeader headerData={headerData} />
            <TableBody>
              {queueMessageData?.campaign_services_queue?.length > 0 &&
                queueMessageData?.campaign_services_queue?.map((item, i) => {
                  return (
                    <TableRow
                      hover
                      className="border border-l-0 border-t-2 border-t-grey-100"
                      role="checkbox"
                      key={i}
                      tabIndex={-1}
                    >
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-[10%]"
                        align="left"
                      >
                        {i + 1}
                      </TableCell>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-[25%]"
                        align="left"
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-[25%]"
                        align="left"
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-[25%]"
                        align="left"
                      >
                        {item.countryCode} {item.phoneNumber}
                      </TableCell>
                      <TableCell
                        className={`text-16 font-semibold pl-0 w-[25%] ${
                          item.status === 'queued' && 'text-[#FF8800]'
                        } ${item.status === 'delivered' && 'text-[#00BF26]'} ${
                          item.status === 'failed' && 'text-[#FF0000]'
                        } ${item.status === 'scheduled' && 'text-[#FF8800]'}`}
                        align="left"
                      >
                        {item.status}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default withReducer('state', reducer)(ViewBulkMessageDetails);

const getVisitedBusinessBySalesPerson = `
query getVisitedBusinessBySalesPerson(
  $pageNumber: Int
  $pageSize: Int
  $searchText: String
  $isBookmark: Boolean
  $sortBy: String
  $sortOrder: OrderDirection
) {
  getVisitedBusinessBySalesPerson(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchText: $searchText
    isBookmark: $isBookmark
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    totalCount
    data {
      id
      name
      address
      business_types {
        id
        bType
      }
      countries {
        id
        name
      }
      cts
      uts
      validity
      demo_businesses_feedback {
        feedback
        phoneNumber
        countryCode
        sales_person_visited_status{
          type
        }
      }
      users {
        id
        fullName
        email
        userName
        userStatusId
        phoneNumber{
          isoCode
          phoneNumber
          countryCode
        }
        user_package_subscription {
          startDate
          expireDate
        }
      }
      demo_businesses_follow_up_feedback{
        businessId
        cts
        id
        followUpDate
        note
      }
      states {
        id
        name
      }
      user_business_review_platforms {
        user_businesses_status {
          id
          status
        }
      }
    }
    message
    status
  }
}
`;

const visitedBusinessData = {
  getVisitedBusinessBySalesPerson,
};

export default visitedBusinessData;

import { io } from 'socket.io-client';
import userService from './app/services/userService';

const URL = process.env.REACT_APP_SOCKET;
const socket = io(URL, {
  extraHeaders: {
    Authorization: `Bearer ${userService.getAccessToken() || null}`,
  },
});
export { socket };

import BusinessType from './BusinessType';
import BusinessAttribute from './BusinessAttribute';
import BusinessAttributeType from './BusinessAttributeType';
import BusinessList from './BusinessList';

const BusinessConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'business-type',
      element: <BusinessType />,
    },
    {
      path: 'business-attribute',
      element: <BusinessAttribute />,
    },
    {
      path: 'business-attribute-type',
      element: <BusinessAttributeType />,
    },
    {
      path: 'business-list',
      element: <BusinessList />,
    },
  ],
};

export default BusinessConfig;

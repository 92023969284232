const getReviewPlatformForPortal = `
query getReviewPlatformForPortal(
  $reviewPlatformId: Int
  $pageNumber: Int
  $pageSize: Int
  $sortBy: String
  $sortOrder: OrderDirection
  $isActive: Boolean
) {
  getReviewPlatformForPortal(
    reviewPlatformId: $reviewPlatformId
    pageNumber: $pageNumber
    pageSize: $pageSize
    sortBy: $sortBy
    sortOrder: $sortOrder
    isActive: $isActive
  ) {
    data {
      id
      countryId
      cts
      isActive
      logo
      name
      url
    }
    totalCount
    message
    status
  }
}

`;

const addReviewPlatform = `
mutation addReviewPlatform($name: String!,  $logo: Upload!, $countryId: [Int!]!, $url: String!) {
   addReviewPlatform(
     name: $name
     logo: $logo
     countryId: $countryId
     url: $url
   ) {
     status
     message
   }
 }
`;

const updateReviewPlatform = `
mutation updateReviewPlatform($reviewPlatformId: Int!, $name: String, $logo: Upload, $countryId: [Int!], $url: String, $isActive: Boolean) {
  updateReviewPlatform(reviewPlatformId: $reviewPlatformId, name: $name, logo: $logo, countryId: $countryId, url: $url, isActive: $isActive) {
    status
    message
  }
}
`;
const enableDisableReviewType = `
mutation ($reviewPlatformId: Int!, $isEnable: Boolean!) {
  enableDisableReviewType(reviewPlatformId: $reviewPlatformId, isEnable: $isEnable) {
      message
      status 
		}
	}
`;

const getAllBusinessReviews = `
query getAllBusinessReviews($placeId: String!, $filterReviewRating: Int, $platformId: Int!, $pageNumber:Int, $pageSize: Int, $sortBy: String, $sortOrder: OrderDirection, $attributeId: Int, $ratingType: String){
  getAllBusinessReviews(
  placeId: $placeId
  filterReviewRating: $filterReviewRating
  platformId: $platformId
  pageNumber: $pageNumber
  pageSize: $pageSize,
  sortBy:$sortBy,
  sortOrder:$sortOrder,
  attributeId: $attributeId,
  ratingType: $ratingType
) {
  data {
    id
    review
    reviewCts
    reviewRating
    reviewerName
    responseFromOnwer
    reviewerProfileUrl
    imageLinks
    emotionPair {
      emoji
      emotion
    }
    videoLinks {
      link
      thumb
    }
  }
  status
  message
  totalReviews
}
}
`;

const getReviewFilter = `query {
  getReviewFilter{
    data{
      cts
      id
      uts
      filterReviewRating
      type
      isActive
    }
    status
    message
  
  }
}`;

const defaultReviewPlatform = `mutation($businessId: Int!, $defaultPlatformId: Int!) {
  defaultReviewPlatform(businessId: $businessId, defaultPlatformId: $defaultPlatformId){
    status
    message
  
  }
}`;

const getOnlineBusinessReview = `query getOnlineBusinessReview(
  $businessId: Int!,
  $orderBy: String,
  $pageNumber: Int,
  $pageSize: Int,
  $reviewPlatformId: Int!,
  $filterReviewRating: Int
  $sortBy: String,
){
getOnlineBusinessReview(
  businessId: $businessId,
  orderBy: $orderBy,
  pageNumber: $pageNumber,
  pageSize: $pageSize,
  reviewPlatformId: $reviewPlatformId,
  filterReviewRating: $filterReviewRating
  sortBy: $sortBy,
){
    status
    message
    totalReview
    data {
      id
      review
      businessId
      rating
      cts
      reviewerName
    }
  }

}
`;

const reviewPlatformData = {
  getReviewPlatformForPortal,
  addReviewPlatform,
  updateReviewPlatform,
  enableDisableReviewType,
  getAllBusinessReviews,
  getReviewFilter,
  defaultReviewPlatform,
  getOnlineBusinessReview,
};

export default reviewPlatformData;

import ReviewPlatform from './ReviewPlatform';

const ReviewPlatformConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'review-platform',
      element: <ReviewPlatform />,
    },
  ],
};

export default ReviewPlatformConfig;

import CryptoJS from 'crypto-js';
import axios from 'axios';
import Icon from '@mui/material/Icon';
import userService from '../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

const forAuthBaseURL = process.env.REACT_APP_AUTH_ENDPOINT;
const instanceForAuth = axios.create({
  forAuthBaseURL,
});

export const countryData = (countryId = '', phoneCountry = '') => {
  const country = [
    {
      id: 2,
      name: 'India',
      countryCode: 91,
      isoCode: 'IND',
      isActive: true,
      currency: 'INR',
      symbol: '₹',
      flag: 'https://flagcdn.com/w320/in.png',
      phoneCountry: 'in',
    },
    {
      id: 1,
      name: 'United States',
      countryCode: 1,
      isoCode: 'USA',
      isActive: true,
      currency: 'USD',
      symbol: '$',
      flag: 'https://flagcdn.com/w320/us.png',
      phoneCountry: 'us',
    },
  ];
  if (countryId) {
    return country.find((element) => {
      return element.id === countryId;
    });
  }
  if (phoneCountry) {
    return country.find((element) => {
      return element.phoneCountry === phoneCountry;
    });
  }
  return country;
};

export const getParamsId = () => {
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  if (/^\d+$/.test(lastPart)) {
    return lastPart;
  }
  return '';
};

export const getEncryptedData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(value, publicKeyValue).toString();
  } catch (error) {
    throw new Error(error);
  }
};

export const getDecryptData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    const decrypted = CryptoJS.AES.decrypt(value, publicKeyValue);
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error(error);
  }
};

export const handleApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  try {
    const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServerForAuth = async (data) => {
  instanceForAuth.defaults.headers.common.Authorization = `Bearer ${
    userService.getAccessToken() || null
  }`;
  try {
    const response = await instanceForAuth.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleWabaApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  try {
    const response = await instance.post(process.env.REACT_APP_WABA_API_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.icon) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.icon"]}`);
    formData.append(0, data?.variables?.icon);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToSend = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.profileImage) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.profileImage"]}`);
    formData.append(0, data?.variables?.profileImage);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const storeCsvFileDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.file) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.file"]}`);
    formData.append(0, data?.variables?.file);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const serverApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleAuthApi = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.image) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.image"]}`);
    formData.append(0, data?.variables?.image);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const avatarDataSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.avatarImageFile) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.avatarImageFile"]}`);
    formData.append(0, data?.variables?.avatarImageFile);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const Message = {
  emailRegex: /\S+@\S+\.\S+/,
};

export const reviewFormate = (reviewData) => {
  return [...Array(5)].map((_, i) => {
    if (i + 1 <= reviewData) {
      return (
        <Icon className="text-16 align-middle mr-2 text-yellow-800" key={i}>
          star
        </Icon>
      );
    }

    if (reviewData - i > 0 && reviewData - i < 1) {
      return (
        <>
          <Icon className="text-16 align-middle mr-2 text-yellow-800" key={i}>
            star_half
          </Icon>
        </>
      );
    }

    return (
      <Icon className="text-16 align-middle mr-2 text-gray-400" key={i}>
        star
      </Icon>
    );
  });
};

export const sortOptions = (inputValues, options) => {
  if (!inputValues) return options;

  const normalizedInputValue = inputValues.toLowerCase();

  return options.sort((a, b) => {
    const aValue = a.label.toLowerCase();
    const bValue = b.label.toLowerCase();

    if (aValue === normalizedInputValue) return -1;
    if (bValue === normalizedInputValue) return 1;

    if (aValue.startsWith(normalizedInputValue) && !bValue.startsWith(normalizedInputValue))
      return -1;
    if (!aValue.startsWith(normalizedInputValue) && bValue.startsWith(normalizedInputValue))
      return 1;

    return aValue.localeCompare(bValue);
  });
};

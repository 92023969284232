import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
});

export const forgotPassword = createAsyncThunk('reset-password/forgotPassword', async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    localStorage.getItem('authToken') || null
  }`;
  instance.defaults.headers.common.isrequestfrom = true;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    const result = await response.data;
    localStorage.removeItem('authToken');
    return result;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState: null,
  reducers: {
    resetresetPassword: (state, action) => {
      if (state) {
        state.resetPasswordData = {};
      }
    },
  },
  extraReducers: {
    [forgotPassword.fulfilled]: (state, action) => ({
      ...state,
      forgotPasswordData: action.payload,
    }),
  },
});

export const { resetresetPassword } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;

import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import history from '@history';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import Loading from './Loading';
import './MangeBusiness.css';
import UserProfile from '../../common/userProfile';
import manageBusinessData from '../../query/manageBusiness';
import reducer from './store';
import { getParamsId, handleApiRequest } from '../../common/common';
import DeletePopUp from './PopUp';

function StaffMember() {
  const { t } = useTranslation();
  const [businessAgentList, setBusinessAgentList] = useState([]);
  const [agentSupportStatus, setAgentSupportStatus] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [staffMemberId, setStaffMemberId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [agentData, setAgentData] = useState({
    agentName: '',
    agentRole: '',
  });
  const [editData, setEditData] = useState();
  const [isEmpty, setIsEmpty] = useState({ agentName: false, agentRole: false });
  const businessId = localStorage.getItem('businessId');

  const fetchData = useCallback(async () => {
    const payload = {
      query: manageBusinessData.getBusinessAgentList,
      variables: {
        businessId: parseInt(businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getBusinessAgentList?.status === 200) {
      setBusinessAgentList(result?.getBusinessAgentList?.data);
    }
  }, [businessId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      setPageLoading(true);
      const payloadData = {
        query: manageBusinessData.getAgentSupportStatus,
        variables: {
          businessId: parseInt(businessId, 10),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      setPageLoading(false);
      if (resultData?.getAgentSupportStatus?.status === 200) {
        setAgentSupportStatus(resultData?.getAgentSupportStatus?.data?.agentSupport);
      }
    };
    fetchAgentSupportStatus();
  }, [businessId]);

  const handleRemove = () => {
    setOpenDialogue(false);
    setAgentData();
    setStaffMemberId();
    setEdit(false);
  };

  if (pageLoading) {
    return <Loading />;
  }

  const addStaffMember = async () => {
    const isNumeric = (str) => /^\d+$/.test(str);
    if (isNumeric(agentData?.agentName) || isNumeric(agentData?.agentRole)) {
      enqueueSnackbar(t('manageBusiness.addBusinessSteps.staffMember.validationMessage'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    setRefreshIcon(true);
    const payload = {
      query: !edit ? manageBusinessData.addBusinessAgent : manageBusinessData.updateBusinessAgent,
      variables: {
        businessId: parseInt(businessId, 10),
        name: agentData?.agentName,
        role: agentData?.agentRole,
        ...(edit && {
          businessAgentId: parseInt(staffMemberId, 10),
        }),
      },
    };
    try {
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      const res = edit ? result?.updateBusinessAgent : result?.addBusinessAgent;

      if (res?.status === 200) {
        fetchData();
        handleRemove();
        if (!edit) {
          const obj = {
            query: manageBusinessData.setAgentSupportStatus,
            variables: {
              businessId: parseInt(businessId, 10),
              isActive: true,
            },
          };
          await handleApiRequest(obj);
          setAgentSupportStatus(true);
        }
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      enqueueSnackbar('An error occurred', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const staffMemberSupport = async () => {
    setAgentSupportStatus(!agentSupportStatus);
    const payload = {
      query: manageBusinessData.setAgentSupportStatus,
      variables: {
        businessId: parseInt(businessId, 10),
        isActive: !agentSupportStatus,
      },
    };
    const result = await handleApiRequest(payload);
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;
    setAgentData({ ...agentData, [field]: value });
    setIsEmpty({ ...isEmpty, [field]: value.trim() === '' });
  };

  const editStaffSupportMember = (value) => {
    setEdit(true);
    setOpenDialogue(true);
    setStaffMemberId(value?.id);
    setAgentData({
      agentName: value?.name,
      agentRole: value?.role,
    });
    setEditData({
      agentName: value?.name,
      agentRole: value?.role,
    });
  };

  const deleteStaffMember = async () => {
    if (staffMemberId) {
      const payload = {
        query: manageBusinessData.deleteBusinessAgent,
        variables: {
          businessAgentId: parseInt(staffMemberId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteBusinessAgent?.status === 200) {
        fetchData();
        cancelDeleteStaff();
      }
    }
  };

  const handleRedirect = () => {
    localStorage.removeItem('activeStepNumber');
    localStorage.removeItem('businessId');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    const id = getParamsId();
    if (id) {
      history.push(`/business/dashboard/${id}`);
    } else {
      history.push('/business/dashboard');
    }
  };

  const deleteStaff = async (value) => {
    setOpenDeleteDialogue(true);
    setStaffMemberId(value);
  };

  const cancelDeleteStaff = async (value) => {
    setStaffMemberId(value);
    setOpenDeleteDialogue(false);
    setIsEmpty({
      agentName: false,
      agentRole: false,
    });
  };

  return (
    <div className="text-left">
      <h3 className="text-24 font-bold mb-12">
        {t('manageBusiness.addBusinessSteps.staffMember.addStaffMember')}
      </h3>
      <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
        {t('manageBusiness.addBusinessSteps.staffMember.pageDesc')}
      </Typography>
      {businessAgentList?.length > 0 && (
        <div className="bg-grey-50 px-20 py-16 rounded-md mt-32">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-18 font-semibold mb-8">
                {' '}
                {t('manageBusiness.addBusinessSteps.staffMember.staffMembersSupport')}
              </h3>
              <Typography className="block text-14 font-medium text-grey-700">
                {agentSupportStatus
                  ? t('manageBusiness.addBusinessSteps.staffMember.staffMembersSupportDisableDesc')
                  : t('manageBusiness.addBusinessSteps.staffMember.staffMembersSupportDesc')}
              </Typography>
            </div>
            <Switch checked={agentSupportStatus} onChange={() => staffMemberSupport()} />
          </div>
        </div>
      )}
      <div className="mx-auto mt-24">
        <div className="bg-grey-50 px-20 py-16 rounded-md">
          <div className="flex justify-between border-b-1 border-solid border-grey pb-14 mb-20">
            <h4 className="text-20 font-semibold ">
              {t('manageBusiness.addBusinessSteps.staffMember.title')}
            </h4>
            {businessAgentList?.length > 0 && (
              <Button
                variant="contained"
                color="secondary"
                className="md:text-14 font-medium disabled:text-black rounded-full px-28 min-h-28 py-0"
                aria-label="add"
                size="small"
                onClick={() => setOpenDialogue(true)}
                type="button"
              >
                {t('manageBusiness.addBusinessSteps.productServices.businessProduct.LAdd')}
              </Button>
            )}
          </div>
          {businessAgentList?.length > 0 ? (
            <>
              {businessAgentList?.map((item, index) => {
                return (
                  <div
                    className="items-center justify-between rounded-sm mx-auto flex items-center md:py-2 border-b-1 border-grey-200 border-solid h-48"
                    key={index}
                  >
                    <span className="block md:text-16 text-14 font-semibold capitalize flex items-center">
                      <UserProfile name={item.name} />{' '}
                      <span className="ml-16 inline-block">{item?.name}</span>
                    </span>
                    <div className="flex items-center gap-24">
                      <span className="block md:text-16 text-14 font-medium text-grey-600 capitalize">
                        {item?.role}
                      </span>
                      <div>
                        <div>
                          <Button
                            variant="contained"
                            color="inherit"
                            className="md:text-14 font-medium text-grey-700 disabled:text-black rounded-full p-0 bg-white mr-16 min-h-32 w-32 min-w-32 w-32 h-32"
                            aria-label="edit"
                            onClick={() => editStaffSupportMember(item)}
                            type="button"
                          >
                            <Icon className="text-20">edit</Icon>
                          </Button>
                          <Button
                            variant="contained"
                            color="inherit"
                            className="md:text-14 font-medium text-grey-700 disabled:text-black rounded-full p-0 bg-white min-h-32 w-32 min-w-32 w-32 h-32"
                            aria-label="delete"
                            onClick={() => deleteStaff(item.id)}
                            type="button"
                          >
                            <Icon className="text-20">delete</Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="text-center md:py-20 py-10">
                <h5 className="text-20 font-semibold mb-20">
                  {t('manageBusiness.addBusinessSteps.staffMember.addStaffMember')}
                </h5>
                <Typography className="text-14 font-medium mb-20 max-w-384 mx-auto">
                  {t('manageBusiness.addBusinessSteps.staffMember.staffMemberDesc')}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 font-medium disabled:text-black rounded-full px-28"
                  aria-label="add"
                  onClick={() => setOpenDialogue(true)}
                  type="button"
                >
                  <Icon>add</Icon>
                  {t('manageBusiness.addBusinessSteps.staffMember.addStaffMemberButton')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="text-right mt-48 md:mb-96 mb-36">
        <Button
          variant="outlined"
          color="secondary"
          className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
          aria-label="skip"
          onClick={() => handleRedirect()}
          type="button"
        >
          {t('manageBusiness.button.skip')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
          aria-label="confirm"
          disabled={businessAgentList?.length === 0}
          onClick={() => handleRedirect()}
          type="button"
        >
          {t('manageBusiness.addBusinessSteps.customAttribute.saveAndNext')}
        </Button>
      </div>
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={() => handleRemove()}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {!edit
              ? t('manageBusiness.addBusinessSteps.staffMember.dialog.add')
              : t('manageBusiness.addBusinessSteps.staffMember.dialog.edit')}{' '}
            {t('manageBusiness.addBusinessSteps.staffMember.dialog.title')}
          </Typography>
        </DialogTitle>

        <form noValidate className="flex flex-col md:overflow-hidden">
          <DialogContent className="p-0 mb-24">
            <div className="block mb-24">
              <InputLabel
                htmlFor="name"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center"
              >
                {t('manageBusiness.addBusinessSteps.staffMember.dialog.form.staffMemberName.name')}
                <span className="text-red-500 text-2xl">*</span>
              </InputLabel>
              <TextField
                placeholder={t(
                  'manageBusiness.addBusinessSteps.staffMember.dialog.form.staffMemberName.placeHolder'
                )}
                size="small"
                onChange={handleInputChange('agentName')}
                value={agentData?.agentName}
                id="name"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty.agentName && (
                <Typography className="text-red pt-8 text-14">
                  {t(
                    'manageBusiness.addBusinessSteps.staffMember.dialog.form.staffMemberName.validationMessage'
                  )}
                </Typography>
              )}
            </div>
            <div className="block mb-24">
              <InputLabel
                htmlFor="role"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center"
              >
                {t('manageBusiness.addBusinessSteps.staffMember.dialog.form.role.name')}
                <span className="text-red-500 text-2xl">*</span>
              </InputLabel>
              <TextField
                placeholder={t(
                  'manageBusiness.addBusinessSteps.staffMember.dialog.form.role.placeHolder'
                )}
                size="small"
                onChange={handleInputChange('agentRole')}
                value={agentData?.agentRole}
                id="role"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty.agentRole && (
                <Typography className="text-red pt-8 text-14">
                  {t(
                    'manageBusiness.addBusinessSteps.staffMember.dialog.form.role.validationMessage'
                  )}
                </Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
              variant="contained"
              color="secondary"
              size="small"
              disabled={
                !agentData?.agentName ||
                !agentData?.agentRole ||
                (agentData?.agentRole === editData?.agentRole &&
                  agentData?.agentName === editData?.agentName)
              }
              onClick={() => addStaffMember()}
            >
              {t('manageBusiness.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <DeletePopUp
        openValue={openDeleteDialogue}
        eventCall={deleteStaffMember}
        cancelCall={cancelDeleteStaff}
        refreshIcon={refreshIcon}
        popUpDescription={t('manageBusiness.addBusinessSteps.staffMember.deleteMessage')}
        popUpMessage={t('manageBusiness.addBusinessSteps.staffMember.confirmStaffDeletion')}
        popUpButtonName={t('manageBusiness.addBusinessSteps.staffMember.dialog.deleteAgent')}
      />
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(StaffMember);

import ServiceRecovery from './ServiceRecovery';

const ServiceRecoveryConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'serviceRecovery/:id?',
      element: <ServiceRecovery />,
    },
  ],
};

export default ServiceRecoveryConfig;

import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { publicIpv4 } from 'public-ip';
import history from '@history';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import { getDataToServer, getEncryptedData, getDecryptData } from '../../common/common';
import pricingCatalogueData from '../../query/pricingCatalogue';
import {
  getPricingCatalogueOnPackageId,
  addPricingCatalogueOnPackageId,
  closePricingCatalogueDialog,
  openPricingCatalogueDialog,
  closeEditPricingCatalogueDialog,
  openEditPricingCatalogueDialog,
  reSetPricingCatalogue,
  updatePackagesStatus,
} from './store/pricingCatalogueSlice';
import reducer from './store';
import UserService from '../../../services/userService';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function PricingCatalogue() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();

  const pricingListData = useSelector(
    ({ pricingCatalogueReducer }) =>
      pricingCatalogueReducer?.pricingCatalogue?.getPricingCatalogueOnPackageIdData?.data
        ?.getPricingCatalogueOnPackageId?.data || ''
  );

  const addPriceCatalogueStatus = useSelector(
    ({ pricingCatalogueReducer }) =>
      pricingCatalogueReducer?.pricingCatalogue?.addPricingCatalogueOnPackageIdData?.data
        ?.addPricingCatalogueOnPackageId || ''
  );

  const appDialog = useSelector(
    ({ pricingCatalogueReducer }) => pricingCatalogueReducer.pricingCatalogue.pricingCatalogueDialog
  );

  const loginUserData = UserService.getUserData();

  const [ipBasedData, setIpBasedData] = useState();

  const defaultCurrency = loginUserData?.currency;

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState();
  const [packageInterval, setPackageInterval] = useState('month');
  const [isAddOn, setIsAddOn] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState([{}]);
  const [refreshIcon, setRefreshIcon] = useState(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = pricingListData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddressData = async () => {
        if (defaultCurrency) {
          setTimeout(() => {
            setFormFields([{ text: '', selectedOption: defaultCurrency }]);
          }, 1000);
        }
      };
      ipAddressData();
    }
  }, [ipBasedData, defaultCurrency]);

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: pricingCatalogueData.getPricingCatalogueOnPackageId,
      variables: {
        packageId: parseInt(routeParams?.planId, 10),
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    dispatch(getPricingCatalogueOnPackageId(payload));

    return () => {
      unmounted = true;
      dispatch(reSetPricingCatalogue());
    };
  }, [dispatch, routeParams?.planId]);

  useEffect(() => {
    if (pricingListData) {
      setData(JSON.parse(getDecryptData(pricingListData)));
    } else {
      setData();
    }
  }, [pricingListData]);

  useEffect(() => {
    if (pricingListData || pricingListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pricingListData]);

  useEffect(() => {
    if (addPriceCatalogueStatus && addPriceCatalogueStatus.status === 400) {
      enqueueSnackbar(addPriceCatalogueStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
    } else if (addPriceCatalogueStatus && addPriceCatalogueStatus.status === 200) {
      enqueueSnackbar(addPriceCatalogueStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      dispatch(closePricingCatalogueDialog());
    }
  }, [enqueueSnackbar, addPriceCatalogueStatus, dispatch]);

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.price')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.createDate')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.updateDate')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.visibility')}</TableSortLabel>
          </TableCell>
          {/* <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.action')}</TableSortLabel>
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    return dispatch(closePricingCatalogueDialog());
  }

  const handleTextChange = (index, value) => {
    const updatedFormFields = [...formFields];
    updatedFormFields[index].text = value;
    setFormFields(updatedFormFields);
  };

  const handleSelectChange = (index, value) => {
    const updatedFormFields = [...formFields];
    updatedFormFields[index].selectedOption = value;
    setFormFields(updatedFormFields);
  };

  const handleAddMore = () => {
    setFormFields([...formFields, { text: '', selectedOption: defaultCurrency }]);
  };

  const handleRemove = (index) => {
    const updatedFormFields = [...formFields];
    updatedFormFields.splice(index, 1);
    setFormFields(updatedFormFields);
  };

  function handelAddPrice() {
    setRefreshIcon(true);
    const payload = {
      query: pricingCatalogueData.addPricingCatalogueOnPackageId,
      variables: {
        packageId: parseInt(routeParams?.planId, 10),
        productId: data?.productId,
        packageInterval,
        currency: formFields[0].selectedOption,
        price: parseInt(formFields[0].text, 10),
        isAddOn,
        isDefault,
      },
    };
    dispatch(addPricingCatalogueOnPackageId(payload));
  }

  const handleVisibilityStatus = async (value) => {
    const payload = {
      query: pricingCatalogueData.updatePackagesStatus,
      variables: {
        packageId: parseInt(value.id, 10),
        isActive: !value?.isActive,
      },
    };
    const result = await dispatch(updatePackagesStatus(payload));
    if (result?.payload?.data?.updatePackagesStatus?.status === 200) {
      const obj = {
        query: pricingCatalogueData.getPricingCatalogueOnPackageId,
        variables: {
          packageId: parseInt(routeParams?.planId, 10),
          sortBy: 'cts',
          sortOrder: 'desc',
        },
      };
      dispatch(getPricingCatalogueOnPackageId(obj));
    }
  };

  return (
    <div className="p-20 lg:p-40">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}

      <div className="flex items-center justify-between">
        <div>
          <Toolbar className="px-0 font-semibold min-h-fit mb-20">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('pricingCatalogue.pricingCatalogueList.title')}
            </Typography>
          </Toolbar>
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-16 md:text-28 font-bold"
              >
                <span className="inline-block bg-white rounded-md w-48 h-48 text-center flex items-center mr-10">
                  <Icon className="text-32 mx-auto">receipt_long</Icon>
                </span>{' '}
                {t('pricingCatalogue.pricingCatalogueDetails.title')} {routeParams?.planName}{' '}
                {t('pricingCatalogue.pricingCatalogueDetails.plan')}
              </Typography>
            </div>
          </div>
        </div>
        <div className="inline-block items-center w-auto float-right">
          {data?.productId && (
            <Button
              className="text-18 px-32"
              variant="contained"
              color="secondary"
              aria-label="Edit"
              onClick={() => {
                dispatch(openPricingCatalogueDialog());
              }}
            >
              {t('pricingCatalogue.addNew.addNew')}
            </Button>
          )}
        </div>
      </div>

      <div className="bg-white rounded-md relative shadow">
        {data?.packages?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={data?.length}
                />
                <TableBody>
                  {data &&
                    data?.packages?.length > 0 &&
                    data?.packages?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.amount}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.uts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Switch
                              checked={row?.isActive}
                              onChange={() => handleVisibilityStatus(row)}
                            />
                          </TableCell>
                          {/* <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-16 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(openPricingCatalogueDialog());
                              }}
                            >
                              {t('pricingCatalogue.pricingCatalogueList.view')}
                            </span>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('pricingCatalogue.pricingCatalogueList.messages.noPricingCatalogue')}
            </Typography>
          </motion.div>
        )}
      </div>

      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={() => closeComposeDialog()}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-dashed border-grey-500 w-full pb-10">
            {t('pricingCatalogue.addNew.title')}
          </Typography>
        </DialogTitle>

        <DialogContent className={` p-0 mb-24`}>
          <div className="mb-36">
            <InputLabel id="price" className="text-16 font-medium text-grey-900">
              {t('pricingCatalogue.addNew.price')}*
            </InputLabel>
            {formFields.map((field, index) => (
              <Box key={index} display="flex" alignItems="center" className="m-0 mt-12">
                <TextField
                  type="number"
                  placeholder="0"
                  sx={{
                    '& .MuiInputBase-input': {
                      borderLeft: 'none',
                      height: '40px',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    },
                    '& .MuiInputBase-root': {
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    },
                  }}
                  className="h-40 w-4/5 overflow-hidden"
                  variant="outlined"
                  value={field.text}
                  onChange={(e) => handleTextChange(index, e.target.value)}
                />
                <FormControl variant="outlined">
                  <Select
                    value={field.selectedOption || ipBasedData?.currencyCode}
                    sx={{ '& .MuiSelect-select': { minHeight: '16px  !important' } }}
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                    className="h-40 border-l-0 rounded-l-none w-80 text-semibold"
                  >
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </FormControl>

                {index !== 0 && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => handleRemove(index)}
                    sx={{ ml: 2 }}
                  >
                    <DeleteIcon />
                  </Button>
                )}
              </Box>
            ))}
            <Button
              className="rounded-md font-semibold text-14 text-darkgreen  hover:text-black p-0"
              type="button"
              color="secondary"
              variant="text"
              onClick={handleAddMore}
            >
              <Icon className="text-16">add</Icon> {t('pricingCatalogue.addNew.addMore')}
            </Button>
          </div>
          <InputLabel id="price" className="text-16 font-medium mb-12 text-black">
            {t('pricingCatalogue.addNew.billingPeriod')}
          </InputLabel>
          <Select
            className="rounded-md bg-white border-1 border-solid border-grey h-40 text-16 w-full leading-5 shadow-none mb-20"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={packageInterval}
            fullWidth
            onChange={(event) => setPackageInterval(event.target.value)}
          >
            <MenuItem value="month" className="capitalize">
              {t('pricingCatalogue.addNew.monthly')}
            </MenuItem>
            <MenuItem value="year" className="capitalize">
              {t('pricingCatalogue.addNew.yearly')}
            </MenuItem>
          </Select>
          <FormControlLabel
            className="block"
            control={
              <Checkbox
                size="small"
                checked={isAddOn}
                onChange={() => setIsAddOn(!isAddOn)}
                name="add_ons"
              />
            }
            label={t('pricingCatalogue.addNew.add')}
          />
          <FormControlLabel
            className="block text-16 font-semibold"
            control={
              <Checkbox
                sx={{
                  borderColor: 'darkgreen',
                  // other styles
                }}
                size="small"
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
                name="default"
              />
            }
            label={t('pricingCatalogue.addNew.default')}
          />
        </DialogContent>
        <DialogActions className="p-0 justify-center flex">
          <Button
            onClick={() => handelAddPrice()}
            variant="contained"
            color="secondary"
            className="text-16 font-medium rounded-md"
            disabled={formFields[0].text <= 0 || refreshIcon}
          >
            {t('pricingCatalogue.addNew.addPrice')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
          <Button
            onClick={() => {
              closeComposeDialog();
              setFormFields([{ text: '', selectedOption: ipBasedData?.currencyCode }]);
            }}
            variant="outlined"
            color="primary"
            className="text-16 font-medium rounded-md"
          >
            {t('pricingCatalogue.addNew.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withReducer('pricingCatalogueReducer', reducer)(PricingCatalogue);

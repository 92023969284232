import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from '@lodash';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import history from '@history';
import './Qrcode.css';
import * as yup from 'yup';
import queryData from '../../query/common';
import qrcodeData from '../../query/qrcodeOrder';
import { getCountries, getStates, createBusinessQrCodeOrder } from './store/qrcodeOrderSlice';
import reducer from './store';
import UserService from '../../../services/userService';
import { getCountryData } from '../../../store/userSlice';

const defaultValues = {
  firstName: '',
  lastName: '',
  businessName: '',
  phone_number: '',
  mail: '',
  quantity: '',
  address: '',
  landmark: '',
  country: '',
  state: '',
  city: '',
  zipCode: '',
};

function QrcodeOrder() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loginUserData = UserService.getUserData();
  const routeParams = useParams();
  const [phoneValue, setPhoneValue] = useState();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState();
  const [countryId, setCountryId] = useState(loginUserData?.countryId);
  const [stateId, setStateId] = useState(0);
  const [qty, setQty] = useState(1);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [phone, setPhone] = useState();
  const [successOrder, setSuccessOrder] = useState(false);
  const countriesData = useSelector(getCountryData);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    businessName: yup.string().required(t('qrcodeOrder.form.businessName.validationMessages')),
    phone_number: yup
      .string()
      .required(t('signUpPage.formField.phoneNumber.validationMessages.required'))
      .min(10, t('signUpPage.formField.phoneNumber.validationMessages.min')),
    mail: yup
      .string()
      .required(t('signInPage.emailValidation'))
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .trim(),
    address: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.address.validationMessages.required')
      )
      .trim(),
    landmark: yup.string().required(t('qrcodeOrder.form.landmark.validationMessages')).trim(),
    city: yup.string().required(t('qrcodeOrder.form.city.validationMessages')),
    zipCode: yup
      .string()
      .required(
        t('appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.required')
      )
      .matches(
        /^[0-9]+$/,
        t('appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.match')
      )
      .test(
        'len',
        `${
          countryId === 2
            ? t(
                'appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.indiaMatch'
              )
            : t(
                'appWebsite.newDomain.registerDomain.formField.zipCode.validationMessages.otherMatch'
              )
        }`,
        (val) => {
          if (countryId === 2) {
            return val && val?.toString().length === 6;
          }
          return (val && val?.toString().length === 5) || (val && val?.toString()?.length === 9);
        }
      ),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    async function fetchData() {
      if (countryId) {
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const res = await dispatch(getStates(payload));
        if (res?.payload?.data?.getStates?.status === 200) {
          setStateList(res?.payload?.data?.getStates?.data);
        }
      }
    }
    fetchData();
  }, [countryId, dispatch]);

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({ ...data, phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10) });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  function handleSelectCountry(event) {
    setCountryId(event.target.value);
    setStateId(0);
  }

  function handleSelectState(event) {
    setStateId(event.target.value);
  }

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const payload = {
      query: qrcodeData.createBusinessQrCodeOrder,
      variables: {
        address: data.address,
        businessName: data.businessName,
        city: data.city,
        countryCode: `+${phone?.dialCode}`,
        countryId: parseInt(countryId, 10),
        email: data.mail,
        firstName: data.firstName,
        landmark: data.landmark,
        lastName: data.lastName,
        phoneNumber: phone?.phoneNumber,
        quantity: parseInt(qty, 10),
        stateId: parseInt(stateId, 10),
        userBusinessId: parseInt(routeParams?.businessId, 10),
        zipCode: parseInt(data.zipCode, 10),
      },
    };
    const result = await dispatch(createBusinessQrCodeOrder(payload));
    setRefreshIcon(false);
    if (result?.payload?.data?.createBusinessQrCodeOrder?.status === 200) {
      enqueueSnackbar(`${t('qrcodeOrder.form.successMsg')}`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setPhone();
      setSuccessOrder(true);
    } else {
      enqueueSnackbar(result?.payload?.data?.createBusinessQrCodeOrder?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  function handleRemove() {
    reset(defaultValues);
    setQty(1);
  }

  return (
    <div className={`p-20 lg:p-40 l-padding mx-auto ${successOrder ? 'w-full' : 'max-w-[1600px]'}`}>
      {successOrder ? (
        <div className="bg-white rounded-md relative shadow md:p-20 p-16  md:order-2 w-full text-center order-height flex items-center">
          <div className="mx-auto">
            <img
              src="/assets/images/business/order_success.png"
              className="img-fluid mx-auto md:pb-40 pb-20"
              alt="..."
            />
            <h2 className="text-24 font-bold">{t('qrcodeOrder.orderSuccess.title')}</h2>
            <Typography className="text-16 font-medium max-w-480 mx-auto mt-16">
              {t('qrcodeOrder.orderSuccess.desc')}
            </Typography>
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mt-24"
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => {
                history.push('orderQR://quickReviewOrderQRSuccess');
              }}
            >
              {t('qrcodeOrder.orderSuccess.button')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full mb-24 pb-16 border-b-1 border-dashed border-grey">
            <div className="flex items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-16 md:text-28 font-bold"
              >
                {t('qrcodeOrder.title')}
              </Typography>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-40">
            <div className="order-2 md:order-1">
              <img
                src="https://quick-review.s3.amazonaws.com/qrcode_preview.jpg"
                className="mx-auto w-auto"
                alt="..."
              />
              <h3 className="md:text-28 text-18 font-bold mt-16">
                Quick Reviews Acrylic QR Code Display Stand (Size: 4 X 6 Inch)
              </h3>
            </div>
            <div className="bg-white rounded-md relative shadow md:p-20 p-16 md:col-span-2 order-1 md:order-2">
              <form name="userDetails" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-16 md:gap-20 grid-cols-1 md:grid-cols-2">
                  <div className="block">
                    <InputLabel id="firstName" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.firstName.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.firstName}
                          helperText={errors?.firstName?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel id="lastName" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.lastName.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.lastName}
                          helperText={errors?.lastName?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      id="businessName"
                      className="text-16 font-medium mb-4 text-grey-900"
                    >
                      {t('qrcodeOrder.form.businessName.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="businessName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.businessName}
                          helperText={errors?.businessName?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      id="phone_number"
                      className="text-16 font-medium mb-4 text-grey-900"
                    >
                      {t('qrcodeOrder.form.phone_number.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <PhoneInput
                      placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                      inputStyle={{
                        width: '100%',
                        height: '37px',
                      }}
                      className="w-full"
                      name="phone_number"
                      required
                      error={!!errors.phone_number}
                      helperText={errors?.phone_number?.message}
                      value={phoneValue}
                      size="small"
                      country={loginUserData?.isoCode?.toLowerCase()}
                      enableSearch="true"
                      onChange={handlePhoneChange}
                    />
                    {phoneValue && (
                      <p
                        className={`text-red ml-12 top-5 relative text-md ${
                          (phoneValue?.length || 0) - (phone?.dialCode?.length || 0) <= 10
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        {errors.phone_number?.message
                          ? t('signUpPage.formField.phoneNumber.validationMessages.min')
                          : ''}
                      </p>
                    )}
                  </div>
                  <div className="block">
                    <InputLabel id="mail" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.mail.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="mail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.mail}
                          helperText={errors?.mail?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel
                      htmlFor="quantity"
                      className="text-16 font-medium mb-4 text-grey-900"
                    >
                      {t('qrcodeOrder.form.quantity.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Select
                      id="quantity"
                      name="quantity"
                      className="w-full"
                      size="small"
                      value={qty}
                      onChange={(e) => {
                        setQty(e.target.value);
                      }}
                    >
                      {Array.from({ length: 10 }, (__, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="block">
                    <InputLabel id="address" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.address.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.address}
                          helperText={errors?.address?.message}
                          variant="outlined"
                          multiline
                          rows={4}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel id="landmark" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.landmark.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="landmark"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.landmark}
                          helperText={errors?.landmark?.message}
                          variant="outlined"
                          multiline
                          rows={4}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel id="country" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.country.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="w-full"
                          id="country"
                          value={countryId}
                          size="small"
                          displayEmpty
                          error={!!errors.country}
                          onChange={(e) => {
                            handleSelectCountry(e);
                          }}
                        >
                          <MenuItem value={0} disabled>
                            {t('qrcodeOrder.form.country.placeHolder')}
                            <span className="text-red-500">*</span>
                          </MenuItem>
                          {countryList?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel id="state" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.state.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="w-full"
                          id="state"
                          value={stateId}
                          size="small"
                          displayEmpty
                          error={!!errors.state}
                          onChange={(e) => {
                            handleSelectState(e);
                          }}
                        >
                          <MenuItem value={0} disabled>
                            {t('qrcodeOrder.form.state.placeHolder')}
                          </MenuItem>
                          {stateList?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {stateId === 0 && (
                      <p className="text-red ml-12 top-5 relative text-md">
                        {errors.state?.message
                          ? t('signUpPage.formField.phoneNumber.validationMessages.min')
                          : ''}
                      </p>
                    )}
                  </div>
                  <div className="block">
                    <InputLabel id="city" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.city.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={!!errors.city}
                          helperText={errors?.city?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel id="zipCode" className="text-16 font-medium mb-4 text-grey-900">
                      {t('qrcodeOrder.form.zipCode.label')}
                      <span className="text-red-500">*</span>
                    </InputLabel>
                    <Controller
                      name="zipCode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          error={!!errors.zipCode}
                          helperText={errors?.zipCode?.message}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="text-center mt-24">
                  {/* <Button
                    className="md:text-16 font-medium rounded-md md:min-w-192 mx-10"
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={handleRemove}
                  >
                    {t('business.businessAttribute.buttons.cancel')}
                  </Button> */}
                  <Button
                    className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon || stateId === 0}
                  >
                    {t('qrcodeOrder.form.button')}
                    {refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default withReducer('qrcodeOrderReducer', reducer)(QrcodeOrder);

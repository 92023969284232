import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { redirect, Link, useParams, useHistory } from 'react-router-dom';
import history from '@history';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import paymentData from '../query/payment';
import { getEncryptedData, getDecryptData } from '../common/common';
import {
  upGradeAndDowngradeSubscriptionAddOn,
  getPackageDataOnPackageIdAndCountryId,
  getPackageAddOn,
  getSubcriptionAndAddOnUsingUserId,
  addSubscriptionAddOn,
  resetPayment,
} from './store/paymentSlice';
import reducer from './store';

function PaymentUpdate() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  // const history = useHistory();
  const countryData = useMemo(
    () => [
      {
        id: 2,
        name: 'India',
        countryCode: 91,
        isoCode: 'IND',
        isActive: true,
        currency: 'INR',
        symbol: '₹',
        flag: 'https://flagcdn.com/w320/in.png',
      },
      {
        id: 1,
        name: 'United States',
        countryCode: 1,
        isoCode: 'USA',
        isActive: true,
        currency: 'USD',
        symbol: '$',
        flag: 'https://flagcdn.com/w320/us.png',
      },
    ],
    []
  );

  const upGradeAndDowngradeStatus = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.upGradeAndDowngradeSubscriptionAddOnData?.data
        ?.upGradeAndDowngradeSubscriptionAddOn || ''
  );

  const getPackageData = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.getPackageDataOnPackageIdAndCountryIdData?.data
        ?.getPackageDataOnPackageIdAndCountryId || ''
  );

  const getPackageAddOnData = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.getPackageAddOnData?.data?.getPackageAddOn || ''
  );

  const getSubcriptionAndAddOnData = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.getSubcriptionAndAddOnUsingUserIdData?.data
        ?.getSubcriptionAndAddOnUsingUserId || {}
  );

  const AddRemoveSubscriptionStatus = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.addSubscriptionAddOnData?.data?.addSubscriptionAddOn || ''
  );

  let packageDetails;
  let packageAddOnDetails;
  let packageDetailsByUserID;

  if (getPackageData?.data) {
    packageDetails = JSON.parse(getDecryptData(getPackageData?.data));
  }

  if (getPackageAddOnData?.data) {
    packageAddOnDetails = JSON.parse(getDecryptData(getPackageAddOnData?.data));
  }

  if (
    Object.keys(getSubcriptionAndAddOnData).length > 0 &&
    getSubcriptionAndAddOnData?.status === 200 &&
    getSubcriptionAndAddOnData?.data
  ) {
    packageDetailsByUserID = JSON.parse(getDecryptData(getSubcriptionAndAddOnData?.data));
  }

  const [loading, setLoading] = useState(true);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [totalLocation, setTotalLocation] = useState(0);
  const [subscriptionPackageStripePriceId, setSubscriptionPackageStripePriceId] = useState('');
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [curretnRecurring, setCurretnRecurring] = useState();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [billingType, setBillingType] = useState('month');
  const [paymentType, setPaymentType] = useState('');
  const [subcriptiontype, setSubcriptiontype] = useState('');
  const [ipBasedData, setIpBasedData] = useState();
  const [amountPerLocation, setAmountPerLocation] = useState();
  const [addOnPrice, setAddonPrice] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [successPayment, setSuccessPayment] = useState(false);
  const [billingDetail, setBillingDetail] = useState('');
  const [addonHeading, setAddonHeading] = useState('');
  const [calulationHeading, setCalulationHeading] = useState('');
  const [timeRemaining, setTimeRemaining] = useState();
  const [isTimeOver, setIsTimeOver] = useState(false);
  const [routeToken] = useState(jwtDecode(routeParams?.token));

  let selectedPackageDetails;
  let calculateTotalAmount;

  useEffect(() => {
    setSubscriptionData(null);
    if (!ipBasedData) {
      setIpBasedData(countryData.find((o) => o.id === routeToken?.countryId));
    }
  }, [dispatch, ipBasedData, countryData, routeToken?.countryId]);

  let currentAddonValue;

  if (getPackageData?.data) {
    selectedPackageDetails = packageDetails?.find((element) => {
      return element.stripePriceId === subscriptionPackageStripePriceId;
    });
    calculateTotalAmount = parseInt(packageDetailsByUserID?.subcription?.amount, 10);

    if (totalAddonAmount > 0 && paymentType !== 'downgradeAddon') {
      calculateTotalAmount += parseInt(totalAddonAmount, 10);
    } else if (totalAddonAmount > 0 && paymentType === 'downgradeAddon') {
      calculateTotalAmount -= parseInt(totalAddonAmount, 10);
    }

    let addonValue;

    if (packageDetailsByUserID?.addOn?.length > 0) {
      addonValue = packageDetailsByUserID?.addOn?.find((addonDetail) => {
        return addonDetail;
      });
      currentAddonValue = parseInt(addonValue?.amount, 10) * parseInt(addonValue?.quantity, 10);
      calculateTotalAmount += parseInt(currentAddonValue, 10);
    }

    // if (totalAddonAmount > 0) {
    //   calculateTotalAmount += parseInt(totalAddonAmount, 10);
    // }
  }

  let selectedAddOn;

  if (getPackageAddOnData?.data) {
    selectedAddOn = packageAddOnDetails?.find((element) => {
      return billingType === element.packageInterval;
    });
  }

  useEffect(() => {
    const tokenExpiryTimestamp = routeToken?.exp;
    const currentDate = new Date();
    if (tokenExpiryTimestamp * 1000 < currentDate.getTime()) {
      setIsTimeOver(true);
    } else {
      const tokenDate = new Date(tokenExpiryTimestamp * 1000);
      const timeDiff = Math.abs(tokenDate.getTime() - currentDate.getTime());
      const timeDiffInSecond = Math.ceil(timeDiff / 1000);
      setTimeRemaining(timeDiffInSecond);
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
  }, [routeToken.exp]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      setIsTimeOver(true);
    }
  }, [timeRemaining]);

  useEffect(() => {
    let signalAddOnPrice = '';
    signalAddOnPrice = packageAddOnDetails?.find((element) => {
      return billingType === element.packageInterval;
    });
    setAddonPrice(signalAddOnPrice?.stripePriceId);
  }, [billingType, packageAddOnDetails]);

  useEffect(() => {
    setCurretnRecurring(
      currentAddonValue
        ? parseInt(packageDetailsByUserID?.subcription?.amount, 10) + currentAddonValue
        : parseInt(packageDetailsByUserID?.subcription?.amount, 10)
    );
  }, [currentAddonValue, packageDetailsByUserID?.subcription?.amount]);

  useEffect(() => {
    // if (packageDetailsByUserID?.addOn?.length > 0) {
    //   if (totalLocation === 0) {
    //     setTotalLocation(packageDetailsByUserID?.addOn[0]?.quantity);
    //   }
    // }
    if (packageDetailsByUserID) {
      setBillingType(packageDetailsByUserID?.subcription.packageInterval);
    }
  }, [packageDetailsByUserID, totalLocation]);

  useEffect(() => {
    let decoded;
    if (ipBasedData) {
      const fetchData = async () => {
        try {
          if (routeParams && routeParams.token) {
            decoded = jwtDecode(routeParams?.token);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
        }

        setPaymentType(decoded?.type);
        setSubcriptiontype(decoded?.type);
        switch (decoded?.type) {
          case 'addon':
            setBillingDetail('Add Add-Ons');
            setAddonHeading('Add Add-ons');
            break;
          case 'cancelAddon':
            setBillingDetail('Cancel Add-Ons');
            break;
          case 'upgradeAddon':
            setBillingDetail('Upgrade Add-Ons');
            setAddonHeading('Add More Add-ons');
            setCalulationHeading('Added Add-Ons');
            break;
          case 'downgradeAddon':
            setBillingDetail('Downgrade Add-Ons');
            setAddonHeading('Remove Add-ons');
            setCalulationHeading('Removed Add-Ons');
            break;
          case 'upgradeDowngradeSubscription':
            setBillingDetail('Upgrade Downgrade Subscription');
            break;
          default:
            break;
        }
        const data = JSON.stringify({
          countryId: routeToken?.countryId,
          // packageId: parseInt(decoded?.packageId, 10),
          packageId: 5,
        });

        // const data = JSON.stringify({
        //   countryId: 2,
        //   packageId: 16,
        // });

        const UserIdData = JSON.stringify({
          userId: parseInt(decoded?.id, 10),
        });

        const payloadSubscriptionDataUserId = {
          query: paymentData.getSubcriptionAndAddOnUsingUserId,
          variables: { data: getEncryptedData(UserIdData) },
          url: routeParams.token,
        };
        await dispatch(getSubcriptionAndAddOnUsingUserId(payloadSubscriptionDataUserId));

        const payload = {
          query: paymentData.getPackageDataOnPackageIdAndCountryId,
          variables: { data: getEncryptedData(data) },
        };
        await dispatch(getPackageDataOnPackageIdAndCountryId(payload));
        const PayloadAddOn = {
          query: paymentData.getPackageAddOn,
          variables: { data: getEncryptedData(data) },
        };
        dispatch(getPackageAddOn(PayloadAddOn));
        setLoading(false);
        return () => {
          dispatch(resetPayment());
        };
      };
      fetchData();
    }
  }, [dispatch, routeParams, ipBasedData, countryData, routeToken?.countryId]);

  useEffect(() => {
    if (
      packageDetails?.length > 0 &&
      packageDetails[0]?.stripePriceId &&
      !subscriptionPackageStripePriceId
    ) {
      setSubscriptionPackageStripePriceId(packageDetails[0]?.stripePriceId);
    }
  }, [packageDetails, subscriptionPackageStripePriceId]);

  useEffect(() => {
    if (upGradeAndDowngradeStatus?.status) {
      if (upGradeAndDowngradeStatus?.status !== 200) {
        enqueueSnackbar(upGradeAndDowngradeStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      } else {
        setSuccessPayment(true);
      }
    }
  }, [upGradeAndDowngradeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (AddRemoveSubscriptionStatus?.status) {
      if (AddRemoveSubscriptionStatus?.status !== 200) {
        enqueueSnackbar(AddRemoveSubscriptionStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      } else {
        setSuccessPayment(true);
      }
    }
  }, [AddRemoveSubscriptionStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (selectedAddOn) {
      setTotalAddonAmount(parseInt(totalLocation, 10) * parseInt(selectedAddOn?.amount, 10));
    } else {
      setTotalAddonAmount(parseInt(totalLocation, 10) * parseInt(amountPerLocation, 10));
    }
  }, [totalLocation, amountPerLocation, selectedAddOn]);

  if (loading) {
    return <FuseLoading />;
  }

  if (successPayment) {
    return <Success type={paymentType} addonType={billingDetail} />;
  }

  const handleChangeLocation = (event, amountSingleLocation) => {
    setTotalLocation(event.target.value);
    setAmountPerLocation(amountSingleLocation);
  };

  const handleChangePackage = (event) => {
    let packageInterval = [];
    setSubscriptionPackageStripePriceId(event.target.value);

    packageInterval = packageDetails?.find((element) => {
      return event.target.value === element.stripePriceId;
    });

    setBillingType(packageInterval?.packageInterval);
  };

  const handleSubscription = async () => {
    setSubscriptionLoading(true);
    let packagePriceData;
    if (paymentType === 'addon') {
      packagePriceData = {
        stripeSubscriptionId: packageDetailsByUserID?.subcription?.stripeSubscriptionId,
        countryId: routeToken?.countryId,
        packageInterval: packageDetailsByUserID?.subcription?.packageInterval,
      };
      if (totalLocation > 0) {
        packagePriceData = {
          ...packagePriceData,
          isUpgrade: paymentType === 'upgradeAddon',
          isRemove:
            paymentType === 'downgradeAddon' &&
            packageDetailsByUserID?.addOn[0]?.quantity === totalLocation,
          addOn: [
            {
              priceId: addOnPrice,
              quantity: totalLocation,
            },
          ],
        };
      }
    } else {
      packagePriceData = {
        priceId: packageDetailsByUserID?.addOn[0]?.stripePriceId,
        quantity: totalLocation,
        stripeSubscriptionId: packageDetailsByUserID?.subcription?.stripeSubscriptionId,
        isUpgrade: paymentType !== 'downgradeAddon',
      };
    }
    // return;
    const data = JSON.stringify(packagePriceData);

    try {
      if (paymentType === 'addon') {
        const registrationPayload = {
          query: paymentData.addSubscriptionAddOn,
          variables: { data: getEncryptedData(data) },
          url: routeParams.token,
        };
        await dispatch(addSubscriptionAddOn(registrationPayload));
      } else {
        const registrationPayload = {
          query: paymentData.upGradeAndDowngradeSubscriptionAddOn,
          variables: { data: getEncryptedData(data) },
          url: routeParams.token,
        };
        await dispatch(upGradeAndDowngradeSubscriptionAddOn(registrationPayload));
      }
      setSubscriptionLoading(false);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <>
      <div className="text-center items-center flex mx-auto rounded-md w-full md:w-auto md:min-w-586">
        <div className="w-full rounded-md m-16" style={{ backgroundColor: '#FAFBFC' }}>
          <div className="bg-white flex items-center justify-between px-16 sm:px-32 py-16  rounded-t-md">
            <img src="assets/images/logo/logo_green.png" className="w-[170px]" alt="logo" />
          </div>
          {isTimeOver ? (
            <h2 className="font-semibold text-24 py-16">unauthorized</h2>
          ) : (
            <>
              <p style={{ backgroundColor: '#FFFAE5' }} className="py-10 font-medium text-16">
                Please complete the process within{' '}
                <span className="text-red">
                  {Math.floor(timeRemaining / 60)}:
                  {(timeRemaining % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}{' '}
                  seconds.
                </span>
              </p>
              {Object.keys(getSubcriptionAndAddOnData).length > 0 &&
              getSubcriptionAndAddOnData?.status === 200 &&
              getSubcriptionAndAddOnData?.message === 'Success' ? (
                <div className="text-left px-16 sm:px-32">
                  <div className="my-28">
                    <div className="flex justify-between">
                      <h1 className="font-semibold text-24 mb-5">{billingDetail}</h1>
                    </div>
                    <Typography className="mb-0 text-16 md:text-20 font-medium">
                      Subscription will renew automatically
                    </Typography>
                  </div>
                  <div className="bg-white mt-16 rounded-md leading-loose shadow-none border">
                    <h3 className="text-16 font-semibold border-b-1 border-dashed border-darkgreen py-10 px-12">
                      <img
                        src="assets/images/icon/active_subscription.png"
                        className="h-20 w-20 align-middle mr-12 inline-block"
                        alt="..."
                      />{' '}
                      Active Subscription
                    </h3>
                    <div className="py-12 px-14">
                      <h3 className="text-14 text-darkgreen font-semibold">Active Plan</h3>
                      <div className="flex justify-between items-center text-16 font-semibold">
                        {packageDetailsByUserID?.subcription?.name} Plan
                        <h4 className="text-18 font-semibold capitalize">
                          {ipBasedData?.symbol}
                          {packageDetailsByUserID?.subcription?.amount} /{' '}
                          {packageDetailsByUserID?.subcription?.packageInterval}
                        </h4>
                      </div>

                      {packageDetailsByUserID?.addOn?.length > 0 &&
                        packageDetailsByUserID?.addOn?.map((addonDetail, i) => (
                          <div key={i}>
                            <h3 className="text-14 text-darkgreen font-semibold border-t-2 border-dashed pt-12 mt-12">
                              Active Add-Ons
                            </h3>
                            <div className="flex justify-between items-center text-18 font-medium">
                              {addonDetail?.quantity} Business Location
                              <h4 className="text-18 font-semibold capitalize">
                                {ipBasedData?.symbol}
                                {currentAddonValue} / {addonDetail?.packageInterval}
                              </h4>
                            </div>
                          </div>
                        ))}
                      <div className="flex justify-between items-center text-18 font-medium border-t-2 border-dashed pt-12 mt-12">
                        <h3 className="text-16 font-semibold ">Total Current Recurring Amount</h3>
                        <h4 className="text-18 font-semibold capitalize">
                          {ipBasedData?.symbol}
                          {curretnRecurring} /{' '}
                          {packageDetailsByUserID?.subcription?.packageInterval}
                        </h4>
                      </div>
                    </div>
                  </div>

                  {subcriptiontype === 'upgradeDowngradeSubscription' && (
                    <div className="mt-28">
                      <FormControl className="block">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-28"
                          value={subscriptionPackageStripePriceId}
                          onChange={handleChangePackage}
                        >
                          {packageDetails &&
                            packageDetails?.length > 0 &&
                            packageDetails?.map((packageValue, index) => (
                              <FormControlLabel
                                key={index}
                                value={packageValue?.stripePriceId}
                                sx={{
                                  '& .Mui-checked ~ .MuiFormControlLabel-root': {
                                    backgroundColor: 'red',
                                  },
                                }}
                                control={
                                  <Radio className="p-0 absolute right-12 top-12 w-24 h-24" />
                                }
                                label={
                                  <code>
                                    <span className="block text-16 font-medium mb-5 capitalize">
                                      {packageValue?.packagePeriod} {packageValue?.packageInterval}
                                    </span>
                                    <span className="block text-18 font-bold mb-5 capitalize">
                                      {ipBasedData?.symbol}
                                      {packageValue?.amount}/{packageValue?.packageInterval}
                                    </span>
                                    <span className="block text-12 sm:text-14 font-medium">
                                      {packageValue?.packageInterval === 'month'
                                        ? 'Billed monthly'
                                        : 'Billed annually'}
                                    </span>
                                  </code>
                                }
                                className="bg-white rounded-md p-16 border m-0 relative"
                              />
                            ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  <div className="mt-28">
                    <Accordion className="shadow-none border">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="text-darkgreen" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="py-12 px-14"
                        sx={{
                          '& .MuiAccordionSummary-content, &.Mui-expanded': { m: '0 !important' },
                          '&.Mui-expanded': {
                            minHeight: '48px',
                            borderBottom: '1px dashed #A5D6A7',
                          },
                        }}
                      >
                        <Typography className="text-16 font-semibold">
                          {' '}
                          <Icon className="text-20 align-middle mr-12 text-darkgreen">
                            library_add
                          </Icon>
                          {addonHeading}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {packageAddOnDetails &&
                          packageAddOnDetails?.length > 0 &&
                          packageAddOnDetails?.map((packageAddOnValue, index) => (
                            <span key={index}>
                              {billingType === packageAddOnValue.packageInterval && (
                                <div className="flex items-center justify-between">
                                  <span>
                                    <h4 className="text-darkgreen font-medium text-16">Location</h4>
                                    <span className="font-medium text-12 sm:text-14 mt-6 block capitalize">
                                      {ipBasedData?.symbol}
                                      {packageAddOnValue?.amount}
                                      /Location/
                                      {packageAddOnValue?.packageInterval}
                                    </span>
                                  </span>
                                  <FormControl
                                    className="sm:min-w-120 sm:w-auto w-96 mx-0 extra-small-select"
                                    sx={{ m: 1 }}
                                  >
                                    {billingDetail === 'Downgrade Add-Ons' ? (
                                      <>
                                        <Select
                                          value={totalLocation}
                                          onChange={(e) =>
                                            handleChangeLocation(e, packageAddOnValue?.amount)
                                          }
                                          size="small"
                                          className="font-semibold text-12 sm:text-14"
                                          sx={{ '& .MuiSelect-select': { lineHeight: '20px' } }}
                                          displayEmpty
                                          inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                          <MenuItem value={0}>Select Location</MenuItem>
                                          {packageDetailsByUserID?.addOn?.length > 0 &&
                                            packageDetailsByUserID?.addOn?.map((addonDetail, i) =>
                                              // Assuming addonDetail?.quantity is the number of items you want
                                              // to create for each addonDetail
                                              Array.from({ length: addonDetail?.quantity }).map(
                                                (_, addonIndex) => (
                                                  <MenuItem value={addonIndex + 1}>
                                                    {addonIndex + 1} Location
                                                  </MenuItem>
                                                )
                                              )
                                            )}
                                        </Select>
                                      </>
                                    ) : (
                                      <>
                                        <Select
                                          value={totalLocation}
                                          onChange={(e) =>
                                            handleChangeLocation(e, packageAddOnValue?.amount)
                                          }
                                          size="small"
                                          className="font-semibold text-12 sm:text-14"
                                          sx={{ '& .MuiSelect-select': { lineHeight: '20px' } }}
                                          displayEmpty
                                          inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                          <MenuItem value={0}>Select Location</MenuItem>
                                          <MenuItem value={1}>1 Location</MenuItem>
                                          <MenuItem value={2}>2 Location</MenuItem>
                                          <MenuItem value={3}>3 Location</MenuItem>
                                          <MenuItem value={4}>4 Location</MenuItem>
                                          <MenuItem value={5}>5 Location</MenuItem>
                                        </Select>
                                      </>
                                    )}
                                  </FormControl>

                                  {totalLocation !== 0 && (
                                    <h5 className="text-16 md:text-20 font-semibold">
                                      {paymentType === 'downgradeAddon' ? '-' : ''}{' '}
                                      {ipBasedData?.symbol}
                                      {totalAddonAmount}
                                    </h5>
                                  )}
                                </div>
                              )}
                            </span>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="bg-white border mt-16 py-12 px-14 rounded-md leading-loose">
                    {calulationHeading !== '' && (
                      <h5 className="text-20 font-semibold">{calulationHeading}</h5>
                    )}
                    {/* <div className="flex items-center justify-between">
                  <span className="text-14 sm:text-16 text-grey-600 font-medium">Base price</span>
                  <span className="text-14 sm:text-16 font-semibold">
                    {ipBasedData?.symbol}{packageDetailsByUserID?.subcription?.amount}
                  </span>
                </div> */}
                    {totalLocation !== 0 && (
                      <>
                        <div className="flex items-center justify-between">
                          <span className="text-14 sm:text-16 text-grey-600 font-medium">
                            {totalLocation} Business Location
                          </span>
                          <span className="text-14 sm:text-16 font-semibold">
                            {paymentType === 'downgradeAddon' ? '-' : ''} {ipBasedData?.symbol}
                            {parseInt(totalAddonAmount, 10)}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}
                    {/* <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 font-medium">
                      Amount To Pay Now
                    </span>
                    <span className="text-14 sm:text-16 font-semibold">
                      {' '}
                      {ipBasedData?.symbol}
                      {parseInt(totalAddonAmount, 10)}
                    </span>
                  </div> */}
                    {/* <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      Total amount
                    </span>
                    <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                      {ipBasedData?.symbol}{parseInt(totalAddonAmount, 10)}/
                      {packageDetailsByUserID?.subcription?.packageInterval}
                    </span>
                  </div> */}
                    {calulationHeading !== '' && <h5 className="text-20 font-semibold">Invoice</h5>}
                    <div className="flex items-center justify-between">
                      <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                        Next recurring amount
                      </span>
                      <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                        {ipBasedData?.symbol}
                        {calculateTotalAmount}/
                        {packageDetailsByUserID?.subcription?.packageInterval}
                      </span>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="rounded-md w-full text-18 sm:text-20 font-semibold mt-28"
                    onClick={() => handleSubscription()}
                    disabled={subscriptionLoading || parseInt(totalAddonAmount, 10) === 0}
                    color="secondary"
                  >
                    {billingDetail}
                    {subscriptionLoading && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                  <div className="text-16 text-medium text-center my-20">
                    You can cancel the subscription anytime
                  </div>
                  {billingDetail === 'Add Add-Ons' && (
                    <div className="rounded-md border-1 border-dashed text-14 bg-white p-12 text-grey text-left mb-20">
                      <span className=" max-w-512 block">
                        <b className="text-black">Note:</b> The payment will be automatically
                        charged from your account. Thank you for your continued trust in our
                        services.
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <h2 className="font-semibold text-24 py-16">
                  {getSubcriptionAndAddOnData?.message}
                </h2>
              )}

              <div className="bg-white py-10">
                By continuing you agree to our <br />
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.privacyPolicy')}
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  Subscriber Agreement.
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_EULA_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.eula')}
                </a>{' '}
                and{' '}
                <a
                  href={process.env.REACT_APP_DISCLAIMER_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.disclaimers')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function Success(data) {
  const { addonType, type } = data;
  useEffect(() => {
    setTimeout(() => {
      history.push('subscription://quickreviewsSubscriptionSuccess');
    }, 2000);
  }, []);

  const handleBackToApp = () => {
    // const deepLink = 'quickreviews.click://PlanDetailsScreen';
    if (type === 'upgradeAddon' || type === 'downgradeAddon') {
      history.push('addon://quickreviewsUpgradeDowngradeAddonSuccess');
    } else if (type === 'addon') history.push('addAddon://quickreviewsAddAddonSuccess');

    // const newWindow = window.open(deepLink, '_blank');
    // if (newWindow) {
    //   newWindow.focus();
    // } else {
    //   // Handle cases where the deep link couldn't be opened (e.g., app not installed)
    //   console.log('Failed to open the app. Please make sure the app is installed.');
    // }
    // setTimeout(() => {
    //   history.push('subscription://quickreviewsSubscriptionSuccess');
    // }, 2000);
    // window.close();
  };

  return (
    <div
      className="text-center md:h-screen items-center text-center md:flex mx-auto rounded-md"
      style={{ maxWidth: '586px' }}
    >
      <div className="rounded-md m-16 sm:m-0" style={{ backgroundColor: '#FAFBFC' }}>
        <div className="bg-white px-16 sm:px-32 py-16  rounded-t-md">
          <img src="assets/images/logo/logo_green.png" className="w-[170px]" alt="logo" />
        </div>
        <div className="px-16 sm:px-32 mt-68 pb-32">
          <img src="assets/images/icon/done.png" className="h-144 w-144 mx-auto mb-28" alt="..." />
          <h2 className="text-24 font-semibold">{addonType} Successful!</h2>
          <Typography className="md:text-18 text-16 mt-12 text-medium mb-40 max-w-400 mx-auto">
            Congratulations on your successful purchase of the {addonType}. Thank you for choosing
            our services.
          </Typography>
          <div className="rounded-md border-1 border-dashed text-14 bg-white p-12 text-grey-600 text-left mb-20">
            <Typography className="md:text-18 max-w-512 text-16 mt-12 text-medium max-w-400 mb-0">
              <b className="text-black">Note:</b> "Thank you for upgrading your plan! Your changes
              are being processed and will be reflected in the next one hour. If you any have issues
              or have questions, feel free to reach out to our support team. We appreciate your
              patience!"
            </Typography>
          </div>
          <Button
            variant="contained"
            color="secondary"
            className="w-full rounded-md text-18 sm:text-20 font-semibold"
            type="button"
            onClick={handleBackToApp}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withReducer('paymentReducer', reducer)(PaymentUpdate);

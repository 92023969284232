import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`qrcodeOrder/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getCountries = createAsyncThunkWithAuthorization(
  'getCountries',
  'qrcodeOrder/getCountries'
);
export const getStates = createAsyncThunkWithAuthorization('getStates', 'qrcodeOrder/getStates');
export const createBusinessQrCodeOrder = createAsyncThunkWithAuthorization(
  'createBusinessQrCodeOrder',
  'qrcodeOrder/createBusinessQrCodeOrder'
);

const qrcodeOrderSlice = createSlice({
  name: 'qrcodeOrder',
  initialState: null,
  reducers: {
    resetQrcodeOrder: (state, action) => {},
  },
  extraReducers: {},
});

export const { resetQrcodeOrder } = qrcodeOrderSlice.actions;

export default qrcodeOrderSlice.reducer;

import BusinessInformation from './businessInformation';
import About from './about';
import Home from './home';
import Blog from './blog';
import Service from './service';
import SocialLinks from './socialLinks';
import Features from './features';
import Achievements from './achievements';
import Feedback from './feedback';
import Product from './product';

const cmsWebsiteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'business-information',
      element: <BusinessInformation />,
    },
    {
      path: 'about',
      element: <About />,
    },
    {
      path: 'home',
      element: <Home />,
    },
    {
      path: 'blog',
      element: <Blog />,
    },
    {
      path: 'service',
      element: <Service />,
    },
    {
      path: 'social-links',
      element: <SocialLinks />,
    },
    {
      path: 'features',
      element: <Features />,
    },
    {
      path: 'achievements',
      element: <Achievements />,
    },
    {
      path: 'feedback',
      element: <Feedback />,
    },
    {
      path: 'products',
      element: <Product />,
    },
  ],
};

export default cmsWebsiteConfig;

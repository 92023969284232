import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import cmsData from '../../query/cms';
import {
  resetCmsApp,
  updatePopulerAndRecommendedAsIsMenuData,
  openAddPopularItemDialog,
  closeAddPopularItemDialog,
  openAddRecommendedItemDialog,
  closeAddRecommendedItemDialog,
  getBusinessMenuAndServicesData,
  isPopularDatagetBusinessMenuAndServicesData,
  isRecommendedDatagetBusinessMenuAndServicesData,
} from './store/cmsAppSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function PopularAndRecommended() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appDialog = useSelector(({ cmsAppReducer }) => cmsAppReducer.cmsApp.AddItemDialog);

  const isPopularDataGetBusinessMenuAndServicesData = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.isPopularDatagetBusinessMenuAndServicesDataValue?.data
        ?.getBusinessMenuAndServices?.data || ''
  );

  const getBusinessMenuAndServices = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getBusinessMenuAndServicesDataValue?.data?.getBusinessMenuAndServices
        ?.data || ''
  );

  const isRecommendedGetBusinessMenuAndServicesData = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.isRecommendedDatagetBusinessMenuAndServicesDataValue?.data
        ?.getBusinessMenuAndServices?.data || ''
  );

  const [loading, setLoading] = useState(false);
  const loginUserData = UserService.getUserData();
  const [checkedItems, setCheckedItems] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [checkboxData, setCheckboxData] = useState(isPopularDataGetBusinessMenuAndServicesData);
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const updatePopulerAndRecommendedAsIsMenu = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.updatePopulerAndRecommendedAsIsMenuDataValue?.data
        ?.updatePopulerAndRecommendedAsIsMenuData || ''
  );

  useEffect(() => {
    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatePopulerAndRecommendedAsIsMenu?.status) {
      enqueueSnackbar(updatePopulerAndRecommendedAsIsMenu?.message, {
        variant: updatePopulerAndRecommendedAsIsMenu?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updatePopulerAndRecommendedAsIsMenu, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
          isActive: true,
        },
      };
      dispatch(isPopularDatagetBusinessMenuAndServicesData(payload));
    }
    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (getBusinessMenuAndServices || getBusinessMenuAndServices === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [getBusinessMenuAndServices, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
          isPopuler: true,
          isActive: true,
        },
      };
      dispatch(getBusinessMenuAndServicesData(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
          isRecommended: true,
          isActive: true,
        },
      };
      dispatch(isRecommendedDatagetBusinessMenuAndServicesData(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isPopularDataGetBusinessMenuAndServicesData) {
      setCheckboxData(isPopularDataGetBusinessMenuAndServicesData);
    }
  }, [isPopularDataGetBusinessMenuAndServicesData]);

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    if (appDialog?.type === 'popular') {
      dispatch(closeAddPopularItemDialog());
    } else {
      dispatch(closeAddRecommendedItemDialog());
    }
  }

  function handleRemove() {
    closeComposeDialog();
  }

  const handleCheckboxChange = (item, index) => {
    if (appDialog?.type === 'popular') {
      const updatedData = [...checkboxData];
      updatedData[index] = { ...updatedData[index], isPopuler: !updatedData[index].isPopuler };
      setCheckboxData(updatedData);
      const existingItemIndex = checkedItems.findIndex(
        (existingItem) => existingItem.id === updatedData[index].id
      );
      if (existingItemIndex !== -1) {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[existingItemIndex] = updatedData[index];
        setCheckedItems(updatedCheckedItems);
      } else {
        setCheckedItems((prevChangedRecords) => [...prevChangedRecords, updatedData[index]]);
      }
    } else {
      const updatedData = [...checkboxData];
      updatedData[index] = {
        ...updatedData[index],
        isRecommended: !updatedData[index].isRecommended,
      };
      setCheckboxData(updatedData);
      const existingItemIndex = checkedItems.findIndex(
        (existingItem) => existingItem.id === updatedData[index].id
      );
      if (existingItemIndex !== -1) {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[existingItemIndex] = updatedData[index];
        setCheckedItems(updatedCheckedItems);
      } else {
        setCheckedItems((prevChangedRecords) => [...prevChangedRecords, updatedData[index]]);
      }
    }
  };

  const handleRemoveData = async (item) => {
    const payloadValue = {
      query: cmsData.updatePopulerAndRecommendedAsIsMenuData,
      variables: {
        domainRegisterId: userDomainRegisterId,
        input: [
          {
            menuAndServiceId: item.id,
            isActive: false,
          },
        ],
      },
    };
    await dispatch(updatePopulerAndRecommendedAsIsMenuData(payloadValue));
    if (item.isPopuler === true) {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
          isPopuler: true,
          isActive: true,
        },
      };
      await dispatch(getBusinessMenuAndServicesData(payload));
    } else {
      const payload = {
        query: cmsData.getBusinessMenuAndServices,
        variables: {
          domainRegisterId: userDomainRegisterId,
          isRecommended: true,
          isActive: true,
        },
      };
      await dispatch(isRecommendedDatagetBusinessMenuAndServicesData(payload));
    }
  };
  const addItems = async () => {
    setRefreshIcon(true);
    let inputData = [];

    if (appDialog?.type === 'recommended') {
      inputData = checkedItems.map((checkedItem) => ({
        menuAndServiceId: checkedItem.id,
        isRecommended: checkedItem.isRecommended,
      }));
    } else {
      inputData = checkedItems.map((checkedItem) => ({
        menuAndServiceId: checkedItem.id,
        isPopuler: checkedItem.isPopuler,
      }));
    }
    closeComposeDialog();
    const payloadValue = {
      query: cmsData.updatePopulerAndRecommendedAsIsMenuData,
      variables: {
        domainRegisterId: userDomainRegisterId,
        input: inputData,
      },
    };
    await dispatch(updatePopulerAndRecommendedAsIsMenuData(payloadValue));
    setCheckedItems([]);
    setRefreshIcon(false);
    const payloadObj = {
      query: cmsData.getBusinessMenuAndServices,
      variables: {
        domainRegisterId: userDomainRegisterId,
        isRecommended: true,
        isActive: true,
      },
    };
    const payloadData = {
      query: cmsData.getBusinessMenuAndServices,
      variables: {
        domainRegisterId: userDomainRegisterId,
        isPopuler: true,
        isActive: true,
      },
    };
    appDialog?.type === 'recommended'
      ? await dispatch(isRecommendedDatagetBusinessMenuAndServicesData(payloadObj))
      : await dispatch(getBusinessMenuAndServicesData(payloadData));
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <div>
          <>
            <div className="items-center mb-36">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-16 md:text-24 font-bold mb-20"
              >
                {t('appWebsite.PopularAndRecommended.title')}
              </Typography>
            </div>
            <div>
              {!getBusinessMenuAndServices?.length > 0 ? (
                <div className="bg-white rounded-md relative shadow block p-24">
                  <Typography className="sm:flex text-18 md:text-24 font-semibold rounded-tl-md rounded-tr-md border-b-2 border-dashed border-grey-300 pb-24">
                    {t('appWebsite.PopularAndRecommended.popularItem.title')}
                  </Typography>
                  <div className="text-center my-40 mb-20">
                    <img
                      src="/assets/images/business/empty_basket.png"
                      className="img-fluid mx-auto max-w-96 mb-28"
                      alt="..."
                    />
                    {checkboxData.length === 0 ? (
                      <div>
                        <Typography className="text-16 md:text-16 font-medium text-center mx-auto">
                          {t('appWebsite.PopularAndRecommended.addmenu')}
                        </Typography>
                        <a href="cms-app/menu">
                          {t('appWebsite.PopularAndRecommended.foraddmenu')}
                        </a>
                      </div>
                    ) : (
                      <div>
                        <Typography className="sm:flex text-16 md:text-16 font-medium max-w-640 mx-auto">
                          {t('appWebsite.PopularAndRecommended.popularItem.notItemDesc')}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="w-full md:text-18 font-medium disabled:text-black rounded-md max-w-256 mt-40"
                          aria-label="Add New Item"
                          onClick={() => {
                            dispatch(openAddPopularItemDialog());
                          }}
                        >
                          {t('appWebsite.PopularAndRecommended.addItems')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-md relative shadow block p-24">
                  <div className="flex justify-between">
                    <Typography className="sm:flex text-18 md:text-24 font-semibold pb-24">
                      {t('appWebsite.PopularAndRecommended.popularItem.title')}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full md:text-18 font-medium disabled:text-black rounded-md max-w-160"
                      aria-label="Add New Item"
                      onClick={() => {
                        dispatch(openAddPopularItemDialog());
                      }}
                    >
                      {t('appWebsite.PopularAndRecommended.editItems')}
                    </Button>
                  </div>
                  <hr className="border-b-2 border-dashed border-grey-300 border-t-0" />
                  <TableContainer className="border-b-1 border-solid border-grey-200 mt-16">
                    <Table aria-labelledby="tableTitle" size="medium">
                      <TableHead>
                        <TableRow className="bg-grey-200 rounded-tr-md">
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.image')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.name')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.category')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                            {t('appWebsite.dishmenu.column.action')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getBusinessMenuAndServices?.length > 0 &&
                          getBusinessMenuAndServices?.map((menuData, i) => (
                            <TableRow hover className="border border-t-2 border-t-grey-100" key={i}>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <img
                                  src={menuData?.image}
                                  className="img-fluid max-w-80 rounded-md"
                                  alt="trial_accounts"
                                />
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {menuData?.name}
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {menuData.user_business_categories?.name}
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="w-full md:text-18 font-medium disabled:text-black bg-darkgreen-100 text-[#006B5A] rounded-md max-w-88"
                                  aria-label="Add New Item"
                                  onClick={() => handleRemoveData(menuData)}
                                >
                                  {t('appWebsite.PopularAndRecommended.remove')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {!isRecommendedGetBusinessMenuAndServicesData?.length > 0 ? (
                <div className="bg-white rounded-md relative shadow block p-24 mt-40">
                  <Typography className="sm:flex text-18 md:text-24 font-semibold rounded-tl-md rounded-tr-md border-b-2 border-dashed border-grey-300 pb-24">
                    {t('appWebsite.PopularAndRecommended.recommendedItem.title')}
                  </Typography>
                  <div className="text-center my-40 mb-20">
                    <img
                      src="/assets/images/business/empty_basket.png"
                      className="img-fluid mx-auto max-w-96 mb-28"
                      alt="..."
                    />
                    {checkboxData.length === 0 ? (
                      <div>
                        <Typography className="text-16 md:text-16 font-medium text-center mx-auto">
                          {t('appWebsite.PopularAndRecommended.addmenu')}
                        </Typography>
                        <a href="cms-app/menu">
                          {t('appWebsite.PopularAndRecommended.foraddmenu')}
                        </a>
                      </div>
                    ) : (
                      <div>
                        <Typography className="sm:flex text-16 md:text-16 font-medium max-w-640 mx-auto">
                          {t('appWebsite.PopularAndRecommended.recommendedItem.notItemDesc')}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="w-full md:text-18 font-medium disabled:text-black rounded-md max-w-256 mt-40"
                          aria-label="Add New Item"
                          onClick={() => {
                            dispatch(openAddRecommendedItemDialog());
                          }}
                        >
                          {t('appWebsite.PopularAndRecommended.addItems')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-md relative shadow block p-24 mt-40">
                  <div className="flex justify-between">
                    <Typography className="sm:flex text-18 md:text-24 font-semibold pb-24">
                      {t('appWebsite.PopularAndRecommended.recommendedItem.title')}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full md:text-18 font-medium disabled:text-black rounded-md max-w-160"
                      aria-label="Add New Item"
                      onClick={() => {
                        dispatch(openAddRecommendedItemDialog());
                      }}
                    >
                      {t('appWebsite.PopularAndRecommended.editItems')}
                    </Button>
                  </div>
                  <hr className="border-b-2 border-dashed border-grey-300 border-t-0" />
                  <TableContainer className="border-b-1 border-solid border-grey-200 mt-16">
                    <Table aria-labelledby="tableTitle" size="medium">
                      <TableHead>
                        <TableRow className="bg-grey-200 rounded-tr-md">
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.image')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.name')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.dishmenu.column.category')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                            {t('appWebsite.dishmenu.column.action')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isRecommendedGetBusinessMenuAndServicesData?.length > 0 &&
                          isRecommendedGetBusinessMenuAndServicesData?.map((menuData, i) => (
                            <TableRow hover className="border border-t-2 border-t-grey-100" key={i}>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <img
                                  src={menuData?.image}
                                  className="img-fluid max-w-80 rounded-md"
                                  alt="trial_accounts"
                                />
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {menuData?.name}
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {menuData.user_business_categories?.name}
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="w-full md:text-18 font-medium disabled:text-black bg-darkgreen-100 text-darkgreen rounded-md max-w-88"
                                  aria-label="Add New Item"
                                  onClick={() => handleRemoveData(menuData)}
                                >
                                  {t('appWebsite.PopularAndRecommended.remove')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </>
          <Dialog
            classes={{
              paper: 'm-24 p-28',
            }}
            {...appDialog.props}
            onClose={closeComposeDialog}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle className="p-0 mb-24">
              <div className="flex justify-between items-center border-b-2 border-solid border-darkgreen-100">
                <div>
                  <Typography className="text-20 font-semibold text-black  w-full pb-10">
                    {appDialog?.type === 'popular'
                      ? `${t('appWebsite.PopularAndRecommended.popularItem.dialogTitle')}`
                      : `${t('appWebsite.PopularAndRecommended.recommendedItem.dialogTitle')}`}
                  </Typography>
                </div>
                <div>
                  <Icon
                    onClick={handleRemove}
                    className="text-24 text-grey-400 font-normal cursor-pointer"
                  >
                    cancel
                  </Icon>
                </div>
              </div>
            </DialogTitle>
            <DialogContent className="p-0 mb-24 max-h-640">
              {checkboxData &&
                checkboxData.length > 0 &&
                checkboxData.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      className="block"
                      control={
                        <Checkbox
                          size="small"
                          checked={
                            appDialog?.type === 'popular' ? item.isPopuler : item.isRecommended
                          }
                          onChange={() => handleCheckboxChange(item, index)}
                          name={item.name}
                        />
                      }
                      label={item.name}
                    />
                  );
                })}
            </DialogContent>
            <DialogActions className="p-0 justify-center">
              <Button
                className="md:text-18 font-semibold disabled:text-black rounded-md"
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => addItems()}
                disabled={refreshIcon || checkedItems.length === 0}
              >
                {t('appWebsite.PopularAndRecommended.addItems')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsReducer', reducer)(PopularAndRecommended);

const getMenu = `
query{
  getUserDomainMenu{
    message
    status
    data{
      active
      domainMenuId
      domainRegisterId
      userDomainMenuId
      isDefault
      menu
      url
    }
  }
}
`;

const addUpdateDomainMenu = `
mutation ($input: [DomainDto!]!) {
  addUpdateDomainMenu(input: $input) {
    status
    message
  }
}
`;

const menuData = {
  getMenu,
  addUpdateDomainMenu,
};

export default menuData;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const setAuthorizationHeader = () => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
};

const handleApiRequest = async (endpoint, data) => {
  setAuthorizationHeader();
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`cmsWebsite/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

const handleFileData = (data, operationName) => {
  const formData = new FormData();
  formData.append('operations', JSON.stringify(data.payload));

  let variableData = `{"0":["variables.input.0.file"]`;
  if (data?.fileData?.length === 1) {
    variableData = `${variableData}}`;
  }
  data?.fileData?.forEach((image, index) => {
    if (index !== 0) variableData = `${variableData},"${index}":["variables.input.${index}.file"]}`;
  });

  formData.append('map', variableData);

  data?.fileData?.forEach((image, index) => {
    formData.append(index, image);
  });

  return formData;
};

export const createAsyncThunkWithAuthorizationAndFile = (name, endpoint, operationName) => {
  return createAsyncThunk(`cmsWebsite/${name}`, async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        handleFileData(data, operationName),
        {
          headers: {
            'apollo-require-preflight': 'true',
            'Content-Type': 'multipart/form-data',
            'x-apollo-operation-name': operationName,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  });
};

export const getConvertedAccountSalesPerson = createAsyncThunkWithAuthorization(
  'getConvertedAccountSalesPerson',
  baseURL
);

export const resendConvertedUserUsernameAndPasswordBySalesPerson =
  createAsyncThunkWithAuthorization(
    'resendConvertedUserUsernameAndPasswordBySalesPerson',
    authEndpoint
  );

export const getUserDomainMenu = createAsyncThunkWithAuthorization('getUserDomainMenu', baseURL);

export const getUserTemplateBasicDetails = createAsyncThunkWithAuthorization(
  'getUserTemplateBasicDetails',
  baseURL
);

export const updateUserTemplateBasicDetails = createAsyncThunk(
  'cmsWebsite/updateUserTemplateBasicDetails',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
        'x-apollo-operation-name': 'updateUserTemplateBasicDetails',
      },
    };
    const formT = new FormData();
    let isFile = false;
    formT.append('operations', JSON.stringify(data));

    if (data?.variables?.logo && !data?.variables?.lightLogo) {
      isFile = true;
      formT.append('map', '{"0":["variables.logo"]}');
      formT.append(0, data.variables.logo);
    } else if (data?.variables?.lightLogo && !data?.variables?.logo) {
      isFile = true;
      formT.append('map', '{"0":["variables.lightLogo"]}');
      formT.append(0, data.variables.lightLogo);
    } else if (data?.variables?.logo && data?.variables?.lightLogo) {
      isFile = true;
      formT.append('map', '{"0":["variables.logo"],"1":["variables.lightLogo"]}');
      formT.append(0, data.variables.logo);
      formT.append(1, data.variables.lightLogo);
    } else if (data?.variables?.aboutImage) {
      isFile = true;
      formT.append('map', '{"nFile":["variables.aboutImage"]}');
      formT.append('nFile', data.variables.aboutImage);
    }
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        !isFile ? { ...data } : formT,
        isFile ? headerData : {}
      );
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const getHomeOrSliderData = createAsyncThunkWithAuthorization(
  'getHomeOrSliderData',
  baseURL
);

export const addHomeOrSliderData = createAsyncThunkWithAuthorizationAndFile(
  'addHomeOrSliderData',
  process.env.REACT_APP_API_ENDPOINT,
  'addHomeOrSliderData'
);

export const updateHomeOrSliderData = createAsyncThunk(
  'cmsWebsite/updateHomeOrSliderData',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    let headerData = {};
    const formData = new FormData();
    let isFile = false;
    if (data?.fileData?.length > 0) {
      isFile = true;
      headerData = {
        headers: {
          'apollo-require-preflight': 'true',
          'Content-Type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateHomeOrSliderData',
        },
      };
      formData.append('operations', JSON.stringify(data));
      formData.append('map', `{"0":["variables.slider"]}`);
      formData.append(0, data.fileData[0]);
    }
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        !isFile ? { ...data } : formData,
        headerData
      );
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const deleteHomeOrSliderData = createAsyncThunkWithAuthorization(
  'deleteHomeOrSliderData',
  baseURL
);

export const getBusinessBlogData = createAsyncThunkWithAuthorization(
  'getBusinessBlogData',
  baseURL
);

export const addBusinessBlogData = createAsyncThunkWithAuthorizationAndFile(
  'addBusinessBlogData',
  process.env.REACT_APP_API_ENDPOINT,
  'addBusinessBlogData'
);

export const updateBusinessBlogData = createAsyncThunk(
  'cmsWebsite/updateBusinessBlogData',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data.payload));
    if (data?.fileData) {
      formT.append('map', '{"nFile":["variables.blogImage"]}');
      formT.append('nFile', data.fileData);
    } else {
      formT.append('map', '{}');
    }
    try {
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, formT, {
        headers: {
          'apollo-require-preflight': 'true',
          'x-apollo-operation-name': 'updateStoreBusinessBlog',
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const deleteBusinessBlogData = createAsyncThunkWithAuthorization(
  'deleteBusinessBlogData',
  baseURL
);

export const getUserTemplateDomainServiceDetail = createAsyncThunkWithAuthorization(
  'getUserTemplateDomainServiceDetail',
  baseURL
);

export const addUserTemplateDomainServiceDetail = createAsyncThunkWithAuthorizationAndFile(
  'addUserTemplateDomainServiceDetail',
  process.env.REACT_APP_API_ENDPOINT,
  'addUserTemplateDomainServiceDetail'
);

export const updateUserTemplateDomainServiceDetail = createAsyncThunk(
  'cmsWebsite/updateUserTemplateDomainServiceDetail',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    let headerData = {};
    const formData = new FormData();
    let isFile = false;
    if (data?.fileData?.length > 0) {
      isFile = true;
      headerData = {
        headers: {
          'apollo-require-preflight': 'true',
          'Content-Type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateUserTemplateDomainServiceDetail',
        },
      };
      formData.append('operations', JSON.stringify(data));
      formData.append('map', `{"0":["variables.icon"]}`);
      formData.append(0, data?.fileData[0]);
    }
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        !isFile ? { ...data } : formData,
        headerData
      );
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const getUserBusinessSocialMediaLink = createAsyncThunkWithAuthorization(
  'getUserBusinessSocialMediaLink',
  baseURL
);

export const addUserBusinessSocialMediaLink = createAsyncThunkWithAuthorization(
  'addUserBusinessSocialMediaLink',
  baseURL
);
export const updateUserBusinessSocialMediaLink = createAsyncThunkWithAuthorization(
  'updateUserBusinessSocialMediaLink',
  authEndpoint
);

export const getBusinesAchievementData = createAsyncThunkWithAuthorization(
  'getBusinesAchievementData',
  authEndpoint
);

export const addBusinesAchievementData = createAsyncThunk(
  'cmsWebsite/addBusinesAchievementData',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const requestData = {
      query: data.payload.query,
      variables: data.payload.variables,
    };
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, requestData);
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const updateBusinesAchievementData = createAsyncThunk(
  'cmsWebsite/updateBusinesAchievementData',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data.payload));
    if (data?.fileData) {
      formT.append('map', '{"nFile":["variables.blogImage"]}');
      formT.append('nFile', data.fileData);
    } else {
      formT.append('map', '{}');
    }
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formT, {
        headers: {
          'apollo-require-preflight': 'true',
          'x-apollo-operation-name': 'updateStoreBusinessBlog',
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const deleteBusinesAchievementData = createAsyncThunkWithAuthorization(
  'deleteBusinesAchievementData',
  authEndpoint
);

export const getDomainFeatures = createAsyncThunkWithAuthorization('getDomainFeatures', baseURL);

export const addDomainFeatures = createAsyncThunkWithAuthorizationAndFile(
  'addDomainFeatures',
  process.env.REACT_APP_API_ENDPOINT,
  'addDomainFeatures'
);
export const deleteDomainFeatures = createAsyncThunkWithAuthorization(
  'deleteDomainFeatures',
  authEndpoint
);

export const updateDomainFeatures = createAsyncThunk(
  'cmsWebsite/updateDomainFeatures',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    let headerData = {};
    const formData = new FormData();
    let isFile = false;
    if (data?.fileData?.length > 0) {
      isFile = true;
      headerData = {
        headers: {
          'apollo-require-preflight': 'true',
          'Content-Type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateDomainFeatures',
        },
      };
      formData.append('operations', JSON.stringify(data));
      formData.append('map', `{"0":["variables.icon"]}`);
      formData.append(0, data?.fileData[0]);
    }
    try {
      const response = await instance.post(
        process.env.REACT_APP_API_ENDPOINT,
        !isFile ? { ...data } : formData,
        headerData
      );
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const getWebFeedback = createAsyncThunkWithAuthorization('getWebFeedback', authEndpoint);

export const getUserBusinessProduct = createAsyncThunkWithAuthorization(
  'getUserBusinessProduct',
  authEndpoint
);

export const addUserBusinessProduct = createAsyncThunkWithAuthorizationAndFile(
  'addUserBusinessProduct',
  process.env.REACT_APP_API_ENDPOINT,
  'addUserBusinessProduct'
);
export const deleteUserBusinessProduct = createAsyncThunkWithAuthorization(
  'deleteUserBusinessProduct',
  authEndpoint
);

export const updateUserBusinessProduct = createAsyncThunk(
  'cmsWebsite/updateUserBusinessProduct',
  async (data, { getState, dispatch }) => {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data.payload));
    if (data?.fileData) {
      formT.append('map', '{"nFile":["variables.image"]}');
      formT.append('nFile', data.fileData);
    } else {
      formT.append('map', '{}');
    }
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, formT, {
        headers: {
          'apollo-require-preflight': 'true',
          'x-apollo-operation-name': 'updateUserBusinessProduct',
        },
      });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

const cmsWebsiteSlice = createSlice({
  name: 'cmsWebsite',
  initialState: null,
  reducers: {
    resetCmsWebsite: (state, action) => {
      if (state) {
        state.getUserTemplateBasicDetailsData = {};
        state.updateUserTemplateBasicDetailsData = {};
        state.getHomeOrSliderDataValue = {};
        state.addHomeOrSliderDataValue = {};
        state.updateHomeOrSliderDataValue = {};
        state.deleteHomeOrSliderDataValue = {};
        state.getBuisnessBlogDataValue = {};
        state.addBuisnessBlogDataValue = {};
        state.deleteBusinessBlogDataValue = {};
        state.updateBusinessBlogDataValue = {};
        state.getUserTemplateDomainServiceDetailData = {};
        state.addUserTemplateDomainServiceDetailData = {};
        state.updateUserTemplateDomainServiceDetailData = {};
        state.getUserBusinessSocialMediaLinkData = {};
        state.addUserBusinessSocialMediaLinkData = {};
        state.updateUserBusinessSocialMediaLinkData = {};
        state.getDomainFeaturesData = {};
        state.addDomainFeaturesData = {};
        state.updateDomainFeaturesData = {};
        state.deleteDomainFeaturesData = {};
        state.getBusinesAchievementDataValue = {};
        state.addBusinesAchievementDataValue = {};
        state.updateBusinesAchievementDataValue = {};
        state.deleteBusinesAchievementDataValue = {};
        state.getWebFeedbackData = {};
        state.getUserBusinessProductData = {};
        state.addUserBusinessProductData = {};
        state.deleteUserBusinessProductData = {};
        state.updateUserBusinessProductData = {};
      }
    },
  },
  extraReducers: {
    [getUserTemplateBasicDetails.fulfilled]: (state, action) => ({
      ...state,
      getUserTemplateBasicDetailsData: action.payload,
    }),
    [updateUserTemplateBasicDetails.fulfilled]: (state, action) => ({
      ...state,
      updateUserTemplateBasicDetailsData: action.payload,
    }),
    [getHomeOrSliderData.fulfilled]: (state, action) => ({
      ...state,
      getHomeOrSliderDataValue: action.payload,
    }),
    [addHomeOrSliderData.fulfilled]: (state, action) => ({
      ...state,
      addHomeOrSliderDataValue: action.payload,
    }),
    [updateHomeOrSliderData.fulfilled]: (state, action) => ({
      ...state,
      updateHomeOrSliderDataValue: action.payload,
    }),
    [deleteHomeOrSliderData.fulfilled]: (state, action) => ({
      ...state,
      deleteHomeOrSliderDataValue: action.payload,
    }),
    [getBusinessBlogData.fulfilled]: (state, action) => ({
      ...state,
      getBuisnessBlogDataValue: action.payload,
    }),
    [addBusinessBlogData.fulfilled]: (state, action) => ({
      ...state,
      addBuisnessBlogDataValue: action.payload,
    }),
    [updateBusinessBlogData.fulfilled]: (state, action) => ({
      ...state,
      updateBusinessBlogDataValue: action.payload,
    }),
    [deleteBusinessBlogData.fulfilled]: (state, action) => ({
      ...state,
      deleteBusinessBlogDataValue: action.payload,
    }),
    [getUserTemplateDomainServiceDetail.fulfilled]: (state, action) => ({
      ...state,
      getUserTemplateDomainServiceDetailData: action.payload,
    }),
    [addUserTemplateDomainServiceDetail.fulfilled]: (state, action) => ({
      ...state,
      addUserTemplateDomainServiceDetailData: action.payload,
    }),
    [updateUserTemplateDomainServiceDetail.fulfilled]: (state, action) => ({
      ...state,
      updateUserTemplateDomainServiceDetailData: action.payload,
    }),
    [getUserBusinessSocialMediaLink.fulfilled]: (state, action) => ({
      ...state,
      getUserBusinessSocialMediaLinkData: action.payload,
    }),
    [addUserBusinessSocialMediaLink.fulfilled]: (state, action) => ({
      ...state,
      addUserBusinessSocialMediaLinkData: action.payload,
    }),
    [updateUserBusinessSocialMediaLink.fulfilled]: (state, action) => ({
      ...state,
      updateUserBusinessSocialMediaLinkData: action.payload,
    }),
    [getDomainFeatures.fulfilled]: (state, action) => ({
      ...state,
      getDomainFeaturesData: action.payload,
    }),
    [addDomainFeatures.fulfilled]: (state, action) => ({
      ...state,
      addDomainFeaturesData: action.payload,
    }),
    [deleteDomainFeatures.fulfilled]: (state, action) => ({
      ...state,
      deleteDomainFeaturesData: action.payload,
    }),
    [updateDomainFeatures.fulfilled]: (state, action) => ({
      ...state,
      updateDomainFeaturesData: action.payload,
    }),
    [getBusinesAchievementData.fulfilled]: (state, action) => ({
      ...state,
      getBusinesAchievementDataValue: action.payload,
    }),
    [addBusinesAchievementData.fulfilled]: (state, action) => ({
      ...state,
      addBusinesAchievementDataValue: action.payload,
    }),
    [updateBusinesAchievementData.fulfilled]: (state, action) => ({
      ...state,
      updateBusinesAchievementDataValue: action.payload,
    }),
    [deleteBusinesAchievementData.fulfilled]: (state, action) => ({
      ...state,
      deleteBusinesAchievementDataValue: action.payload,
    }),
    [getWebFeedback.fulfilled]: (state, action) => ({
      ...state,
      getWebFeedbackData: action.payload,
    }),
    [getUserBusinessProduct.fulfilled]: (state, action) => ({
      ...state,
      getUserBusinessProductData: action.payload,
    }),
    [addUserBusinessProduct.fulfilled]: (state, action) => ({
      ...state,
      addUserBusinessProductData: action.payload,
    }),
    [deleteUserBusinessProduct.fulfilled]: (state, action) => ({
      ...state,
      deleteUserBusinessProductData: action.payload,
    }),
    [updateUserBusinessProduct.fulfilled]: (state, action) => ({
      ...state,
      updateUserBusinessProductData: action.payload,
    }),
  },
});

export const { resetCmsWebsite } = cmsWebsiteSlice.actions;

export default cmsWebsiteSlice.reducer;

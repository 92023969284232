import QuickChat from './QuickChat';

const QuickChatConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-chat/:id?',
      element: <QuickChat />,
    },
  ],
};

export default QuickChatConfigs;

import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const PlanUsage = () => {
  const [planUsageData, setPlanUsageData] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (planUsageData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [planUsageData]);

  useEffect(() => {
    const fetchPlanUsageData = async () => {
      const payload = {
        query: manageBusinessData.getUserBusinessesPackageUsage,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getUserBusinessesPackageUsage?.status === 200) {
        const planData = result?.getUserBusinessesPackageUsage?.data?.dataUsage;
        if (planData) {
          setPlanUsageData(JSON.parse(planData));
        }
      } else {
        enqueueSnackbar(result?.getUserBusinessesPackageUsage?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchPlanUsageData();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 h-max lg:p-40">
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
      >
        {t('planUsage.title')}
      </Typography>
      <div className="bg-white rounded-md sm:flex gap-16 mt-32 items-center p-16">
        <img
          className="rounded-md max-w-128 max-h-128"
          src={userCurrentBusinessDetails?.profileURL || '/assets/images/business/business.png'}
          alt=""
        />
        <div>
          <Typography className="font-bold text-20 break-all mb-10">
            {userCurrentBusinessDetails?.name &&
              userCurrentBusinessDetails.name.charAt(0).toUpperCase() +
                userCurrentBusinessDetails.name.slice(1)}
          </Typography>
          <Typography className="text-16 sm:max-w-640 font-semibold">
            {userCurrentBusinessDetails?.address}
          </Typography>
        </div>
      </div>
      {planUsageData?.length > 0 && (
        <div className="bg-white w-full rounded-md p-16 mt-24">
          {planUsageData?.map((plan, i) => {
            return (
              <div className="">
                {i !== 0 && <hr className="my-16 border-dashed border border-gray-400" />}
                <div className="flex justify-between">
                  <Typography className="text-16 font-semibold">{plan?.viewName}</Typography>
                  <Typography className="text-16 font-semibold">
                    {plan?.usedCount}/{plan?.totalCount}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlanUsage;

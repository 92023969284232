import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import history from '@history';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import { publicIpv4 } from 'public-ip';
import withReducer from 'app/store/withReducer';
import businessData from '../../../query/business';
import {
  Message,
  getDecryptData,
  getEncryptedData,
  getParamsId,
  handleApiRequest,
} from '../../../common/common';
import { storeCustomerList, storeSendSmsOrEmailDetails } from '../store/targetMessageSlice';
import { selectDashboardData } from '../../../../store/userSlice';
import planQuery from '../../../query/plan';
import reducer from '../store';

const SelectPlateFormType = () => {
  const [businessListData, setBusinessListData] = useState([]);
  const [limitAvailable, setLimitAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    if (!sendSmsOrEmailDetailsData?.phone) {
      setLoading(true);
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: planQuery.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await handleApiRequest(payload);
        setLoading(false);
        if (locationResponse?.getLocation?.data) {
          const locationData = JSON.parse(getDecryptData(locationResponse?.getLocation?.data));
          dispatch(
            storeSendSmsOrEmailDetails({
              ...sendSmsOrEmailDetailsData,
              phone: {
                countryCode: locationData?.isoCode?.toLowerCase(),
                dialCode: locationData?.countryCode.toString(),
              },
            })
          );
        }
      };
      ipAddressData();
    }
  }, [dispatch, sendSmsOrEmailDetailsData]);

  useEffect(() => {
    const id = getParamsId();
    let targetMessageType = '';
    if (id) {
      targetMessageType =
        location?.pathname === `/invite-reviews/${id}` ? 'invite_reviews' : 'send_messages';
    } else {
      targetMessageType =
        location?.pathname === '/invite-reviews/' ? 'invite_reviews' : 'send_messages';
    }

    if (sendSmsOrEmailDetailsData?.targetMessageType !== targetMessageType) {
      dispatch(
        storeSendSmsOrEmailDetails({
          ...sendSmsOrEmailDetailsData,
          selectPlateFormType: 2,
          tabValue: 1,
          targetMessageType,
          businessId: 0,
        })
      );
    }
    if (sendSmsOrEmailDetailsData?.tabValue === 1) {
      dispatch(storeCustomerList({}));
    }
  }, [dispatch, location?.pathname, sendSmsOrEmailDetailsData, businessListData]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      setLoading(true);
      const payload = {
        query: businessData.getBusinessBasedOnUserId,
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getBusinessBasedOnUserId?.status === 200) {
        setBusinessListData(result?.getBusinessBasedOnUserId?.data);
      }
    };
    fetchBusinessData();
  }, [dispatch]);

  const handleTabChange = async (event, newValue) => {
    dispatch(
      storeSendSmsOrEmailDetails({
        ...sendSmsOrEmailDetailsData,
        tabValue: newValue,
      })
    );
  };

  const handlePlateFormChange = async (e) => {
    dispatch(
      storeSendSmsOrEmailDetails({
        ...sendSmsOrEmailDetailsData,
        selectPlateFormType: parseInt(e.target.value, 10),
      })
    );
    const payload = {
      query: businessData.campaignServiceValidation,
      variables: {
        campaignPlatformId: parseInt(e.target.value, 10),
        userBusinessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.campaignServiceValidation?.status === 200) {
      setLimitAvailable(result?.campaignServiceValidation);
    }
  };

  const handleRedirect = () => {
    const data = {
      ...sendSmsOrEmailDetailsData,
      serviceType: 'individual_type',
      campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10),
      businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      reviewUrl: userCurrentBusinessDetails?.businessQRId,
      businessName: userCurrentBusinessDetails?.name,
    };
    dispatch(storeSendSmsOrEmailDetails(data));
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`create-new-review/${id}`);
      } else {
        history.push('create-new-review');
      }
    } else if (id) {
      history.push(`create-new-message/${id}`);
    } else {
      history.push('create-new-message');
    }
  };

  const handleNextRedirect = () => {
    const data = {
      ...sendSmsOrEmailDetailsData,
      serviceType: 'bulk_type',
      campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10),
      businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      reviewUrl: userCurrentBusinessDetails?.businessQRId,
      businessName: userCurrentBusinessDetails?.name,
    };
    dispatch(storeSendSmsOrEmailDetails(data));
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`select-invite-review-customer/${id}`);
      } else {
        history.push(`select-invite-review-customer`);
      }
    } else if (id) {
      history.push(`select-send-message-customer/${id}`);
    } else {
      history.push('select-send-message-customer');
    }
  };

  const handleHistoryButton = () => {
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`invite-review-history/${id}`);
      } else {
        history.push('invite-review-history');
      }
    } else if (id) {
      history.push(`send-message-history/${id}`);
    } else {
      history.push('send-message-history');
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
              ? t('inviteReviews.title')
              : t('sendMessage.title')}
          </Typography>
        </div>
        <div className="flex gap-24">
          <Button
            className="bg-darkgreen rounded px-20 text-white hover:bg-darkgreen"
            onClick={() => handleHistoryButton()}
          >
            {t('inviteReviews.buttons.history')}
          </Button>
        </div>
      </div>
      <div className="bg-white px-20 md:px-36 py-20 rounded-md lg:w-[50%] relative shadow">
        <Tabs
          value={parseInt(sendSmsOrEmailDetailsData?.tabValue, 10)}
          className="rounded-tl-md rounded-tr-md overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: 'scroll !important',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={1}
            label={t('inviteReviews.individualMessage')}
          />
          <Tab
            className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
            value={2}
            label={t('inviteReviews.bulkMessage')}
          />
        </Tabs>
        <div className="flex items-center my-20 justify-between">
          <Typography className="font-medium text-18">
            {t('inviteReviews.selectPlateForm')}
          </Typography>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10)}
          onChange={handlePlateFormChange}
        >
          <div className="flex justify-between gap-12 md:gap-24">
            <div className="border-2 border-[#707070] rounded-md p-10">
              <FormControlLabel value="2" control={<Radio />} label="SMS" />
              <Typography>
                Your invitation will be sent to the customer's phone number via text message
              </Typography>
            </div>
            <div className="border-2 border-[#707070] rounded-md p-10">
              <FormControlLabel value="1" control={<Radio />} label="Email" />
              <Typography>Your invitation will be sent to the customer's email address.</Typography>
            </div>
          </div>
        </RadioGroup>
        <div>
          <div className="mt-8">
            {parseInt(sendSmsOrEmailDetailsData?.tabValue, 10) === 1 && (
              <div>
                <div className="mt-12">
                  <Typography className="mt-12 mb-6">
                    {t('inviteReviews.firstName')}
                    <span className=" text-2xl text-red-500">*</span>
                  </Typography>
                  <TextField
                    className="w-full"
                    size="small"
                    value={sendSmsOrEmailDetailsData?.firstName}
                    onChange={(e) =>
                      dispatch(
                        storeSendSmsOrEmailDetails({
                          ...sendSmsOrEmailDetailsData,
                          firstName: e.target.value,
                        })
                      )
                    }
                  />
                  <Typography className="mt-12 mb-6">
                    {t('inviteReviews.lastName')}
                    <span className=" text-2xl text-red-500">*</span>
                  </Typography>
                  <TextField
                    className="w-full"
                    size="small"
                    value={sendSmsOrEmailDetailsData?.lastName}
                    onChange={(e) =>
                      dispatch(
                        storeSendSmsOrEmailDetails({
                          ...sendSmsOrEmailDetailsData,
                          lastName: e.target.value,
                        })
                      )
                    }
                  />
                  {sendSmsOrEmailDetailsData?.selectPlateFormType === 1 ? (
                    <>
                      <Typography className="mt-12 mb-6">
                        {t('inviteReviews.email')}
                        <span className=" text-2xl text-red-500">*</span>
                      </Typography>
                      <TextField
                        className="w-full"
                        size="small"
                        value={sendSmsOrEmailDetailsData?.email}
                        onChange={(e) => {
                          const emailValue = e.target.value;
                          dispatch(
                            storeSendSmsOrEmailDetails({
                              ...sendSmsOrEmailDetailsData,
                              email: emailValue,
                            })
                          );
                          if (!Message.emailRegex.test(emailValue)) {
                            setIsEmailValid(true);
                            setEmailErrorMessage(
                              t('signInPage.formField.email.validationMessages.validEmail')
                            );
                          } else {
                            setIsEmailValid(false);
                            setEmailErrorMessage();
                          }
                        }}
                      />
                      {isEmailValid && (
                        <Typography className="text-red pt-4">{emailErrorMessage}</Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography className="mt-12 mb-6">
                        {t('inviteReviews.phone')}
                        <span className=" text-2xl text-red-500">*</span>
                      </Typography>
                      <PhoneInput
                        placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                        inputStyle={{
                          width: '100%',
                          height: '42px',
                        }}
                        className="w-full"
                        name="phone_number"
                        required
                        country={sendSmsOrEmailDetailsData?.phone?.countryCode}
                        enableSearch="true"
                        value={sendSmsOrEmailDetailsData?.phone?.phoneNumber}
                        onChange={(value, data) => {
                          const phoneNumber = value.replace(/[^0-9]/g, '');
                          dispatch(
                            storeSendSmsOrEmailDetails({
                              ...sendSmsOrEmailDetailsData,
                              phone: {
                                ...data,
                                phoneNumber,
                              },
                            })
                          );
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <div>
              <Typography className="mt-12 mb-6">{t('inviteReviews.selectBusiness')}</Typography>
              {businessListData
                .filter((item) => item.id === userCurrentBusinessDetails?.id)
                .map((item) => (
                  <div key={item.id} className="w-full border border-gray-400 rounded-md p-10">
                    <Typography className="max-w-640 min-w-136 font-semibold w-auto break-all whitespace-normal">
                      {item.name}
                    </Typography>
                    <Typography className="text-md break-all w-full tracking-normal text-black opacity-100">
                      {item.address || item.address2}
                    </Typography>
                  </div>
                ))}
            </div>
            {sendSmsOrEmailDetailsData?.tabValue === 1 && (
              <div className="text-center mt-28 mb-16">
                <Button
                  className="bg-darkgreen rounded-md text-white hover:bg-darkgreen disabled:text-black disabled:bg-gray px-60"
                  onClick={() => handleRedirect()}
                  disabled={
                    !sendSmsOrEmailDetailsData?.firstName ||
                    !sendSmsOrEmailDetailsData?.lastName ||
                    (sendSmsOrEmailDetailsData?.selectPlateFormType === 1
                      ? !sendSmsOrEmailDetailsData?.email ||
                        !/\S+@\S+\.\S+/.test(sendSmsOrEmailDetailsData.email)
                      : !/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
                          sendSmsOrEmailDetailsData?.phone?.phoneNumber?.slice(
                            sendSmsOrEmailDetailsData?.phone?.dialCode.length
                          )
                        ))
                  }
                >
                  {sendSmsOrEmailDetailsData?.selectPlateFormType === 1
                    ? t('inviteReviews.selectEmail')
                    : t('inviteReviews.selectSms')}
                </Button>
              </div>
            )}
            {parseInt(sendSmsOrEmailDetailsData?.tabValue, 10) === 2 && (
              <div>
                <div className="text-center mt-28 mb-16">
                  <Button
                    className="bg-darkgreen rounded-md text-white disabled:text-black disabled:bg-gray hover:bg-darkgreen px-60"
                    type="button"
                    onClick={() => handleNextRedirect()}
                  >
                    {t('inviteReviews.next')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withReducer('state', reducer)(SelectPlateFormType);

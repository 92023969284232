import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import history from '@history';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getParamsId, reviewFormate } from '../../common/common';

const BusinessPlateForm = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const redirect = () => {
    const id = getParamsId();
    if (id) {
      history.push(`reviews/${id}`);
    } else {
      history.push('reviews/');
    }
  };

  return (
    <div className="bg-white rounded-md relative shadow p-20 w-full">
      <Typography className="font-bold text-20">
        {t('businessDashboard.businessPlateForm')}
      </Typography>
      {userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.map((item, index) => {
          return (
            <div
              className={`flex items-center justify-between mt-16 p-12 rounded-lg min-h-112 ${
                (parseInt(item?.reviewPlatformId, 10) === 2 ||
                  parseInt(item?.reviewPlatformId, 10) === 5) &&
                'bg-blue-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 3 ||
                  parseInt(item?.reviewPlatformId, 10) === 7) &&
                'bg-orange-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 4 ||
                  parseInt(item?.reviewPlatformId, 10) === 6) &&
                'bg-purple-A300'
              }`}
              key={index}
            >
              <div className="flex gap-16 items-center">
                <div className="bg-white p-12 rounded">
                  <img className="w-32 m-auto" src={item?.logo} alt="" />
                </div>
                <div>
                  <Typography className="text-16 font-semibold">
                    {item?.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </Typography>
                  <Typography className="text-14">Based on {item?.totalReview} Reviews</Typography>
                  <Typography className="pr-8">
                    {reviewFormate(item?.totalRatingStars)}{' '}
                    <span className="font-semibold">{item?.totalRatingStars} / 5</span>
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  className="bg-white hover:bg-white rounded max-w-40 min-w-40 w-40"
                  onClick={() => redirect()}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BusinessPlateForm;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`reviewPlatform/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
const handleReviewPlatformRequest = async (data, operationName) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  const formT = new FormData();
  formT.append('operations', JSON.stringify(data.payload));
  if (data.filedata) {
    formT.append('map', '{"nFile":["variables.logo"]}');
    formT.append('nFile', data.filedata);
  } else {
    formT.append('map', '{}');
  }
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, formT, {
      headers: operationName
        ? {
            'apollo-require-preflight': 'true',
            'x-apollo-operation-name': operationName,
          }
        : {},
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const addReviewPlatform = createAsyncThunk(
  'reviewPlatform/addReviewPlatform',
  async (data, { dispatch }) => {
    const result = await handleReviewPlatformRequest(data, 'addReviewPlatform');
    dispatch(closeNewReviewPlatformDialog());
    return result;
  }
);

export const updateReviewPlatform = createAsyncThunk(
  'reviewPlatform/updateReviewPlatform',
  async (data, { dispatch }) => {
    const result = await handleReviewPlatformRequest(data, 'updateReviewPlatform');
    dispatch(closeNewReviewPlatformDialog());
    dispatch(closeEditReviewPlatformDialog());
    return result;
  }
);
export const getReviewPlatformForPortal = createAsyncThunkWithAuthorization(
  'getReviewPlatformForPortal',
  'reviewPlatform/getReviewPlatformForPortal'
);

export const getCountries = createAsyncThunkWithAuthorization(
  'getCountries',
  'reviewPlatform/getCountries'
);
export const enableDisableReviewType = createAsyncThunkWithAuthorization(
  'enableDisableReviewType',
  'reviewPlatform/enableDisableReviewType'
);

const reviewPlatformSlice = createSlice({
  name: 'reviewPlatform',
  initialState: {
    reviewPlatformDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openNewReviewPlatformDialog: (state, action) => {
      state.reviewPlatformDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewReviewPlatformDialog: (state, action) => {
      state.reviewPlatformDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditReviewPlatformDialog: (state, action) => {
      state.reviewPlatformDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditReviewPlatformDialog: (state, action) => {
      state.reviewPlatformDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    resetReviewPlatform: (state, action) => {
      state.getReviewPlatformForPortalData = {};
      state.addReviewPlatformData = {};
      state.updateReviewPlatformData = {};
      state.getCountriesData = {};
      state.enableDisableReviewTypeData = {};
    },
  },
  extraReducers: {
    [getReviewPlatformForPortal.fulfilled]: (state, action) => ({
      ...state,
      getReviewPlatformForPortalData: action.payload,
    }),
    [addReviewPlatform.fulfilled]: (state, action) => ({
      ...state,
      addReviewPlatformData: action.payload,
    }),
    [updateReviewPlatform.fulfilled]: (state, action) => ({
      ...state,
      updateReviewPlatformData: action.payload,
    }),
    [getCountries.fulfilled]: (state, action) => ({
      ...state,
      getCountriesData: action.payload,
    }),
    [enableDisableReviewType.fulfilled]: (state, action) => ({
      ...state,
      enableDisableReviewTypeData: action.payload,
    }),
  },
});

export const {
  resetReviewPlatform,
  openNewReviewPlatformDialog,
  closeNewReviewPlatformDialog,
  openEditReviewPlatformDialog,
  closeEditReviewPlatformDialog,
} = reviewPlatformSlice.actions;

export default reviewPlatformSlice.reducer;

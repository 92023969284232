import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import history from '@history';
import moment from 'moment';
import Button from '@mui/material/Button';
import cmsData from '../../query/cms';
import { getBusinessFeedback, resetCmsApp } from './store/cmsAppSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Feedback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const loginUserData = UserService.getUserData();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const feedbackData = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getBusinessFeedbackData?.data?.getBusinessFeedback?.data || ''
  );
  const totalFeedback = useSelector(
    ({ cmsAppReducer }) =>
      cmsAppReducer?.cmsApp?.getBusinessFeedbackData?.data?.getBusinessFeedback?.totalCount || 0
  );
  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getBusinessFeedback,
        variables: {
          domainRegisterId: userDomainRegisterId,
          pageNumber: 1,
          pageSize: 10,
          searchText: '',
        },
      };
      dispatch(getBusinessFeedback(payload));
    }

    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (feedbackData || feedbackData === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [feedbackData, isValidDomainRegisterId]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: cmsData.getBusinessFeedback,
      variables: {
        domainRegisterId: userDomainRegisterId,
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    dispatch(getBusinessFeedback(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: cmsData.getBusinessFeedback,
      variables: {
        domainRegisterId: userDomainRegisterId,
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessFeedback(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: cmsData.getBusinessFeedback,
      variables: {
        domainRegisterId: userDomainRegisterId,
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessFeedback(payload));
      setLoadingPage(false);
    });
  }
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40 h-full">
      {isValidDomainRegisterId ? (
        <>
          {loadingPage && (
            <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
              <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
            </div>
          )}
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-16 md:text-24 font-bold"
              >
                {t('appWebsite.feedback.title')}
              </Typography>
            </div>
            <div className="flex flex-1 items-center justify-center px-12">
              <Paper
                component={motion.div}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
              >
                <Input
                  placeholder="Search"
                  className="flex flex-1 mx-8 text-black text-16"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchEvent}
                  value={searchValue}
                />
                {searchValue ? (
                  <Icon
                    color="action"
                    className="cursor-pointer"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    close
                  </Icon>
                ) : (
                  <Icon color="action">search</Icon>
                )}
              </Paper>
            </div>
          </div>
          <div className="bg-white rounded-md relative shadow p-24">
            {feedbackData && feedbackData?.length > 0 ? (
              <>
                {feedbackData.map((item, index) => (
                  <div
                    className="border-[1px] !border-gray-400 rounded px-[30px] py-[25px] mb-20 last:mb-0"
                    key={index}
                  >
                    <div>
                      <span className="font-semibold text-18">
                        {item?.client_app_device_register_and_login?.fullName}
                      </span>
                      <span className="flex item-center justify-between">
                        <span className="text-grey font-medium text-14">
                          {moment(item.cts).format('MMM DD, YYYY')}
                        </span>
                        <span className="text-blue font-medium text-16">
                          {' '}
                          {item?.client_app_device_register_and_login?.mobileNumber}
                        </span>
                      </span>
                    </div>
                    <div className="font-medium text-16 mt-16">{item?.messages}</div>
                  </div>
                ))}
              </>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
              >
                <Typography color="textSecondary" variant="h5" className="text-16">
                  {t('appWebsite.feedback.noFeedbackMsg')}
                </Typography>
              </motion.div>
            )}
          </div>
          {feedbackData?.length > 0 && (
            <TablePagination
              sx={{
                '.MuiInputBase-root': {
                  lineHeight: 'inherit',
                  padding: 0,
                },
              }}
              component="div"
              count={totalFeedback}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsReducer', reducer)(Feedback);

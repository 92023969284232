import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const SigninBoxContent = () => {
  const { t } = useTranslation();

  const stars = 'assets/images/icon/simple-star.svg';

  const repeatedContent = Array.from({ length: 5 }).map((_, index) => (
    <img src={stars} className="mr-14" alt="star" key={index} />
  ));

  return (
    <Box
      className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
      style={{
        backgroundImage: `url('assets/images/background/auth_bg.jpg')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="z-10 relative w-full max-w-2xl">
        <div className="text-32 font-semibold leading-none text-white">
          <div className="mb-14">{t('signInPage.messages.welcomeTitle')}</div>
        </div>
        <div className="mt-32 mb-28 text-18 leading-8 text-white max-w-sm">
          {t('signUpPage.messages.locateBusiness')}
        </div>
        <div className="flex">{repeatedContent}</div>
      </div>
    </Box>
  );
};

export default SigninBoxContent;

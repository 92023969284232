import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import history from '@history';
import Icon from '@mui/material/Icon';
import userService from '../../../services';
import AddEditClientPopUp from '../clients/addEditClient';
import { getDataToServer, handleApiRequest } from '../../common/common';
import agencyData from '../../query/agency';
import manageBusinessData from '../../query/manageBusiness';
import BusinessLocation from '../../business/manage-business/BusinessLocation';
import { getReviewPlaterFormData } from '../../../store/userSlice';

function AddBusiness() {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginUserData = userService.getUserData();

  const [agencyClientList, setAgencyClientList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(parseInt(routeParams.id, 10) || 0);
  const [selectClient, setSelectClient] = useState(false);
  const [reviewPlateFormData, setReviewPlateFormData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openAddDialogue, setOpenAddDialogue] = useState(false);
  const params = useParams();
  const reviewPlateForm = useSelector(getReviewPlaterFormData);

  useEffect(() => {
    if (params?.id) {
      setSelectClient(true);
    }
  }, [params?.id]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: agencyData.getAgencyClientList,
        variables: {
          pageSize: 20,
          pageNumber: 1,
          searchText: '',
        },
      };
      const result = await getDataToServer(payload);
      if (result?.getAgencyClientList?.status === 200) {
        setPageLoading(false);
      }
      setAgencyClientList(result?.getAgencyClientList?.data);
    };
    fetchDashboard();
  }, [dispatch]);

  useEffect(() => {
    const excludedPlatforms = ['amazon', 'flipkart', 'quickreviews', 'trustpilot'];
    const filteredData = reviewPlateForm?.filter(
      (platform) => !excludedPlatforms?.includes(platform?.name)
    );
    const updatedData = filteredData?.map((platform) => ({
      ...platform,
      isBusinessDetailsAdded: false,
    }));
    const googlePlatform = updatedData?.find((platform) => platform.name === 'google');
    const otherPlatforms = updatedData?.filter((platform) => platform.name !== 'google');
    const finalData = googlePlatform ? [googlePlatform, ...otherPlatforms] : otherPlatforms;
    setReviewPlateFormData(finalData);
  }, [reviewPlateForm]);

  if (pageLoading) {
    return <FuseLoading />;
  }

  const cancelDialogue = async () => {
    setOpenAddDialogue(false);
    const payload = {
      query: agencyData.getAgencyClientList,
      variables: {
        searchText: '',
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAgencyClientList?.status === 200) {
      setAgencyClientList(result?.getAgencyClientList?.data);
      setSelectedClientId(0);
    }
  };

  const handleBackArrow = () => {
    setSelectClient(false);
  };

  return (
    <div className="p-20 lg:p-40">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex gap-12 items-center">
          {selectClient && (
            <Icon className="text-20 cursor-pointer" onClick={() => handleBackArrow()}>
              west
            </Icon>
          )}
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('agencyBusiness.addNewBusiness.title')}
          </Typography>
        </div>
      </div>

      <div className="bg-white rounded-md relative shadow py-68 px-16">
        {!selectClient ? (
          <>
            <div className="text-center">
              <h3 className="text-24 font-semibold block mb-20">
                {t('agencyBusiness.addNewBusiness.selectClient')}
              </h3>
              <Typography
                color="textSecondary"
                variant="h5"
                className="text-16 max-w-640 mx-auto mb-28"
              >
                {t('agencyBusiness.addNewBusiness.selectClientDesc')}
              </Typography>
              <Select
                className="block max-w-640 mx-auto"
                id="country"
                value={selectedClientId}
                size="small"
                displayEmpty
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                onChange={(e) => {
                  setSelectedClientId(e?.target?.value);
                }}
              >
                <MenuItem value={0} disabled>
                  {t('agencyBusiness.addNewBusiness.selectName')}
                </MenuItem>
                {agencyClientList?.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.firstName &&
                      option.firstName.charAt(0).toUpperCase() + option.firstName.slice(1)}{' '}
                    {option?.lastName &&
                      option.lastName.charAt(0).toUpperCase() + option.lastName.slice(1)}
                  </MenuItem>
                ))}
                <MenuItem
                  className="text-darkgreen font-medium"
                  onClick={() => setOpenAddDialogue(true)}
                >
                  <Icon>add</Icon> {t('agencyBusiness.clients.addClient')}
                </MenuItem>
              </Select>
            </div>
            <div className="text-center mt-80">
              <Button
                className="text-16 font-medium px-32 mx-auto disabled:bg-darkgreen-200 rounded-md"
                variant="contained"
                color="secondary"
                aria-label="Edit"
                disabled={selectedClientId === 0}
                onClick={(e) => setSelectClient(true)}
              >
                {t('agencyBusiness.addNewBusiness.confirm')}
              </Button>
            </div>
          </>
        ) : (
          <div className="max-w-[900px] w-full mx-auto">
            <BusinessLocation
              clientId={selectedClientId}
              reviewPlateFormData={reviewPlateFormData}
              setReviewPlateFormData={setReviewPlateFormData}
            />
          </div>
        )}
      </div>
      {openAddDialogue && (
        <AddEditClientPopUp
          openValue={openAddDialogue}
          cancelCall={cancelDialogue}
          type="add"
          popUpMessage={t('agencyBusiness.clients.addClient')}
          popUpButtonName={t('agencyBusiness.clients.addClients')}
        />
      )}
    </div>
  );
}
export default AddBusiness;

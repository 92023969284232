import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

export default function UserFilterDialog({
  filterOpen,
  setFilterOpen,
  open,
  setOpen,
  selectedPlanType,
  setSelectedPlanType,
  selectedStatus,
  setSelectedStatus,
  selectedCountries,
  setSelectedCountries,
  selectedSubcription,
  setSelectedSubcription,
  selectedSubcriptionPeriod,
  setSelectedSubcriptionPeriod,
  statusData,
  getStatusClick,
  fetchAllUsers,
  setFilterLength,
  searchValue,
}) {
  const { t } = useTranslation();
  const [countryIcon, setCountryIcon] = useState(false);
  const [statusIcon, setStatusIcon] = useState(false);
  const [subscriptionIcon, setSubscriptionIcon] = useState(false);
  const [subscriptionPeriodIcon, setSubscriptionPeriodIcon] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    Yearly: true,
    Monthly: true,
  });
  const countries = [
    {
      id: 2,
      countryName: 'India',
    },
    {
      id: 1,
      countryName: 'USA',
    },
  ];
  const subscriptionPeriodArray = [
    {
      id: 1,
      subPerName: 'Monthly',
    },
    {
      id: 2,
      subPerName: 'Yearly',
    },
  ];
  const subscriptionConditionArray = [
    {
      id: 1,
      subName: 'free',
    },
    {
      id: 2,
      subName: 'paid',
    },
    {
      id: 3,
      subName: 'expired',
    },
  ];

  const handleFilterClose = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
  };

  const handleplanTypeChange = (plan) => {
    setSelectedPlanType((prevSelectedPlan) => {
      if (prevSelectedPlan.some((c) => c.id === plan.id)) {
        return prevSelectedPlan.filter((c) => c.id !== plan.id);
      }
      return [...prevSelectedPlan, plan];
    });
  };
  const handleSubscriptionChange = (sub) => {
    setSelectedSubcription((prev) => {
      if (prev.some((c) => c.id === sub.id)) {
        return prev.filter((c) => c.id !== sub.id);
      }
      return [...prev, sub];
    });
  };
  const handleSubscriptionPeriodChange = (subPeriod) => {
    setSelectedSubcriptionPeriod((prev) => {
      if (prev.some((c) => c.id === subPeriod.id)) {
        return prev.filter((c) => c.id !== subPeriod.id);
      }
      return [...prev, subPeriod];
    });
  };

  const handleCountryChange = (country) => {
    setSelectedCountries((prevSelectedCountries) => {
      if (prevSelectedCountries.some((c) => c.id === country.id)) {
        return prevSelectedCountries.filter((c) => c.id !== country.id);
      }
      return [...prevSelectedCountries, country];
    });
  };

  const handleStatusChange = (status) => {
    setSelectedStatus((prevSelectedStatus) => {
      if (prevSelectedStatus.some((c) => c.id === status.id)) {
        return prevSelectedStatus.filter((c) => c.id !== status.id);
      }
      return [...prevSelectedStatus, status];
    });
  };

  const handleFilterApply = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
    getStatusClick(0, searchValue);
  };

  const clearFilters = () => {
    setFilterLength([]);
    setSelectedPlanType([]);
    setSelectedStatus([]);
    setSelectedCountries([]);
    setSelectedSubcription([]);
    setSelectedSubcriptionPeriod([]);
    fetchAllUsers();
  };

  return (
    <div>
      <Menu
        className="rounded-lg mt-96 h-70 w-140"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            mt: 5,
            ml: -5,
            height: '600px',
            width: '250px',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={filterOpen}
        onClose={handleFilterClose}
      >
        <div className="px-8">
          <div className="flex justify-between items-center ms-10">
            <Typography variant="h6" className="text-black text-base font-semibold">
              {t('users.UserList.Filter.mainBtn')}
            </Typography>
            <IconButton onClick={handleFilterClose}>
              <CloseIcon className="text-lg" />
            </IconButton>
          </div>
          <hr className=" ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setCountryIcon(!countryIcon)}
            >
              {t('users.UserList.Filter.country')}
            </FormLabel>
            {countryIcon ? (
              <ArrowDropUpIcon onClick={() => setCountryIcon(!countryIcon)} className="mt-7" />
            ) : (
              <ArrowDropDownIcon onClick={() => setCountryIcon(!countryIcon)} className="mt-7" />
            )}
          </div>

          {countryIcon && (
            <FormGroup className="ms-5 me-7">
              {countries.map((country) => (
                <FormControlLabel
                  className="-ml-6 -mt-[14px] capitalize"
                  key={country.id}
                  control={
                    <Checkbox
                      className="-mr-5"
                      checked={selectedCountries.some(
                        (selectedCountry) => selectedCountry.id === country.id
                      )}
                      onChange={() => handleCountryChange(country)}
                      value={country.countryName}
                    />
                  }
                  label={country.countryName}
                />
              ))}
            </FormGroup>
          )}
          <hr className=" ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setStatusIcon(!statusIcon)}
            >
              {t('users.UserList.Filter.status')}
            </FormLabel>
            {statusIcon ? (
              <ArrowDropUpIcon onClick={() => setStatusIcon(!statusIcon)} className="mt-7" />
            ) : (
              <ArrowDropDownIcon onClick={() => setStatusIcon(!statusIcon)} className="mt-7" />
            )}
          </div>
          {statusIcon && (
            <FormGroup className="ms-5 me-7 capitalize">
              {statusData?.map((statusItem) => (
                <FormControlLabel
                  className="-ml-6 -mt-[14px] capitalize"
                  key={statusItem.id}
                  control={
                    <Checkbox
                      className="-mr-5"
                      checked={selectedStatus.some(
                        (selectStatus) => selectStatus.id === statusItem.id
                      )}
                      onChange={() => handleStatusChange(statusItem)}
                      value={statusItem.status}
                    />
                  }
                  label={statusItem.status}
                />
              ))}
            </FormGroup>
          )}
          <hr className=" ms-10 me-7" />
          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setSubscriptionIcon(!subscriptionIcon)}
            >
              {t('users.UserList.Filter.subscription')}
            </FormLabel>
            {subscriptionIcon ? (
              <ArrowDropUpIcon
                onClick={() => setSubscriptionIcon(!subscriptionIcon)}
                className="mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setSubscriptionIcon(!subscriptionIcon)}
                className="mt-7"
              />
            )}
          </div>
          {subscriptionIcon && (
            <FormGroup className="ms-5 me-7 ">
              {subscriptionConditionArray?.map((subItem) => (
                <FormControlLabel
                  className="-ml-6 -mt-[14px] capitalize"
                  key={subItem.id}
                  control={
                    <Checkbox
                      className="-mr-5"
                      checked={selectedSubcription.some((selectSub) => selectSub.id === subItem.id)}
                      onChange={() => handleSubscriptionChange(subItem)}
                      value={subItem.subName}
                    />
                  }
                  label={subItem.subName}
                />
              ))}
            </FormGroup>
          )}

          <hr className=" ms-10 me-7" />
          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setSubscriptionPeriodIcon(!subscriptionPeriodIcon)}
            >
              {t('users.UserList.Filter.subscriptionPeriod')}
            </FormLabel>
            {subscriptionPeriodIcon ? (
              <ArrowDropUpIcon
                onClick={() => setSubscriptionPeriodIcon(!subscriptionPeriodIcon)}
                className="mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setSubscriptionPeriodIcon(!subscriptionPeriodIcon)}
                className="mt-7"
              />
            )}
          </div>
          {subscriptionPeriodIcon && (
            <FormGroup className="ms-5 me-7">
              {subscriptionPeriodArray?.map((subPerItem) => (
                <FormControlLabel
                  className="-ml-6 -mt-[14px] capitalize"
                  key={subPerItem.id}
                  control={
                    <Checkbox
                      className="-mr-5"
                      checked={selectedSubcriptionPeriod.some(
                        (selectSub) => selectSub.id === subPerItem.id
                      )}
                      onChange={() => handleSubscriptionPeriodChange(subPerItem)}
                      value={subPerItem.subPerName}
                    />
                  }
                  label={subPerItem.subPerName}
                />
              ))}
            </FormGroup>
          )}

          <hr className="ms-10 me-7" />
          {(selectedCountries.length >= 1 ||
            selectedPlanType.length >= 1 ||
            selectedSubcription.length >= 1 ||
            selectedSubcriptionPeriod.length >= 1 ||
            selectedStatus.length >= 1) && (
            <div className="flex justify-end  gap-8 mt-[150px]">
              <Button variant="outlined" color="secondary" onClick={clearFilters}>
                {t('users.UserList.Filter.clearBtn')}
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleFilterApply()}>
                {t('users.UserList.Filter.applyBtn')}
              </Button>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
}

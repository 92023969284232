import ComparePlanAndFeature from './ComparePlanAndFeature';

const ComparePlanAndFeatureConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'compare-plans-and-features',
      element: <ComparePlanAndFeature />,
    },
  ],
};

export default ComparePlanAndFeatureConfig;

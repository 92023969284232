import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import withReducer from '../../../../store/withReducer';
import businessData from '../../../query/business';
import { getBusinessBasedOnUserId } from '../store/promotionSlice';
import reducer from '../store';

const SelectBusinessForRedeem = (props) => {
  const { selectBusiness, setSelectedBusiness } = props;
  const dispatch = useDispatch();
  const [businessListData, setBusinessListData] = useState([]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      const payload = {
        query: businessData.getBusinessBasedOnUserId,
      };
      const result = await dispatch(getBusinessBasedOnUserId(payload));
      if (result?.payload?.getBusinessBasedOnUserId?.status === 200) {
        setBusinessListData(result?.payload?.getBusinessBasedOnUserId?.data);
        setSelectedBusiness(result?.payload?.getBusinessBasedOnUserId?.data?.[0]?.id);
      }
    };
    fetchBusinessData();
  }, [dispatch, setSelectedBusiness]);

  return (
    <div className="bg-white rounded-lg relative shadow p-20">
      <div className="border-b-2 border-solid border-black border-dotted">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-20 pb-16 font-medium"
        >
          {t('promotion.selectBusiness')}
        </Typography>
      </div>
      <Typography className="pt-16">{t('promotion.choosePromotions')}</Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectBusiness}
        onChange={(event) => setSelectedBusiness(parseInt(event.target.value, 10))}
      >
        {businessListData?.length > 0 &&
          businessListData.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.id}
              control={<Radio />}
              label={item.name}
              checked={selectBusiness === item.id}
            />
          ))}
      </RadioGroup>
    </div>
  );
};

export default withReducer('promotionReducer', reducer)(SelectBusinessForRedeem);

import Clients from './clients';
import ClientDetails from './ClientDetails';

const ClientsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'agency/clients',
      element: <Clients />,
    },
    {
      path: 'agency/client/:clientId',
      element: <ClientDetails />,
    },
  ],
};

export default ClientsConfigs;

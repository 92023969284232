import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import cmsData from '../../query/cms';
import {
  resetCmsWebsite,
  getUserBusinessProduct,
  deleteUserBusinessProduct,
  addUserBusinessProduct,
  updateUserBusinessProduct,
} from './store/cmsWebsiteSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Product() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form Validation Schema
   */

  const defaultValues = {};

  const schema = yup.object().shape({
    formData: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .required(t('appWebsite.product.formField.bannerTitle.validationMessages.required')),
        description: yup
          .string()
          .required(t('appWebsite.product.formField.description.validationMessages.required')),
      })
    ),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [imagePreviewName, setImagePreviewName] = useState([]);
  const [noImgMsg, setNoImgMsg] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [actionValue, setActionValue] = useState('action');
  const loginUserData = UserService.getUserData();
  const [editProductId, setEditProductId] = useState(null);
  const [editImagePreview, setEditImagePreview] = useState();

  const sliderDetails = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getUserBusinessProductData?.data?.getUserBusinessProduct
        ?.data || ''
  );

  const deleteStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.deleteUserBusinessProductData?.data
        ?.deleteUserBusinessProduct || ''
  );

  const updateBusinessBlogStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.updateUserBusinessProductData?.data
        ?.updateUserBusinessProduct || ''
  );

  useEffect(() => {
    const payload = {
      query: cmsData.getUserBusinessProduct,
      variables: { domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10) },
    };
    dispatch(getUserBusinessProduct(payload));

    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, loginUserData?.domainRegisterId]);

  useEffect(() => {
    if (deleteStatus?.status) {
      enqueueSnackbar(deleteStatus?.message, {
        variant: deleteStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [deleteStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateBusinessBlogStatus?.status) {
      enqueueSnackbar(updateBusinessBlogStatus?.message, {
        variant: updateBusinessBlogStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateBusinessBlogStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (sliderDetails?.length === 0) {
      append({ title: '', description: '' });
    }
  }, [sliderDetails?.length, append]);

  useEffect(() => {
    if (sliderDetails || sliderDetails === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [sliderDetails]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleDeleteProduct = async (id) => {
    try {
      const deletePayload = {
        query: cmsData.deleteUserBusinessProduct,
        variables: {
          businessProductId: parseInt(id, 10),
          domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10),
        },
      };
      await dispatch(deleteUserBusinessProduct(deletePayload));
      const obj = {
        query: cmsData.getUserBusinessProduct,
        variables: { domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10) },
      };
      await dispatch(getUserBusinessProduct(obj));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditProduct = async (item) => {
    setEditProductId(parseInt(item.id, 10));
    setEditImagePreview(item?.image);
    setIsEdit(true);
    const fieldsCopy = [...fields];
    fieldsCopy.forEach((field, index) => {
      remove(index);
    });
    append(item);
  };

  function handleFileChange(event, index) {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }

    imagePreviewName[index] = event?.target?.files[0].name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);

    setNoImgMsg('');
  }

  function handleDrop(event, index) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    imagePreviewName[index] = event?.dataTransfer?.files[0].name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);
    setNoImgMsg('');
  }

  const handleAddNewProduct = () => {
    setIsEdit(true);
    reset({ formData: [{ title: '', description: '' }] });
  };

  const formsData = [{ title: '', description: '' }];

  const onSubmit = async (values) => {
    setRefreshIcon(true);
    try {
      if (editProductId) {
        const payloads = {
          query: cmsData.updateUserBusinessProduct,
          variables: {
            businessProductId: editProductId,
            description: values?.formData[0]?.description,
            title: values?.formData[0]?.title,
          },
        };
        const dataObjs = {
          payload: payloads,
          fileData: images[0],
        };
        await dispatch(updateUserBusinessProduct(dataObjs));
        setImages([]);
        setImagePreviewName([]);
      } else {
        if (images.length < values.formData.length) {
          enqueueSnackbar(t('appWebsite.blogRequiredMessage'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setRefreshIcon(false);
          return;
        }
        const payloadValue = {
          query: cmsData.addUserBusinessProduct,
          variables: {
            input: values.formData.map((formDataItem) => ({
              ...formDataItem,
            })),
            domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10),
          },
        };

        const dataObj = {
          payload: payloadValue,
          fileData: images,
        };

        await dispatch(addUserBusinessProduct(dataObj));
        setImages([]);
        setImagePreviewName([]);
      }
      setIsEdit(false);
      setRefreshIcon(false);
      reset({ formData: [{ title: '', description: '' }] });
      const obj = {
        query: cmsData.getUserBusinessProduct,
        variables: { domainRegisterId: parseInt(loginUserData?.domainRegisterId, 10) },
      };

      await dispatch(getUserBusinessProduct(obj));
      reset();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div className="p-20 lg:p-40 h-full">
      <>
        <div className="items-center mb-36">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-16 md:text-24 font-bold mb-20"
          >
            {t('appWebsite.product.title')}
          </Typography>
          <>
            {isEdit && (
              <div className="inline-block items-center w-auto float-right">
                <Button
                  className="text-18 px-15 font-semibold"
                  variant="outlined"
                  color="secondary"
                  aria-label="Edit"
                  onClick={(e) => {
                    setIsEdit(false);
                    setEditProductId(null);
                    setEditImagePreview();
                    reset({ formData: [{ title: '', description: '' }] });
                  }}
                >
                  {t('business.selectedBusinessDetail.buttons.cancel')}
                </Button>
              </div>
            )}
          </>
        </div>
        <div>
          {!sliderDetails?.length > 0 || isEdit ? (
            <form
              name="registerForm"
              noValidate
              className="w-full"
              defaultValue={{ forms: formsData }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <div>
                  {fields.map((fieldInput, index) => {
                    return (
                      <div className="bg-white rounded-md relative shadow p-24 mb-40" key={index}>
                        <div className="flex">
                          <div className="w-full">
                            <div className="block">
                              <InputLabel
                                htmlFor="title"
                                className="text-16 font-medium mb-12 text-grey-900"
                              >
                                {t('appWebsite.product.formField.productTitle.name')}*
                              </InputLabel>
                              <Controller
                                // name="bannerTitle"
                                name={`formData[${index}].title`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mb-24"
                                    sx={{
                                      '& .MuiInputBase-input': {
                                        background: 'white',
                                      },
                                    }}
                                    type="text"
                                    size="small"
                                    value={undefined}
                                    defaultValue={fieldInput.title}
                                    error={!!errors?.formData?.[index]?.title}
                                    helperText={errors?.formData?.[index]?.title?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <div className="block">
                              <InputLabel
                                htmlFor="description"
                                className="text-16 font-medium mb-12 text-grey-900"
                              >
                                {t('appWebsite.product.formField.description.name')}*
                              </InputLabel>
                              <Controller
                                // name="description"
                                name={`formData[${index}].description`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mb-24"
                                    sx={{
                                      '& .MuiInputBase-input': {
                                        background: 'white',
                                      },
                                    }}
                                    type="text"
                                    size="small"
                                    value={undefined}
                                    defaultValue={fieldInput.description}
                                    multiline
                                    rows={4}
                                    error={!!errors?.formData?.[index]?.description}
                                    helperText={errors?.formData?.[index]?.description?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <motion.div className="block mb-24">
                              <InputLabel
                                htmlFor={`formData[${index}].uploadImage`}
                                className="text-16 font-medium mb-12 text-grey-900"
                              >
                                {t('appWebsite.product.formField.uploadImage.name')}
                              </InputLabel>
                              <Paper
                                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                                role="button"
                                onDrop={(event) => {
                                  handleDrop(event, index);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <div className="inline-block">
                                  {/* <img
                            className="w-2/4 block rounded max-h-96"
                            name="logo"
                            src={imagePreview}
                            alt="logo"
                          /> */}
                                  <Controller
                                    name={`formData[${index}].uploadImage`}
                                    control={control}
                                    error={!!errors?.formData?.[index]?.uploadImage}
                                    helperText={errors?.formData?.[index]?.uploadImage?.message}
                                    render={({ field }) => (
                                      <label htmlFor={`formData[${index}].uploadImage`}>
                                        <input
                                          {...field}
                                          accept="image/*"
                                          className="hidden"
                                          name={`formData[${index}].uploadImage`}
                                          id={`formData[${index}].uploadImage`}
                                          type="file"
                                          onChange={(event) => {
                                            handleFileChange(event, index);
                                          }}
                                        />
                                        <Button
                                          size="small"
                                          className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                                          variant="contained"
                                          color="secondary"
                                          component="span"
                                        >
                                          <Icon color="action material-symbols-outlined align-middle">
                                            upload
                                          </Icon>{' '}
                                          {t('reviewPlatform.buttons.uploadReviewIcon')}
                                        </Button>
                                      </label>
                                    )}
                                  />
                                </div>
                                <div className="inline-block ml-16 font-medium">
                                  {imagePreviewName[index] ||
                                    `${t('appWebsite.about.formField.OrDropImage')}`}
                                </div>
                                {noImgMsg && (
                                  <span className="text-red block mt-16">{noImgMsg}</span>
                                )}
                              </Paper>
                            </motion.div>
                            {editProductId && editImagePreview && (
                              <img
                                src={editImagePreview}
                                className="rounded-md max-w-400"
                                alt="..."
                              />
                            )}
                          </div>
                          {!editProductId && index !== 0 && (
                            <Button
                              onClick={() => remove(index)}
                              className="ml-24 w-44 px-0 min-w-44 h-44 min-h-44 rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                              type="button"
                              color="secondary"
                              variant="contained"
                            >
                              <Icon>delete</Icon>
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-right">
                    <Button
                      className="rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                      type="button"
                      color="secondary"
                      variant="contained"
                      onClick={() => append({ title: '', description: '' })}
                    >
                      <Icon>add</Icon> {t('appWebsite.product.formField.addNew')}
                    </Button>
                  </div>
                </div>
              </div>
              {fields.length > 0 && (
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                    aria-label="Register"
                    disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                    type="submit"
                    size="small"
                  >
                    {t('appWebsite.product.formField.button')}{' '}
                    {refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              )}
            </form>
          ) : (
            <div className="bg-white rounded-md relative shadow p-24">
              <div className="items-center mb-36">
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="text-16 md:text-24 font-semibold mb-20"
                >
                  {t('appWebsite.product.productBanner')}
                </Typography>
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-16 px-16 rounded-md"
                    variant="contained"
                    color="secondary"
                    aria-label="Edit"
                    onClick={() => handleAddNewProduct()}
                    // onClick={(e) => setIsEdit(true)}
                  >
                    {t('appWebsite.product.formField.addNew')}
                  </Button>
                </div>
              </div>
              <TableContainer className="border-b-1 border-solid border-grey-200">
                <Table aria-labelledby="tableTitle" size="medium">
                  <TableHead>
                    <TableRow className="bg-grey-200 rounded-tr-md">
                      <TableCell className="text-16 font-medium py-10">
                        {t('appWebsite.product.column.image')}
                      </TableCell>
                      <TableCell className="text-16 font-medium py-10">
                        {t('appWebsite.product.column.name')}
                      </TableCell>
                      <TableCell className="text-16 font-medium py-10">
                        {t('appWebsite.product.column.date')}
                      </TableCell>
                      <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                        {t('appWebsite.product.column.action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sliderDetails &&
                      sliderDetails?.length > 0 &&
                      sliderDetails?.map((sliderData, i) => {
                        return (
                          <TableRow hover className="border border-t-2 border-t-grey-100" key={i}>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <img
                                src={sliderData?.image}
                                className="img-fluid max-w-80 rounded-md"
                                alt="trial_accounts"
                              />
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {sliderData?.title}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              {moment(sliderData?.cts).format('MMM DD, YYYY')}
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <Select
                                className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                variant="filled"
                                value={actionValue}
                                fullWidth
                                // onChange={handleActionDropdownChange}
                              >
                                <MenuItem
                                  value="action"
                                  className="capitalize mx-5 rounded-md"
                                  disabled
                                >
                                  {t('menuItems.action')}
                                </MenuItem>
                                <MenuItem
                                  value="delete"
                                  className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                  onClick={() => handleEditProduct(sliderData)}
                                >
                                  {t('menuItems.edit')}
                                </MenuItem>
                                <MenuItem
                                  value="delete"
                                  className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                  onClick={() => handleDeleteProduct(sliderData.id)}
                                >
                                  {t('menuItems.delete')}
                                </MenuItem>
                              </Select>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(Product);

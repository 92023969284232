import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { logoutUser, selectUser } from 'app/store/userSlice';
import userService from '../../services';
import { getParamsId } from '../../main/common/common';

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bc = new BroadcastChannel('logout_channel');
  const [userMenu, setUserMenu] = useState(null);
  const [loginUserData, setLoginUserData] = useState();
  const link = history?.location?.pathname;

  bc.onmessage = (event) => {
    if (event.data === 'logout') {
      sessionStorage.clear();
    }
  };

  useEffect(() => {
    const id = getParamsId();
    if (id) {
      const userData = sessionStorage.getItem(`jwt_access_token_${id}`);
      if (userData) {
        setLoginUserData(jwtDecode(userData));
      }
    } else {
      const loginUser = userService.getUserData();
      setLoginUserData(loginUser);
    }
  }, []);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  function handleLogout() {
    dispatch(logoutUser());
    bc.postMessage('logout');
    sessionStorage.clear();
  }

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex text-16 text-black capitalize">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography className="text-14 font-medium capitalize" color="text.secondary">
            {user?.roles?.id === 1
              ? 'Admin'
              : user?.roles?.id === 3
              ? 'sales Manager'
              : user?.roles?.id === 2
              ? 'Business User'
              : ''}
          </Typography>
        </div>
        <Avatar
          className="md:mx-4 text-16 capitalize"
          alt={user?.firstName}
          src={user?.profileUrl || user?.firstName}
        />
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {loginUserData?.roles?.type !== 'sales_user' && (
          <MenuItem
            component={Link}
            to={
              getParamsId()
                ? window.location.pathname.includes('agency')
                  ? `/agency-profile`
                  : `/user-profile/${getParamsId()}`
                : loginUserData?.roles?.type === 'agent'
                ? '/agency-profile'
                : '/user-profile'
            }
            onClick={userMenuClose}
            role="button"
          >
            <ListItemIcon className="min-w-40">
              <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;

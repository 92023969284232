const getBusinessTypeVideoDetailsForAdmin = `
query ($pageNumber: Int, $pageSize: Int, $searchText: String) {
     getBusinessTypeVideoDetailsForAdmin(pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText) {
      message
      status 
      totalCount
      data{
          bType
          businessCount
          businessTypeId
          videoCount
      }
     }
}
`;

const getVideoByBusinessType = `
query ($businessType: Int!) {
     getVideoByBusinessType(businessType: $businessType) {
      message
      status 
      data{
          videoLink
          videoTitle
          hideVideo
          id
          business_types{
            bType
            id
            isActive
          }
      }
     }
}
`;

const addVideoByBusinessType = `
mutation ($businessTypeId: Int, $file: Upload, $videoTitle: String) {
  addVideoByBusinessType(businessTypeId: $businessTypeId, file: $file, videoTitle: $videoTitle) {
      message
      status
  }
}
`;

const deleteAndUpdateBusinessTypeVideo = `
mutation ($isDelete: Boolean, $isHide: Boolean, $videoId: Int!) {
  deleteAndUpdateBusinessTypeVideo(isDelete: $isDelete, isHide: $isHide, videoId: $videoId) {
      message
      status
     }
}
`;

const updateVideoByBusinessType = `
mutation ($file: Upload, $marketingVideoId: Int, $videoTitle: String) {
  updateVideoByBusinessType(file: $file, marketingVideoId: $marketingVideoId, videoTitle: $videoTitle) {
      message
      status
     }
}
`;

const tabletVideosData = {
  getBusinessTypeVideoDetailsForAdmin,
  getVideoByBusinessType,
  addVideoByBusinessType,
  deleteAndUpdateBusinessTypeVideo,
  updateVideoByBusinessType,
};

export default tabletVideosData;

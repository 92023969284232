import { Typography, MenuItem, Button, Icon, Select } from '@mui/material';
import moment from 'moment';
import history from '@history';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { getParamsId } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const LatestReviews = (props) => {
  const {
    reviewData,
    selectPlateForm,
    handlePlatformChange,
    zomatoRatingType,
    setZomatoRatingType,
  } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  return (
    <div className="bg-white rounded-md relative shadow md:p-20 p-20 w-full mt-28">
      <div className="flex items-center justify-between border-b-2 border-dashed border-grey-400 mb-16 pb-16">
        <h3 className="text-18 font-semibold">{t('businessDashboard.latestReview')}</h3>
        <div>
          <Select
            className="inline-block bg-white border border-1 border-darkgreen text-darkgreen capitalize min-h-32 h-32 pt-0"
            id="country"
            value={parseInt(selectPlateForm, 10)}
            size="small"
            displayEmpty
            sx={{
              '& .MuiSelect-select': {
                padding: '5px 16px',
                display: 'flex',
                alignItems: 'center',
                border: 'none',
              },
            }}
            onChange={(e) => handlePlatformChange(e)}
          >
            {userCurrentBusinessDetails?.reviewplatform?.map((option, i) => (
              <MenuItem key={i} value={option?.reviewPlatformId} className="capitalize">
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            className="rounded-md min-h-32 h-32 ml-20"
            variant="contained"
            color="secondary"
            onClick={() => {
              const id = getParamsId();
              if (id) {
                history.push(`/reviews/${id}`);
              } else {
                history.push('/reviews/');
              }
            }}
          >
            See All
          </Button>
        </div>
      </div>
      <div className="bg-white w-full rounded-md">
        <div className="w-full">
          <div className="overflow-scroll">
            {parseInt(selectPlateForm, 10) === 3 && (
              <div className="border border-solid rounded-lg !border-darkgreen mb-8">
                <div className="flex justify-between">
                  <Button
                    className={`text-center w-1/2 rounded-lg font-medium ${
                      zomatoRatingType === 'dining'
                        ? 'text-darkgreen hover:bg-darkgreen-100 bg-darkgreen-100'
                        : 'text-black hover:bg-white bg-translate'
                    }`}
                    onClick={() => setZomatoRatingType('dining')}
                  >
                    {t('reviews.diningRating')}
                  </Button>
                  <div className="border-l border-solid !border-darkgreen" />
                  <Button
                    className={`text-center w-1/2 rounded-lg font-medium ${
                      zomatoRatingType === 'delivery'
                        ? 'text-darkgreen hover:bg-darkgreen-100 bg-darkgreen-100'
                        : 'text-black hover:bg-white bg-translate'
                    }`}
                    onClick={() => setZomatoRatingType('delivery')}
                  >
                    {t('reviews.deliveryRating')}
                  </Button>
                </div>
              </div>
            )}
            {reviewData?.length > 0 ? (
              <>
                {reviewData?.map((review, index) => {
                  return (
                    <div
                      className="flex rounded-md mb-16 bg-gray-A300 p-12 border border-solid border-gray-A300 "
                      key={index}
                      onKeyDown={(e) => {}}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="w-full">
                        <div className="flex w-full justify-between">
                          <div className="flex items-center gap-12 w-full">
                            <img
                              className="w-48 h-48 rounded-3xl"
                              src={review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'}
                              alt=""
                            />
                            <div className="w-full">
                              <div className="font-semibold flex justify-between">
                                <span>{review?.reviewerName}</span>
                                <span className="font-medium">
                                  {moment(review?.reviewCts).format('MMM DD, YYYY')}
                                </span>
                              </div>
                              {[...Array(5)].map((_, i) => (
                                <Icon
                                  className={`text-16 align-middle mr-2 -mt-2 ${
                                    i < review?.reviewRating ? 'text-yellow-800' : 'text-gray-400'
                                  }`}
                                  key={i}
                                >
                                  star
                                </Icon>
                              ))}{' '}
                              {review?.reviewRating}.0
                            </div>
                          </div>
                        </div>
                        <Typography className="pt-12 text-14">
                          {review?.review && review.review.length > 190
                            ? `${review.review.slice(0, 190)}...`
                            : review.review}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="text-center my-24">
                <img className="m-auto w-144" src="/assets/images/business/reviews.svg" alt="" />
                <Typography className="font-semibold text-20 my-4">
                  {t('reviews.noReviews')}
                </Typography>
                <Typography className="text-16">{t('reviews.message')}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestReviews;

import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo, useCallback } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import history from '@history';
import reducer from './store';

function Payment() {
  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [razorpayIntant, setRazorpayIntant] = useState('');
  const [routeToken] = useState(jwtDecode(routeParams?.token));
  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        console.log('razorpay loaded successfully');
        resolve(true);
      };
      script.onerror = () => {
        console.log('error in loading razorpay');
        resolve(false);
      };
      document.body.appendChild(script);
    });

  const handleRazorpaySubscription = useCallback(async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }
    if (routeToken?.getWaySupport === 'razorpay' && !razorpayIntant) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
        subscription_id: routeToken?.razorpaySubscriptionId,
        name: 'Quick Reviews',
        prefill: {
          name: `${routeToken?.firstName}${routeToken?.lastName}`,
          email: routeToken?.email,
          contact: routeToken?.phoneNumber,
        },
        theme: {
          color: '#006b5a',
        },
        handler: (response) => {
          console.log('succeeded');
          console.log(response);
          if (response) {
            const isSafari =
              navigator.vendor &&
              navigator.vendor.indexOf('Apple') > -1 &&
              navigator.userAgent &&
              navigator.userAgent.indexOf('CriOS') === -1 &&
              navigator.userAgent.indexOf('FxiOS') === -1;
            console.log('isSafari', isSafari);
            console.log('mobile', navigator.userAgent.match(/iPhone/i));
            if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
              console.log('mobile');
              history.push('firstTimeSubscription://quickReviewsPurchasePlanSuccess');
            } else {
              console.log('web');
              window.location.reload();
            }
          }
        },
        timeout: 600,
        modal: {
          confirm_close: true,
          ondismiss: async (reason) => {
            console.log('reason', reason);
            if (reason === undefined) {
              console.log('cancelled');
              window.location.reload();
            } else if (reason === 'timeout') {
              window.location.reload();
            } else {
              console.log('failed');
              window.location.reload();
            }
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      setRazorpayIntant(paymentObject);
      paymentObject.on('payment.submit', (response) => {
        console.log('test', response);
      });

      // To get payment id in case of failed transaction.
      paymentObject.on('payment.failed', (response) => {
        console.log('payment on failed', response);
      });
      paymentObject.on('payment.error', (response) => {
        console.log('payment on error', response);
      });
      paymentObject.on('fault.close', (response) => {
        console.log('payment on close', response);
      });
      paymentObject.on('render', (response) => {
        console.log('payment on render', response);
      });
      paymentObject.open();
    }
  }, [razorpayIntant, routeToken]);

  useEffect(() => {
    setLoading(true);
    handleRazorpaySubscription();
  }, [handleRazorpaySubscription]);

  if (loading) {
    return <FuseLoading />;
  }

  return <></>;
}

export default withReducer('paymentReducer', reducer)(Payment);

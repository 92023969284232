import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const makeAuthorizedRequest = async (endpoint, data, withAuthorization = false) => {
  if (withAuthorization) {
    instance.defaults.headers.common.Authorization = `Bearer ${
      userService.getAccessToken() || null
    }`;
  }

  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getCountries = createAsyncThunk('searchBusiness/getCountries', async (data) =>
  makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data)
);

export const getStates = createAsyncThunk('searchBusiness/getStates', async (data) =>
  makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data)
);

export const getBusinessPlaceType = createAsyncThunk(
  'searchBusiness/getBusinessPlaceType',
  async (data) => makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data, true)
);

export const addBusinessBySalesPerson = createAsyncThunk(
  'searchBusiness/addBusinessBySalesPerson',
  async (data) => makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data, true)
);

export const searchBusinessNearBy = createAsyncThunk(
  'searchBusiness/searchBusinessNearBy',
  async (data) => makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data)
);

export const getBusinessType = createAsyncThunk('searchBusiness/getBusinessType', async (data) =>
  makeAuthorizedRequest(process.env.REACT_APP_API_ENDPOINT, data)
);

const searchBusinessSlice = createSlice({
  name: 'searchBusiness',
  initialState: null,
  reducers: {
    resetSearchBusiness: (state, action) => {
      if (state) {
        state.getCountriesData = {};
        state.getStatesData = {};
        state.getBusinessPlaceTypeData = {};
        state.searchBusinessNearByData = {};
        state.addBusinessBySalesPersonData = {};
      }
    },
  },
  extraReducers: {
    [getCountries.fulfilled]: (state, action) => ({
      ...state,
      getCountriesData: action.payload,
    }),
    [getStates.fulfilled]: (state, action) => ({
      ...state,
      getStatesData: action.payload,
    }),
    [getBusinessPlaceType.fulfilled]: (state, action) => ({
      ...state,
      getBusinessPlaceTypeData: action.payload,
    }),
    [searchBusinessNearBy.fulfilled]: (state, action) => ({
      ...state,
      searchBusinessNearByData: action.payload,
    }),
    [getBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getBusinessTypeData: action.payload,
    }),
    [addBusinessBySalesPerson.fulfilled]: (state, action) => ({
      ...state,
      addBusinessBySalesPersonData: action.payload,
    }),
  },
});

export const { resetSearchBusiness } = searchBusinessSlice.actions;

export default searchBusinessSlice.reducer;

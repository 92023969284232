import Partners from './Partners';
import PartnerDetails from './PartnersDetails';

const partnersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'partners/:id',
      element: <Partners />,
    },
    {
      path: 'partner/:id',
      element: <PartnerDetails />,
    },
  ],
};

export default partnersConfig;

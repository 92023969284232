const forgotPassword = `
     mutation ($data: String!) {
		forgotPassword(data: $data) { 
			message
			status
		}
	}
`;

const passwordData = {
  forgotPassword,
};

export default passwordData;

import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import cmsData from '../../query/cms';
import { resetCmsApp } from './store/cmsAppSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Offers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const loginUserData = UserService.getUserData();

  useEffect(() => {
    return () => {
      dispatch(resetCmsApp());
    };
  }, [dispatch]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-40 h-full">
      <>
        <div className="items-center mb-36">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-16 md:text-24 font-bold mb-20"
          >
            {t('appWebsite.offers.title')}
          </Typography>
        </div>
        <div>
          <h2>Write Offer Code Here</h2>
        </div>
      </>
    </div>
  );
}

export default withReducer('cmsReducer', reducer)(Offers);

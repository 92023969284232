import { Button, MenuItem, Select, CircularProgress, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import history from '@history';
import promotionQuery from 'src/app/main/query/promotion';
import { createPromotion } from '../store/promotionSlice';
import PreviewImages from './PreviewImages';
import { getParamsId } from '../../../common/common';

const PercentageDiscount = (props) => {
  const {
    promotionApplicableData,
    selectBusiness,
    selectPromotionTypeId,
    previewImages,
    buttonTypeData,
    images,
    setImages,
    countryId,
  } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [buttonType, setButtonType] = useState(buttonTypeData?.[0]?.key);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectedButton, setSelectedButton] = useState(buttonTypeData?.[0]?.type);
  const [applicable, setApplicable] = useState(promotionApplicableData?.[0]?.key);
  const [loading, setLoading] = useState(false);
  const [percentageDiscount, setPercentageDiscount] = useState({
    discountValue: '',
    maximumDiscount: '',
    minimumCartValue: '',
  });

  const onSubmit = async () => {
    if (!loading) {
      let imageData = {};
      if (typeof images === 'object') {
        imageData = {
          image: images,
        };
      }
      if (typeof images === 'string') {
        imageData = {
          imageUrl: images,
        };
      }
      if (
        !percentageDiscount?.minimumCartValue ||
        !percentageDiscount?.maximumDiscount ||
        !percentageDiscount?.discountValue
      ) {
        enqueueSnackbar('All fields are required', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else {
        setLoading(true);
        setRefreshIcon(true);

        const payload = {
          query: promotionQuery.createPromotion,
          variables: {
            userBusinessId: parseInt(selectBusiness, 10),
            promotionCategoryId: parseInt(selectPromotionTypeId, 10),
            ...imageData,
            htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
            </head>
            <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
                <div style="margin: 0 auto; padding: 20px;">
                    <div style="background-image: url(###backgroundImage###); background-repeat:no-repeat; background-position:center; background-size: cover;">
                        <div style="padding: 25px;">
                            <div style="border: 1px solid white; padding-left: 20px;">
                                <h1 style="color: white;">${
                                  percentageDiscount?.discountValue
                                } % OFF</h1>
                                <p style="color: white;">On all orders with a minimum <br />order value of ${
                                  parseInt(countryId, 10) === 1 ? '$' : '₹'
                                } ${percentageDiscount?.minimumCartValue}</p>
                                <div style="display: flex; align-items: center; gap: 10px; margin-bottom: 10px;">
                                <button style="padding: 8px 25px 8px 25px; border-radius: 4px; background-color: white; border: 1px solid white;"> ${selectedButton} </button>
                                <p style="color: white;">Up to ${
                                  parseInt(countryId, 10) === 1 ? '$' : '₹'
                                } ${percentageDiscount?.maximumDiscount}</p>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`),
            minimumCartValue: parseInt(percentageDiscount?.minimumCartValue, 10),
            maximumDiscount: parseInt(percentageDiscount?.maximumDiscount, 10),
            discountValue: parseInt(percentageDiscount?.discountValue, 10),
            buttonName: buttonType,
            applicableFor: applicable,
          },
        };
        const result = await dispatch(createPromotion(payload));
        setRefreshIcon(false);
        await setLoading(false);
        if (result?.payload?.createPromotion?.status === 200) {
          const id = getParamsId();
          if (id) {
            history.push(`promotion/${id}`);
          } else {
            history.push('promotion');
          }
          enqueueSnackbar(result?.payload.createPromotion?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result?.payload.createPromotion?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    }
  };

  const handleCancel = () => {
    const id = getParamsId();
    if (id) {
      history.push(`promotion/${id}`);
    } else {
      history.push('promotion');
    }
  };

  const handleButtonType = (value) => {
    const data = buttonTypeData?.find((item) => item.key === value);
    setButtonType(data?.key);
    setSelectedButton(data?.type);
  };

  return (
    <div className="md:grid md:grid-cols-8 gap-16">
      <div className="bg-white col-span-5 rounded-md relative rounded-lg shadow p-20 mt-24">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-24 mb-24 font-medium"
        >
          {t('promotion.promotionInfo')}
        </Typography>
        <div>
          <Typography className="pb-8">
            {t('promotion.discountPercentage')} <span className=" text-2xl text-red-500">*</span>
          </Typography>
          <TextField
            className="w-full"
            size="small"
            type="number"
            onChange={(e) =>
              setPercentageDiscount((prevState) => ({
                ...prevState,
                discountValue: e.target.value,
              }))
            }
          />
        </div>
        <div className="py-8">
          <Typography className="pb-8">
            {t('promotion.maximumDiscount')} <span className=" text-2xl text-red-500">*</span>
          </Typography>
          <TextField
            className="w-full"
            size="small"
            type="number"
            onChange={(e) =>
              setPercentageDiscount((prevState) => ({
                ...prevState,
                maximumDiscount: e.target.value,
              }))
            }
          />
        </div>
        <div className="py-8">
          <Typography className="pb-8">{t('promotion.minimumCartValue')}</Typography>
          <TextField
            className="w-full"
            size="small"
            type="number"
            onChange={(e) =>
              setPercentageDiscount((prevState) => ({
                ...prevState,
                minimumCartValue: e.target.value,
              }))
            }
          />
        </div>
        <div className="py-8">
          <Typography className="pb-8">{t('promotion.applicableFor')}</Typography>
          <Select
            className="w-full"
            id="country"
            value={applicable}
            displayEmpty
            onChange={(e) => {
              setApplicable(e.target.value);
            }}
            size="small"
          >
            {promotionApplicableData?.length > 0 &&
              promotionApplicableData?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.key}>
                    {item?.type}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="py-8">
          <Typography className="pb-8">{t('promotion.buttonName')}</Typography>
          <Select
            className="w-full"
            id="country"
            value={buttonType}
            displayEmpty
            onChange={(e) => {
              handleButtonType(e.target.value);
            }}
            size="small"
          >
            {buttonTypeData?.length > 0 &&
              buttonTypeData?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.key}>
                    {item?.type}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="text-center my-16">
          <Button
            className="bg-white hover:bg-white border border-solid rounded md:px-60 text-darkgreen border-darkgreen"
            onClick={() => handleCancel()}
          >
            {t('promotion.cancel')}
          </Button>
          <Button
            className="text-white bg-darkgreen disabled:text-black disabled:bg-gray md:px-40 rounded hover:bg-darkgreen ml-8"
            type="button"
            disabled={
              !percentageDiscount?.minimumCartValue ||
              !percentageDiscount?.maximumDiscount ||
              !percentageDiscount?.discountValue ||
              refreshIcon
            }
            onClick={() => onSubmit()}
          >
            {t('promotion.createPromotion')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      </div>
      <div className="col-span-3 bg-white rounded-md relative rounded-lg shadow p-20 mt-24">
        <PreviewImages
          previewImages={previewImages}
          setImages={setImages}
          percentageDiscount={percentageDiscount}
          selectPromotionTypeId={selectPromotionTypeId}
          buttonType={selectedButton}
          countryId={countryId}
        />
      </div>
    </div>
  );
};

export default PercentageDiscount;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import { reviewFormate, getDataToServer } from '../../common/common';
import NoData from '../matrices/noDataScreen';

function DashboardReviewDistribution(props) {
  const { placeId } = props;
  const { t } = useTranslation();
  const reviewPlatformId = 7;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 7)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getReviewRatingDistributionV1,
            variables: {
              reviewPlatformId: 3,
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await getDataToServer(payload);
          if (result?.getReviewRatingDistributionV1?.data) {
            setNewGraphData([
              result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJson?.fiveStar,
              result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJson?.fourStar,
              result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJson?.threeStar,
              result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJson?.twoStar,
              result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJson?.oneStar,
            ]);
          } else {
            setNewGraphData('');
          }
        } else if (userCurrentBusinessDetails?.isOnlineBusiness && !placeId) {
          setNewGraphData('');
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function getChartSimple(data, categories, index, color, types) {
    const chart = {
      series: [
        {
          data,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: false,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            columnWidth: '100%',
            barHeight: '70%',
            distributed: true,
            borderRadiusApplication: 'around',
            colors: {
              backgroundBarColors: ['#F8F8FA'],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 5,
            },
            dataLabels: {
              position: 'center',
              maxItems: 100,
              hideOverflowingLabels: false,
              formatter(val) {
                return `${val}%`;
              },
              total: {
                enabled: false,
                offsetX: -40,
                offsetY: 5,
                formatter(val) {
                  return `${val}%`;
                },
                style: {
                  color: '#373d3f',
                  fontSize: '12px',
                  fontWeight: 600,
                },
              },
            },
          },
        },
        legend: {
          markers: {
            radius: 12,
          },
        },
        fill: {
          colors: color,
          opacity: 1,
        },
        colors: color,
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories,
          show: false,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            offsetY: 0,
          },
          max: 100,
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return `${val}%`;
            },
            title: {
              formatter() {
                return '';
              },
            },
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  }
  return (
    <>
      <div>
        {newGraphData && newGraphData?.length > 0 ? (
          <>
            <div className="flex gap-8 items-center">
              <div className="bg-grey-100 rounded-md text-18 font-semibold text-center p-8">
                {props?.averageRating} {t('metrics.outOf')}{' '}
                <span className="inline-block ml-5">{reviewFormate(props?.averageRating)}</span>
              </div>
              <div className="text-16 bg-grey-100 font-semibold text-center py-8 px-20">
                {props?.totalReview}{' '}
                <span className="text-grey-700">{t('metrics.customerReviews')}</span>
              </div>
            </div>

            <>
              {getChartSimple(
                newGraphData,
                ['5 ★', '4 ★', '3 ★', '2 ★', '1 ★'],
                3,
                ['#029C00', '#95B001', '#F59001', '#F45604', '#D12022'],
                'bar'
              )}
            </>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}

export default DashboardReviewDistribution;

import withReducer from 'app/store/withReducer';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';
import _ from '@lodash';
import history from '@history';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import Loading from './Loading';
import './MangeBusiness.css';
import queryData from '../../query/common';
import reducer from './store';
import UserService from '../../../services/userService';
import {
  countryData,
  getParamsId,
  handleApiRequest,
  sortOptions,
  getDecryptData,
} from '../../common/common';
import manageBusinessData from '../../query/manageBusiness';
import { getBusinessTypeData, getCountryData } from '../../../store/userSlice';

const defaultValues = {
  name: '',
  email: '',
  website: '',
  phone_number: '',
};

function AddBusiness(prop) {
  const { setActiveStepNumber } = prop;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        t('manageBusiness.addBusinessSteps.addBusiness.form.businessName.validationMessage')
      ),
    phone_number: yup
      .string()
      .required(t('signUpPage.formField.phoneNumber.validationMessages.required'))
      .min(10, t('signUpPage.formField.phoneNumber.validationMessages.min')),
    email: yup
      .string()
      .email(t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.validationMessage')),
    website: yup
      .string()
      .test(
        'is-url',
        t('manageBusiness.addBusinessSteps.addBusiness.form.website.validationMessage'),
        (value) => {
          if (!value || value === '') {
            return true;
          }
          return /^(https?:\/\/)?(www\.)?[\w-]+(\.[a-z]{2,}){1,}$/i.test(value);
        }
      ),
  });
  const loginUserData = UserService.getUserData();

  const [countryList, setCountryList] = useState([]);
  const [phone, setPhone] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState();
  const [businessType, setBusinessType] = useState([]);
  const [selectBusinessType, setSelectBusinessType] = useState();
  const [phoneCountryId, setPhoneCountryId] = useState();
  const [countryId, setCountryId] = useState(loginUserData?.countryId);
  const [inputValue, setInputValue] = useState('');
  const [countryLocation, setCountryLocation] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountry, setSelectedCountry] = useState();
  const businessTypeList = useSelector(getBusinessTypeData);
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    const ipData =
      localStorage.getItem('ipData') && JSON.parse(getDecryptData(localStorage.getItem('ipData')));
    setCountryLocation(ipData?.isoCode);

    const findCountry = countryList?.find((country) => country?.isoCode === ipData?.isoCode);
    setSelectedCountry(findCountry);
    setCountryId(findCountry?.id);
  }, [countryList]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectBusinessType = (selectedOption) => {
    setSelectBusinessType(selectedOption ? selectedOption?.value : null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  const { control, watch, reset, handleSubmit, formState, getValues, setError, setValue } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    const fetchBusinessType = async () => {
      const payload = {
        query: queryData.getBusinessType,
        variables: {
          isOnlineBusiness: true,
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.getBusinessType?.status === 200) {
        const businessTypeData = res?.getBusinessType?.data?.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }));
        setBusinessType(businessTypeData);
      }
    };
    fetchBusinessType();
  }, []);

  useEffect(() => {
    const data = countryData(loginUserData?.countryId);
    setPhoneCountryId(data?.phoneCountry);
  }, [loginUserData?.countryId]);

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const businessDetail = {
      countryId: parseInt(countryId, 10),
      email: data?.email,
      name: data?.name,
      phoneNumber: {
        phoneNumber: parseInt(data?.phone_number, 10),
        countryCode: `+${phone?.dialCode}`,
        isoCode: phone?.countryCode,
      },
      reviewPlatformId: 7,
      website: data?.website,
      isOnlineBusiness: true,
      reviewPlatform: [],
      businessTypeId: parseInt(selectBusinessType, 10),
    };
    const payload = {
      query: manageBusinessData.addBusinessDetailV2,
      variables: { businessDetail },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.addBusinessDetailV2?.status === 200) {
      localStorage.setItem('activeStepNumber', 2);
      setActiveStepNumber(2);
    } else {
      enqueueSnackbar(result?.addBusinessDetailV2?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  if (pageLoading) {
    return <Loading />;
  }

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({ ...data, phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10) });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectCountry = (event) => {
    setCountryId(event.target.value);
  };

  const handleSkipStep = () => {
    const id = getParamsId();
    if (id) {
      history.push(`/business/dashboard/${id}`);
    } else {
      history.push('/business/dashboard');
    }
    localStorage.removeItem('activeStepNumber');
  };

  return (
    <div className="text-left">
      <h3 className="text-24 font-bold mb-12">
        {t('manageBusiness.addBusinessSteps.addBusiness.pageTitle')}
      </h3>
      <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
        {t('manageBusiness.addBusinessSteps.addBusiness.pageDesc')}
      </Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="md:overflow-hidden">
        <div className="mx-auto mt-24">
          <div className="bg-grey-50 px-20 py-16 rounded-md">
            <div className="flex justify-between border-b-1 border-solid border-grey pb-14 mb-20">
              <h4 className="text-20 font-semibold ">
                {t('manageBusiness.addBusinessSteps.addBusiness.smallTitle')}
              </h4>
            </div>
            <div className="grid gap-16 md:gap-20 grid-cols-1 md:grid-cols-2">
              <div className="block">
                <InputLabel htmlFor="name" className="text-16 font-medium mb-12 text-grey-900">
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.businessName.name')}
                  <span className="text-red">*</span>
                </InputLabel>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.businessName.placeHolder'
                      )}
                      size="small"
                      id="name"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="phone_number"
                  className="text-16 font-medium mb-12 text-grey-900"
                >
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.phone.name')}
                  <span className="text-red">*</span>
                </InputLabel>
                <PhoneInput
                  placeholder={t(
                    'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                  )}
                  inputStyle={{
                    width: '100%',
                    height: '37px',
                  }}
                  className="w-full"
                  name="phone_number"
                  required
                  error={!!errors.phone_number}
                  helperText={errors?.phone_number?.message}
                  value={phoneValue}
                  size="small"
                  country={loginUserData?.phone?.isoCode || selectedCountry?.isoCode.toLowerCase()}
                  enableSearch="true"
                  onChange={handlePhoneChange}
                />
                {phoneValue && (
                  <p
                    className={`text-red ml-12 top-5 relative text-md ${
                      (phoneValue?.length || 0) - (phone?.dialCode?.length || 0) <= 10
                        ? 'block'
                        : 'hidden'
                    }`}
                  >
                    {errors.phone_number?.message
                      ? t('signUpPage.formField.phoneNumber.validationMessages.min')
                      : ''}
                  </p>
                )}
              </div>
              <div className="block">
                <InputLabel htmlFor="name" className="text-16 font-medium mb-12 text-grey-900">
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.businessType.name')}
                  <span className="text-red">*</span>
                </InputLabel>
                <ReactSelect
                  labelId="businessType"
                  placeholder={t('business.selectBusinessType')}
                  id="businessType"
                  name="businessType"
                  onChange={handleSelectBusinessType}
                  onInputChange={handleInputChange}
                  value={businessType?.find((op) => {
                    return parseInt(op.value, 10) === parseInt(selectBusinessType, 10);
                  })}
                  options={filteredOptions}
                  components={{
                    MenuList: CustomMenuList,
                  }}
                />
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="email"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                >
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.name')}
                </InputLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.placeHolder'
                      )}
                      size="small"
                      id="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="block">
                <InputLabel
                  id="country"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                >
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.country.name')}
                </InputLabel>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      id="country"
                      value={countryId}
                      size="small"
                      displayEmpty
                      error={!!errors.country}
                      onChange={(e) => {
                        handleSelectCountry(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('qrcodeOrder.form.country.placeHolder')}
                      </MenuItem>
                      {countryList?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="website"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                >
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.website.name')}
                </InputLabel>
                <Controller
                  control={control}
                  name="website"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.website.placeHolder'
                      )}
                      size="small"
                      id="website"
                      error={!!errors.website}
                      helperText={errors?.website?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mt-48 md:mb-96 mb-36">
          <Button
            variant="outlined"
            color="secondary"
            className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
            aria-label="skip"
            onClick={() => handleSkipStep()}
            type="button"
          >
            {t('manageBusiness.button.skip')}
          </Button>
          <Button
            className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
            variant="contained"
            color="secondary"
            type="submit"
            size="small"
            disabled={
              _.isEmpty(dirtyFields) || !isValid || refreshIcon || !selectBusinessType || !countryId
            }
          >
            {t('manageBusiness.addBusinessSteps.addBusiness.title')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(AddBusiness);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`orderedQrcode/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getBusinessQrOrder = createAsyncThunkWithAuthorization('getBusinessQrOrder', baseURL);

export const getBusinessQrOrderStatusField = createAsyncThunkWithAuthorization(
  'getBusinessQrOrderStatusField',
  baseURL
);

export const updateBusinessQrOrderStatus = createAsyncThunkWithAuthorization(
  'updateBusinessQrOrderStatus',
  baseURL
);

const orderedQrcodeSlice = createSlice({
  name: 'orderedQrcode',
  initialState: {},
  reducers: {
    reSetOrderedQrcode: (state, action) => {
      if (state) {
        state.getBusinessQrOrderData = {};
      }
    },
  },
  extraReducers: {
    [getBusinessQrOrder.fulfilled]: (state, action) => ({
      ...state,
      getBusinessQrOrderData: action.payload,
    }),
  },
});

export const { reSetOrderedQrcode } = orderedQrcodeSlice.actions;

export default orderedQrcodeSlice.reducer;

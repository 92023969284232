const getUserTemplateBasicDetails = `
query($domainRegisterId: Int) {
     getUserTemplateBasicDetails(domainRegisterId: $domainRegisterId) {
          status
          message
          data{
               id
               aboutUs
               businessName
               domainRegisterId
               active
               googleMapLink
               address
               email
               mission
               userId
               lightLogo
               logo
               aboutImage
               phone{
               isoCode
               countryCode
               phoneNumber
               }
               vision
               aboutHeading
               webTemplateId
               business_types{
                    id
                    bType
               }
          }      
     }
}
`;

const updateUserTemplateBasicDetails = `
mutation($aboutHeading: String, $aboutUs: String, $mission: String, $vision: String, $domainRegisterId: Int, $themeId: Int, $businessName: String, $businessTypeId: Int, $email: String, $countryCode: Int, $isoCode: String, $phoneNumber: String, $address: String, $googleMapLink: String, $logo: Upload, $lightLogo: Upload, $aboutImage: Upload, $appTemplateId: Int) {
     updateUserTemplateBasicDetails(aboutHeading: $aboutHeading, aboutUs: $aboutUs, mission: $mission, vision: $vision, domainRegisterId: $domainRegisterId, themeId: $themeId, businessName: $businessName, businessTypeId: $businessTypeId, email: $email, phone: { countryCode: $countryCode, isoCode: $isoCode, phoneNumber: $phoneNumber}, address: $address, googleMapLink: $googleMapLink, logo: $logo, lightLogo: $lightLogo, aboutImage: $aboutImage, appTemplateId: $appTemplateId) {
          status
          message     
     }
}
`;

const getHomeOrSliderData = `
query($domainRegisterId: Int!, $isImage: Boolean, $isSlider: Boolean) {
     getHomeOrSliderData(domainRegisterId: $domainRegisterId, isImage: $isImage, isSlider: $isSlider) {
          status
          message
          data{
               active
               cts
               description
               domainRegisterId
               heading
               id
               isImage
               isSlider
               slider
               userId
          }
     }
}
`;

const addHomeOrSliderData = `
mutation($domainRegisterId: Int!, $input: [HomeOrSliderDataList!]!) {
     addHomeOrSliderData(domainRegisterId: $domainRegisterId, input: $input) {
          status
          message
     }
}
`;

const updateHomeOrSliderData = `
mutation($domainRegisterId: Int, $description: String, $heading: String, $isActive: Boolean, $isImage: Boolean, $isSlider: Boolean, $slider: Upload, $sliderId: Int!) {
     updateHomeOrSliderData(domainRegisterId: $domainRegisterId, description: $description, heading: $heading, isActive: $isActive, isImage: $isImage, isSlider: $isSlider, slider: $slider, sliderId: $sliderId) {
          status
          message
     }
}
`;

const deleteHomeOrSliderData = `
mutation($sliderId: Int!) {
     deleteHomeOrSliderData(sliderId: $sliderId) {
          status
          message
     }
}
`;

const getBusinessBlog = `
query($domainRegisterId: Int){
     getBusinessBlogs(domainRegisterId: $domainRegisterId){
       status
       message
       data{
         blogImage
         blogTitle
         description
         domainRegisterId
         id
         userId
       }
     }
}
`;

const storeBusinessBlogs = `
mutation($input: [AddStoreBusinessBlogsAttributesInput!]!) {
     storeBusinessBlogs(input: $input) {
          status
          message
     }
}
`;

const deleteBlogs = `
mutation ($blogId: Int!){
     deleteBlogs(blogId:$blogId){
     message
     status
     }
}
 `;

const updateStoreBusinessBlog = `
mutation updateStoreBusinessBlog(
     $blogId: Int!,
     $blogTitle: String,
     $description: String,
     $blogImage: Upload
   ) {
     updateStoreBusinessBlog(
       blogId: $blogId,
       blogTitle: $blogTitle,
       description: $description,
       blogImage: $blogImage,
     ) {
       status
       message
     }
}
`;

const getUserTemplateDomainServiceDetail = `
query($domainRegisterId: Int) {
     getUserTemplateDomainServiceDetail(domainRegisterId: $domainRegisterId) {
          status
          message
          data{
               active
               cts
               details
               domainRegisterId
               heading
               icon
               id
               userId
          }
     }
}
`;

const addUserTemplateDomainServiceDetail = `
mutation( $input: [AddUserTemplateAttributesInput!]! ){
     addUserTemplateDomainServiceDetail(input: $input) {
          status
          message
     }
}
`;

const updateUserTemplateDomainServiceDetail = `
mutation( $details: String, $heading: String, $icon: Upload, $isActive: Boolean, $userTemplateDomainServiceId: Int! ){
     updateUserTemplateDomainServiceDetail(details: $details, heading: $heading, icon: $icon, isActive: $isActive, userTemplateDomainServiceId: $userTemplateDomainServiceId) {
          status
          message
     }
}
`;

const getUserBusinessSocialMediaLink = `
query($domainRegisterId: Int! ){
     getUserBusinessSocialMediaLink(domainRegisterId: $domainRegisterId) {
          status
          message
          data{
               addbyUser
               icon
               id
               link
               name
          }
     }
}
`;

const addUserBusinessSocialMediaLink = `
mutation($domainRegisterId: Int!, $domainSocialMediaId : Int!, $link: String!){
     addUserBusinessSocialMediaLink(domainRegisterId: $domainRegisterId, domainSocialMediaId: $domainSocialMediaId, link: $link) {
          status
          message
     }
}
`;

const updateUserBusinessSocialMediaLink = `
mutation($userBusinessDomainSocialMediaId : Int!, $link: String!){
     updateUserBusinessSocialMediaLink(userBusinessDomainSocialMediaId: $userBusinessDomainSocialMediaId, link: $link) {
          status
          message
     }
}
`;

const getDomainFeatures = `
query($domainRegisterId : Int!){
     getDomainFeatures(domainRegisterId: $domainRegisterId) {
          status
          message
          data{
               active
               description
               domainRegisterId
               icon
               id
               title
               userId
          }
          domainDetails{
               mainDescription
               mainTitle
          }
     }
}
`;

const addDomainFeatures = `
mutation( $domainRegisterId : Int! , $input: [FeaturesInput!]!, $mainDescription: String, $mainTitle: String){
     addDomainFeatures(domainRegisterId: $domainRegisterId, input: $input, mainFeatures: {mainDescription: $mainDescription, mainTitle: $mainTitle}) {
          status
          message
     }
}
`;

const deleteDomainFeatures = `
mutation ($domainFeaturesId: Int!){
     deleteDomainFeatures(domainFeaturesId:$domainFeaturesId){
     message
     status
     }
}
 `;

const updateDomainFeatures = `
mutation ($domainRegisterId: Int, $description: String, $featureId: Int, $icon: Upload,  $mainDescription: String, $mainTitle: String, $title: String){
     updateDomainFeatures(domainRegisterId:$domainRegisterId, description: $description, featureId: $featureId, icon: $icon, mainFeatures: {mainDescription: $mainDescription, mainTitle: $mainTitle}, title: $title){
     message
     status
     }
}
 `;

const addOnboardingData = `
mutation addOnboardingData(
     $domainRegisterId: Int!
     $input: [AddOnBoardingAttributesInput!]!
   ) {
     addOnboardingData(domainRegisterId: $domainRegisterId, input: $input) {
       message
       status
     }
}   
`;
const UpdateOnboardingData = `
mutation UpdateOnboardingData(
     $onBoardingId: Int!
     $heading: String
     $description: String
     $image: Upload
   ) {
     updateOnboardingData(
       onBoardingId: $onBoardingId
       heading: $heading
       description: $description
       image: $image
     ) {
       status
       message
     }
}   
`;
const getOnboardingData = `
query ($domainRegisterId: Int!){
     getOnboardingData(domainRegisterId: $domainRegisterId){
       status
       message
       totalCount
       data{
         id
         heading
         description
         image
         cts
       }
     }
}   
`;

const deleteOnboardingData = `
mutation ($onBoardingId: Int!){
     deleteOnboardingData(onBoardingId: $onBoardingId){
       status
       message
     }
}   
`;

const addBusinessAchivement = `
mutation ($input: [BusinessAchievementsInput!]) {
     addBusinessAchivement(input: $input) {
       status
       message
     }
}
`;
const updateBusinessAchievement = `
mutation updateBusinessAchievement(
     $businessAchievementId: Int!,
     $counterTitle: String,
     $counterValue: Int,
     $icon: String
     ){
     updateBusinessAchievement(
       businessAchievementId: $businessAchievementId,
       counterTitle: $counterTitle,
       counterValue: $counterValue,
       icon: $icon
     ){
       status
       message
     }
}
`;

const deleteBusinessAchievement = `
mutation ($businessAchievementId: Int!) {
     deleteBusinessAchievement(businessAchievementId: $businessAchievementId){
       status
       message
     }
} 
`;
const getBusinessAchivement = `
query($domainRegisterId: Int!) {
     getBusinessAchivement(domainRegisterId: $domainRegisterId){
       status
       message
       data{
         id
         counterTitle
         counterValue
         domainRegisterId
         icon
       }
       totalCount
     }
}   
`;

const getAppBusinessCategories = `
query($domainRegisterId: Int!) {
     getAppBusinessCategories(domainRegisterId: $domainRegisterId){
       status
       message
       data{
          domainRegisterId
          id
          image
          isActive
          name
          userBusinessCategoriesCount
          userId
       }
     }
}   
`;

const addAppBusinessCategories = `
mutation($domainRegisterId: Int!, $input: [BusinessCategoriesInput!]!) {
     addAppBusinessCategories(domainRegisterId: $domainRegisterId, input: $input){
       status
       message
     }
}   
`;

const updateAppBusinessCategories = `
mutation($businessCategoriesId: Int!, $image: Upload, $isActive: Boolean, $name: String) {
     updateAppBusinessCategories(businessCategoriesId: $businessCategoriesId, image: $image, isActive: $isActive, name: $name){
       status
       message
     }
}   
`;

const getBusinessFeedback = `
query($domainRegisterId: Int!, $pageNumber: Int, $pageSize: Int, $searchText: String) {
     getBusinessFeedback(domainRegisterId: $domainRegisterId, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText){
       status
       message
       totalCount
       data{
          client_app_device_register_and_login{
               fullName
               id
               mobileNumber
          }
          messages
          cts
       }
     }
}   
`;

const getWebFeedback = `
query($domainRegisterId: Int!, $pageNumber: Int, $pageSize: Int, $searchText: String) {
     getWebFeedback(domainRegisterId: $domainRegisterId, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText){
       status
       message
       totalCount
       data{
          clientUserId
          id
          messages
          mobileNumber{
               countryCode
               isoCode
               phoneNumber
          }
          name
       }
     }
}   
`;

const updatePopulerAndRecommendedAsIsMenuData = `
mutation($input: [PopularAndRecommendedMenuDto!]!) {
     updatePopulerAndRecommendedAsIsMenuData(input: $input){
       status
       message
     }
}   
`;

const getBusinessMenuAndServices = `
query ($domainRegisterId: Int!, $searchText: String, $isPopuler: Boolean, $isMain: Boolean, $isRecommended: Boolean, $isActive: Boolean) {
     getBusinessMenuAndServices(
       domainRegisterId: $domainRegisterId
       searchText: $searchText
       isPopuler: $isPopuler
       isMain: $isMain
       isRecommended: $isRecommended
       isActive: $isActive
     ) {
       data {
         currencyType
         description
         domainRegisterId
         id
         image
         isActive
         isMain
         userBusinessCategoriesId
         user_business_categories{
          id 
          name
         }
         isPopuler
         isRecommended
         keyFeatures
         name
         percentage
         price
         specialPrice
         userId
         salesPrice
       }
       message
       message
     }
   }   
`;
const addAppBusinessMenuAndServices = `
mutation addAppBusinessMenuAndServices(
     $currencyType: String!
     $domainRegisterId: Int!
     $name: String!
     $percentage: Int!
     $price: Int!
     $userBusinessCategoriesId: Int!
     $keyFeatures: String
     $description: String
     $image: Upload!
     $specialPrice: Int!
   ) {
     addAppBusinessMenuAndServices(
       currencyType: $currencyType
       domainRegisterId: $domainRegisterId
       name: $name
       percentage: $percentage
       price: $price
       userBusinessCategoriesId: $userBusinessCategoriesId
       keyFeatures: $keyFeatures
       description: $description
       image: $image
       specialPrice: $specialPrice
     ) {
       message
       status
     }
}   
`;

const updateBusinessMenuAndServices = `
mutation updateBusinessMenuAndServices($businessMenuAndServicesId: Int!, $isActive: Boolean, $currencyType: String,  $name: String, $percentage: Int, $price: Int, $userBusinessCategoriesId: Int, $keyFeatures: String, $description: String, $image: Upload, $specialPrice: Int) {
     updateBusinessMenuAndServices(
       businessMenuAndServicesId: $businessMenuAndServicesId
       currencyType: $currencyType
       name: $name
       percentage: $percentage
       price: $price
       userBusinessCategoriesId: $userBusinessCategoriesId
       keyFeatures: $keyFeatures
       description: $description
       image: $image
       specialPrice: $specialPrice
       isActive: $isActive
     ) {
       message
       status
     }
   }
   `;

const getUserBusinessProduct = `
   query getUserBusinessProduct($domainRegisterId: Int!) {
     getUserBusinessProduct(domainRegisterId: $domainRegisterId) {
          message
          status
          data{
               domainRegisterId
               description
               image
               userId
               title
               id
             }         
        }
     }
      `;

const addUserBusinessProduct = `
     mutation addUserBusinessProduct($input: [BusinessProductInput!]!, $domainRegisterId: Int!) {
          addUserBusinessProduct(input: $input, domainRegisterId: $domainRegisterId) {
               message
               status       
          }
     }
`;

const deleteUserBusinessProduct = `
     mutation deleteUserBusinessProduct($businessProductId: Int, $domainRegisterId: Int) {
          deleteUserBusinessProduct(businessProductId: $businessProductId, domainRegisterId: $domainRegisterId) {
               message
               status       
          }
     }
`;

const updateUserBusinessProduct = `
mutation updateUserBusinessProduct($businessProductId: Int!, $description: String, $image: Upload, $title: String, $isActive: Boolean) {
     updateUserBusinessProduct(businessProductId: $businessProductId, description: $description, image: $image, title: $title, isActive: $isActive) {
          message
          status       
     }
}
`;

const cmsData = {
  getUserTemplateBasicDetails,
  updateUserTemplateBasicDetails,
  getHomeOrSliderData,
  addHomeOrSliderData,
  updateHomeOrSliderData,
  deleteHomeOrSliderData,
  getBusinessBlog,
  storeBusinessBlogs,
  deleteBlogs,
  updateStoreBusinessBlog,
  getUserTemplateDomainServiceDetail,
  addUserTemplateDomainServiceDetail,
  updateUserTemplateDomainServiceDetail,
  getUserBusinessSocialMediaLink,
  addUserBusinessSocialMediaLink,
  updateUserBusinessSocialMediaLink,
  getDomainFeatures,
  addDomainFeatures,
  deleteDomainFeatures,
  updateDomainFeatures,
  addOnboardingData,
  UpdateOnboardingData,
  getOnboardingData,
  deleteOnboardingData,
  addBusinessAchivement,
  updateBusinessAchievement,
  deleteBusinessAchievement,
  getBusinessAchivement,
  getAppBusinessCategories,
  addAppBusinessCategories,
  updateAppBusinessCategories,
  getBusinessFeedback,
  getWebFeedback,
  updatePopulerAndRecommendedAsIsMenuData,
  getBusinessMenuAndServices,
  addAppBusinessMenuAndServices,
  updateBusinessMenuAndServices,
  getUserBusinessProduct,
  addUserBusinessProduct,
  deleteUserBusinessProduct,
  updateUserBusinessProduct,
};

export default cmsData;

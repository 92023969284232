import {
  Button,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import history from '@history';
import { t } from 'i18next';
import moment from 'moment';
import { motion } from 'framer-motion';
import TableHeader from 'src/app/component/table/TableHeader';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import promotionQuery from '../../query/promotion';
import { getRedeemPromotionsList, updateRedeemPromotion } from './store/promotionSlice';
import Pagination from '../../../component/table/Pagination';
import { getParamsId } from '../../common/common';

const Redeem = () => {
  const headerData = [
    t('promotion.column.srNo'),
    t('promotion.column.businessName'),
    t('promotion.column.createdDate'),
    t('promotion.column.productName'),
    t('promotion.column.points'),
    t('promotion.column.status'),
    t('promotion.column.action'),
  ];
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [redeemData, setRedeemData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  useEffect(() => {
    setLoading(true);
    const fetchRedeemData = async () => {
      const payload = {
        query: promotionQuery.getRedeemPromotionsList,
      };
      const result = await dispatch(getRedeemPromotionsList(payload));
      setLoading(false);
      if (result?.payload?.getRedeemPromotionsList?.status === 200) {
        setRedeemData(result?.payload?.getRedeemPromotionsList?.data);
        setTotalCount(result?.payload?.getRedeemPromotionsList?.totalCount);
      }
    };
    fetchRedeemData();
  }, [dispatch]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    setLoading(true);
    const payload = {
      query: promotionQuery.getRedeemPromotionsList,
      variables: {
        pageNumber: value + 1,
        pageSize: rowsPerPage,
      },
    };
    const result = await dispatch(getRedeemPromotionsList(payload));
    setLoading(false);
    if (result?.payload?.getRedeemPromotionsList?.status === 200) {
      setRedeemData(result?.payload?.getRedeemPromotionsList?.data);
      setTotalCount(result?.payload?.getRedeemPromotionsList?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoading(true);
    const targetStatus = event.target.value;
    setRowsPerPage(targetStatus);
    setPage(1);
    const payload = {
      query: promotionQuery.getRedeemPromotionsList,
      variables: {
        pageNumber: 1,
        pageSize: targetStatus,
      },
    };
    const result = await dispatch(getRedeemPromotionsList(payload));
    setLoading(false);
    if (result?.payload?.getRedeemPromotionsList?.status === 200) {
      setRedeemData(result?.payload?.getRedeemPromotionsList?.data);
      setTotalCount(result?.payload?.getRedeemPromotionsList?.totalCount);
    }
  };

  const handleUpdateStatus = async (data) => {
    const payload = {
      query: promotionQuery.updateRedeemPromotion,
      variables: {
        redeemPromotionId: parseInt(data.id, 10),
        status: !data.isActive,
      },
    };
    const result = await dispatch(updateRedeemPromotion(payload));
    if (result?.payload?.updateRedeemPromotion?.status === 200) {
      const obj = {
        query: promotionQuery.getRedeemPromotionsList,
      };
      const res = await dispatch(getRedeemPromotionsList(obj));
      setLoading(false);
      if (res?.payload?.getRedeemPromotionsList?.status === 200) {
        setRedeemData(res?.payload?.getRedeemPromotionsList?.data);
        setTotalCount(res?.payload?.getRedeemPromotionsList?.totalCount);
      }
    }
  };
  return (
    <div className="p-20 lg:p-40">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('promotion.redeemPromotions')}
          </Typography>
        </div>
        <div className="flex gap-24">
          <Button
            className="bg-darkgreen rounded px-20 text-white hover:bg-darkgreen"
            onClick={() => {
              const id = getParamsId();
              if (id) {
                history.push(`new-redeem/${id}`);
              } else {
                history.push('new-redeem');
              }
            }}
          >
            {t('promotion.createNew')}
          </Button>
        </div>
      </div>
      {redeemData && redeemData?.length > 0 && (
        <>
          <div className="bg-white rounded-md relative shadow">
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHeader headerData={headerData} />
                <TableBody>
                  {redeemData?.length > 0 &&
                    redeemData?.map((item, i) => {
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          key={i}
                          tabIndex={-1}
                        >
                          <TableCell
                            className="text-16 color-black font-semibold w-[10%]"
                            align="left"
                          >
                            {i + 1 + (page - 1) * rowsPerPage}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {item.user_businesses?.name}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold capitalize"
                            align="left"
                          >
                            {moment(item?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {item.productName}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {item.points}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Switch
                              checked={item.isActive}
                              onClick={() => handleUpdateStatus(item)}
                            />
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value="action"
                              fullWidth
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('business.businessList.column.action')}{' '}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                              >
                                {t('business.businessList.column.viewDetails')}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Pagination
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      {redeemData && redeemData?.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
        >
          <div>
            <img
              src="/assets/images/business/promotion.png"
              className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
              alt="..."
            />
            <h5 className="block text-24 font-semibold text-black">
              {t('promotion.noRedeemMessage')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-16 pt-12 font-medium max-w-640"
            >
              {t('promotion.noRedeemDesc')}
            </Typography>
            <Button
              className="md:text-16 font-medium rounded-md min-w-288 mt-32"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={() => {
                const id = getParamsId();
                if (id) {
                  history.push(`new-redeem/${id}`);
                } else {
                  history.push('new-redeem');
                }
              }}
            >
              {t('promotion.createPromotion')}
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Redeem;

import './signUp.css';
import { yupResolver } from '@hookform/resolvers/yup';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import ReactFlagsSelect from 'react-flags-select';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { Link, json, useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { Country } from 'country-state-city';
import history from '@history';
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import jwtDecode from 'jwt-decode';
import { MuiOtpInput } from 'mui-one-time-password-input';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { getCountryData, setUserData } from 'app/store/userSlice';
import userService from '../../services/userService';
import SignInBoxContent from './SignInBoxContent';
import queryData from '../query/common';
import planQuery from '../query/plan';
import {
  getCountries,
  signUpPerson,
  getPackages,
  resetsignUp,
  getLocation,
} from './store/signUpSlice';
import reducer from './store';
import 'react-phone-input-2/lib/style.css';
import { countryData, getDecryptData, getEncryptedData, handleApiRequest } from '../common/common';
import { asPreviouslyRegesterInGoogle, checkEmail, googleAuth } from '../auth/store/loginAuthSlice';
import Query from '../query/auth';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 8,
    position: 'relative',
  },
  buttonProgress: {
    color: 'darkgreen',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -5,
    marginLeft: -5,
  },
}));

const defaultValues = {
  email: '',
  firstname: '',
  middlename: '',
  lastname: '',
  password: '',
  phoneNumber: '',
  passwordConfirm: '',
};

const defaultValuesEmail = {
  email: '',
};
function SignUpPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const schemaEmail = yup.object().shape({
    email: yup
      .string()
      .required(t('signUpPage.formField.email.validationMessages.required'))
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .trim(),
  });
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('signInPage.formField.email.validationMessages.required'))
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .trim(),
    firstname: yup
      .string()
      .required('Firstname is required')
      .max(15, 'First Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in Firstname.'
      ),
    lastname: yup
      .string()
      .required('Lastname is required')
      .max(15, 'Last Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in Lastname.'
      ),
    // password: yup
    //   .string()
    //   .required('Password is required')
    //   .min(8, t('users.resetPassword.formFields.validationMessages.passwordTooShort'))
    //   .matches(
    //     /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //     t('users.resetPassword.formFields.validationMessages.passwordRequirements')
    //   ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Confirm Password must match with Password'),
  });
  const {
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    control: controlEmail,
    formState: formStateEmail,
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    setError: setErrorEmail,
    setValue: setValueEmail,
    clearErrors: clearErrorsEmail,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValuesEmail,
    resolver: yupResolver(schemaEmail),
  });

  const { isValid, dirtyFields, errors } = formState;
  const {
    isValid: isValidEmail,
    dirtyFields: dirtyFieldsEmail,
    errors: errorsEmail,
  } = formStateEmail;
  const params = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [registerProcess, setRegisterProcess] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [userValue, setUserValue] = useState();
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [isRegisterByPhone, setIsRegisterByPhone] = useState(false);
  const [useInformation, setUserInformation] = useState(false);
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [phoneNumberVerify, setPhoneNumberVerify] = useState(false);
  const [checkPhoneNumberVerify, setCheckPhoneNumberVerify] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState('');
  const [countryIsoData, setCountryIsoData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWhatsapp, setIsWhatsApp] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedPackageTypeId, setSelectedPackageTypeId] = useState(
    params?.id || process.env.REACT_APP_DEFAULT_SIGNUP_PACKAGEID
  );
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const countriesData = useSelector(getCountryData);

  const message = encodeURIComponent('Send this message to sign-in on quick review dashboard');
  const whatsAppURL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  let prevItem = null;
  useEffect(() => {
    if (countryList) {
      const isoCode = countryList.map((country) => country.isoCode);
      setCountryIsoData(isoCode);
    }
  }, [countryList]);

  const customLabels = useMemo(() => {
    if (!countryList) {
      return {};
    }

    return countryList.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
      };
      return acc;
    }, {});
  }, [countryList]);

  const getPackageResponse = useSelector(
    ({ signUpReducer }) => signUpReducer?.signUp?.getPackagesData?.data?.getPackages?.data || []
  );

  const getAppleData = useSelector(
    (signUpReducer) => signUpReducer?.signInReducer?.login?.appleTokenData
  );
  const [isAppleSignUp, setIsAppleSignUp] = useState(false);
  const [appleAccessToken, setAppleAccessToken] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetsignUp());
    };
  }, [dispatch]);
  useEffect(() => {
    const payload = {
      query: queryData.getCountries,
    };
    dispatch(getCountries(payload));
  }, [dispatch]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            localStorage.setItem('ipData', locationData);
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: planQuery.getPackages,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: 'month',
        },
      };
      await dispatch(getPackages(payload));
    };

    fetchData();
  }, [dispatch, ipBasedData?.countryId]);

  useEffect(() => {
    const foundSelectedPackageObject = getPackageResponse?.find(
      (item) => item?.packageTypeId === parseInt(selectedPackageTypeId, 10)
    );

    setSelectedPackageId(foundSelectedPackageObject?.id);
  }, [getPackageResponse, selectedPackageTypeId]);

  useEffect(() => {
    if (ipBasedData && ipBasedData.countryCode) {
      const matchingLabel = Object.entries(customLabels)?.find(
        ([key, value]) => value.secondary === `+${ipBasedData.countryCode}`
      );
      if (matchingLabel) {
        setSelected(matchingLabel[1].isoCode);
      }
    }
  }, [ipBasedData, customLabels]);

  useEffect(() => {
    if (userValue) {
      clearErrors('email');
    } else {
      setNextStep(false);
    }
  }, [userValue, clearErrors]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if (getAppleData?.decodeData && getAppleData?.token) {
      setIsEmail(false);
      if (getAppleData?.decodeData?.email) {
        setValue('email', getAppleData?.decodeData?.email);
        setIsEmail(true);
      }
      setUserInformation(true);
      setRegisterProcess(true);
      setIsAppleSignUp(true);
      setAppleAccessToken(getAppleData?.token);
    }
  }, [
    setValue,
    setIsEmail,
    setRegisterProcess,
    setUserInformation,
    setIsAppleSignUp,
    setAppleAccessToken,
    getAppleData,
  ]);

  async function signUpWithGoogle() {
    const contactNumber = getValues('phoneNumber');
    const findCountryCode = Object.entries(customLabels)?.find(
      ([key, value]) => value.isoCode === selected
    );
    const data = getEncryptedData(
      JSON.stringify({
        accessToken: googleAccessToken,
        email: getValues('email').toLocaleLowerCase(),
        firstName: getValues('firstname'),
        lastName: getValues('lastname'),
        countryId: ipBasedData?.countryId,
        userPackageId: parseInt(selectedPackageId, 10) || 1,
        phoneNumber: {
          countryCode: findCountryCode[1].secondary,
          phoneNumber: parseInt(contactNumber, 10),
          isoCode: findCountryCode[1].isoCode,
        },
        isSocialUser: true,
        timeZone: '',
      })
    );
    const payload = {
      query: queryData.googleAuthV3,
      variables: { data },
    };
    const result = await dispatch(signUpPerson(payload));
    if (result?.payload?.googleAuthV3?.status === 200) {
      enqueueSnackbar(result?.payload?.googleAuthV3?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      if (result?.payload?.googleAuthV3?.refreshToken) {
        dispatch(setUserData(result?.payload?.googleAuthV3?.user));
        userService.setSession(result?.payload?.googleAuthV3?.accessToken);
        loginRedirect(result?.payload?.googleAuthV3);
      }
      closeSnackbar(3000);
    } else {
      enqueueSnackbar(result?.payload?.googleAuthV3?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }
  async function signUpWithApple() {
    const contactNumber = getValues('phoneNumber');
    const data = getEncryptedData(
      JSON.stringify({
        accessToken: appleAccessToken,
        firstName: getValues('firstname'),
        lastName: getValues('lastname'),
        countryId: ipBasedData?.countryId,
        userPackageId: parseInt(selectedPackageId, 10) || 1,
        phoneNumber: {
          countryCode: `+${ipBasedData?.countryCode}`,
          phoneNumber: parseInt(contactNumber, 10),
          isoCode: ipBasedData?.isoCode,
        },
        isSocialUser: true,
        timeZone: '',
      })
    );
    const payload = {
      query: queryData.appleAuthV2,
      variables: { data },
    };
    const result = await dispatch(signUpPerson(payload));
    if (result?.payload?.appleAuthV2?.status === 200) {
      enqueueSnackbar(result?.payload?.appleAuthV2?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      if (result?.payload?.appleAuthV2?.refreshToken) {
        dispatch(setUserData(result?.payload?.appleAuthV2?.user));
        userService.setSession(result?.payload?.appleAuthV2?.accessToken);
        loginRedirect(result?.payload?.appleAuthV2);
      }
      closeSnackbar(3000);
    } else {
      enqueueSnackbar(result?.payload?.appleAuthV2?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }

  const handleSubmitData = async () => {
    if (!getValues('firstname') || !getValues('lastname')) {
      enqueueSnackbar(t('signInPage.firstNameAndLastName'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      const findCountryCode = Object.entries(customLabels)?.find(
        ([key, value]) => value.isoCode === selected
      );
      const findCountryId = countryList?.find(
        (country) => `${country.countryCode}` === findCountryCode[1].secondary.slice(1)
      );
      const data = getEncryptedData(
        JSON.stringify({
          firstName: getValues('firstname'),
          middleName: getValues('middlename'),
          lastName: getValues('lastname'),
          phoneNumber: {
            countryCode: findCountryCode[1].secondary,
            phoneNumber: parseInt(getValues('phoneNumber'), 10),
            isoCode: findCountryCode[1].isoCode,
          },
          accessToken: '',
          countryId: parseInt(findCountryId?.id, 10),
          userPackageId: parseInt(selectedPackageId, 10) || 1,
          isSocialUser: false,
          timeZone: '',
        })
      );
      const payload = {
        query: queryData.signupV3,
        variables: { data },
      };
      const result = await dispatch(signUpPerson(payload));
      if (result?.payload?.signupV3?.status === 200) {
        enqueueSnackbar(result?.payload?.signupV3?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        if (result?.payload?.signupV3?.refreshToken) {
          dispatch(setUserData(result?.payload?.signupV3?.user));
          userService.setSession(result?.payload?.signupV3?.accessToken);
          loginRedirect(result?.payload?.signupV3);
        }
      } else {
        enqueueSnackbar(result?.payload?.signupV3?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleWhatsappClick = () => {
    const URL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;
    window.open(URL, '_blank');
  };

  const loginRedirect = useCallback(
    (loginData) => {
      if (Object.keys(loginData).length > 0) {
        if (
          loginData?.status === 'fail' ||
          loginData?.status === 'error' ||
          loginData?.status !== 200
        ) {
          enqueueSnackbar(loginData.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        } else if (loginData?.status === 200 && loginData?.user) {
          const userData = loginData.user;
          if (userData?.roles?.type === 'sales_user') {
            history.push('/dashboard');
          } else if (userData?.roles?.type === 'business_user') {
            history.push('/select-business');
          } else if (userData?.roles?.type === 'admin') {
            history.push('/admin/dashboard');
          }
        }
      }
    },
    [enqueueSnackbar]
  );

  async function onSubmit({ email, firstname, middlename, lastname, password, phoneNumber }) {
    const res = await submitOtp();
    const findCountryCode = Object.entries(customLabels)?.find(
      ([key, value]) => value.isoCode === selected
    );
    const findCountryId = countryList?.find(
      (country) => `${country.countryCode}` === findCountryCode[1].secondary.slice(1)
    );
    if (res.status === 200 && !isGoogleSignUp && !isAppleSignUp) {
      const object = isRegisterByPhone
        ? {
            isEmailRegistration: false,
          }
        : {
            p: password,
            isEmailRegistration: true,
          };
      const data = getEncryptedData(
        JSON.stringify({
          e: email.toLocaleLowerCase(),
          ...object,
          firstName: firstname,
          middleName: middlename,
          lastName: lastname,
          phoneNumber: {
            countryCode: findCountryCode[1].secondary,
            phoneNumber: parseInt(phoneNumber, 10),
            isoCode: findCountryCode[1].isoCode,
          },
          accessToken: '',
          userPackageId: parseInt(selectedPackageId, 10) || 1,
          countryId: parseInt(findCountryId?.id, 10),
          isSocialUser: false,
          timeZone: '',
        })
      );
      const payload = {
        query: queryData.signupV3,
        variables: { data },
      };
      const result = await dispatch(signUpPerson(payload));
      if (
        result?.payload?.signupV3?.status === 200 &&
        result?.payload?.signupV3?.message !== 'Phone Number already exists'
      ) {
        enqueueSnackbar(result?.payload?.signupV3?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        if (result?.payload?.signupV3?.message !== 'Phone Number already exists') {
          reset(defaultValues);
          if (result?.payload?.signupV3?.refreshToken) {
            dispatch(setUserData(result?.payload?.signupV3?.user));
            userService.setSession(result?.payload?.signupV3?.accessToken);
            loginRedirect(result?.payload?.signupV3);
          }
        }
        closeSnackbar(3000);
      } else {
        enqueueSnackbar(result?.payload?.signupV3?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
    }
  }

  async function submitOtp() {
    setSeconds(59);
    setMinutes(0);
    const findCountryCode = Object.entries(customLabels)?.find(
      ([key, value]) => value.isoCode === selected
    );
    const otpValue = parseInt(otp, 10);
    if (getValues('email') && !isGoogleSignUp && !phoneNumberVerify && !isAppleSignUp) {
      const data = getEncryptedData(
        `{"email": "${getValues('email').toLocaleLowerCase()}", "otp": ${otpValue}}`
      );
      const payload = {
        query: queryData.verifyOTP,
        variables: { data },
      };
      const result = await dispatch(signUpPerson(payload));
      if (result.payload?.verifyOTP?.status === 200) {
        enqueueSnackbar(result.payload?.verifyOTP?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOtp('');
        setRegisterProcess(true);
      } else {
        enqueueSnackbar(result.payload?.verifyOTP?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return result?.payload?.verifyOTP;
    }
    if (getValues('phoneNumber')) {
      const phonePayload = {
        query: queryData.verifyPhoneNumberOTP,
        variables: {
          data: getEncryptedData(
            JSON.stringify({
              countryCode: findCountryCode[1].secondary,
              phoneNumber: getValues('phoneNumber'),
              otp: otpValue,
            })
          ),
        },
      };

      const phoneResult = await dispatch(signUpPerson(phonePayload));
      if (phoneResult.payload?.verifyPhoneNumberOTP?.status === 200) {
        setOtp('');
        setCheckPhoneNumberVerify(true);
        setRegisterProcess(true);
        setUserInformation(true);

        if (isGoogleSignUp) {
          signUpWithGoogle();
        } else if (isAppleSignUp) {
          signUpWithApple();
        }
      }

      return phoneResult?.payload?.verifyPhoneNumberOTP;
    }

    return null;
  }
  const checkPhoneNumber = async () => {
    const email = /\S+@\S+\.\S+/.test(getValues('email'));
    const phoneNumberPattern = /^\d+$/.test(getValues('phoneNumber'));
    if (!getValues('firstname') || !getValues('lastname')) {
      enqueueSnackbar(t('signInPage.firstNameAndLastName'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      if (
        !email &&
        checkPhoneNumberVerify &&
        !phoneNumberPattern &&
        !isGoogleSignUp &&
        !isAppleSignUp
      ) {
        handleSubmitData();
      }
      if (checkPhoneNumberVerify) {
        const isValidData = await trigger(['firstname', 'lastname', 'email', 'phoneNumber']);

        if (!isValidData) {
          return;
        }

        if (!email) {
          handleSubmitData();
        } else {
          const payload = {
            query: queryData.checkEmail,
            variables: {
              email: getValues('email').toLocaleLowerCase(),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.checkEmail?.status === 200 && result?.checkEmail?.available === true) {
            setSeconds(59);
            setMinutes(0);
            await setEmailVerify(true);
            await clearErrorsEmail('error');
            const data = {
              query: queryData.sendOTPOnEmailVerification,
              variables: { email: getValues('email').toLocaleLowerCase() },
            };
            const res = await dispatch(signUpPerson(data));
            setResendOtpStatus(false);
            if (result?.payload?.sendOTPOnEmailVerification?.status === 200) {
              enqueueSnackbar(res?.payload?.sendOTPOnEmailVerification?.message, {
                variant: 'success',
                autoHideDuration: 3000,
              });
            }
          } else if (result?.payload?.checkEmail?.available === false) {
            setErrorEmail('error', {
              type: 'manual',
              message: t('signUpPage.messages.existsEmail'),
            });
            enqueueSnackbar(result?.payload?.checkEmail?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }
      } else if (email && phoneNumberPattern) {
        const payload = {
          query: queryData.checkAndVerifyPhoneNumber,
          variables: {
            phoneNumber: parseInt(getValues('phoneNumber'), 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.checkAndVerifyPhoneNumber?.status === 200 &&
          result?.checkAndVerifyPhoneNumber?.available === true
        ) {
          setSeconds(59);
          setMinutes(0);
          await clearErrorsEmail('error');
          const matchingLabel = Object.entries(customLabels)?.find(
            ([key, value]) => value.isoCode === selected
          );
          const data = {
            query: queryData.sendOTPOnPhoneNumberVerification,
            variables: {
              countryCode: matchingLabel[1].secondary || `+${ipBasedData?.countryCode}`,
              phoneNumber: parseInt(getValues('phoneNumber'), 10),
            },
          };
          const response = await dispatch(signUpPerson(data));
          if (response?.payload?.sendOTPOnPhoneNumberVerification?.status === 200) {
            await setPhoneNumberVerify(true);
            enqueueSnackbar(response?.payload?.sendOTPOnPhoneNumberVerification?.message, {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(response?.payload?.sendOTPOnPhoneNumberVerification?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } else if (
          result?.checkAndVerifyPhoneNumber?.status === 200 &&
          result?.checkAndVerifyPhoneNumber?.available === false
        ) {
          setErrorEmail('error', {
            type: 'manual',
            message: t('signUpPage.messages.existsPhoneNumber'),
          });
          enqueueSnackbar(result?.checkAndVerifyPhoneNumber?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        setErrorEmail('error', {
          type: 'manual',
          message: t('signUpPage.messages.enterValidPhoneNumber'),
        });
      }
    }
  };

  const handleChange = (event) => {
    const phoneNumberPattern = /^\d+$/;
    const phoneNumber = event.target.value;
    if (phoneNumber.length < 11) {
      setValue('phoneNumber', parseInt(phoneNumber, 10));
    }
    if (phoneNumberPattern.test(phoneNumber)) {
      clearErrorsEmail('error');
    } else {
      setError('phoneNumber', {
        type: 'manual',
        message: t('signUpPage.messages.enterValidPhoneNumber'),
      });
    }
  };

  const resendOTP = async () => {
    setResendOtpStatus(true);
    const phoneNumberPattern = /^\d+$/.test(getValues('phoneNumber'));

    if (getValues('email') && !isGoogleSignUp && !isAppleSignUp && !phoneNumberPattern) {
      const payload = {
        query: queryData.sendOTPOnEmailVerification,
        variables: { email: getValues('email').toLocaleLowerCase() },
      };
      const result = await dispatch(signUpPerson(payload));
      setResendOtpStatus(false);
      if (result?.payload?.sendOTPOnEmailVerification?.status === 200) {
        enqueueSnackbar(result?.payload?.sendOTPOnEmailVerification?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } else if (phoneNumberPattern && !getValues('email')) {
      setSeconds(59);
      setMinutes(0);
      const number = userValue || getValues('phoneNumber');
      const phoneData = { phoneNumber: parseInt(userValue, 10) };
      const matchingLabel = Object.entries(customLabels)?.find(
        ([key, value]) => value.isoCode === selected
      );
      const payload = {
        query: queryData.sendOTPOnPhoneNumberVerification,
        variables: {
          countryCode: matchingLabel[1].secondary || `+${ipBasedData?.countryCode}`,
          phoneNumber: parseInt(number, 10),
        },
      };
      const result = await dispatch(signUpPerson(payload));
      setResendOtpStatus(false);
      if (result?.payload?.sendOTPOnPhoneNumberVerification?.status === 200 && isGoogleSignUp) {
        setRegisterProcess(false);
      } else if (result.payload?.sendOTPOnPhoneNumberVerification?.status === 400) {
        enqueueSnackbar(result.payload?.sendOTPOnPhoneNumberVerification?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return result;
    } else if (phoneNumberPattern && getValues('email')) {
      const payload = {
        query: queryData.checkAndVerifyPhoneNumber,
        variables: {
          phoneNumber: parseInt(getValues('phoneNumber'), 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (
        result?.checkAndVerifyPhoneNumber?.status === 200 &&
        result?.checkAndVerifyPhoneNumber?.available === true
      ) {
        setSeconds(59);
        setMinutes(0);
        await clearErrorsEmail('error');
        const matchingLabel = Object.entries(customLabels)?.find(
          ([key, value]) => value.isoCode === selected
        );
        const phoneData = {
          query: queryData.sendOTPOnPhoneNumberVerification,
          variables: {
            countryCode: matchingLabel[1].secondary || `+${ipBasedData?.countryCode}`,
            phoneNumber: parseInt(getValues('phoneNumber'), 10),
          },
        };
        const response = await dispatch(signUpPerson(phoneData));
        if (response?.payload?.sendOTPOnPhoneNumberVerification?.status === 200) {
          await setPhoneNumberVerify(true);
          enqueueSnackbar(response?.payload?.sendOTPOnPhoneNumberVerification?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(response?.payload?.sendOTPOnPhoneNumberVerification?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else if (
        result?.checkAndVerifyPhoneNumber?.status === 200 &&
        result?.checkAndVerifyPhoneNumber?.available === false
      ) {
        setErrorEmail('error', {
          type: 'manual',
          message: t('signUpPage.messages.existsPhoneNumber'),
        });
        enqueueSnackbar(result?.checkAndVerifyPhoneNumber?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else if (!phoneNumberPattern) {
      setErrorEmail('error', {
        type: 'manual',
        message: t('signUpPage.messages.enterValidPhoneNumber'),
      });
    }
    setMinutes(1);
    setSeconds(0);
    setTimeout(() => {
      setResendOtpStatus(false);
      setMinutes(0);
      setSeconds(59);
    }, 1000);
    return null;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserValue(inputValue);

    if (/^\d+$/.test(inputValue)) {
      e.target.type = 'tel';
      setShowPhoneDropdown(true);
      const digitCount = inputValue.replace(/\D/g, '').length;
      if (digitCount > 15) {
        e.target.value = inputValue.substring(0, 10);
        setUserValue(inputValue.substring(0, 15));
      }
    } else {
      e.target.type = 'text';
      setShowPhoneDropdown(false);
    }
  };

  async function handleMail() {
    setIsButtonDisabled(true);
    try {
      const email = /\S+@\S+\.\S+/.test(userValue);
      const phoneNumberPattern = /^\d+$/.test(parseInt(userValue, 10));
      if (email === true) {
        setValue('email', userValue);
        const payload = {
          query: queryData.checkEmail,
          variables: {
            email: userValue,
          },
        };
        const result = await dispatch(checkEmail(payload));
        if (
          result?.payload?.data?.checkEmail?.status === 200 &&
          result?.payload?.data?.checkEmail?.available === true
        ) {
          clearErrorsEmail('error');
          setIsEmail(true);
          setNextStep(true);
          resendOTP();
        } else if (result?.payload?.data?.checkEmail?.available === false) {
          setErrorEmail('error', {
            type: 'manual',
            message: t('signUpPage.messages.existsEmail'),
          });
        }
      } else if (phoneNumberPattern) {
        setValue('phoneNumber', parseInt(userValue, 10));
        const payload = {
          query: queryData.checkAndVerifyPhoneNumber,
          variables: {
            phoneNumber: parseInt(userValue, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.checkAndVerifyPhoneNumber?.status === 200 &&
          result?.checkAndVerifyPhoneNumber?.available === true
        ) {
          const res = await resendOTP();
          if (res?.payload?.sendOTPOnPhoneNumberVerification?.status === 200) {
            setNextStep(true);
            setIsRegisterByPhone(true);
            clearErrorsEmail('error');
          }
        } else if (
          result?.checkAndVerifyPhoneNumber?.status === 200 &&
          result?.checkAndVerifyPhoneNumber?.available === false
        ) {
          setErrorEmail('error', {
            type: 'manual',
            message: t('signUpPage.messages.existsPhoneNumber'),
          });
        }
      } else if (email === false && !showPhoneDropdown) {
        setErrorEmail('error', {
          type: 'manual',
          message: t('signUpPage.messages.enterValidEmail'),
        });
      } else if (!phoneNumberPattern && showPhoneDropdown) {
        setErrorEmail('error', {
          type: 'manual',
          message: t('signUpPage.messages.enterValidPhoneNumber'),
        });
      }
    } finally {
      setIsButtonDisabled(false);
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleMail();
    }
  }

  const handleCheckPassword = () => {
    const password = getValues('password');

    const passwordRegex =
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

    if (!password) {
      enqueueSnackbar('Password is required', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else if (password.length < 8) {
      enqueueSnackbar(t('users.resetPassword.formFields.validationMessages.passwordTooShort'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else if (!passwordRegex.test(password)) {
      enqueueSnackbar(t('users.resetPassword.formFields.validationMessages.passwordRequirements'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      setUserInformation(true);
    }
  };

  function handleKeyPressSignUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitOtp();
    }
  }

  function handleKeyPressSignUpNext(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setUserInformation(true);
    }
  }

  const responseGoogle = async (response) => {
    const decodeData = jwtDecode(response?.credential);
    setGoogleAccessToken(response?.credential);
    if (response) {
      setGoogleLoading(true);
      let successMessage = '';
      try {
        const googleResponse = JSON.stringify({
          accessToken: response.credential,
        });
        const registrationPayload = {
          query: Query.asPreviouslyRegesterInGoogle,
          variables: { data: getEncryptedData(googleResponse) },
        };
        const registrationResult = await dispatch(
          asPreviouslyRegesterInGoogle(registrationPayload)
        );
        if (registrationResult.payload?.available === true) {
          setValue('firstname', decodeData?.given_name);
          setValue('lastname', decodeData?.family_name);
          setValue('email', decodeData?.email);
          setUserInformation(true);
          setRegisterProcess(true);
          setIsGoogleSignUp(true);
        } else {
          successMessage = t('signInPage.messages.notRegistered');
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setGoogleLoading(false);
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  const handleAppleSignInSuccess = (response) => {
    if (response && response.id_token) {
      const decodedToken = jwtDecode(response.id_token);

      const userEmail = decodedToken.email;
      // const userName = decodedToken.name;
    }
  };

  const handleAppleSignInError = (error) => {
    console.error('error', error);
  };

  const handleChangeEmailOrPhone = () => {
    setNextStep(false);
  };

  const handleBackArrow = () => {
    setSeconds(0);
    setMinutes(0);
    setNextStep(false);
    setUserInformation(false);
    setIsRegisterByPhone(false);
    setRegisterProcess(false);
    setCheckPhoneNumberVerify(false);
    setPhoneNumberVerify(false);
    setEmailVerify(false);
    setIsGoogleSignUp(false);
    setIsRegisterByPhone(false);
    setGoogleLoading(false);
    setSelectedPackageTypeId(params?.id || process.env.REACT_APP_DEFAULT_SIGNUP_PACKAGEID);
    setUserValue('');
    setOtp('');
    reset('');
    setIsAppleSignUp(false);
  };
  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="salse-form h-full sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full lg:w-1/2 py-8 px-16 sm:p-48 lg:p-96 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        {nextStep && (
          <Button
            className="md:absolute top-[5%] p-0  hover:bg-white left-[5%] min-w-8 h-8"
            onClick={() => handleBackArrow()}
          >
            <ArrowBackIcon />
          </Button>
        )}
        {!isWhatsapp ? (
          <div className="block">
            {!registerProcess ? (
              <div className="w-full max-w-360 sm:w-360">
                <img
                  className="w-[170px]"
                  src="/assets/images/icon/quick_reviews_logo.png"
                  alt="logo"
                />

                <Typography className="mt-28 text-32 font-semibold tracking-tight leading-tight">
                  Sign Up
                </Typography>
                <>
                  {(nextStep && getValues('email')) || isGoogleSignUp ? (
                    <div className="mt-8">
                      <Typography className="text-28 mb-16 font-medium">
                        {t('signInPage.messages.verifyYourAccount')}
                      </Typography>
                      <Typography className="text-16 font-medium">
                        {t('signInPage.messages.verifyEmail')}
                      </Typography>
                      <Typography className="text-16 font-medium">
                        {isGoogleSignUp ? getValues('phoneNumber') : getValues('email')}
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex items-baseline mt-2 font-medium">
                      <Typography className="text-18 font-medium mt-15">
                        {t('signUpPage.messages.alreadyHaveAccount')}
                      </Typography>
                      <Link className="text-18 font-medium ml-4" to="/sign-in">
                        {t('signInPage.title')}
                      </Link>
                    </div>
                  )}
                  <form
                    name="form"
                    noValidate
                    className="flex flex-col justify-center w-full mt-32"
                  >
                    {(!nextStep || showPhoneDropdown) && !isGoogleSignUp && (
                      <span className="relative">
                        {showPhoneDropdown && (
                          <span className="absolute z-20">
                            <ReactFlagsSelect
                              className={
                                userValue && nextStep
                                  ? 'pointer-events-none react-flag-button'
                                  : 'react-flag-button'
                              }
                              selected={selected}
                              onSelect={(code) => setSelected(code)}
                              countries={countryIsoData}
                              searchable
                              customLabels={customLabels}
                              showSelectedLabel={false}
                            />
                          </span>
                        )}
                        <TextField
                          className="mb-24 rounded-md"
                          name="email"
                          control={control}
                          style={{ height: '44px' }}
                          placeholder={t('signInPage.formField.email.userNameSignUp')}
                          sx={{
                            '& .MuiInputBase-input': {
                              border: '1px solid #666666',
                              textIndent: showPhoneDropdown ? '105px !important' : 'initial',
                              transition: 'all .2s ease-in-out',
                              borderRadius: '7px',
                            },
                          }}
                          autoFocus
                          value={userValue}
                          disabled={userValue && nextStep}
                          onChange={handleInputChange}
                          onKeyDown={(event) => {
                            handleKeyPress(event);
                          }}
                          size="small"
                          error={!!errorsEmail.error}
                          helperText={errorsEmail.error ? errorsEmail.error.message : ''}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </span>
                    )}
                    {(nextStep || isGoogleSignUp) && (
                      <>
                        <MuiOtpInput
                          className="max-w-xs my-24"
                          TextFieldsProps={{
                            size: 'small',
                            placeholder: '-',
                            type: 'number',
                          }}
                          type="number"
                          gap={2}
                          length={6}
                          value={otp}
                          onChange={(newValue) => setOtp(newValue)}
                        />
                        <div>
                          <Button
                            className="hover:bg-white text-red"
                            onClick={() => handleChangeEmailOrPhone()}
                          >
                            {getValues('email')
                              ? t('signInPage.changeEmail')
                              : t('signInPage.changePhoneNumber')}
                          </Button>
                        </div>
                        <div className="countdown-text flex items-center justify-start text-16 font-medium my-10">
                          {seconds > 0 || minutes > 0 ? (
                            <p>
                              {t('signUpPage.messages.codeResend')}:{' '}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>{t('signUpPage.messages.notReceivedCode')}</p>
                          )}
                          {resendOtpStatus ? (
                            <CircularProgress
                              size={24}
                              className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                            />
                          ) : (
                            <Button
                              disabled={seconds > 0 || minutes > 0}
                              className="underline decoration-1 text-14 font-medium"
                              color="secondary"
                              style={{
                                color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                              }}
                              onClick={resendOTP}
                            >
                              {t('signUpPage.buttons.resendOTP')}
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                    {isGoogleSignUp && (
                      <Button
                        variant="contained"
                        color="secondary"
                        className="w-full md:text-18 font-semibold disabled:text-black rounded-md"
                        aria-label="Sign in"
                        disabled={!otp}
                        onClick={submitOtp}
                        type="button"
                      >
                        {t('signUpPage.buttons.confirm')}
                      </Button>
                    )}
                    {!isGoogleSignUp && (
                      <>
                        {nextStep ? (
                          <div>
                            <Button
                              variant="contained"
                              color="secondary"
                              className="w-full md:text-18 font-semibold disabled:text-black rounded-md"
                              aria-label="Sign in"
                              disabled={!otp}
                              onClick={submitOtp}
                              type="button"
                            >
                              {t('signUpPage.buttons.confirm')}
                            </Button>
                            {loading && (
                              <CircularProgress
                                size={24}
                                className="text-darkgreen absolute mx-auto"
                              />
                            )}
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant="contained"
                              color="secondary"
                              className="w-full md:text-18 font-semibold disabled:text-black rounded-md"
                              aria-label="Sign in"
                              disabled={isButtonDisabled || !userValue}
                              onClick={handleMail}
                              type="button"
                            >
                              {t('signUpPage.buttons.next')}
                            </Button>
                            {OTPLoading && (
                              <CircularProgress
                                size={24}
                                className="text-darkgreen absolute mx-auto"
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                    <div className="my-20 flex items-center justify-center">
                      <Divider className="w-80" />
                      <span className="mx-8 font-medium text-16">
                        {t('signInPage.messages.signUpWithGoogle')}
                      </span>
                      <Divider className="w-80" />
                    </div>
                    <div className="w-full mb-8 text-center google-login">
                      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <div className="w-full">
                          <GoogleLogin onSuccess={responseGoogle} onError={responseGoogle} />
                          {googleLoading && (
                            <CircularProgress
                              size={24}
                              className="text-darkgreen absolute mx-auto top-10 right-0"
                            />
                          )}
                        </div>
                      </GoogleOAuthProvider>
                      {/* <div className="flex justify-center py-4 mb-8 mt-16 text-center google-login">
                      <AppleSignin
                        authOptions={{
                          clientId: 'org.reactjs.native.example.happyReview',
                          scope: 'email name',
                          redirectURI: 'https://app-uat.quickreviews.click/sign-up',
                          state: 'state',
                          nonce: 'nonce',
                        }}
                        uiType="dark"
                        noDefaultStyle={false}
                        buttonExtraChildren="Continue with Apple"
                        onSuccess={handleAppleSignInSuccess}
                        onError={handleAppleSignInError}
                        render={(props) => (
                          <button type="button" {...props}>
                            My Custom Button
                          </button>
                        )}
                      />
                      
                    </div> */}
                    </div>
                    <div className="w-full mb-8 text-center google-login relative mt-12 cursor-pointer">
                      <AppleLogin
                        clientId="org.reactjs.native.example.happyReview"
                        redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URL}appleVerify`}
                        scope="email name   "
                        responseMode="form_post"
                        responseType="id_token code"
                        designProp={{ width: 200 }}
                      />
                    </div>
                    {/* <div className="w-full mb-8 text-center google-login relative mt-12 cursor-pointer">
                      <Button
                        className="border text-14 border-solid font-semibold rounded max-w-200 w-200 hover:bg-white"
                        onClick={() => setIsWhatsApp(true)}
                      >
                        <img className="pr-8" src="/assets/images/business/whatsapp.svg" alt="" />
                        {t('whatsapp.continueWhatsappSignUp')}
                      </Button>
                    </div>
                    <Button
                      className="text-darkgreen underline max-w-192 m-auto hover:bg-white"
                      onClick={() => handleWhatsappClick()}
                    >
                      {' '}
                      {t('whatsapp.openWhatsapp')}
                    </Button> */}
                  </form>
                </>
              </div>
            ) : (
              <div className="w-full max-w-360 sm:w-360">
                <img
                  className="w-[170px]"
                  src="/assets/images/icon/quick_reviews_logo.png"
                  alt="logo"
                />
                {useInformation && !phoneNumberVerify && !emailVerify && (
                  <Typography className="mt-28 text-32 font-semibold tracking-tight leading-tight">
                    Personal Information
                  </Typography>
                )}
                <form
                  name="registerForm"
                  noValidate
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="lg:flex">
                    <div className="lg:mr-14">
                      {useInformation && !phoneNumberVerify && !emailVerify && (
                        <>
                          <Controller
                            name="firstname"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className={`mb-${errors.firstname ? '10' : '24'}`}
                                label={t('signUpPage.formField.firstName.name')}
                                type="text"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    asterisk: 'text-red-500',
                                  },
                                }}
                              />
                            )}
                          />
                          {errors.firstname && (
                            <div className="text-red-900 mb-24">{errors.firstname.message}</div>
                          )}
                          {!isGoogleSignUp ||
                            (!isAppleSignUp && (
                              <Controller
                                name="middlename"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mb-24"
                                    label={t('signUpPage.formField.middleName.name')}
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            ))}
                          <Controller
                            name="lastname"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className={`mb-${errors.lastname ? '10' : '24'}`}
                                label={t('signUpPage.formField.lastName.name')}
                                type="text"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    asterisk: 'text-red-500',
                                  },
                                }}
                              />
                            )}
                          />
                          {errors.lastname && (
                            <div className="text-red-900 mb-24">{errors.lastname.message}</div>
                          )}
                          {isEmail || isGoogleSignUp || isAppleSignUp ? (
                            <div className="flex flex-col justify-center w-full">
                              <span className="relative">
                                <span className="absolute z-20">
                                  <ReactFlagsSelect
                                    className="react-flag-button"
                                    selected={selected}
                                    onSelect={(code) => setSelected(code)}
                                    countries={countryIsoData}
                                    searchable
                                    customLabels={customLabels}
                                    showSelectedLabel={false}
                                  />
                                </span>
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24 rounded-md"
                                      placeholder={t('signUpPage.formField.phoneNumber.name')}
                                      style={{ height: '44px' }}
                                      type="number"
                                      onChange={handleChange}
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          paddingLeft: '125px',
                                          border: '1px solid #666666',
                                          textIndent: showPhoneDropdown
                                            ? '105px !important'
                                            : 'initial',
                                          transition: 'all .2s ease-in-out',
                                          borderRadius: '7px',
                                        },
                                      }}
                                      size="small"
                                      error={!!errorsEmail.error}
                                      helperText={
                                        errorsEmail.error ? errorsEmail.error.message : ''
                                      }
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </span>
                              {!isEmail && (
                                <Controller
                                  name="email"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      label={t('signUpPage.formField.email.name')}
                                      type="text"
                                      size="small"
                                      error={!!errors.email}
                                      helperText={errors?.email?.message}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </div>
                          ) : (
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  label={t('signUpPage.formField.email.name')}
                                  type="text"
                                  size="small"
                                  error={!!errors.email}
                                  helperText={errors?.email?.message}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}

                          <div>
                            <span className="block text-center mb-20 text-16 font-semibold text-darkgreen">
                              {t('signUpPage.messages.selectPlan')}
                            </span>
                            <FormControl className="block mb-28">
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                className="grid grid-cols-1 gap-20 lg:gap-20"
                                value={selectedPackageTypeId}
                                onChange={(event) => setSelectedPackageTypeId(event?.target?.value)}
                              >
                                {getPackageResponse
                                  .filter((packageValue) => packageValue?.id !== 1)
                                  .sort((a, b) => {
                                    if (a?.packageIndex < b?.packageIndex) {
                                      return -1;
                                    }
                                    if (a?.packageIndex > b?.packageIndex) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((packageValue, index) => {
                                    const previousItem = prevItem;
                                    prevItem = packageValue;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        value={packageValue?.packageTypeId}
                                        control={
                                          <Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />
                                        }
                                        label={
                                          <code className="flex justify-between items-center w-full">
                                            <span className="text-16 font-semibold inline-block capitalize select-plan-signup">
                                              {packageValue.name}
                                              <span className="option-info h-full inline-block">
                                                <Icon className="text-16 relative top-3 ml-3">
                                                  info
                                                </Icon>
                                                <span className="option-description absolute hidden bg-darkgreen text-white rounded-md p-2 mt-1 w-full left-0 right-0 z-30 max-h-320 overflow-y-auto">
                                                  <ul className="list-disc list-inside px-14 pt-8 pb-14">
                                                    {Object.values(
                                                      JSON.parse(packageValue.packageDetails)
                                                    )
                                                      .filter((item, idx) => idx < 18)
                                                      .map((feature, i) => (
                                                        <li className="mb-0" key={i}>
                                                          <span className="text-12 font-semibold relative -left-10">
                                                            {feature.name}
                                                          </span>
                                                        </li>
                                                      ))}
                                                  </ul>
                                                </span>
                                              </span>
                                            </span>
                                            <span>
                                              <span
                                                className={`${
                                                  packageValue?.offerAmount
                                                    ? 'line-through font-medium text-16 md:text-16'
                                                    : 'text-16 font-medium inline-block capitalize'
                                                }`}
                                              >
                                                <span>
                                                  {packageValue?.currency === 'INR' ? '₹' : '$'}
                                                </span>
                                                {packageValue?.amount}
                                              </span>
                                              {packageValue?.offerAmount && (
                                                <h3 className="my-0 text-16 md:text-16 font-semibold inline-block ml-5 font-semibold text-16 md:text-16">
                                                  <span>
                                                    {packageValue?.currency === 'INR' ? '₹' : '$'}
                                                  </span>
                                                  {packageValue.offerAmount}
                                                </h3>
                                              )}
                                              /Month
                                            </span>
                                          </code>
                                        }
                                        className={`rounded-md p-10 border m-0 relative block w-full border-1 border-solid ${
                                          parseInt(selectedPackageTypeId, 10) ===
                                          packageValue?.packageTypeId
                                            ? 'bg-[#006B5A] border-[#006B5A] text-white'
                                            : 'bg-white border-grey '
                                        }`}
                                      />
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>
                          </div>

                          <div className="pb-24">
                            <Typography>
                              {t('signUpPage.messages.termsAndConditionsMessage')}
                            </Typography>
                            <Typography className="text-darkgreen">
                              <a href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}>
                                {' '}
                                {t('signUpPage.messages.termsAndCondition')}
                              </a>{' '}
                              <span className="text-black">and </span>{' '}
                              <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK}>
                                {' '}
                                {t('signUpPage.messages.pricyPolicy')}{' '}
                              </a>
                            </Typography>
                            {isGoogleSignUp && (
                              <Button
                                variant="contained"
                                color="secondary"
                                className="w-full md:text-18 font-semibold rounded-md"
                                aria-label="Sign in"
                                type="button"
                                disabled={!getValues('phoneNumber')}
                                onClick={checkPhoneNumber}
                              >
                                {t('signUpPage.buttons.next')}
                              </Button>
                            )}
                          </div>
                          {!isGoogleSignUp && (
                            <Button
                              variant="contained"
                              color="secondary"
                              className="w-full md:text-18 font-semibold rounded-md"
                              aria-label="Sign in"
                              type="button"
                              onClick={checkPhoneNumber}
                            >
                              {t('signUpPage.buttons.next')}
                            </Button>
                          )}
                        </>
                      )}
                      {useInformation && phoneNumberVerify && (
                        <>
                          <div className="flex flex-col justify-center w-full">
                            <span className="relative">
                              <span className="absolute z-20">
                                <ReactFlagsSelect
                                  className="react-flag-button"
                                  selected={selected}
                                  onSelect={(code) => setSelected(code)}
                                  countries={countryIsoData}
                                  searchable
                                  customLabels={customLabels}
                                  showSelectedLabel={false}
                                />
                              </span>
                              <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mb-24 rounded-md"
                                    style={{ height: '44px' }}
                                    type="number"
                                    sx={{
                                      '& .MuiInputBase-input': {
                                        paddingLeft: '125px',
                                        border: '1px solid #666666',
                                        textIndent: showPhoneDropdown
                                          ? '105px !important'
                                          : 'initial',
                                        transition: 'all .2s ease-in-out',
                                        borderRadius: '7px',
                                      },
                                    }}
                                    disabled={useInformation && getValues('phoneNumber')}
                                    autoFocus
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                            </span>
                          </div>
                          <MuiOtpInput
                            className="max-w-xs my-24"
                            TextFieldsProps={{
                              size: 'small',
                              placeholder: '-',
                              type: 'number',
                            }}
                            type="number"
                            gap={2}
                            length={6}
                            value={otp}
                            onChange={(newValue) => setOtp(newValue)}
                          />
                          <div className="countdown-text flex items-center justify-start text-16 font-medium my-10">
                            {seconds > 0 || minutes > 0 ? (
                              <p>
                                {t('signUpPage.messages.codeResend')}:{' '}
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            ) : (
                              <p>{t('signUpPage.messages.notReceivedCode')}</p>
                            )}
                            {resendOtpStatus ? (
                              <CircularProgress
                                size={24}
                                className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                              />
                            ) : (
                              <Button
                                disabled={seconds > 0 || minutes > 0}
                                className="underline decoration-1 text-14 font-medium"
                                color="secondary"
                                style={{
                                  color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                                }}
                                onClick={resendOTP}
                              >
                                {t('signUpPage.buttons.resendOTP')}
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                      {useInformation && emailVerify && (
                        <>
                          <div className="mt-2 mb-12">
                            <Typography className="text-28 mb-16 font-medium">
                              {t('signInPage.messages.verifyYourAccount')}
                            </Typography>
                            <Typography className="text-16 font-medium">
                              {t('signInPage.messages.verifyEmail')}
                            </Typography>
                            <Typography className="text-16 font-medium">
                              {isGoogleSignUp ? getValues('phoneNumber') : getValues('email')}
                            </Typography>
                          </div>
                          <MuiOtpInput
                            className="max-w-xs my-24"
                            TextFieldsProps={{
                              size: 'small',
                              placeholder: '-',
                              type: 'number',
                            }}
                            type="number"
                            gap={2}
                            length={6}
                            value={otp}
                            onChange={(newValue) => setOtp(newValue)}
                          />
                          <div className="countdown-text flex items-center justify-start text-16 font-medium my-10">
                            {seconds > 0 || minutes > 0 ? (
                              <p>
                                {t('signUpPage.messages.codeResend')}:{' '}
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            ) : (
                              <p>{t('signUpPage.messages.notReceivedCode')}</p>
                            )}
                            {resendOtpStatus ? (
                              <CircularProgress
                                size={24}
                                className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                              />
                            ) : (
                              <Button
                                disabled={seconds > 0 || minutes > 0}
                                className="underline decoration-1 text-14 font-medium"
                                color="secondary"
                                style={{
                                  color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                                }}
                                onClick={resendOTP}
                              >
                                {t('signUpPage.buttons.resendOTP')}
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                      {useInformation && (emailVerify || phoneNumberVerify) && (
                        <>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="w-full md:text-18 font-semibold rounded-md"
                            aria-label="Sign in"
                            disabled={!otp}
                            type="submit"
                          >
                            {t('signUpPage.buttons.next')}
                          </Button>
                        </>
                      )}
                      {!useInformation && getValues('email') && nextStep && (
                        <>
                          <TextField
                            className="mb-24 rounded-md"
                            name="email"
                            control={control}
                            style={{ height: '44px' }}
                            placeholder={t('signInPage.formField.email.userName')}
                            sx={{
                              '& .MuiInputBase-input': {
                                border: '1px solid #666666',
                                textIndent: showPhoneDropdown ? '105px !important' : 'initial',
                                transition: 'all .2s ease-in-out',
                                borderRadius: '7px',
                              },
                            }}
                            autoFocus
                            disabled={userValue && nextStep}
                            value={userValue}
                            size="small"
                            error={!!errorsEmail.error}
                            helperText={errorsEmail.error ? errorsEmail.error.message : ''}
                            variant="outlined"
                            required
                            fullWidth
                          />
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                label={t('signUpPage.formField.password.name')}
                                type="password"
                                size="small"
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                                InputProps={{
                                  className: 'pr-2',
                                  type: showPassword ? 'text' : 'password',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        <Icon className="text-20" color="action">
                                          {showPassword ? 'visibility' : 'visibility_off'}
                                        </Icon>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    asterisk: 'text-red-500',
                                  },
                                }}
                              />
                            )}
                          />
                          <Controller
                            name="passwordConfirm"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                label={t('signUpPage.formField.passwordConfirm.name')}
                                type="password"
                                size="small"
                                error={!!errors.passwordConfirm}
                                helperText={errors?.passwordConfirm?.message}
                                onKeyDown={(event) => {
                                  handleKeyPressSignUpNext(event);
                                }}
                                InputProps={{
                                  className: 'pr-2',
                                  type: showCnfPassword ? 'text' : 'password',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowCnfPassword(!showCnfPassword)}
                                      >
                                        <Icon className="text-20" color="action">
                                          {showCnfPassword ? 'visibility' : 'visibility_off'}
                                        </Icon>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    asterisk: 'text-red-500',
                                  },
                                }}
                              />
                            )}
                          />

                          <Button
                            variant="contained"
                            color="secondary"
                            className={`w-full md:text-18 font-semibold rounded-md ${
                              (formState.isDirty && formState.errors.passwordConfirm) ||
                              !getValues('password') ||
                              !getValues('passwordConfirm')
                                ? 'disabled:text-black'
                                : ''
                            }`}
                            aria-label="Sign in"
                            disabled={
                              (formState.isDirty && formState.errors.passwordConfirm) ||
                              !getValues('password') ||
                              !getValues('passwordConfirm')
                            }
                            onClick={() => handleCheckPassword()}
                            type="button"
                          >
                            {t('signInPage.buttons.signUp')}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
            {!useInformation && (
              <div className="bg-white py-10 max-w-288 text-14 text-center mx-auto">
                By continuing you agree to our <br />
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.privacyPolicy')}
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.termsService')}
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_EULA_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.eula')}
                </a>{' '}
                and{' '}
                <a
                  href={process.env.REACT_APP_DISCLAIMER_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.disclaimers')}
                </a>{' '}
              </div>
            )}
          </div>
        ) : (
          <div className="bg-gradient-to-b from-darkgreen-A500 to-darkgreen text-center p-40 max-w-360 rounded-xl">
            <Typography className="text-white w-full text-28 py-8 font-semibold">
              {t('whatsapp.signUpContinue')}
            </Typography>
            <div className="text-center m-auto bg-white max-w-192  p-16 rounded-md my-20">
              <QRCode className="w-160 h-160" value={whatsAppURL} />
            </div>
            <Typography className="text-white text-20 max-w-384 py-24 font-normal">
              {t('whatsapp.scanQrCode')}
            </Typography>
          </div>
        )}
      </Paper>
      <SignInBoxContent />
    </div>
  );
}

export default withReducer('signUpReducer', reducer)(SignUpPage);

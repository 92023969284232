import { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import ReviewMode from '../../manage-business/ReviewMode';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const ReviewScreenMode = () => {
  const [modeId, setModeId] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchBusinessData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
      setLoading(true);
      const payload = {
        query: manageBusinessData.getUserBusinessDetail,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getUserBusinessDetail?.status === 200) {
        setModeId(parseInt(result?.getUserBusinessDetail?.data?.[0]?.reviewModeTypeId, 10));
      } else {
        enqueueSnackbar(result?.getUserBusinessDetail?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [
    enqueueSnackbar,
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    setModeId,
  ]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  const saveAndNext = async () => {
    if (userCurrentBusinessDetails?.id) {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.updateUserBusinessesPlatformModeTypeV2,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          reviewModeTypeId: parseInt(modeId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateUserBusinessesPlatfromModeType?.status === 200) {
        enqueueSnackbar(result?.updateUserBusinessesPlatfromModeType?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.updateUserBusinessesPlatfromModeType?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 h-5/6 lg:p-40">
      <>
        <div className="flex flex-1 w-full items-center justify-between mb-36">
          <div className="flex items-center">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('reviewScreenMode.title')}
            </Typography>
          </div>
          <div className="md:flex gap-24 text-center">
            <Button
              className="bg-darkgreen rounded md:px-60 disabled:bg-gray disabled:text-black text-white hover:bg-darkgreen mx-10 md:mx-0"
              disabled={refreshIcon || !modeId || !userCurrentBusinessDetails?.id}
              onClick={() => saveAndNext()}
            >
              {t('reviewScreenMode.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        </div>
        <div className="bg-white p-36">
          <ReviewMode setModeId={setModeId} modeId={modeId} />
        </div>
      </>
    </div>
  );
};

export default ReviewScreenMode;

import { t } from 'i18next';
import { Button, Icon, MenuItem, Select, Typography } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSnackbar } from 'notistack';
import { socket } from '../../../../socket';
import './Waba.css';
import TemplateList from './TemplateList';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest } from '../../common/common';

const WabaInbox = (props) => {
  const chatScroll = useRef(null);
  const [messageInput, setMessageInput] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isExpandedSearch, setIsExpandedSearch] = useState(false);
  const [openTemplateListDialog, setOpenTemplateListDialog] = useState(false);
  const [isExpandedNote, setIsExpandedNote] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [getChatNote, setGetChatNote] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noteValue, setNoteValue] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const inputRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [editedNote, setEditedNote] = useState(null);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    try {
      if (selectedContact) {
        const whatAppUserDetails = async () => {
          const payload = {
            query: wabaQuery.getAllMessage,
            variables: {
              waChatRoomId: selectedContact?.waChatRoomId,
              sortBy: 'cts',
              sortOrder: 'asc',
              pageNumber: 1,
              pageSize: 500,
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getAllMessage?.status === 200) {
            setSelectContactMessageList(res?.getAllMessage?.data);
            chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
          }
        };

        whatAppUserDetails();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact]);

  useEffect(() => {
    try {
      const whatAppUserChatRoom = async () => {
        const payload = {
          query: wabaQuery.getChatRoomWithLastMessage,
          variables: {
            isBroadcast: false,
            isUnRead: false,
            search: searchValue,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getChatRoomWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getChatRoomWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }
      };

      whatAppUserChatRoom();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isExpandedSearch) {
      inputRef.current.focus();
    }
  }, [isExpandedSearch]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });
    socket.on('receivedMessage', (message) => {
      if (message?.payload?.eventType === 'MESSAGES_STATUS') {
        const messageId = message?.payload?.data?.messageId;
        setSelectContactMessageList((prevList) =>
          prevList.map((msg) =>
            msg.messageId === messageId ? { ...msg, status: message?.payload?.data?.status } : msg
          )
        );
      }

      if (message?.payload?.eventType === 'MESSAGE_FROM_CLIENT') {
        setSelectContactMessageList((prevMessages) => [
          ...prevMessages,
          { ...message?.payload?.data, cts: message?.payload?.data?.timestamp },
        ]);
      }
    });

    // Disconnect from the server
    socket.on('disconnect', () => {
      setIsConnected(false);
      // Optionally, you might want to clear any state related to active session here
    });

    // return () => {
    //   socket.off('connect', onConnect);
    //   socket.off('disconnect', onDisconnect);
    //   socket.off('foo', onFooEvent);
    // };
  }, []);

  useEffect(() => {
    socket.on('receiveMessage', (newMessage) => {
      setSelectContactMessageList((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      // socket.off('receiveMessage');
    };
  }, []);

  const handleGetNote = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getChatRoomNote?.status === 200) {
        setGetChatNote(res?.getChatRoomNote?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact?.waChatRoomId]);

  useEffect(() => {
    try {
      if (selectedContact) {
        handleGetNote();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, handleGetNote]);

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };

  const handleSendMessage = async (value, template) => {
    if (value === 'text' && (!messageInput || messageInput.trim() === '')) {
      return;
    }
    setMessageInput('');
    const tempMessageId = `${Date.now()}${selectedContact?.id}`;

    let newMessage = {};
    if (value === 'template') {
      newMessage = {
        type: 'template',
        templateId: template?.id,
        cts: moment().unix(),
        tempMessageId,
        status: 'sent',
        templateData: template?.templateData,
        dynamicData: template?.dynamicData,
      };
    } else {
      newMessage = {
        type: 'text',
        message: messageInput,
        cts: moment().unix(),
        tempMessageId,
        status: 'sent',
      };
    }
    setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
    try {
      const payload = {
        query: wabaQuery.sendMessages,
        variables: {
          name: selectedContact.name,
          phoneNumber: selectedContact.phoneNumber,
          tempMessageId,
          type: value === 'template' ? 'template' : 'text',
          waBusinessPhoneNumberId: props?.whatsAppPhoneNumberId,
        },
      };
      if (value === 'template') {
        payload.variables.templateId = selectedTemplate?.id;
        payload.variables.templateComponents = selectedTemplate?.dynamicData;
      }
      if (value === 'text') {
        payload.variables.message = messageInput;
      }
      const res = await handleWabaApiRequest(payload);
      if (res.sendMessages?.status === 200) {
        const updatedTempMessageId = res.sendMessages?.data?.tempMessageId;
        const messageId = res.sendMessages?.data?.messageId;
        setSelectContactMessageList((prevList) =>
          prevList.map((msg) =>
            msg.tempMessageId === updatedTempMessageId ? { ...msg, messageId } : msg
          )
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const createNote = async () => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.createChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
          note: noteValue,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.createChatRoomNote?.status === 200) {
        setNoteValue('');
        handleGetNote();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const deleteNote = async (id) => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.deleteChatRoomNote,
        variables: {
          noteId: id,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.deleteChatRoomNote?.status === 200) {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleGetNote();
      } else {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditClick = (note) => {
    setEditedNote(note.id);
    setEditedContent(note.note);
  };

  const handleTemplateSelect = (template) => {
    if (template) {
      setSelectedTemplate(template);

      handleSendMessage('template', template);
      setOpenTemplateListDialog(false);
    }
  };

  const handleSaveClick = async (note) => {
    try {
      if (note?.note !== editedContent) {
        const payload = {
          query: wabaQuery.editChatRoomNote,
          variables: {
            noteId: note?.id,
            note: editedContent,
          },
        };
        const res = await handleWabaApiRequest(payload);
        setLoading(false);
        if (res?.editChatRoomNote?.status === 200) {
          enqueueSnackbar(res?.editChatRoomNote?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          handleGetNote();
          setEditedNote(null);
          setEditedContent('');
        }
      } else {
        setEditedNote(null);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="bg-white rounded-md border-1 border-solid border-grey-300">
          <div className="flex h-screen chat-height">
            {/* Left Sidebar */}
            <div className="w-1/4 overflow-auto">
              <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16 relative">
                <div className="flex items-center justify-between">
                  <Select
                    className="inline-block border-none text-black capitalize min-h-0 h-auto p-0 font-semibold text-16"
                    id="msgType"
                    // value={parseInt(selectPlateForm, 10)}
                    size="small"
                    displayEmpty
                    IconComponent={() => (
                      <Icon className="inline-block text-black absolute top-0 right-0 text-20">
                        keyboard_arrow_down
                      </Icon>
                    )}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiSelect-select': {
                        padding: '0px 0',
                        display: 'flex',
                        alignItems: 'center',
                        border: 'none',
                      },
                    }}
                    // onChange={(e) => handlePlatformChange(e)}
                  >
                    <MenuItem className="capitalize">{t('waba.inbox.allChat')}</MenuItem>
                    <MenuItem className="capitalize">{t('waba.inbox.unreadChat')}</MenuItem>
                    <MenuItem className="capitalize">{t('waba.inbox.broadcast')}</MenuItem>
                  </Select>
                  <div
                    className={`transition-all duration-300 ease-in-out absolute  ${
                      isExpandedSearch ? 'left-16 right-16' : 'w-0'
                    } overflow-hidden`}
                  >
                    <input
                      ref={inputRef}
                      type="text"
                      className="px-10 py-2 border rounded-md w-full h-36 border-darkgreen"
                      placeholder={t('waba.inbox.searchPlaceholder')}
                      onChange={(event) => setSearchValue(event?.target?.value)}
                      value={searchValue}
                      style={{ display: isExpandedSearch ? 'block' : 'none' }}
                    />
                    <Icon
                      aria-hidden
                      className="absolute right-10 top-10 text-16 cursor-pointer"
                      onClick={() => {
                        setIsExpandedSearch(false);
                        setSearchValue('');
                      }}
                    >
                      {' '}
                      close{' '}
                    </Icon>
                  </div>
                  <div>
                    <Icon
                      aria-hidden
                      className="cursor-pointer"
                      onClick={() => setIsExpandedSearch(true)}
                    >
                      {' '}
                      search{' '}
                    </Icon>
                    <Icon className="ml-12">add</Icon>
                  </div>
                </div>
              </div>
              <div className="p-16">
                {getChatRoom && getChatRoom?.length > 0 ? (
                  <>
                    {getChatRoom?.map((contact, index) => (
                      <div
                        key={index}
                        className={`mb-10 rounded cursor-pointer hover:bg-darkgreen-100 border-1 border-solid  rounded-md p-10 ${
                          selectedContact?.id === contact?.id
                            ? 'bg-darkgreen-100 border-darkgreen-100 border-l-3 border-l-solid border-l-darkgreen'
                            : 'bg-white border-grey-300 pl-12'
                        }`}
                        aria-hidden
                        onClick={() => handleContactClick(contact)}
                      >
                        <div className="flex items-center">
                          <img
                            src="/assets/images/business/profile.png"
                            className="img-fluid w-full max-w-48 mr-10"
                            alt="..."
                          />

                          <div className="overflow-hidden">
                            <h3 className="text-16 font-semibold">
                              {' '}
                              {contact?.name || contact?.phoneNumber}
                            </h3>
                            <span className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis">
                              {contact?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <Typography className="text-center font-medium">
                    {t('waba.inbox.noResult')}
                  </Typography>
                )}
              </div>
            </div>

            {/* Middle Section */}
            <div
              className={`flex-grow relative ${
                selectedContact ? 'w-3/5' : 'w-4/5'
              } border-x-1 border-solid border-grey-300`}
            >
              {selectedContact ? (
                <>
                  <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16">
                    <div className="flex items-center">
                      <img
                        src="/assets/images/business/profile.png"
                        className="img-fluid w-full max-w-40 mr-10"
                        alt="..."
                      />

                      <div>
                        <h3 className="text-16 font-bold mb-4">
                          {selectedContact?.name || selectedContact?.phoneNumber}
                        </h3>
                        <span className="font-medium text-14">Last seen at 07:15 PM</span>
                      </div>
                    </div>
                  </div>
                  <div className="inbox-height flex flex-col bg-gray-A300 relative">
                    <div className="flex-grow overflow-auto p-20 pb-0">
                      {selectContactMessageList.map((message, index) => (
                        <div className="mb-12 block" key={index}>
                          <div
                            key={message.id}
                            className={`rounded-md mb-4 inline-block px-8 py-8 text-14 font-semibold bg-white ${
                              !message?.from
                                ? 'self-end text-right float-right '
                                : 'self-start text-left'
                            }`}
                          >
                            <div>
                              {message?.type === 'text'
                                ? message?.message
                                : message?.templateData &&
                                  JSON.parse(message?.templateData)?.components?.map(
                                    (templateValue, i) => (
                                      <div className="block max-w-320 text-left" key={i}>
                                        <div>
                                          {templateValue?.type === 'HEADER' && (
                                            <>
                                              {templateValue?.format === 'TEXT' && (
                                                <Typography>{templateValue?.text}</Typography>
                                              )}
                                              {templateValue?.format === 'IMAGE' && (
                                                <>
                                                  {JSON.parse(message?.dynamicData)?.header?.map(
                                                    (dynamicValue) => (
                                                      <>
                                                        {dynamicValue?.type === 'image' && (
                                                          <img
                                                            src={dynamicValue?.value}
                                                            className="mb-10"
                                                            alt="..."
                                                          />
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {templateValue?.type === 'BODY' && (
                                            <>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: templateValue?.text,
                                                }}
                                              />
                                            </>
                                          )}
                                          {templateValue?.type === 'FOOTER' && (
                                            <>
                                              <Typography className="mt-6 text-grey-700">
                                                {templateValue?.text}
                                              </Typography>
                                            </>
                                          )}
                                          {templateValue?.type === 'BUTTONS' &&
                                            templateValue?.buttons?.map((buttonValue, b) => (
                                              <div
                                                className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                                                key={b}
                                              >
                                                <a
                                                  className="font-bold text-darkgreen text-16"
                                                  href={`${
                                                    buttonValue?.phone_number
                                                      ? `tel:${buttonValue?.phone_number}`
                                                      : ''
                                                  }`}
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  role="button"
                                                >
                                                  <img
                                                    src="/assets/images/business/telephone.png"
                                                    className="w-14 h-14 align-middle mr-4 inline-block"
                                                    alt="..."
                                                  />
                                                  {buttonValue?.text}
                                                </a>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    )
                                  )}
                            </div>
                          </div>
                          <div
                            className={`text-12 text-gray-700 font-medium clear-both     ${
                              !message?.from ? 'text-right' : 'text-left'
                            }`}
                          >
                            {moment.unix(message.cts / 1000).format('h:MM A')}
                            {!message?.from && message.status === 'sent' && (
                              <Icon className="text-14">done</Icon>
                            )}
                            {!message?.from && message.status === 'delivered' && (
                              <DoneAllIcon className="text-14 ml-3" />
                            )}
                            {!message?.from && message.status === 'read' && (
                              <DoneAllIcon className="text-blue text-14 ml-3" />
                            )}
                          </div>
                        </div>
                      ))}
                      <div ref={chatScroll} className="h-32" />
                    </div>
                    <div className="mt-4 flex bg-white items-center px-20">
                      <input
                        type="text"
                        className="flex-grow p-2 border-none rounded-none h-60"
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        placeholder={t('waba.inbox.typeMessage')}
                        onKeyPress={handleKeyPress}
                      />
                      <div>
                        <span
                          aria-hidden
                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          className="bg-grey-100 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                        >
                          <Icon className="text-20">emoji_emotions</Icon>
                        </span>
                        {showEmojiPicker && (
                          <div className="absolute bottom-60 right-16">
                            <EmojiPicker
                              onEmojiClick={(emojiObject) => {
                                setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
                                setShowEmojiPicker(false);
                              }}
                            />
                          </div>
                        )}
                        <span
                          aria-hidden
                          onClick={() => setOpenTemplateListDialog(true)}
                          title="Template"
                          className="bg-grey-100 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                        >
                          <Icon className="text-20">text_snippet</Icon>
                        </span>

                        <Button
                          className="rounded-md md:px-24"
                          onClick={() => handleSendMessage('text')}
                          size="small"
                          variant="outlined"
                          color="secondary"
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="h-full flex items-center justify-center bg-gray-A300">
                  <div className="mx-auto text-center">
                    <img
                      src="/assets/images/business/conversation.png"
                      className="img-fluid mx-auto mb-48"
                      alt="..."
                    />
                    <Typography className="block text-24 font-semibold mb-12">
                      {t('waba.inbox.starAConversation')}
                    </Typography>
                    <Typography className="block font-medium text-16 text-grey-600 mb-28">
                      {t('waba.inbox.startDesc')}
                    </Typography>
                    <Button
                      className="rounded-md sm:px-68"
                      size="medium"
                      variant="contained"
                      color="secondary"
                    >
                      {t('waba.inbox.addNewContact')}
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {/* Right Sidebar */}
            {selectedContact && (
              <div className="w-1/5 p-16">
                <div>
                  <div className="border-b-1 border-solid border-grey-300 pb-16 pt-0 mb-16">
                    <div className="flex items-center">
                      <img
                        src="/assets/images/business/profile.png"
                        className="img-fluid w-full max-w-48 mr-8"
                        alt="..."
                      />

                      <div>
                        <h3 className="text-16 font-bold">
                          {selectedContact?.name || selectedContact?.phoneNumber}
                        </h3>
                        <span className="font-medium text-14 text-grey-800 capitalize">
                          {selectedContact?.status}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-grey-100 mt-12 rounded-md p-14">
                    <span className="text-16 font-semibold block mb-8">{t('waba.inbox.info')}</span>
                    <span className="font-medium text-12 text-grey-600 block">
                      {t('waba.inbox.phone')}
                    </span>
                    <Typography className="text-14 font-semibold">
                      {selectedContact?.phoneNumber}
                    </Typography>
                  </div>
                  <div className="flex items-center justify-between mt-20">
                    <span className="text-16 font-semibold block mb-8">
                      {t('waba.inbox.notes')}
                    </span>
                    <span className="bg-darkgreen w-24 h-24 rounded-md inline-block text-white text-center pt-4">
                      <Icon onClick={() => setIsExpandedNote(true)} aria-hidden className="text-16">
                        add
                      </Icon>
                    </span>
                  </div>
                  {!isExpandedNote && !getChatNote && (
                    <Typography className="text-12 font-medium text-grey-600">
                      {t('waba.inbox.noteInfo')}
                    </Typography>
                  )}
                  <input
                    // ref={inputRef}
                    type="text"
                    className="px-10 py-2 border rounded-md w-full h-36 border-darkgreen bg-darkgreen-100 mt-10"
                    onChange={(event) => setNoteValue(event?.target?.value)}
                    value={noteValue}
                    style={{ display: isExpandedNote ? 'block' : 'none' }}
                  />
                  {isExpandedNote && (
                    <div className="text-right mt-10">
                      <Icon
                        onClick={() => {
                          setIsExpandedNote(false);
                          setNoteValue('');
                        }}
                        aria-hidden
                        className="text-12 pt-3 w-20 h-20 text-red border-1 border-solid border-red rounded-md inline-block"
                      >
                        close
                      </Icon>
                      {noteValue && (
                        <Icon
                          onClick={() => createNote()}
                          className="text-12 pt-3 w-20 h-20 text-green border-1 border-solid border-green rounded-md inline-block ml-10 relative"
                        >
                          check
                          {loading && (
                            <CircularProgress
                              size={12}
                              className="text-darkgreen absolute mx-auto right-3"
                            />
                          )}
                        </Icon>
                      )}
                    </div>
                  )}
                  {getChatNote &&
                    getChatNote?.length > 0 &&
                    getChatNote?.map((note, index) => (
                      <div className="bg-darkgreen-100 p-12 rounded-md mb-12" key={index}>
                        {editedNote === note.id ? (
                          <input
                            type="text"
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                            className="w-full px-10 py-2 border rounded-md h-36 border-darkgreen bg-darkgreen-100"
                          />
                        ) : (
                          <span className="block">{note.note}</span>
                        )}
                        <span className="flex mt-12 items-center justify-between">
                          {moment(note?.uts).format('MMM DD, YYYY h:MM A')}
                          <span>
                            {editedNote === note.id ? (
                              <Icon
                                onClick={() => handleSaveClick(note)}
                                aria-hidden
                                className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block cursor-pointer"
                              >
                                save
                              </Icon>
                            ) : (
                              <>
                                <Icon
                                  onClick={() => handleEditClick(note)}
                                  aria-hidden
                                  className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block cursor-pointer"
                                >
                                  edit
                                </Icon>
                                <Icon
                                  onClick={() => deleteNote(note.id)}
                                  aria-hidden
                                  className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block ml-10"
                                >
                                  delete
                                </Icon>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {openTemplateListDialog && (
          <TemplateList
            openValue={openTemplateListDialog}
            cancelCall={() => setOpenTemplateListDialog(false)}
            popUpMessage={t('waba.inbox.chooseTemplate')}
            onTemplateSelect={handleTemplateSelect}
          />
        )}
      </div>
    </>
  );
};

export default WabaInbox;

import { TableCell, TableHead, TableRow } from '@mui/material';

const TableHeader = (props) => {
  const { headerData, remove, headerBackgroundColorValue } = props;
  return (
    <TableHead
      className={`${remove ? 'hidden md:table-header-group' : ''} ${
        headerBackgroundColorValue || 'bg-grey-50'
      } rounded-md`}
    >
      <TableRow>
        {headerData?.length &&
          headerData.map((header, i) => {
            return (
              <TableCell
                key={i}
                className={`${
                  remove ? 'pl-0' : ''
                } text-16 text-grey-600 font-semibold capitalize border-b-1 py-16 border-solid border-grey-500 rounded-md`}
                align="left"
              >
                {header}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

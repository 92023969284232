import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`pricingCatalogue/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getPackagesForAdmin = createAsyncThunkWithAuthorization(
  'getPackagesForAdmin',
  baseURL
);

export const getPricingCatalogueOnPackageId = createAsyncThunkWithAuthorization(
  'getPricingCatalogueOnPackageId',
  baseURL
);

export const addPricingCatalogueOnPackageId = createAsyncThunkWithAuthorization(
  'addPricingCatalogueOnPackageId',
  baseURL
);

export const getUserDetails = createAsyncThunkWithAuthorization('getUserDetails', baseURL);
export const updateQuickReviewContactStatus = createAsyncThunkWithAuthorization(
  'updateQuickReviewContactStatus',
  baseURL
);

export const updatePackagesStatus = createAsyncThunkWithAuthorization(
  'updatePackagesStatus',
  baseURL
);

const pricingCatalogueSlice = createSlice({
  name: 'pricingCatalogue',
  initialState: {
    pricingCatalogueDialog: {
      type: 'new',
      props: {
        open: false,
      },
    },
  },
  reducers: {
    openPricingCatalogueDialog: (state, action) => {
      state.pricingCatalogueDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closePricingCatalogueDialog: (state, action) => {
      state.pricingCatalogueDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditPricingCatalogueDialog: (state, action) => {
      state.pricingCatalogueDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeEditPricingCatalogueDialog: (state, action) => {
      state.pricingCatalogueDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    reSetPricingCatalogue: (state, action) => {
      if (state) {
        state.getPackagesForAdminData = {};
        state.updateQuickReviewContactStatusData = {};
        state.getPricingCatalogueOnPackageIdData = {};
        state.addPricingCatalogueOnPackageIdData = {};
        state.updatePackagesStatusData = {};
      }
    },
  },
  extraReducers: {
    [getPackagesForAdmin.fulfilled]: (state, action) => ({
      ...state,
      getPackagesForAdminData: action.payload,
    }),
    [getPricingCatalogueOnPackageId.fulfilled]: (state, action) => ({
      ...state,
      getPricingCatalogueOnPackageIdData: action.payload,
    }),
    [addPricingCatalogueOnPackageId.fulfilled]: (state, action) => ({
      ...state,
      addPricingCatalogueOnPackageIdData: action.payload,
    }),
    [updatePackagesStatus.fulfilled]: (state, action) => ({
      ...state,
      updatePackagesStatusData: action.payload,
    }),
  },
});

export const {
  reSetPricingCatalogue,
  openPricingCatalogueDialog,
  closePricingCatalogueDialog,
  openEditPricingCatalogueDialog,
  closeEditPricingCatalogueDialog,
} = pricingCatalogueSlice.actions;

export default pricingCatalogueSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../services/userService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
});

const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`forgotPassword/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const sendForgotPasswordLink = createAsyncThunkWithAuthorization(
  'sendForgotPasswordLink',
  '/forgotPassword/sendForgotPasswordLink'
);

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: null,
  reducers: {
    forgotPasswordAction: (state, action) => {
      if (state) {
        state.sendForgotPasswordLinkData = {};
      }
    },
  },

  extraReducers: {
    [sendForgotPasswordLink.fulfilled]: (state, action) => ({
      ...state,
      sendForgotPasswordLinkData: action.payload,
    }),
  },
});

export const { forgotPasswordAction } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

const getPackagesForAdmin = `
   query{
    getPackagesForAdmin{ 
          message
          status
          data
     }
   }
`;

const getPricingCatalogueOnPackageId = `
   query($isActive: Boolean, $sortBy: String, $sortOrder:String, $packageId: Int!){
    getPricingCatalogueOnPackageId(isActive: $isActive, sortBy: $sortBy sortOrder: $sortOrder packageId: $packageId){ 
          message
          status
          data
     }
   }
`;

const addPricingCatalogueOnPackageId = `
   mutation($addMoreCurrency: AddMoreCurrency, $currency: String!, $isAddOn: Boolean, $isDefault: Boolean!, $packageId: Int!, $packageInterval: String!, $price: Int!, $productId: String!){
    addPricingCatalogueOnPackageId(addMoreCurrency: $addMoreCurrency, currency: $currency, isAddOn: $isAddOn, isDefault: $isDefault, packageId: $packageId, packageInterval: $packageInterval, price: $price, productId: $productId){ 
          message
          status
     }
   }
`;

const updatePackagesStatus = `
mutation($packageId: Int!, $isActive: Boolean!) {
   updatePackagesStatus(packageId: $packageId , isActive: $isActive){
     status
     message
   }
 }
 `;

const pricingCatalogueData = {
  getPackagesForAdmin,
  getPricingCatalogueOnPackageId,
  addPricingCatalogueOnPackageId,
  updatePackagesStatus,
};

export default pricingCatalogueData;

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { json, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import axios from 'axios';
import './QuickChatEditor.css';
import { t } from 'i18next';
import quickChatQuery from '../query/quickChat';
import { handleApiRequest } from '../common/common';

function QuickChat(props) {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const chatScroll = useRef(null);
  const [messageText, setMessageText] = useState('');
  const [chatInfo, setChatInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(true);
  const [tags, setTags] = useState([]);
  const [displayMenu, setDisplayMenu] = useState(false);

  const currentTime = moment().format('h:mm A');

  useEffect(() => {
    chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const intialSetData = useCallback(() => {
    setTimeout(() => {
      setMessages([
        {
          text: `${t('quickChat.createBot.defaultMessage')}`,
          sender: 'bot',
          time: moment().format('h:mm A'),
        },
        {
          text: `${t('quickChat.createBot.defaultMessage2')}`,
          sender: 'bot',
          time: moment().format('h:mm A'),
        },
      ]);
      setIsBotTyping(false);
      setDisplayMenu(true);
    }, 2000);
  }, []);
  useEffect(() => {
    intialSetData();
  }, [intialSetData]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      const payloadData = {
        query: quickChatQuery.getQuickChat,
        variables: {
          domain: `${routeParams?.location}/${routeParams?.business}`,
        },
      };
      const resultData = await handleApiRequest(payloadData);

      if (resultData?.getQuickChat?.status === 200) {
        setChatInfo(resultData?.getQuickChat?.data);
        if (resultData?.getQuickChat?.data) {
          setTags(JSON?.parse(resultData?.getQuickChat?.data?.quickChatSuggestion));
        }
      }
    };
    fetchAgentSupportStatus();
  }, [routeParams]);

  const sendMessage = async (e) => {
    const message = messageText || e;
    const data = JSON.stringify({
      user_business_id: chatInfo?.userBusinessesId,
      question: message,
    });

    if (message?.trim()) {
      const newMessages = [...messages, { text: message, sender: 'user', time: currentTime }];
      setMessages(newMessages);
      setIsBotTyping(true);
      setMessageText('');

      const config = {
        url: process.env.REACT_APP_AI_QUICK_CHAT,
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };

      try {
        const response = await axios.request(config);

        const botReply = response?.data?.data;
        setIsBotTyping(false);
        setMessages([...newMessages, { text: botReply?.answer, sender: 'bot', time: currentTime }]);
      } catch (error) {
        console.error(error);
        setIsBotTyping(false);
      }
    }
  };
  const onMessageSubmit = async (e) => {
    e.preventDefault();
    sendMessage();
    setDisplayMenu(false);
  };

  const handleTagClick = async (tag) => {
    setMessageText(tag);
    sendMessage(tag);
    setDisplayMenu(false);
  };

  const closeWindow = () => {
    window.open('about:blank', '_self');
    window.close();
  };
  return (
    <>
      {chatInfo && chatInfo !== null ? (
        <>
          {chatInfo?.headerColor && (
            <AppBar
              className={`shadow-md sticky mb-48 bg-[${chatInfo?.headerColor}]`}
              style={{ backgroundColor: chatInfo?.headerColor }}
            >
              <Toolbar className="px-4">
                <div className="flex flex-1 items-center px-12">
                  <Avatar
                    src={chatInfo?.avatar}
                    className="bg-white md:p-10 p-5 md:w-48 md:h-48 w-28 h-28"
                  />
                  <span className="w-10 h-10 bg-green inline-block rounded-full -ml-8 z-10 mt-20" />
                  <Typography className="mx-16 text-16" color="inherit">
                    {chatInfo?.name}
                  </Typography>
                </div>

                <div className="flex px-4">
                  <IconButton onClick={() => closeWindow()} color="inherit" size="large">
                    <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          )}

          <div className="mb-76 px-12">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`block max-w-[1050px] relative mx-auto ${
                  msg.sender === 'bot' ? 'text-left' : `text-right`
                }`}
              >
                {msg.sender === 'bot' && (
                  <Avatar
                    src={chatInfo?.avatar}
                    className="float-left md:mr-28 mr-12 md:w-60 md:h-60 w-28 h-28 bg-darkgreen-100 md:p-10 p-5"
                  />
                )}
                <div className="sm:max-w-480 max-w-256 inline-block">
                  <div
                    key={index}
                    className={`transition ease-in-out delay-150 md:p-16 p-10 my-5 mx-auto rounded-2xl message md:text-16 font-semibold ${
                      msg.sender === 'bot'
                        ? 'bg-darkgreen-100 text-left rounded-tl-none'
                        : `bg-[${chatInfo?.headerColor}] text-right text-white rounded-tr-none`
                    } `}
                    style={{
                      backgroundColor:
                        msg.sender === 'bot' ? 'darkgreen-100' : chatInfo?.headerColor,
                    }}
                    dangerouslySetInnerHTML={{ __html: msg.text }}
                  />
                  <Typography
                    className="time w-full text-12 font-medium text-grey-700 mt-8 whitespace-nowrap"
                    color="text.secondary"
                  >
                    {msg?.time}
                  </Typography>
                </div>
              </div>
            ))}
            {displayMenu && (
              <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 max-w-[1050px] md:pl-80 pl-36">
                {tags?.map((tag, i) => (
                  <div
                    key={i}
                    className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                    aria-hidden="true"
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}
            {isBotTyping && (
              <div className="block max-w-[1050px] relative mx-auto text-left">
                <Avatar
                  src={chatInfo?.avatar}
                  className="float-left md:mr-28 mr-12 md:w-60 md:h-60 w-28 h-28 bg-darkgreen-100 md:p-10 p-5"
                />
                <div className="max-w-480 inline-block">
                  <div className="py-16 my-5 mx-auto rounded-2xl message md:text-16 font-semibold bg-white text-left rounded-tl-none">
                    <div className="typing-indicator">
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={chatScroll} className="h-20" />
          </div>

          <form
            onSubmit={onMessageSubmit}
            className="pb-16 px-8 bg-white bottom-0 fixed left-0 right-0 mx-auto max-w-[1050px] pt-24"
          >
            <Paper className="rounded-16 flex items-center relative shadow-none border-1 border-solid border-grey-500">
              <InputBase
                autoFocus={false}
                id="message-input"
                size="small"
                className="flex flex-1 grow shrink-0 px-16"
                placeholder="Ask Me anything"
                onChange={(e) => setMessageText(e.target.value)}
                value={messageText}
              />
              <IconButton className="absolute right-10 top-3" type="submit" size="small">
                <FuseSvgIcon className="rotate-90" color="action">
                  heroicons-outline:paper-airplane
                </FuseSvgIcon>
              </IconButton>
            </Paper>
          </form>
        </>
      ) : (
        <div className="h-screen flex items-center mx-10">
          <div className="bg-darkgreen-100 max-w-screen-lg	lg:py-60 py-20 lg:px-96 px-20 text-center mx-auto ">
            <div>
              <img
                className="m-auto max-w-96"
                src="/assets/images/business/invalid_chat.png"
                alt=""
              />
              <h3 className="sm:text-28 text-24 font-bold mb-16 mt-20 text-darkgreen">
                {t('quickChat.invalidLink')}
              </h3>
              <Typography className="sm:text-16 font-semibold mx-auto max-w-screen-md lg:px-96">
                {t('quickChat.invalidDesc')}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default QuickChat;

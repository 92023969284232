import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serverApiRequest } from '../../../common/common';
import userService from '../../../../services/userService';

const processEndPoint = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL: processEndPoint,
});

const handleApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToServer = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.image) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.image"]}`);
    formData.append(0, data?.variables?.image);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`promotion/${name}`, async (data) => {
    return serverApiRequest(endpoint, data);
  });
};

const createAsyncThunkWithAuthorizationForImage = (name, endpoint) => {
  return createAsyncThunk(`promotion/${name}`, async (data) => {
    return imageDataToServer(endpoint, data);
  });
};

export const getPromotions = createAsyncThunkWithAuthorization('getPromotions', processEndPoint);
export const getPromotion = createAsyncThunkWithAuthorization('getPromotion', processEndPoint);
export const getBusinessBasedOnUserId = createAsyncThunkWithAuthorization(
  'getBusinessBasedOnUserId',
  processEndPoint
);
export const getPromotionApplicableForType = createAsyncThunkWithAuthorization(
  'getPromotionApplicableForType',
  processEndPoint
);
export const getPromotionButtonType = createAsyncThunkWithAuthorization(
  'getPromotionButtonType',
  processEndPoint
);
export const getPromotionType = createAsyncThunkWithAuthorization(
  'getPromotionButtonType',
  processEndPoint
);

export const getPromotionAndRedeemPreviewImages = createAsyncThunkWithAuthorization(
  'getPromotionAndRedeemPreviewImages',
  processEndPoint
);

export const createPromotion = createAsyncThunkWithAuthorizationForImage(
  'createPromotion',
  processEndPoint
);

export const getLoyaltyPointsType = createAsyncThunkWithAuthorization(
  'getLoyaltyPointsType',
  processEndPoint
);

export const getPromotionLoyaltyDurationType = createAsyncThunkWithAuthorization(
  'getPromotionLoyaltyDurationType',
  processEndPoint
);

export const getRedeemPromotionsList = createAsyncThunkWithAuthorization(
  'getRedeemPromotionsList',
  processEndPoint
);

export const createRedeemPromotion = createAsyncThunkWithAuthorizationForImage(
  'createRedeemPromotion',
  processEndPoint
);

export const updateRedeemPromotion = createAsyncThunkWithAuthorization(
  'updateRedeemPromotion',
  processEndPoint
);

export const getPromotionApplyTo = createAsyncThunkWithAuthorization(
  'getPromotionApplyTo',
  processEndPoint
);

export const deletePromotion = createAsyncThunkWithAuthorization(
  'deletePromotion',
  processEndPoint
);

const promotionSlice = createSlice({
  name: 'promotion',
  initialState: {},
  reducers: {
    reSetPromotion: (state, action) => {
      if (state) {
        state.getPromotionsData = {};
        state.getBusinessBasedOnUserIdData = {};
        state.getPromotionApplicableForTypeData = {};
        state.getPromotionButtonTypeData = {};
        state.getPromotionTypeData = {};
        state.getPromotionAndRedeemPreviewImagesData = {};
        state.createPromotionData = {};
        state.getLoyaltyPointsTypeData = {};
        state.getPromotionLoyaltyDurationTypeData = {};
        state.getRedeemPromotionsListData = {};
        state.createRedeemPromotionData = {};
        state.updateRedeemPromotionData = {};
        state.getPromotionApplyToData = {};
        state.deletePromotionData = {};
        state.getPromotionData = {};
      }
    },
  },
  extraReducers: {
    [getPromotions.fulfilled]: (state, action) => ({
      ...state,
      getPromotionsData: action.payload,
      getBusinessBasedOnUserIdData: action.payload,
      getPromotionApplicableForTypeData: action.payload,
      getPromotionButtonTypeData: action.payload,
      getPromotionTypeData: action.payload,
      getPromotionAndRedeemPreviewImagesData: action.payload,
      createPromotionData: action.payload,
      getLoyaltyPointsTypeData: action.payload,
      getPromotionLoyaltyDurationTypeData: action.payload,
      getRedeemPromotionsListData: action.payload,
      createRedeemPromotion: action.payload,
      updateRedeemPromotionData: action.payload,
      getPromotionApplyToData: action.payload,
      deletePromotionData: action.payload,
      getPromotionData: action.payload,
    }),
  },
});

export const { reSetPromotion } = promotionSlice.actions;

export default promotionSlice.reducer;

import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import * as yup from 'yup';
import _ from '@lodash';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import Loading from './Loading';
import './MangeBusiness.css';
import manageBusinessData from '../../query/manageBusiness';
import reducer from './store';
import { handleApiRequest } from '../../common/common';

const CustomAttribute = (props) => {
  const { setActiveStepNumber } = props;
  const { t } = useTranslation();
  const [businessAttribute, setBusinessAttribute] = useState([]);
  const [businessCustomAttribute, setBusinessCustomAttribute] = useState([]);
  const [ratingFormateData, setRatingFormateData] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [ratingFormateValue, selectRatingFormateValue] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [bAttribute, setBAttribute] = useState();
  const [userCustomBusinessAttributeId, setUserBusinessAttributeId] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(false);
  const [userBusinessDetail, setUserBusinessDetail] = useState({});
  const fetchBusinessCustomAttribute = useCallback(async () => {
    const payloadCustom = {
      query: manageBusinessData.getBusinessOwnerCustomAttributes,
      variables: {
        businessId: parseInt(userBusinessDetail?.id, 10),
      },
    };
    const resultCustom = await handleApiRequest(payloadCustom);
    if (resultCustom?.getBusinessOwnerCustomAttributes?.status === 200) {
      setBusinessCustomAttribute(resultCustom?.getBusinessOwnerCustomAttributes?.data);
    }
  }, [userBusinessDetail?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.getUserBusinessDetail?.status === 200) {
          const data = result.getUserBusinessDetail?.data;
          if (data && data.length > 0) {
            const lastIndex = data.length - 1;
            localStorage.setItem('businessId', data[lastIndex].id);
            setUserBusinessDetail(data[lastIndex]);
          }
        }
      } catch (error) {
        console.error('Error fetching business details:', error);
        setPageLoading(false);
      }
    };
    fetchData();
  }, [setUserBusinessDetail]);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessAttributesOnId,
        variables: {
          businessId: parseInt(userBusinessDetail?.id, 10),

          businessTypeId: parseInt(userBusinessDetail?.businessTypeId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAttributesOnId?.status === 200) {
        setBusinessAttribute(result?.getBusinessAttributesOnId?.data);
      }
    };
    if (userBusinessDetail?.id) {
      fetchData();
      fetchBusinessCustomAttribute();
    }
  }, [userBusinessDetail?.id, userBusinessDetail?.businessTypeId, fetchBusinessCustomAttribute]);

  useEffect(() => {
    setPageLoading(true);
    const fetchData = async () => {
      const payload = {
        query: manageBusinessData.getBusinessAttributeValue,
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      setRatingFormateData(result?.getBusinessAttributeValue?.data);
    };
    fetchData();
  }, []);

  const handleRemove = () => {
    selectRatingFormateValue(0);
    setOpenDialogue(false);
    setEdit(false);
    setBAttribute('');
    setIsEmpty(false);
    setEditData();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value?.length !== 21) {
      setBAttribute(value);
      setIsEmpty(value.trim() === '');
    }
  };

  const editBusinessAttribute = (value) => {
    setOpenDialogue(true);
    setEdit(true);
    setBAttribute(value?.bAttribute);
    selectRatingFormateValue(value?.businessAttributeValueId);
    setUserBusinessAttributeId(value?.id);
    setEditData(value);
  };

  const onSubmit = async (submitData) => {
    setRefreshIcon(true);
    const payload = {
      query: edit
        ? manageBusinessData.editCustomBusinessAttribute
        : manageBusinessData.addCustomBusinessAttribute,
      variables: {
        bAttribute,
        businessId: parseInt(userBusinessDetail?.id, 10),
        businessAttributeValueId: parseInt(ratingFormateValue, 10),
        isActive: false,
        ...(edit && {
          userCustomBusinessAttributeId: parseInt(userCustomBusinessAttributeId, 10),
        }),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const res = edit ? result?.editCustomBusinessAttribute : result?.addCustomBusinessAttribute;
    if (res?.status === 200) {
      handleRemove();
      setUserBusinessAttributeId();
      fetchBusinessCustomAttribute();
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  if (pageLoading) {
    return <Loading />;
  }

  const saveAndNext = async () => {
    setRefreshIcon(true);
    const customAttributeData = businessCustomAttribute?.map((ele) => {
      return {
        userCustomBusinessAttributeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const defaultAttributeData = businessAttribute?.map((ele) => {
      return {
        businessTypeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const payload = {
      query: manageBusinessData.updateCustomBusinessAttributeWithDefaultBusinessAttribute,
      variables: {
        customBusinessAttribute: customAttributeData,
        defaultAttribute: defaultAttributeData,
        businessId: parseInt(userBusinessDetail?.id, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.status === 200) {
      localStorage.setItem('activeStepNumber', 3);
      setActiveStepNumber(3);
    } else {
      enqueueSnackbar(result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const enableDisableCustomAttribute = (value) => {
    const data = businessCustomAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessCustomAttribute(data);
  };

  const enableDisableDefaultAttribute = (value) => {
    const data = businessAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessAttribute(data);
  };

  return (
    <div className="text-left">
      <h3 className="text-24 font-bold mb-12">
        {t('manageBusiness.addBusinessSteps.customAttribute.pageTitle')}
      </h3>
      <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
        {t('manageBusiness.addBusinessSteps.customAttribute.pageDesc')}
      </Typography>
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-36 mx-auto mt-24">
        {businessAttribute?.length > 0 && (
          <div className="bg-grey-50 px-20 py-16 rounded-md">
            <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
              {t('manageBusiness.addBusinessSteps.customAttribute.defaultAttribute')}
            </h4>
            {businessAttribute?.map((item, index) => {
              return (
                <div
                  className="my-14 items-center rounded-sm mx-auto md:py-2 px-10 py-6 bg-white h-44"
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <span className="block md:text-16 text-14 font-semibold">
                      {item?.bAttribute}
                    </span>
                    <Switch
                      checked={item?.isActive}
                      onChange={() => enableDisableDefaultAttribute(item)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="bg-grey-50 px-20 py-16 rounded-md">
          <div className="flex justify-between border-b-1 border-solid border-grey pb-14 mb-20">
            <h4 className="text-20 font-semibold ">
              {t('manageBusiness.addBusinessSteps.customAttribute.customAttribute')}
            </h4>
            {businessCustomAttribute?.length > 0 && (
              <Button
                variant="contained"
                color="secondary"
                className="md:text-14 font-medium disabled:text-black rounded-full px-28 min-h-28 py-0"
                aria-label="add"
                size="small"
                onClick={() => setOpenDialogue(true)}
                type="button"
              >
                {t('manageBusiness.addBusinessSteps.productServices.businessProduct.LAdd')}
              </Button>
            )}
          </div>
          {businessCustomAttribute?.length > 0 ? (
            <>
              {businessCustomAttribute?.map((item, index) => {
                return (
                  <div
                    className="my-14 items-center rounded-sm mx-auto flex items-center"
                    key={index}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        className="md:text-16 font-medium disabled:text-black rounded-sm px-0 bg-white mr-16 min-h-44 w-44 min-w-44"
                        aria-label="edit"
                        onClick={() => editBusinessAttribute(item)}
                        type="button"
                      >
                        <Icon>edit</Icon>
                      </Button>
                    </div>
                    <div className="flex items-center justify-between md:py-2 px-10 py-6 bg-white h-44 w-full">
                      <span className="block md:text-16 text-14 font-semibold capitalize">
                        {item?.bAttribute}
                      </span>
                      <Switch
                        checked={item?.isActive}
                        onChange={() => enableDisableCustomAttribute(item)}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="text-center md:pt-88 pt-48">
                <h5 className="text-20 font-semibold mb-20">
                  {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeTitle')}
                </h5>
                <Typography className="text-14 font-medium mb-20 max-w-384 mx-auto">
                  {t('manageBusiness.addBusinessSteps.customAttribute.AddAttributeDesc')}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 font-medium disabled:text-black rounded-full px-28"
                  aria-label="add"
                  onClick={() => setOpenDialogue(true)}
                  type="button"
                >
                  <Icon>add</Icon>
                  {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeButton')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {[...businessAttribute, ...businessCustomAttribute].filter((obj) => obj.isActive === true)
        .length > 5 && (
        <Typography className="text-red md:text-right font-medium mt-44">
          {t('manageBusiness.addBusinessSteps.customAttribute.customAttributeLimitExist')}
        </Typography>
      )}
      <div className="text-right mt-16 md:mb-96 mb-36">
        <Button
          variant="outlined"
          color="secondary"
          className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
          aria-label="skip"
          onClick={() => {
            localStorage.setItem('activeStepNumber', 3);
            setActiveStepNumber(3);
          }}
          type="button"
        >
          {t('manageBusiness.button.skip')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
          aria-label="confirm"
          disabled={
            refreshIcon ||
            (businessCustomAttribute?.length === 0 &&
              businessAttribute.every((item) => item.isActive === true))
          }
          onClick={() => saveAndNext()}
          type="button"
        >
          {t('manageBusiness.addBusinessSteps.customAttribute.saveAndNext')}
          {refreshIcon && (
            <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
          )}
        </Button>
      </div>
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {!edit
              ? t('manageBusiness.addBusinessSteps.customAttribute.dialog.add')
              : t('manageBusiness.addBusinessSteps.customAttribute.dialog.edit')}{' '}
            {t('manageBusiness.addBusinessSteps.customAttribute.dialog.title')}
          </Typography>
        </DialogTitle>

        <form noValidate className="flex flex-col md:overflow-hidden">
          <DialogContent className="p-0 mb-24">
            <div className="block mb-24">
              <InputLabel
                htmlFor="bAttribute"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
              >
                {t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.name'
                )}
                <span>{bAttribute?.length || 0}/20</span>
              </InputLabel>
              <TextField
                placeholder={t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                )}
                onChange={handleChange}
                value={bAttribute}
                size="small"
                id="bAttribute"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty && (
                <Typography className="text-red pt-8 text-14">
                  {t(
                    'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                  )}
                </Typography>
              )}
            </div>
            <div className="block">
              <InputLabel htmlFor="bAttribute" className="text-16 font-medium mb-12 text-grey-900">
                {t(
                  'manageBusiness.addBusinessSteps.customAttribute.dialog.form.selectRatingFormat.name'
                )}
              </InputLabel>
              <Select
                className="w-full"
                id="ratingFormate"
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                size="small"
                value={ratingFormateValue}
                displayEmpty
                onChange={(e) => {
                  selectRatingFormateValue(e?.target?.value);
                }}
              >
                <MenuItem value={0} disabled>
                  {t('business.businessAttribute.formField.select')}
                </MenuItem>
                {ratingFormateData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
              variant="contained"
              color="secondary"
              type="submit"
              size="small"
              disabled={
                ratingFormateValue === 0 ||
                refreshIcon ||
                !bAttribute ||
                (editData?.bAttribute === bAttribute &&
                  parseInt(editData?.businessAttributeValueId, 10) ===
                    parseInt(ratingFormateValue, 10))
              }
              onClick={() => onSubmit()}
            >
              {t('manageBusiness.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withReducer('manageBusinessReducer', reducer)(CustomAttribute);

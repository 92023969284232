import AppPromotionAndSlider from './AppPromotionSlider';

const appPromotionSliderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'app-promotion-slider',
      element: <AppPromotionAndSlider />,
    },
  ],
};

export default appPromotionSliderConfig;

import UserList from './UserList';
import UserDetails from './UserDetails';

const UsersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'user-list',
      element: <UserList />,
    },
    {
      path: 'user-details/:userId',
      element: <UserDetails />,
    },
  ],
};

export default UsersConfig;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`userFeedback/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getAllUserFeedback = createAsyncThunkWithAuthorization('getAllUserFeedback', baseURL);

const userFeedbackSlice = createSlice({
  name: 'userFeedback',
  initialState: null,
  reducers: {
    reSetUsers: (state, action) => {
      if (state) {
        state.getAllUserFeedbackData = {};
      }
    },
  },
  extraReducers: {
    [getAllUserFeedback.fulfilled]: (state, action) => ({
      ...state,
      getAllUserFeedbackData: action.payload,
    }),
  },
});

export const { reSetUsers } = userFeedbackSlice.actions;

export default userFeedbackSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const processEndPoint = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL: processEndPoint,
});

const handleApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`plan/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

export const getPackages = createAsyncThunkWithAuthorization('getPackages', processEndPoint);

export const getPlansAndFeaturesData = createAsyncThunkWithAuthorization(
  'getPlansAndFeaturesData',
  processEndPoint
);

export const getLocation = createAsyncThunkWithAuthorization('getLocation', processEndPoint);

export const getUserSubscriptionPackage = createAsyncThunkWithAuthorization(
  'getUserSubscriptionPackage',
  processEndPoint
);

export const getPaymentHistory = createAsyncThunkWithAuthorization(
  'getPaymentHistory',
  processEndPoint
);

export const getInvoiceHistoryForRazorpay = createAsyncThunkWithAuthorization(
  'getInvoiceHistoryForRazorpay',
  processEndPoint
);

export const getPaymentInvoice = createAsyncThunkWithAuthorization(
  'getPaymentInvoice',
  processEndPoint
);

export const saveQuickReviewContactUsData = createAsyncThunkWithAuthorization(
  'saveQuickReviewContactUsData',
  processEndPoint
);

export const redirectPortalForSubscription = createAsyncThunkWithAuthorization(
  'redirectPortalForSubscription',
  processEndPoint
);

export const payUpdate = createAsyncThunkWithAuthorization('payUpdate', processEndPoint);

export const cancelSubscription = createAsyncThunkWithAuthorization(
  'cancelSubscription',
  processEndPoint
);

export const cancelSubscriptionForRazorpay = createAsyncThunkWithAuthorization(
  'cancelSubscriptionForRazorpay',
  processEndPoint
);

const planSlice = createSlice({
  name: 'plan',
  initialState: {
    contactDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
    cancelPlanDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openContactDialog: (state, action) => {
      state.contactDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeContactDialog: (state, action) => {
      state.contactDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    reSetPlan: (state, action) => {
      if (state) {
        state.getPackagesData = {};
        state.getPlansAndFeaturesDataValue = {};
        state.getUserSubscriptionPackageData = {};
        state.getPaymentHistoryData = {};
        state.saveQuickReviewContactUsDataDetail = {};
        state.cancelSubscriptionData = {};
        state.getPaymentInvoiceData = {};
        state.getInvoiceHistoryForRazorpayData = {};
      }
    },
  },
  extraReducers: {
    [getPackages.fulfilled]: (state, action) => ({
      ...state,
      getPackagesData: action.payload,
    }),
    [getPlansAndFeaturesData.fulfilled]: (state, action) => ({
      ...state,
      getPlansAndFeaturesDataValue: action.payload,
    }),
    [getUserSubscriptionPackage.fulfilled]: (state, action) => ({
      ...state,
      getUserSubscriptionPackageData: action.payload,
    }),
    [getPaymentHistory.fulfilled]: (state, action) => ({
      ...state,
      getPaymentHistoryData: action.payload,
    }),
    [getPaymentInvoice.fulfilled]: (state, action) => ({
      ...state,
      getPaymentInvoiceData: action.payload,
    }),
    [saveQuickReviewContactUsData.fulfilled]: (state, action) => ({
      ...state,
      saveQuickReviewContactUsDataDetail: action.payload,
    }),
    [redirectPortalForSubscription.fulfilled]: (state, action) => ({
      ...state,
      redirectPortalForSubscriptionData: action.payload,
    }),
    [cancelSubscription.fulfilled]: (state, action) => ({
      ...state,
      cancelSubscriptionData: action.payload,
    }),
    [cancelSubscriptionForRazorpay.fulfilled]: (state, action) => ({
      ...state,
      cancelSubscriptionForRazorpayData: action.payload,
    }),
    [getInvoiceHistoryForRazorpay.fulfilled]: (state, action) => ({
      ...state,
      getInvoiceHistoryForRazorpayData: action.payload,
    }),
  },
});

export const { reSetPlan, openContactDialog, closeContactDialog } = planSlice.actions;

export default planSlice.reducer;

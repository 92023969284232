import PricingCatalogue from './PricingCatalogue';
import PricingCatalogueDetails from './PricingCatalogueDetails';

const PricingCatalogueConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'pricing-catalogue',
      element: <PricingCatalogue />,
    },
    {
      path: 'pricing-catalogue-detail/:planName/:planId',
      element: <PricingCatalogueDetails />,
    },
  ],
};

export default PricingCatalogueConfig;

import Promotion from './Promotion';
import PromotionDetails from './PromotionDetails';
import Redeem from './Redeem';
import NewPromotion from './new-promotion/NewPromotion';
import NewRedeem from './new-redeem/NewRedeem';

const promotionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'promotion/:id?',
      element: <Promotion />,
    },
    {
      path: 'new-promotion/:id?',
      element: <NewPromotion />,
    },
    {
      path: 'redeem/:id?',
      element: <Redeem />,
    },
    {
      path: 'new-redeem/:id?',
      element: <NewRedeem />,
    },
    {
      path: 'promotions/:promotionId/:id?',
      element: <PromotionDetails />,
    },
  ],
};

export default promotionConfig;

import FuseDialog from '@fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import SettingsPanel from '../shared-components/SettingsPanel';
import adminData from '../../main/query/admin';
import { getDecryptData, getParamsId, handleApiRequest } from '../../main/common/common';
import userService from '../../services';
import planQuery from '../../main/query/plan';
import {
  getUserPackageData,
  selectDashboardData,
  setBusinessType,
  setCountries,
  setReviewPlateForm,
  setUserSubscriptionPackage,
} from '../../store/userSlice';
import queryData from '../../main/query/common';

const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

function Layout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const appContext = useContext(AppContext);
  const [isAppMaintenance, setIsAppMaintenance] = useState();
  const { routes } = appContext;
  const routesComponent = useRoutes(routes);
  const location = useLocation();
  const [isBusinessAvailable, setIsBusinessAvailable] = useState(false);
  const loginUserData = userService.getUserData();
  const [pageLoading, setPageLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const getUserSubscriptionPackageDetails = useSelector(getUserPackageData);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const id = getParamsId();
  const dispatch = useDispatch();
  const fetchIsAppMaintenance = async () => {
    const payload = {
      query: adminData.getAppMaintenanceStatus,
    };
    const result = await handleApiRequest(payload);
    if (result?.getAppMaintenanceStatus?.status === 200) {
      setIsAppMaintenance(result.getAppMaintenanceStatus?.data?.isAppUnderMaintenance);
    }
  };

  useEffect(() => {
    const fetchBusinessType = async () => {
      const payload = {
        query: queryData.getBusinessType,
        ...(userCurrentBusinessDetails?.isOnlineBusiness && {
          variables: {
            isOnlineBusiness: true,
          },
        }),
      };
      const res = await handleApiRequest(payload);
      if (res?.getBusinessType?.status === 200) {
        dispatch(setBusinessType(res?.getBusinessType?.data));
      }
    };
    fetchBusinessType();
  }, [dispatch, userCurrentBusinessDetails?.isOnlineBusiness]);

  useEffect(() => {
    const fetchCountries = async () => {
      const payload = {
        query: queryData.getCountries,
      };
      try {
        const res = await handleApiRequest(payload);
        if (res?.getCountries?.status === 200) {
          dispatch(setCountries(res?.getCountries?.data));
        }
      } catch (error) {
        console.error('Failed to fetch countries:', error);
      }
    };
    fetchCountries();
  }, [dispatch]);

  useEffect(() => {
    const fetchReviewPlateForm = async () => {
      const payload = {
        query: manageBusinessData.getReviewPlatforms,
      };
      try {
        const result = await handleApiRequest(payload);
        if (result.getReviewPlatforms?.status === 200) {
          dispatch(setReviewPlateForm(result?.getReviewPlatforms?.data));
        }
      } catch (error) {
        console.error('Failed to fetch review platforms:', error);
      }
    };
    if (loginUserData?.roles?.type !== 'admin') {
      fetchReviewPlateForm();
    }
  }, [dispatch, loginUserData?.roles?.type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payloadData = {
          query: planQuery.getUserSubscriptionPackage,
        };
        const result = await handleApiRequest(payloadData);
        if (result?.getUserSubscriptionPackage?.status === 200) {
          dispatch(
            setUserSubscriptionPackage(
              JSON.parse(getDecryptData(result.getUserSubscriptionPackage?.data))
            )
          );
        }
      } catch (error) {
        console.error('Error fetching subscription package:', error);
      }
    };
    if (loginUserData?.roles?.type === 'business_user') {
      fetchData();
    }
  }, [dispatch, loginUserData?.roles?.type]);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (
        loginUserData?.roles?.type === 'business_user' ||
        loginUserData?.roles?.type === 'agent'
      ) {
        setPageLoading(true);
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (
          result?.getUserBusinessDetail?.status === 200 &&
          result?.getUserBusinessDetail?.data?.length === 0
        ) {
          setIsBusinessAvailable(true);
        }
      }
    };
    fetchBusinessDetails();
  }, [loginUserData?.roles?.type]);

  useEffect(() => {
    fetchIsAppMaintenance();
  }, []);

  const handleAddBusiness = () => {
    if (new Date() > new Date(getUserSubscriptionPackageDetails?.expireDate)) {
      enqueueSnackbar(t('userBusinessList.planExpire'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (id) {
      history.push(`/select-business/${id}`);
    } else {
      history.push('/select-business');
    }
  };

  return (
    <Root id="fuse-layout" config={config} className="w-full flex">
      {config.leftSidePanel.display && <LeftSideLayout1 />}
      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

        <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          {config.toolbar.display && (
            <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
          )}

          <div className="sticky top-0 z-99">{/* <SettingsPanel /> */}</div>
          {loginUserData?.roles?.type !== 'admin' &&
          location.pathname !== '/sign-in' &&
          location.pathname !== '/sign-up' &&
          isAppMaintenance ? (
            <div className="bg-darkgreen-100 flex items-center justify-center h-full w-full">
              <div>
                <img className="w-480 m-auto" src="/assets/video/Maintenance_gif.gif" alt="" />

                <Typography className="text-center text-16 font-bold mt-24 py-16">
                  {t('appMaintenance.webServerMaintenance')}
                </Typography>
                <Typography className="text-center max-w-512 lg:max-w-480 m-auto">
                  {t('appMaintenance.underMaintenance')}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              {pageLoading ? (
                <FuseLoading />
              ) : (
                <>
                  {isBusinessAvailable &&
                  location.pathname !== '/select-business' &&
                  location.pathname !== `/select-business/${id}` &&
                  location.pathname !== '/manage-business' &&
                  location.pathname !== `/manage-business/${id}` &&
                  location.pathname !== '/user-profile' &&
                  location.pathname !== `/user-profile/${id}` &&
                  location.pathname !== '/your-plan' &&
                  location.pathname !== `/your-plan/${id}` &&
                  location.pathname !== '/agency-profile' &&
                  location.pathname !== location.pathname.includes('/scanner') &&
                  !location.pathname === location.pathname.includes('/pay') &&
                  location.pathname !== '/manage-business-online' &&
                  location.pathname !== `/manage-business-online/${id}` ? (
                    <div className="bg-white h-full flex items-center justify-center">
                      <div className="text-center">
                        <img
                          className="m-auto"
                          src="/assets/images/business/businessShop.svg"
                          alt=""
                        />
                        <Typography className="font-bold text-24 my-16">
                          {t('businessDashboard.message')}
                        </Typography>
                        <Typography className="text-14 max-w-640 font-semibold">
                          {t('businessDashboard.desc')}
                        </Typography>
                        <Button
                          className="rounded px-44 mt-16"
                          variant="contained"
                          color="secondary"
                          onClick={() => handleAddBusiness()}
                        >
                          {t('businessDashboard.addBusiness')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col flex-auto min-h-0 relative z-10">
                      <FuseDialog />

                      <FuseSuspense>{routesComponent}</FuseSuspense>

                      {props.children}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {config.footer.display && (
            <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </Root>
  );
}

export default memo(Layout1);

import './signIn.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import QRCode from 'qrcode.react';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import history from '@history';
import { useCallback, useEffect, useState, useMemo } from 'react';
import withReducer from 'app/store/withReducer';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useParams, Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { publicIpv4 } from 'public-ip';
import { Country } from 'country-state-city';
import AppleLogin from 'react-apple-login';
import jwtDecode from 'jwt-decode';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { getCountryData } from 'app/store/userSlice';
import SignInBoxContent from './SignInBoxContent';
import Query from '../query/auth';
import queryData from '../query/common';
import reducer from '../auth/store';
import {
  submitLogin,
  sendOTPOnPhoneNumberForLogin,
  checkEmail,
  checkUserName,
  resetLoginAction,
  googleAuth,
  asPreviouslyRegesterInGoogle,
  asPreviouslyRegesterInAppleThunk,
  appleAuthThunk,
  appleTokenThunk,
} from '../auth/store/loginAuthSlice';
import { getDecryptData, getEncryptedData, countryData } from '../common/common';
import { getCountries, getLocation } from '../sign-up/store/signUpSlice';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 8,
    position: 'relative',
  },
  buttonProgress: {
    color: 'darkgreen',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -5,
    marginLeft: -5,
  },
}));
/**
 * Form Validation Schema
 */

const defaultValues = {
  email: '',
  // password: '',
};

function SignInPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const routeParams = useParams();
  const schema = yup.object().shape({
    // email: yup.string().required(t('signInPage.formField.email.validationMessages.required')),
    // password: yup
    //   .string()
    //   .required(t('signInPage.formField.password.validationMessages.required'))
    //   .min(4, t('signInPage.formField.password.validationMessages.min')),
  });

  const login = useSelector(({ signInReducer }) => signInReducer?.login?.submitLoginData);
  const googleLogin = useSelector(({ signInReducer }) => signInReducer?.login?.googleAuthData);
  const appleLogin = useSelector(({ signInReducer }) => signInReducer?.login?.appleAuthData);

  const phoneOtpStatus = useSelector(
    ({ signInReducer }) =>
      signInReducer?.login?.sendOTPOnPhoneNumberForLoginData?.data?.sendOTPOnPhoneNumberForLogin
  );

  const emailAvailableStatus = useSelector(
    ({ signInReducer }) => signInReducer?.login?.checkEmailData?.data?.checkEmail
  );

  const userAvailableStatus = useSelector(
    ({ signInReducer }) => signInReducer?.login?.checkUserNameData?.data?.checkUserName
  );

  const { control, formState, handleSubmit, setError, setValue, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [userValue, setUserValue] = useState(false);
  const [isValueChange, setIsValueChange] = useState();
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [selected, setSelected] = useState('IN');
  const [otp, setOtp] = useState('');
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [passwordValue, setPasswordValue] = useState();
  const [nextStep, setNextStep] = useState(false);
  const [countryIsoData, setCountryIsoData] = useState([]);
  const [checkExistPassword, setCheckExistPassword] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    const payload = {
      query: queryData.getCountries,
    };
    dispatch(getCountries(payload));
  }, [dispatch]);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (countryList) {
      const isoCode = countryList.map((country) => country.isoCode);
      setCountryIsoData(isoCode);
    }
  }, [countryList]);

  const message = encodeURIComponent('Send this message to sign-in on quick review dashboard');
  const whatsAppURL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;

  const handleWhatsappClick = () => {
    const URL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;
    window.open(URL, '_blank');
  };

  const customLabels = useMemo(() => {
    if (!countryList) {
      return {};
    }

    return countryList.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
      };
      return acc;
    }, {});
  }, [countryList]);

  const loginRedirect = useCallback(
    (loginData) => {
      if (Object.keys(loginData).length > 0) {
        if (
          loginData?.status === 'fail' ||
          loginData?.status === 'error' ||
          loginData?.status !== 200
        ) {
          enqueueSnackbar(loginData.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        } else if (loginData?.status === 200 && loginData?.user) {
          const userData = loginData.user;
          enqueueSnackbar(t('signInPage.messages.successMessage'), {
            variant: 'success',
            autoHideDuration: 2000,
          });
          if (userData?.roles?.type === 'sales_user') {
            history.push('/dashboard');
          } else if (userData?.roles?.type === 'business_user') {
            history.push('/business/dashboard');
          } else if (userData?.roles?.type === 'admin') {
            history.push('/admin/dashboard');
          } else if (userData?.roles?.type === 'demo_user') {
            history.push('/business/dashboard');
          } else if (userData?.roles?.type === 'agent') {
            history.push('/agency/dashboard');
          }
        }
      }
    },
    [enqueueSnackbar, t]
  );

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
            localStorage.setItem('ipData', locationData);
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    if (ipBasedData && ipBasedData.countryCode) {
      const matchingLabel = Object.entries(customLabels).find(
        ([key, value]) => value.secondary === `+${ipBasedData.countryCode}`
      );
      if (matchingLabel) {
        setSelected(matchingLabel[1].isoCode);
      }
    }
  }, [ipBasedData, customLabels]);

  useEffect(() => {
    setLoading(false);
    if (login) {
      loginRedirect(login);
    }
  }, [enqueueSnackbar, login, t, loginRedirect]);

  useEffect(() => {
    setLoading(false);
    if (googleLogin) {
      loginRedirect(googleLogin);
    }
  }, [enqueueSnackbar, googleLogin, t, loginRedirect]);

  useEffect(() => {
    return () => {
      /**
       * Reset login action on component unload
       */
      dispatch(resetLoginAction());
    };
  }, [dispatch]);

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
    history.push('forget-password');
  };

  useEffect(() => {
    if (userValue) {
      clearErrors('email');
    } else {
      setNextStep(false);
    }
  }, [userValue, clearErrors]);

  useEffect(() => {
    if (phoneOtpStatus?.status) {
      enqueueSnackbar(phoneOtpStatus?.message, {
        variant: phoneOtpStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
    if (phoneOtpStatus?.status === 200) {
      setNextStep(true);
    }
  }, [phoneOtpStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (emailAvailableStatus?.status === 200 && emailAvailableStatus?.available) {
      enqueueSnackbar(
        'This Email Address cannot be found. Please use a different account or sign up for a new account.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      closeSnackbar(3000);
    }
    if (emailAvailableStatus?.status === 200 && !emailAvailableStatus?.available) {
      setNextStep(true);
    }
  }, [emailAvailableStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (userAvailableStatus?.status === 200 && userAvailableStatus?.available) {
      enqueueSnackbar(
        'This User Name cannot be found. Please use a different account or sign up for a new account.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      closeSnackbar(3000);
    } else if (userAvailableStatus?.status === 400) {
      enqueueSnackbar(userAvailableStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
    if (userAvailableStatus?.status === 200 && !userAvailableStatus?.available) {
      setNextStep(true);
    }
  }, [userAvailableStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (phoneOtpStatus?.status === 200) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  }, [minutes, seconds, phoneOtpStatus]);

  useEffect(() => {
    setLoading(false);
    if (appleLogin) {
      loginRedirect(appleLogin);
    }
  }, [enqueueSnackbar, appleLogin, t, loginRedirect]);

  useEffect(() => {
    setLoading(false);
    const appleResponse = async (token) => {
      setAppleLoading(true);
      let successMessage = '';
      try {
        const appleToken = JSON.stringify({
          accessToken: token,
        });
        const registrationPayload = {
          query: Query.asPreviouslyRegesterInApple,
          variables: { data: getEncryptedData(appleToken) },
        };
        const registrationResult = await dispatch(
          asPreviouslyRegesterInAppleThunk(registrationPayload)
        );
        if (registrationResult.payload?.available === false) {
          const applePayload = {
            query: Query.appleAuthV2,
            variables: { data: getEncryptedData(appleToken) },
          };
          await dispatch(appleAuthThunk(applePayload));
        }
        if (registrationResult.payload?.available) {
          const decodeData = jwtDecode(token);
          await dispatch(appleTokenThunk({ token, decodeData }));
          history.push('/sign-up');
        } else {
          successMessage = t('signInPage.messages.appleAccountNotRegistered');
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setAppleLoading(false);
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    };
    if (routeParams?.token) {
      appleResponse(routeParams.token);
    }
  }, [routeParams?.token, enqueueSnackbar, dispatch, t]);

  const getEmailOrPhoneInput = async () => {
    if (isValueChange) {
      setIsValueChange(false);
      if (!userValue) {
        setError('email', {
          type: 'manual',
          message: t('signInPage.formField.email.validationMessages.required'),
        });
        setLoading(false);
        return;
      }
      setOTPLoading(true);
      const findCountryCode = Object.entries(customLabels).find(
        ([key, value]) => value.isoCode === selected
      );
      if (showPhoneDropdown) {
        try {
          const payload = {
            query: Query.sendOTPOnPhoneNumberForLogin,
            variables: {
              countryCode: findCountryCode[1].secondary,
              phoneNumber: parseFloat(userValue),
            },
          };
          await dispatch(sendOTPOnPhoneNumberForLogin(payload));
          setOTPLoading(false);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      } else {
        const email = /\S+@\S+\.\S+/.test(userValue);
        try {
          if (email === true) {
            const payload = {
              query: queryData.checkEmail,
              variables: {
                email: userValue,
              },
            };
            await dispatch(checkEmail(payload));
            setOTPLoading(false);
          } else {
            const payload = {
              query: queryData.checkUserName,
              variables: {
                userName: userValue,
              },
            };
            await dispatch(checkUserName(payload));
            setOTPLoading(false);
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    }
  };

  function onChangeCountryDrop(value) {
    setSelected(value);
  }

  function handleSignIn() {
    const findCountryCode = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    );
    let data;
    if (showPhoneDropdown) {
      data = getEncryptedData(
        JSON.stringify({
          countryCode: findCountryCode[1].secondary,
          phoneNumber: parseInt(userValue, 10),
          otp: parseInt(otp, 10),
        })
      );
      const payload = {
        query: Query.authLoginMutation,
        variables: { data },
      };
      dispatch(submitLogin(payload));
    } else if (userValue && !passwordValue) {
      enqueueSnackbar(t('signInPage.enterYourPassword'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (passwordValue === checkExistPassword) {
      enqueueSnackbar(t('signInPage.enterValidPassword'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setCheckExistPassword(passwordValue);
      setLoading(true);
      data = getEncryptedData(`{"e": "${userValue}", "p": "${passwordValue}"}`);
      const payload = {
        query: Query.authLoginMutation,
        variables: { data },
      };
      dispatch(submitLogin(payload));
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      getEmailOrPhoneInput();
    }
  }

  function handleKeyPressSignIn(event) {
    if (!loading) {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSignIn();
      }
    }
  }

  const resendOTP = async () => {
    setResendOtpStatus(true);
    const findCountryCode = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    );
    const payload = {
      query: Query.sendOTPOnPhoneNumberForLogin,
      variables: {
        countryCode: findCountryCode[1].secondary,
        phoneNumber: parseInt(userValue, 10),
      },
    };
    const result = await dispatch(sendOTPOnPhoneNumberForLogin(payload));
    if (result.payload?.sendOTPOnPhoneNumberForLogin?.status === 400) {
      enqueueSnackbar(result.payload?.sendOTPOnPhoneNumberForLogin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setMinutes(1);
    setSeconds(0);
    setTimeout(() => {
      setResendOtpStatus(false);
      setMinutes(0);
      setSeconds(59);
    }, 1000);
  };

  const responseGoogle = async (response) => {
    if (response) {
      // const data = await axios.get(
      //   'https://accounts.google.com/o/oauth2/v2/auth?client_id=51666468051-hpv7ad36fr0v4p51b8vesm97akogv5h7.apps.googleusercontent.com&redirect_uri=https://app-uat.quickreviews.click&response_type=token&scope=https://www.googleapis.com/auth/plus.business.manage&state=xcoVv98y2kd44vuqwye3kcq&include_granted_scopes=true'
      // );
      // console.log('data', data);
      setGoogleLoading(true);
      let successMessage = '';
      try {
        const googleResponse = JSON.stringify({
          accessToken: response.credential,
        });
        const registrationPayload = {
          query: Query.asPreviouslyRegesterInGoogle,
          variables: { data: getEncryptedData(googleResponse) },
        };
        const registrationResult = await dispatch(
          asPreviouslyRegesterInGoogle(registrationPayload)
        );
        if (registrationResult.payload?.available === false) {
          const googlePayload = {
            query: Query.googleAuth,
            variables: { data: getEncryptedData(googleResponse) },
          };
          await dispatch(googleAuth(googlePayload));
        } else {
          successMessage = t('signInPage.messages.gmailIsNotRegistered');
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setGoogleLoading(false);
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        {!isWhatsapp ? (
          <div className="block">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <img src="assets/images/logo/logo_green.png" className="w-[170px]" alt="logo" />

              <Typography className="mt-28 text-28 font-semibold tracking-tight leading-tight">
                {t('signInPage.title')}
              </Typography>
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full mt-32"
              >
                <span className="relative">
                  {showPhoneDropdown && (
                    <span className="absolute z-20">
                      <ReactFlagsSelect
                        className={
                          userValue && nextStep
                            ? 'pointer-events-none react-flag-button'
                            : 'react-flag-button'
                        }
                        selected={selected}
                        onSelect={(code) => onChangeCountryDrop(code)}
                        countries={countryIsoData}
                        searchable
                        customLabels={customLabels}
                        showSelectedLabel={false}
                      />
                    </span>
                  )}

                  <TextField
                    className="mb-28 rounded-md"
                    name="email"
                    control={control}
                    style={{ height: '44px' }}
                    placeholder={t('signInPage.formField.email.userName')}
                    sx={{
                      '& .MuiInputBase-input': {
                        border: '1px solid #666666',
                        textIndent: showPhoneDropdown ? '105px !important' : 'initial',
                        transition: 'all .2s ease-in-out',
                        borderRadius: '7px',
                      },
                    }}
                    autoFocus
                    disabled={userValue && nextStep}
                    onKeyDown={(event) => {
                      handleKeyPress(event);
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setUserValue(inputValue);
                      setIsValueChange(true);
                      if (/^\d+$/.test(inputValue)) {
                        e.target.type = 'tel';
                        setShowPhoneDropdown(true);
                        const digitCount = inputValue.replace(/\D/g, '').length;
                        if (digitCount > 15) {
                          e.target.value = inputValue.substring(0, 10);
                        }
                      } else {
                        e.target.type = 'text';
                        setShowPhoneDropdown(false);
                      }
                    }}
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </span>
                {nextStep && (
                  <>
                    {showPhoneDropdown ? (
                      <>
                        <MuiOtpInput
                          className="max-w-xs my-24"
                          TextFieldsProps={{
                            size: 'small',
                            placeholder: '-',
                            type: 'number',
                          }}
                          type="number"
                          gap={2}
                          length={6}
                          value={otp}
                          onChange={(newValue) => setOtp(newValue)}
                        />

                        <div className="countdown-text flex items-center justify-start text-14 font-medium my-10">
                          {seconds > 0 || minutes > 0 ? (
                            <p>
                              {t('signUpPage.messages.codeResend')}:{' '}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>{t('signUpPage.messages.notReceivedCode')}</p>
                          )}
                          {resendOtpStatus ? (
                            <CircularProgress
                              size={24}
                              className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                            />
                          ) : (
                            <Button
                              disabled={seconds > 0 || minutes > 0}
                              className="underline decoration-1 text-14 font-medium"
                              color="secondary"
                              style={{
                                color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                              }}
                              onClick={resendOTP}
                            >
                              {t('signUpPage.buttons.resendOTP')}
                            </Button>
                          )}
                        </div>
                      </>
                    ) : (
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            placeholder={t('signInPage.formField.password.name')}
                            type="password"
                            onChange={(e) => setPasswordValue(e.target.value)}
                            onKeyDown={(event) => {
                              handleKeyPressSignIn(event);
                            }}
                            size="small"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            InputProps={{
                              className: 'pr-2',
                              type: showPassword ? 'text' : 'password',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    <Icon className="text-20" color="action">
                                      {showPassword ? 'visibility' : 'visibility_off'}
                                    </Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </>
                )}
                {nextStep ? (
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Sign in"
                      disabled={loading}
                      onClick={() => handleSignIn()}
                      type="button"
                    >
                      {t('signInPage.buttons.signIn')}
                    </Button>
                    {loading && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </div>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Sign in"
                      disabled={loading}
                      onClick={() => getEmailOrPhoneInput()}
                      type="button"
                    >
                      {OTPLoading && (
                        <CircularProgress size={20} className="text-white absolute mx-auto mr-64" />
                      )}
                      {t('signUpPage.buttons.next')}
                    </Button>
                  </div>
                )}
                <div className="text-center mt-14">
                  <button
                    type="button"
                    className="inline-block text-14 text-darkgreen font-semibold cursor-pointer ml-4"
                    onClick={handleForgotPasswordClick}
                  >
                    {t('signInPage.link.forgotPassword')}
                  </button>
                </div>
                <div className="my-20 flex items-center justify-center">
                  <Divider className="w-80" />
                  <span className="mx-8 font-medium text-16">
                    {t('signInPage.messages.signInWithGoogle')}
                  </span>
                  <Divider className="w-80" />
                </div>
                <div className="w-full mb-8 text-center google-login relative">
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <div className="w-full">
                      <GoogleLogin onSuccess={responseGoogle} onError={responseGoogle} />
                      {googleLoading && (
                        <CircularProgress
                          size={24}
                          className="text-darkgreen absolute mx-auto top-10 right-0"
                        />
                      )}
                    </div>
                  </GoogleOAuthProvider>
                </div>
                <div className="w-full mb-8 text-center google-login relative mt-12 cursor-pointer">
                  <AppleLogin
                    clientId="org.reactjs.native.example.happyReview"
                    redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URL}appleVerify`}
                    scope="email name   "
                    responseMode="form_post"
                    responseType="id_token code"
                    designProp={{ width: 200 }}
                  />
                  {appleLoading && (
                    <CircularProgress
                      size={24}
                      className="text-darkgreen absolute mx-auto top-10 right-0"
                    />
                  )}
                </div>
                {/* <div className="w-full mb-8 text-center google-login relative mt-12 cursor-pointer">
                  <Button
                    className="border text-14 border-solid font-semibold rounded max-w-200 w-200 hover:bg-white"
                    onClick={() => setIsWhatsapp(true)}
                  >
                    <img className="pr-8" src="/assets/images/business/whatsapp.svg" alt="" />
                    {t('whatsapp.continueWhatsappSignIn')}
                  </Button>
                </div>
                <Button
                  className="text-darkgreen underline max-w-192 m-auto hover:bg-white"
                  onClick={() => handleWhatsappClick()}
                >
                  {' '}
                  {t('whatsapp.openWhatsapp')}
                </Button> */}
              </form>
              <div className="text-center">
                <Typography className="text-16 font-medium mt-16">
                  {t('signInPage.messages.dontHaveAccount')}
                </Typography>
                <Link className="text-16 font-semibold text-darkgreen" role="button" to="/sign-up">
                  {t('signInPage.link.registerAsSales')}
                </Link>
              </div>
            </div>
            <div className="bg-white py-10 max-w-288 text-14 text-center mx-auto">
              By continuing you agree to our <br />
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
              >
                {t('signUpPage.labels.privacyPolicy')}
              </a>
              ,{' '}
              <a
                href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
              >
                {t('signUpPage.labels.termsService')}
              </a>
              ,{' '}
              <a
                href={process.env.REACT_APP_EULA_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
              >
                {t('signUpPage.labels.eula')}
              </a>{' '}
              and{' '}
              <a
                href={process.env.REACT_APP_DISCLAIMER_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
              >
                {t('signUpPage.labels.disclaimers')}
              </a>{' '}
            </div>
          </div>
        ) : (
          <div className="bg-gradient-to-b from-darkgreen-A500 to-darkgreen text-center p-40 max-w-360 rounded-xl">
            <Typography className="text-white w-full text-28 py-8 font-semibold">
              {t('whatsapp.signInContinue')}
            </Typography>
            <div className="text-center m-auto bg-white max-w-160 p-16 rounded-md my-20">
              <QRCode value={whatsAppURL} />
            </div>
            <Typography className="text-white text-20 max-w-384 py-24 font-normal">
              {t('whatsapp.scanQrCode')}
            </Typography>
          </div>
        )}
      </Paper>
      <SignInBoxContent />
    </div>
  );
}

export default withReducer('signInReducer', reducer)(SignInPage);

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'src/app/store/userSlice';
import ReviewSentiment from './reviewSentiment';
import metricsData from '../../query/metrics';
import { reviewFormate, getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function AttributeComparison(props) {
  const { reviewSentiment, reviewPlatformId, placeId } = props;
  const [newGraphData, setNewGraphData] = useState([]);
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [planStatus, setPlanStatus] = useState();

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getReviewsAttributeComparisonV1,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }

          const result = await getDataToServer(payload);
          setPlanStatus(result?.getReviewsAttributeComparisonV1?.status);
          setNewGraphData(result?.getReviewsAttributeComparisonV1?.data?.comparisonJsonV1 || []);
        }
        if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setPlanStatus('');
          setNewGraphData([]);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    placeId,
    reviewPlatformId,
    selectedReviewSentimentId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  return (
    <>
      {((planStatus !== 403 && !userCurrentBusinessDetails?.isOnlineBusiness) ||
        parseInt(reviewPlatformId, 10) === 7) && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[7, 6, 5, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
      )}
      <div className="p-14">
        {newGraphData && newGraphData?.length > 0 ? (
          newGraphData?.map((item, index) => (
            <div className="bg-grey-100 py-12 px-14 mb-12 rounded-md" key={index}>
              <div className="flex items-center justify-between">
                <div>
                  <div className="block font-semibold text-16">
                    <span>{item?.key}</span>
                  </div>
                </div>
                <span className="font-bold inline-block leading-none">
                  {item?.value?.toFixed(1)} {reviewFormate(item?.value)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}

export default AttributeComparison;

const signUpForSalesPerson = `
   mutation signUpForSalesPerson($data: String!){
    signUpForSalesPerson(data: $data){ 
      status
      message  
     }
   }
`;

const getDashboardForSalesUser = `
query {
     getDashboardForSalesUser {
        data {
            salesUserDashboardData{
              salesPersonsActivityInMonth{
                convertedAccountsCount
                demoAccountsCount
              }
              lastMonthVisitedAccount
              topBusinessType
              totalDemoAccountToRealAccount {
                convertedAccountsCount
                demoAccountsCount
              }
              totalVisitedAccount
              totalSelectedAccount
            }
            salesUserDashboardDataV1{
              salesPersonPc{
                activeBusiness
                convertedBusiness
                selectedAccount
                visitedAccount
              }
              totalVisitedAccounts{
                onlyVisitedAccountsCount
                totalVisitedAccountsCount
                totalConvertedAccountsCount
                deactivatedVisitedAccountsCount
              }
              totalDemoAccounts{
                deactivatedDemoAccountsCount
                onlyDemoAccountsCount
                totalConvertedAccountsCount
                totalDemoAccountsCount
                visitedCount
              }
            }
          
          }
        message
        status
     }
   }
`;

const getDeactivateBusiness = `
query getDeactivateBusiness($pageNumber: Int, $pageSize: Int, $searchText: String){
  getDeactivateBusiness(pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText) {
      totalCount
      data {       
          id
          name
          userId
          users{
            uts
          }
      }
      message
      status
     }
   }
`;
const activateBusinessAccountByAdmin = `
  mutation activateBusinessAccountByAdmin($userId: Int!) {
    activateBusinessAccountByAdmin(userId: $userId) {
      message
      status
    }
  }
`;

const getSalesUsers = `
query getSalesUsers($isActive: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection){
  getSalesUsers(isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
      totalCount
      data {       
          id
          fullName
          firstName
          lastName
          cts
          phoneNumber{
            countryCode
            isoCode
            phoneNumber
          }
          userName
          email
      }
      message
      status
     }
   }
`;

const getSalesUsersBySalesUserId = `
query getSalesUsersBySalesUserId($salesUserId: Int!){
  getSalesUsersBySalesUserId(salesUserId: $salesUserId) {
    convertedRealAccountCount
    createdDemoAccountCount
    visitedAccount
    data {
      id
      firstName
      middleName
      lastName
      zipcode
      salesId
      email
      phoneNumber {
        countryCode
        isoCode
        phoneNumber
      }
      city
      address
      countries {
        id
        name
      }
      states {
        id
        name
      }
    }
    message
    status
     }
   }
`;

const editProfile = `
  mutation ($address: String, $addressTwo: String, $email: String, $city: String, $countryId: Int, $firstName: String, $lastName: String, $middleName: String, $phoneNumber: PhoneNumberObject, $salesId: String, $stateId: Int, $userId: Int, $zipCode: Int) {
		editProfile(address: $address, addressTwo: $addressTwo, city: $city, countryId: $countryId, firstName: $firstName, lastName:$lastName middleName:$middleName, phoneNumber: $phoneNumber, email: $email salesId: $salesId, stateId: $stateId, userId: $userId, zipCode: $zipCode) {
      status
      message
      data {
        firstName
        lastName
        middleName
        address
        address2
        city
        zipcode
        stateId
        countryId
        phoneNumber {
          countryCode
          isoCode
          phoneNumber
        }
      }  
		}
	}
`;

const getDemoBusinessUpcomingFollowUpDataBySalesPerson = `
query ($pageNumber: Int, $pageSize: Int){
  getDemoBusinessUpcomingFollowUpDataBySalesPerson(pageNumber:$pageNumber, pageSize:$pageSize){
    status
    message
    totalCount
    data {
        cts
        note
        businessId
        followUpDate
        businessName
        countryCode
        phoneNumber
    }
  }
}
`;

const updateUserProfilePicture = `
 mutation($file: Upload, $isRemove: Boolean!){
  updateUserProfilePicture(file: $file, isRemove: $isRemove){
    status
    message
  }
 }
`;

const deleteSalesUser = `
 mutation($data: String!){
  deleteSalesUser(data: $data){
    status
    message
  }
 }
`;

const salesData = {
  signUpForSalesPerson,
  getDashboardForSalesUser,
  getDeactivateBusiness,
  activateBusinessAccountByAdmin,
  getSalesUsers,
  getSalesUsersBySalesUserId,
  editProfile,
  getDemoBusinessUpcomingFollowUpDataBySalesPerson,
  updateUserProfilePicture,
  deleteSalesUser,
};

export default salesData;

import i18next from 'i18next';

import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import { getParamsId } from '../main/common/common';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'dashboards',
    title: i18next.t('navigation.dashboard'),
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
    auth: 'sales_user ',
  },
  {
    id: 'admin_dashboards',
    title: i18next.t('navigation.dashboard'),
    type: 'item',
    icon: 'dashboard',
    url: 'admin/dashboard',
    auth: 'admin',
  },
  {
    id: 'business_dashboards',
    title: i18next.t('navigation.dashboard'),
    type: 'item',
    icon: 'dashboard',
    url: `business/dashboard/${getParamsId()}`,
    auth: 'business_user',
  },
  // {
  //   id: 'app_website',
  //   title: i18next.t('navigation.business.appWebsite'),
  //   type: 'item',
  //   icon: 'devices',
  //   url: 'app-website',
  //   auth: 'business_user',
  // },
  // {
  //   id: 'menu',
  //   title: i18next.t('navigation.menu'),
  //   type: 'item',
  //   icon: 'menu',
  //   url: '/menu',
  //   auth: 'business_user',
  // },
  {
    id: 'reviews',
    title: i18next.t('navigation.reviews'),
    type: 'item',
    icon: 'star',
    url: `/reviews/${getParamsId()}`,
    auth: 'business_user',
  },
  {
    id: 'matrices',
    title: i18next.t('navigation.matrices'),
    type: 'item',
    icon: 'insert_chart',
    url: `/matrices/${getParamsId()}`,
    auth: 'business_user',
  },
  {
    id: 'plan',
    title: i18next.t('navigation.plan'),
    type: 'item',
    icon: 'monetization_on',
    url: `/your-plan/${getParamsId()}`,
    auth: 'business_user',
  },
  {
    id: 'products',
    title: i18next.t('navigation.products'),
    type: 'item',
    icon: 'business',
    url: `/product/${getParamsId()}`,
    auth: 'business_user',
  },
  {
    id: 'targetMessage',
    title: i18next.t('navigation.targetMessage'),
    type: 'collapse',
    icon: 'messages',
    auth: 'business_user',
    children: [
      {
        id: 'inviteMessage',
        title: i18next.t('navigation.inviteReviews'),
        type: 'item',
        url: `/invite-reviews/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'sendMessage',
        title: i18next.t('navigation.sendMessage'),
        type: 'item',
        url: `/send-message/${getParamsId()}`,
        auth: 'business_user',
      },
    ],
  },
  {
    id: 'businessSetUp',
    title: i18next.t('navigation.businessSetup'),
    type: 'collapse',
    icon: 'business',
    auth: 'business_user',
    children: [
      {
        id: 'editBusinessDetails',
        title: i18next.t('navigation.editBusinessDetail'),
        type: 'item',
        url: `/editBusinessDetails/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'qrCode',
        title: i18next.t('navigation.qrCode'),
        type: 'item',
        url: `/qrCode/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'manageReviewSite',
        title: i18next.t('navigation.manageReviewSite'),
        type: 'item',
        url: `/manageReviewSite/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'reviewScreenMode',
        title: i18next.t('navigation.reviewScreenMode'),
        type: 'item',
        url: `/reviewScreenMode/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'businessProductAndService',
        title: i18next.t('navigation.businessProductAndService'),
        type: 'item',
        url: `/businessProductAndService/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'customAttribute',
        title: i18next.t('navigation.customAttributes'),
        type: 'item',
        url: `/customAttributes/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'serviceRecovery',
        title: i18next.t('navigation.serviceRecovery'),
        type: 'item',
        url: `/serviceRecovery/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'staff',
        title: i18next.t('navigation.staff'),
        type: 'item',
        url: `/staff/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'planUsage',
        title: i18next.t('navigation.planUsage'),
        type: 'item',
        url: `/planUsage/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'inviteUser',
        title: i18next.t('navigation.inviteUser'),
        type: 'item',
        url: '/inviteUser',
        auth: 'business_user',
      },
      {
        id: 'autoGenerateText',
        title: i18next.t('navigation.autoGenerateText'),
        type: 'item',
        url: `/autoGenerateText/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'autoReply',
        title: i18next.t('navigation.autoReply'),
        type: 'item',
        url: `/autoReply/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'businessPerformanceReports',
        title: i18next.t('navigation.businessPerformanceReports'),
        type: 'item',
        url: `/businessPerformanceReports/${getParamsId()}`,
        auth: 'business_user',
      },
      {
        id: 'userNumber',
        title: i18next.t('navigation.userNumber'),
        type: 'item',
        url: `/userNumber/${getParamsId()}`,
        auth: 'business_user',
      },
    ],
  },
  {
    id: 'promotion',
    title: i18next.t('navigation.promotion'),
    type: 'item',
    icon: 'emoji_events',
    url: `/promotion/${getParamsId()}`,
    auth: 'business_user',
  },
  {
    id: 'quick_chat',
    title: i18next.t('navigation.quickChat'),
    type: 'collapse',
    icon: 'chat',
    auth: 'business_user',
    children: [
      {
        id: 'quick_chat_bot',
        title: i18next.t('navigation.quickChatBot'),
        type: 'item',
        url: `/quick-chat/${getParamsId()}`,
        end: true,
      },
      // {
      //   id: 'quick_chat_waba',
      //   title: i18next.t('navigation.quickWaba'),
      //   type: 'item',
      //   url: `/waba/${getParamsId()}`,
      //   end: true,
      // },
    ],
  },
  {
    id: 'quick_post',
    title: i18next.t('navigation.quickPost'),
    type: 'item',
    icon: 'image',
    url: `/quick-post/${getParamsId()}`,
    auth: 'business_user',
  },
  // {
  //   id: 'cms-app',
  //   title: 'CMS App',
  //   type: 'collapse',
  //   icon: 'description',
  //   auth: 'business_user',
  //   children: [
  //     {
  //       id: 'select-app',
  //       title: 'App Theme',
  //       type: 'item',
  //       url: '/select-app',
  //       end: true,
  //     },
  //     {
  //       id: 'business-information',
  //       title: 'Business Information',
  //       type: 'item',
  //       url: '/business-information',
  //       end: true,
  //     },
  //     {
  //       id: 'onboarding-screens',
  //       title: 'Onboarding Screens',
  //       type: 'item',
  //       url: '/cms-app/onboarding',
  //       end: true,
  //     },
  //     {
  //       id: 'categories',
  //       title: 'Categories',
  //       type: 'item',
  //       url: '/cms-app/categories',
  //       end: true,
  //     },
  //     {
  //       id: 'menu',
  //       title: 'Menu',
  //       type: 'item',
  //       url: '/cms-app/menu',
  //       end: true,
  //     },
  //     {
  //       id: 'home',
  //       title: 'Home',
  //       type: 'item',
  //       url: '/cms-app/home',
  //       end: true,
  //     },
  //     {
  //       id: 'PopularAndRecommended',
  //       title: 'Popular & Recommended',
  //       type: 'item',
  //       url: '/cms-app/popular-recommended',
  //       end: true,
  //     },
  //     {
  //       id: 'offer',
  //       title: 'Offers',
  //       type: 'item',
  //       url: '/cms-app/offers',
  //       end: true,
  //     },
  //     {
  //       id: 'blog',
  //       title: 'Blogs',
  //       type: 'item',
  //       url: '/blog',
  //       end: true,
  //     },
  //     {
  //       id: 'user-feedback',
  //       title: 'User Feedback',
  //       type: 'item',
  //       url: '/cms-app/feedback',
  //       end: true,
  //     },
  //   ],
  // },
  {
    id: 'user-list',
    title: i18next.t('navigation.userList'),
    type: 'item',
    icon: 'group',
    url: '/user-list',
    auth: 'admin',
  },
  {
    id: 'business-list',
    title: i18next.t('navigation.businessList'),
    type: 'item',
    icon: 'store',
    url: '/business-list',
    auth: 'admin',
  },
  {
    id: 'queries',
    title: i18next.t('navigation.queries'),
    type: 'item',
    icon: 'help',
    url: '/queries',
    auth: 'admin',
  },
  {
    id: 'qrcode',
    title: i18next.t('navigation.orderedQrCode'),
    type: 'item',
    icon: 'shopping_bag',
    url: '/ordered-qrcode',
    auth: 'admin',
  },
  {
    id: 'review-platform',
    title: i18next.t('navigation.reviewPlatform'),
    type: 'item',
    icon: 'stars',
    url: '/review-platform',
    auth: 'admin',
  },
  {
    id: 'user-feedback',
    title: i18next.t('navigation.userFeedback'),
    type: 'item',
    icon: 'feedback',
    url: '/user-feedback',
    auth: 'admin',
  },
  {
    id: 'master',
    title: 'Maters',
    type: 'group',
    translate: 'MASTERS',
    auth: 'admin',
    children: [
      {
        id: 'master.business',
        title: i18next.t('navigation.masters.business'),
        type: 'collapse',
        icon: 'home_work',
        translate: 'BUSINESS',
        children: [
          {
            id: 'business-type',
            title: i18next.t('navigation.masters.businessType'),
            type: 'item',
            url: '/business-type',
            end: true,
          },
          {
            id: 'business-attribute',
            title: i18next.t('navigation.masters.businessAttribute'),
            type: 'item',
            url: '/business-attribute',
            end: true,
          },
          {
            id: 'business-attribute-type',
            title: i18next.t('navigation.masters.businessAttributeType'),
            type: 'item',
            url: '/business-attribute-type',
            end: true,
          },
        ],
      },
      {
        id: 'tablet-videos',
        title: i18next.t('navigation.masters.tabletVideos'),
        type: 'item',
        icon: 'video_library',
        url: '/tablet-videos',
        auth: 'admin',
      },
      {
        id: 'Compare Plans & Features',
        title: i18next.t('navigation.masters.comparePlansAndFeatures'),
        type: 'item',
        icon: 'home_work',
        url: '/compare-plans-and-features',
        auth: 'admin',
      },
      {
        id: 'app-promotion-slider',
        title: i18next.t('navigation.masters.appPromotionSlider'),
        type: 'item',
        icon: 'home_work',
        url: '/app-promotion-slider',
        auth: 'admin',
      },
      {
        id: 'pricing-catalogue',
        title: i18next.t('navigation.masters.pricingCatalogue'),
        type: 'item',
        icon: 'list_alt',
        url: '/pricing-catalogue',
        auth: 'admin',
      },
      {
        id: 'negative-reviews',
        title: i18next.t('navigation.masters.negativeReviews'),
        type: 'item',
        icon: 'star',
        url: '/negative-reviews',
        auth: 'admin',
      },
      {
        id: 'app-maintenance',
        title: i18next.t('navigation.masters.appMaintenance'),
        type: 'item',
        icon: 'settings',
        url: '/app-maintenance',
        auth: 'admin',
      },
    ],
  },
  {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    translate: 'SALES',
    auth: 'admin',
    children: [
      {
        id: 'salesperson',
        title: i18next.t('navigation.sales.salesperson'),
        type: 'item',
        icon: 'person',
        url: '/sales-person',
        auth: 'admin',
      },
    ],
  },
  {
    id: 'partners',
    title: 'Partners',
    type: 'group',
    translate: 'PARTNERS',
    auth: 'admin',
    children: [
      {
        id: 'partner',
        title: i18next.t('navigation.partners.partners'),
        type: 'item',
        icon: 'group',
        url: '/partners/2',
        auth: 'admin',
      },
    ],
  },
  {
    id: 'app-website',
    title: i18next.t('navigation.app_and_website.title'),
    type: 'group',
    auth: 'admin',
    children: [
      {
        id: 'users',
        title: i18next.t('navigation.app_and_website.user'),
        type: 'item',
        icon: 'person',
        url: '/users',
        auth: 'admin',
      },
    ],
  },
  {
    id: 'search-business',
    title: i18next.t('navigation.searchBusiness'),
    type: 'item',
    icon: 'storefront',
    url: '/search-business',
    auth: 'sales_user',
  },
  {
    id: 'selected-business',
    title: i18next.t('navigation.selectedBusiness'),
    type: 'item',
    icon: 'check_circle',
    url: '/selected-business',
    auth: 'sales_user',
  },
  {
    id: 'visited-business',
    title: i18next.t('navigation.visitedBusiness'),
    type: 'item',
    icon: 'next_plan',
    url: '/visited-business',
    auth: 'sales_user',
  },
  {
    id: 'converted-business',
    title: i18next.t('navigation.convertedBusiness'),
    type: 'item',
    icon: 'autorenew',
    url: '/converted-business',
    auth: 'sales_user',
  },
  {
    id: 'deactivated-account',
    title: i18next.t('navigation.deactivatedAccount'),
    type: 'item',
    icon: 'person_off',
    url: '/deactivated-account',
    auth: 'sales_user',
  },
  {
    id: 'agency_dashboard',
    title: i18next.t('navigation.dashboard'),
    type: 'item',
    icon: 'dashboard',
    url: 'agency/dashboard',
    auth: 'agent',
  },
  {
    id: 'agency_business',
    title: i18next.t('navigation.businesses'),
    type: 'item',
    icon: 'business',
    url: 'agency/business',
    auth: 'agent',
  },
  {
    id: 'agency_clients',
    title: i18next.t('navigation.clients'),
    type: 'item',
    icon: 'people',
    url: 'agency/clients',
    auth: 'agent',
  },
];

export default navigationConfig;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import { reviewFormate, getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function ReviewVelocity() {
  const { t } = useTranslation();

  const [reviewVelocityData, setReviewVelocityData] = useState([]);
  const [reviewVelocityDate, setReviewVelocityDate] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [startDate, setStartDate] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id) {
          const payload = {
            query: metricsData.getReviewVelocityData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };

          const result = await getDataToServer(payload);
          if (result?.getReviewVelocityData?.status === 200) {
            const data = result?.getReviewVelocityData?.data?.reviewsVelocityDataV1;
            const convertKeyValueObj =
              data &&
              JSON.parse(data).map((item) => {
                const obj = {};
                item.forEach(({ key, value }) => {
                  obj[key] = value;
                });
                return obj;
              });
            setReviewVelocityDate(result?.getReviewVelocityData?.data?.uts);
            setReviewVelocityData(convertKeyValueObj || []);
            setStartDate(result?.getReviewVelocityData?.data?.dateValidation);
          } else {
            setReviewVelocityDate();
            setReviewVelocityData([]);
            setStartDate([]);
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  return (
    <>
      {reviewVelocityData && reviewVelocityData?.length > 0 ? (
        <div>
          <div className="[&:not(:last-child)] border-1 border-solid border-darkgreen rounded-lg p-14">
            {reviewVelocityData?.map((item, index) => (
              <div
                className="[&:not(:last-child)] border-b-1 border-solid border-darkgreen pb-12 mb-12 last:border-b-0 last:mb-0 last:pb-0"
                key={index}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h2
                      className={`text-16 font-semibold text-capitalize ${
                        item?.isOwner && 'text-darkgreen'
                      }`}
                    >
                      {item?.name}
                    </h2>
                    <div className="block font-medium text-grey-700">
                      <span className="inline-block mr-5 -mt-2">
                        {reviewFormate(item?.totalReviewRatings)}
                      </span>
                      <span>{item?.totalReviews}</span>
                      <span className="inline-block mx-3 text-12 relative -top-1">|</span>
                      <span>{item?.totalReviewRatings} Stars</span>
                    </div>
                  </div>
                  <span className={`font-bold text-24 ${item?.isOwner && 'text-darkgreen'}`}>
                    +{item?.count}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="text-14 font-medium text-center mt-16">
            {t('metrics.month')} ({moment(Number(startDate[1]?.value) * 1000).format('DD MMM')} -{' '}
            {moment(Number(startDate[0]?.value) * 1000).format('DD MMM')}){' '}
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ReviewVelocity;

import Business from './business';
import AddBusiness from './addBusiness';

const BusinessConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'agency/business',
      element: <Business />,
    },
    {
      path: 'agency/add-business/:id?',
      element: <AddBusiness />,
    },
  ],
};

export default BusinessConfigs;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from 'src/app/services/userService';

const processEndPoint = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL: processEndPoint,
});

const handleApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`tabletVideos/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};

const createAsyncThunkWithFile = (name, endpoint) => {
  return createAsyncThunk(`tabletVideos/${name}`, async (data, { dispatch }) => {
    const formT = new FormData();
    formT.append('operations', JSON.stringify(data.payload));
    if (data.fileData) {
      formT.append('map', '{"nFile":["variables.file"]}');
      formT.append('nFile', data.fileData);
    } else {
      formT.append('map', '{}');
    }

    return handleApiRequest(endpoint, formT, {
      'apollo-require-preflight': 'true',
      'x-apollo-operation-name': name,
    });
  });
};

export const getBusinessTypeVideoDetailsForAdmin = createAsyncThunkWithAuthorization(
  'getBusinessTypeVideoDetailsForAdmin',
  processEndPoint
);

export const getVideoByBusinessType = createAsyncThunkWithAuthorization(
  'getVideoByBusinessType',
  processEndPoint
);

export const addVideoByBusinessType = createAsyncThunkWithFile(
  'addVideoByBusinessType',
  processEndPoint
);

export const updateVideoByBusinessType = createAsyncThunkWithFile(
  'updateVideoByBusinessType',
  processEndPoint
);

export const deleteAndUpdateBusinessTypeVideo = createAsyncThunkWithAuthorization(
  'deleteAndUpdateBusinessTypeVideo',
  processEndPoint
);

export const getBusinessType = createAsyncThunkWithAuthorization(
  'getBusinessType',
  processEndPoint
);

const tabletVideosSlice = createSlice({
  name: 'tabletVideos',
  initialState: {
    videoDialog: {
      type: 'new',
      props: {
        open: false,
      },
    },
  },
  reducers: {
    openAddVideoDialog: (state, action) => {
      state.videoDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeAddVideoDialog: (state, action) => {
      state.videoDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditVideoDialog: (state, action) => {
      state.videoDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditVideoDialog: (state, action) => {
      state.videoDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    reSetTabletVideos: (state, action) => {
      if (state) {
        state.getBusinessTypeVideoDetailsForAdminData = {};
        state.getVideoByBusinessTypeData = {};
        state.addVideoByBusinessTypeData = {};
        state.deleteAndUpdateBusinessTypeVideoData = {};
        state.getBusinessTypeData = {};
        state.updateVideoByBusinessTypeData = {};
      }
    },
  },
  extraReducers: {
    [getBusinessTypeVideoDetailsForAdmin.fulfilled]: (state, action) => ({
      ...state,
      getBusinessTypeVideoDetailsForAdminData: action.payload,
    }),
    [getVideoByBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getVideoByBusinessTypeData: action.payload,
    }),
    [addVideoByBusinessType.fulfilled]: (state, action) => ({
      ...state,
      addVideoByBusinessTypeData: action.payload,
    }),
    [updateVideoByBusinessType.fulfilled]: (state, action) => ({
      ...state,
      updateVideoByBusinessTypeData: action.payload,
    }),
    [deleteAndUpdateBusinessTypeVideo.fulfilled]: (state, action) => ({
      ...state,
      deleteAndUpdateBusinessTypeVideoData: action.payload,
    }),
    [getBusinessType.fulfilled]: (state, action) => ({
      ...state,
      getBusinessTypeData: action.payload,
    }),
  },
});

export const {
  reSetTabletVideos,
  openAddVideoDialog,
  closeAddVideoDialog,
  openEditVideoDialog,
  closeEditVideoDialog,
} = tabletVideosSlice.actions;

export default tabletVideosSlice.reducer;

import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import _ from '@lodash';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { getDecryptData } from '../../common/common';
import pricingCatalogueData from '../../query/pricingCatalogue';
import { getPackagesForAdmin, reSetPricingCatalogue } from './store/pricingCatalogueSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function PricingCatalogue() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pricingListData = useSelector(
    ({ pricingCatalogueReducer }) =>
      pricingCatalogueReducer?.pricingCatalogue?.getPackagesForAdminData?.data?.getPackagesForAdmin
        ?.data || ''
  );

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = pricingListData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: pricingCatalogueData.getPackagesForAdmin,
    };
    dispatch(getPackagesForAdmin(payload));

    return () => {
      unmounted = true;
      dispatch(reSetPricingCatalogue());
    };
  }, [dispatch]);

  useEffect(() => {
    if (pricingListData) {
      setData(JSON.parse(getDecryptData(pricingListData)));
    } else {
      setData();
    }
  }, [pricingListData]);

  useEffect(() => {
    if (pricingListData || pricingListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pricingListData]);

  function EnhancedTableHead(props) {
    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.name')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.count')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.createDate')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.updateDate')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('pricingCatalogue.column.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectPricingCatalogueDetails(planName, planId) {
    return history.push({
      pathname: `/pricing-catalogue-detail/${planName}/${planId}`,
    });
  }

  return (
    <div className="p-20 lg:p-40">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('pricingCatalogue.pricingCatalogueList.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12" />
        <div className="items-center justify-end" />
      </div>
      <div className="bg-white rounded-md relative shadow">
        {data?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={pricingListData?.length}
                />
                <TableBody>
                  {data &&
                    data?.length > 0 &&
                    data?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.pricingCount} Prices
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.uts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-16 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                              aria-hidden="true"
                              onClick={() => {
                                redirectPricingCatalogueDetails(row?.name, row?.id);
                              }}
                            >
                              {t('pricingCatalogue.pricingCatalogueList.view')}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('pricingCatalogue.pricingCatalogueList.messages.noPricingCatalogue')}
            </Typography>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default withReducer('pricingCatalogueReducer', reducer)(PricingCatalogue);

import Users from './users';

const appWebsiteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'users',
      element: <Users />,
    },
  ],
};

export default appWebsiteConfig;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import userService from '../../../../services/userService';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});
const handleApiRequest = async (endpoint, data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    return (await instance.post(endpoint, { ...data }))?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

const createAsyncThunkWithAuthorization = (name, endpoint) => {
  return createAsyncThunk(`manageBusiness/${name}`, async (data) => {
    return handleApiRequest(endpoint, data);
  });
};
export const getReviewPlatforms = createAsyncThunkWithAuthorization('getReviewPlatforms', baseURL);

export const getUserBusinessDetail = createAsyncThunkWithAuthorization(
  'getUserBusinessDetail',
  baseURL
);

export const getBusinessAttributesOnId = createAsyncThunkWithAuthorization(
  'getBusinessAttributesOnId',
  baseURL
);

export const getBusinessOwnerCustomAttributes = createAsyncThunkWithAuthorization(
  'getBusinessOwnerCustomAttributes',
  baseURL
);

export const getBusinessAttributeValue = createAsyncThunkWithAuthorization(
  'getBusinessAttributeValue',
  baseURL
);

export const getBusinessServicesAndProductData = createAsyncThunkWithAuthorization(
  'getBusinessServicesAndProductData',
  baseURL
);

export const getReviewPlatfromModeType = createAsyncThunkWithAuthorization(
  'getReviewPlatfromModeType',
  baseURL
);

export const getBusinessAgentList = createAsyncThunkWithAuthorization(
  'getBusinessAgentList',
  baseURL
);

export const getAgentSupportStatus = createAsyncThunkWithAuthorization(
  'getAgentSupportStatus',
  baseURL
);

export const getAmazonProduct = createAsyncThunkWithAuthorization('getAmazonProduct', baseURL);

export const getFlipkartProduct = createAsyncThunkWithAuthorization('getFlipkartProduct', baseURL);

export const getCountries = createAsyncThunkWithAuthorization('getCountries', baseURL);

const manageBusinessSlice = createSlice({
  name: 'manageBusiness',
  initialState: {
    customDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openNewCustomDialog: (state, action) => {
      state.customDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewCustomDialog: (state, action) => {
      state.customDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditCustomDialog: (state, action) => {
      state.customDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditCustomDialog: (state, action) => {
      state.customDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    resetManageBusiness: (state, action) => {},
  },
  extraReducers: {},
});

export const {
  resetManageBusiness,
  openNewCustomDialog,
  closeNewCustomDialog,
  openEditCustomDialog,
  closeEditCustomDialog,
} = manageBusinessSlice.actions;

export default manageBusinessSlice.reducer;

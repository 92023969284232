import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography, Icon, CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import Loading from './Loading';
import './MangeBusiness.css';
import userService from '../../../services';
import manageBusinessData from '../../query/manageBusiness';
import reducer from './store';
import { getParamsId, handleApiRequest } from '../../common/common';

function ReviewMode(props) {
  const { setActiveStepNumber, setModeId, modeId } = props;
  const { t } = useTranslation();
  const loginUserData = userService.getUserData();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [reviewModeData, setReviewModemData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [reviewModeId, setReviewModeId] = useState(3);
  const { enqueueSnackbar } = useSnackbar();
  const businessId = localStorage.getItem('businessId');
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getReviewPlatfromModeType,
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        const id = getParamsId();
        if (result?.getReviewPlatfromModeType?.status === 200) {
          const sortedSliders = result?.getReviewPlatfromModeType?.data?.sort((a, b) =>
            a.id === 3 ? -1 : b.id === 3 ? 1 : a.id - b.id
          );
          if (sortedSliders) {
            let finalData = sortedSliders;

            if (
              location.pathname === '/manage-business-online' ||
              location.pathname === `/manage-business-online/${id}` ||
              userCurrentBusinessDetails?.isOnlineBusiness
            ) {
              finalData = sortedSliders?.filter((i) => i?.id !== 5);
            }
            setReviewModemData(finalData);
          }
        } else {
          enqueueSnackbar(result?.getReviewPlatfromModeType?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [enqueueSnackbar, userCurrentBusinessDetails?.isOnlineBusiness, location.pathname]);

  if (pageLoading) {
    return <Loading />;
  }

  const saveAndNext = async () => {
    setRefreshIcon(true);
    const getBusinessId = sessionStorage.getItem(`selectedBusinessId_${getParamsId()}`);
    const payload = {
      query: manageBusinessData.updateUserBusinessesPlatformModeTypeV2,
      variables: {
        userBusinessId: parseInt(businessId, 10) || parseInt(getBusinessId, 10),
        reviewModeTypeId: parseInt(reviewModeId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const id = getParamsId();
    if (result?.updateUserBusinessesPlatfromModeType?.status === 200) {
      if (
        location.pathname !== '/reviewScreenMode' &&
        location.pathname !== `/reviewScreenMode/${id}`
      ) {
        if (
          location.pathname === '/manage-business-online' ||
          location.pathname === `/manage-business-online/${id}`
        ) {
          localStorage.setItem('activeStepNumber', 4);
          setActiveStepNumber(4);
        } else {
          localStorage.setItem('activeStepNumber', 5);
          setActiveStepNumber(5);
        }
      }
    } else {
      enqueueSnackbar(result?.updateUserBusinessesPlatfromModeType?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleSkip = () => {
    const id = getParamsId();
    if (
      location.pathname === '/manage-business-online' ||
      location.pathname === `/manage-business-online/${id}`
    ) {
      localStorage.setItem('activeStepNumber', 4);
      setActiveStepNumber(4);
    } else {
      localStorage.setItem('activeStepNumber', 5);
      setActiveStepNumber(5);
    }
  };

  return (
    <>
      {reviewModeData?.length > 0 ? (
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 md:gap-36 gap-10 mx-auto mt-24">
          {reviewModeData?.length > 0 &&
            reviewModeData?.map((item, index) => {
              return (
                <div className="rounded-md bg-grey-50 md:p-24 p-10" key={index}>
                  <FormControl className="block">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      className="block"
                      value={
                        location.pathname !== '/reviewScreenMode' &&
                        location.pathname !== `/reviewScreenMode/${getParamsId()}`
                          ? reviewModeId
                          : modeId
                      }
                      onChange={(event) => {
                        setReviewModeId(event?.target?.value);
                        if (
                          location.pathname === '/reviewScreenMode' ||
                          location.pathname === `/reviewScreenMode/${getParamsId()}`
                        ) {
                          setModeId(event?.target?.value);
                        }
                      }}
                    >
                      <FormControlLabel
                        value={item?.id}
                        control={<Radio className="p-0 absolute bottom-2 right-0" />}
                        label={
                          <>
                            <span className="mx-auto block">
                              <img
                                className={`${
                                  location.pathname === '/reviewScreenMode' ||
                                  location.pathname === `/reviewScreenMode/${getParamsId()}`
                                    ? 'w-192'
                                    : 'w-128'
                                } mx-auto`}
                                src={item?.image?.[0]}
                                alt={item?.name}
                              />
                            </span>
                            <span className="block md:text-18 text-14 font-semibold text-left mt-48">
                              {item?.name}
                            </span>
                          </>
                        }
                        className="w-full block m-0"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          <h1 className="text-16 font-bold text-center">{t('manageBusiness.message')}</h1>
        </div>
      )}
      {location.pathname !== '/reviewScreenMode' &&
        location.pathname !== `/reviewScreenMode/${getParamsId()}` && (
          <div className="text-right mt-48 md:mb-96 mb-36">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => handleSkip()}
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
              aria-label="confirm"
              disabled={refreshIcon}
              onClick={() => saveAndNext()}
              type="button"
            >
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
              {location.pathname !== '/reviewScreenMode' &&
              location.pathname !== `/reviewScreenMode/${getParamsId()}`
                ? t('manageBusiness.addBusinessSteps.customAttribute.saveAndNext')
                : t('manageBusiness.addBusinessSteps.customAttribute.save')}
            </Button>
          </div>
        )}
    </>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ReviewMode);

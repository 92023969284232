import { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Input,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import Pagination from '../../../component/table/Pagination';
import promotionQuery from '../../query/promotion';
import { handleApiRequest } from '../../common/common';
import AddEditAppPromotionAndSlider from './AddEditAppPromotionSlider';
import TableHeader from '../../../component/table/TableHeader';

const AppPromotionAndSlider = () => {
  const headerData = [
    t('appPromotionSlider.column.srNo'),
    t('appPromotionSlider.column.image'),
    t('appPromotionSlider.column.title'),
    t('appPromotionSlider.column.description'),
    t('appPromotionSlider.column.createdDate'),
    t('appPromotionSlider.column.status'),
    t('appPromotionSlider.column.action'),
  ];
  const [appPromotionSliderData, setAppPromotionSLiderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [editData, setEditData] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('all');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [checkInitialDataAvailable, setCheckInitialDataAvailable] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchSliderData() {
      const payload = {
        query: promotionQuery.getPromotionSlider,
      };
      const res = await handleApiRequest(payload);
      setLoading(false);
      if (res?.getPromotionSlider?.status === 200) {
        setAppPromotionSLiderData(res?.getPromotionSlider?.data);
        setTotalCount(res?.getPromotionSlider?.totalCount);
        if (res?.getPromotionSlider?.data?.length === 0) {
          setCheckInitialDataAvailable(true);
        } else {
          setCheckInitialDataAvailable(false);
        }
      }
    }
    fetchSliderData();
  }, []);

  const fetchData = async () => {
    const payload = {
      query: promotionQuery.getPromotionSlider,
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  const handleStatusChange = async (id, value) => {
    const payload = {
      query: promotionQuery.updatePromotionSlider,
      variables: {
        promotionSliderId: id,
        isActive: !value,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.updatePromotionSlider?.status === 200) {
      fetchData();
    }
  };

  const handleEditData = (data) => {
    setEditData(data);
    setIsDialogOpen(true);
  };

  const handleStatusDropdownChange = async (event) => {
    const statusValue = event.target.value;
    const value = statusValue === 'all' ? undefined : Boolean(JSON.parse(event.target.value));
    setStatus(statusValue);
    setSearchValue('');
    setFilterValue(value);
    const payload = {
      query: promotionQuery.getPromotionSlider,
      variables: {
        isActive: statusValue === 'all' ? undefined : Boolean(JSON.parse(event.target.value)),
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  const handleSearchEvent = async (event) => {
    const searchTextValue = event.target.value;
    setSearchValue(searchTextValue);
    const payload = {
      query: promotionQuery.getPromotionSlider,
      variables: {
        searchText: searchTextValue,
        isActive: filterValue,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    const payload = {
      query: promotionQuery.getPromotionSlider,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: promotionQuery.getPromotionSlider,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    const payload = {
      query: promotionQuery.getPromotionSlider,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        isActive: filterValue,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getPromotionSlider?.status === 200) {
      setAppPromotionSLiderData(res?.getPromotionSlider?.data);
      setTotalCount(res?.getPromotionSlider?.totalCount);
    }
  };

  return (
    <div className="p-20 lg:p-40 h-full overflow-hidden">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('appPromotionSlider.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('appPromotionSlider.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              onChange={handleSearchEvent}
              value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-136 leading-5 shadow-none mr-16"
            variant="filled"
            value={status}
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>
            <MenuItem value="true" className="capitalize">
              {t('menuItems.active')}
            </MenuItem>
            <MenuItem value="false" className="capitalize">
              {t('menuItems.inActive')}
            </MenuItem>
          </Select>
          <Button
            variant="contained"
            color="secondary"
            className="w-full min-w-188 md:text-18 font-medium disabled:text-black"
            aria-label="Add Business Type"
            onClick={() => {
              setIsDialogOpen(true);
              setEditData();
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">
              &nbsp; {t('appPromotionSlider.button.addSlider')}
            </span>
            <span className="flex sm:hidden">
              &nbsp; {t('appPromotionSlider.button.addSlider')}
            </span>
          </Button>
        </div>
      </div>
      {!checkInitialDataAvailable ? (
        <div className="bg-white rounded-md relative shadow">
          {appPromotionSliderData?.length > 0 ? (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                  <TableHeader headerData={headerData} />
                  <TableBody>
                    {appPromotionSliderData?.length > 0 &&
                      appPromotionSliderData?.map((item, i) => {
                        return (
                          <TableRow
                            hover
                            className="border border-t-2 border-t-grey-100"
                            role="checkbox"
                            key={i}
                            tabIndex={-1}
                          >
                            <TableCell
                              className="text-16 color-black font-semibold w-[10%]"
                              align="left"
                            >
                              {item?.index}
                            </TableCell>
                            <TableCell
                              className="text-16 color-black font-semibold w-[10%]"
                              align="left"
                            >
                              <img
                                className="img-fluid max-w-80 rounded-md"
                                src={item.icon}
                                alt=""
                              />
                            </TableCell>
                            <TableCell
                              className="text-16 color-black font-semibold capitalize w-[20%]"
                              align="left"
                            >
                              {item.title}
                            </TableCell>
                            <TableCell
                              className="text-16 color-black font-semibold w-[30%]"
                              align="left"
                            >
                              {item.description}
                            </TableCell>
                            <TableCell
                              className="text-16 color-black font-semibold w-[10%]"
                              align="left"
                            >
                              {moment(item?.cts).format('MMM DD, YYYY')}
                            </TableCell>
                            <TableCell
                              className="text-16 color-black font-semibold w-[10%]"
                              align="left"
                            >
                              <Switch
                                color="secondary"
                                checked={item?.isActive}
                                onChange={() => handleStatusChange(item.id, item.isActive)}
                              />
                            </TableCell>
                            <TableCell className="text-16 color-black font-semibold" align="left">
                              <Button onClick={() => handleEditData(item)}>
                                <Icon
                                  className="cursor-pointer text-grey-600 hover:text-darkgreen align-middle"
                                  aria-hidden="true"
                                >
                                  edit
                                </Icon>
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                totalCount={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography className="text-center p-20">
              {t('appPromotionSlider.noSliderData')}
            </Typography>
          )}
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center h-full">
          <div>
            <img className="m-auto" src="/assets/images/admin-icon/add-icon.png" alt="" />
            <Typography className="text-black font-bold text-24 text-center">
              {t('appPromotionSlider.noSliderAdded')}
            </Typography>
            <Typography className="text-black font-medium text-16 pt-8 w-[64%] text-center m-auto">
              {t('appPromotionSlider.message')}
            </Typography>
          </div>
        </div>
      )}
      <AddEditAppPromotionAndSlider
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        totalData={appPromotionSliderData}
        editData={editData}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        setEditData={setEditData}
        fetchData={fetchData}
      />
    </div>
  );
};

export default AppPromotionAndSlider;

import SearchBusiness from './SearchBusiness';

const SearchBusinessConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'search-business',
      element: <SearchBusiness />,
    },
  ],
};

export default SearchBusinessConfig;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function CompetitorBenchmarking(props) {
  const { reviewSentiment } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const [competitor, setCompetitor] = useState([]);
  const [overAll, setOverAll] = useState([]);
  const [competitorBusinessId, setCompetitorBusinessId] = useState(-1);

  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const [newGraphData, setNewGraphData] = useState([
    {
      name: userCurrentBusinessDetails?.name,
      data: [],
    },
    {
      name: 'Other Business',
      data: [],
    },
  ]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id) {
          const payload = {
            query: metricsData.getCompetitorBenchmarking,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          const result = await getDataToServer(payload);

          const data = result?.getCompetitorBenchmarking?.data || [];
          if (data[0]?.competitorBenchmarkingV1) {
            const competitorDataOverAll =
              competitorBusinessId !== -1
                ? data[0].competitorBenchmarkingV1?.competitor
                : data[0].competitorBenchmarkingV1.overall;
            const userBusinessData = data[0].businessAttributeRatingsV1;
            const competitorList = data[0].competitorBenchmarkingV1?.competitor;

            setCompetitor(competitorList);
            setOverAll(userBusinessData);
            setNewGraphData([
              {
                name: userCurrentBusinessDetails?.name,
                data: userBusinessData?.map((item) => item.value),
              },
              {
                name:
                  competitorBusinessId !== -1
                    ? `${competitorDataOverAll[competitorBusinessId]?.name.slice(0, 10)}...`
                    : 'Other Business',
                data:
                  competitorBusinessId !== -1
                    ? competitorDataOverAll[competitorBusinessId]?.status !== 'pending' &&
                      competitorDataOverAll[competitorBusinessId]?.value?.map((item) => item?.value)
                    : competitorDataOverAll?.map((item) => item?.value),
              },
            ]);
          } else {
            setCompetitor([]);
            setOverAll([]);
            setNewGraphData([]);
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    selectedReviewSentimentId,
    competitorBusinessId,
    userCurrentBusinessDetails?.name,
  ]);

  const getChartSimple = (data, categories, index, color, types) => {
    const chart = {
      series: data,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '60px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            radius: 12,
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories,
          labels: {
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return `${val} ★`;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => `${val.toFixed(1)} ★`,
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  return (
    <div className="relative">
      <ReviewSentiment
        reviewSentiment={reviewSentiment}
        sentimentId={[7, 5, 2]}
        onSelectSentiment={setSelectedReviewSentimentId}
      />
      {overAll && overAll?.length > 0 ? (
        <>
          <Select
            className="inline-block bg-white text-black capitalize border-none pt-0 font-semibold w-full rounded-full mb-12 relative"
            id="country"
            value={competitorBusinessId}
            size="small"
            displayEmpty
            sx={{
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                maxWidth: '360px',
              },
            }}
            onChange={(e) => setCompetitorBusinessId(e?.target?.value)}
          >
            <MenuItem value={-1} className="capitalize">
              Overall
            </MenuItem>
            {competitor?.map((option, i) => (
              <MenuItem key={i} value={i} className="capitalize whitespace-normal">
                <div className="w-full max-w-360">{option.name}</div>
              </MenuItem>
            ))}
          </Select>
          <>
            <div>
              {getChartSimple(
                newGraphData,
                overAll?.map((period) => period?.key),
                6,
                ['#008ffb', '#ffbd23'],
                'bar'
              )}
            </div>
          </>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default CompetitorBenchmarking;

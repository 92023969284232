import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import history from '@history';
import jwtDecode from 'jwt-decode';
import Query from '../main/query/auth';

/* eslint-disable camelcase */

export const getParamsId = () => {
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  if (/^\d+$/.test(parseInt(lastPart, 10))) {
    return lastPart;
  }
  return '';
};
class UserService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      if (
        window.location.pathname.includes('agency') ||
        window.location.pathname.includes('business')
      ) {
        history.push('sign-in');
        sessionStorage.clear();
      }
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  // createUser = (data) => {
  //   return new Promise((resolve, reject) => {
  //     axios.post(jwtServiceConfig.signUp, data).then((response) => {
  //       if (response.data.user) {
  //         this.setSession(response.data.access_token);
  //         resolve(response.data.user);
  //         this.emit('onLogin', response.data.user);
  //       } else {
  //         reject(response.data.error);
  //       }
  //     });
  //   });
  // };

  // signInWithEmailAndPassword = (email, password) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(jwtServiceConfig.signIn, {
  //         data: {
  //           email,
  //           password,
  //         },
  //       })
  //       .then((response) => {
  //         if (response.data.user) {
  //           this.setSession(response.data.access_token);
  //           resolve(response.data.user);
  //           this.emit('onLogin', response.data.user);
  //         } else {
  //           reject(response.data.error);
  //         }
  //       });
  //   });
  // };
  signInWithToken = () => {
    const link = history?.location?.pathname.includes('agency');
    const id = getParamsId();
    const accessToken =
      (!link && window.sessionStorage.getItem(`jwt_access_token_${id}`)) ||
      window.localStorage.getItem('jwt_access_token');
    const tokenValue = jwtDecode(accessToken);
    if (
      !id &&
      tokenValue?.userType === 'agent' &&
      !window.location.pathname.includes('agency') &&
      !window.location.pathname.includes('scanner')
    ) {
      history.push('/agency/dashboard');
    }
    const payload = {
      query: Query.verifyToken,
      variables: { token: accessToken },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_AUTH_ENDPOINT, { ...payload })
        .then((response) => {
          const result = response.data;
          if (result && result.data && result.data.verifyToken && result.data.verifyToken) {
            this.setSession(result.data.verifyToken.data.accessToken);
            const tokenData = {
              ...result.data.verifyToken.data,
            };
            resolve(tokenData);
          } else {
            this.logout();
            history.push('/sign-in');
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          history.push('/sign-in');
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    // return axios.post(jwtServiceConfig.updateUser, {
    //   user,
    // });
    return axios.post('/api/auth/user/update', {
      user,
    });
  };

  setSession = (access_token) => {
    const link = history?.location?.pathname.includes('agency');
    const id = getParamsId();
    if (access_token) {
      if (!link && !window.sessionStorage.getItem(`jwt_access_token_${id}`)) {
        localStorage.setItem('jwt_access_token', access_token);
      }
      // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      axios.defaults.headers.common.Authorization = `${access_token}`;
    } else {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    history.go('/sign-in');
    // this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    const id = getParamsId();
    const link = history?.location?.pathname.includes('agency');
    return !link && window.sessionStorage.getItem(`jwt_access_token_${id}`)
      ? window.sessionStorage.getItem(`jwt_access_token_${id}`)
      : window.localStorage.getItem('jwt_access_token');
  };

  getUserData = () => {
    return JSON.parse(localStorage.getItem('userData'));
  };
}

const instance = new UserService();

export default instance;
